import { Grid } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import styles from "./analytics.module.scss";
import InvestmentGrpahs from "./investmentGraph";

const Analytics = () => {
  const [isActive, setisActive] = useState(true);

  const ToggleActiveButton = () => {
    setisActive(!isActive);
  };

  return (
    <Grid container>
      <Grid item container className={styles.con1} justifyContent="center">
        <Grid className={styles.investmentSource} item>
          <h5
            className={isActive && styles.active}
            onClick={ToggleActiveButton}>
            Date Source: Investments (Default)
          </h5>
        </Grid>
        <Grid item className={styles.propertySource}>
          <h5
            className={!isActive && styles.active}
            onClick={ToggleActiveButton}>
            Date Source: Properties
          </h5>
        </Grid>
      </Grid>
      <Grid item container>
        <InvestmentGrpahs />
      </Grid>
    </Grid>
  );
};

export default Analytics;
