import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Property from "./property";
import styles from "./Components.module.scss";
import { useSelector } from "react-redux";
import { AddInvestmentLoanService } from "network/addInvestmentLoanService";

const TagProperty = ({ id, formik, isedit }) => {
  const [propertyData, setPropertyData] = useState([]);
  const { investmentID } = useSelector((store) => store.app);

  const handleCheck = (formik, index, item) => {
    if (formik.values.loanSet[id].selectedProperties[index]) {
      formik.setFieldValue(
        `loanSet[${id}].selectedProperties[${index}]`,
        false
      );
      if (isedit) {
        formik.setFieldValue(
          `loanSet[${id}].detach_property[${index}]`,
          item.id
        );
      }
    } else {
      formik.setFieldValue(
        `loanSet[${id}].selectedProperties[${index}]`,
        item.id
      );
      if (isedit) {
        formik.setFieldValue(`loanSet[${id}].detach_property[${index}]`, false);
      }
    }
  };

  const getPropertiesData = async () => {
    const payload = {
      investment_id: investmentID
    };
    const resp = await AddInvestmentLoanService.getProperties(payload);
    if (resp.success) {
      setPropertyData(resp?.data?.results);
    }
  };

  useEffect(() => {
    getPropertiesData();
  }, []);

  return (
    <Grid container className={styles.tagpropertyCtr}>
      <Grid item xs={12}>
        <Grid container className={styles.headerCtr}>
          <div className={styles.headerEmp}></div>
          <div className={styles.header}>
            <Typography className={styles.headerText}>Property Name</Typography>
          </div>
          <div className={styles.header}>
            <Typography className={styles.headerText}>Type</Typography>
          </div>
          <div className={styles.header}>
            <Typography className={styles.headerText}>Area</Typography>
          </div>
          <div className={styles.header}>
            <Typography className={styles.headerText}>Occupancy</Typography>
          </div>
          <div className={styles.header}>
            <Typography className={styles.headerText}>Year Of Build</Typography>
          </div>
          <div className={styles.header}>
            <Typography className={styles.headerText}>Address</Typography>
          </div>
          <div className={styles.header}>
            <Typography className={styles.headerText}>Zip</Typography>
          </div>
          <div className={styles.header}>
            <Typography className={styles.headerText}>Status</Typography>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {propertyData &&
          propertyData.length > 0 &&
          propertyData.map((item, index) => {
            return (
              <Property
                key={item.id}
                formik={formik}
                item={item}
                index={index}
                id={id}
                handleCheck={handleCheck}
              />
            );
          })}
      </Grid>
    </Grid>
  );
};

export default TagProperty;
