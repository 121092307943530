import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import { CovertToMb } from "helpers/Common";
import React from "react";
import styles from "./commanstyle.module.scss";

const PropertyDocTable = ({ Title, Headingdata, mapArray, width }) => {
  function getFileData() {
    const obj = {};
    mapArray.map((ele) => {
      if (
        Object.keys(obj)?.findIndex((val) => val === ele?.category?.name) === -1
      ) {
        obj[ele?.category?.name] = [
          {
            name: ele?.document_media?.name,
            type: ele?.category?.id,
            link: ele?.document_media?.file,
            id: ele?.document_media?.id,
            docId: ele.id,
            size: ele?.document_media?.file_size
          }
        ];
      } else {
        obj[ele?.category?.name] = [
          ...obj[ele?.category?.name],
          {
            name: ele?.document_media?.name,
            type: ele?.category?.id,
            link: ele?.document_media?.file,
            id: ele?.document_media?.id,
            docId: ele.id,
            size: ele?.document_media?.file_size
          }
        ];
      }
    });
    return obj;
  }

  function getSize(item) {
    return item?.reduce((total, { size }) => total + size, 0);
  }

  return (
    <>
      <div className={styles.TableTitle}>{Title}</div>
      <Table
        className={styles.Spon_T_C}
        style={width ? { minWidth: width } : {}}>
        <TableHead>
          <TableRow>
            {Headingdata?.map((item, i) => (
              <TableCell className={styles.T_Heading} key={i}>
                {item.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(getFileData())?.map((arr, i) => (
            <TableRow key={i}>
              <TableCell className={styles.T_body}>{arr[0]}</TableCell>
              <TableCell className={styles.T_body}>
                {CovertToMb(getSize(arr[1]))}
              </TableCell>
              <TableCell className={styles.T_body}>
                <DocumentsUpload editable={false} listData={arr[1]} />
              </TableCell>
            </TableRow>
          ))}
          {Object.entries(getFileData())?.length == 0 && (
            <TableRow>
              <TableCell colSpan={Headingdata.length}>
                <Grid container justifyContent="center">
                  NO Documents Avalilable
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default PropertyDocTable;
