import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid
} from "@material-ui/core";
import Illustrations from "components/illustration/Illustrations";
import LightTooltip from "components/LightTooltip";
import { truncate } from "helpers/Common";
import React from "react";
import styles from "../../EntityDetail.module.scss";
import InvestmentToolTipData from "./InvestmentToolTipData";
import investmentEmptyScreen from "assets/emptyState/noInvestments.svg";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "redux/index";

function InvestmentTable({ tableData, heading }) {
  const history = useHistory();
  let setLoanHead = 0;
  tableData?.map((value) => {
    if (Object.keys(value)?.length > 0) {
      setLoanHead = 1;
    }
  });

  const handleInvestmentClick = (id) => {
    AppDispatcher.setInvestmentDetailID(id);
    history.push("/u/investments/detail");
  };

  return (
    <>
      {tableData?.length != 0 ? (
        <TableContainer
          component={Paper}
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)"
          }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {setLoanHead == 1 ? (
              <TableHead>
                <TableRow>
                  {heading.map((value, index) => {
                    return (
                      <TableCell key={index} className={styles.noiTableHeader}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : (
              <Illustrations
                src={investmentEmptyScreen}
                heading="No Investments found"
                // subHeading="Add Investment Data To View"
              />
            )}
            <TableBody>
              {tableData?.map((value, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {heading.map((item, indx) => {
                      return (
                        <LightTooltip
                          key={indx}
                          title={
                            <InvestmentToolTipData
                              data={value["toolTipData"]}
                            />
                          }>
                          <TableCell
                            style={
                              indx === 0
                                ? { cursor: "pointer", color: "#191919" }
                                : { color: "#191919" }
                            }
                            align="left"
                            onClick={
                              indx === 0
                                ? () => {
                                    handleInvestmentClick(value?.id);
                                  }
                                : () => {}
                            }
                            component="th"
                            scope="row">
                            <Grid
                              className={[
                                indx === 0 && styles.firstCell,

                                indx == 8 || (indx == 7 && value["tableType"])
                                  ? value[indx] != "-" &&
                                    (value[indx] === "Active"
                                      ? styles.activeCell
                                      : styles.inActiveCell)
                                  : null
                              ]}>
                              {truncate(value[indx], 20)}
                            </Grid>
                          </TableCell>
                        </LightTooltip>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff",
            width: "100vw"
          }}>
          <Illustrations
            src={investmentEmptyScreen}
            heading="No Investments found"
            // subHeading="Add Investment Data To View"
          />
        </Grid>
      )}
    </>
  );
}

export default InvestmentTable;
