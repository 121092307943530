import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";

const MessegeDescriptionBox = styled(TextField)({
  "& label": {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#191919"
  },
  "& .MuiInputBase-multiline": {
    padding: "0px"
  },
  "& label.Mui-focused": {
    color: "#191919"
  },
  "& .MuiInputBase-input": {
    // paddingLeft: "10px",
    paddingRight: "15px",
    paddingTop: "20px",

    // paddingBottom: "15px",
    fontFamily: "Inter SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    // lineHeight: "24px",
    color: "#191919",
    // border: "1px solid #BBBBBB",
    // width: "100px",
    borderRadius: "8px",
    WebkitBoxShadow: "0 0 0 1000px #FFF inset"
    // height: "100px",
  },
  "& .MuiInputBase-input:focus": {},
  "& .Mui-error": {
    "& .MuiInputBase-input": {
      border: "1px solid #E13838",
      fontFamily: "Inter Regular"
    }
  },
  "& .Mui-disabled": {
    "& .MuiInputBase-input": {
      backgroundColor: "#E9E9E9",
      WebkitBoxShadow: "0 0 0 1000px #E9E9E9 inset",
      fontFamily: "Inter Regular",
      borderRadius: "8px"
    }
  }
});

export default MessegeDescriptionBox;
