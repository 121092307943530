import React from "react";
import InvestorCards from "../../../../../components/Cards/InvestorCards";
import { Grid } from "@material-ui/core";
import voiletDollar from "assets/icons/NewCardSvg/voiletDollar.svg";
import blueTree from "assets/icons/NewCardSvg/blueTree.svg";
import pinkTree from "assets/icons/NewCardSvg/pinkTree.svg";
import voiletCardCircle from "assets/icons/NewCardSvg/voiletCardCircle.svg";

const InvestmentDetailCard = ({
  InvestedAmount,
  DistributionAmount,
  TotalDistribution,
  IRR
}) => {
  return (
    <Grid container style={{ marginTop: 40, marginBottom: 30 }} direction="row">
      <Grid item lg={3} xs={12} md={4} sm={6}>
        <InvestorCards
          Title="Total Invested Amount"
          count={InvestedAmount}
          icon={voiletDollar}
        />
      </Grid>
      <Grid item lg={3} xs={12} md={4} sm={6}>
        <InvestorCards
          Title="Total Distribution Amount"
          count={DistributionAmount}
          icon={blueTree}
        />
      </Grid>
      <Grid item lg={3} xs={12} md={4} sm={6}>
        <InvestorCards
          Title="Total Distribution"
          count={TotalDistribution}
          icon={pinkTree}
          link="/u/distributions"
        />
      </Grid>
      <Grid item lg={3} xs={12} md={4} sm={6}>
        <InvestorCards
          Title="IRR"
          count={IRR}
          icon={voiletCardCircle}
          link="/u/distributions"
        />
      </Grid>
    </Grid>
  );
};

export default InvestmentDetailCard;
