import FieldHeading from "../../../../components/fieldHeading";
import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import AppTextFieldWhite from "../../../../components/StyledComponents/AppTextFieldWhite";
import CustomSelect from "components/StyledComponents/Select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Toast from "components/toast";
import { preventNonNumericalInput } from "helpers";
import { SubAdminService } from "network/subAdminService";

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: "#FAFAFA",
    padding: 20,
    borderRadius: 8,
    marginBottom: 20
  },
  basicContainer2: {
    marginTop: 10
  },
  primaryContactText: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0
  },
  text: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#fff",
    height: 20,
    marginBottom: 4
  },
  deleteBtn: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#E13838",
    marginTop: 10,
    cursor: "pointer"
  }
}));

const AddSubAdmin = ({ arrayHelper, id, formik }) => {
  const classes = useStyles();
  const [role, setRole] = useState([]);

  const contactsErrors =
    (formik.errors.associatedSubAdminSet &&
      formik.errors.associatedSubAdminSet.length &&
      formik.errors.associatedSubAdminSet[id]) ||
    {};
  const contactsTouched =
    (formik.touched.associatedSubAdminSet &&
      formik.touched.associatedSubAdminSet.length &&
      formik.touched.associatedSubAdminSet[id]) ||
    {};

  const handleDelete = async () => {
    if (formik.values.associatedSubAdminSet[id].id) {
      const res = await AddInvestmentBasicDetailsService.deleteContacts(
        formik.values.associatedSubAdminSet[id].id
      );
      if (!res.success) {
        return Toast.showErrorToast(res.error.message[0]);
      }
    }

    arrayHelper.remove(id);
  };

  const fetchRole = async () => {
    const res = await SubAdminService.getRole();
    if (res.success) {
      const data = res?.data?.map((ele) => ({
        value: ele.id,
        label: ele.name
      }));
      setRole(data);
    }
  };

  useEffect(() => {
    fetchRole();
  }, []);

  return (
    <Grid xs={12} className={classes.root}>
      <Grid container spacing={2} className={classes.basicContainer2}>
        <Grid item xs={12} sm={6} md={3}>
          <FieldHeading title="First Name" />
          <AppTextFieldWhite
            id={`associatedSubAdminSet[${id}].first_name`}
            placeholder="Add First Name"
            variant="standard"
            fullWidth
            value={formik.values.associatedSubAdminSet[id].first_name}
            onChange={formik.handleChange(
              `associatedSubAdminSet[${id}].first_name`
            )}
            error={
              contactsTouched.first_name && Boolean(contactsErrors.first_name)
            }
            helperText={contactsTouched.first_name && contactsErrors.first_name}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FieldHeading title="Last Name" />
          <AppTextFieldWhite
            id={`associatedSubAdminSet[${id}].last_name`}
            placeholder="Add Last Name"
            variant="standard"
            fullWidth
            value={formik.values.associatedSubAdminSet[id].last_name}
            onChange={formik.handleChange(
              `associatedSubAdminSet[${id}].last_name`
            )}
            error={
              contactsTouched.last_name && Boolean(contactsErrors.last_name)
            }
            helperText={contactsTouched.last_name && contactsErrors.last_name}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FieldHeading title="Email ID" />
          <AppTextFieldWhite
            id={`associatedSubAdminSet[${id}].email`}
            placeholder="Add Email ID"
            variant="standard"
            fullWidth
            value={formik.values.associatedSubAdminSet[id].email}
            onChange={formik.handleChange(`associatedSubAdminSet[${id}].email`)}
            error={contactsTouched.email && Boolean(contactsErrors.email)}
            helperText={contactsTouched.email && contactsErrors.email}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid container item xs={12} sm={6} md={3} spacing={1}>
          <Grid item xs={4}>
            <FieldHeading title="Phone Number" />
            <PhoneInput
              country={"us"}
              placeholder="Code"
              value={formik.values.associatedSubAdminSet[id].country_code}
              onChange={formik.handleChange(
                `associatedSubAdminSet[${id}].country_code`
              )}
              inputStyle={{
                border: "1px solid #BBBBBB",
                borderRadius: "6px",
                fontSize: "15px",
                width: "100%",
                height: 48,
                marginRight: 10
              }}
              dropdownStyle={{
                width: "550%",
                marginBottom: 30,
                height: 100
              }}
              buttonStyle={{
                borderRadius: "4px"
              }}></PhoneInput>
          </Grid>

          <Grid item xs={8}>
            <Typography className={classes.text}></Typography>
            <AppTextFieldWhite
              id={`associatedSubAdminSet[${id}].phone_number`}
              placeholder="Enter Phone Number"
              variant="standard"
              type="tel"
              inputProps={{ maxLength: 12 }}
              onKeyDown={preventNonNumericalInput}
              fullWidth
              style={{ marginLeft: 10 }}
              value={formik.values.associatedSubAdminSet[id].phone_number}
              onChange={formik.handleChange(
                `associatedSubAdminSet[${id}].phone_number`
              )}
              error={
                contactsTouched.phone_number &&
                Boolean(contactsErrors.phone_number)
              }
              helperText={
                contactsTouched.phone_number && contactsErrors.phone_number
              }
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FieldHeading title="Role" />
          <CustomSelect
            items={role}
            fullWidth
            value={formik.values.associatedSubAdminSet[id].access_role}
            onChange={(e) =>
              formik.setFieldValue(
                `associatedSubAdminSet[${id}].access_role`,
                e.target.value
              )
            }
            error={contactsErrors.access_role}
            touched={contactsTouched.access_role}
          />
        </Grid>
      </Grid>

      <Grid xs={1}>
        {id != 0 && (
          <Typography className={classes.deleteBtn} onClick={handleDelete}>
            Delete
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default AddSubAdmin;
