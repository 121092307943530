import { Grid, Typography } from "@material-ui/core";
import { truncate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import React from "react";
import CommonPropertyDetalTable from "./CommonPropertyDetailTable";
import style from "./propertiesProfile.module.scss";

const loanHeading = [
  "Loan ID",
  "Loan Type",
  "Loan Amount",
  "Outstanding Amount",
  "Debt Service",
  "DSCR",
  "LTV",
  "Shared with",
  "Status"
];

function LoanTable({ propertyData }) {
  const tableData = propertyData?.loans?.map((item) => {
    return {
      0: item?.base_id || "-",
      1: truncate(item?.type, 16) || "-",
      2: formatCurrency(item?.amount) || "-",
      3: formatCurrency(item?.outstanding || "-" || "0"),
      4: formatCurrency(item?.debtservice || "0"),
      5: item?.stats?.dscr || "0",
      6: item?.stats?.ltv || "0",
      7: item?.loan_property,
      8: item?.status || "-",

      tableType: false
    };
  });

  return (
    <Grid item xs={12} className={style.overviewMainContainer}>
      <Grid container>
        <Typography className={style.cardHeading}>Loan Information</Typography>
      </Grid>
      <Grid container>
        <CommonPropertyDetalTable
          data={propertyData?.loans}
          tableData={tableData}
          heading={loanHeading}
          type="loan"
        />
      </Grid>
    </Grid>
  );
}

export default LoanTable;
