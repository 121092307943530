// List all endpoints here
// @ts-check
import { Endpoint } from "./apiModel";
import { HTTP_METHODS } from "./httpMethods";

// ******************
// Endpoint class takes 3 params in constructor ==> "endpoint", "http-method", "API-version"
// By default, version is set to v1reset-password
// ******************
export const API = {
  AUTH: {
    LOGIN: new Endpoint("login/", HTTP_METHODS.POST),
    FORGOT_PASSWORD: new Endpoint("forgot-password/", HTTP_METHODS.POST),
    LOGOUT: new Endpoint("logout", HTTP_METHODS.DEL),
    OTP_VERFICATION: new Endpoint("token-email/", HTTP_METHODS.PATCH),
    RESET_PASSWORD: new Endpoint("reset-password/", HTTP_METHODS.PATCH)
  },
  PRIVATE: {
    DASHBOARD: new Endpoint("auth", HTTP_METHODS.GET)
  },
  INVESTOR: {
    ADD_INVESTOR: new Endpoint("create-investor", HTTP_METHODS.PATCH),
    INVESTORS: new Endpoint("admin/investor/", HTTP_METHODS.GET),
    INVESTORS_FILTER: new Endpoint("admin/investor", HTTP_METHODS.GET),
    INVESTOR_STATS: new Endpoint("admin/investor/stats/", HTTP_METHODS.GET),
    ADD_INVESTOR_EMAIL: new Endpoint("admin/invite/", HTTP_METHODS.POST),
    ADD_INVESTOR_EMAIL_PATCH: new Endpoint("admin/invite/", HTTP_METHODS.PATCH),
    INVESTOR_DETAIL: new Endpoint("investor/", HTTP_METHODS.GET),
    STATUS: new Endpoint("status", HTTP_METHODS.GET),
    CREATE_INVESTOR: new Endpoint("admin/investor/create/", HTTP_METHODS.POST),
    DELETE_DOCS: new Endpoint("documents", HTTP_METHODS.DEL),
    DELETE_INVESTOR: new Endpoint("investor", HTTP_METHODS.DEL),
    EXPORT_PDF_CSV: new Endpoint("admin/investor", HTTP_METHODS.GET)
  },
  SPONSOR: {
    SPONSORS: new Endpoint("sponsor/", HTTP_METHODS.GET),
    SPONSOR_STATS: new Endpoint("sponsor/stats/", HTTP_METHODS.GET),
    SPONSORS_FILTER: new Endpoint("sponsor", HTTP_METHODS.GET),
    ADD_SPONSOR: new Endpoint("sponsor/", HTTP_METHODS.POST),
    ADD_SPONSOR_SEND_INVITE: new Endpoint("admin/sponsor/invite/", HTTP_METHODS.POST),
    EDIT_SPONSOR: new Endpoint("sponsor", HTTP_METHODS.PATCH),
    SPONSOR_PROFILE: new Endpoint("sponsor", HTTP_METHODS.GET),
    DELETE_SPONSOR: new Endpoint("sponsor", HTTP_METHODS.DEL),
    EXPORT_PDF_CSV: new Endpoint("sponsor", HTTP_METHODS.GET)
  },
  ENTITY: {
    ENTITIES: new Endpoint("entity/", HTTP_METHODS.GET),
    ENTITIES_FILTER: new Endpoint("entity", HTTP_METHODS.GET),
    ENTITY_STATS: new Endpoint("entity/stats/", HTTP_METHODS.GET),
    SPON_LIST_BY_ROLE: new Endpoint(
      "admin/user-dropdown/SPONSOR",
      HTTP_METHODS.GET
    ),
    INV_LIST_BY_ROLE: new Endpoint(
      "admin/user-dropdown/INVESTOR",
      HTTP_METHODS.GET
    ),
    EXPORT_PDF_CSV: new Endpoint("entity", HTTP_METHODS.GET),
    ADD_ENTITY: new Endpoint("entity/", HTTP_METHODS.POST),
    ENTITY_PROFILE: new Endpoint("entity", HTTP_METHODS.GET),
    ENTITY_DETAIL: new Endpoint("entity/detail", HTTP_METHODS.GET),
    EDIT_ENTITY: new Endpoint("entity", HTTP_METHODS.PATCH),
    DELETE_ENTITY_INVESTOR: new Endpoint("entity", HTTP_METHODS.DEL),
    DELETE_ENTITY_SPONSOR: new Endpoint("entity", HTTP_METHODS.DEL),
    DELETE_PARTNERS: new Endpoint("entity/partners", HTTP_METHODS.DEL),
    INVESTOR_EMAIL_LIST: new Endpoint(
      "admin/investor/drop-downs",
      HTTP_METHODS.GET
    ),
    SPONSOR_EMAIL_LIST: new Endpoint("sponsor/dropdown", HTTP_METHODS.GET),
    INVESTOR_TAX_LIST: new Endpoint(
      "entity/investor/dropdown",
      HTTP_METHODS.GET
    ),
    SPONSOR_TAX_LIST: new Endpoint("entity/sponsor/dropdown", HTTP_METHODS.GET)
  },
  PROFILE: {
    CHANGEPASSWORD: new Endpoint("change-password/", HTTP_METHODS.PATCH),
    DELETE_PROFILE_PIC: new Endpoint("upload", HTTP_METHODS.DEL)
  },
  COMMON: {
    IMAGE_UPLOAD: new Endpoint("upload/", HTTP_METHODS.POST),
    DELETE_DOCS: new Endpoint("documents", HTTP_METHODS.DEL),
    DELETE_MEDIA: new Endpoint("upload", HTTP_METHODS.DEL),
    GET_CATEGORIES: new Endpoint("documents/category/", HTTP_METHODS.GET),
    IMAGE_LIST: new Endpoint("upload/", HTTP_METHODS.GET),
    GET_DOC_CATEGORIES: new Endpoint(
      "documents/category/mapping/",
      HTTP_METHODS.GET
    ),
    GET_ALL_ROLES: new Endpoint("roles/", HTTP_METHODS.GET)
  },
  ADD_INVESTMENT_INVESTOR: {
    GET_INVESTORS: new Endpoint("admin/role/INVESTOR/", HTTP_METHODS.GET),
    GET_ENTITIES: new Endpoint("entity", HTTP_METHODS.GET),
    ADD_INVESTORS: new Endpoint("investment", HTTP_METHODS.PATCH),
    DELETE_INVESTOR: new Endpoint("investment/investor", HTTP_METHODS.DEL),
    DELETE_INVESTOR_K1: new Endpoint("investment/managedata", HTTP_METHODS.DEL)
  },
  LIST_INVESTMENT: {
    GET_INVESTMENT: new Endpoint("investment/", HTTP_METHODS.GET),
    DELETE_INVESTMENT: new Endpoint("investment", HTTP_METHODS.DEL),
    GET_INVESTMENT_NAME: new Endpoint("investment", HTTP_METHODS.GET),
    GET_ALL_INVESTMENT_NAME: new Endpoint(
      "investment/investment-dropdown",
      HTTP_METHODS.GET
    ),
    GET_INVESTMENT_STATS: new Endpoint("investment/stats/", HTTP_METHODS.GET),
    GET_INVESTMENT_ROLE_INVESTOR: new Endpoint(
      "admin/role/INVESTOR/",
      HTTP_METHODS.GET
    ),
    GET_INVESTORS: new Endpoint("admin/investor", HTTP_METHODS.GET),
    GET_SPONSORS: new Endpoint("sponsor", HTTP_METHODS.GET),
    GET_INVESTMENT_ROLE_SPONSOR: new Endpoint(
      "admin/role/SPONSOR",
      HTTP_METHODS.GET
    ),
    EXPORT_PDF_CSV: new Endpoint("investment", HTTP_METHODS.GET),
    GET_INVESTMENT_ENTITY: new Endpoint("entity", HTTP_METHODS.GET),
    GET_CSV: new Endpoint("investment/download", HTTP_METHODS.GET)
  },
  LIST_INVESTMENT_Detail: {
    GET_INVESTMENT_Detail: new Endpoint("investment", HTTP_METHODS.GET),
    INVESTMENT_DETAILS_GRAPHS: new Endpoint(
      "investment/analytics",
      HTTP_METHODS.GET
    )
  },
  ADD_INVESTMENT_PREVIEW: {
    GET_INVESTMENT_DETAILS: new Endpoint("investment", HTTP_METHODS.GET),
    ADD_INVESTORS: new Endpoint("investment", HTTP_METHODS.PATCH)
  },
  ADD_INVESTMENT_BASIC: {
    GET_SPONSORS: new Endpoint("admin/role/SPONSOR/", HTTP_METHODS.GET),
    SPON_LIST_BY_ROLE: new Endpoint(
      "admin/user-dropdown/SPONSOR",
      HTTP_METHODS.GET
    ),
    GET_SPONSOR_ENTITY: new Endpoint(
      "entity/entity-dropdown",
      HTTP_METHODS.GET
    ),
    DELETE_NOI: new Endpoint("investment/noi", HTTP_METHODS.DEL),
    DELETE_CONTACTS: new Endpoint("investment/contacts", HTTP_METHODS.DEL),
    DELETE_TIERS: new Endpoint("investment/tiers", HTTP_METHODS.DEL),
    DELETE_SPONSORS: new Endpoint("sponsor/investment", HTTP_METHODS.DEL)
  },
  ADD_INVESTMENT: {
    ADD_INVESTMENT_POST: new Endpoint("investment/", HTTP_METHODS.POST),
    GET_PROPERTIES: new Endpoint("investment/properties/", HTTP_METHODS.GET),
    GET_ALL_PROPERTIES_DATA: new Endpoint("investment", HTTP_METHODS.GET),
    DELETE_PROPERTIES: new Endpoint("investment/properties", HTTP_METHODS.DEL),
    GET_DOC_CATEGORIES: new Endpoint(
      "documents/category/mapping/",
      HTTP_METHODS.GET
    )
  },
  DROPDOWN_OPTIONS: {
    OPTIONS: new Endpoint("investment/choices/", HTTP_METHODS.GET)
  },
  ADD_INVESTMENT_LOAN: {
    GET_PROPERTIES: new Endpoint("investment/properties", HTTP_METHODS.GET),
    ADD_LOAN: new Endpoint("investment", HTTP_METHODS.PATCH),
    GET_LOANS: new Endpoint("investment", HTTP_METHODS.GET),
    DELETE_LOAN: new Endpoint("investment/loans", HTTP_METHODS.DEL)
  },
  ADD_INVESTMENT_DISTRIBUTION: {
    ADD_DISTRIBUTION: new Endpoint("investment", HTTP_METHODS.PATCH),
    DELETE_DISTRIBUTION: new Endpoint(
      "investment/distribution",
      HTTP_METHODS.DEL
    )
  },
  LIST_PROPERTIES: {
    GET_PROPERTIES: new Endpoint("investment", HTTP_METHODS.GET),
    GET_STATS: new Endpoint("investment/properties/stats/", HTTP_METHODS.GET),
    ADD_PROPERTIES: new Endpoint("investment/properties/", HTTP_METHODS.POST),
    EDIT_PROPERTIES: new Endpoint("investment/properties", HTTP_METHODS.PATCH),
    DELETE_PROPERTIES: new Endpoint("investment/properties", HTTP_METHODS.DEL),
    DELETE_NOI: new Endpoint("investment/properties/noi", HTTP_METHODS.DEL),
    EXPORT_PDF_CSV: new Endpoint("investment/properties/", HTTP_METHODS.GET),
    PROPERTY_DETAILS_GRAPHS: new Endpoint("property", HTTP_METHODS.GET)
  },
  DISTRIBUTION: {
    GET_STATS: new Endpoint("investment/distribution/stats/", HTTP_METHODS.GET),
    GET_YEARS: new Endpoint(
      "investment/distribution/year-list",
      HTTP_METHODS.GET
    ),
    GET_DISTRIBUTIONS: new Endpoint(
      "investment/distribution",
      HTTP_METHODS.GET
    ),
    DELETE_DISTRIBUTION: new Endpoint(
      "investment/distribution",
      HTTP_METHODS.DEL
    ),
    EDIT_DISTRIBUTION: new Endpoint(
      "investment/distribution/",
      HTTP_METHODS.PATCH
    ),
    GET_INVESTORLIST: new Endpoint(
      "admin/investor/investment-investor-dropdown",
      HTTP_METHODS.GET
    ),
    EXPORT_PDF_CSV: new Endpoint("investment/distribution", HTTP_METHODS.GET)
  },
  LIST_CONTACTS: {
    GET_INVESTMENT_CONTACT: new Endpoint("investment", HTTP_METHODS.GET),
    GET_ADD_INVESTMENT_CONTACT: new Endpoint(
      "investment/investment-dropdown",
      HTTP_METHODS.GET
    ),
    GET_CONTACTS: new Endpoint("investment/contacts", HTTP_METHODS.GET),
    GET_SINGLE_CONTACT: new Endpoint("investment/contacts", HTTP_METHODS.GET),
    EDIT_CONTACTS: new Endpoint("investment/contacts", HTTP_METHODS.PATCH),
    DELETE_CONTACTS: new Endpoint("investment/contacts", HTTP_METHODS.DEL),
    ADD_CONTACT: new Endpoint("investment/contacts/", HTTP_METHODS.POST),
    SEND_CONTACT_EMAIL: new Endpoint(
      "investment/contacts/mail/",
      HTTP_METHODS.POST
    ),

    EXPORT_PDF_CSV: new Endpoint("investment/contacts", HTTP_METHODS.GET)
  },
  ManageData: {
    TEMPLATE_DATA_UPLOAD: new Endpoint("templates/create/", HTTP_METHODS.POST),
    TEMPLATE_DOWNLOAD: new Endpoint("templates/standard/", HTTP_METHODS.GET),
    ADD_MANAGEDATA: new Endpoint(
      "investment/managedata/addk1/",
      HTTP_METHODS.POST
    ),
    GET_MANAGEDATA_PROPERTY_NAME_LIST: new Endpoint(
      "investment/properties/dropdown",
      HTTP_METHODS.GET
    ),

    GET_MANAGEDATA_SPONSOR_ENTITY_LIST: new Endpoint(
      "entity",
      HTTP_METHODS.GET
    ),
    GET_MANAGEDATA_SPONSOR_NAME_LIST: new Endpoint("sponsor", HTTP_METHODS.GET),
    GET_INVESTOR_NAME: new Endpoint("admin/investor", HTTP_METHODS.GET),
    GET_INVESTOR_ENTITY_NAME: new Endpoint("entity", HTTP_METHODS.GET),
    MANAGEDATA_YEARS: new Endpoint(
      "investment/managedata/year-list",
      HTTP_METHODS.GET
    ),

    K1_DATA_LIST: new Endpoint("investment/managedata", HTTP_METHODS.GET),

    MANAGEDATA_INVESTMENT_LIST: new Endpoint(
      "investment/investment-dropdown",
      HTTP_METHODS.GET
    ),
    MANAGEDATA_INVESTMENT_NAME_LIST: new Endpoint(
      "investment/investment-dropdown",
      HTTP_METHODS.GET
    ),
    GET_MANAGEDATA_LIST: new Endpoint(
      "investment/managedata",
      HTTP_METHODS.GET
    ),
    GET_YEARS: new Endpoint(
      "investment/managedata/year-list",
      HTTP_METHODS.GET
    ),
    GET_TEMPLATE_DATA_LIST: new Endpoint("templates", HTTP_METHODS.GET),
    GET_MANAGEDATA_K1: new Endpoint("investment/managedata", HTTP_METHODS.GET),
    GET_STATS: new Endpoint("investment/managedata/stats/", HTTP_METHODS.GET),
    DELETE_MANAGE_DATA_K1: new Endpoint(
      "investment/managedata",
      HTTP_METHODS.DEL
    ),
    EDIT_MANAGE_DATA_K1: new Endpoint(
      "investment/managedata",
      HTTP_METHODS.PATCH
    ),
    LIST_PROPERTY: new Endpoint(
      "investment/properties/dropdown",
      HTTP_METHODS.GET
    ),
    LIST_INVESTOR: new Endpoint("investment/dropdown-inv", HTTP_METHODS.GET),
    INVESTOR_LIST_PROPERTY: new Endpoint(
      "investment/dropdown-inv",
      HTTP_METHODS.GET
    ),
    EXPORT_PDF_CSV: new Endpoint("investment/managedata", HTTP_METHODS.GET)
  },
  DASHBOARD: {
    GET_STATS: new Endpoint("admin/dashboard/data-card", HTTP_METHODS.GET),
    GET_RECENT_INVESTOR: new Endpoint(
      "admin/dashboard/recent-investor",
      HTTP_METHODS.GET
    ),
    GET_RECENT_INVESTMENTS: new Endpoint(
      "admin/dashboard/recent-investment",
      HTTP_METHODS.GET
    ),
    GET_RECENT_DISTRIBUTIONS: new Endpoint(
      "admin/dashboard/recent-distribution",
      HTTP_METHODS.GET
    ),
    GET_INVESTMENT_BY_PROPERTY: new Endpoint(
      "admin/dashboard/group-by-type",
      HTTP_METHODS.GET
    )
  },
  Document: {
    GET_DOCUMENTS_LIST: new Endpoint("documents/list", HTTP_METHODS.GET)
  },
  ROLE: {
    GET_ROLES: new Endpoint("subadmin/role", HTTP_METHODS.GET),
    GET_ROLE_MODULES: new Endpoint("subadmin/role-module/", HTTP_METHODS.GET),
    ADD_ROLE: new Endpoint("subadmin/role/", HTTP_METHODS.POST),

    GET_ROLE: new Endpoint("subadmin/role", HTTP_METHODS.GET),
    EDIT_ROLE: new Endpoint("subadmin/role", HTTP_METHODS.PATCH),
    DELETE_ROLE: new Endpoint("subadmin/role", HTTP_METHODS.DEL)
  },
  SubAdmin: {
    ADD_SUB_ADMIN: new Endpoint("admin/subadmin/", HTTP_METHODS.POST),
    LIST_SUB_ADMIN: new Endpoint("subadmin/sub-admin", HTTP_METHODS.GET),
    GET_ROLES: new Endpoint("subadmin/role-dropdown/", HTTP_METHODS.GET),
    PASSWORD_RESET_SUB_ADMIN: new Endpoint(
      "subadmin/sub-admin/verify",
      HTTP_METHODS.PATCH
    ),
    DELETE_SUB_ADMIN: new Endpoint("subadmin/sub-admin", HTTP_METHODS.DEL),
    GET_ACCESS_ROLES: new Endpoint("subadmin/role", HTTP_METHODS.GET),
    EDIT_SUB_ADMIN: new Endpoint("subadmin/sub-admin", HTTP_METHODS.PATCH)
  }
};
