import { API, NetworkManager } from "./core";

export class PropertiesService {
  static async getStats() {
    const instance = new NetworkManager(API.LIST_PROPERTIES.GET_STATS);
    return await instance.httpRequest();
  }

  static async getProperties(params) {
    const instance = new NetworkManager(
      API.LIST_PROPERTIES.GET_PROPERTIES,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async get_Pdf_Or_Csv(params) {
    const instance = new NetworkManager(
      API.LIST_PROPERTIES.EXPORT_PDF_CSV,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async dropdownOptions() {
    const instance = new NetworkManager(API.DROPDOWN_OPTIONS.OPTIONS);
    return await instance.httpRequest();
  }

  static async addProperties(payload) {
    const instance = new NetworkManager(
      API.LIST_PROPERTIES.ADD_PROPERTIES,
      payload
    );
    return await instance.httpRequest();
  }
  static async editProperties(payload, id) {
    const instance = new NetworkManager(
      API.LIST_PROPERTIES.EDIT_PROPERTIES,
      payload,
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
  static async deleteProperty(params) {
    const instance = new NetworkManager(
      API.LIST_PROPERTIES.DELETE_PROPERTIES,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async deletePropertyNoi(params) {
    const instance = new NetworkManager(API.LIST_PROPERTIES.DELETE_NOI, {}, [
      params
    ]);
    return await instance.httpRequest();
  }
  static async propertyDetailGraph(params) {
    const instance = new NetworkManager(
      API.LIST_PROPERTIES.PROPERTY_DETAILS_GRAPHS,
      {},
      [params]
    );
    return await instance.httpRequest();
  }
}
