import React from "react";
import styles from "./Stepper.module.scss";
import { useSelector } from "react-redux";
import { Grid, Typography } from "@material-ui/core";
import { AppDispatcher } from "redux/index";

const Wizard = ({ isEditing, status = "" }) => {
  const { investmentActiveStep } = useSelector((store) => store.app);

  const steps = isEditing
    ? status === "Draft"
      ? [
          {
            title: "Basic",
            key: 0
          },
          {
            title: "Investor",
            key: 1
          },
          {
            title: "Loan",
            key: 2
          },
          {
            title: "Refinance",
            key: 3
          },
          { title: "Distributions", key: 4 },
          {
            title: "Preview",
            key: 5
          }
        ]
      : [
          {
            title: "Basic",
            key: 0
          },
          {
            title: "Investor",
            key: 1
          },
          {
            title: "Loan",
            key: 2
          },
          {
            title: "Refinance",
            key: 3
          },
          {
            title: "Preview",
            key: 5
          }
        ]
    : [
        {
          title: "Basic",
          key: 0
        },
        {
          title: "Investor",
          key: 1
        },
        {
          title: "Loan",
          key: 2
        },
        {
          title: "Refinance",
          key: 3
        },
        { title: "Distributions", key: 4 },
        {
          title: "Preview",
          key: 5
        }
      ];

  return (
    <>
      <Grid container style={{ marginTop: "53px" }}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" wrap="nowrap">
            {steps.map((item, index) => {
              return (
                <div
                  className={styles.stepCtr}
                  key={`steppper-${index}`}
                  style={isEditing ? { cursor: "pointer" } : {}}
                  onClick={
                    isEditing
                      ? () => {
                          AppDispatcher.setAddInvestmentStep(index);
                        }
                      : () => {}
                  }>
                  <div
                    className={
                      investmentActiveStep >= item.key
                        ? styles.idCtrActive
                        : styles.idCtr
                    }>
                    {item.key + 1}
                  </div>
                  <div className={styles.textCtr}>
                    <Typography
                      className={
                        investmentActiveStep >= item.key
                          ? styles.stepTextActive
                          : styles.stepText
                      }>
                      {item.title}
                    </Typography>
                  </div>
                  <div
                    className={
                      investmentActiveStep >= item.key + 1
                        ? styles.borderActive
                        : styles.border
                    }></div>
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Wizard;
