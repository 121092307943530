import React from "react";
import { makeStyles } from "@material-ui/core";

const useBtnStyle = makeStyles(() => ({
  mainContainer: {
    backgroundColor: "#5775E6",
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 50,
    paddingRight: 50,
    fontFamily: "Inter Regular",
    fontWeight: 600,
    fontSize: 18,
    color: "#fff",
    borderRadius: 4,
    cursor: "pointer",
    border: "none",
    width: "200px",
    whiteSpace: "nowrap",
    textAlign: "center",
    marginLeft: "10px"
  }
}));

const AddBtn = ({
  text,
  style,
  onClick,
  disabled,
  extraClasses = "",
  ...props
}) => {
  const classes = useBtnStyle();

  return (
    <button
      disabled={disabled}
      style={style ? style : null}
      className={`${classes.mainContainer} ${extraClasses}`}
      onClick={onClick}
      {...props}>
      {text}
    </button>
  );
};

export default AddBtn;
