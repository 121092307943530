import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./AppTable.module.css";
import DotsMenu from "../../assets/icons/dotsMenu.svg";
import { Menu, MenuItem, Button, Tooltip } from "@material-ui/core";
import { AppDispatcher } from "../../redux/index";
import DateFormatter from "../../helpers/formatDate";
import styles1 from "../../pages/private/investments/investments.module.css";
import SearchInput from "components/Search";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import { InvestmentListService } from "network/investment_ListService";
import { EntityService } from "network/entityService";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Toast from "components/toast";
import CloseIcon from "assets/icons/cross.svg";
import { InvestorServices } from "network/investorServices";
import { FilterStatus, truncate } from "helpers/Common";
import noInvestors from "assets/emptyState/noInvestors.svg";
import Illustrations from "components/illustration/Illustrations";
import NewTable from "components/NewTable/NewTable";
import ManageColumn from "components/ManageColumns";
import MoreFilterContainer from "components/MoreFilterContainer/MoreFilterContainer";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import filterIcon from "../../assets/icons/Filter.svg";
import BluefilterIcon from "../../assets/icons/blueFilter.svg";
import DotFilterIcon from "../../assets/icons/DotFilter.svg";
import CSV from "../../assets/icons/NewCardSvg/CSV.svg";
import FWDEmail from "../../assets/icons/NewCardSvg/FWDEmail.svg";
import FrwrdAsEmail from "components/ForwarsAsEmail";
import LightTooltip from "components/LightTooltip";
import NewDropDown from "components/NewDropDown";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
import { numFormatter } from "helpers";
import { useSelector } from "react-redux";

const addedViaList = [
  {
    label: "Added Via",
    value: "added_via"
  },
  {
    label: "Admin",
    value: "Admin"
  },
  {
    label: "Sign Up",
    value: "SignUp"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "Pdf",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const TableHeaders = [
  {
    id: "investor_name",
    name: "Investor Name"
  },

  {
    id: "investor_entity_name",
    name: "Entity Name"
  },
  {
    id: "investment_name",
    name: "Investment Name"
  },
  {
    id: "added_via",
    name: "Added Via"
  },
  {
    id: "registration_date",
    name: "Registration Date"
  },
  {
    id: "amount_invested",
    name: "Amount Invested"
  },
  {
    id: "status",
    name: "Status"
  }
];

function AppTable({ permissions }) {
  const history = useHistory();
  const {
    retainFilter,
    retainManageColumn,
    page,
    pageResetValue,
    pageSize,
    count
  } = useSelector((store) => store.app);
  const { checked, searchQuery, addedVia, status } = retainFilter;

  const [THeaders, setTHeaders] = useState(
    retainManageColumn.length ? retainManageColumn : TableHeaders
  );
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [Export, setExport] = useState("exportList");
  const [tempr, setTempr] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [investorStatuss, setInvestorStatuss] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [isFilterActive, setFilterActive] = useState(false);
  const [expandIndex, setExpandIndex] = useState(-1);
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);

  const mappingObject = {
    "Investment Name": "investment_id",
    "Entity Name": "entity_id"
  };

  const handleApply = (data) => {
    setShowFilter(false);
    AppDispatcher.retainFilters({ ...retainFilter, checked: [...data] });
  };

  const handleClear = () => {
    setShowFilter(false);
    AppDispatcher.retainFilters({ ...retainFilter, checked: [] });
  };

  const setQuery = (val) => {
    AppDispatcher.retainFilters({ ...retainFilter, searchQuery: val });
    AppDispatcher.retainPageValue(0);
  };

  const handleClick = (event, item) => {
    AppDispatcher.setInvestorId(item);
    setAnchorEl(event.currentTarget);
  };

  const handleChangeStatus = () => {
    history.push("/u/investors/changeStatus");
  };

  const handleEdit = () => {
    history.push("/u/investors/editInvestor");
  };

  const handleCellClick = (item) => {
    AppDispatcher.setInvestorDetailID(item.id);
    AppDispatcher.setInvestorStatus(item.status);

    history.push("/u/investors/detail");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleClick = () => {
    setShowFilter(!showFilter);
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestmentName([
      `?query={id,name}${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getEntityNames = async (page = 1, search = "") => {
    const response = await EntityService.getEntityListByRole({
      query: `{id,name}`,
      entity_type: "INVESTOR",
      page,
      ...(search ? { search } : {})
    });
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setInvestorStatuss(res.data.INVESTOR_STATUS);
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const ChipsComponent = () => {
    let chipsArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        chipsArray.push(newObj);
      });
    });

    return (
      <div className={styles1.chipsCtr}>
        {chipsArray.length > 0
          ? chipsArray.map((item, index) => {
              return (
                <div key={index} className={styles1.chips}>
                  <div className={styles1.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles1.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const handleEntityClick = (e, type, entityId) => {
    e.stopPropagation();
    AppDispatcher.setEntitiesDetailData({
      id: entityId,
      entity_type: "INVESTOR"
    });
    history.push("/u/entities/detail");
  };

  const handleInvestmentClick = (e, type, id) => {
    e.stopPropagation();
    AppDispatcher.setInvestmentDetailID(id);
    history.push("/u/investments/detail");
  };

  const handleDeleteInvestor = () => {
    history.push("/u/investors/deleteInvestor");
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let keyData = newCheckedData?.[keyIndex]?.value;
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    AppDispatcher.retainFilters({
      ...retainFilter,
      checked: [...newCheckedData]
    });
  };

  const getInvestorsData = async (pageNum, isAppend = false, all = false) => {
    setLoading(true);
    let url = `?status=${status}`;
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (addedVia !== "added_via") {
      url += `&added_via=${addedVia}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    url += `&page=${pageNum}&page_size=${all ? all : pageSize}`;
    const response = await InvestorServices.getInvestorsList([url]);
    if (response.success) {
      if (isAppend) {
        setTempr([...tempr, ...response.data.results]);
      } else {
        setTempr(response.data.results);
        AppDispatcher.retainCount(response.data.count);
      }
    }
    setLoading(false);
  };

  const changePage = (val) => {
    if (val > page) {
      AppDispatcher.retainPage(val);
      getInvestorsData(val, true);
    }
    AppDispatcher.retainPageValue(val - 1);
  };

  const changePageSize = (val) => {
    AppDispatcher.retainPageSize(val);
    AppDispatcher.retainPageValue(val - 1);
  };

  const setFilterMeta = () => {
    setFilterState([
      {
        key: "Investment Name",
        value: getInvestmentName
      },
      {
        key: "Entity Name",
        value: getEntityNames
      }
    ]);
  };

  useEffect(() => {
    getOptions();
    setFilterMeta();
  }, []);

  useEffect(() => {
    if (page > 1) {
      getInvestorsData(1, false, (pageResetValue + 1) * pageSize);
      AppDispatcher.retainPage(pageResetValue + 1);
    } else {
      getInvestorsData(1);
    }
    setFilterActive(FilterStatus(checked));
  }, [status, searchQuery, checked, pageSize, addedVia]);

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = `?status=${status}`;

    if (emailData) {
      if (preview === "preview") {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `&${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (addedVia !== "added_via") {
      url += `&added_via=${addedVia}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }

    setExport("exportList");
    const response = await InvestorServices.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          // link.target = "_blank";
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  /******************************************************************** */
  /*********************       New Table Data           *****************/
  /*********************************************************************/
  const getTooltipData = (item) => {
    const mainDiv = {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px",
      width: "250px"
    };
    const heading = { fontSize: "16px", fontWeight: 400, color: "#A0A0A0" };
    const Value = { fontSize: "16px", fontWeight: 600, color: "#191919" };
    return (
      <>
        <div style={mainDiv}>
          <h3 style={heading}>Investor ID:</h3>
          <h3 style={Value}>{item?.base_id}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Distribution Received:</h3>
          <h3 style={Value}>{numFormatter(0)}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Distribution Count:</h3>
          <h3 style={Value}>{item?.stats?.total_distribution_count}</h3>
        </div>
      </>
    );
  };

  const Tabledatalist =
    tempr &&
    tempr.map((item, index) => {
      return [
        <LightTooltip key="investor_name" title={getTooltipData(item)}>
          <div
            className={`${styles.text} ${styles.hover}`}
            style={{ cursor: "pointer" }}
            onClick={() => handleCellClick(item)}>
            {truncate(`${item?.first_name} ${item?.last_name}`, 30)}
          </div>
        </LightTooltip>,
        <LightTooltip key="investor_entity_name" title={getTooltipData(item)}>
          <div key="investor_entity_name">
            {item.entity.length > 0 ? (
              <NewDropDown
                index={index}
                item={item?.entity}
                handleCellItemClick={handleEntityClick}
                type="entity"
                expandIndex={expandIndex}
                setExpandIndex={setExpandIndex}
              />
            ) : (
              <div className={styles.text}>-</div>
            )}
          </div>
        </LightTooltip>,

        <div
          key="investment_name"
          className={styles.text}
          style={{ cursor: "pointer" }}
          onClick={() => handleCellClick(item)}>
          {item.investment.length > 0 ? (
            <NewDropDown
              index={index}
              item={item?.investment}
              handleCellItemClick={handleInvestmentClick}
              type="investment"
              expandIndex={expandIndex}
              setExpandIndex={setExpandIndex}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>,
        <LightTooltip key="added_via" title={getTooltipData(item)}>
          <div
            key="added_via"
            className={styles.text}
            style={{ cursor: "pointer" }}>
            {item?.added_via || "-"}
          </div>
        </LightTooltip>,
        <LightTooltip key="registration_date" title={getTooltipData(item)}>
          <div
            key="registration_date"
            className={styles.text}
            style={{ cursor: "pointer" }}
            onClick={() => handleCellClick(item)}>
            {DateFormatter(item?.created_at)}
          </div>
        </LightTooltip>,
        <LightTooltip key="amount_invested" title={getTooltipData(item)}>
          <div
            key="amount_invested"
            className={styles.text}
            style={{ cursor: "pointer" }}
            onClick={() => handleCellClick(item)}>
            {`$${numFormatter(item?.stats?.total_invested_amount)}`}
          </div>
        </LightTooltip>,
        <div
          key="status"
          onClick={() => handleCellClick(item)}
          style={{
            cursor: "pointer",
            minHeight: 28,
            width: 72,
            alignItems: "center",
            backgroundColor:
              item?.status === "Pending"
                ? "#EBEFFF"
                : item?.status == "Active"
                ? "#EDFFEF"
                : "#FCEBEB ",
            borderRadius: 153,
            textAlign: "center",
            fontFamily: "Inter Bold",
            fontSize: 14,
            paddingTop: 4,
            color:
              item?.status == "Active"
                ? "#50E138"
                : item?.status === "Pending"
                ? "#445EBE"
                : "#E13838",
            fontWeight: 700
          }}>
          {item?.status}
        </div>,
        <div
          key="email"
          className={styles.text}
          style={{ cursor: "pointer" }}
          onClick={() => handleCellClick(item)}>
          {item?.email}
        </div>,
        (permissions?.is_update || permissions?.is_delete) && (
          <div key="Action">
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleClick(e, item.id)}>
              <img src={DotsMenu} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  marginTop: 40,
                  boxShadow: "5px 5px 5px #E9E9E9"
                }
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}>
              {permissions?.is_update ? (
                <div>
                  <MenuItem
                    className={styles.menuItem}
                    onClick={handleEdit}
                    style={{
                      fontFamily: "Inter Regular"
                    }}>
                    Edit Investor
                  </MenuItem>
                  <MenuItem
                    onClick={handleChangeStatus}
                    className={styles.menuItem}>
                    Change Status
                  </MenuItem>
                </div>
              ) : null}
              {permissions?.is_delete ? (
                <MenuItem
                  className={styles.menuItemDelete}
                  onClick={handleDeleteInvestor}>
                  Delete
                </MenuItem>
              ) : null}
            </Menu>
          </div>
        )
      ];
    });

  /******************************************************************* */
  /******************************************************************* */
  /******************************************************************* */

  return (
    <>
      <FrwrdAsEmail
        AllHeaders={TableHeaders}
        FrdasEmail={FrdasEmail}
        setFrdasEmail={setFrdasEmail}
        open={frdEmailOpen}
        setOpen={setFrdasEmailOpen}
        clickOnShare={(payload) => {
          getExport_PDF_CSV_Link(payload);
        }}
        clickOnPreview={(payload) => {
          getExport_PDF_CSV_Link(payload, "preview");
        }}
      />

      <div className={styles.tableCtrOuter}>
        <TableTopFilter
          tabletViewCSV={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={CSV}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={ExportList}
                clickMenuItem={(obj, close) => {
                  setExport(obj?.value);
                  close();
                }}
              />
            </div>
          }
          tabletViewFWDEmail={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={FWDEmail}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={frdasEamilList}
                clickMenuItem={(obj, close) => {
                  setFrdasEmail(obj?.value);
                  close();
                }}
              />
            </div>
          }
          filterSection1={[
            <SearchInput searchQuery={searchQuery} setQuery={setQuery} />,
            <CustomSelectFilter
              border={true}
              placeholder="Status"
              items={investorStatuss}
              onChange={(e) => {
                AppDispatcher.retainFilters({
                  ...retainFilter,
                  status: e.target.value
                });
                AppDispatcher.retainPageValue(0);
              }}
              value={status}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Added Via"
              items={addedViaList}
              onChange={(e) => {
                AppDispatcher.retainFilters({
                  ...retainFilter,
                  addedVia: e.target.value
                });
                AppDispatcher.retainPageValue(0);
              }}
              value={addedVia}
            />,
            <Tooltip title="More Filters" arrow>
              <div className={styles.clickable} onClick={onHandleClick}>
                <img
                  src={
                    isFilterActive
                      ? DotFilterIcon
                      : showFilter
                      ? BluefilterIcon
                      : filterIcon
                  }
                  alt="alt"
                  layout="fixed"
                  width={45}
                  height={45}
                />
              </div>
            </Tooltip>,

            showFilter && (
              <>
                <MoreFilterContainer
                  open={showFilter}
                  checked={checked}
                  handleClose={onHandleClick}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filters={filterState}
                />
              </>
            )
          ]}
          filterSection2={[
            <ManageColumn
              AllHeaders={TableHeaders}
              checkedHeaders={
                retainManageColumn?.length ? retainManageColumn : TableHeaders
              }
              setTableHeaders={setTHeaders}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Forward as Email"
              items={frdasEamilList}
              value={FrdasEmail}
              onChange={(e) => setFrdasEmail(e.target.value)}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Export"
              items={ExportList}
              value={Export}
              onChange={(e) => setExport(e.target.value)}
            />
          ]}
        />

        <ChipsComponent />
        <NewTable
          loading={loading}
          Header={[
            ...THeaders,
            (permissions?.is_update || permissions?.is_delete) && {
              id: "Action",
              name: "Action"
            }
          ]}
          tableData={Tabledatalist}
          pageSize={pageSize}
          changePage={changePage}
          changePageSize={changePageSize}
          count={count}
          pageResetValue={pageResetValue}
          labelRowsPerPage="Total No Of Investors Per Page"
          Illustration={
            <Illustrations
              src={noInvestors}
              heading="No Investors"
              subHeading="There are no Investors added yet."
            />
          }
        />
      </div>
    </>
  );
}

export default AppTable;
