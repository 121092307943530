import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../../EntityDetail.module.scss";

function InvestmentToolTipData({ data = [] }) {
  return (
    <>
      {data?.map((value, indx) => {
        return (
          <Grid
            style={{ width: "320px" }}
            // style={{ padding: "2px" }}

            spacing={1}
            key={indx}
            justifyContent="space-between"
            container>
            <Grid item>
              <Typography className={Styles.toolTipData1}>
                {value[0]}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={Styles.toolTipData2}>
                {value[1]}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

export default InvestmentToolTipData;
