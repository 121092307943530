import { makeStyles } from "@material-ui/core";
import { truncate } from "helpers/Common";
import React from "react";

const useStyle = makeStyles({
  hide: {
    display: "none"
  },
  span: {
    fontWeight: 600,
    color: "#5775E5",
    paddingLeft: 10
  },
  show: { display: "block", maxHeight: 160, overflow: "auto" },
  listItems: {
    padding: 6,
    cursor: "pointer",
    "&:hover": {
      background: "#f6f8fd",
      width: "auto"
    }
  }
});

function NewDropDown({
  index,
  item,
  handleCellItemClick,
  type,
  expandIndex,
  setExpandIndex
}) {
  const classes = useStyle();
  return (
    <div
      onMouseEnter={() => setExpandIndex(index)}
      onMouseLeave={() => setExpandIndex(-1)}
      title={item?.[0]?.name?.length > 12 && item?.[0]?.name}>
      <div
        className={expandIndex === index && classes.hide}
        style={{ padding: 6 }}
        onClick={() => setExpandIndex(index)}>
        {truncate(item?.[0]?.name || "-", 20)}
        <span className={classes.span}>
          {item?.length <= 1 ? "" : `+${item?.length - 1}`}
        </span>
      </div>

      <div className={expandIndex === index ? classes.show : classes.hide}>
        {item?.map((item, i) => (
          <div
            key={i}
            onClick={(e) => handleCellItemClick(e, type, item?.id)}
            title={item?.name?.length > 12 && item?.name}
            className={classes.listItems}>
            {truncate(item?.name, 20)}
          </div>
        ))}
      </div>
    </div>
  );
}

export default NewDropDown;
