import React from "react";
import { Grid, makeStyles, Typography, Box } from "@material-ui/core";
// import userprofile from "../../../../assets/icons/userprofile.svg";
import styles from "../profile.module.scss";
// import FormDial from "../editprofile/Dialog/editprofile";
import PasswordChanges from "../editprofile/Dialog/changepassword";
import FormDial from "../editprofile/Dialog/editprofile";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  mainContainer: {}
}));

const Card = ({ data, updateData, detailType, loading = false }) => {
  const classes = useStyles();

  const skeleton = (values) => {
    return !loading ? values : <Skeleton width={150} variant="text" /> || "-";
  };

  return (
    <>
      {detailType === "personal" ? (
        <div
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)"
          }}>
          <Grid container className={classes.mainContainer}>
            <Grid item xs={12}>
              <div className={`${styles.bground} ${styles.section}`}>
                <Box
                  padding={"20px"}
                  style={{ backgroundColor: "#FFFF", borderRadius: "10px" }}>
                  <div className={`${styles.card1} ${styles.marginBottom20}`}>
                    <div className={styles.cardheading}>
                      <Typography
                        align="left"
                        style={{ fontSize: 18, fontWeight: 700 }}>
                        Personal Details
                      </Typography>
                    </div>
                    <FormDial
                      detailType={detailType}
                      updateRecord={updateData}
                      data={data}
                    />
                  </div>
                  <div className={styles.details}>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      style={{ marginBottom: 20 }}>
                      <Grid item xs>
                        <label className={styles.lineStyle}>Simplee ID</label>
                      </Grid>
                      <Grid item xs className={styles.detailsValues}>
                        {skeleton(data.simpleeID) !== null
                          ? skeleton(data.simpleeID)
                          : "-"}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      style={{ marginBottom: 20 }}>
                      <Grid item xs>
                        <label className={styles.lineStyle}>Name</label>
                      </Grid>
                      <Grid item xs className={styles.detailsValues}>
                        {skeleton(data.first_name) !== null
                          ? skeleton(data.first_name + " " + data.last_name)
                          : "-"}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      style={{ marginBottom: 20 }}>
                      <Grid item xs>
                        <label className={styles.lineStyle}>Phone Number</label>
                      </Grid>
                      <Grid item xs className={styles.detailsValues}>
                        {skeleton(data.phone_number) !== null &&
                        data.country_code !== null
                          ? skeleton(`${data.country_code}${data.phone_number}`)
                          : "-"}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      style={{ marginBottom: 20 }}>
                      <Grid item xs>
                        <label className={styles.lineStyle}>Email ID:</label>
                      </Grid>
                      <Grid item xs className={styles.detailsValues}>
                        {data.email !== null ? skeleton(data.email) : "-"}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      style={{ marginBottom: 20 }}>
                      <Grid item xs>
                        <label className={styles.lineStyle}>Password</label>
                      </Grid>

                      <Grid
                        className={styles.cardbutton}
                        container
                        item
                        xs
                        direction="column">
                        <Grid
                          container
                          justifyContent="flex-end"
                          className={styles.detailsValuesPass}>
                          {skeleton("*********")}
                        </Grid>
                        <Grid
                          container
                          justifyContent="flex-end"
                          className={styles.passwordchangestyles}>
                          {skeleton(<PasswordChanges />)}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid container className={classes.mainContainer}>
            <Grid item xs={12}>
              <div className={`${styles.bground} ${styles.section}`}>
                <Box
                  padding={"20px"}
                  style={{ backgroundColor: "#FFFF", borderRadius: "10px" }}>
                  <div className={`${styles.card1} ${styles.marginBottom20}`}>
                    <div className={styles.cardheading}>
                      <Typography
                        align="left"
                        style={{ fontSize: 18, fontWeight: 700 }}>
                        Access Details
                      </Typography>
                    </div>
                    <Grid style={{ height: "65px" }}></Grid>
                  </div>
                  <div className={styles.details}>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      style={{ marginBottom: 20 }}>
                      <Grid item xs>
                        <label className={styles.lineStyle}>
                          Access Roles:
                        </label>
                      </Grid>
                      <Grid item xs className={styles.detailsValues}>
                        {skeleton(data?.role) || "-"}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      style={{ marginBottom: 20 }}>
                      <Grid item xs>
                        <label className={styles.lineStyle}>Description:</label>
                      </Grid>
                      <Grid item xs className={styles.detailsValues}>
                        {skeleton(data?.description) || "-"}
                      </Grid>
                    </Grid>
                  </div>
                </Box>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
export default Card;
