import Illustrations from "components/illustration/Illustrations";
import React from "react";
import PropertiesAccordionTable from "../../addInvestment/steps/step7/NewComponents/PropertiesAccordian";
import HeadingContainer from "../component/HeadingContainer";
import noProperties from "assets/emptyState/noProperties.svg";
import { Grid } from "@material-ui/core";

const Properties = ({ data }) => {
  return (
    <Grid
      container
      spacing={4}
      style={{ marginTop: "20px", marginBottom: "54px" }}>
      {/* Property */}
      <Grid item xs={12}>
        <HeadingContainer Title="Property Information" dropShadow>
          {data?.properties?.length ? (
            <PropertiesAccordionTable data={data?.properties} zip />
          ) : (
            <Grid item xs={12}>
              <Illustrations
                src={noProperties}
                subHeading={`No added Properties.`}
              />
            </Grid>
          )}
        </HeadingContainer>
      </Grid>
    </Grid>
  );
};

export default Properties;
