import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import styles from "./AccordianTable.module.scss";
import downPA from "assets/icons/downPA.svg";
import upPA from "assets/icons/upPA.svg";
import formatDate from "helpers/formatDate";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import TaggedPropertyTable from "./TaggedPropertyTable";
import formatCurrency from "helpers/formatCurrency";
import { truncate } from "helpers/Common";
import LightTooltip from "components/LightTooltip";

const loanType = {
  fixedratemortgage: "Fixed Rate Mortgage",
  adjustratemortgage: "Adjustable Rate Mortgage",
  fedhousadmin: "Federal Housing Administration",
  convenloan: "Conventional loan",
  vetaffloan: "Veteran Affairs (VA) loan",
  usdaloan: "U.S. Department of Agriculture (USDA) loan",
  jumboloan: "Jumbo loan",
  constloan: "Construction loan",
  ballonloan: "Balloon loans",
  piggyloan: "Piggybank loan",
  intonmortgages: "Interest-only mortgages"
};

const width_1 = "167px";
const width_2 = "104px";
const width_3 = "100px";
const width_4 = "108px";
const width_5 = "99px";
const width_6 = "162px";
const width_7 = "102px";
const width_8 = "131px";
const width_9 = "72px";
const width_10 = "93px";
const width_11 = "93px";

const LoanAccordionTable = ({ data }) => {
  const [accordianHeight, setAccordianHeight] = useState([]);

  const getTooltipData = (item) => {
    const mainDiv = {
      display: "flex",
      gap: "20px",
      padding: "12px",
      width: "fit-content"
    };
    const heading = {
      fontSize: "16px",
      fontWeight: 400,
      color: "#A0A0A0",
      minWidth: "180px"
    };
    const Value = {
      fontSize: "16px",
      fontWeight: 600,
      minWidth: "150px",
      color: "#191919"
    };
    return (
      <>
        <div style={mainDiv}>
          <h3 style={heading}>Notes:</h3>
          <h3 style={Value}>{item?.description ?? "-"}</h3>
        </div>
      </>
    );
  };

  const handleAccordian = (index) => {
    let height = { ...accordianHeight };
    height[index] = !height[index];
    setAccordianHeight(height);
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  return (
    <Box className={styles.container} style={{ paddingLeft: 0 }}>
      {/*************************Table******************************* */}

      <div className={styles.tableCtr}>
        <div className={styles.headerCtr} style={{ paddingLeft: 20 }}>
          <div className={styles.fieldHeder} style={{ width: width_1 }}>
            <Typography className={styles.fieldHeadingtext}>
              Outstanding Loan
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_2 }}>
            <Typography className={styles.fieldHeadingtext}>
              Loan Type
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_3 }}>
            <Typography className={styles.fieldHeadingtext}>Notes</Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_4 }}>
            <Typography className={styles.fieldHeadingtext}>
              Loan Amount
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_5 }}>
            <Typography className={styles.fieldHeadingtext}>
              Bank Name
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_6 }}>
            <Typography className={styles.fieldHeadingtext}>
              Loan Initiation Date
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_7 }}>
            <Typography className={styles.fieldHeadingtext}>
              Debt Service
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_8 }}>
            <Typography className={styles.fieldHeadingtext}>
              Last Updated On
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_9 }}>
            <Typography className={styles.fieldHeadingtext}> Status</Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_10 }}>
            <Typography className={styles.fieldHeadingtext}>
              Documents
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_11 }}>
            <Typography className={styles.fieldHeadingtext}></Typography>
          </div>
        </div>

        {data?.map((item, index) => {
          return (
            <div
              key={index}
              className={
                accordianHeight[index] === true
                  ? styles.mainTest
                  : styles.mainTest1
              }>
              <div
                className={styles.bodyCtr}
                key={index}
                style={{ paddingLeft: 20 }}>
                <div className={styles.fieldBody} style={{ width: width_1 }}>
                  <Typography
                    className={`${styles.fieldBodyText} ${styles.clickable}`}>
                    {item?.outstanding
                      ? formatCurrency(item?.outstanding)
                      : "-"}
                  </Typography>
                </div>
                <div
                  title={loanType?.[item?.type]}
                  className={styles.fieldBody}
                  style={{ width: width_2 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.type ? truncate(loanType?.[item?.type], 12) : "-"}
                  </Typography>
                </div>
                <div
                  className={styles.fieldBody}
                  style={{ width: width_3, cursor: "pointer" }}>
                  <LightTooltip title={getTooltipData(item)}>
                    <Typography className={styles.fieldBodyText}>
                      {item?.description
                        ? truncate(item?.description, 16)
                        : "-"}
                    </Typography>
                  </LightTooltip>
                </div>
                <div className={styles.fieldBody} style={{ width: width_4 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.amount ? formatCurrency(item?.amount) : "-"}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_5 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.bank ? truncate(item?.bank, 15) : "-"}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_6 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.initiation ? formatDate(item?.initiation) : "-"}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_7 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.debtservice
                      ? formatCurrency(item?.debtservice)
                      : "-"}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_8 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.last_updated_on
                      ? formatDate(item?.last_updated_on)
                      : "-"}
                  </Typography>
                </div>

                <div className={styles.fieldBody} style={{ width: width_9 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.status ? (
                      <div
                        style={{
                          width: "fit-content",
                          height: 30,
                          borderRadius: 153,
                          padding: "4px 14px 4px 14px",
                          backgroundColor:
                            item.status == "Active" ? "#EDFFEF" : "#FCEBEB",
                          color:
                            item.status == "Active" ? "#55A55E" : "#E13838",
                          fontFamily: "Inter Bold",
                          fontWeight: 600,
                          fontSize: 14
                        }}>
                        {item?.status}
                      </div>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_10 }}>
                  <DocumentsUpload
                    editable={false}
                    listData={formatDocsData(item?.documents || [])}
                  />
                </div>

                <div
                  className={styles.accordianIconDiv}
                  style={{ width: width_11 }}>
                  <div
                    onClick={() => handleAccordian(index)}
                    className={styles.accordianArrowImg}>
                    <img
                      alt="icon"
                      src={accordianHeight[index] === true ? upPA : downPA}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  borderLeft: "6px solid #4D69D0 ",
                  borderRadius: "0px 6px",
                  display: `${
                    accordianHeight[index] === true ? "block" : "none"
                  } `
                }}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginBottom: "25px"
                  }}>
                  <Grid container item xs={12}>
                    {/* tagged Property */}
                    <Grid item xs={12}>
                      <div className={styles.accordianTitle}>
                        <h4>Tagged Properties</h4>
                        <span>{item?.loan_property?.length || "0"}</span>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TaggedPropertyTable
                        Headingdata={[
                          { heading: "Property Name" },
                          { heading: "Type" },
                          { heading: "Area/Units" },
                          { heading: "Occupancy" },
                          { heading: "Year of Build" },
                          { heading: "Address" },
                          { heading: "ZIP" },
                          { heading: "Status" }
                        ]}
                        mapArray={
                          item?.loan_property?.length ? item?.loan_property : []
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          );
        })}
        {data?.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
              backgroundColor: "#fff",
              padding: 20
            }}>
            No Properties Found
          </div>
        )}
      </div>
      {/* ******************************************************** */}
    </Box>
  );
};

export default LoanAccordionTable;
