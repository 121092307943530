import { makeStyles } from "@material-ui/core";
import Checkbox from "components/StyledComponents/AppCheckbox";
import React from "react";

const useStyles = makeStyles(() => ({
  mainContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column"
  },
  subContainer: {
    display: "flex",
    marginBottom: 10,
    justifyContent: "flex-start"
  },
  headerItem: {
    width: 138,
    boxSizing: "border-box",
    fontFamily: "Inter Bold",
    fontSize: 16,
    fontWeight: 600,
    color: "#191919",
    paddingBottom: "10px",
    borderBottom: "1px solid #A0A0A0"
  },
  listItemContainer: {
    width: "100%",
    display: "flex",
    backgroundColor: "#FFFFFF",
    boxShadow: "2px 20px 30px rgba(0, 0, 0, 0.02)",
    marginBottom: 2
  },
  firstListItemChild: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    width: 299,
    marginRight: 10,
    marginLeft: 20,
    textTransform: "capitalize"
  },
  listItemChild: {
    width: 128,
    marginRight: 10
  },
  error: {
    color: "#E13838",
    fontSize: "0.75rem",
    marginTop: 3
  }
}));

const AssignPermissions = ({ modules, permissions, formik }) => {
  const classes = useStyles();

  const togglePermissionSelect = (index, permission) => {
    const isPermissionGranted =
      formik.values.modules[index].permissions[permission];
    if (permission === "is_retrieve") {
      if (isPermissionGranted) {
        formik.setFieldValue("viewPerm", formik.values.viewPerm - 1);
      } else {
        formik.setFieldValue("viewPerm", formik.values.viewPerm + 1);
      }
    }
    if (!isPermissionGranted) {
      ["is_create", "is_update", "is_delete"].includes(permission)
        ? formik.setFieldValue(
            `modules[${index}].permissions[${"is_retrieve"}]`,
            !isPermissionGranted
          )
        : null;
    }

    if (isPermissionGranted && permission === "is_retrieve") {
      formik.setFieldValue(
        `modules[${index}].permissions[${"is_update"}]`,
        !isPermissionGranted
      );
      formik.setFieldValue(
        `modules[${index}].permissions[${"is_delete"}]`,
        !isPermissionGranted
      );
      formik.setFieldValue(
        `modules[${index}].permissions[${"is_create"}]`,
        !isPermissionGranted
      );
    }

    formik.setFieldValue(
      `modules[${index}].permissions[${permission}]`,
      !isPermissionGranted
    );
  };

  return (
    <div className={classes.mainContainer}>
      <div className={classes.subContainer}>
        <div
          className={classes.headerItem}
          style={{
            paddingLeft: 20,
            width: 329
          }}>
          Modules
        </div>
        <div className={classes.headerItem}>View</div>
        <div className={classes.headerItem}>Add</div>
        <div className={classes.headerItem}>Update</div>
        <div className={classes.headerItem}>Delete</div>
      </div>
      {modules.map((module, index) => (
        <div key={module.value} className={classes.listItemContainer}>
          <div className={classes.firstListItemChild}>
            {module?.label?.toLowerCase()}
          </div>
          {permissions.map((permission) => (
            <div key={permission.value} className={classes.listItemChild}>
              <Checkbox
                isOn={
                  formik?.values?.modules[index]?.permissions[permission.value]
                }
                onToggle={() => togglePermissionSelect(index, permission.value)}
                style={{
                  padding: "14px 0 10px 0",
                  cursor: "pointer"
                }}
              />
            </div>
          ))}
        </div>
      ))}
      {formik.errors.viewPerm && formik.touched.viewPerm && (
        <div className={classes.error}>{formik.errors.viewPerm}</div>
      )}
    </div>
  );
};

export default AssignPermissions;
