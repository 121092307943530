import React from "react";
import { Grid } from "@material-ui/core";
import HeadingContainer from "../../component/HeadingContainer";
import LoanAccordionTable from "components/Common/LoanAccordian/LoanAccordianTable";
import Illustrations from "components/illustration/Illustrations";
import noLoans from "assets/emptyState/noLoans.svg";
import RefinanceAccordionTable from "components/Common/RefinanceAccordian/RefinanceAccordianTable";

const LoanInfo = ({ data }) => {
  return (
    <Grid
      container
      spacing={4}
      style={{ marginTop: "20px", marginBottom: "54px" }}>
      {/* Loan */}
      <Grid item xs={12}>
        <HeadingContainer Title="Loan Information" dropShadow>
          {data?.loan?.length ? (
            <LoanAccordionTable data={data?.loan} />
          ) : (
            <Grid item xs={12}>
              <Illustrations src={noLoans} subHeading={`No added Loans.`} />
            </Grid>
          )}
        </HeadingContainer>
      </Grid>

      {/* Refinance */}
      <Grid item xs={12}>
        <HeadingContainer Title="Refinances" dropShadow>
          {data?.refinances?.length ? (
            <RefinanceAccordionTable data={data?.refinances} />
          ) : (
            <Grid item xs={12}>
              <Illustrations src={noLoans} subHeading={`No added Refinance.`} />
            </Grid>
          )}
        </HeadingContainer>
      </Grid>
    </Grid>
  );
};

export default LoanInfo;
