import { Box } from "@material-ui/core";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Table from "./components/Table";
import { useHistory } from "react-router-dom";
import styles1 from "../../../pages/private/investments/investments.module.css";

const Roles = () => {
  const history = useHistory();
  const [permissions, setPermissions] = useState();
  const { userPermissions, user } = useSelector((store) => store.app);

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "ROLES",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(() => {
    checkPermissions();
  }, []);

  return (
    <>
      <HeaderComponent
        heading="Roles"
        description="List Of All Roles Assigned By Admin On Simplee"
        addInvestorBtn={false}
        ExtraButtons={
          permissions?.is_create ? (
            <div className={styles1.addInvestmentButtonContainer}>
              <p
                className={styles1.addInvestmentButtonText}
                onClick={() => history.push("/u/roles/addRole")}>
                + Add Role
              </p>
            </div>
          ) : null
        }
      />
      <Box height={20} />
      <Table permissions={permissions} />
    </>
  );
};

export default Roles;
