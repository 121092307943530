import FieldHeading from "../../../../../../../components/fieldHeading";
import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box
} from "@material-ui/core";
import AppTextFieldWhite from "../../../../../../../components/StyledComponents/AppTextFieldWhite";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
// import Toast from "components/toast";
import { preventNonNumericalInput } from "helpers";
// import DeleteModal from "components/Modals/DeleteModal";
import RedDelete from "../../../../../../../assets/icons/NewCardSvg/RedDelete.svg";
import Toast from "components/toast";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import DeleteModal from "components/Modals/DeleteModal";

const useStyles = makeStyles(() => ({
  basicContainer2: {
    marginTop: 10
  },
  primaryContactText: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0
  },
  text: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#fff",
    height: 20,
    marginBottom: 4
  },
  deleteBtn: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#E13838",
    marginRight: 10,
    cursor: "pointer"
  },
  country_code_error: {
    fontSize: "0.75rem",
    marginTop: 3,
    textAlign: "left",
    fontFamily: "Inter Regular",
    fontWeight: 400,
    lineHeight: 1.66,
    color: "#E13838"
  }
}));

const Contacts = ({
  arrayHelper,
  id,
  formik,
  defaultValue = "Yes",
  ClickonContactsEdit,
  type = false,
  add = false,
  boxType = false
}) => {
  const classes = useStyles();

  const [deleteModal, setDeleteModal] = useState(false);

  const checkDelete = () => {
    if (formik.values.associatedContactsSet[id].id) {
      setDeleteModal(true);
    } else {
      arrayHelper.remove(id);
    }
  };

  const handleChangeEntityFor = (e) => {
    formik.values.associatedContactsSet[id].is_primary =
      e.target.value === "Yes" ? true : false;
  };

  const contactsErrors =
    (formik.errors.associatedContactsSet &&
      formik.errors.associatedContactsSet.length &&
      formik.errors.associatedContactsSet[id]) ||
    {};
  const contactsTouched =
    (formik.touched.associatedContactsSet &&
      formik.touched.associatedContactsSet.length &&
      formik.touched.associatedContactsSet[id]) ||
    {};

  const handleDelete = async () => {
    const res = await AddInvestmentBasicDetailsService.deleteContacts(
      formik.values.associatedContactsSet[id].id
    );
    if (res.success) {
      setDeleteModal(false);
      arrayHelper.remove(id);
      Toast.showSuccessToast(res?.data?.message);
    } else {
      setDeleteModal(false);
      return Toast.showErrorToast(res.error.message?.[0]);
    }
  };

  return (
    <div
      style={
        add
          ? { backgroundColor: "#FAFAFA" }
          : {
              backgroundColor: "#FAFAFA",
              padding: "20px"
            }
      }>
      <Grid container justifyContent="space-between">
        <Box style={add && boxType ? { paddingLeft: "20px" } : null} item>
          <Typography className={classes.primaryContactText}>
            Primary Contact
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              row
              aria-label="gender"
              name="controlled-radio-buttons-group"
              onChange={(e) => handleChangeEntityFor(e)}
              defaultValue={defaultValue}>
              <FormControlLabel
                value="Yes"
                disabled={!ClickonContactsEdit}
                control={<Radio color="secondary" />}
                label={<div className={classes.label}>Yes</div>}
              />
              <FormControlLabel
                value="No"
                disabled={!ClickonContactsEdit}
                control={<Radio color="secondary" />}
                label={<div className={classes.label}>No</div>}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        {id == 0 && type == true ? null : ClickonContactsEdit ? (
          <Grid item>
            <div className={classes.deleteBtn} onClick={checkDelete}>
              <img src={RedDelete} alt="delete" />
            </div>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        style={
          add && boxType
            ? { paddingLeft: "20px" }
            : { backgroundColor: "#FAFAFA" }
        }
        spacing={2}
        className={classes.basicContainer2}>
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="First Name" />
          <AppTextFieldWhite
            disabled={!ClickonContactsEdit}
            id={`associatedContactsSet[${id}].first_name`}
            placeholder="Add First Name"
            variant="standard"
            fullWidth
            value={formik.values.associatedContactsSet[id].first_name}
            onChange={formik.handleChange(
              `associatedContactsSet[${id}].first_name`
            )}
            error={
              contactsTouched.first_name && Boolean(contactsErrors.first_name)
            }
            helperText={contactsTouched.first_name && contactsErrors.first_name}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Last Name" />
          <AppTextFieldWhite
            disabled={!ClickonContactsEdit}
            id={`associatedContactsSet[${id}].last_name`}
            placeholder="Add Last Name"
            variant="standard"
            fullWidth
            value={formik.values.associatedContactsSet[id].last_name}
            onChange={formik.handleChange(
              `associatedContactsSet[${id}].last_name`
            )}
            error={
              contactsTouched.last_name && Boolean(contactsErrors.last_name)
            }
            helperText={contactsTouched.last_name && contactsErrors.last_name}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Company Name" />
          <AppTextFieldWhite
            disabled={!ClickonContactsEdit}
            id={`associatedContactsSet[${id}].company_name`}
            placeholder="Add Company Name"
            variant="standard"
            fullWidth
            value={formik.values.associatedContactsSet[id].company_name}
            onChange={formik.handleChange(
              `associatedContactsSet[${id}].company_name`
            )}
            error={
              contactsTouched.company_name &&
              Boolean(contactsErrors.company_name)
            }
            helperText={
              contactsTouched.company_name && contactsErrors.company_name
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Email ID" />
          <AppTextFieldWhite
            disabled={!ClickonContactsEdit}
            id={`associatedContactsSet[${id}].email`}
            placeholder="Add Email ID"
            variant="standard"
            fullWidth
            value={formik.values.associatedContactsSet[id].email}
            onChange={formik.handleChange(`associatedContactsSet[${id}].email`)}
            error={contactsTouched.email && Boolean(contactsErrors.email)}
            helperText={contactsTouched.email && contactsErrors.email}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid container item xl={3} lg={4} sm={6} spacing={1}>
          <Grid item xs={4}>
            <FieldHeading title="Phone Number" />
            <PhoneInput
              disabled={!ClickonContactsEdit}
              country={"us"}
              placeholder="Code"
              value={formik.values.associatedContactsSet[id].country_code}
              onChange={formik.handleChange(
                `associatedContactsSet[${id}].country_code`
              )}
              error={
                contactsTouched.country_code && contactsErrors.country_code
              }
              helperText={
                contactsTouched.country_code && contactsErrors.country_code
              }
              inputStyle={{
                background: !ClickonContactsEdit ? "#e9e9e9" : "#FFF",
                border: "1px solid #BBBBBB",
                borderRadius: "6px",
                fontSize: "15px",
                width: "100%",
                height: 45,
                marginRight: 10
              }}
              dropdownStyle={{
                width: "550%",
                marginBottom: 30,
                height: 100
              }}
              buttonStyle={{
                borderRadius: "4px"
              }}></PhoneInput>
            <div className={classes.country_code_error}>
              {contactsTouched.country_code && contactsErrors.country_code}
            </div>
          </Grid>

          <Grid item xs={8}>
            <Typography className={classes.text}></Typography>
            <AppTextFieldWhite
              disabled={!ClickonContactsEdit}
              id={`associatedContactsSet[${id}].phone_number`}
              placeholder="Enter Phone Number"
              variant="standard"
              type="tel"
              inputProps={{ maxLength: 12 }}
              onKeyDown={preventNonNumericalInput}
              fullWidth
              style={{ marginLeft: 10 }}
              value={formik.values.associatedContactsSet[id].phone_number}
              onChange={formik.handleChange(
                `associatedContactsSet[${id}].phone_number`
              )}
              error={
                contactsTouched.phone_number &&
                Boolean(contactsErrors.phone_number)
              }
              helperText={
                contactsTouched.phone_number && contactsErrors.phone_number
              }
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
        </Grid>
        <Grid item xl={3} lg={4} sm={6} style={{ paddingLeft: 15 }}>
          <FieldHeading title="Designation" />
          <AppTextFieldWhite
            disabled={!ClickonContactsEdit}
            id={`associatedContactsSet[${id}].designation`}
            placeholder="Add Designation"
            variant="standard"
            fullWidth
            value={formik.values.associatedContactsSet[id].designation}
            onChange={formik.handleChange(
              `associatedContactsSet[${id}].designation`
            )}
            error={
              contactsTouched.designation && Boolean(contactsErrors.designation)
            }
            helperText={
              contactsTouched.designation && contactsErrors.designation
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
      </Grid>

      {boxType
        ? null
        : arrayHelper.form.values.associatedContactsSet.length !== 0 && (
            <Box
              height={"30px"}
              width={"105%"}
              bgcolor={!boxType ? "#FFF" : null}
              mt="20px"
              ml="-20px"
              mb="20px"
            />
          )}
      <DeleteModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
        title="Are You Sure You Want To Delete This Item?"
        description="This will permanently remove all data associated with it from Simplee."
      />
    </div>
  );
};

export default Contacts;
