import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import ProfileImage from "./components/ProfileImage";
import Card from "./components/Card";
import { Endpoint, NetworkManager } from "../../../network/core/index";
import { useSelector } from "react-redux";
// import Loader from "../../../components/Loader/AppLoader";
import Toast from "components/toast";
import style from "./profile.module.scss";

function Profile() {
  const [record, setRecord] = useState({
    first_name: "",
    last_name: "",
    role: "",
    email: "",
    phone_number: "",
    image_url: null,
    country_code: "+1",
    profile_pic_id: "",
    simpleeID: "",
    description: ""
  });

  const [loading, setLoading] = useState(false);
  const { user } = useSelector((store) => store.app);

  const fetchAdminDetails = async () => {
    setLoading(true);
    const instance = new NetworkManager(
      new Endpoint(`admin/profile/${user.id}/`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      setLoading(false);
      let temp = resp.data;
      let tempData = { ...record };
      tempData["first_name"] = temp.first_name;
      tempData["last_name"] = temp.last_name;
      tempData["email"] = temp.email;
      tempData["phone_number"] = temp.phone_number;
      tempData["role"] = temp.role;
      tempData["simpleeID"] = temp.base_id;
      tempData["image_url"] = temp?.profile_image?.file;
      tempData["country_code"] =
        temp.country_code === null ? "+1" : temp.country_code;
      tempData["profile_pic_id"] =
        temp.profile_image === null
          ? temp.profile_image
          : temp.profile_image.id;
      setRecord(tempData);
    } else {
      Toast.showErrorToast("Failed To Load Profile Data");
    }
  };

  const fetchSubAdminDetails = async () => {
    setLoading(true);
    const instance = new NetworkManager(
      new Endpoint(`subadmin/sub-admin/${user.id}`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      setLoading(false);
      let temp = resp?.data?.user;
      let tempData = { ...record };
      tempData["first_name"] = temp?.first_name;
      tempData["last_name"] = temp?.last_name;
      tempData["email"] = temp?.email;
      tempData["phone_number"] = temp?.phone_number;
      tempData["role"] = user.role;
      tempData["simpleeID"] = temp.base_id;
      tempData["description"] =
        resp?.data?.access_role?.access_roles?.description;
      tempData["image_url"] =
        temp?.profile_image === null
          ? temp?.profile_image
          : temp?.profile_image?.file;
      tempData["country_code"] =
        temp?.country_code === null ? "+1" : temp?.country_code;
      tempData["profile_pic_id"] =
        temp?.profile_image === null
          ? temp?.profile_image
          : temp?.profile_image?.id;
      setRecord(tempData);
    } else {
      Toast.showErrorToast("Failed To Load Profile Data");
    }
  };

  useEffect(() => {
    if (user.role === "ADMIN") {
      fetchAdminDetails();
    } else {
      fetchSubAdminDetails();
    }
  }, []);

  return (
    <div>
      <HeaderComponent
        description="Manage your profile settings here"
        heading="My Profile"
      />
      {/* <Typography>Manage your profile settings here</Typography> */}

      <Grid>
        <Grid
          container
          justifyContent="space-around"
          style={{
            background:
              "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)",
            backgroundSize: "100% 240px",
            backgroundRepeat: "no-repeat",
            backgroundClip: "content-box, padding-box",
            paddingTop: "52px"
          }}>
          <Grid
            item
            xs={12}
            style={{
              height: "100px"
            }}></Grid>
          {/* <Grid style={{display:"flex",justifyContent:"space-evenly"}}></Grid> */}
          <Grid className={style.imgBox} item xs={12} sm={2}>
            <ProfileImage data={record} />
          </Grid>
          <Grid
            justifyContent="flex-start"
            className={style.infoContainer}
            container
            lg={9}
            xs={12}>
            <Grid className={style.leftBox} md={6} item xs={12}>
              <Card
                loading={loading}
                data={record}
                detailType="personal"
                updateData={fetchAdminDetails}
              />
            </Grid>

            <Grid
              style={{
                boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)"
              }}
              md={6}
              item
              xs={12}>
              {/* <NotificationProfile /> */}
              <Card
                loading={loading}
                data={record}
                detailType="access"
                updateData={fetchAdminDetails}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Profile;
