import React, { useState } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { Typography, Grid } from "@material-ui/core";
import styles from "../../profile.module.scss";
import { NetworkManager, API } from "../../../../../network/core/index";
import Toast from "../../../../../components/toast";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "../../../../../assets/icons/eye-off.svg";
import Visibility from "../../../../../assets/icons/eye.svg";
import CloseIcon from "../../../../../assets/icons/CloseIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { validationSchemaAdminChangePassword } from "../../../../../helpers/validationSchema";
import AppTextField from "../../../../../components/StyledComponents/AppTextFeild";

const useStyles = makeStyles(() => ({
  imageSrc: {
    height: "12px",
    width: "16px",
    marginRight: "12px",
    marginLeft: "-40px",
    "&:hover": {
      background: "none"
    }
  }
}));

const PasswordChanges = () => {
  const [open, setOpen] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);
  const [isVisible2, setVisible2] = useState(false);
  const classes = useStyles();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: validationSchemaAdminChangePassword,
    onSubmit: async (values) => {
      const payload = {
        current_password: values.oldPassword,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword
      };
      const instance = new NetworkManager(API.PROFILE.CHANGEPASSWORD, payload);
      const resp = await instance.httpRequest();
      if (resp.success) {
        setOpen(false);
        formik.resetForm(); // Reset form after successfull pwd change
        Toast.showSuccessToast("Password Changed Successfully");
      } else {
        Toast.showErrorToast("Password Does Not Match");
      }
    }
  });

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleChangePassword = () => {
    formik.handleSubmit();
  };

  return (
    <div className={styles.changecolor}>
      <div
        style={{
          // marginLeft: 5,
          fontFamily: "Inter Regular",
          color: "#5775E5",
          cursor: "pointer",
          fontWeight: 600,
          paddingLeft: "100px",
          fontSize: "14px"
        }}
        onClick={handleClickOpen}>
        Change Password
      </div>
      {/* </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <Grid
            container
            style={{ padding: 40, width: 458 }}
            justifyContent="center">
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h4">Change Password</Typography>
              </Grid>
              <Grid item>
                <img
                  src={CloseIcon}
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12} style={{ marginTop: 40 }}>
              <Grid item xs={12}>
                <AppTextField
                  id="oldPassword"
                  placeholder="Enter Current Password Here"
                  label="Add Current Password"
                  fullWidth
                  variant="standard"
                  style={{
                    height: 100
                  }}
                  autoComplete="old-password"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.oldPassword &&
                    Boolean(formik.errors.oldPassword)
                  }
                  helperText={
                    formik.touched.oldPassword && formik.errors.oldPassword
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    disableUnderline: true,
                    type: isVisible ? "text" : "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setVisible(!isVisible)}
                          edge="end"
                          className={classes.imageSrc}>
                          {isVisible ? (
                            <img src={Visibility} />
                          ) : (
                            <img src={VisibilityOff} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid container xs={12}>
              <Grid item xs={12}>
                <AppTextField
                  id="newPassword"
                  placeholder="Enter New Password Here"
                  label="Add New Password "
                  variant="standard"
                  fullWidth
                  autoComplete="new-password"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.newPassword &&
                    Boolean(formik.errors.newPassword)
                  }
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    disableUnderline: true,
                    type: isVisible1 ? "text" : "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          style={{ backgroundColor: "#FFFF" }}
                          aria-label="toggle password visibility"
                          onClick={() => setVisible1(!isVisible1)}
                          edge="end"
                          className={classes.imageSrc}>
                          {isVisible1 ? (
                            <img src={Visibility} />
                          ) : (
                            <img src={VisibilityOff} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>

            <Grid container xs={12} style={{ marginTop: 30 }}>
              <Grid item xs={12}>
                <AppTextField
                  id="confirmPassword"
                  placeholder="Confirm Password Here"
                  label="Confirm New Password"
                  variant="standard"
                  fullWidth
                  autoComplete="confirm-password"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    disableUnderline: true,
                    type: isVisible2 ? "text" : "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setVisible2(!isVisible2)}
                          edge="end"
                          className={classes.imageSrc}>
                          {isVisible2 ? (
                            <img src={Visibility} />
                          ) : (
                            <img src={VisibilityOff} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              justifyContent="center"
              direction="row"
              style={{ marginTop: 20 }}>
              <div className={styles.changepasswordbutton}>
                <button
                  className={styles.cancelbtn}
                  type="button"
                  onClick={handleClose}>
                  Cancel
                </button>
                <button
                  type="submit"
                  className={styles.updatebtn}
                  onClick={handleChangePassword}>
                  Update
                </button>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default PasswordChanges;
