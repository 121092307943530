import React from "react";
import style from "../../../manage_data.module.scss";
import SuccessIcon from "../../../../../../assets/icons/Success.svg";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "../../../../../../redux/index";

const Step3 = ({ setOpen }) => {
  const history = useHistory();

  const handleSucessUpload = () => {
    setOpen(false);
    AppDispatcher.setGroupBy("Template");
    history.push("/u/manage_data");
    AppDispatcher.setUploadTemplateStep(0);
  };

  const VerifySuccess = () => {
    return (
      <div className={style.verifyContainer}>
        <img src={SuccessIcon} />
        <div className={style.verifySuccessConatiner}>
          <div className={style.successTitle}>Verification Successful</div>
          <div>
            Data successfully uploaded on server, you can find it in manage data
          </div>
        </div>
        <div>
          <button
            className={style.cancelbtn}
            onClick={() => handleSucessUpload()}>
            Go to List
          </button>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className={style.verifyContainer}>
        <VerifySuccess />
      </div>
    </>
  );
};

export default Step3;
