// Export all the private routes

import Dashboard from "../../pages/private/dashboard";
import Settings from "../../pages/private/settings";
import AddInvestors from "../../pages/private/investors/addinvestors";
import EditProfile from "../../pages/private/myProfile/editprofile";
import Investors from "../../pages/private/investors";
import InvestorDetail from "../../pages/private/investors/detail/index";
import ChangeStatus from "../../pages/private/investors/ChangeStatus/changeStatus";
import EditInvestor from "../../pages/private/investors/EditInvestor/editInvestors";
import Sponsors from "../../pages/private/sponsors";
import AddSponsor from "../../pages/private/sponsors/addSponsor";
import EditSponsor from "../../pages/private/sponsors/editSponsor";
import Entities from "../../pages/private/entities";
import AddEntities from "../../pages/private/entities/addEntities";
import EditEntities from "../../pages/private/entities/editEntities";
import Investments from "../../pages/private/investments";
import Properties from "../../pages/private/properties";
import AddProperties from "../../pages/private/properties/addProperties";
import EditProperties from "../../pages/private/properties/editProperties";
import DeleteProperties from "pages/private/properties/deleteProperties/index";
import PropertiesDetail from "../../pages/private/properties/detail/index";
import InvestmentDetail from "../../pages/private/investments/detail/index";
import SponsorDetail from "../../pages/private/sponsors/detail/index";
import EntityDetail from "../../pages/private/entities/detail/index";
import AddInvestment from "../../pages/private/investments/addInvestment";
import InvestmentChangeStatus from "pages/private/investments/changeStatus/changeStatus";
import Distributions from "pages/private/distributions";
import EditDistribution from "pages/private/distributions/editDistribution";
import DeleteDistribution from "pages/private/distributions/deleteDistribution/deleteDistribution";
import AddDistribution from "pages/private/distributions/addDistribution";
import Contacts from "../../pages/private/contacts";
import AddContacts from "pages/private/contacts/addContacts/AddContacts";
import EditContacts from "pages/private/contacts/editContacts/EditContacts";
import DeleteContacts from "pages/private/contacts/deleteContacts/deleteContacts";
import ManageData from "../../pages/private/manage_data";
import AddData from "../../pages/private/manage_data/addData/index";
import DeleteManageDataK1 from "pages/private/manage_data/deleteData";
import EditManageDataK1 from "pages/private/manage_data/editData";
import Roles from "pages/private/roles";
import Documents from "pages/private/documents/index";
import SubAdmin from "pages/private/sub_admin/index";
import AddRole from "pages/private/roles/addRole";
import AddSubAdmin from "pages/private/sub_admin/addSubAdmin";
import EditSubAdmin from "pages/private/sub_admin/editSubAdmin";
import EditRole from "pages/private/roles/editRole";
import DeleteRole from "pages/private/roles/deleteRole";
import DeleteSubAdmin from "pages/private/sub_admin/deleteSubAdmin";
import DeleteDocuments from "pages/private/documents/deleteDocuments";
import DeleteInvestor from "pages/private/investors/deleteInvestor";
import DeleteInvestment from "pages/private/investments/deleteInvestment";
import DeleteSponsor from "pages/private/sponsors/deleteSponsor";
import DeleteEntity from "pages/private/entities/deleteEntities";
import SponsorStatus from "pages/private/sponsors/changeStatus/index";
import EntitiesStatus from "pages/private/entities/changeStatus/index";
import UploadTemplate from "pages/private/manage_data/template/uploadTemplate/index";

export const PrivateRoutes = [
  { path: "/u/dashboard", exact: true, component: Dashboard },
  { path: "/u/settings", exact: true, component: Settings },
  { path: "/u/investors/addinvestors", exact: true, component: AddInvestors },
  { path: "/u/editprofile", exact: true, component: EditProfile },
  { path: "/u/investors", exact: true, component: Investors },
  { path: "/u/investors/detail", exact: true, component: InvestorDetail },
  { path: "/u/investors/changeStatus", exact: true, component: ChangeStatus },
  { path: "/u/investors/editInvestor", exact: true, component: EditInvestor },
  { path: "/u/sponsors", exact: true, component: Sponsors },
  { path: "/u/sponsors/addSponsor", exact: true, component: AddSponsor },
  { path: "/u/sponsors/editSponsor", exact: true, component: EditSponsor },
  { path: "/u/entities", exact: true, component: Entities },
  { path: "/u/entities/addEntity", exact: true, component: AddEntities },
  { path: "/u/entities/editEntity", exact: true, component: EditEntities },
  { path: "/u/investments", exact: true, component: Investments },
  { path: "/u/investments", exact: true, component: Investments },
  { path: "/u/investments/detail", exact: true, component: InvestmentDetail },
  {
    path: "/u/investments/changeStatus",
    exact: true,
    component: InvestmentChangeStatus
  },
  { path: "/u/properties", exact: true, component: Properties },
  {
    path: "/u/properties/addProperties",
    exact: true,
    component: AddProperties
  },
  {
    path: "/u/properties/editProperties",
    exact: true,
    component: EditProperties
  },
  {
    path: "/u/properties/deleteProperties",
    exact: true,
    component: DeleteProperties
  },
  { path: "/u/properties/detail", exact: true, component: PropertiesDetail },
  { path: "/u/sponsors/detail", exact: true, component: SponsorDetail },
  { path: "/u/entities/detail", exact: true, component: EntityDetail },
  { path: "/u/distributions", exact: true, component: Distributions },
  {
    path: "/u/distributions/editDistribution",
    exact: true,
    component: EditDistribution
  },
  {
    path: "/u/distributions/deleteDistribution",
    exact: true,
    component: DeleteDistribution
  },
  {
    path: "/u/distributions/addDistribution",
    exact: true,
    component: AddDistribution
  },
  {
    path: "/u/investments/addInvestment",
    exact: true,
    component: AddInvestment
  },
  { path: "/u/contacts", exact: true, component: Contacts },
  {
    path: "/u/contacts/addcontacts",
    exact: true,
    component: AddContacts
  },
  {
    path: "/u/contacts/editcontacts",
    exact: true,
    component: EditContacts
  },
  {
    path: "/u/contacts/deleteContacts",
    exact: true,
    component: DeleteContacts
  },
  { path: "/u/manage_data", exact: true, component: ManageData },
  { path: "/u/manage_data", exact: true, component: ManageData },
  { path: "/u/manage_data/addData", exact: true, component: AddData },
  {
    path: "/u/manage_data/deleteData",
    exact: true,
    component: DeleteManageDataK1
  },
  {
    path: "/u/manage_data/editData",
    exact: true,
    component: EditManageDataK1
  },
  { path: "/u/roles", exact: true, component: Roles },
  { path: "/u/roles/addRole", exact: true, component: AddRole },
  { path: "/u/roles/editRole", exact: true, component: EditRole },
  { path: "/u/roles/deleteRole", exact: true, component: DeleteRole },
  { path: "/u/documents", exact: true, component: Documents },
  { path: "/u/sub_admin", exact: true, component: SubAdmin },
  {
    path: "/u/sub_admin/addSubAdmin",
    exact: true,
    component: AddSubAdmin
  },
  {
    path: "/u/sub_admin/editSubAdmin",
    exact: true,
    component: EditSubAdmin
  },
  {
    path: "/u/sub_admin/deleteSubAdmin",
    exact: true,
    component: DeleteSubAdmin
  },
  {
    path: "/u/documents/deleteDocuments",
    exact: true,
    component: DeleteDocuments
  },
  {
    path: "/u/investors/deleteInvestor",
    exact: true,
    component: DeleteInvestor
  },
  {
    path: "/u/investments/deleteInvestment",
    exact: true,
    component: DeleteInvestment
  },
  {
    path: "/u/sponsors/deleteSponsor",
    exact: true,
    component: DeleteSponsor
  },
  {
    path: "/u/entities/deleteEntities",
    exact: true,
    component: DeleteEntity
  },
  { path: "/u/sponsors/changeStatus", exact: true, component: SponsorStatus },
  { path: "/u/entities/changeStatus", exact: true, component: EntitiesStatus },
  {
    path: "/u/manage_data/upload/template",
    exact: true,
    component: UploadTemplate
  }
];
