import { API, NetworkManager } from "./core";

export class EntityService {
  static async getEntities() {
    const instance = new NetworkManager(API.ENTITY.ENTITIES);
    return await instance.httpRequest();
  }

  static async getEntityStats() {
    const instance = new NetworkManager(API.ENTITY.ENTITY_STATS);
    return await instance.httpRequest();
  }

  static async get_Pdf_Or_Csv(params) {
    const instance = new NetworkManager(API.ENTITY.EXPORT_PDF_CSV, {}, params);
    return await instance.httpRequest();
  }

  static async filterEntities(params) {
    const instance = new NetworkManager(API.ENTITY.ENTITIES_FILTER, {}, params);
    return await instance.httpRequest();
  }

  static async getInvByRole(params) {
    const instance = new NetworkManager(
      API.ENTITY.INV_LIST_BY_ROLE,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getSponByRole(params) {
    const instance = new NetworkManager(
      API.ENTITY.SPON_LIST_BY_ROLE,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async addEntity(payload) {
    const instance = new NetworkManager(API.ENTITY.ADD_ENTITY, payload);
    return await instance.httpRequest();
  }

  static async getEntityDetails(params) {
    const instance = new NetworkManager(API.ENTITY.ENTITY_PROFILE, {}, params);
    return await instance.httpRequest();
  }

  static async getEntityListByRole(params) {
    const instance = new NetworkManager(API.ENTITY.ENTITIES, {}, params);
    return await instance.httpRequest();
  }

  static async editEntity(payload, params) {
    const instance = new NetworkManager(
      API.ENTITY.EDIT_ENTITY,
      payload,
      params
    );
    return await instance.httpRequest();
  }

  static async deleteEntitiesInvestor(params) {
    const instance = new NetworkManager(
      API.ENTITY.DELETE_ENTITY_INVESTOR,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async deleteEntitiesSponsor(params) {
    const instance = new NetworkManager(
      API.ENTITY.DELETE_ENTITY_SPONSOR,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async deletePartners(params) {
    const instance = new NetworkManager(API.ENTITY.DELETE_PARTNERS, {}, params);
    return await instance.httpRequest();
  }
  static async getAllRoles() {
    const instance = new NetworkManager(API.COMMON.GET_ALL_ROLES);
    return await instance.httpRequest();
  }
  static async getInvestorEmailList(params) {
    const instance = new NetworkManager(
      API.ENTITY.INVESTOR_EMAIL_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async getSponsorEmailList(params) {
    const instance = new NetworkManager(
      API.ENTITY.SPONSOR_EMAIL_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async getInvestorTaxList(params) {
    const instance = new NetworkManager(
      API.ENTITY.INVESTOR_TAX_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async getSponsorTaxList(params) {
    const instance = new NetworkManager(
      API.ENTITY.SPONSOR_TAX_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
