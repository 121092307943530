import React, { useEffect, useRef, useState } from "react";
import styles from "./newAutoComplete.module.scss";
import AddIcon from "assets/icons/addIcon.svg";
// import CircularProgress from "@material-ui/core/CircularProgress";

function NewAutoComplete({
  disabled,
  setExistingEmailSelected = () => {},
  setClickedOnAddbtn = () => {},
  dataList,
  setSearchQuery,
  search,
  placeholder = "placeholder",
  btnText,
  setShowAddMore,
  formik,
  fieldKey,
  fieldValue,
  setFieldValue,
  fieldValueError
}) {
  const [open, setOpen] = useState(false);
  const closeRef = useRef(null);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    fieldKey
      ? formik.setFieldValue(`${fieldKey}`, e.target.value)
      : setFieldValue(e.target.value);
    setOpen(true);
    setExistingEmailSelected(false);
    setClickedOnAddbtn(false);
  };

  useEffect(() => {
    !search?.length && setOpen(false);
    document.addEventListener("mousedown", (e) => {
      if (!closeRef.current?.contains(e.target)) {
        setOpen(false);
      }
    });
  }, [search]);

  return (
    <>
      <div className={styles.main_container} ref={closeRef}>
        <input
          disabled={disabled}
          className={styles.input_style}
          style={
            disabled
              ? { background: "#E9E9E9" }
              : fieldValueError
              ? { border: "1px solid #E13838" }
              : formik.touched[`${fieldKey}`] &&
                formik.errors[`${fieldKey}`] && { border: "1px solid #E13838" }
          }
          onChange={handleSearch}
          value={
            search
              ? search
              : fieldKey
              ? formik?.values?.[`${fieldKey}`]
              : fieldValue
          }
          placeholder={placeholder}
        />
        {open ? (
          <div className={styles.droproot}>
            <div className={styles.auto_data_container}>
              {dataList?.length ? (
                dataList?.map((item) => item)
              ) : (
                <div className={styles.loaderposition}>
                  {/* <CircularProgress /> */}
                </div>
              )}
            </div>
            {btnText && (
              <div
                className={styles.addMoreBtn}
                onClick={() => {
                  setShowAddMore(btnText);
                  setOpen(false);
                  if (fieldKey) {
                    formik.setFieldValue("first_name", "");
                    formik.setFieldValue("last_name", "");
                    formik.setFieldValue("countryCode", "");
                    formik.setFieldValue("phone", "");
                    formik.setFieldValue("company_name", "");
                  }
                  setClickedOnAddbtn(true);
                }}>
                <img src={AddIcon} alt="add" style={{ marginRight: 17 }} />
                {`Add New ${btnText}`}
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div style={{ color: "red", fontSize: "12px" }}>
        {formik.touched[`${fieldKey}`] && formik.errors[`${fieldKey}`]}
      </div>
    </>
  );
}

export default NewAutoComplete;
