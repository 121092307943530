let handleNotification;
const handlePermission = (callback) => {
  handleNotification = callback;
  if (window.safari && window.safari.pushNotification) {
    let result = window.safari.pushNotification.permission(
      "web.com.admin.simplee"
    );
    checkPermission(result);
  }
};

const checkPermission = (permissionData) => {
  if (permissionData.permission === "default") {
    window.safari.pushNotification.requestPermission(
      "https://api.dev.simplee.foxlabs.in/api/v1/v2/pushPackages/web.com.admin.simplee",
      { panel: "simplee" },
      checkPermission
    );
  } else if (permissionData.permission === "granted") {
    handleNotification(permissionData.deviceToken);
  }
};

export { handlePermission };
