import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import styles from "./Components.module.scss";
import TagProperty from "./TagProperty";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import Calender from "components/calender";
import CustomSelect from "components/StyledComponents/Select";
import FieldHeading from "components/fieldHeading";
import DescriptionBox from "components/StyledComponents/DescriptionBox";
import { preventNonNumericalInput } from "helpers";
import NewCategoryDocTable from "pages/private/properties/component/NewCategoryDocTable";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import RedDelete from "assets/icons/NewCardSvg/RedDelete.svg";
import Toast from "components/toast";
import TagLoan from "./TagLoan";
import EditIcon from "assets/icons/EditIcon.svg";
import DeleteModal from "components/Modals/DeleteModal";
import { AddInvestmentLoanService } from "network/addInvestmentLoanService";

const Refinances = ({ id, arrayHelper, formik, dropDownOpRes, isedit }) => {
  const [isDetails, setIsDetails] = useState(false);
  const [docCategories, setDocCategories] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [LoanEdit, setLoanEdit] = useState(false);
  const dropdownRef = useRef(null);

  const checkDelete = (deleteid) => {
    if (deleteid) {
      setDeleteModal(true);
    } else {
      arrayHelper.remove(id);
    }
  };

  const handleDelete = async (deleteID) => {
    setDeleteModal(false);
    const resp = await AddInvestmentLoanService.deleteLoan(deleteID);
    if (resp.success) {
      arrayHelper.remove(id);
      Toast.showSuccessToast(resp?.data?.message);
    } else {
      Toast.showErrorToast(resp?.message?.message?.["0"]);
    }
  };

  const onDetailsClick = () => {
    setIsDetails(!isDetails);
  };

  const refinanceErrors =
    (formik.errors.refinanceSet &&
      formik.errors.refinanceSet.length &&
      formik.errors.refinanceSet[id]) ||
    {};
  const refinanceTouched =
    (formik.touched.refinanceSet &&
      formik.touched.refinanceSet.length &&
      formik.touched.refinanceSet[id]) ||
    {};

  const onkeyPress = (e, ref) => {
    if (e.keyCode == 9) {
      ref.current.focus();
    }
  };

  const onKeyDownActions = (e, ref) => {
    preventNonNumericalInput();
    onkeyPress(e, ref);
  };

  const setDocData = (data, formik) => {
    formik.setFieldValue(`refinanceSet[${id}].documents`, data);
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTPROPERTY"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    setLoanEdit(formik.values.refinanceSet[id].isLoanEdit);
  }, [formik.values.refinanceSet[id].isLoanEdit]);

  return (
    <>
      <Grid container style={{ marginBottom: "20px" }}>
        <Grid
          item
          container
          alignItems="center"
          xs={12}
          style={{ paddingLeft: "5px" }}>
          <Typography className={styles.overviewText}>{`Refinance ${
            id + 1
          }`}</Typography>
          {isedit ? (
            <div
              style={{ marginLeft: "20px", cursor: "pointer" }}
              onClick={() =>
                formik.setFieldValue(`refinanceSet[${id}].isLoanEdit`, true)
              }>
              <img src={EditIcon} alt="edit" />
            </div>
          ) : null}
        </Grid>
      </Grid>

      <Grid
        container
        className={styles.overviewCtr}
        style={{ marginBottom: "20px" }}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Outstanding Loan ($)" />
              <AppTextFieldWhite
                disabled={!LoanEdit && isedit}
                id={`refinanceSet[${id}].outstanding_loan`}
                placeholder=""
                onKeyDown={(e) => onKeyDownActions(e, dropdownRef)}
                value={formik.values.refinanceSet[id].outstanding_loan}
                onChange={formik.handleChange(
                  `refinanceSet[${id}].outstanding_loan`
                )}
                error={
                  refinanceTouched?.outstanding_loan &&
                  Boolean(refinanceErrors?.outstanding_loan)
                }
                helperText={
                  refinanceTouched?.outstanding_loan &&
                  refinanceErrors?.outstanding_loan
                }
                variant="standard"
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>
            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Refinance Loan Type" />
              <CustomSelect
                disabled={!LoanEdit && isedit}
                id={`refinanceSet[${id}].refinance_loan_type`}
                fullWidth
                defaultValue={"fixed"}
                items={dropDownOpRes?.LOAN_TYPE}
                value={formik.values.refinanceSet[id].refinance_loan_type}
                onChange={formik.handleChange(
                  `refinanceSet[${id}].refinance_loan_type`
                )}
                error={refinanceErrors?.refinance_loan_type}
                touched={refinanceTouched?.refinance_loan_type}
              />
            </Grid>
            <Grid item xs>
              <FieldHeading title={"Notes"} />
              <DescriptionBox
                disabled={!LoanEdit && isedit}
                id="description"
                placeholder="Add Description"
                variant="standard"
                fullWidth
                multiline
                rows={2}
                value={formik.values.refinanceSet[id].notes}
                onChange={formik.handleChange(`refinanceSet[${id}].notes`)}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{ maxLength: 100 }}
                error={
                  refinanceTouched?.notes && Boolean(refinanceErrors?.notes)
                }
                helperText={refinanceTouched?.notes && refinanceErrors?.notes}
              />
              <Grid
                container
                style={{ color: "#589F11" }}
                justifyContent="flex-end">
                {`${formik.values.refinanceSet[id].notes?.length || 0}/100`}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {isDetails && (
          <Grid item xs={12}>
            <Grid container spacing={2} className={styles.marginTop}>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Refinance Loan Amount ($)" />
                <AppTextFieldWhite
                  id={`refinanceSet[${id}].refinance_loan_amount`}
                  onKeyDown={(e) => onKeyDownActions(e, dropdownRef)}
                  value={formik.values.refinanceSet[id].refinance_loan_amount}
                  onChange={formik.handleChange(
                    `refinanceSet[${id}].refinance_loan_amount`
                  )}
                  placeholder=""
                  variant="standard"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ maxLength: 12 }}
                />
              </Grid>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Bank Name" />
                <AppTextFieldWhite
                  id={`refinanceSet[${id}].bank_name`}
                  value={formik.values.refinanceSet[id].bank_name}
                  onChange={formik.handleChange(
                    `refinanceSet[${id}].bank_name`
                  )}
                  placeholder="Add Bank Name"
                  variant="standard"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Refinance Loan Initiation Date" />
                <Calender
                  setDate={(date) =>
                    formik.setFieldValue(
                      `refinanceSet[${id}].refinance_loan_initiation_date`,
                      date
                    )
                  }
                  defaultDate={
                    formik.values.refinanceSet[id]
                      .refinance_loan_initiation_date
                  }
                />
              </Grid>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Debt Service ($)" />
                <AppTextFieldWhite
                  id={`refinanceSet[${id}].debt_service`}
                  onKeyDown={(e) => onKeyDownActions(e, dropdownRef)}
                  value={formik.values.refinanceSet[id].debt_service}
                  onChange={formik.handleChange(
                    `refinanceSet[${id}].debt_service`
                  )}
                  placeholder=""
                  variant="standard"
                  fullWidth
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ maxLength: 12 }}
                />
              </Grid>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Last updated on" />
                <Calender
                  setDate={(date) =>
                    formik.setFieldValue(
                      `refinanceSet[${id}].last_updated_on`,
                      date
                    )
                  }
                  defaultDate={formik.values.refinanceSet[id].last_updated_on}
                />
              </Grid>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Status" />
                <CustomSelect
                  id={`refinanceSet[${id}].loan_status`}
                  fullWidth
                  defaultValue={"active"}
                  items={dropDownOpRes?.LOANSTATUS}
                  value={formik.values.refinanceSet[id].status}
                  onChange={formik.handleChange(`refinanceSet[${id}].status`)}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {isDetails && (
          <Grid item xs={12}>
            <Grid container className={styles.marginTop}>
              <Typography className={styles.descText}>
                Tag Properties
              </Typography>
            </Grid>
            <Grid container style={{ overflow: "auto" }}>
              <TagProperty id={id} formik={formik} isedit={isedit} />
            </Grid>
          </Grid>
        )}
        {isDetails && (
          <Grid item xs={12}>
            <Grid container className={styles.marginTop}>
              <Typography className={styles.descText}>
                Loans Refinanced
              </Typography>
            </Grid>
            <Grid container style={{ overflow: "auto" }}>
              <TagLoan id={id} formik={formik} isedit={isedit} />
            </Grid>
          </Grid>
        )}
        {isDetails && (
          <Grid item xs={12}>
            <Grid container className={styles.marginTop}>
              <Typography className={styles.descText}>
                Upload Documents
              </Typography>
            </Grid>
            <Grid container alignItems="flex-end">
              <Grid item xs={6}>
                <NewCategoryDocTable
                  sponDocCategories={docCategories.filter(
                    (el) => el.name !== "Misc." && el.name !== "Rent Roll"
                  )}
                  listData={formik.values.refinanceSet[id].documents}
                  setListData={(list) => {
                    setDocData(list, formik);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {isDetails && (
          <Grid item xs={12}>
            <Grid
              container
              justifyContent="space-between"
              className={styles.marginTop}>
              <div className={styles.addDetailsCtr} onClick={onDetailsClick}>
                <div className={styles.collapseIcon}>-</div>
                <div>
                  <Typography className={styles.collapseText}>
                    Collapse
                  </Typography>
                </div>
              </div>
              <div
                onClick={() => {
                  checkDelete(formik.values?.refinanceSet?.[id]?.loan_id);
                }}
                style={{ cursor: "pointer" }}>
                <img src={RedDelete} width={40} height={40} alt="delete" />
              </div>
            </Grid>
          </Grid>
        )}
        {!isDetails && (
          <Grid item xs={12}>
            {LoanEdit && (
              <Grid
                container
                justifyContent="space-between"
                className={styles.marginTop}>
                <div className={styles.addDetailsCtr} onClick={onDetailsClick}>
                  <div className={styles.addIcon}>+</div>
                  <div>
                    <Typography className={styles.addText}>
                      Add Details
                    </Typography>
                  </div>
                </div>
                <div
                  onClick={() => {
                    checkDelete(formik.values?.refinanceSet?.[id]?.loan_id);
                  }}
                  style={{ cursor: "pointer" }}>
                  <img src={RedDelete} width={40} height={40} alt="delete" />
                </div>
              </Grid>
            )}
          </Grid>
        )}
        <DeleteModal
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          handleSubmit={() =>
            handleDelete(formik.values?.refinanceSet?.[id]?.loan_id)
          }
          title="Are You Sure You Want To Delete This Item?"
          description="This will permanently remove all data associated with it from Simplee."
        />
      </Grid>
    </>
  );
};

export default Refinances;
