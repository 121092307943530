import React from "react";
import { Box, Typography } from "@material-ui/core";

const TextCounter = ({
  currentDescriptionLength,
  maxLength,
  wordLength = 100
}) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "flex-end"
      }}>
      <Typography
        style={{
          color: currentDescriptionLength < wordLength ? "#589F11" : "red"
        }}>{`${currentDescriptionLength}/${maxLength}`}</Typography>
    </Box>
  );
};

export default TextCounter;
