import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../../components/Buttons/Primary";
import AppTextField from "../../../../components/StyledComponents/AppTextFeild";
import styles from "../forgotPassword.module.scss";
import OtpInput from "react-otp-input";
import Loader from "../../../../components/Loader/AppLoader";

const VerifyOtp = ({
  formik,
  handlePress,
  handleChange,
  resendOTP,
  loadings
}) => {
  const [resendOTPActive, setResendOTPActive] = useState(false);
  const [secondsRemaining, setSecondsRemaining] = useState(0);

  const startResendOtpTimer = () => {
    setResendOTPActive(false);
    let secondsRemaining = 90;
    setSecondsRemaining(secondsRemaining);
    let timer = setInterval(() => {
      if (secondsRemaining <= 0) {
        setResendOTPActive(true);
        return clearInterval(timer);
      }
      secondsRemaining--;
      setSecondsRemaining(secondsRemaining);
    }, 1000);
  };

  useEffect(() => {
    return startResendOtpTimer();
  }, []);
  return (
    <>
      {loadings ? (
        <Loader />
      ) : (
        <>
          <div className={styles.headingContainer}>
            <div className={styles.heading}>Forgot Password</div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.email}>
              <AppTextField
                id="email"
                placeholder="Enter Your Email "
                variant="standard"
                fullWidth
                disabled
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            </div>
            <div className={styles.password}>
              <OtpInput
                value={formik.values.otp}
                onChange={handleChange}
                inputStyle={{
                  height: 48,
                  width: 48,
                  borderRadius: 8,
                  marginRight: 10,
                  border: "1px solid rgba(244, 242, 255, 1)",
                  color: "#000"
                }}
                numInputs={6}
              />
              {formik.errors.otp && formik.touched.otp && (
                <div
                  style={{
                    color: "#E13838",
                    fontSize: "0.75rem",
                    marginTop: 3
                  }}>
                  {formik.errors.otp}
                </div>
              )}
            </div>
            {resendOTPActive ? (
              <div
                style={{
                  fontFamily: "Inter Regular",
                  color: "#445EBE",
                  fontSize: 16,
                  marginTop: 8,
                  cursor: "pointer"
                }}
                onClick={() => {
                  if (resendOTP()) {
                    startResendOtpTimer();
                  }
                }}>
                Resend OTP
              </div>
            ) : (
              <div
                style={{
                  fontFamily: "Inter Regular",
                  fontSize: 16,
                  marginTop: 8
                }}>
                Resend OTP in {secondsRemaining}s
              </div>
            )}
          </div>
          <div className={styles.formTagContainer}></div>
          <div>
            <PrimaryButton text="Submit" onPress={handlePress} width="68%" />
          </div>
          <div style={{ marginTop: 20 }}>
            <p className={styles.note}>
              Note: If You Did not Receive Our OTP Email:
            </p>
            <p className={styles.note} style={{ marginLeft: 10 }}>
              {"\u2022"} Confirm That Your Email Was Entered Correctly Above.
            </p>
            <p className={styles.note} style={{ marginLeft: 10 }}>
              {"\u2022"} Check Your Spam And Junk Email Folder.
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default VerifyOtp;
