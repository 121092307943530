import { Grid, makeStyles } from "@material-ui/core";
import Calender from "components/calender";
import FieldHeading from "components/fieldHeading";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import DescriptionBox from "components/StyledComponents/DescriptionBox";
import { formatDate, preventNonNumericalInput } from "helpers";
import formatCurrency from "helpers/formatCurrency";
import React, { Fragment, useEffect } from "react";

const useStyles = makeStyles(() => ({
  EquityStyle: {
    color: "#A0A0A0",
    fontFamily: "Inter Regular",
    fontSize: 16,
    fontWeight: 400,
    display: "flex",
    alignItems: "center",
    marginTop: "23.5px"
  },
  EquitySpan: {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 600,
    color: "#191919"
  }
}));

const EquityInformation = ({
  index,
  formik,
  ClickonEquitInfoEdit,
  isEditButton
}) => {
  const classes = useStyles();

  const Setequity_amount = () => {
    formik.setFieldValue(
      `equity_info[${index}].equity_amount`,
      `${
        formik.values.equity_info[index].total_asset -
        formik.values.equity_info[index].outstanding
      }`
    );
  };

  useEffect(() => {
    Setequity_amount();
  }, [
    formik.values.equity_info[index]?.total_asset,
    formik.values.equity_info[index]?.outstanding
  ]);

  return (
    <Fragment>
      <Grid container spacing={2}>
        {/* "Total Asset Value" */}
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Total Asset Value ($)" />
          <AppTextFieldWhite
            disabled={!ClickonEquitInfoEdit}
            id="total_asset"
            placeholder=""
            variant="standard"
            fullWidth
            onKeyDown={preventNonNumericalInput}
            value={formik.values.equity_info[index]?.total_asset}
            onChange={formik.handleChange(`equity_info[${index}].total_asset`)}
            error={
              formik.touched?.equity_info?.[index]?.total_asset &&
              Boolean(formik.errors?.equity_info?.[index]?.total_asset)
            }
            helperText={
              formik.touched?.equity_info?.[index]?.total_asset &&
              formik.errors?.equity_info?.[index]?.total_asset
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>

        {/* Outstanding Loan */}
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Outstanding Loan ($)" />
          <AppTextFieldWhite
            disabled={!ClickonEquitInfoEdit}
            id="outstanding"
            placeholder=""
            variant="standard"
            onKeyDown={preventNonNumericalInput}
            fullWidth
            value={formik.values.equity_info[index]?.outstanding}
            onChange={formik.handleChange(`equity_info[${index}].outstanding`)}
            error={
              formik.touched?.equity_info?.[index]?.outstanding &&
              Boolean(formik.errors?.equity_info?.[index]?.outstanding)
            }
            helperText={
              formik.touched?.equity_info?.[index]?.outstanding &&
              formik.errors?.equity_info?.[index]?.outstanding
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>

        {/* "Bank Name" */}
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Bank Name" />
          <AppTextFieldWhite
            disabled={!ClickonEquitInfoEdit}
            id="bank_name"
            placeholder="Add Bank Name"
            variant="standard"
            // onKeyDown={(e) => onKeyDownActions(e, dropdownRef)}
            fullWidth
            value={formik.values.equity_info[index]?.bank_name}
            onChange={formik.handleChange(`equity_info[${index}].bank_name`)}
            error={
              formik.touched?.equity_info?.[index]?.bank_name &&
              Boolean(formik.errors?.equity_info?.[index]?.bank_name)
            }
            helperText={
              formik.touched?.equity_info?.[index]?.bank_name &&
              formik.errors?.equity_info?.[index]?.bank_name
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 50 }}
          />
        </Grid>

        {/* as on  */}
        {isEditButton && (
          <Grid item xl={3} lg={4} sm={6}>
            <FieldHeading title="As On" />
            <Calender
              disabled={!ClickonEquitInfoEdit}
              id="AsOn"
              setDate={(date) =>
                formik.setFieldValue(`equity_info[${index}].updated_at`, date)
              }
              defaultDate={formik.values.equity_info[index].updated_at || null}
            />
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2}>
        {/* "Debt Service (Optional)" */}
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Debt Service ($) (Optional)" />
          <AppTextFieldWhite
            disabled={!ClickonEquitInfoEdit}
            id="debtservice"
            placeholder=""
            variant="standard"
            onKeyDown={preventNonNumericalInput}
            fullWidth
            value={formik.values.equity_info[index]?.debtservice}
            onChange={formik.handleChange(`equity_info[${index}].debtservice`)}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>

        {/* Date (Optional) */}
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Date (Optional)" />
          <Calender
            disabled={!ClickonEquitInfoEdit}
            id="Date (Optional)"
            setDate={(date) =>
              formik.setFieldValue(
                `equity_info[${index}].date`,
                formatDate(date)
              )
            }
            defaultDate={formik.values.equity_info[index].date}
          />
        </Grid>
        {isEditButton && (
          <Grid item xs>
            <FieldHeading title="Notes" />
            <DescriptionBox
              disabled={!ClickonEquitInfoEdit}
              id="notes"
              placeholder="Add Description"
              variant="standard"
              fullWidth
              rows={3}
              multiline
              value={formik.values.equity_info[index].notes}
              onChange={formik.handleChange(`equity_info[${index}].notes`)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                disableUnderline: true
              }}
              inputProps={{
                maxLength: 100
              }}
            />
            <Grid
              container
              style={{ color: "#589F11" }}
              justifyContent="flex-end">
              {`${formik.values.equity_info[index].notes?.length || 0}/100`}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid container>
        <div className={classes.EquityStyle}>
          <div id="Equity">
            Equity:
            <span className={classes.EquitySpan}>{`${
              formatCurrency(
                formik.values.equity_info[index]?.total_asset -
                  formik.values.equity_info[index]?.outstanding
              ).split(".")?.[0]
            }`}</span>
          </div>
        </div>
      </Grid>
    </Fragment>
  );
};

export default EquityInformation;
