import { Grid, Typography } from "@material-ui/core";
import { formatDate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import React from "react";
import CommonPropertyDetalTable from "./CommonPropertyDetailTable";
import style from "./propertiesProfile.module.scss";

const heading = [
  "Year",
  "Month",
  "Total Revenue",
  "Total Operating Expenses",
  "Financing Expenses",
  "Non Operating Expenses",
  "NOI",
  "Date of Addition",
  "File"
];

function NetOperatingIncomeTable({ propertyData }) {
  const tableData = propertyData?.property_noi?.map((item) => {
    return {
      0: item?.year || "-",
      1: item?.month || "-",
      2: formatCurrency(item?.revenue || 0),
      3: formatCurrency(item?.operating_expenses || 0),
      4: formatCurrency(item?.finance_expenses || 0),
      5: formatCurrency(item?.non_operating_expenses || 0),
      6: formatCurrency(item?.noi || 0),

      7: formatDate(item?.created_at),
      8: item?.documents,

      tableType: false
    };
  });

  return (
    <Grid item xs={12} className={style.overviewMainContainer}>
      <Grid container>
        <Typography className={style.cardHeading}>
          Net Operating Income
        </Typography>
      </Grid>
      <Grid container>
        <CommonPropertyDetalTable
          data={propertyData?.noi}
          tableData={tableData}
          heading={heading}
          type="noi"
        />
      </Grid>
    </Grid>
  );
}

export default NetOperatingIncomeTable;
