import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../components/Buttons/Primary";
import AppTextField from "../../../components/StyledComponents/AppTextFeild";
import styles from "../forgotPassword/forgotPassword.module.scss";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "../../../assets/icons/eye-off.svg";
import Visibility from "../../../assets/icons/eye.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import { validationSchemaChangePassword } from "helpers/validationSchema";
import FieldHeading from "components/fieldHeading";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { SubAdminService } from "network/subAdminService";
import Toast from "components/toast";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  imageSrc: {
    height: "12px",
    width: "16px",
    marginLeft: "-40px",
    marginRight: "12px",
    "&:hover": {
      background: "none"
    }
  },
  container: {
    display: "flex",
    flexDirection: "column"
  },
  formContainer: {
    width: 343,
    marginTop: 24
  },
  formTagContainer: {
    width: "100%",
    height: 41
  }
}));

const useQuery = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

const SubAdminLogin = () => {
  const classes = useStyles();
  const history = useHistory();
  let { id } = useParams();
  let query = useQuery();
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);
  const [initialValues, setInitialValue] = useState({
    email: "John.Doe@simplee.com",
    current_password: "",
    newPassword: "",
    retype_password: ""
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchemaChangePassword,
    onSubmit: (values) => handleFormSubmit(values)
  });

  const stopAction = (e) => {
    e.preventDefault();
  };

  const handleFormSubmit = async (values) => {
    const payload = {
      email: values.email,
      current_password: values.current_password,
      new_password: values.newPassword,
      retype_password: values.confirmPassword,
      device: {
        device_token:
          "eRNUoGI9FHDeoByrplscBU:APA91bHGOA3JFbnxn7wrZAcJIS7OrTZ1qVy3Utd5DL02p0qEjOdv-2Zxc019nQEqCSxsTnH_8nkNqhjl7sIjDDh555GNq0iqO5fNDu2UtM2W0qdbAR4guYiEY3QuAEI9W38XJD0IOk-m",
        device_type: "WEB",
        is_safari: 0
      }
    };
    let res = await SubAdminService.passwordResetSubAdmin(payload, id + "/");
    if (res.success) {
      history.push("/auth/login");
      Toast.showSuccessToast("Password reset success");
    } else {
      Toast.showErrorToast(res.error.message[0]);
    }
  };

  useEffect(() => {
    let current_password = query.get("password");
    let email = query.get("email").replaceAll(" ", "+");
    setInitialValue({
      ...initialValues,
      current_password: current_password,
      email: email
    });
  }, []);

  return (
    <div className={classes.container}>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Hi, Sub-Admin</div>
        <div className={styles.subHeading} style={{ fontSize: 16 }}>
          Login And Manage Your Assigned Tasks On Simplee
        </div>
      </div>
      <div className={`${styles.formContainer} ${classes.formContainer}`}>
        <div className={styles.email}>
          <FieldHeading title={"Email ID"} />
          <AppTextField
            id="email"
            placeholder="John.Doe@simplee.com"
            variant="standard"
            fullWidth
            value={formik.values.email}
            disabled={true}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
          />
        </div>
        <div className={styles.email}>
          <FieldHeading title={"Set New Password"} />
          <AppTextField
            id="newPassword"
            placeholder="*******"
            variant="standard"
            onCut={stopAction}
            onCopy={stopAction}
            onPaste={stopAction}
            fullWidth
            autoComplete="new-password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true,
              type: isVisible ? "text" : "password",
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setVisible(!isVisible)}
                    edge="end"
                    className={classes.imageSrc}>
                    {isVisible ? (
                      <img src={Visibility} />
                    ) : (
                      <img src={VisibilityOff} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className={styles.password}>
          <FieldHeading title={"Confirm New Password"} />
          <AppTextField
            id="confirmPassword"
            placeholder="*******"
            variant="standard"
            onCut={stopAction}
            onCopy={stopAction}
            onPaste={stopAction}
            fullWidth
            autoComplete="confirm-password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true,
              type: isVisible1 ? "text" : "password",
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setVisible1(!isVisible1)}
                    edge="end"
                    className={classes.imageSrc}>
                    {isVisible1 ? (
                      <img src={Visibility} />
                    ) : (
                      <img src={VisibilityOff} />
                    )}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div className={classes.formTagContainer}></div>
      <div>
        <PrimaryButton
          text="Log In"
          onPress={formik.handleSubmit}
          width="233px"
        />
      </div>
    </div>
  );
};

export default SubAdminLogin;
