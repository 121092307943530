import React, {useState} from "react";
import PrimaryButton from "../../../../components/Buttons/Primary";
import AppTextField from "../../../../components/StyledComponents/AppTextFeild";
import styles from "../forgotPassword.module.scss";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "../../../../assets/icons/eye-off.svg";
import Visibility from "../../../../assets/icons/eye.svg";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  imageSrc: {
    height: "12px",
    width: "16px",
    marginLeft: "-40px",
    marginRight: "12px",
    "&:hover": {
      background: "none"
    }
  }
}));

const ChangePassword = ({
  formik,

  handlePress
}) => {
  const classes = useStyles();
  const [isVisible, setVisible] = useState(false);
  const [isVisible1, setVisible1] = useState(false);

  const stopAction = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Forgot Password</div>
        <div className={styles.subHeading}>We Got You Covered</div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.email}>
          <AppTextField
            id="newPassword"
            label="Set New Password"
            placeholder="*******"
            variant="standard"
            onCut={stopAction}
            onCopy={stopAction}
            onPaste={stopAction}
            fullWidth
            autoComplete="new-password"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.newPassword && Boolean(formik.errors.newPassword)
            }
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true,
              type: isVisible ? "text" : "password" , endAdornment: (
                <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVisible(!isVisible)}
                  edge="end"
                  className={classes.imageSrc}>
                  {isVisible ? (
                    <img src={Visibility} />
                  ) : (
                    <img src={VisibilityOff} />
                  )}
                </IconButton>
              </InputAdornment>
              )
            }}
          />
        </div>
        <div className={styles.password}>
          <AppTextField
            id="confirmPassword"
            label="Confirm New Password"
            placeholder="*******"
            variant="standard"
            onCut={stopAction}
            onCopy={stopAction}
            onPaste={stopAction}
            fullWidth
            autoComplete="confirm-password"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true,
              type: isVisible1 ? "text" : "password" , endAdornment: (
                <InputAdornment position="start">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVisible1(!isVisible1)}
                  edge="end"
                  className={classes.imageSrc}>
                  {isVisible1 ? (
                    <img src={Visibility} />
                  ) : (
                    <img src={VisibilityOff} />
                  )}
                </IconButton>
              </InputAdornment>
              )
            }}
          />
        </div>
      </div>
      <div className={styles.formTagContainer}></div>
      <div>
        <PrimaryButton text="Submit" onPress={handlePress} width="68%" />
      </div>
    </>
  );
};

export default ChangePassword;
