import React from "react";
import ManageColumnsWeb from "./web/ManageColumnsWeb";
import ManageColumnsMobile from "./mobile/ManageColumnsMobile";
import Hidden from "@material-ui/core/Hidden";
import Toast from "components/toast";

const Index = ({
  AllHeaders,
  open,
  setOpen,
  FrdasEmail,
  setFrdasEmail,
  clickOnShare,
  clickOnPreview
}) => {
  const ExtractAllHeaders = AllHeaders.map((obj) => obj.name);
  const [columnNames, setColumnNames] = React.useState([...ExtractAllHeaders]);
  const [checked, setChecked] = React.useState([...ExtractAllHeaders]);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [emails, setEmails] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleSave = () => {
    const sortedHeader = [];

    checked.map((val) => {
      AllHeaders.map((obj) => {
        if (obj.name === val) {
          sortedHeader.push(obj);
        }
      });
    });

    const headerarray = sortedHeader.map((el) => el?.id);

    if (emails.length) {
      const Payload = {
        sequence: headerarray,
        emails: emails,
        type: FrdasEmail
      };
      clickOnShare(Payload);
      handleClose();
      setFrdasEmail("frdasEmail");
      setEmails([]);
    } else {
      Toast.showErrorToast("Please Fill Email");
    }
  };

  const handlePreview = () => {
    const sortedHeader = [];

    checked.map((val) => {
      AllHeaders.map((obj) => {
        if (obj.name === val) {
          sortedHeader.push(obj);
        }
      });
    });

    const headerarray = sortedHeader.map((el) => el?.id);

    const Payload = {
      sequence: headerarray,
      type: FrdasEmail
    };
    clickOnPreview(Payload);
    // setEmails([]);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    const results = columnNames.filter((person) =>
      person.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setColumnNames(results);
  };

  const handleSelectAll = () => {
    setChecked([...ExtractAllHeaders]);
  };

  const handleClose = () => {
    setOpen(false);
    setFrdasEmail("frdasEmail");
  };

  React.useEffect(() => {
    if (searchTerm === "") {
      setColumnNames([...ExtractAllHeaders]);
    }
  }, [searchTerm]);

  return (
    <>
      <Hidden xsDown>
        <ManageColumnsWeb
          FrdasEmail={FrdasEmail}
          open={open}
          handleSave={handleSave}
          handlePreview={handlePreview}
          columnNames={columnNames}
          handleClose={handleClose}
          checked={checked}
          setChecked={setChecked}
          handleToggle={handleToggle}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          handleSelectAll={handleSelectAll}
          ExtractAllHeaders={ExtractAllHeaders}
          setEmails={setEmails}
        />
      </Hidden>
      <Hidden smUp>
        <ManageColumnsMobile
          open={open}
          handleSave={handleSave}
          columnNames={columnNames}
          handleClose={handleClose}
          checked={checked}
          setChecked={setChecked}
          setColumnNames={setColumnNames}
          handleToggle={handleToggle}
        />
      </Hidden>
    </>
  );
};

export default Index;
