import { Grid } from "@material-ui/core";
import Illustrations from "components/illustration/Illustrations";
import React from "react";
import HeadingContainer from "../component/HeadingContainer";
import noInvestors from "assets/emptyState/noInvestors.svg";
import InvestorTable from "components/Common/InvestorTable";
// import { AppDispatcher } from "../../../../../redux";
// import { useHistory } from "react-router-dom";

const Investors = ({ data }) => {
  // const history = useHistory();

  // const handleClick = (e, type, id) => {
  //   e.stopPropagation();
  //   if (id) {
  //     switch (type) {
  //       case "investor":
  //         AppDispatcher.setInvestorDetailID(id);
  //         history.push("/u/investors/detail");
  //         break;
  //       case "investorEntity":
  //         AppDispatcher.setEntitiesDetailData({
  //           id: id,
  //           entity_type: "INVESTOR"
  //         });
  //         history.push("/u/entities/detail");
  //         break;
  //     }
  //   }
  // };

  return (
    <Grid
      container
      spacing={4}
      style={{ marginTop: "20px", marginBottom: "54px" }}>
      <Grid item xs={12}>
        <HeadingContainer Title="All Investors">
          {data?.investors?.length ? (
            <InvestorTable
              Headingdata={[
                { heading: "Investor Name" },
                { heading: "Membership" },
                { heading: "Total Invested Amount" },
                { heading: "Date of Investment" },
                { heading: "As On" },
                { heading: "Tax ID (EIN)" },
                { heading: "Entity Name" },
                { heading: "Mailing Address" },
                { heading: "Current Value" },
                { heading: "Documents" }
              ]}
              InvDetailPageData={data.investors ? data?.investors : []}
            />
          ) : (
            <Grid item xs={12}>
              <Illustrations
                src={noInvestors}
                subHeading={`No added Investors.`}
              />
            </Grid>
          )}
        </HeadingContainer>
      </Grid>
    </Grid>
  );
};

export default Investors;
