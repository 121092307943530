import React from "react";
import styles from "./primary.module.scss";

const ExportPDFButton = (props) => {
  return (
    <div {...props} className={styles.ExportPdfBtn}>
      <h4>Export PDF</h4>
    </div>
  );
};

export default ExportPDFButton;
