import { API, NetworkManager } from "./core";

export class SubAdminService {
  static async getRole() {
    const instance = new NetworkManager(API.SubAdmin.GET_ROLES);
    return await instance.httpRequest();
  }
  static async getAccessRoleList(params) {
    const instance = new NetworkManager(
      API.SubAdmin.GET_ACCESS_ROLES,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getSubAdmin(params) {
    const instance = new NetworkManager(
      API.SubAdmin.LIST_SUB_ADMIN,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async dropdownOptions() {
    const instance = new NetworkManager(API.DROPDOWN_OPTIONS.OPTIONS);
    return await instance.httpRequest();
  }

  static async addSubAdmin(payload) {
    const instance = new NetworkManager(
      API.SubAdmin.ADD_SUB_ADMIN,
      payload,
      {}
    );
    return await instance.httpRequest();
  }
  static async passwordResetSubAdmin(payload, id) {
    const instance = new NetworkManager(
      API.SubAdmin.PASSWORD_RESET_SUB_ADMIN,
      payload,
      [`${id}`]
    );
    return await instance.httpRequest();
  }
  static async editSubAdmin(payload, id) {
    const instance = new NetworkManager(API.SubAdmin.EDIT_SUB_ADMIN, payload, [
      id
    ]);
    return await instance.httpRequest();
  }
  static async deleteSubAdmin(params) {
    const instance = new NetworkManager(
      API.SubAdmin.DELETE_SUB_ADMIN,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
