import { Box, Grid, makeStyles } from "@material-ui/core";
import FieldHeading from "components/fieldHeading";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import { FieldArray } from "formik";
import React, { useEffect, useState } from "react";
import plusAdd from "assets/icons/PlusAdd.svg";
import NewAutoComplete from "components/NewAutoComplete/NewAutoComplete";
import { EntityService } from "network/entityService";
import NewSposnors from "./NewSposnors";
import SponsorEntityDilogBox from "./SponsorDilogBox/SponsorEntityDilogBox";

const useStyles = makeStyles(() => ({
  mainContainer: {
    cursor: "pointer",
    padding: "16px",
    "&:hover": {
      background: "#F6F8FD"
    }
  },
  email: {
    fontWeight: 400,
    fontSize: " 16px",
    lineHeight: "22px",
    color: "#000000"
  },
  lighttext: {
    padding: "3px 0px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#616161"
  },
  lightTextOverflow: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  darktext: {
    color: "#000000"
  }
}));

const NewSponsorEntity = ({ formik, classes, ClickonSponsorEdit }) => {
  const dropDownStyle = useStyles();
  const [sponsorEntityDilogBox, setSponsorEntityDilogBox] = useState(false);
  const [taxIDsearch, SettaxIDsearch] = useState("");
  const [data, setData] = useState([]);
  const [showAddMore, setShowAddMore] = useState("");

  /**************************************************** */
  /********** fetch dropdown list data ********** */
  /**************************************************** */

  const getEntityTaxId = async () => {
    let url = "";
    if (taxIDsearch) {
      url = `?search=${taxIDsearch}`;

      const response = await EntityService.getSponsorTaxList([url]);
      if (response.success) {
        setData(response.data);
      } else {
        setData([]);
      }
    }
  };

  /**************************************************** */
  /*********handle DropDown onClick******************** */
  /**************************************************** */

  const handleTaxIDFromData = (item) => {
    SettaxIDsearch("");
    formik.setFieldValue(`Sponsor.master_sponsor[0].sponsor`, item?.id);
    formik.setFieldValue(`Sponsor.taxId`, item.tax_id);
    formik.setFieldValue(`Sponsor.sponsor_entity_name`, item?.name);
    formik.setFieldValue(`Sponsor.mailing_address`, item?.address?.[0].address);
    formik.setFieldValue(`Sponsor.sponsors_details[0].email`, item?.user_email);
    formik.setFieldValue(`Sponsor.sponsors_details[0].name`, item?.user_name);
    formik.setFieldValue(
      `Sponsor.sponsors_details[0].company_name`,
      item?.user_data[0]?.company_name
    );
    formik.setFieldValue(
      `Sponsor.sponsors_details[0].code`,
      item?.user_data[0]?.country_code
    );
    formik.setFieldValue(
      `Sponsor.sponsors_details[0].phone`,
      item?.user_data[0]?.phone_number
    );
  };

  /**************************************************** */
  /********* Set Fetched data to drop down ************ */
  /**************************************************** */

  const TaxIdList = data?.map((item) => (
    <div
      className={dropDownStyle.mainContainer}
      onClick={() => handleTaxIDFromData(item)}>
      <div
        title={`${item?.tax_id}`}
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        Tax ID (EIN):
        <span className={dropDownStyle.darktext}>{` ${item?.tax_id}`}</span>
      </div>

      <div
        title={item?.name}
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Entity Name:
        <span className={dropDownStyle.darktext}>{` ${
          item?.name || "-"
        }`}</span>
      </div>

      <div
        title={item?.user_email}
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        {`Sponsor Email ID`}:
        <span className={dropDownStyle.darktext}>{` ${
          item?.user_email || "-"
        }`}</span>
      </div>

      <div
        title={item?.address?.[0]?.address}
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        {`Mailing Address`}:
        <span className={dropDownStyle.darktext}>{` ${
          item?.address?.[0]?.address || "-"
        }`}</span>
      </div>

      <div
        title={item?.user_name}
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        {`Sponsor Name`}:
        <span className={dropDownStyle.darktext}>{` ${
          item?.user_name || "-"
        }`}</span>
      </div>

      <div className={`${dropDownStyle.lighttext}`}>
        Managed By:
        <span className={dropDownStyle.darktext}> {item.managed_by}</span>
      </div>

      <div className={`${dropDownStyle.lighttext}`}>
        Partners:
        <span className={dropDownStyle.darktext}>
          {item?.partners?.map((ele, i) => {
            if (i === 0) {
              return ele.name;
            } else {
              return `,${ele.name}`;
            }
          })}
        </span>
      </div>
    </div>
  ));
  /**************************************************** */
  /**************************************************** */

  useEffect(() => {
    getEntityTaxId();
  }, [taxIDsearch]);

  useEffect(() => {
    if (showAddMore === "Entity") {
      setSponsorEntityDilogBox(true);
    } else {
      setSponsorEntityDilogBox(false);
    }
  }, [showAddMore]);

  return (
    <>
      <SponsorEntityDilogBox
        sponsorEntityDilogBox={sponsorEntityDilogBox}
        setSponsorEntityDilogBox={setSponsorEntityDilogBox}
        setShowAddMore={setShowAddMore}
        formik={formik}
        SettaxIDsearch={SettaxIDsearch}
      />

      <Grid container spacing={2}>
        <Grid container spacing={2} item xs={12}>
          <Grid item xl={3} lg={4} sm={6}>
            <FieldHeading title="Tax ID (EIN) or Sponsor Entity" />
            <NewAutoComplete
              disabled={!ClickonSponsorEdit}
              placeholder="Enter TaxId/EntityName"
              // setExistingEmailSelected={setExistingEmailSelected}
              // setClickedOnAddbtn={setClickedOnAddbtn}
              btnText="Entity"
              setShowAddMore={setShowAddMore}
              search={taxIDsearch}
              setSearchQuery={SettaxIDsearch}
              dataList={TaxIdList}
              fieldValue={formik.values.Sponsor.taxId}
              fieldValueError={
                formik.touched?.Sponsor?.taxId &&
                Boolean(formik.errors?.Sponsor?.taxId)
              }
              setFieldValue={(val) =>
                formik.setFieldValue(`Sponsor.taxId`, val)
              }
              formik={formik}
            />
            <div
              style={{ fontSize: "12px", fontWeight: 400, color: "#E13838" }}>
              {formik.touched?.Sponsor?.taxId && formik.errors?.Sponsor?.taxId}
            </div>
          </Grid>
          <Grid item xl={3} lg={4} sm={6}>
            <FieldHeading title="Sponsor Entity Name" />
            <AppTextFieldWhite
              id="sponsor_entity_name"
              placeholder="Add Entity Name"
              variant="standard"
              fullWidth
              disabled
              value={formik.values.Sponsor.sponsor_entity_name}
              onChange={formik.handleChange(`Sponsor.sponsor_entity_name`)}
              error={
                formik.touched?.Sponsor?.sponsor_entity_name &&
                Boolean(formik.errors?.Sponsor?.sponsor_entity_name)
              }
              helperText={
                formik.touched?.Sponsor?.sponsor_entity_name &&
                formik.errors?.Sponsor?.sponsor_entity_name
              }
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xl={3} lg={4} sm={6}>
            <FieldHeading title="Mailing Address" />
            <AppTextFieldWhite
              id="mailing_address"
              placeholder="Add Mailing Address"
              variant="standard"
              fullWidth
              disabled
              value={formik.values.Sponsor.mailing_address}
              onChange={formik.handleChange(`Sponsor.mailing_address`)}
              error={
                formik.touched?.Sponsor?.mailing_address &&
                Boolean(formik.errors?.Sponsor?.mailing_address)
              }
              helperText={
                formik.touched?.Sponsor?.mailing_address &&
                formik.errors?.Sponsor?.mailing_address
              }
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} item xs={12}>
          {
            <FieldArray
              name="Sponsor.sponsors_details"
              render={(arrayHelper) => (
                <>
                  {formik.values.Sponsor.sponsors_details.map(
                    (sponsors, index) => (
                      <NewSposnors
                        key={index}
                        ClickonSponsorEdit={ClickonSponsorEdit}
                        index={index}
                        formik={formik}
                        sponsors={sponsors}
                        SettaxIDsearch={SettaxIDsearch}
                        dropDownStyle={dropDownStyle}
                        arrayHelper={arrayHelper}
                        showAddMore={showAddMore}
                        setShowAddMore={setShowAddMore}
                      />
                    )
                  )}
                  {ClickonSponsorEdit &&
                    arrayHelper.form.values?.Sponsor.sponsors_details
                      ?.length !== 0 && (
                      <Box
                        height={"30px"}
                        minWidth={"105%"}
                        bgcolor={"#FFF"}
                        mt="20px"
                        ml="-12px"
                        mb="15px"
                      />
                    )}
                  {ClickonSponsorEdit && (
                    <div
                      className={classes.addMore}
                      onClick={
                        ClickonSponsorEdit
                          ? () =>
                              arrayHelper.push({
                                email: "",
                                name: "",
                                company_name: "",
                                code: "+1",
                                phone: ""
                              })
                          : () => {}
                      }>
                      <img src={plusAdd} alt="" style={{ marginRight: 8 }} />
                      <div>Add Additional Sponsor</div>
                    </div>
                  )}
                </>
              )}
            />
          }
        </Grid>
      </Grid>
    </>
  );
};

export default NewSponsorEntity;
