import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { truncate } from "helpers/Common";
import React from "react";
import DeleteIcon from "../../assets/icons/delete.svg";

const useStyles = makeStyles(() => ({
  doctableHeading: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  docImg: {
    height: 40,
    width: 40,
    borderRadius: 8
  },
  docText: {
    fontFamily: "Inter Regular",
    fontWeight: 400,
    fontSize: 16
  },
  docDelete: {
    marginRight: 20,
    marginTop: 10
  }
}));

function DocTableManageData({ allDocs, deleteImage }) {
  const classes = useStyles();

  return (
    <Table style={{ marginTop: "20px" }}>
      <TableHead></TableHead>

      {allDocs.map((item) => (
        <TableBody>
          <TableRow
            style={{
              backgroundColor: "#EEF1FC",
              marginTop: "10px"
            }}>
            <TableCell
              style={{ borderBottom: "10px solid #FFFF" }}
              className={classes.docText}>
              {truncate(item?.name, 20)}
            </TableCell>
            <TableCell
              align="right"
              style={{
                borderBottom: "10px solid #FFFF"
              }}>
              <img
                style={{ cursor: "pointer" }}
                onClick={() => deleteImage(item.docId ? item.docId : item.id)}
                src={DeleteIcon}
                className={classes.docDelete}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      ))}
    </Table>
  );
}

export default DocTableManageData;
