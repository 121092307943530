import React from "react";
import { Grid, IconButton, Box, Typography } from "@material-ui/core";
import DocumentsUpload from "./DocumentsUpload/documentsUpload";
import CustomSelect from "components/StyledComponents/Select";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import ClearIcon from "@material-ui/icons/Clear";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import EditIcon from "assets/icons/EditIcon2.svg";

const Occupancy = ({
  id,
  arrayHelper,
  formik,
  noiCategories,
  monthChoices,
  isEditable,
  deleteHandler,
  occsEditableRows,
  makeOccsEditableRow
}) => {
  const totalUnits = formik.values.totalUnits;
  let occupancy = `${(
    (formik.values.occupancy[id].units_leased / totalUnits) *
    100
  ).toFixed(2)}%`;

  if (!totalUnits) {
    occupancy = "Invalid";
  }
  const collections =
    (formik.values.occupancy[id].rentCollected /
      formik.values.occupancy[id].rentPotential) *
    100;

  const currentOccYearSelected = formik.values.occupancy[id].year;

  const sameYearOccs = formik.values.occupancy.map((item) => {
    if (item.year === currentOccYearSelected) {
      return item.month;
    }
    return "no";
  });

  const handleDelete = async () => {
    if (formik.values.occupancy[id].id) {
      deleteHandler(formik.values.occupancy[id].id);
    }
    arrayHelper.remove(id);
  };

  const setDocData = (data) => {
    formik.setFieldValue(`occupancy[${id}].documents`, data);
  };

  return (
    <Grid container spacing={2}>
      <Box
        display="flex no-wrap"
        flexDirection={["row"]}
        justifyContent="space-between"
        alignItems="center"
        style={{
          display: "inline-flex",
          gap: "16px",
          width: "100%",
          padding: 20,
          paddingBottom: id == formik.values.occupancy.length - 1 ? 20 : 0
        }}>
        <Box>
          <SelectWithInfiniteScroll
            disabled={
              isEditable === "true" &&
              !occsEditableRows.includes(id) &&
              formik.values.occupancy[id]?.addNewOccs !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            minValue={
              parseInt(formik.values.occupancy[id]?.year) < 2000
                ? parseInt(formik.values.occupancy[id]?.year)
                : 2000
            }
            selectedItem={formik.values.occupancy[id]?.year ?? 2000}
            onChange={(value) => {
              formik.setFieldValue(`occupancy[${id}].year`, value);
            }}
          />
        </Box>
        <Box>
          <CustomSelect
            disabled={
              isEditable === "true" &&
              !occsEditableRows.includes(id) &&
              formik.values.occupancy[id]?.addNewOccs !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            placeholder="Term"
            items={monthChoices}
            value={formik.values.occupancy[id].month ?? ""}
            onChange={(e) => {
              if (sameYearOccs.includes(e.target.value)) {
                return null;
              }
              formik.setFieldValue(`occupancy[${id}].month`, e.target.value);
            }}
            sameYear={sameYearOccs}
          />
        </Box>
        <Box item xs={3}>
          <AppTextFieldWhite
            disabled={
              isEditable === "true" &&
              !occsEditableRows.includes(id) &&
              formik.values.occupancy[id]?.addNewOccs !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            id={`occupancy[${id}].units_leased`}
            placeholder="Unit Leased"
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.occupancy[id].units_leased}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            onChange={formik.handleChange(`occupancy[${id}].units_leased`)}
          />
        </Box>

        <Box item xs={3}>
          <Typography
            style={{
              width: "150px"
            }}>{`${
            occupancy && occupancy !== "NaN%" && occupancy !== "Infinity%"
              ? occupancy
              : "0%"
          }`}</Typography>
        </Box>

        <Box item xs={3}>
          <AppTextFieldWhite
            disabled={
              isEditable === "true" &&
              !occsEditableRows.includes(id) &&
              formik.values.occupancy[id]?.addNewOccs !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            id={`occupancy[${id}].rentPotential`}
            placeholder="Rent Potential"
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.occupancy[id].rentPotential}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            onChange={formik.handleChange(`occupancy[${id}].rentPotential`)}
          />
        </Box>
        <Box item xs={3}>
          <AppTextFieldWhite
            disabled={
              isEditable === "true" &&
              !occsEditableRows.includes(id) &&
              formik.values.occupancy[id]?.addNewOccs !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            id={`occupancy[${id}].rentCollected`}
            placeholder="Rent Collected"
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.occupancy[id].rentCollected}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            onChange={formik.handleChange(`occupancy[${id}].rentCollected`)}
          />
        </Box>
        <Box item xs={3} style={{ minWidth: "75px" }}>
          <Typography style={{ width: "60px" }}>
            {`${
              collections && collections !== "NaN" && collections !== "Infinity"
                ? collections.toFixed(2)
                : "0"
            }%`}
          </Typography>
        </Box>

        <Box style={{ minWidth: "90px" }}>
          {noiCategories &&
            noiCategories
              .filter((el) => el.name !== "Misc.")
              .map((category) => (
                <DocumentsUpload
                  disabled={
                    isEditable === "true" &&
                    !occsEditableRows.includes(id) &&
                    formik.values.occupancy[id]?.addNewOccs !== "true"
                  }
                  key={category.id}
                  type={category.id}
                  title={category.name}
                  listData={formik.values.occupancy[id].documents}
                  setListData={(list) => setDocData(list)}
                />
              ))}
        </Box>
        {isEditable === "true" && (
          <Box alignSelf={"center"} marginLeft="auto">
            <IconButton
              onClick={() => makeOccsEditableRow(id)}
              style={{ color: "#ff0000", padding: "5px" }}>
              <img src={EditIcon} alt="edit" />
            </IconButton>
          </Box>
        )}
        <Box alignSelf={"center"} marginLeft={isEditable !== "true" && "auto"}>
          <IconButton onClick={handleDelete} style={{ color: "#ff0000" }}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default Occupancy;
