import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Grid,
  CircularProgress
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import styles from "../../myProfile/profile.module.scss";
import profile from "../../../../assets/icons/defaultProfilePic.svg";
import { useSelector } from "react-redux";
import { API, Endpoint, NetworkManager } from "../../../../network/core/index";
import { validationSchemaAddInvestor } from "../../../../helpers/validationSchema";
import AppTextField from "../../../../components/StyledComponents/AppTextFeild";
// import UploadIcon from "../../../../assets/icons/upload.svg";
import Toast from "../../../../components/toast";
import DeleteIcon from "../../../../assets/icons/delete.svg";
import { InvestorServices } from "../../../../network/investorServices";
import FileUploadIcon from "../../../../assets/icons/FileUpload.svg";
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { verifyDocUploadType } from "helpers/Common";

const EditInvestor = () => {
  const [open, setOpen] = useState(true);
  const [initValues, setInitValues] = useState({
    first_name: "-",
    last_name: "-",
    email: "_"
  });

  const [record, setRecord] = useState([]);

  const [allDocs, setAllDocs] = useState([]);

  const [docsLoading, setDocsLoading] = useState(false);
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [imageList, setImageList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const { investorId, userPermissions, user } = useSelector(
    (store) => store.app
  );

  const fetchInvestorDetail = async () => {
    const instance = new NetworkManager(
      new Endpoint(`investor/${investorId}/`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      let temp = resp.data;
      let tempData = { ...initValues };
      tempData["first_name"] = temp.first_name;
      tempData["last_name"] = temp.last_name;
      tempData["email"] = temp.email;
      setRecord(temp.documents);
      let test = [];
      for (let i = 0; i < temp.documents.length; i++) {
        test.push(temp.documents[i].document_media);
      }
      setAllDocs(test);
      setInitValues(tempData);
    } else {
      Toast.showErrorToast("Not Working");
    }
  };

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  const handleSubmit1 = async (val) => {
    const payload = {
      first_name: val.first_name,
      last_name: val.last_name,
      documents: imageList
    };
    const instance = new NetworkManager(
      new Endpoint(`investor/${investorId}/`, "PATCH"),
      payload
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      setOpen(false);
      history.push("/u/investors");
      Toast.showSuccessToast("Investor Profile Updated Successfully");
    } else {
      Toast.showErrorToast("Error Updating Profile");
    }
  };

  const deleteImage = async (id) => {
    setAllDocs((prev) => {
      return prev.filter((item) => item.id != id);
    });
    setImageList((prev) => {
      return prev.filter((item) => item.id != id);
    });
    const index = record.findIndex((item) => item.document_media.id === id);
    const deleteid = record[index].id;

    const resp = await InvestorServices.deleteInvestorDocs(deleteid);
    if (resp.success) {
      Toast.showSuccessToast("Document Deleted Successfully");
    } else {
      Toast.showErrorToast("Unable To Delete Document");
    }
  };

  const onFileDrop = (e) => {
    if (!verifyDocUploadType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const instance = new NetworkManager(API.COMMON.IMAGE_UPLOAD, data);
    const resp = await instance.httpRequest(true, true);
    if (resp.success) {
      setDocsLoading(false);
      const updatedFileList = [...imageList, resp.data];
      setImageList(updatedFileList);
      const updateAllDocs = [...allDocs, resp.data];
      setAllDocs(updateAllDocs);
      Toast.showSuccessToast("Documents Upload Successfully");
    } else {
      Toast.showErrorToast("Error Uploading docs");
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "INVESTORS",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_update) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    fetchInvestorDetail();
  }, [investorId]);

  useEffect(() => {
    checkPermissions();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{
          backgroundColor: "rgba(235, 239, 255, 0.6)",
          backdropFilter: "blur(26)"
        }}>
        <DialogContent style={{ width: 580 }}>
          <Grid
            container
            style={{
              paddingRight: 40,
              paddingLeft: 30,
              paddingTop: 20,
              paddingBottom: 30
            }}>
            <Grid
              container
              xs={12}
              justifyContent="space-between"
              alignItems="center">
              <Grid container item direction="row" xs={10} alignItems="center">
                <Grid item>
                  <img
                    src={profile}
                    style={{ width: 64, height: 64, marginRight: 20 }}
                  />
                </Grid>
                <Grid item alignItems="center">
                  <Typography variant="h4">Edit Profile</Typography>
                </Grid>
              </Grid>
              <Grid item xs={1}>
                <img
                  src={CloseIcon}
                  onClick={handleClose}
                  style={{ cursor: "pointer", marginTop: 6 }}
                />
              </Grid>
            </Grid>
            <Formik
              initialValues={initValues}
              validationSchema={validationSchemaAddInvestor}
              enableReinitialize={true}
              onSubmit={(values) => {
                handleSubmit1(values);
              }}>
              {({ errors, touched, values, handleSubmit, handleChange }) => (
                <Form>
                  <Grid
                    container
                    xs={12}
                    direction="row"
                    style={{ marginTop: 30 }}>
                    <Grid
                      xs={6}
                      item
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.labelsEdit}>First Name</label>
                      <AppTextField
                        id="first_name"
                        placeholder="Enter Investor First Name"
                        variant="standard"
                        value={values.first_name}
                        style={{ width: 222 }}
                        onChange={handleChange("first_name")}
                        error={touched.first_name && Boolean(errors.first_name)}
                        helperText={touched.first_name && errors.first_name}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      item
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label
                        className={styles.labelsEdit}
                        style={{ marginLeft: 10 }}>
                        Last Name
                      </label>
                      <AppTextField
                        id="last_name"
                        placeholder="Enter Investor Last Name"
                        variant="standard"
                        value={values.last_name}
                        style={{ width: 222, marginLeft: 10 }}
                        onChange={handleChange("last_name")}
                        error={touched.last_name && Boolean(errors.last_name)}
                        helperText={touched.last_name && errors.last_name}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{ disableUnderline: true }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" style={{ marginTop: 20 }}>
                    <Grid
                      item
                      xs={12}
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.labelsEdit}>Email</label>
                      <AppTextField
                        id="email"
                        placeholder="Enter investor name"
                        variant="standard"
                        value={values.email}
                        onChange={handleChange("email")}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{ disableUnderline: true }}
                        disabled={true}
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: 20 }}>
                    <Grid item xs={12}>
                      <div
                        ref={wrapperRef}
                        className={styles.drop_file_input}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}>
                        <div>
                          {docsLoading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              {/* <img
                                src={UploadIcon}
                                className={styles.uploadImage}
                              /> */}
                              <p className={styles.droptext}>
                                Drag and drop files here or&nbsp;
                                <span
                                  style={{ color: "#5775E5", fontWeight: 600 }}>
                                  Browse
                                </span>
                              </p>
                            </>
                          )}
                        </div>
                        <input type="file" value="" onChange={onFileDrop} />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      {allDocs.length > 0 ? (
                        <>
                          {allDocs.map((item) => (
                            <>
                              <div
                                key={item.id}
                                style={{
                                  minHeight: 68,
                                  backgroundColor: "#E5E9ED",
                                  borderRadius: 4,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  paddingLeft: 20,
                                  marginTop: 20
                                }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                  }}>
                                  {item.image_type.toLowerCase() === "png" ||
                                  item.image_type.toLowerCase() === "jpeg" ? (
                                    <>
                                      <img
                                        src={item.file}
                                        style={{
                                          height: 40,
                                          width: 40,
                                          borderRadius: 8
                                        }}
                                      />
                                    </>
                                  ) : (
                                    <img
                                      src={FileUploadIcon}
                                      style={{
                                        height: 40,
                                        width: 40,
                                        borderRadius: 8
                                      }}
                                    />
                                  )}

                                  <div
                                    style={{
                                      fontFamily: "Inter Regular",
                                      fontSize: 18,
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      marginLeft: 10,
                                      width: 320,
                                      lineHeight: 2
                                    }}>
                                    {item.name}
                                  </div>
                                </div>
                                <div
                                  style={{ cursor: "pointer", marginTop: 10 }}
                                  onClick={() => deleteImage(item.id)}>
                                  <img
                                    src={DeleteIcon}
                                    style={{ marginRight: 20 }}
                                  />
                                </div>
                              </div>
                            </>
                          ))}
                        </>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    xs={12}
                    justifyContent="center"
                    direction="row"
                    style={{ marginTop: 10 }}>
                    <div className={styles.changepasswordbutton}>
                      <button
                        type="submit"
                        className={styles.updatebtn}
                        onClick={handleSubmit}>
                        Save
                      </button>
                      <button
                        className={styles.cancelbtn}
                        type="button"
                        onClick={handleClose}>
                        Cancel
                      </button>
                    </div>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditInvestor;
