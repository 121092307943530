import Actions from "../actions/appActions";
let initialState = {
  user: {},
  authToken: null,
  isLogged: false,
  investorEmail: "",
  investorName: null,
  investorId: "",
  sponsorId: "",
  entityId: "",
  distributionId: "",
  resetPasswordOtp: "",
  investmentActiveStep: 0,
  investmentCompletedSteps: -1,
  propertyData: [],
  investmentID: null,
  drawer: false,
  investorStatus: null,
  investmentDetailId: null,
  sponsorDetailId: null,
  investorDetailId: "",
  entitiesDetailData: null,
  propertiesDetailId: "null",
  propertiesId: "",
  contactsId: null,
  isPrimary: null,
  manageDataK1Id: {},
  roleId: "",
  subAdminId: "",
  userPermissions: [],
  documentId: "",
  dateRange: {},
  drawerOpen: false,
  entitiesIdAndType: {},
  uploadTemplateActiveStep: 0,
  manageDataGroupBy: "K1",
  allRoles: {},
  equity_amount: "",
  outStandingLoan: "",
  LoanSectionOutStandingLoan: "",
  openAddK1PopUp: false,
  openEditk1PopUp: false,
  openSendEmailContact: false,
  ContactEmailId: "",
  adminEmailId: "",
  retainFilter: {
    searchQuery: "",
    checked: [],
    status: "All",
    addedVia: "added_via"
  },
  count: 10,
  page: 1,
  pageResetValue: 0,
  pageSize: 10,
  retainManageColumn: []
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOGIN:
      return {
        ...state,
        user: action.data
      };
    case Actions.LOGOUT:
      return {
        ...state,
        isLogged: false,
        authToken: null
      };
    case Actions.SET_TOKEN:
      return {
        ...state,
        authToken: action.data,
        isLogged: true
      };
    case Actions.SET_EMAIL:
      return {
        ...state,
        investorEmail: action.data
      };
    case Actions.SET_INVESTOR_NAME:
      return {
        ...state,
        investorName: action.data
      };
    case Actions.SET_INVESTOR_ID:
      return {
        ...state,
        investorId: action.data
      };
    case Actions.SET_INVESTOR_DETAIL_ID:
      return {
        ...state,
        investorDetailId: action.data
      };
    case Actions.SET_SPONSOR_ID:
      return {
        ...state,
        sponsorId: action.data
      };
    case Actions.SET_ENTITY_ID:
      return {
        ...state,
        entityId: action.data
      };
    case Actions.SET_OTP:
      return {
        ...state,
        resetPasswordOtp: action.data
      };
    case Actions.SET_ADD_INVESTMENT_STEP:
      return {
        ...state,
        investmentActiveStep: action.data,
        investmentCompletedSteps: Math.max(
          action.data - 1,
          state.investmentCompletedSteps
        )
      };
    case Actions.RESET_ADD_INVESTMENT_STEP:
      return {
        ...state,
        investmentActiveStep: 0,
        investmentCompletedSteps: -1
      };
    case Actions.ADD_INVESTMENT_NEXT_STEP:
      return {
        ...state,
        investmentCompletedSteps: state.investmentActiveStep,
        investmentActiveStep: state.investmentActiveStep + 1
      };
    case Actions.SET_PROPERTY_DATA:
      return {
        ...state,
        propertyData: action.data
      };
    case Actions.ADD_INVESTMENT_ID:
      return {
        ...state,
        investmentID: action.data
      };
    case Actions.SET_INVESTOR_STATUS:
      return {
        ...state,
        investorStatus: action.data
      };
    case Actions.SET_INVESTMENT_DETAIL_ID:
      return {
        ...state,
        investmentDetailId: action.data
      };
    case Actions.SET_SPONSOR_DETAIL_ID:
      return {
        ...state,
        sponsorDetailId: action.data
      };
    case Actions.SET_PROPERTIES_DETAIL_ID:
      return {
        ...state,
        propertiesDetailId: action.data
      };
    case Actions.SET_ENTITIES_DETAIL_DATA:
      return {
        ...state,
        entitiesDetailData: action.data
      };
    case Actions.SET_DISTRIBUTION_ID:
      return {
        ...state,
        distributionId: action.data
      };
    case Actions.SET_PROPERTIES_ID:
      return {
        ...state,
        propertiesId: action.data
      };
    case Actions.SET_CONTACTS_ID:
      return { ...state, contactsId: action.data };

    case Actions.SET_IsPrimary:
      return { ...state, isPrimary: action.data };

    case Actions.SET_MANAGEDATAK1_ID:
      return {
        ...state,
        manageDataK1Id: action.data
      };
    case Actions.SET_ROLE_ID:
      return {
        ...state,
        roleId: action.data
      };
    case Actions.SET_SUB_ADMIN_ID:
      return {
        ...state,
        subAdminId: action.data
      };
    case Actions.SET_USER_PERMISSIONS:
      return {
        ...state,
        userPermissions: action.data
      };
    case Actions.SET_DOCUMENT_ID:
      return {
        ...state,
        documentId: action.data
      };
    case Actions.SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.data
      };
    case Actions.SET_DRAWER_OPEN:
      return {
        ...state,
        drawer: action.data
      };

    case Actions.SET_ENTITIES_ID_TYPE:
      return {
        ...state,
        entitiesIdAndType: action.data
      };
    case Actions.SET_UPLOAD_TEMPLATE_STEP:
      return {
        ...state,
        uploadTemplateActiveStep: action.data
      };
    case Actions.SET_GROUPBY:
      return {
        ...state,
        manageDataGroupBy: action.data
      };
    case Actions.SET_ALL_ROLES:
      return {
        ...state,
        allRoles: action.data
      };
    case Actions.SET_EQUITY_AMOUNT:
      return {
        ...state,
        equity_amount: action.data
      };
    case Actions.SET_OUTSTANDING_LOAN:
      return {
        ...state,
        outStandingLoan: action.data
      };
    case Actions.SET_LOANSECTION_BAR_VALUE:
      return {
        ...state,
        LoanSectionOutStandingLoan: action.data
      };

    case Actions.OPEN_ADD_MANAGE_DATA:
      return {
        ...state,
        openAddK1PopUp: action.data
      };

    case Actions.OPEN_EDIT_MANAGE_DATA:
      return {
        ...state,
        openEditK1PopUp: action.data
      };
    case Actions.SEND_EMAIL_CONTACT:
      return {
        ...state,
        openSendEmailContact: action.data
      };
    case Actions.SEND_EMAIL_ID_CONTACT:
      return {
        ...state,
        ContactEmailId: action.data
      };
    case Actions.ADMIN_EMAIL_ID:
      return {
        ...state,
        adminEmailId: action.data
      };
    case Actions.RETAIN_FILTERS:
      return {
        ...state,
        retainFilter: action.data
      };
    case Actions.RETAIN_COUNT:
      return {
        ...state,
        count: action.data
      };
    case Actions.RETAIN_PAGE:
      return {
        ...state,
        page: action.data
      };
    case Actions.RETAIN_PAGERESETVALUE:
      return {
        ...state,
        pageResetValue: action.data
      };
    case Actions.RETAIN_MANAGECOLUMN:
      return {
        ...state,
        retainManageColumn: action.data
      };
    case Actions.RETAIN_PAGESIZE:
      return {
        ...state,
        pageSize: action.data
      };
    default:
      return state;
  }
};
export default AppReducer;
