import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  main: {
    // flexGrow: 1
    // width: 260,
    // height: 110,
    // borderRadius: 8,
    // marginLeft: "12px",
    // marginTop: "12px",
    // display: "flex",
    // flexDirection: "row",
    // justifyContent: "space-between"
  },
  sponsorIcon: {
    height: 44,
    width: 44,
    marginTop: 28,
    marginLeft: 10
  },
  cardTitle: {
    marginTop: 24,
    fontSize: 14,
    color: "#BBBBBB",
    fontFamily: "Inter Regular",
    fontWeight: 600
  },
  cardContent: {
    fontSize: 26,
    fontFamily: "Inter Regular",
    fontWeight: 700,
    color: "#616161"
  }
}));

const SponsorCards = ({ Title, count, icon, link = false }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <>
      <Grid
        onClick={handleClick}
        container
        className={classes.main}
        style={{
          background: "white",
          cursor: link ? "pointer" : ""
        }}
        direction="row">
        <Grid item>
          <img src={icon} className={classes.sponsorIcon} />
        </Grid>
        <Grid
          item
          style={{ paddingLeft: 20, paddingBottom: 12, paddingRight: 8 }}>
          <Typography className={classes.cardTitle}>{Title}</Typography>
          <div className={classes.cardContent}>{count}</div>
        </Grid>
      </Grid>
    </>
  );
};

export default SponsorCards;
