import { CircularProgress, Menu, MenuItem } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "assets/icons/cross.svg";
import styles1 from "../../pages/private/investments/investments.module.css";
import SearchInput from "components/Search";
import React, { useEffect, useState, useRef, Fragment } from "react";
import { truncate } from "helpers/Common";

const useStyles = makeStyles(() => ({
  root: {
    // maxHeight: "192px",
  },
  heading: {
    color: "#0C0C0C",
    fontFamily: "Inter Regular",
    fontSize: "16px",
    fontWeight: 600
  },
  summaryRoot: {
    minHeight: "48px",
    padding: "0px 23px"
  },
  iconColor: {
    color: "black"
  },
  details: {
    padding: "0px 16px"
  },
  listIcon: {
    minWidth: "0px"
  },
  title: {
    color: "#191919",
    fontFamily: "Inter Regular",
    fontSize: "14px",
    fontWeight: 400,
    flex: 1,
    marginBottom: 5
  },
  titleTag: {
    color: "#A0A0A0",
    fontFamily: "Inter Regular",
    fontSize: "16px",
    fontWeight: 500,
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "auto"
  },
  SearchCtr: {
    padding: "0px 10px",
    marginBottom: "10px"
  },
  text: {
    fontFamily: "Inter SemiBold",
    color: "red"
  },
  listItemText: {
    fontSize: "14px", //Insert your required size
    fontFamily: "Inter Regular !important",
    fontWeight: "400 !important"
  },
  chipContainer: {
    width: "290px",
    overflowX: "scroll"
  },
  listCtr: {
    maxHeight: "192px",
    overflowY: "scroll",
    marginTop: 10
  },
  loaderCtr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0"
  },
  container: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: "8px 12px",
    borderRadius: 8,
    border: "1px solid #EBEFFF",
    cursor: "pointer",
    fontFamily: "Inter semibold",
    fontSize: 14,
    fontWeight: 600,
    width: "260px"
  },
  mainMenu: {
    width: 353,
    maxHeight: 257,
    boxSizing: "border-box",
    border: "1px solid #EBEFFF",
    borderRadius: 16,
    backgroundColor: "#FFFFFF"
  },
  searchCtr: {
    width: "100%",
    padding: "20px 20px 0",
    "& div:first-child": {
      width: "100% !important"
    }
  },
  expand: {
    marginLeft: 15,
    marginBottom: "-5px"
  },
  listContainer: {
    width: "100%",
    marginTop: 5,
    maxHeight: 160,
    boxSizing: "border-box",
    overflowY: "scroll"
  },
  itemContainer: {
    padding: "5px 15px 2px 20px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: "#F5F7FB"
    }
  },
  itemText: {
    fontFamily: "Inter semibold",
    fontSize: 14,
    fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  chipsCtr: {
    marginTop: "2px",
    marginRight: "10px",
    display: "flex"
  }
}));
export default function AccordionFilter({
  getData,
  checked,
  title,
  handleToggle,
  index,
  handleDelete,
  isStaticData = false,
  placeholder
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const isLoadingRef = useRef(false);
  const searchRef = useRef(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsExpanded(!isExpanded);
  };

  const getName = (value) => {
    if (!value) {
      return "";
    }
    if (value.label) {
      return value.label;
    }

    if (!value.name) {
      return `${value.first_name} ${value.last_name}`;
    }
    if (value.name) {
      return value.name;
    }
  };

  const getFilterChips = () => {
    return (
      <div className={classes.chipsCtr}>
        {checked[index]?.value?.map((selected) => (
          <div
            key={Math.random()}
            className={styles1.chips}
            style={{ minWidth: "fit-content" }}>
            <div className={styles1.chipsText}>{selected.name}</div>
            <div
              onClick={() => handleDelete(checked?.[index].key, selected.id)}>
              <img width="12px" height="10px" src={CloseIcon} alt="cross" />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const loadMore = async () => {
    isLoadingRef.current = true;
    const resp = await getData(page + 1, search);
    setMaxPage(resp.maxPage || 1);
    setPage((prev) => prev + 1);
    setData((prev) => [...prev, ...resp.data]);
    isLoadingRef.current = false;
  };

  const handleScroll = (e) => {
    if (isLoadingRef.current) {
      return;
    }
    if (
      page < maxPage &&
      e.target.scrollHeight - e.target.scrollTop - 25 <= e.target.clientHeight
    ) {
      loadMore();
    }
  };

  async function fetchDataOne() {
    if (!searchRef.current) {
      searchRef.current = true;
      return;
    }
    if (isStaticData) {
      if (search) {
        setData(
          getData.filter((el) =>
            el.name.toLowerCase().includes(search.toLowerCase())
          )
        );
      } else {
        setData(getData);
      }
    } else {
      setPage(1);
      const resp = await getData(1, search);
      setMaxPage(resp.maxPage);
      setData(resp.data);
    }
  }

  useEffect(() => {
    fetchDataOne();
  }, [search, isStaticData]);

  async function fetchDataTwo() {
    let resp = {};
    if (isStaticData) {
      resp.data = getData;
      setMaxPage(1);
    } else {
      resp = await getData(1, "");
      setMaxPage(resp.maxPage);
    }
    setData(resp.data);
  }

  useEffect(() => {
    fetchDataTwo();
  }, [isStaticData]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.title}>{title}</div>
        <div className={classes.container} onClick={handleClick}>
          <span className={classes.titleTag}>
            {checked[index]?.value.length
              ? checked[index]?.value?.map((selected, i) => (
                  <Fragment key={Math.random()}>
                    {i === 0 ? `${selected.name}` : `,${selected.name}`}
                  </Fragment>
                ))
              : placeholder ?? `Select ${title}`}
          </span>
          <div className={classes.expand}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        </div>

        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
          style={{ top: "32px" }}
          keepMounted
          open={open}
          onClose={handleClose}>
          <div className={classes.chipContainer}>{getFilterChips()}</div>

          <div className={classes.searchCtr}>
            <SearchInput setQuery={(e) => setSearch(e)} />
          </div>
          <div className={classes.listCtr} onScroll={handleScroll}>
            {data.map((value) => {
              return (
                <MenuItem
                  key={value?.id}
                  style={{ padding: 0 }}
                  onClick={() =>
                    handleToggle(
                      title,
                      value.id ? value.id : value.value,
                      getName(value)
                    )
                  }>
                  <div className={classes.itemContainer}>
                    <div className={classes.itemText} title={getName(value)}>
                      {truncate(getName(value), 25)}
                    </div>
                  </div>
                </MenuItem>
              );
            })}
            {page < maxPage ? (
              <div className={classes.loaderCtr}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  classes={{
                    circle: classes.circle
                  }}
                  size={30}
                  thickness={4}
                />
              </div>
            ) : null}
          </div>
        </Menu>
      </div>
    </>
  );
}
