import React, { useEffect, useState } from "react";
import styles from "./graphComponent.module.scss";

const ToggleButton = ({ toggle, setToggle }) => {
  const [switches, setSwitches] = useState(false);

  useEffect(() => {
    setSwitches(!switches);
  }, [toggle]);

  return (
    <div className={styles.ToggleContainer}>
      <div
        className={switches ? styles.tc_1 : styles.tc_2}
        onClick={() => setToggle("Monthly")}
      >
        <h4>Monthly</h4>
      </div>
      <div
        className={switches ? styles.tc_2 : styles.tc_1}
        onClick={() => setToggle("Quaterly")}
      >
        <h4>Quarterly</h4>
      </div>
    </div>
  );
};

export default ToggleButton;
