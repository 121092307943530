import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import GetCustomSelectMenu from "./customDate";
import { withStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const ColorButton = withStyles(() => ({
  root: {
    color: " black",
    height: "45px",
    background: "white",
    fontFamily: "Inter Regular",
    fontWeight: "500",
    fontSize: "14px",
    border: "1px solid #ebefff",
    wordWrap: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    borderRadius: "16px",
    padding: "23px 15px 23px 15px",
    "&:hover": {
      background: "white"
    }
  }
}))(Button);

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
    padding: "16px",
    borderRadius: "16px"
  }
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center"
    }}
    {...props}
  />
));

export default function DateRange() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ width: "140px" }}>
      <ColorButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disableTouchRipple>
        Date-Range
      </ColorButton>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <GetCustomSelectMenu handleClose={handleClose} />
      </StyledMenu>
    </div>
  );
}
