import React from "react";
import styles from "./primary.module.scss";

const UpdateBtn = (props) => {
  return (
    <button {...props} className={styles.updateBtn}>
      <h4>Update</h4>
    </button>
  );
};

export default UpdateBtn;
