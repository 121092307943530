import { API, NetworkManager } from "./core";

export class DistributionService {
  static async getStats() {
    const instance = new NetworkManager(API.DISTRIBUTION.GET_STATS);
    return await instance.httpRequest();
  }

  static async getYears(params) {
    const instance = new NetworkManager(API.DISTRIBUTION.GET_YEARS, {}, params);
    return await instance.httpRequest();
  }

  static async getDistributions(params) {
    const instance = new NetworkManager(
      API.DISTRIBUTION.GET_DISTRIBUTIONS,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async get_Pdf_Or_Csv(params) {
    const instance = new NetworkManager(
      API.DISTRIBUTION.EXPORT_PDF_CSV,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async deleteDistribution(params) {
    const instance = new NetworkManager(
      API.DISTRIBUTION.DELETE_DISTRIBUTION,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async getDistributionData(params) {
    const instance = new NetworkManager(
      API.DISTRIBUTION.GET_DISTRIBUTIONS,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async editDistribution(params, payload) {
    const instance = new NetworkManager(
      API.DISTRIBUTION.EDIT_DISTRIBUTION,
      payload,
      params
    );
    return await instance.httpRequest();
  }

  static async getInvestorList(params) {
    const instance = new NetworkManager(
      API.DISTRIBUTION.GET_INVESTORLIST,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
