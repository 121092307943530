import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  main: {
    width: "auto",
    marginRight: "50px",
    marginBottom: "33px",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap"
  },
  noiYear: {
    display: "flex",
    alignItems: "center",
    gap: 20
  }
}));

const InvestorCards = ({
  Title,
  count,
  icon,
  style,
  link = false,
  customColor,
  showDollor = false,
  backgroundColor = "white",
  noiCardYear = "",
  noiYear = "",
  downArrow,
  showSideData = false,
  year = "",
  month = ""
}) => {
  const classes = useStyles();
  const color = customColor ? customColor : "#BBBBBB";
  const history = useHistory();
  const handleClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <>
      <Grid
        onClick={handleClick}
        container
        className={classes.main}
        style={{
          background: backgroundColor,
          cursor: link ? "pointer" : "",
          ...style
        }}
        direction="row">
        <Grid item>
          <img
            src={icon}
            style={{ height: 44, width: 44, marginTop: 24, marginLeft: 10 }}
          />
        </Grid>
        <Grid
          item
          style={{ paddingLeft: 8, paddingBottom: 12, paddingRight: 8 }}>
          <Grid container direction="row" justifyContent="flex-start">
            <Grid item>
              <Typography
                style={{
                  marginTop: 20,
                  fontSize: 14,
                  fontFamily: "Inter Regular",
                  fontWeight: 600,
                  color: color,
                  whiteSpace: "nowrap"
                }}>
                {Title}
              </Typography>
            </Grid>
            {noiCardYear !== "" ? (
              <Grid item>
                <div
                  style={{
                    padding: "4px 14px",
                    borderRadius: 153,
                    backgroundColor: "#EBEFFF",
                    fontFamily: "Inter Bold",
                    fontSize: 14,
                    color: "#445EBE",
                    marginTop: 20,
                    marginLeft: 20
                  }}>
                  {noiCardYear}
                </div>
              </Grid>
            ) : null}
          </Grid>

          {noiYear !== "" ? (
            <div className={classes.noiYear}>
              <div
                style={{
                  fontSize: 26,
                  fontFamily: "Inter Regular",
                  fontWeight: 700,
                  color: "#616161",
                  lineHeight: "31px"
                }}>
                {showDollor ? "$" : ""}
                {count}
              </div>
              <div
                style={{
                  padding: "8px 12px",
                  borderRadius: 153,
                  backgroundColor: "rgba(225, 56, 56, 0.05)",
                  fontFamily: "Inter Regular",
                  fontSize: 14,
                  fontWeight: 600,
                  color: "#E13838"
                }}>
                {noiYear}
                {downArrow && (
                  <img src={downArrow} alt="icn" style={{ paddingLeft: 5 }} />
                )}
              </div>
            </div>
          ) : (
            <div
              onClick={handleClick}
              style={{
                fontSize: 26,
                fontFamily: "Inter Regular",
                fontWeight: 700,
                color: "#616161",
                lineHeight: "31px",
                cursor: link ? "pointer" : ""
              }}>
              {showDollor ? "$" : ""}
              {count}
              {showSideData == true ? (
                <span
                  style={{
                    marginLeft: "10px",
                    whiteSpace: "nowrap",
                    padding: "8px 10px 8px 12px",
                    backgroundColor: "#FFF4EA",
                    borderRadius: 153,
                    color: "#FF9029",
                    fontWeight: 600,
                    fontSize: "12px"
                  }}>
                  {`${year} | ${month}`}
                </span>
              ) : (
                false
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default InvestorCards;
