import React from "react";
import { makeStyles } from "@material-ui/core";

const useBtnStyle = makeStyles(() => ({
  mainContainer: {
    backgroundColor: "#ffffff",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 50,
    paddingRight: 50,
    fontFamily: "Inter Regular",
    fontWeight: 600,
    fontSize: "18px",
    color: "#445EBE",
    borderRadius: 8,
    border: "1px solid #445EBE",
    cursor: "pointer",
    whiteSpace: "nowrap",
    width: 200,
    textAlign: "center"
  }
}));

const CancelBtn = ({ style = {}, onClick, extraClasses = "" }) => {
  const classes = useBtnStyle();

  return (
    <div
      style={style}
      className={`${classes.mainContainer} ${extraClasses}`}
      onClick={onClick}>
      Cancel
    </div>
  );
};

export default CancelBtn;
