import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import React from "react";
import styles from "./Investment.module.scss";
import DollerActive from "../../../../assets/icons/tab/DollerActive.svg";
import DollerInactive from "../../../../assets/icons/tab/DollerInactive.svg";
import investorActive from "../../../../assets/icons/tab/investorActive.svg";
import investorInactive from "../../../../assets/icons/tab/investorInactive.svg";
import PropertyActive from "../../../../assets/icons/tab/PropertyActive.svg";
import PropertyInactive from "../../../../assets/icons/tab/PropertyInactive.svg";
import LoanInActive from "../../../../assets/icons/tab/LoansActive.svg";
import LoanInactive from "../../../../assets/icons/tab/LoansInactive.svg";
import DistributionActive from "../../../../assets/icons/tab/DistributionActive.svg";
import DistributionInactive from "../../../../assets/icons/tab/DistributionInactive.svg";
import whiteCircle from "../../../../assets/icons/tab/whiteCircle.svg";
import brownCircle from "../../../../assets/icons/tab/brownCircle.svg";

const InvestmentToggleSwitch = ({ setSelectedIndex, selectedIndex }) => {
  const investmentHeaderList = [
    {
      id: 0,
      name: "Analytics",
      color: "#5775E5",
      textColor: "#fff",
      inActiveIcon: brownCircle,
      activeIcon: whiteCircle
    },
    {
      id: 1,
      name: "Overview",
      color: "#5775E5",
      textColor: "#fff",
      inActiveIcon: DollerInactive,
      activeIcon: DollerActive
    },
    {
      id: 2,
      name: "Investors",
      textColor: "#BBBBBB",
      inActiveIcon: investorInactive,
      activeIcon: investorActive
    },
    {
      id: 3,
      name: "Property",
      textColor: "#BBBBBB",
      inActiveIcon: PropertyInactive,
      activeIcon: PropertyActive
    },
    {
      id: 4,
      name: "Loans",
      textColor: "#BBBBBB",
      inActiveIcon: LoanInactive,
      activeIcon: LoanInActive
    },

    {
      id: 5,
      name: "Distributions",
      textColor: "#BBBBBB",
      inActiveIcon: DistributionInactive,
      activeIcon: DistributionActive
    }
  ];

  return (
    <>
      {investmentHeaderList.map((item, index) => (
        <Grid
          item
          key={index}
          onClick={() => setSelectedIndex(item.id)}
          className={
            item.id === selectedIndex
              ? styles.toggleSwichActive
              : styles.toggleSwichInActive
          }>
          <div style={{ marginRight: 15, marginTop: 2 }}>
            <img
              alt="img"
              src={
                item.id === selectedIndex ? item.activeIcon : item.inActiveIcon
              }
              width={"22px"}
              height={"22px"}
            />
          </div>
          <Typography
            className={
              item.id === selectedIndex
                ? styles.toggleSwichActiveText
                : styles.toggleSwichInActiveText
            }>
            {item.name}
          </Typography>
        </Grid>
      ))}
    </>
  );
};

export default InvestmentToggleSwitch;
