import { parse } from "date-fns";
import * as yup from "yup";
const validationSchemaLogin = yup.object({
  email: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  password: yup.string().required("Password Required")
});
const validationSchemaEditInvestor = yup.object({
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  name: yup.string().required("Investor Name Is Required")
});
const validationSchemaAddEntityInvestorExsisting = yup.object({
  entityName: yup
    .string()
    .required("Entity Name Is Required")
    .max(100, "No More Than 100 Characters"),
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),

  mailingAddress: yup
    .string()
    .required("Mailing Address Is Required")
    .max(100, "No More Than 100 Characters"),

  taxId: yup.string().required("Tax ID Is Required"),
  partnerSet: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Partner Name Is Required")
        .max(100, "No More Than 100 Characters"),
      email: yup
        .string()
        .required("Email Is A Required Field")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "*Invalid Email Address"
        )
    })
  )
});
const validationSchemaAddEntityInvestorNotExsisting = yup.object({
  entityName: yup
    .string()
    .required("Entity Name Is Required")
    .max(100, "No More Than 100 Characters"),
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  first_name: yup
    .string()
    .required("First Name Is Required")
    .max(100, "No More Than 100 Characters"),
  last_name: yup
    .string()
    .required("Last Name Is Required")
    .max(100, "No More Than 100 Characters"),
  mailingAddress: yup
    .string()
    .required("Mailing Address Is Required")
    .max(100, "No More Than 100 Characters"),
  phone: yup
    .string()
    .required("Phone Number Is Required")
    .min(10, "Enter Valid Mobile Number")
    .max(12, "You Can Enter Max 12 Digit Mobile Number"),
  countryCode: yup
    .string()
    .required("Country Code Is Required")
    .min(1, "Enter Valid Country Code")
    .max(4, "You Can Enter Max 4 Digit Country Code")
    .nullable(),
  taxId: yup.string().required("Tax ID Is Required"),
  partnerSet: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Partner Name Is Required")
        .max(100, "No More Than 100 Characters"),
      email: yup
        .string()
        .required("Email Is A Required Field")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "*Invalid Email Address"
        )
    })
  )
});
const validationSchemaAddEntitySponsorExsisting = yup.object({
  entityName: yup
    .string()
    .required("Entity Name Is Required")
    .max(100, "No More Than 100 Characters"),
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  mailingAddress: yup
    .string()
    .required("Mailing Address Is Required")
    .max(100, "No More Than 100 Characters"),

  taxId: yup
    .string()
    .required("Tax ID Is Required")
    .max(100, "No More Than 100 Characters"),
  partnerSet: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Partner Name Is Required")
        .max(100, "No More Than 100 Characters"),
      email: yup
        .string()
        .required("Email Is A Required Field")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "*Invalid Email Address"
        )
    })
  )
});
const validationSchemaAddEntitySponsorNotExsisting = yup.object({
  entityName: yup
    .string()
    .required("Entity Name Is Required")
    .max(100, "No More Than 100 Characters"),
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "*Invalid Email Address"
    ),
  first_name: yup
    .string()
    .required("First Name Is Required")
    .max(100, "No More Than 100 Characters"),
  last_name: yup
    .string()
    .required("Last Name Is Required")
    .max(100, "No More Than 100 Characters"),
  mailingAddress: yup
    .string()
    .required("Mailing Address Is Required")
    .max(100, "No More Than 100 Characters"),
  company_name: yup
    .string()
    .required("Company Name Is Required")
    .max(100, "No More Than 100 Characters"),
  phone: yup
    .string()
    .required("Phone Number Is Required")
    .min(10, "Enter Valid Mobile Number")
    .max(12, "You Can Enter Max 12 Digit Mobile Number"),
  countryCode: yup
    .string()
    .required("Country Code Is Required")
    .min(1, "Enter Valid Country Code")
    .max(4, "You Can Enter Max 4 Digit Country Code")
    .nullable(),
  taxId: yup
    .string()
    .required("Tax ID Is Required")
    .max(100, "No More Than 100 Characters"),
  partnerSet: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Partner Name Is Required")
        .max(100, "No More Than 100 Characters"),
      email: yup
        .string()
        .required("Email Is A Required Field")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "*Invalid Email Address"
        )
    })
  )
});

const validationSchemaAddInvestmentInvestor = yup.object({
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  first_name: yup
    .string()
    .required("First Name Is Required")
    .max(100, "No More Than 100 Characters"),
  last_name: yup
    .string()
    .required("Last Name Is Required")
    .max(100, "No More Than 100 Characters")
});

const validationSchemaEditEntity = yup.object({
  entityFor: yup.string().required("Entity For Is Required"),
  firstName: yup.string().required("First Name Is Required"),
  entityName: yup
    .string()
    .required("Entity Name Is Required")
    .max(100, "No More Than 100 Characters"),
  address: yup
    .string()
    .required("Address Is Required")
    .max(100, "No More Than 100 Characters"),
  taxId: yup.string().required("Tax ID Is Required"),
  partners: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Partner Name Is Required")
        .max(100, "No More Than 100 Characters"),
      email: yup
        .string()
        .required("Email Is A Required Field")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "*Invalid Email Address"
        )
    })
  )
});
const validationSchemaAddSponsor = yup.object({
  firstName: yup.string().required("First Name Is Required").trim(),
  lastName: yup.string().required("Last Name Is Required").trim(),
  company: yup.string().required("Company Name Is Required").trim(),
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  phone: yup
    .string()
    .required("Phone Number Is Required")
    .min(10, "Enter Valid Mobile Number")
    .max(12, "You Can Enter Max 12 Digit Mobile Number"),
  countryCode: yup
    .string()
    .required("Country Code Is Required")
    .min(1, "Enter Valid Country Code")
    .max(4, "You Can Enter Max 4 Digit Country Code")
    .nullable()
});
const validationSchemaAddInvestor = yup.object({
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  first_name: yup.string().required("First Name Is Required").trim(),
  last_name: yup.string().required("Last Name Is Required").trim()
});
const validationSchemaName = yup.object({
  name: yup.string().required("Investor Name Is Required")
});
const validationSchemaSendOtp = yup.object({
  email: yup
    .string()
    .required("Email Is Required")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    )
});
const validationSchemaVerifyOtp = yup.object({
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  otp: yup
    .string()
    .required("OTP Is Required")
    .matches(/^\d{6}$/i, "OTP Should Be 6 Digit")
});
const validationSchemaChangePassword = yup.object({
  newPassword: yup
    .string()
    .required("New Password Is A Required Field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number And One Special Character"
    ),
  confirmPassword: yup
    .string()
    .test("passwords-match", "Passwords Must Match", function (value) {
      return this.parent.newPassword === value;
    })
});
const validationSchemaAdminChangePassword = yup.object({
  oldPassword: yup.string().required("Current Password Is A Required Field"),
  newPassword: yup
    .string()
    .required("New Password Is A Required Field")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, No Space And One Special Character"
    ),
  confirmPassword: yup
    .string()
    .test("passwords-match", "Passwords Must Match", function (value) {
      return this.parent.newPassword === value;
    })
});

const validationSchemaAdminEditProfile = yup.object({
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  first_name: yup.string().required("First Name Is Required"),
  last_name: yup.string().required("Last Name Is Required"),
  phone_number: yup
    .string()
    .required("Phone Number Is Required")
    .min(10, "Enter Valid Mobile Number")
    .max(12, "You Can Enter Max 12 Digit Mobile Number")
    .nullable()
});
const validationSchemaInvestorForm = yup.object({
  investorSet: yup.array().of(
    yup.object().shape({
      investor_name: yup.string().required("Investor Name Is Required"),
      investor_id: yup.string().required("Select Investor From DropDown"),
      membership: yup
        .number()
        .required("Membership Percentage Is Required")
        .lessThan(
          100.00001,
          "Membership Percentage Should Not Be More Than 100%"
        ),
      total_invested_amount: yup
        .number()
        .required("Total Invested Amount Is Required"),
      asOn: yup
        .date()
        .transform(function (value, originalValue) {
          if (this.isType(value)) {
            return value;
          }
          const result = parse(originalValue, "MM.dd.yyyy", new Date());
          return result;
        })
        .typeError("Please Enter A Valid Date")
        .required("As On Is Required"),

      commited_investment: yup
        .number()
        .required("Initial Invested Amount Is Required"),
      preferred_return: yup
        .number()
        .required("Preferred Return Is Required")
        .lessThan(100.00001, "Preferred Return Should Not Be More Than 100%"),
      last_date_preferred_return: yup
        .string()
        .required("Last Date Is Required"),
      notes: yup.string().max(100, "You Can Enter Max 100 Words"),
      date_of_investment: yup
        .date()
        .transform(function (value, originalValue) {
          if (this.isType(value)) {
            return value;
          }
          const result = parse(originalValue, "MM.dd.yyyy", new Date());
          return result;
        })
        .typeError("Please Enter A Valid Date")
        .required("Investment Date Is Required"),
      investment_type: yup.string().required("Investment Type Is Required"),
      k1: yup.array().of(
        yup.object().shape({
          year: yup.string().required("Year Is Required ").nullable(),
          notes: yup.string().max(100, "You Can Enter Max 100 Words")
        })
      )
    })
  )
});

const ValidationSchemaInvestmentChangeSatatus = yup.object({
  dateOfSale: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "MM.dd.yyyy", new Date());
      return result;
    })
    .typeError("Please Enter A Valid Date")
    .required("Date Of Sale Is Required")
});

const ValidationSchemeAddInvestmentBasicDetails = yup.object({
  investment_name: yup.string().required("Investment Name Is Required"),
  purchase_price: yup
    .number()
    .required("Purchase Price Is Required")
    .nullable(),
  start_date: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "MM.dd.yyyy", new Date());
      return result;
    })
    .typeError("Please Enter A Valid Date")
    .required("Start Date Is Required"),
  distribution_cadence: yup
    .string()
    .required("Distribution Cadence Is Required"),
  is_refinanced: yup.string().nullable(),
  dateOfSale: yup.string().nullable(),
  equity_info: yup.array().of(
    yup.object().shape({
      total_asset: yup.string().required("Total Asset Is Required ").nullable(),
      outstanding: yup
        .string()
        .required("Outstanding Loan Is Required ")
        .nullable(),
      bank_name: yup.string().required("Bank Name Is Required ")
    })
  ),

  Sponsor: yup.object().shape({
    taxId: yup.string().required("Tax ID Is Required "),
    sponsor_entity_name: yup.string().required("Sponsor Entity Is Required"),
    mailing_address: yup.string().required("Mailing Address Is Required"),
    sponsors_details: yup.array().of(
      yup.object().shape({
        email: yup
          .string()
          .required("Sponsor Email Id Is Required")
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            "Invalid Email Address"
          ),
        name: yup.string().required("Sponsor Name Is Required"),
        company_name: yup.string().required("Company Name Is Required"),
        // code: yup.string().required("Code Is Required"),
        phone: yup.string().required("Phone Number Is Required")
      })
    )
  }),

  noiSet: yup.array().of(
    yup.object().shape({
      investment_noi: yup.string().required("NOI Is Required").nullable(),
      year: yup.string().required("Year Is Required").nullable(),
      noi_cadence: yup.string().required("Cadence Is Required").nullable()
    })
  ),

  sponsorSet: yup.array().of(
    yup.object().shape({
      startfrom: yup
        .number()
        .typeError("Please Enter Valid Number")
        .required("Start From Percentage Is Required")
        .lessThan(
          100.00001,
          "Start From Percentage Should Not Be More Than 100%"
        )
        .nullable(),
      to: yup
        .number()
        .typeError("Please Enter Valid Number")
        .required("To Percentage Is Required")
        .lessThan(100.00001, "To Percentage Should Not Be More Than 100%")
        .nullable(),
      promote: yup
        .number()
        .typeError("Please Enter Valid Number")
        .required("Promote Percentage Is Required")
        .lessThan(100.00001, "Promote Percentage Should Not Be More Than 100%")
        .nullable()
    })
  ),

  properties: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Property Name Is Required"),
      address: yup.array().of(
        yup.object().shape({
          address: yup.string().required("Property Address Is Required")
        })
      )
    })
  ),

  associatedContactsSet: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required("First Name Is Required"),
      last_name: yup.string().required("Last Name Is Required"),
      email: yup
        .string()
        .required("Email Is Required ")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Invalid Email Address"
        ),
      phone_number: yup
        .string()
        .required("Phone Number Is Required")
        .min(10, "Enter Valid Mobile Number")
        .max(12, "You Can Enter Max 12 Digit Mobile Number")
        .nullable(),
      designation: yup.string().required("Designation Is Required"),
      company_name: yup.string().required("Company Name Is Required"),
      country_code: yup
        .string()
        .required("Country Code Is Required")
        .min(1, "Enter Valid Country Code")
        .max(4, "You Can Enter Max 4 Digit Country Code")
        .nullable(),
      is_primary: yup.string()
    })
  )
});

const ValidationSchemeAddInvestmentBasicDetailsDraft = yup.object({
  investment_name: yup.string().required("Investment Name Is Required")
});

const validationSchemaAddInvestmentProperty = yup.object({
  propertySet: yup.array().of(
    yup.object().shape({
      name: yup.string().required("Property Name Is Required"),
      value: yup.number().required("Property Value Is Required"),
      type: yup.string().required("Property Type Is Required"),
      areas: yup.number().required("Area Covered Is Required"),
      unit: yup.string().required("Units Is Required"),
      occupancy: yup
        .number()
        .required("Occupancy Is Required")
        .lessThan(100.00001, "Occupancy Should Not Be More Than 100%"),
      status: yup.string().required("Status Is Required")
    })
  )
});
const validationSchemaAddInvestmentPropertyDraft = yup.object({
  propertySet: yup.array().of(
    yup.object().shape({
      property_name: yup.string().required("Property Name Is Required"),
      property_value: yup.string(),
      area_covered: yup.string(),
      occupancy: yup.string()
    })
  )
});
const ValidationSchemeAddInvestmentLoans = yup.object({
  loanSet: yup.array().of(
    yup.object().shape({
      outstanding_loan: yup
        .number()
        .typeError("Please Enter Valid Number")
        .required("Outstanding Loan Is Required")
        .nullable(),
      loan_type: yup.string().required("Loan Type Is Required"),
      notes: yup.string().required("Notes Is Required")
    })
  )
});

const ValidationSchemeAddInvestmentLoansDraft = yup.object({
  loanSet: yup.array().of(
    yup.object().shape({
      outstanding_loan: yup
        .number()
        .required("Outstanding Loan Is Required")
        .typeError("Please Enter Valid Number")
        .nullable(),
      loan_type: yup.string(),
      notes: yup.string()
    })
  )
});
const ValidationSchemeAddInvestmentRefinance = yup.object({
  refinanceSet: yup.array().of(
    yup.object().shape({
      outstanding_loan: yup
        .number()
        .typeError("Please Enter Valid Number")
        .required("Outstanding Loan Is Required")
        .nullable(),
      refinance_loan_type: yup
        .string()
        .required("Refinance Loan Type Is Required"),
      notes: yup.string().required("Notes Is Required")
    })
  )
});

const ValidationSchemeAddInvestmentRefinanceDraft = yup.object({
  refinanceSet: yup.array().of(
    yup.object().shape({
      outstanding_loan: yup
        .number()
        .required("Outstanding Loan Is Required")
        .typeError("Please Enter Valid Number")
        .nullable(),
      refinance_loan_type: yup.string(),
      notes: yup.string()
    })
  )
});
const validationSchemaAddDistribution = yup.object({
  distributionSet: yup.array().of(
    yup.object().shape({
      investor_name: yup.string().required("Investor Name Is Required"),
      distribution_amount: yup
        .string()
        .required("Distribution Amount Is Required"),
      distribution_date: yup
        .date()
        .transform(function (value, originalValue) {
          if (this.isType(value)) {
            return value;
          }
          const result = parse(originalValue, "MM.dd.yyyy", new Date());
          return result;
        })
        .typeError("Please Enter A Valid Date")
        .required("Distribution Date Is Required"),

      distribution_type: yup.string().required("Distribution Type Is Required"),
      distribution_year: yup.number().required("Distribution Year Is Required"),
      distribution_time_frame: yup
        .string()
        .required("Distribution TimeFrame Is Required"),
      notes: yup.string().required("Notes Is Required")
    })
  )
});
const validationSchemaEditDistribution = yup.object({
  distribution_date: yup
    .date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, "MM.dd.yyyy", new Date());
      return result;
    })
    .typeError("Please Enter A Valid Date")
    .required("Distribution Date Is Required"),
  distribution_type: yup.string().required("Distribution Type Is Required"),
  distribution_year: yup.number().required("Distribution Year Is Required"),
  distribution_timeframe: yup
    .string()
    .required("Distribution Timeframe Is Required"),
  total_distribution: yup.number().required("Total Distribution Is Required"),
  amount_released: yup
    .number()
    .required("Distribution Amount Released Is Required")
});
const validationSchemaDistributionAdd = yup.object({
  distributionSet: yup.array().of(
    yup.object().shape({
      date: yup.string().required("Distribution Date Is Required").nullable(),
      type: yup.string().required("Distribution Type Is Required"),
      totalDistribution: yup
        .number()
        .required("Total Distribution Is Required"),
      year: yup.number().required("Distribution Year Is Required"),
      investorsSelected: yup.number().moreThan(0, "Select At Least 1 Investor"),
      amountSum: yup
        .number()
        .when("totalDistribution", (totalDistribution, schema) => {
          return schema.test({
            test: (amount) => amount === totalDistribution,
            message:
              "Total Amount Released Should Be Equal To Total Distribution"
          });
        })
    })
  )
});
const validationSchemaSelectInvestment = yup.object({
  selectedInvestment: yup.string().required("Select An Investment To Continue")
});

const validationSchemaAddContancts = yup.object({
  investment_name: yup.string().required(" Investment Name Is Required"),
  associatedContactsSet: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required(" First Name Is Required"),
      last_name: yup.string().required("Last Name Is Required"),
      email: yup
        .string()
        .required("Email Is A Required Field")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Invalid Email Address"
        ),
      phone_number: yup
        .string()
        .required("Phone Number Is Required")
        .min(10, "Enter Valid Mobile Number")
        .max(12, "You Can Enter Max 12 Digit Mobile Number")
        .nullable(),
      designation: yup.string().required("Designation Is Required"),
      company_name: yup.string().required(" Company Name Is Required"),
      country_code: yup
        .string()
        .required("Country Code Is Required")
        .min(1, "Enter Valid Country Code")
        .max(4, "You Can Enter Max 4 Digit Country Code")
        .nullable(),
      is_primary: yup.string()
    })
  )
});

const validationSchemaAddProperty = yup.object({
  investmentName: yup.string().required("Investment Name Is Required"),
  propertyName: yup.string().required("Property Name Is Required"),
  type: yup.string().required("Property Type Is Required"),
  status: yup.string(),
  propertyValue: yup.number(),
  area: yup.number(),
  address: yup.string().required("Address Is Required"),
  totalUnits: yup.number(),
  // occupancy: yup
  //   .number()
  //   .lessThan(100.00001, "Occupancy Should Not Be More Than 100%"),
  yearBuild: yup.string(),
  description: yup.string().max(100)
});
const validationSchemaManageDataAddK1PopUp = yup.object({
  year: yup.number().required("Year Is Required"),
  investor: yup.string().required("Investor Name Is Required"),
  investment: yup.string().required("Investment Name Is Required"),
  documents: yup.array().min(1, "Upload At Least One Document")
});
const validationSchemaManageDataAddWithPropertyK1PopUp = yup.object({
  year: yup.number().required("Year Is Required"),
  investor: yup.string().required("Investor Name Is Required"),
  investment: yup.string().required("Investment Name Is Required"),
  property: yup.string().required("Property Name Is Required"),
  documents: yup.array().min(1, "Upload At Least One Document")
});
const validationSchemaAddRole = yup.object({
  name: yup.string().required("Role Name Is Required"),
  description: yup.string().required("Description Is Required"),
  viewPerm: yup.number().moreThan(0, "Select At Least 1 View Permission")
});
const validationSchemaAddSubAdmin = yup.object({
  associatedSubAdminSet: yup.array().of(
    yup.object().shape({
      first_name: yup.string().required(" First Name Is Required"),
      last_name: yup.string().required("Last Name Is Required"),
      access_role: yup.string().required("Role Is Required"),
      email: yup
        .string()
        .required("Email Is A Required Field")
        .matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
          "Invalid Email Address"
        ),
      phone_number: yup
        .string()
        .required("Phone Number Is Required")
        .min(10, "Enter Valid Mobile Number")
        .max(12, "You Can Enter Max 12 Digit Mobile Number")
        .nullable(),
      country_code: yup.string()
    })
  )
});
const validationSchemaEditSubAdmin = yup.object({
  first_name: yup.string().required(" First Name Is Required"),
  last_name: yup.string().required("Last Name Is Required"),
  access_role: yup.string().required("Role Is Required"),
  email: yup
    .string()
    .required("Email Is A Required Field")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      "Invalid Email Address"
    ),
  phone_number: yup
    .string()
    .required("Phone Number Is Required")
    .min(10, "Enter Valid Mobile Number")
    .max(12, "You Can Enter Max 12 Digit Mobile Number")
    .nullable(),
  country_code: yup.string()
});
const validationSchemaDocsUploadPopup = yup.object({
  docsSet: yup.array().of(
    yup.object().shape({
      selectedType: yup.string().required("Select Type of Document"),
      documents: yup.array().min(1, "Upload At Least One Document")
    })
  )
});
const validationSchemaUploadTemplate = yup.object({
  documents:
    yup.array().min(1, "Upload At Least One Document") &&
    yup.array().max(1, "Upload Maximum One Document")
});

const validationSchemaManageDataAddK1 = yup.object({
  associatedSet: yup.array().of(
    yup.object().shape({
      type: yup.string().required("Type Is Required"),
      year: yup.string().required("Year Is Required"),
      investmentinvestor: yup.string().required("Investment Name Is Required"),
      investor: yup.string().required("Investor Name Is Required"),
      documents: yup.array().min(1, "Upload At Least One Document")
    })
  )
});
const validationSchemaManageDataAddWithPropertyK1 = yup.object({
  associatedSet: yup.array().of(
    yup.object().shape({
      type: yup.string().required("Type Is Required"),
      investmentinvestor: yup.string().required("Investment Name Is Required"),
      investor: yup.string().required("investor Name Is Required"),
      year: yup.string().required("Year Is Required"),
      // property_id: yup.string().required("Property Name Is Required"),
      documents: yup.array().min(1, "Upload At Least One Document")
    })
  )
});
export {
  validationSchemaManageDataAddK1PopUp,
  validationSchemaManageDataAddWithPropertyK1PopUp,
  validationSchemaLogin,
  validationSchemaSendOtp,
  validationSchemaVerifyOtp,
  validationSchemaChangePassword,
  validationSchemaAdminChangePassword,
  validationSchemaAdminEditProfile,
  validationSchemaAddInvestor,
  validationSchemaAddSponsor,
  validationSchemaName,
  validationSchemaEditInvestor,
  validationSchemaAddEntityInvestorExsisting,
  validationSchemaAddEntityInvestorNotExsisting,
  validationSchemaAddEntitySponsorExsisting,
  validationSchemaAddEntitySponsorNotExsisting,
  validationSchemaEditEntity,
  validationSchemaInvestorForm,
  ValidationSchemeAddInvestmentBasicDetails,
  ValidationSchemeAddInvestmentBasicDetailsDraft,
  validationSchemaAddInvestmentProperty,
  validationSchemaAddInvestmentPropertyDraft,
  validationSchemaAddDistribution,
  validationSchemaEditDistribution,
  validationSchemaDistributionAdd,
  validationSchemaSelectInvestment,
  validationSchemaAddContancts,
  validationSchemaAddProperty,
  validationSchemaManageDataAddK1,
  validationSchemaManageDataAddWithPropertyK1,
  validationSchemaAddRole,
  validationSchemaAddSubAdmin,
  validationSchemaEditSubAdmin,
  validationSchemaDocsUploadPopup,
  validationSchemaUploadTemplate,
  validationSchemaAddInvestmentInvestor,
  ValidationSchemeAddInvestmentLoans,
  ValidationSchemeAddInvestmentLoansDraft,
  ValidationSchemeAddInvestmentRefinance,
  ValidationSchemeAddInvestmentRefinanceDraft,
  ValidationSchemaInvestmentChangeSatatus
};
