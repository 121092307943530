import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import AccordionFilter from "components/Common/AccordianFilter";
import styles from "../../pages/private/investments/investments.module.css";
import { Grid } from "@material-ui/core";
const useStyles = makeStyles(() => ({
  appBar: {
    position: "relative",
    backgroundColor: "white",
    color: "black",
    marginTop: "20px",
    marginBottom: "24px"
  },
  modalStyle: {
    width: "378px"
  },
  paperStyle: {
    justifyContent: "flex-end"
  },
  listWrapper: {
    marginBottom: "15px",
    overflowY: "scroll",
    height: "84vh"
  },
  divider: {
    bottom: "94px",
    position: "relative"
  },
  heading: {
    padding: "0px 14px 0px 23px",
    height: "60px",
    color: "#445EBE",
    fontFamily: "inter",
    fontWeight: 600,
    fontSize: 18
  },
  buttonStyle: {
    color: "#0C0C0C",
    width: "100px",
    fontFamily: "inter",
    fontSize: "14px",
    letterSpacing: 0,
    lineHeight: "19px",
    fontWeight: 600,
    textTransform: "none",
    "&:focus": {
      outline: "none"
    }
  },
  outlinedPrimary: {
    color: "none",
    border: "none"
  },
  saveAsDraftText: {
    fontSize: 18,
    fontFamily: "inter",
    fontWeight: 600,
    color: "#445ebe",
    width: 65,
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: "10px"
  },
  btncontainforms: {
    marginTop: 30,
    marginBottom: 30,
    width: 270,
    alignItems: "center",
    marginLeft: 24
  },
  topCtr: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%"
  }
}));
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});
export default function MoreFilters({
  filters,
  checked,
  handleClose,
  handleClear,
  handleApply,
  open
}) {
  const classes = useStyles();
  const [tempCheckedData, setTempCheckedData] = useState([checked]);

  const handleToggle = (key, id, name) => {
    const isExist = tempCheckedData.findIndex((el) => el.key === key);
    if (isExist === -1) {
      let payload = {
        key: key,
        value: [{ id, name }]
      };
      setTempCheckedData([...tempCheckedData, payload]);
    } else {
      let newCheckedData = tempCheckedData;
      let keyData = newCheckedData.find((el) => el.key === key)?.value;
      const keyIndex = newCheckedData.findIndex((el) => el.key === key);
      let spliceIndex = keyData.findIndex((el) => el.id === id);
      if (spliceIndex === -1) {
        keyData.push({ id, name });
      }
      newCheckedData[keyIndex].value = keyData;
      setTempCheckedData([...newCheckedData]);
    }
  };

  const handleDelete = (key, id) => {
    let newCheckedData = tempCheckedData;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let keyData = newCheckedData?.[keyIndex]?.value;
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setTempCheckedData([...newCheckedData]);
  };

  const onHandleApplyClick = () => {
    handleApply(tempCheckedData);
  };

  useEffect(() => {
    setTempCheckedData(JSON.parse(JSON.stringify(checked)));
  }, [checked]);
  return (
    <div>
      {tempCheckedData && (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
          classes={{
            paperFullScreen: classes.modalStyle,
            scrollPaper: classes.paperStyle
          }}>
          <AppBar className={classes.appBar} elevation={0}>
            <Toolbar className={classes.heading}>
              <div className={classes.topCtr}>
                <div>Filter by</div>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={handleClose}
                  aria-label="close">
                  <CloseIcon />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <div className={classes.listWrapper}>
            {filters.map((data, index) => (
              <div key={index}>
                <AccordionFilter
                  getData={data.value}
                  hasSearchBar={data.hasSearchBar}
                  isStaticData={data.isStaticData}
                  title={data.key}
                  checked={tempCheckedData}
                  index={tempCheckedData.findIndex((el) => el.key === data.key)}
                  handleToggle={handleToggle}
                  handleDelete={handleDelete}
                />
              </div>
            ))}
          </div>
          <Grid container className={classes.btncontainforms}>
            <div className={styles.addInvestmentButtonContainerFilter}>
              <p
                className={styles.addInvestmentButtonText}
                onClick={() => onHandleApplyClick()}>
                Apply
              </p>
            </div>
            <div className={classes.saveAsDraftText}>
              <p onClick={() => handleClear()}>Default</p>
            </div>
          </Grid>
        </Dialog>
      )}
    </div>
  );
}
