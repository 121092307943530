import {
  Grid,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import Illustrations from "components/illustration/Illustrations";
import React from "react";
import styles from "../EntityDetail.module.scss";
// import BasicPopover from "./BasicPopover";
import partnerEmptyScreen from "assets/emptyState/noPartners.svg";

const PartnerTable = ({ data, heading, tableType }) => {
  return (
    <>
      {data && data?.length != 0 ? (
        <TableContainer
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff"
          }}
          component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {heading.map((value, index) => {
                  return (
                    <TableCell key={index} className={styles.noiTableHeader}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  {tableType == "partners" ? (
                    <>
                      <TableCell component="th" scope="row">
                        {row?.name}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row?.email}
                      </TableCell>
                    </>
                  ) : null}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : data?.length == 0 ? (
        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff"
          }}>
          <Illustrations
            src={partnerEmptyScreen}
            heading="No partners found"
            // subHeading="Add Partners To View"
          />
        </Grid>
      ) : (
        [1, 2, 3, 4, 5].map((value, index) => {
          return (
            <Grid
              key={index}
              style={{
                boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
                backgroundColor: "#ffff"
              }}>
              <Skeleton width="100%" variant="text" height={40} />
            </Grid>
          );
        })
      )}
    </>
  );
};

export default PartnerTable;
