import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid
} from "@material-ui/core";
import Illustrations from "components/illustration/Illustrations";
import LightTooltip from "components/LightTooltip";
import NewDropDown from "components/NewDropDown";
import { truncate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import React, { useState } from "react";
import styles from "../../EntityDetail.module.scss";
import InvestmentToolTipData from "../Investment/InvestmentToolTipData";
import propertiesEmptyScreen from "assets/emptyState/noProperties.svg";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "redux/index";

const propertyType = {
  singlefamily: "Single Family Home",
  condominums: "Condominiums",
  townhomes: "Townhomes",
  cooperativehousing: "Cooperative housing (a co-op)",
  duplexes: "Duplexes",
  triplexes: "Triplexes",
  fourplexes: "Fourplexes",
  individualmobile: "Individual mobile homes (not an entire mobile home park)",
  officespace: "Office space",
  hotelandlodging: "Hotel and lodging",
  selfstorage: "Self-storage or mini-storage",
  multifamilyproperties: "Multifamily properties",
  retailproperties: "Retail properties",
  industrialbuildings: "Industrial buildings",
  healthcarefacilites: "Health care facilities",
  specialpurposes: "Special purposes"
};

function PropertiesTable({ data, heading }) {
  const history = useHistory();
  const [expandIndex, setExpandIndex] = useState(-1);
  const propertiesData = data.map((investment) => {
    return investment?.properties.map((item) => {
      return {
        0: item?.name,
        1: item?.managed_by,
        2: formatCurrency(item?.value) || "-",
        3: item?.loan_id,
        4: item?.area || "-",
        5: propertyType[item?.type] || "-",
        6: item?.occupancy || 0,
        7: item?.investment_status || "-",
        pid: item?.id,
        dataName: investment?.investment?.name || investment?.name,
        baseID: item?.base_id,
        units: item?.units
      };
    });
  });

  const handlePrpClick = (id) => {
    AppDispatcher.setPropertiesDetailID(id);
    history.push(`/u/properties/detail`);
  };

  let setLoanHead = 0;
  propertiesData?.map((value) => {
    if (value?.length > 0) {
      setLoanHead = 1;
    }
  });

  return (
    <>
      {propertiesData?.length != 0 ? (
        <TableContainer
          component={Paper}
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff",
            padding: "20px"
          }}>
          <Table sx={{ minWidth: 650 }} aria-label="sticky table">
            {setLoanHead == 1 ? (
              <TableHead>
                <TableRow>
                  {heading.map((value, index) => {
                    return (
                      <TableCell key={index} className={styles.noiTableHeader}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : (
              <Illustrations
                src={propertiesEmptyScreen}
                heading="No Property found"
                // subHeading="Add Investment Data To View"
              />
            )}
            <TableBody>
              {propertiesData?.map((values, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      {values.length > 0 ? (
                        <TableCell
                          style={{ border: "none" }}
                          align="center"
                          colSpan={12}>
                          <div className={styles.tableGrouping}>
                            {" "}
                            {truncate(values[0]["dataName"], 10)}
                          </div>
                        </TableCell>
                      ) : null}
                    </TableRow>
                    {values.map((val, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}>
                          {heading.map((item, indx) => {
                            return (
                              <LightTooltip
                                key={indx}
                                title={
                                  <InvestmentToolTipData
                                    data={[["Property ID: ", val["baseID"]]]}
                                  />
                                }>
                                {typeof val[indx] === "object" ? (
                                  <TableCell
                                    style={{ border: "none", width: "150px" }}>
                                    {val[indx].length > 0 ? (
                                      <span
                                        style={{
                                          color: "#BD54B9",
                                          backgroundColor:
                                            "rgba(189, 84, 185, 0.05)"
                                        }}>
                                        <NewDropDown
                                          index={indx}
                                          item={
                                            val[indx]?.map((item) => {
                                              return {
                                                id: null,
                                                name: item?.base_id
                                              };
                                            }) || []
                                          }
                                          // handleCellItemClick={(name, id) => handleCellClick(name, id)}
                                          // type="sponsor_id"
                                          expandIndex={expandIndex}
                                          setExpandIndex={setExpandIndex}
                                        />
                                      </span>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    style={{ border: "none" }}
                                    component="th"
                                    scope="row">
                                    <Grid
                                      style={
                                        indx == 0
                                          ? {
                                              cursor: "pointer"
                                            }
                                          : null
                                      }
                                      onClick={
                                        indx == 0
                                          ? () => {
                                              handlePrpClick(val["pid"]);
                                            }
                                          : () => {}
                                      }
                                      title={val[indx]}
                                      className={[
                                        indx === 0 && styles.firstCell,
                                        indx == 7 && val["dataName"]
                                          ? val[indx] != "-" &&
                                            (val[indx] == "Active"
                                              ? styles.activeCell
                                              : styles.inActiveCell)
                                          : null
                                      ]}>
                                      {truncate(val[indx], 16)}{" "}
                                      {item === "Occupancy" && "%"}
                                      {item === "Area" && val["units"]}
                                    </Grid>
                                  </TableCell>
                                )}
                              </LightTooltip>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff",
            width: "100vw"
          }}>
          <Illustrations
            src={propertiesEmptyScreen}
            heading="No property found"
            // subHeading="Add Investment Data To View"
          />
        </Grid>
      )}
    </>
  );
}

export default PropertiesTable;
