import { Box, Grid, makeStyles } from "@material-ui/core";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import Loader from "components/Loader/AppLoader";
import Toast from "components/toast";
import { useFormik } from "formik";
import { validationSchemaAddRole } from "helpers/validationSchema";
import { RoleService } from "network/rolesService";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AssignPermissions from "./components/assignPermissions";
import BasicDetails from "./components/basicDetails";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8,
    padding: 20
  },
  heading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 20,
    "& span": {
      flexShrink: 0,
      fontFamily: "Inter Bold",
      marginRight: 20,
      color: "#191919",
      fontSize: "18px",
      fontWeight: 700
    },
    "& div": {
      flexGrow: 1,
      height: 1,
      backgroundColor: "#E9E9E9"
    }
  },
  innerContainer: {
    // backgroundColor: "#ffffff",
    borderRadius: 8,
    padding: 12,
    width: "100%",
    margin: 0
  },
  btnContainer: {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 20,
    "& div": {
      borderRadius: 4,
      fontFamily: "Inter SemiBold",
      fontSize: 18,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer"
    }
  },
  secondaryBtn: {
    color: "#445EBE",
    border: "1px solid #445EBE",
    width: 231,
    height: 48,
    backgroundColor: "#FFFF",
    marginRight: 10
  },
  primaryBtn: {
    backgroundColor: "#445EBE",
    width: 233,
    height: 50,
    color: "#FFFFFF"
  }
}));

const permissions = [
  { label: "View", value: "is_retrieve" },
  { label: "Add", value: "is_create" },
  { label: "Update", value: "is_update" },
  { label: "Delete", value: "is_delete" }
];

const AddRole = ({ isEditCase }) => {
  const classes = useStyles();
  const history = useHistory();
  const [modules, setModules] = useState([]);
  const { roleId } = useSelector((store) => store.app);
  const [loading, setLoading] = useState(true);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    viewPerm: 0,
    modules: []
  });

  const initializeFormik = () => {
    let permissionsTypes = {};
    permissions.forEach((el) => {
      permissionsTypes[el.value] = false;
    });
    setInitialValues({
      ...initialValues,
      modules: modules.map((el) => ({
        value: el.value,
        permissions: { ...permissionsTypes }
      }))
    });
  };

  const handleSubmit = async (values) => {
    const payload = {
      name: values.name,
      description: values.description,
      permissions: values.modules.map(({ value, permissions }) => ({
        role_module: value,
        ...permissions
      }))
    };
    let res;
    if (isEditCase) {
      res = await RoleService.editRole(payload, [`${roleId}\\`]);
    } else {
      res = await RoleService.addRole(payload);
    }
    if (res.success) {
      Toast.showSuccessToast(
        `Role ${isEditCase ? "Updated" : "Added"} Successfully`
      );
      history.push("/u/roles");
    } else {
      Toast.showErrorToast(res.error.message[0]);
    }
  };

  const fetchRoleData = async () => {
    if (!isEditCase) {
      return;
    }
    const res = await RoleService.getRoleById([`${roleId}\\`]);
    if (res.success) {
      setModules(
        res?.data?.permissions?.map(({ module_name, module_id }) => ({
          label: module_name,
          value: module_id
        }))
      );
      let viewPermCount = 0;
      let permissions = res.data.permissions.map((perm) => {
        if (perm.is_retrieve) {
          ++viewPermCount;
        }
        return {
          value: perm.module_id,
          permissions: {
            is_create: perm.is_create,
            is_retrieve: perm.is_retrieve,
            is_update: perm.is_update,
            is_delete: perm.is_delete
          }
        };
      });
      setInitialValues({
        name: res?.data?.name,
        description: res?.data?.description,
        viewPerm: viewPermCount,
        modules: permissions
      });
    }
  };

  const fetchRoleModules = async () => {
    const res = await RoleService.getRoleModules();
    if (res.success) {
      setModules(
        res?.data?.map(({ module_name, id }) => ({
          label: module_name,
          value: id
        }))
      );
    } else {
      Toast.showErrorToast("Failed To Load Modules List");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchemaAddRole,
    onSubmit: handleSubmit
  });

  useEffect(() => {
    if (!isEditCase) {
      initializeFormik();
    }
  }, [modules]);

  useEffect(async () => {
    setLoading(true);
    if (isEditCase) {
      await fetchRoleData();
    } else {
      await fetchRoleModules();
    }
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent
            heading={isEditCase ? "Edit Role" : "Add Role"}
            description={
              isEditCase ? "Edit Roles On Simplee" : "Add New Roles On Simplee"
            }
            backBtn={true}
          />
          <Box height={46} />
          <div className={classes.heading}>
            <span>Basic Details</span>
          </div>
          <Grid container className={classes.container}>
            <Grid container spacing={2} className={classes.innerContainer}>
              <BasicDetails isEditCase={isEditCase} formik={formik} />
            </Grid>
          </Grid>

          <Box height={20} />
          <div className={classes.heading}>
            <span>Assign Permissions</span>
          </div>
          <Grid container>
            <Grid
              container
              spacing={2}
              style={{
                margin: "10px 0 0 0",
                boxShadow: "0px 4px 15px rgba(160, 160, 160, 0.15)",
                padding: 20
              }}>
              <AssignPermissions
                {...{
                  modules,
                  permissions,
                  formik
                }}
              />
            </Grid>
          </Grid>
          <div className={classes.btnContainer}>
            <div
              className={classes.secondaryBtn}
              onClick={() => history.push("/u/roles")}>
              Cancel
            </div>
            <div className={classes.primaryBtn} onClick={formik.handleSubmit}>
              Save
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddRole;
