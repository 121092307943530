import {
  CircularProgress,
  ImageList,
  ImageListItem,
  Grid,
  ImageListItemBar,
  IconButton
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import React, { useState } from "react";
import Image from "./Image";
import "./ImageUpload.scss";

const ImageUpload = ({
  index,
  displayImage = [],
  setDisplayImage,
  handleDelete
}) => {
  const [loadCompletedCount, setLoadCompletedCount] = useState(0);

  const handleImageLoad = () => {
    if (loadCompletedCount < displayImage.length) {
      setLoadCompletedCount((prev) => prev + 1);
    }
  };

  const handleDeleteClick = (id) => {
    handleDelete(id);
    setLoadCompletedCount((prev) => prev - 1);
  };

  return (
    <Grid container width={"100%"}>
      {displayImage?.length < 5 &&
      loadCompletedCount === displayImage?.length ? (
        <Image
          index={index}
          setDisplayImage={setDisplayImage}
          displayImage={displayImage}
        />
      ) : null}

      <ImageList
        rowHeight={184}
        gap={16}
        cols={{ sm: 2, md: 4 }}
        style={{ marginTop: "12px" }}>
        {displayImage.map((item, idx) => (
          <ImageListItem
            key={idx}
            className="show-image"
            style={{ width: "300px", borderRadius: "24px" }}>
            <img
              src={item.file}
              alt={item.name}
              className="image"
              onLoad={(e) => handleImageLoad(e, idx)}
            />
            <ImageListItemBar
              title={item.title}
              position="top"
              style={{
                backgroundColor: "transparent"
              }}
              actionIcon={
                <IconButton
                  style={{
                    backgroundColor: "#fcebeb",
                    color: "#ff0000",
                    marginRight: "10px",
                    marginTop: "10px",
                    borderRadius: "12px"
                  }}
                  aria-label={`clear ${item.title}`}
                  onClick={() => handleDeleteClick(item.id)}>
                  <ClearIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>

      {displayImage?.length !== loadCompletedCount && (
        <div className="loadingImages">
          <div style={{ paddingBottom: 20 }}>
            <CircularProgress />
          </div>
          <div className="loading">Loading Property Images...</div>
          <div className="loadPartialComplete">
            {`${loadCompletedCount} / ${displayImage?.length}`} Completed
          </div>
        </div>
      )}
    </Grid>
  );
};

export default ImageUpload;
