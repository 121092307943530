import * as React from "react";
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Grid
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import styles from "./LoanTable.module.scss";

import { useState } from "react";
import Illustrations from "components/illustration/Illustrations";
import { formatDate, truncate } from "helpers/Common";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import formatCurrency from "helpers/formatCurrency";
import refinancesEmptyScreen from "assets/emptyState/noLoans.svg";
import InvestmentToolTipData from "../Investment/InvestmentToolTipData";
import LightTooltip from "components/LightTooltip";

const loanType = {
  fixedratemortgage: "Fixed Rate Mortgage",
  adjustratemortgage: "Adjustable Rate Mortgage",
  fedhousadmin: "Federal Housing Administration",
  convenloan: "Conventional loan",
  vetaffloan: "Veteran Affairs (VA) loan",
  usdaloan: "U.S. Department of Agriculture (USDA) loan",
  jumboloan: "Jumbo loan",
  constloan: "Construction loan",
  ballonloan: "Balloon loans",
  piggyloan: "Piggybank loan",
  intonmortgages: "Interest-only mortgages"
};

function Row(props) {
  const { row } = props;

  const [open, setOpen] = useState(false);

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  return (
    <React.Fragment>
      <>
        <TableRow
          sx={{ "& > *": { borderBottom: "unset" } }}
          style={{ backgroundColor: "#fff" }}>
          <>
            <LightTooltip
              title={
                <InvestmentToolTipData
                  data={[
                    ["Loan ID: ", row?.base_id],
                    ["Bank Name:", row?.bank],
                    ["Last updated on:", row?.last_updated_on],
                    ["Notes:", row?.description]
                  ]}
                />
              }>
              <TableCell
                component="th"
                scope="row"
                style={open ? { borderBottom: "none" } : null}>
                {loanType[row?.type]}
              </TableCell>
            </LightTooltip>
            <LightTooltip
              title={
                <InvestmentToolTipData
                  data={[
                    ["Loan ID: ", row?.base_id],
                    ["Bank Name:", row?.bank],
                    ["Last updated on:", row?.last_updated_on],
                    ["Notes:", row?.description]
                  ]}
                />
              }>
              <TableCell
                component="th"
                scope="row"
                style={open ? { borderBottom: "none" } : null}>
                {formatCurrency(row?.outstanding)}
              </TableCell>
            </LightTooltip>
            <LightTooltip
              title={
                <InvestmentToolTipData
                  data={[
                    ["Loan ID: ", row?.base_id],
                    ["Bank Name:", row?.bank],
                    ["Last updated on:", row?.last_updated_on],
                    ["Notes:", row?.description]
                  ]}
                />
              }>
              <TableCell
                component="th"
                scope="row"
                style={open ? { borderBottom: "none" } : null}>
                {truncate(row?.description || "-", 25)}
              </TableCell>
            </LightTooltip>
            <LightTooltip
              title={
                <InvestmentToolTipData
                  data={[
                    ["Loan ID: ", row?.base_id],
                    ["Bank Name:", row?.bank],
                    ["Last updated on:", row?.last_updated_on],
                    ["Notes:", row?.description]
                  ]}
                />
              }>
              <TableCell
                component="th"
                scope="row"
                style={open ? { borderBottom: "none" } : null}>
                {formatCurrency(row?.amount)}
              </TableCell>
            </LightTooltip>
            <LightTooltip
              title={
                <InvestmentToolTipData
                  data={[
                    ["Loan ID: ", row?.base_id],
                    ["Bank Name:", row?.bank],
                    ["Last updated on:", row?.last_updated_on],
                    ["Notes:", row?.description]
                  ]}
                />
              }>
              <TableCell
                component="th"
                scope="row"
                style={open ? { borderBottom: "none" } : null}>
                {formatDate(row?.initiation)}
              </TableCell>
            </LightTooltip>
            <LightTooltip
              title={
                <InvestmentToolTipData
                  data={[
                    ["Loan ID: ", row?.base_id],
                    ["Bank Name:", row?.bank],
                    ["Last updated on:", row?.last_updated_on],
                    ["Notes:", row?.description]
                  ]}
                />
              }>
              <TableCell
                component="th"
                scope="row"
                style={open ? { borderBottom: "none" } : null}>
                {formatCurrency(row?.debtservice)}
              </TableCell>
            </LightTooltip>

            <TableCell
              component="th"
              scope="row"
              style={open ? { borderBottom: "none" } : null}>
              <span
                className={
                  row?.status &&
                  (row?.status == "Active" ||
                    row?.status == "Stabilized" ||
                    row?.status == "Refinanced")
                    ? styles.activeCell
                    : styles.inActiveCell
                }>
                {row?.status}
              </span>
            </TableCell>
            <TableCell
              component="th"
              scope="row"
              style={open ? { borderBottom: "none" } : null}>
              {/* <BasicPopover data={row?.documents}/>  */}
              <DocumentsUpload
                editable={false}
                listData={formatDocsData(row?.documents)}
              />
            </TableCell>
          </>

          <TableCell style={{ border: "none" }} align="right">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell
            style={{
              padding: "0px 20px"
            }}
            colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              {Object.keys(row?.loan_property).length != 0 ? (
                <Box sx={{ padding: 30 }}>
                  <Grid container>
                    <Grid item>
                      <Typography className={styles.cardHeading}>
                        Tagged Properties
                      </Typography>
                    </Grid>
                    <Box sx={{ ml: 2 }} item className={styles.cardLength}>
                      {Object.keys(row?.loan_property).length}
                    </Box>
                  </Grid>

                  <Table size="medium" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography className={styles.fieldHeadingtext}>
                            Property Name
                          </Typography>
                        </TableCell>

                        <TableCell>
                          <Typography className={styles.fieldHeadingtext}>
                            Property Type
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={styles.fieldHeadingtext}>
                            Area
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={styles.fieldHeadingtext}>
                            Year Built
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={styles.fieldHeadingtext}>
                            Occupancy
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography
                            noWrap
                            className={styles.fieldHeadingtext}>
                            Address
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={styles.fieldHeadingtext}>
                            ZIP
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className={styles.fieldHeadingtext}>
                            Status
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody style={{ backgroundColor: "#ffff" }}>
                      {row?.loan_property.map((value, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {value?.name}
                            </TableCell>

                            <TableCell
                              title={value?.type}
                              component="th"
                              scope="row">
                              {truncate(value?.type || "-", 12)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {value?.area || "0"} {value?.units}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {value?.build_year || "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {value?.occupancy || "-"}
                            </TableCell>
                            <TableCell
                              title={value?.address[0]?.address}
                              component="th"
                              scope="row">
                              {truncate(value?.address[0]?.address || "-", 12)}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {value?.address[0]?.zip_code || "-"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <span
                                className={
                                  value?.status &&
                                  value?.status == "Stablilized"
                                    ? styles.activeCell
                                    : value?.status && styles.inActiveCell
                                }>
                                {value?.status || "-"}
                              </span>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              ) : (
                <Grid
                  style={{
                    boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
                    backgroundColor: "#ffff"
                  }}>
                  <Illustrations
                    src={refinancesEmptyScreen}
                    heading="No added refinances"
                    // subHeading="Add Investment Data To View"
                  />
                </Grid>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    </React.Fragment>
  );
}

export default function RefinanceSponsorTable({
  tabHead,
  loanTableData,
  tableHeading
}) {
  let setLoanHead = 0;
  loanTableData?.investment?.map((value) => {
    if (value?.refinances.length > 0) {
      setLoanHead = 1;
    }
  });

  return (
    <Grid item xs={12} className={styles.overviewMainContainer}>
      <Grid container>
        <Typography className={styles.cardHeading}>{tabHead}</Typography>
      </Grid>

      {/* <Grid container>
        <Typography className={styles.cardHeading}>{tabHead}</Typography>
      </Grid> */}
      {loanTableData?.investment?.length != 0 ? (
        <TableContainer
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            padding: "0px 20px"
          }}
          component={Paper}>
          <Table aria-label="collapsible table">
            {setLoanHead === 1 ? (
              <TableHead>
                <TableRow>
                  {tableHeading.map((item, index) => {
                    return (
                      <TableCell style={{ whiteSpace: "nowrap" }} key={index}>
                        <Typography className={styles.fieldHeadingtext}>
                          {item}
                        </Typography>
                      </TableCell>
                    );
                  })}
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
            ) : (
              <Illustrations
                src={refinancesEmptyScreen}
                heading="No added refinances"
              />
            )}
            <TableBody style={{ backgroundColor: "rgba(250, 250, 250, 1)" }}>
              {loanTableData ? (
                loanTableData?.investment?.map((data, index) => {
                  return (
                    <>
                      <TableRow key={index}>
                        {data?.refinances.length > 0 ? (
                          <TableCell
                            style={{ border: "none", backgroundColor: "#ffff" }}
                            align="center"
                            colSpan={12}>
                            <div className={styles.tableGrouping}>
                              {data?.name}
                            </div>
                          </TableCell>
                        ) : null}
                      </TableRow>
                      {data?.refinances?.map((row, index) => {
                        return <Row key={index} index={index} row={row} />;
                      })}
                    </>
                  );
                })
              ) : (
                <TableCell
                  colSpan={12}
                  style={{ width: "100%", height: "40%" }}>
                  <Grid
                    style={{
                      boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
                      backgroundColor: "#ffff"
                    }}>
                    <Illustrations
                      src={refinancesEmptyScreen}
                      heading="No added refinances"
                    />
                  </Grid>
                </TableCell>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff"
          }}>
          <Illustrations
            src={refinancesEmptyScreen}
            heading="No added refinances"
            // subHeading="Add Investment Data To View"
          />
        </Grid>
      )}
    </Grid>
  );
}
