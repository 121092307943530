import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: "35px 40px 20px 20px"
  },
  header: {
    marginTop: 12,
    fontSize: 22,
    fontFamily: "Inter Bold",
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: 700
  },
  desc: {
    fontSize: 18,
    fontFamily: "Inter SemiBold",
    textAlign: "center",
    fontWeight: 600,
    paddingLeft: 70,
    paddingRight: 70
  },
  content: {
    fontSize: 16,
    fontFamily: "Inter Regular",
    fontWeight: 400
  },
  footer: {
    marginBottom: 20,
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  test: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },

  cancelButton: {
    backgroundColor: "#EBEFFF",
    paddingTop: 12,
    paddingBottom: 12,
    border: "1px solid #445EBE",
    paddingLeft: 58,
    paddingRight: 58,
    fontFamily: "Inter Regular",
    fontWeight: 600,
    color: "#445EBE",
    borderRadius: 4,
    marginRight: 20,
    cursor: "pointer",
    fontSize: 18
  },
  mainButton: {
    backgroundColor: "#E13838",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 58,
    paddingRight: 58,
    fontFamily: "Inter Regular",
    fontWeight: 600,
    color: "#fff",
    borderRadius: 4,
    marginRight: 20,
    cursor: "pointer",
    fontSize: 18
  }
});

const DeleteModal = ({
  title,
  description,
  open,
  handleClose,
  handleSubmit
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4" className={classes.header}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h4" className={classes.desc}>
              {description}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.test}>
          <div className={classes.footer}>
            <div className={classes.cancelButton} onClick={handleClose}>
              Cancel
            </div>
            <div className={classes.mainButton} onClick={handleSubmit}>
              Delete
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteModal;
