import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup
} from "@material-ui/core";
import React, { useRef, useState } from "react";
import CloseIcon from "assets/icons/CloseIcon.svg";
import Toast from "components/toast";
import styles from "../../../../../myProfile/profile.module.scss";
import FileUploadIcon from "assets/icons/FileUpload.svg";
import CrossIcon from "assets/icons/CrossBlack.svg";
import { CommonService } from "network/commonService";

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: "rgba(235, 239, 255, 0.6)",
    backdropFilter: "blur(26)"
  },
  container: {
    width: "100%"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 30
  },
  heading: {
    fontFamily: "Inter Bold",
    fontSize: 28
  },
  subHeading: {
    fontFamily: "Inter Regular",
    fontSize: 16,
    color: "#616161"
  },
  dialogContent: {
    width: 588,
    padding: 50,
    boxSizing: "border-box"
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    color: "#000000"
  },
  text: {
    fontFamily: "Inter Bold",
    fontSize: 18,
    marginTop: 10
  },
  uploadDocs: {
    backgroundColor: "#E5E9ED",
    padding: 10,
    marginTop: 20,
    width: 372
  },
  imgContainer: {
    display: "flex",
    alignItems: "center"
  },
  docName: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    marginLeft: 20,
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  deleteBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      cursor: "pointer"
    }
  },
  btnContainer: {
    width: 372,
    display: "flex",
    "& div": {
      width: 174,
      height: 50,
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      marginRight: 10,
      borderRadius: 4,
      fontFamily: "Inter SemiBold",
      fontSize: 18
    }
  },
  secondaryBtn: {
    backgroundColor: "#EBEFFF",
    color: "#445EBE",
    border: "1px solid #445EBE"
  },
  primaryBtn: {
    backgroundColor: "#445EBE",
    color: "#FFFFFF"
  }
}));

const InvestorDocUpload = ({ open, setOpen, setData, invDocCategories }) => {
  const classes = useStyles();
  const [docType, setDocType] = useState(invDocCategories[0]?.id);
  const [imageList, setImageList] = useState([]);
  const [docsLoading, setDocsLoading] = useState(false);
  const wrapperRef = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      resp.data.type = docType;
      resp.data.link = resp.data.file;
      const updatedFileList = [...imageList, resp.data];
      setImageList(updatedFileList);
      setDocsLoading(false);
      Toast.showSuccessToast("Documents Upload SuccessFully");
    } else {
      Toast.showErrorToast("Failed To Uploaded Documents");
    }
  };

  const deleteDoc = async (id) => {
    const resp = await CommonService.deleteMedia(id);
    if (resp.success) {
      Toast.showSuccessToast("Documents Deleted Successfully");
      setImageList((prev) => {
        return prev.filter((item) => item.id != id);
      });
    } else {
      Toast.showErrorToast("Failed to Delete Document");
    }
  };

  const handleSave = () => {
    setData(
      imageList.map((el) => {
        el.type = docType;
        return el;
      }),
      docType
    );
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className={classes.dialog}
      maxWidth={false}>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <div>
              <div className={classes.heading}>Upload Distribution Proof</div>
            </div>
            <div className={classes.closeButton}>
              <img
                height="48px"
                src={CloseIcon}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>

          <Box height={40} />
          <div className={classes.label}>Select type of distribution</div>
          <Box height={10} />
          <FormControl component="fieldset">
            <RadioGroup
              row
              name="controlled-radio-buttons-group"
              value={docType}
              onChange={(e) => setDocType(e.target.value)}>
              {invDocCategories &&
                invDocCategories
                  .filter((e) => e.name != "Misc.")
                  .map((category) => (
                    <FormControlLabel
                      value={category.id}
                      control={<Radio color="primary" />}
                      label={
                        <div className={classes.label}>{category.name}</div>
                      }
                    />
                  ))}
            </RadioGroup>
          </FormControl>
          <Box height={30} />
          <div
            style={{ width: "100%" }}
            ref={wrapperRef}
            className={styles.drop_file_input}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onDrop}>
            <div>
              {docsLoading ? (
                <CircularProgress />
              ) : (
                <>
                  <Grid container className={styles.droptext}>
                    Drag And Drop Files Here Or <span>&nbsp;Browse</span>
                  </Grid>
                </>
              )}
            </div>

            <input type="file" value="" onChange={onFileDrop} />
          </div>
          <Grid container>
            {imageList.length > 0 ? (
              <>
                {imageList.map((item) => (
                  <Grid
                    key={item.name}
                    container
                    className={classes.uploadDocs}
                    direction="row">
                    <Grid item xs={10} className={classes.imgContainer}>
                      {item.name.endsWith("png") ||
                      item.name.endsWith("jpeg") ||
                      item.name.endsWith("jpg") ? (
                        <img
                          src={item.link}
                          style={{ height: 54, width: 54, borderRadius: 10 }}
                        />
                      ) : (
                        <img src={FileUploadIcon} />
                      )}
                      <div className={classes.docName}>{item.name}</div>
                    </Grid>
                    <Grid item xs={2} className={classes.deleteBtn}>
                      <img
                        onClick={() => deleteDoc(item.id)}
                        src={CrossIcon}
                        alt="Delete"
                      />
                    </Grid>
                  </Grid>
                ))}
              </>
            ) : null}
          </Grid>
          <Box height={40} />
          <div className={classes.btnContainer}>
            <div
              className={classes.secondaryBtn}
              onClick={() => setOpen(false)}>
              Cancel
            </div>
            <div className={classes.primaryBtn} onClick={handleSave}>
              Attach
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvestorDocUpload;
