import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  makeStyles
} from "@material-ui/core";
import AccordionHeading from "components/AccordianHeader/accordianHeader";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import { FieldArray, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import BasicInformationSection from "./components/basicInformation";
import Section2 from "./components/Section2";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "assets/icons/PlusAdd.svg";
import { validationSchemaDistributionAdd } from "helpers/validationSchema";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import Toast from "components/toast";
import { formatDate, numFormatter } from "helpers";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import { AddInvestmentDistributionService } from "network/addInvestmentDistributionService";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useSelector } from "react-redux";
import { AnimatePresence, motion } from "framer-motion";
import NewCategoryDocTable from "pages/private/properties/component/NewCategoryDocTable";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    padding: "0 0px 60px 0px",
    borderRadius: 8,
    boxSizing: "border-box"
  },
  innerContainer: {
    backgroundColor: "#FFFFFF",
    padding: "20px 10px",
    radius: 8,
    // margin: "0 0 20px",
    width: "100%"
  },
  sectionHeader: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    padding: "0px 0px 20px 0px"
  },
  sectionTitle: {
    fontFamily: "Inter SemiBold",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#191919"
  },
  divider: {
    height: "0px",
    width: "100px",
    flexGrow: 1,
    margin: "0 20px",
    backgroundColor: "#E9E9E9"
  },
  delete: {
    color: "#E13838",
    flexShrink: 0,
    fontFamily: "Inter Regular",
    fontSize: 14,
    cursor: "pointer"
  },
  accordionContainer: {
    padding: "0 10px"
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white"
    }
  },
  docContainer: {
    padding: 0
  },
  addMoreButton: {
    display: "flex",
    alignItems: "center",
    "& span": {
      color: "#445EBE",
      fontFamily: "Inter Regular",
      fontSize: 14,
      paddingLeft: 8
    },
    "& *": {
      cursor: "pointer"
    }
  },
  buttton: {
    fontFamily: "Inter SemiBold",
    fontSize: 16,
    height: 45,
    minWidth: 180,
    padding: "0px 30px !important",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#5775E5",
    color: "#ffffff",
    cursor: "pointer"
  },
  butttonCancel: {
    fontFamily: "Inter SemiBold",
    fontSize: 16,
    height: 45,
    minWidth: 180,
    padding: "0px 30px !important",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: "#5775E5",
    cursor: "pointer",
    border: "1px solid #5775E5"
  },
  errorText: {
    marginTop: 12,
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#E13838",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    "& div": {
      marginLeft: 12
    }
  }
}));

const AddDistribution = () => {
  const classes = useStyles();
  const [investmentId, setInvestmentId] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const { userPermissions, user } = useSelector((store) => store.app);
  const [invData, setInvData] = useState({
    startDate: "",
    cadence: ""
  });
  const history = useHistory();
  const [cadenceData, setCadenceData] = useState({
    MONTH_CHOICES: [],
    QUARTER_CHOICES: []
  });
  const [docCategories, setDocCategories] = useState([]);
  const [distributionType, setDistributionType] = useState([]);
  const [invDocCategories, setInvDocCategories] = useState([]);
  const [proofChoices, setProofChoices] = useState([]);
  const [selectedCurrentYear, setSelectedCurrentYear] = useState([]);

  const [initialValues] = useState({
    distributionSet: [
      {
        selectInvestement: "",
        investmentData: [],
        minYear: 2000,
        date: "",
        type: "",
        year: "",
        notes: "",
        totalDistribution: "",
        investors: [],
        investorSum: {},
        amountSum: 0,
        investorsSelected: 0,
        documents: [],
        distMonth: ""
      }
    ]
  });

  const handleAddMore = (arrayHelper) => {
    arrayHelper.push({
      selectInvestement: "",
      investmentData: [],
      minYear: 2000,
      date: "",
      type: "",
      year: "",
      notes: "",
      totalDistribution: "",
      investors: [],
      investorSum: {},
      amountSum: 0,
      investorsSelected: 0,
      documents: [],
      distMonth: ""
    });
  };

  const getInvDocCategoryMapping = () => {
    let categories = {};
    invDocCategories.forEach((cat) => {
      categories[cat.id] = proofChoices.find(
        (el) =>
          el.label ===
          (cat.name === "Wire-Transfer"
            ? "Wire Transfer"
            : cat.name === "Check"
            ? cat.name
            : "")
      )?.value;
    });
    return categories;
  };

  useEffect(() => {}, [invData]);

  const getPayload = (values) => {
    let payload = [];
    let invDocCategoryMapping = getInvDocCategoryMapping();
    values.distributionSet.forEach((dist) => {
      let distObj = {
        date: formatDate(dist.date),
        type: dist.type,
        total_distribution: dist.totalDistribution,
        year: dist.year
      };
      let cadenceList =
        dist?.investmentData?.distribution_cadence === "monthly"
          ? cadenceData.MONTH_CHOICES
          : cadenceData.QUARTER_CHOICES;
      let investorsList = dist.investors.filter((el) => el.isSelected);
      cadenceList.forEach((cadence) => {
        let investor_distribution = [];
        investorsList.forEach((investor) => {
          if (Number(investor?.value[cadence?.value] || 0)) {
            investor_distribution.push({
              investor_id: investor.isSelected,
              added_by: user?.id,
              notes: dist.notes,
              type: investor?.docs[cadence.value]?.length
                ? invDocCategoryMapping[investor?.type[cadence.value]]
                : null,
              amount: investor.value[cadence.value],
              documents: investor?.docs[cadence.value]?.map((doc) => ({
                document_media_id: doc.id,
                category_id: doc.type
              }))
            });
          }
        });
        if (investor_distribution.length) {
          payload.push({
            ...distObj,
            investor_distribution,
            cadence: cadence.value,
            documents: dist?.documents?.map((doc) => ({
              document_media_id: doc.id,
              category_id: doc.type
            }))
          });
        }
      });
    });
    return { distribution: payload };
  };

  const handleSubmit = async (values) => {
    setButtonLoader(true);

    const payload = getPayload(values);

    if (payload?.distribution?.length) {
      const res = await AddInvestmentDistributionService.addDistribution(
        payload,
        investmentId
      );
      if (res.success) {
        Toast.showSuccessToast("Distributions Added Successfully");
        setButtonLoader(false);
      } else {
        Toast.showErrorToast("Failed to Add Distributions");
        setButtonLoader(false);
      }
    }
    history.push("/u/distributions");
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTDISTRIBUTION"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
    const invCategories = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTDISTRIBUTIONINVESTOR"
    });
    if (invCategories.success) {
      if (invCategories.data.length) {
        setInvDocCategories(invCategories.data[0]?.doccategory);
      }
    } else {
      Toast.showErrorToast("Failed To Load Document Categories");
    }
  };

  const initDropdownValues = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setDistributionType(res?.data.DISTRIBUTION_TYPE_CHOICES);
      setCadenceData({
        MONTH_CHOICES: res?.data.MONTH_CHOICES,
        QUARTER_CHOICES: res?.data.QUARTER_CHOICES,
        MONTH_QUARTER_MAPPING: res?.data.MONTH_QUARTER_MAPPING
      });
      setProofChoices(res?.data?.PROOF_CHOICE);
    }
  };

  const setDocData = (list, formik, index) => {
    formik.setFieldValue(`distributionSet[${index}].documents`, list);
  };

  const getFormattedInvestorList = (values) => {
    let data = [];
    values?.investmentData?.investors.forEach((investor) => {
      let newInvestor = {
        investorId: investor?.id,
        investor_name: investor?.investor + " ",
        entity_name: investor?.investor_entity?.name || "-",
        preferred_return: (investor?.prefered_return || "0") + "%",
        invested_amount:
          "$" +
          (numFormatter(investor?.investor_stats?.invested_amount) || "0"),
        membership: (investor?.member_percent || "0") + "%"
      };
      data.push(newInvestor);
    });

    return data;
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "DISTRIBUTIONS",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_create) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    checkPermissions();
    loadCategories();
    initDropdownValues();
  }, []);

  useEffect(() => {
    getFormattedInvestorList();
  }, [investmentId]);

  return (
    <>
      <div>
        <HeaderComponent
          heading="Add Distributions"
          description="Add Relevent Details To The Specific Investor And Investment Profile"
          backBtn={true}
        />
        <Box height={26} />
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchemaDistributionAdd}
          onSubmit={(values) => {
            handleSubmit(values);
          }}>
          {(formik) => (
            <>
              <div className={classes.container}>
                <FieldArray
                  name="distributionSet"
                  render={(arrayHelper) => (
                    <>
                      {formik.values.distributionSet.map((dist, index) => (
                        <Grid item key={`distributionSet${index}`}>
                          <Box height={20} />
                          <BasicInformationSection
                            arrayHelper={arrayHelper}
                            formik={formik}
                            styles={classes}
                            index={index}
                            invData={invData}
                            selectedCurrentYear={selectedCurrentYear}
                            setSelectedCurrentYear={setSelectedCurrentYear}
                            lists={{ distributionType, cadenceData }}
                            key={`DistributionBasic${index}`}
                            setInvestmentId={setInvestmentId}
                            setInvData={setInvData}
                            investmentId={investmentId}
                          />
                          {formik?.values?.distributionSet[index]
                            ?.selectInvestement && (
                            <AnimatePresence>
                              <motion.div
                                key="content"
                                initial="collapsed"
                                animate="open"
                                exit="collapsed"
                                variants={{
                                  open: { opacity: 1, height: "auto" },
                                  collapsed: { opacity: 0, height: 0 }
                                }}
                                transition={{
                                  duration: 0.8,
                                  ease: [0.04, 0.62, 0.23, 0.98]
                                }}>
                                <Section2
                                  formik={formik}
                                  styles={classes}
                                  index={index}
                                  key={`DistributionTable${index}`}
                                  investorList={getFormattedInvestorList(
                                    formik?.values?.distributionSet[index]
                                  )}
                                  invData={invData}
                                  cadenceData={cadenceData}
                                  cadanceToggle={
                                    formik.values.distributionSet[index]
                                      ?.investmentData?.distribution_cadence
                                  }
                                  selectedCurrentYear={selectedCurrentYear}
                                  invDocCategories={invDocCategories}
                                />
                              </motion.div>
                            </AnimatePresence>
                          )}
                          <Grid
                            container
                            className={classes.docContainer}
                            key={`DistributionDocs${index}`}>
                            <Accordion
                              className={classes.accordionContainer}
                              defaultExpanded
                              style={{ width: "100%" }}
                              classes={{ root: classes.MuiAccordionroot }}>
                              <AccordionSummary
                                style={{ padding: 0 }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header">
                                <AccordionHeading
                                  title="Upload Documents"
                                  padding={0}
                                />
                              </AccordionSummary>
                              <AccordionDetails style={{ padding: 0 }}>
                                <div
                                  style={{
                                    display: "flex",
                                    flexWrap: "wrap"
                                  }}>
                                  {docCategories && (
                                    <NewCategoryDocTable
                                      sponDocCategories={docCategories}
                                      listData={
                                        formik.values.distributionSet[index]
                                          .documents
                                      }
                                      setListData={(list) =>
                                        setDocData(list, formik, index)
                                      }
                                    />
                                  )}
                                  {/* {docCategories &&
                                    docCategories
                                      .filter((e) => e.name != "Misc.")
                                      .map((category) => (
                                        <DocumentsUpload
                                          key={category.id}
                                          type={category.id}
                                          title={category.name}
                                          listData={
                                            formik.values.distributionSet[index]
                                              .documents
                                          }
                                          setListData={(list) =>
                                            setDocData(list, formik, index)
                                          }
                                        />
                                      ))} */}
                                  {/* <DocumentsUpload
                                    filterDocList={docCategories}
                                    title="Other"
                                    listData={
                                      formik.values.distributionSet[index]
                                        .documents
                                    }
                                    setListData={(list) =>
                                      setDocData(list, formik, index)
                                    }
                                  /> */}
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </Grid>
                        </Grid>
                      ))}
                      <Box height={20} />
                      <div className={classes.addMoreButton}>
                        <img
                          src={AddIcon}
                          width="30px"
                          height="30px"
                          onClick={() => handleAddMore(arrayHelper)}
                        />
                        <span
                          onClick={() => handleAddMore(arrayHelper)}
                          style={{
                            fontSize: "15px",
                            fontWeight: 600,
                            color: "rgba(68, 94, 190, 1)"
                          }}>
                          Add More Distributions
                        </span>
                      </div>
                    </>
                  )}
                />
              </div>
              <Grid
                container
                spacing={3}
                style={{
                  // position: "fixed",
                  // bottom: "0px",
                  backgroundColor: "#fff"
                }}
                justifyContent="flex-end">
                <Grid item>
                  <div
                    className={classes.butttonCancel}
                    onClick={() => {
                      history.goBack();
                    }}>
                    Cancel
                  </div>
                </Grid>
                <Grid item>
                  <div
                    className={classes.buttton}
                    onClick={formik.handleSubmit}>
                    {buttonLoader ? (
                      <CircularProgress color="#fff" size="1.5rem" />
                    ) : (
                      "Add Distribution"
                    )}
                  </div>
                </Grid>
              </Grid>
            </>
          )}
        </Formik>
      </div>
    </>
  );
};

export default AddDistribution;
