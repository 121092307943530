import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    padding: "35px 40px 20px 20px"
  },
  header: {
    marginTop: 12,
    fontSize: 16,
    fontFamily: "Inter Bold"
  },
  desc: {
    alignItems: "center",
    marginLeft: 20,
    fontSize: 20,
    fontFamily: "Inter SemiBold"
  },
  content: {
    fontSize: 16,
    fontFamily: "Inter Regular"
  },
  footer: {
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 50,
    display: "flex",
    flexDirection: "row"
  },
  mainButton: {
    backgroundColor: "#5775E6",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 39,
    paddingRight: 39,
    fontFamily: "Inter Regular",
    fontWeight: 600,
    color: "#fff",
    borderRadius: 4,
    marginRight: 20,
    cursor: "pointer"
  }
});

const CancelModal = ({
  title,
  description,
  open,
  handleClose,
  handleSubmit
}) => {
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h4" className={classes.header}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="h4" className={classes.desc}>
              {description}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={classes.footer}>
            <div className={classes.mainButton} onClick={handleClose}>
              No
            </div>
            <div className={classes.mainButton} onClick={handleSubmit}>
              Yes
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CancelModal;
