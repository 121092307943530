import React, { useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Endpoint, NetworkManager } from "../../../../network/core/index";
import "./properties.css";
import Toast from "components/toast";
import { numFormatter } from "helpers";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useHistory } from "react-router-dom";
import ToggleSwitch from "components/Common/ToggleSwitch";
import styles from "components/Common/toggle.module.scss";
import DollerActive from "../../../../assets/icons/tab/DollerActive.svg";
import DollerInactive from "../../../../assets/icons/tab/DollerInactive.svg";
import investorActive from "../../../../assets/icons/tab/investorActive.svg";
import investorInactive from "../../../../assets/icons/tab/investorInactive.svg";
import PropertyActive from "../../../../assets/icons/tab/PropertyActive.svg";
import PropertyInactive from "../../../../assets/icons/tab/PropertyInactive.svg";
import entitiesActive from "../../../../assets/icons/tab/entitiesActive.svg";
import entitiesInactive from "../../../../assets/icons/tab/entitiesInactive.svg";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import { AppDispatcher } from "redux/index";
import DistributionCard from "components/Cards/DistributionCard";
import TotalInvestementIcon from "../../../../assets/icons/NewCardSvg/totalAssetValue.svg";
import TotalDistributionAmountIcon from "../../../../assets/icons/NewCardSvg/totalDistribution.svg";
import TotalDistributionReceivedIcon from "../../../../assets/icons/NewCardSvg/totalEntitiesBlue.svg";
import CocIcon from "../../../../assets/icons/NewCardSvg/voiletCardCircle.svg";
import IRRIcon from "../../../../assets/icons/NewCardSvg/orangeCardCircle.svg";
import { Skeleton } from "@material-ui/lab";
import DetailsTable from "components/Common/CommonDetailsTable/DetailsTable";
import formatCurrency from "helpers/formatCurrency";
import { truncate } from "helpers/Common";
import DataContainer from "components/Common/CommonDetailsTable/DataContainer";
import PropertiesTable from "components/Common/CommonDetailsTable/PropertiesTable";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import ExportPDFButton from "components/Buttons/Primary/ExportPDFButton";

const useStyles = makeStyles({
  icon: {
    paddingRight: 15,
    height: 18
  },
  sectionContainer: {
    // padding: 20,
    borderRadius: "8px",
    marginTop: 10
  },
  header: {
    fontFamily: "Inter Bold",
    fontSize: 18,
    fontWeight: "700",
    fontStyle: "normal"
  },
  propertyContainer: {
    width: "100%",
    padding: 20,
    borderRadius: 8
  },
  propertyDetail: {
    display: "flex",
    padding: "10px 30px",
    backgroundColor: "#FFFFFF",
    borderRadius: 4
  },
  justifyCard: {
    marginTop: 22.5,
    marginRight: 28,
    marginBottom: 20,
    marginLeft: 20,
    backgroundColor: "#FFFFFF",
    width: "100%",
    padding: 20,
    borderRadius: 4
  },
  contactContainer: {
    display: "flex",
    margin: 20,
    width: "fit-content",
    minWidth: "50%",
    maxWidth: "100%",
    flexWrap: "wrap",
    background: "#ffffff",
    borderRadius: "8px",
    padding: 20,
    paddingTop: 0
  },
  contactItem: {
    flexGrow: 0,
    flexShrink: 0,
    marginRight: 40,
    marginLeft: 20
  },
  subHead: {
    fontFamily: "Inter Regular",
    fontWeight: 600,
    color: "#BBBBBB",
    fontSize: 16
  },
  subContent: {
    fontFamily: "Inter Bold",
    fontWeight: 700,
    color: "#191919",
    fontSize: 16
  },
  contactSection: {
    width: "100%",
    background: "#FFFFFF",
    padding: 20
  },
  sectionTitle: {
    marginLeft: 17,
    marginTop: 20,
    alignItems: "center"
  },
  accordionContainer: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white"
    }
  },
  docLength: {
    padding: "1px 5px 2px",
    background: "#445EBE",
    borderRadius: "50%",
    color: "#FFFFFF",
    marginLeft: "5px"
  }
});

const InvestorDetail = () => {
  const classes = useStyles();
  const { investorDetailId } = useSelector((store) => store.app);
  const [investorDetail, setInvestorDetail] = useState({});
  const [investorName, setInvestorName] = useState("");
  const [stats, setStats] = useState();
  const [overview, setOverview] = useState();
  const [investments, setInvestments] = useState([]);
  const [entities, setEntities] = useState([]);
  const [dropDownOpRes, setDropDownOpRes] = useState(null);
  const [permissions, setPermissions] = useState();
  const { userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();
  const [allDocs, setAllDocs] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const ToggleList = [
    {
      id: 0,
      name: "Overview",
      color: "#5775E5",
      textColor: "#fff",
      inActiveIcon: DollerInactive,
      activeIcon: DollerActive
    },
    {
      id: 1,
      name: "Investments",
      textColor: "#BBBBBB",
      inActiveIcon: investorInactive,
      activeIcon: investorActive
    },
    {
      id: 2,
      name: "Entities",
      textColor: "#BBBBBB",
      inActiveIcon: entitiesInactive,
      activeIcon: entitiesActive
    },
    {
      id: 3,
      name: "Properties",
      textColor: "#BBBBBB",
      inActiveIcon: PropertyInactive,
      activeIcon: PropertyActive
    }
  ];

  const handleEdit = () => {
    AppDispatcher.setInvestorId(investorDetailId);
    history.push("/u/investors/editInvestor");
  };

  const fetchInvestorDetails = async () => {
    const instance = new NetworkManager(
      new Endpoint(`admin/investor/detail/${investorDetailId}/`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      let temp = resp.data;
      setAllDocs(temp?.documents);
      setStats(temp?.stats);
      setOverview(temp);
      setInvestments(temp?.investments);
      setEntities(temp.entities);
      setInvestorDetail(temp);
      setInvestorName(temp.name);
    } else {
      Toast.showErrorToast("Failed To Load Investor Data");
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "INVESTORS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(async () => {
    checkPermissions();
    await fetchInvestorDetails();
  }, []);

  /* ****************** investor Contact ************************************/
  const investorContact = () => {
    const processFieldData = (input) => {
      return stats ? input : <Skeleton width={150} variant="text" /> || "-";
    };
    const data = [
      {
        name: "Email",
        fieldData: processFieldData(overview?.email)
      },
      {
        name: "Phone Number",
        fieldData: processFieldData(
          overview?.country_code + overview?.phone_number
        )
      }
    ];

    return (
      <Grid container>
        <DataContainer title="Overview" data={data} xs={6} />
      </Grid>
    );
  };

  /**********************UploadDocuments ******************************/
  const UploadeDocumentTab = () => {
    return (
      <>
        {/*  */}
        <Grid container className={classes.sectionContainer}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item className={classes.header}>
                Uploaded Documents{" "}
                <span className={classes.docLength}>{allDocs.length}</span>
              </Grid>
            </Grid>
          </Grid>
          <div>
            <div style={{ background: "#FFFFFF" }}>
              {/* <UploadedDocsList documentsList={allDocs} /> */}
            </div>
          </div>
        </Grid>
        {/*  */}
      </>
    );
  };

  /********************** Investments **************************************/
  const Investments = () => {
    const investmentTableHeadingData = [
      "Investment Name",
      "Managed By",
      "Investor Entity",
      "Membership",
      "Invested Amount",
      "Total Asset Value",
      "Outstanding Loan",
      "Equity",
      "Current Value",
      "Status"
    ];

    const investorData = investments?.map((item) => {
      return [
        [item?.investment?.name || "-", "investment_id", item?.investment?.id],
        item?.investment?.managed_by,
        [item?.entity?.name || "-", "entity_id", item?.entity?.id],
        item?.member_percent,
        formatCurrency(item?.total_amount),
        formatCurrency(item?.stats?.total_asset),
        formatCurrency(item?.stats?.outstanding),
        formatCurrency(item?.stats?.net_equity),
        formatCurrency(item?.stats?.current_value),
        item?.investment?.status
      ];
    });

    const tooltipData = investments?.map((item) => {
      return [
        ["Investment ID", item?.investment?.base_id],
        ["Total Properties", item?.stats?.total_properties_count || "0"],
        ["Total Distributions", item?.stats?.total_distribution_count || "0"],
        [
          "Total Distribtion Amount",
          formatCurrency(item?.stats?.total_distribution_amount) || "0"
        ],
        ["Net Equity", formatCurrency(item?.stats?.net_equity) || "0"],
        ["Created By", item?.investment?.created_by || "-"]
      ];
    });

    return (
      <Grid container className={`${classes.sectionContainer}`}>
        <Grid item xs={12}>
          <Grid container>
            <DetailsTable
              tableTitle="Associated Investments"
              tableHeading={investmentTableHeadingData}
              sponserData={investorData}
              tooltipData={tooltipData}
            />
          </Grid>
        </Grid>
        {/* <InvestmentTable investment={investments} /> */}
      </Grid>
    );
  };

  /***********************properties**************************************/
  const PropertyTab = () => {
    const sponserTableHeadingData = [
      "Entity Name",
      "Managed By",
      "Investments",
      "Tax ID",
      "Total Invested",
      "Total Asset Value",
      "Outstanding Loan",
      "Equity",
      "Current Value",
      "Status"
    ];

    const sponserData = entities?.map((item) => {
      const investmentNameList = item?.investments?.map((inv) => {
        return {
          id: inv?.id,
          name: truncate(inv?.name, 36)
        };
      });
      return [
        [item?.name, "entity_id", item?.id],
        item?.managed_by,
        investmentNameList,
        item?.tax_id,
        formatCurrency(item?.stats?.total_invested_amount),
        formatCurrency(item?.stats?.total_asset_value),
        formatCurrency(item?.stats?.outstanding_loan),
        formatCurrency(item?.stats?.net_equity),
        formatCurrency(item?.stats?.current_value),
        item?.status
      ];
    });

    const tooltipData = entities?.map((item) => {
      return [
        ["Entity ID", item?.base_id],
        ["Distribution Count", item?.stats?.distribution_count || "0"],
        [
          "Distribution Amount",
          formatCurrency(item?.stats?.distribution_amount) || "0"
        ],
        ["Net Equity", formatCurrency(item?.stats?.net_equity) || "0"],
        ["Created By", item?.created_by?.name || "0"]
      ];
    });

    return (
      <Grid container className={classes.sectionContainer}>
        <Grid item xs={12}>
          <Grid container>
            <DetailsTable
              tableTitle="Associated Entities"
              tableHeading={sponserTableHeadingData}
              sponserData={sponserData}
              handleCellClickName="investment_id"
              tooltipData={tooltipData}
            />
          </Grid>
        </Grid>
        {/* <PropertyTable properties={properties} /> */}
      </Grid>
    );
  };

  /***************************Entities*********************************** */
  const Entities = () => {
    const heading = [
      "Property Name",
      "Managed By",
      "Property Value",
      "Loans",
      "Area/Units",
      "Type",
      "Total Units",
      "Occupancy",
      "Investment Status"
    ];
    let val = [];
    stats?.investments?.map((values) => {
      values?.properties?.map((item) => {
        val.push(item);
      });
    });

    return (
      <Grid container className={classes.sectionContainer} item xs={12}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item className={classes.header}>
              Associated Properties
            </Grid>
          </Grid>
        </Grid>
        <PropertiesTable
          dropDownOpRes={dropDownOpRes}
          data={overview?.investments}
          heading={heading}
        />

        {/* <EntityTable entities={entities} /> */}
      </Grid>
    );
  };

  /**************************************************************/

  const loadInvestmentDetails = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <>
            <Grid container>
              <Grid item xs={12} lg={6} md={6}>
                {investorContact()}
              </Grid>
              <Grid item xs={12} lg={6} md={6}>
                {/* {UploadeDocumentTab()} */}
              </Grid>
            </Grid>
          </>
        );
      case 1:
        return Investments();
      case 2:
        return PropertyTab();
      case 3:
        return Entities();
      default:
        return (
          <>
            <Grid container>
              <Grid item xs={12}>
                {investorContact()}
              </Grid>
              <Grid item xs={12}>
                {UploadeDocumentTab()}
              </Grid>
            </Grid>
          </>
        );
    }
  };
  const processIncommingData = (data) => {
    return data !== null ? data : <Skeleton width={150} />;
  };

  useEffect(() => {
    const getOptions = async () => {
      const res = await AddInvestmentBasicDetailsService.dropdownOptions();
      if (res.success) {
        setDropDownOpRes(res?.data);
      } else {
        Toast.showErrorToast("Failed To Load Distribution Types");
      }
    };

    getOptions();
  }, []);

  const cardsData = [
    {
      title: "Total Invested Amount",
      // link: "/u/investors",
      count: stats
        ? processIncommingData(`$${numFormatter(stats.total_invested_amount)}`)
        : processIncommingData(null),
      icon: TotalDistributionReceivedIcon
    },
    {
      title: "Total Investments",
      // link: "/u/investments",
      count: stats
        ? processIncommingData(
            `$${numFormatter(stats?.total_investment_count)}`
          )
        : processIncommingData(null),
      icon: TotalInvestementIcon
    },
    {
      title: "Total Distribution Amount",
      count: stats
        ? processIncommingData(
            `$${numFormatter(stats.total_distribution_amount)}`
          )
        : processIncommingData(null),
      icon: TotalDistributionAmountIcon
    },
    {
      title: "Total Entities",
      count: stats
        ? processIncommingData(`$${numFormatter(stats?.total_entities_count)}`)
        : processIncommingData(null),
      icon: TotalDistributionReceivedIcon
    },
    {
      title: "CoC",
      count: stats
        ? processIncommingData(`${stats?.coc}%`)
        : processIncommingData(null),
      icon: CocIcon
    },
    {
      title: "IRR",
      count: processIncommingData(
        stats?.irr ? (stats?.irr === "NA" ? "N/A" : `${stats?.irr}%`) : null
      ),
      icon: IRRIcon
    }
  ];

  return (
    <>
      <HeaderComponent
        ExtraButtons={<ExportPDFButton />}
        exportPdf="Export PDF"
        backBtn={true}
        heading={investorName}
        description={`ID: ${investorDetail.base_id}`}
        investment_Status={investorDetail.status}
        isEditInvestorBtn={true}
        onEditInvestmentBtnClick={
          permissions?.is_update ? handleEdit : () => {}
        }
        addedVia={"Admin"}
      />

      {/* cards */}
      <Grid
        container
        style={{
          marginTop: 40,
          marginBottom: 30,
          display: "flex",
          justifyContent: "space-between"
        }}>
        <Grid container style={{ marginTop: 40 }} direction="row">
          {cardsData?.map((item, index) => {
            return (
              <DistributionCard
                key={index}
                title={item.title}
                count={item.count}
                icon={item.icon}
                link={item.link}
              />
            );
          })}
        </Grid>
      </Grid>

      {/* ToggleTabs */}

      <div className={styles.toggleresponiveCard}>
        <Grid
          container
          style={{
            backgroundColor: "#FAFAFA",
            height: 56,
            marginBottom: 20,
            borderRadius: 10
          }}
          className={styles.togglecardGrid}>
          <ToggleSwitch
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            list={ToggleList}
            xs={3}
            lg={2}
            md={3}
            sm={3}
          />
        </Grid>
      </div>

      {/*Details*/}
      <Grid container>
        <Grid item xs={12}>
          {loadInvestmentDetails()}
        </Grid>
      </Grid>
    </>
  );
};

export default InvestorDetail;
