import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory } from "react-router";
import { useSelector } from "react-redux";
import PrivateLayout from "../layout/privateLayout";
import NoNetworkConnectionIcon from "../assets/icons/noNetworkConnectionIcon.svg";
import styles from "./authWrapper.module.scss";
import AddBtn from "components/Buttons/addBtn";
import Logo from "../assets/icons/simpleeLogo.png";
import { AppDispatcher } from "redux/index";

const AuthWrapper = ({ component: Component, ...rest }) => {
  const { isLogged, retainFilter } = useSelector((state) => state.app);
  const [network, setNetwork] = useState(true);
  const history = useHistory();

  const ResetRetainedFilter = () => {
    AppDispatcher.retainFilters({
      ...retainFilter,
      searchQuery: "",
      checked: [],
      status: "All",
      addedVia: "added_via"
    });
    AppDispatcher.retainCount(10);
    AppDispatcher.retainManageColumn([]);
    AppDispatcher.retainPage(1);
    AppDispatcher.retainPageValue(0);
    AppDispatcher.retainPageSize(10);
  };

  const handleNetworkRecovery = () => {
    window.location.reload();
  };

  useEffect(() => {
    setNetwork(navigator.onLine);
    window.addEventListener("online", () => {
      setNetwork(true);
    });
    window.addEventListener("offline", () => {
      setNetwork(false);
    });
    window.onbeforeunload = () => {
      ResetRetainedFilter();
    };
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged === true ? (
          network ? (
            <PrivateLayout {...props}>
              <Component {...props} />
            </PrivateLayout>
          ) : (
            <div>
              <div className={styles.logo}>
                <img
                  className={styles.clickable}
                  src={Logo}
                  onClick={() => history.push("/u/dashboard")}
                />
              </div>
              <div className={styles.noNetworkContainer}>
                <img className={styles.image} src={NoNetworkConnectionIcon} />
                <div className={styles.noNetworkTextContainer}>
                  <div className={styles.noNetworkText}>
                    No Internet Connection
                  </div>
                  <div className={styles.noNetworkSub}>
                    Please Check Your Network And Try Again.
                  </div>
                </div>

                <AddBtn text="Retry" onClick={() => handleNetworkRecovery()} />
              </div>
            </div>
          )
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

export default AuthWrapper;
