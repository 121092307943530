import { Grid } from "@material-ui/core";
import CustomGraph from "components/Graphs/CustomGraph";
import formatCurrency from "helpers/formatCurrency";
import { PropertiesService } from "network/propertiesService";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const PropertyGrpah = () => {
  const { propertiesDetailId } = useSelector((store) => store.app);

  const [graphInfo, setgraphInfo] = useState({
    id: "",
    toggleStatus: "Monthly",
    year: new Date().getFullYear(),
    yearRange: [new Date().getFullYear(), new Date().getFullYear() - 1]
  });
  const [NoiLGData, setNoiLGData] = useState([
    {
      finance_expenses: [],
      noi: [],
      non_operating_expenses: [],
      operating_expenses: [],
      revenue: [],
      year: []
    }
  ]);
  const [NoiBGData, setNoiBGData] = useState({ actualNOI: [], priorNOI: [] });
  const [OccuData, setOccuData] = useState({ occupancy: [], units_leased: [] });
  const [CollData, setCollData] = useState({});
  const [AvrageData, setAvrageData] = useState({});
  const [OperExpData, setOperExpData] = useState({});

  const fetchNoiLGDataGraphData = async (prmData) => {
    let params = "";
    if (prmData.toggleStatus !== "Monthly") {
      params = `${propertiesDetailId}/noi/?type=quarterly&year=${prmData.year}`;
    } else {
      params = `${propertiesDetailId}/noi/?year=${prmData.year}`;
    }
    const resp = await PropertiesService.propertyDetailGraph(params);
    if (resp.success) {
      const dataObj = resp.data;
      const noiArray = [];
      const finance_expenses = [];
      const non_operating_expenses = [];
      const operating_expenses = [];
      const revenue = [];
      const year = [];

      for (const key in dataObj) {
        noiArray.push(dataObj[key].noi);
        finance_expenses.push(dataObj[key].finance_expenses);
        non_operating_expenses.push(dataObj[key].non_operating_expenses);
        operating_expenses.push(dataObj[key].operating_expenses);
        revenue.push(dataObj[key].revenue);
        year.push(dataObj[key].year);
      }
      setNoiLGData({
        finance_expenses: finance_expenses,
        noi: noiArray,
        non_operating_expenses: non_operating_expenses,
        operating_expenses: operating_expenses,
        revenue: revenue,
        year: year
      });
    }
  };

  const fetchNoiBGDataGraphData = async (prmData) => {
    let params = "";
    if (prmData.toggleStatus !== "Monthly") {
      params = `${propertiesDetailId}/noi/?type=quarterly&actual_year=${prmData.yearRange[0]}&compare=True&prior_year=${prmData.yearRange[1]}`;
    } else {
      params = `${propertiesDetailId}/noi/?actual_year=${prmData.yearRange[0]}&compare=True&prior_year=${prmData.yearRange[1]}`;
    }
    const resp = await PropertiesService.propertyDetailGraph(params);
    if (resp.success) {
      const dataObj = resp.data;
      const Actualdata = [];
      const Priordata = [];

      for (const key in dataObj) {
        Actualdata.push(dataObj[key].actual_noi);
        Priordata.push(dataObj[key].prior_year_noi);
      }
      setNoiBGData({
        actualNOI: Actualdata,
        priorNOI: Priordata
      });
    }
  };

  const fetchOccupancyGraphData = async (prmData) => {
    let params = "";
    if (prmData.toggleStatus !== "Monthly") {
      params = `${propertiesDetailId}/?year=${prmData.year}&type=quarterly&data=occupancy`;
    } else {
      params = `${propertiesDetailId}/?year=${prmData.year}&data=occupancy`;
    }
    const resp = await PropertiesService.propertyDetailGraph(params);
    if (resp.success) {
      const dataObj = resp.data;
      const occupancy = [];
      const units_leased = [];

      for (const key in dataObj) {
        occupancy.push(dataObj[key].occupancy);
        units_leased.push(dataObj[key].units_leased);
      }
      setOccuData({
        occupancy: occupancy,
        units_leased: units_leased
      });
    }
  };

  const fetchCollectionGraphData = async (prmData) => {
    let params = "";
    if (prmData.toggleStatus !== "Monthly") {
      params = `${propertiesDetailId}/?year=${prmData.year}&type=quarterly&data=collections`;
    } else {
      params = `${propertiesDetailId}/?year=${prmData.year}&data=collections`;
    }
    const resp = await PropertiesService.propertyDetailGraph(params);
    if (resp.success) {
      const dataObj = resp.data;
      const collections = [];
      const rent_collected = [];

      for (const key in dataObj) {
        collections.push(dataObj[key].collections);
        rent_collected.push(dataObj[key].rent_collected);
      }
      setCollData({
        collections,
        rent_collected
      });
    }
  };

  const fetchAverageRentGraphData = async (prmData) => {
    let params = "";
    if (prmData.toggleStatus !== "Monthly") {
      params = `${propertiesDetailId}/?year=${prmData.year}&type=quarterly&data=average_rent`;
    } else {
      params = `${propertiesDetailId}/?year=${prmData.year}&data=average_rent`;
    }
    const resp = await PropertiesService.propertyDetailGraph(params);
    if (resp.success) {
      const dataObj = resp.data;
      const average_rent = [];

      for (const key in dataObj) {
        average_rent.push(dataObj[key].average_rent);
      }
      setAvrageData({
        average_rent
      });
    }
  };

  const fetchOperatingExpenseGraphData = async (prmData) => {
    let params = "";
    if (prmData.toggleStatus !== "Monthly") {
      params = `${propertiesDetailId}/operating/?type=quarterly&actual_year=${prmData.yearRange[0]}&compare=True&prior_year=${prmData.yearRange[1]}`;
    } else {
      params = `${propertiesDetailId}/operating/?actual_year=${prmData.yearRange[0]}&compare=True&prior_year=${prmData.yearRange[1]}`;
    }
    const resp = await PropertiesService.propertyDetailGraph(params);
    if (resp.success) {
      const dataObj = resp.data;
      const actual_val = [];
      const prior_val = [];

      for (const key in dataObj) {
        actual_val.push(dataObj[key].actual_val);
        prior_val.push(dataObj[key].prior_val);
      }
      setOperExpData({
        actual_val,
        prior_val
      });
    }
  };

  useEffect(() => {
    if (propertiesDetailId) {
      switch (graphInfo.id) {
        case "NoiLineGrpah":
          return fetchNoiLGDataGraphData(graphInfo);
        case "NoiBarGrpah":
          return fetchNoiBGDataGraphData(graphInfo);
        case "OccLineBarGrpah":
          return fetchOccupancyGraphData(graphInfo);
        case "CollLineBarGrpah":
          return fetchCollectionGraphData(graphInfo);
        case "AvgLineBarGrpah":
          return fetchAverageRentGraphData(graphInfo);
        case "operLineBarGrpah":
          return fetchOperatingExpenseGraphData(graphInfo);
        default: {
          fetchNoiLGDataGraphData(graphInfo);
          fetchNoiBGDataGraphData(graphInfo);
          fetchOccupancyGraphData(graphInfo);
          fetchCollectionGraphData(graphInfo);
          fetchAverageRentGraphData(graphInfo);
          fetchOperatingExpenseGraphData(graphInfo);
        }
      }
    }
  }, [graphInfo, propertiesDetailId]);

  return (
    <Grid container spacing={4} style={{ marginTop: 20 }}>
      {/* Line Graph */}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomGraph
          id="NoiLineGrpah"
          setgraphInfo={setgraphInfo}
          Title="Net Operating Income"
          yAxisLabel="Net Operating Income ($)"
          yAxisticks="K"
          graphData={[
            {
              type: "line",
              label: "NOI",
              data: NoiLGData.noi,
              borderColor: "#4D69D0",
              backgroundColor: "rgba(77, 105, 208, 0.1)",
              fill: true,
              tension: 0.4,
              yAxisID: "y",
              pointBorderWidth: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "#4D69D0",
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 3,
              pointRadius: 0
            }
          ]}
          tooltipData={{
            title: (tooltipItems) => {
              const index = tooltipItems[0].dataIndex;
              return `NOI: ${formatCurrency(NoiLGData.noi[index])}`;
            },
            label: (tooltipItems) => {
              const index = tooltipItems.dataIndex;
              return [
                `Total Revenue: ${formatCurrency(NoiLGData.revenue[index])}`,
                `Total Operating Expenses: ${formatCurrency(
                  NoiLGData.operating_expenses[index]
                )}`,
                `Financing Expenses: ${formatCurrency(
                  NoiLGData.finance_expenses[index]
                )}`,
                `Non Operating Expenses: ${formatCurrency(
                  NoiLGData.non_operating_expenses[index]
                )}`
              ];
            }
          }}
        />
      </Grid>

      {/* Bar Graph */}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomGraph
          id="NoiBarGrpah"
          setgraphInfo={setgraphInfo}
          Title="Net Operating Income (Actual vs Prior)"
          yAxisLabel="Net Operating Income (Actual vs Prior)"
          yAxisticks="K"
          yearRange={[
            {
              title: "Year 1",
              color: "#DBE3FF"
            },
            {
              title: "Year 2",
              color: "#4D69D0"
            }
          ]}
          graphData={[
            {
              type: "bar",
              label: "Year 1 NOI",
              data: NoiBGData.actualNOI,
              barThickness: 18,
              borderRadius: 4,
              borderColor: "#DBE3FF",
              backgroundColor: "#DBE3FF",
              yAxisID: "y"
            },
            {
              type: "bar",
              label: "Year 2 NOI",
              data: NoiBGData.priorNOI,
              barThickness: 18,
              borderRadius: 4,
              borderColor: "#4D69D0",
              backgroundColor: "#4D69D0",
              yAxisID: "y"
            }
          ]}
          tooltipData={{
            label: (tooltipItems) => {
              const index = tooltipItems.dataIndex;
              if (tooltipItems.dataset.label === "Year 1 NOI") {
                return [
                  `Year 1 NOI: ${formatCurrency(NoiBGData.actualNOI[index])}`
                ];
              } else {
                return [
                  `Year 2 NOI: ${formatCurrency(NoiBGData.priorNOI[index])}`
                ];
              }
            }
          }}
        />
      </Grid>

      {/* Line Bar Graph*/}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomGraph
          id="OccLineBarGrpah"
          setgraphInfo={setgraphInfo}
          Title="Occupancy"
          legend
          MultiAxis
          yAxisLabel="Units Leased"
          yAxisticks=""
          y1AxisLabel="Occupancy (%)"
          y1Axisticks="%"
          graphData={[
            {
              type: "line",
              label: "Occupancy",
              data: OccuData.occupancy,
              borderColor: "#4D69D0",
              backgroundColor: "#4D69D0",
              fill: false,
              tension: 0.5,
              yAxisID: "y1",
              pointBorderWidth: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "#4D69D0",
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 3,
              pointRadius: 0
            },
            {
              type: "bar",
              label: "Units Leased",
              data: OccuData.units_leased,
              barThickness: 22,
              borderRadius: 6,
              borderColor: "#DBE3FF",
              backgroundColor: "#DBE3FF",
              yAxisID: "y"
            }
          ]}
          tooltipData={{
            label: (tooltipItems) => {
              const index = tooltipItems.dataIndex;
              if (tooltipItems.dataset.label === "Occupancy") {
                return [`Occupancy: ${OccuData.occupancy[index]}%`];
              } else {
                return [`Units Leased: ${OccuData.units_leased[index]}`];
              }
            }
          }}
        />
      </Grid>

      {/* Line Bar Graph*/}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomGraph
          id="CollLineBarGrpah"
          setgraphInfo={setgraphInfo}
          Title="Collections"
          legend
          MultiAxis
          yAxisLabel="Rent Collected ($)"
          yAxisticks="K"
          y1AxisLabel="Collections (%)"
          y1Axisticks="%"
          graphData={[
            {
              type: "line",
              label: "Collections",
              data: CollData.collections,
              borderColor: "#4D69D0",
              backgroundColor: "#4D69D0",
              fill: false,
              tension: 0.5,
              yAxisID: "y1",
              pointBorderWidth: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "#4D69D0",
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 3,
              pointRadius: 0
            },
            {
              type: "bar",
              label: "Rent Collected",
              data: CollData.rent_collected,
              barThickness: 22,
              borderRadius: 6,
              borderColor: "#DBE3FF",
              backgroundColor: "#DBE3FF",
              yAxisID: "y"
            }
          ]}
          tooltipData={{
            label: (tooltipItems) => {
              const index = tooltipItems.dataIndex;
              if (tooltipItems.dataset.label === "Collections") {
                return [`Collections: ${CollData.collections[index]}%`];
              } else {
                return [
                  `Rent Collected: ${formatCurrency(
                    CollData.rent_collected[index]
                  )}`
                ];
              }
            }
          }}
        />
      </Grid>

      {/* Line Graph */}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomGraph
          id="AvgLineBarGrpah"
          setgraphInfo={setgraphInfo}
          Title="Average Rent Per Unit"
          yAxisLabel="Average Rent Per Unit"
          yAxisticks="K"
          graphData={[
            {
              type: "line",
              label: "$",
              data: AvrageData.average_rent,
              borderColor: "#4D69D0",
              backgroundColor: "#4D69D0",
              fill: false,
              tension: 0.5,
              yAxisID: "y",
              pointBorderWidth: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "#4D69D0",
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 3,
              pointRadius: 0
            }
          ]}
          tooltipData={{
            label: (tooltipItems) => {
              const index = tooltipItems.dataIndex;
              return [`${formatCurrency(AvrageData.average_rent[index])}`];
            }
          }}
        />
      </Grid>

      {/*Double Line Graph */}
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <CustomGraph
          id="operLineBarGrpah"
          setgraphInfo={setgraphInfo}
          Title="Operating Expenses Per Unit"
          yearRange={[
            {
              title: "Year 1",
              color: "#DBE3FF"
            },
            { title: "Year 2", color: "#4D69D0" }
          ]}
          yAxisLabel="Operating Expenses Per Unit"
          yAxisticks="K"
          graphData={[
            {
              type: "line",
              label: "Year 1",
              data: OperExpData.actual_val,
              borderColor: "#DBE3FF",
              backgroundColor: "#DBE3FF",
              fill: false,
              tension: 0.5,
              yAxisID: "y",
              pointBorderWidth: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "#4D69D0",
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 3,
              pointRadius: 0
            },
            {
              type: "line",
              label: "Year 2",
              data: OperExpData.prior_val,
              borderColor: "#4D69D0",
              backgroundColor: "#4D69D0",
              fill: false,
              tension: 0.5,
              yAxisID: "y",
              pointBorderWidth: 0,
              pointHoverRadius: 8,
              pointHoverBackgroundColor: "#4D69D0",
              pointHoverBorderColor: "#fff",
              pointHoverBorderWidth: 3,
              pointRadius: 0
            }
          ]}
          tooltipData={{
            label: (tooltipItems) => {
              const index = tooltipItems.dataIndex;
              if (tooltipItems.dataset.label === "Year 1") {
                return [
                  `Year 1: ${formatCurrency(OperExpData.actual_val[index])}`
                ];
              } else {
                return [
                  `Year 2: ${formatCurrency(OperExpData.prior_val[index])}`
                ];
              }
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PropertyGrpah;
