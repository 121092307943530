import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Grid
} from "@material-ui/core";
import Illustrations from "components/illustration/Illustrations";

import { truncate } from "helpers/Common";
import React, { useState } from "react";
import style from "./propertiesProfile.module.scss";

import loanEmptyState from "assets/emptyState/noLoans.svg";
import refinanceEmptyState from "assets/emptyState/noLoans.svg";

import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import NewDropDown from "components/NewDropDown";

function CommonPropertyDetalTable({ tableData, heading, type }) {
  const [expandIndex, setExpandIndex] = useState(-1);

  let setLoanHead = 0;
  tableData?.map((value) => {
    if (Object.keys(value)?.length > 0) {
      setLoanHead = 1;
    }
  });

  let illustration = {
    loan: [loanEmptyState, "No loan found"],
    refinance: [refinanceEmptyState, "No refinance found"],
    noi: [refinanceEmptyState, "No Net Operating Income found"],
    occupancy: [refinanceEmptyState, "No Occupancy found"]
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  return (
    <>
      {tableData?.length != 0 ? (
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {setLoanHead == 1 ? (
              <TableHead>
                <TableRow>
                  {heading.map((value, index) => {
                    return (
                      <TableCell key={index} className={style.noiTableHeader}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : (
              <Illustrations
                src={illustration[type][0]}
                heading={illustration[type][1]}
                // subHeading="Add Investment Data To View"
              />
            )}
            <TableBody>
              {tableData?.map((value, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {heading.map((item, indx) => {
                      return (
                        <TableCell
                          style={indx === 0 ? { cursor: "pointer" } : null}
                          align="left"
                          component="th"
                          scope="row">
                          <Grid
                            className={[
                              indx === 0 && style.firstCell,

                              item != "File" && indx == 8
                                ? value[indx] != "-" &&
                                  (value[indx] === "Active"
                                    ? style.activeCell
                                    : style.inActiveCell)
                                : null
                            ]}>
                            {item === "File" ? (
                              <DocumentsUpload
                                editable={false}
                                listData={formatDocsData(value[indx])}
                              />
                            ) : item === "Shared with" ? (
                              <span
                                style={{
                                  color: "#BD54B9",
                                  backgroundColor: "rgba(189, 84, 185, 0.05)"
                                }}>
                                <NewDropDown
                                  index={indx}
                                  item={
                                    value[indx]?.map((item) => {
                                      return {
                                        id: null,
                                        name: item?.base_id
                                      };
                                    }) || []
                                  }
                                  expandIndex={expandIndex}
                                  setExpandIndex={setExpandIndex}
                                />
                              </span>
                            ) : (
                              truncate(value[indx], 20)
                            )}
                          </Grid>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff",
            width: "100vw"
          }}>
          <Illustrations
            src={illustration[type][0]}
            heading={illustration[type][1]}
            // subHeading="Add Investment Data To View"
          />
        </Grid>
      )}
    </>
  );
}

export default CommonPropertyDetalTable;

// value[indx]?.length > 0 ? (
//   <span
//     style={{
//       color: "#BD54B9",
//       backgroundColor: "rgba(189, 84, 185, 0.05)"
//     }}>
//     <NewDropDown
//       index={indx}
//       item={
//         value[indx]?.map((item) => {
//           return {
//             id: null,
//             name: item?.base_id
//           };
//         }) || []
//       }
//       // handleCellItemClick={(name, id) => handleCellClick(name, id)}
//       // type="sponsor_id"
//       expandIndex={expandIndex}
//       setExpandIndex={setExpandIndex}
//     />
//   </span>
// ) : (
