import Toast from "components/toast";

export const formatDate = (dates) => {
  const date = new Date(dates);
  let month = date.getMonth() + 1;
  let exactDate = date.getDate();
  if (exactDate < 10) {
    exactDate = "0" + exactDate;
  }
  if (month < 10) {
    month = "0" + month;
  }
  return `${month}-${exactDate}-${date.getFullYear()}`;
};

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const verifyDocUploadType = (e) => {
  let ext = e.target.value.split(".").pop().toLowerCase();
  switch (ext) {
    case "jpg":
      return true;
    case "jpeg":
      return true;
    case "png":
      return true;
    case "pdf":
      return true;
    case "csv":
      return true;
    case "docx":
      return true;
    case "txt":
      return true;
    case "xlsx":
      return true;
    default:
      Toast.showErrorToast(
        "Only JPG, JPEG, PNG, PDF, CSV,docx,TEXT And EXCEL File Type Are Allowed. Please Upload Valid File Type"
      );
      return false;
  }
};

export const allowOnlyXlsFileType = (e) => {
  let ext = e.target.value.match(/\.(.+)$/)[1];
  switch (ext) {
    case "xlsx":
      return true;
    default:
      Toast.showErrorToast(
        "Only EXCEL File Type Are Allowed. Please Upload Valid File Type"
      );
      return false;
  }
};

export const truncate = (input = "", uptoCharLength) =>
  input?.length > uptoCharLength
    ? `${input.substring(0, uptoCharLength)}...`
    : input;

export function FilterStatus(checked) {
  const data = checked.filter((obj) => obj.value.length !== 0);
  if (data.length) {
    return true;
  } else {
    return false;
  }
}

export function CovertToMb(kb) {
  if (kb > "1024") {
    return `${parseFloat(kb / 1024).toFixed(2)}MB`;
  } else {
    return `${kb}KB`;
  }
}

export function filterArray(arr1, arr2) {
  const filtered = arr1.filter((el) => {
    return arr2.indexOf(el) === -1;
  });
  return filtered;
}
