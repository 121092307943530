import React, { useEffect, useRef, useState } from "react";
import searchIcon from "../../assets/icons/search.svg";
import styles1 from "../../pages/private/investments/investments.module.css";
import CrossIcon from "../../assets/icons/CrossIcon.svg";

const SearchInput = ({ setQuery, isExpandable = false, searchQuery }) => {
  const [search, setSearch] = useState(searchQuery);
  const [focus, setFocus] = useState(false);
  const inputRef = useRef(null);

  const onSearchTextChange = async (event) => {
    setSearch(event.target.value);
    const timeOutId = setTimeout(() => setQuery(event.target.value), 800);
    return () => clearTimeout(timeOutId);
  };

  useEffect(() => {
    if (focus) {
      inputRef.current.focus();
    }
  }, [focus]);

  const handleSearchClear = () => {
    setSearch("");
    setQuery("");
  };

  return (
    <div
      className={styles1.searchComponent}
      style={
        !isExpandable
          ? {
              width: "100%",
              maxWidth: "368px"
            }
          : { minWidth: "50px" }
      }
      onBlur={() => setFocus(false)}
      onClick={() => setFocus(true)}>
      <img src={searchIcon} alt="alt" />
      {isExpandable && !focus ? null : (
        <input
          className={styles1.searchInput}
          onChange={(e) => onSearchTextChange(e)}
          ref={inputRef}
          value={search}
          placeholder="Search"></input>
      )}
      {search !== "" ? (
        <img
          onClick={() => handleSearchClear()}
          className={styles1.crossIcon}
          src={CrossIcon}
        />
      ) : null}
    </div>
  );
};

export default SearchInput;
