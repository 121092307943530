import {
  Dialog,
  DialogContent,
  makeStyles,
  FormControl,
  CircularProgress,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid
} from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import FileUploadIcon from "assets/icons/FileUpload.svg";
import CrossIcon from "assets/icons/CrossBlack.svg";
import CloseIcon from "assets/icons/CloseIcon.svg";
import CustomSelect from "components/StyledComponents/Select";
import styles from "../../myProfile/profile.module.scss";
import { InvestmentListService } from "network/investment_ListService";
import Toast from "components/toast";
import { useFormik } from "formik";
import UploadIcon from "assets/icons/upload.svg";
import { CommonService } from "network/commonService";
import {
  validationSchemaManageDataAddK1,
  validationSchemaManageDataAddWithPropertyK1
} from "helpers/validationSchema";
import { ManageDataService } from "network/manageDataService";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import { AddInvestmentPreviewService } from "network/addInvestmentPreviewService";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import { verifyDocUploadType } from "helpers/Common";
import CustomSearchSelect from "components/StyledComponents/CustomSearchSelect";
import Loader from "components/Loader/AppLoader";

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: "rgba(235, 239, 255, 0.6)",
    backdropFilter: "blur(26)"
  },
  dialogContent: {
    width: 458,
    padding: 50,
    boxSizing: "border-box"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  header: {
    margin: "30px 0 40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    fontFamily: "Inter Bold",
    fontSize: 28
  },
  closeIcon: {
    cursor: "pointer"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    marginTop: 10
  },
  btnContainer: {
    marginTop: 40,
    display: "flex"
  },
  deleteBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      cursor: "pointer"
    }
  },
  uploadDocs: {
    backgroundColor: "#E5E9ED",
    padding: 10,
    marginTop: 20,
    width: 372
  },
  docName: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    marginLeft: 20,
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: 4
  },
  error: {
    color: "#E13838",
    fontSize: "0.75rem",
    marginTop: 3
  }
}));

const AddDialog = ({ handleClose }) => {
  const classes = useStyles();
  const [investorList, setInvestorList] = useState([]);
  const wrapperRef = useRef(null);
  const [docsLoading, setDocsLoading] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [type, setType] = useState("investment");
  const [selectedItem, setSelectedItem] = useState({});
  const [initialValue, setInitialValue] = useState({
    type: "",
    investment: "",
    investor: "",
    year: "",
    documents: []
  });
  const [invDocCategories, setInvDocCategories] = useState();
  const [propertyList, setPropertyList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema:
      type === "property"
        ? validationSchemaManageDataAddWithPropertyK1
        : validationSchemaManageDataAddK1,
    onSubmit: async (values) => {
      setLoading(true);
      let payload = {
        type: type,
        year: values.year,
        investor: values.investor,
        investment: values.investment,
        documents: values.documents?.map((doc) => ({
          document_media_id: doc.id,
          category_id: doc.type
        }))
      };
      if (type === "property") {
        payload = { ...payload, property_id: values.property };
      }
      let res = await ManageDataService.addManageData(payload);
      if (res.success) {
        Toast.showSuccessToast("K1 Added Successfully");
        handleClose();
        history.push("/u/manage_data");
      } else {
        Toast.showErrorToast(res.error.message[0]);
      }
      setLoading(false);
    }
  });

  const onFileDrop = (e) => {
    if (!verifyDocUploadType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      resp.data.type = invDocCategories;
      resp.data.link = resp.data.file;
      const updatedFileList = [...imageList, resp.data];
      formik.setFieldValue("documents", updatedFileList);
      setImageList(updatedFileList);
      Toast.showSuccessToast("Documents Upload SuccessFully");
      setDocsLoading(false);
    } else {
      Toast.showErrorToast("Failed To Uploaded Documents");
    }
  };

  useEffect(async () => {
    const invCategories = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENT"
    });
    if (invCategories.success) {
      const catId = invCategories.data[0].doccategory.filter(
        (item) => item.name === "K-1"
      );
      if (catId.length) {
        setInvDocCategories(catId[0]?.id);
      }
    } else {
      Toast.showErrorToast("Failed To Load Document Categories");
    }
  }, []);
  const deleteDoc = async (id) => {
    const resp = await CommonService.deleteMedia(id);
    const updateDocList = formik.values.documents.filter(
      (item) => item.id !== id
    );

    if (resp.success) {
      formik.setFieldValue("documents", updateDocList);
      Toast.showSuccessToast("Documents Deleted Successfully");
      setImageList((prev) => {
        return prev.filter((item) => item.id != id);
      });
    } else {
      Toast.showErrorToast("Failed to Delete Document");
    }
  };

  const getInvestorList = async (id) => {
    const res1 = await AddInvestmentPreviewService.getInvestmentDetails([
      id + "?fields=investor"
    ]);

    if (res1.success) {
      setInvestorList(
        res1?.data?.investor?.map((data) => ({
          label: `${data?.investor?.first_name} ${data?.investor?.last_name}`,
          value: data?.investor?.id
        }))
      );
    } else {
      Toast.showErrorToast("Failed To Load Investor Names");
    }
  };

  const getPropertyListByInvestment = async (investmentId) => {
    const res = await ManageDataService.getPropertiesListByInvestment({
      investment_id: investmentId
    });
    if (res.success) {
      const temp = res.data;
      setPropertyList(
        temp?.map((data) => ({
          label: data.name,
          value: data.id
        }))
      );
    } else {
      Toast.showErrorToast("Failed To Load Property Names");
    }
  };

  const UpdateInvestmentList = (data) => {
    formik.setFieldValue("investment", data.id);
    getInvestorList(data.id);
    setSelectedItem(data);
    if (type === "property") {
      getPropertyListByInvestment(data.id);
    }
  };

  useEffect(() => {
    if (type === "property") {
      setInitialValue({ ...initialValue, property: "" });
    }
  }, [type]);

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestmentListService([
      `?status=Active&fields=id,name,start_date,investor${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Dialog open={true} onClose={handleClose} className={classes.dialog}>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.container}>
              <div className={classes.header}>
                <div className={classes.title}>Add K1</div>
                <img
                  src={CloseIcon}
                  className={classes.closeIcon}
                  alt="close"
                  onClick={handleClose}
                />
              </div>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  value={type}
                  name="controlled-radio-buttons-group"
                  onChange={(e) => {
                    setType(e.target.value);
                  }}>
                  <FormControlLabel
                    value="investment"
                    control={<Radio color="secondary" />}
                    label="By Investment"
                  />
                  <FormControlLabel
                    value="property"
                    control={<Radio color="secondary" />}
                    label="By Property"
                  />
                </RadioGroup>
              </FormControl>
              <div className={classes.label}>Select Investment</div>
              <CustomSearchSelect
                getData={getInvestmentName}
                onChange={UpdateInvestmentList}
                selectedItem={selectedItem}
              />
              {type === "property" && (
                <>
                  <div className={classes.label}>Select Property</div>
                  <CustomSelect
                    placeholder={"Select Property"}
                    items={propertyList}
                    value={formik.values.property}
                    onChange={(e) =>
                      formik.setFieldValue("property", e.target.value)
                    }
                    fullWidth={true}
                    error={formik.errors.property}
                    touched={formik.touched.property}
                  />
                </>
              )}
              <div className={classes.label}>Select Investor</div>
              <CustomSelect
                placeholder={"Select Investor"}
                items={investorList}
                value={formik.values.investor}
                onChange={(e) =>
                  formik.setFieldValue("investor", e.target.value)
                }
                fullWidth={true}
                error={formik.errors.investor}
                touched={formik.touched.investor}
              />
              <div className={classes.label}>Select Year</div>
              <SelectWithInfiniteScroll
                id="infiniteYear"
                fullWidth={true}
                minValue={2000}
                onChange={(value) => formik.setFieldValue("year", value)}
                error={formik.errors.year}
                touched={formik.touched.year}
              />
              <div
                style={{ width: "100%", marginTop: 10 }}
                ref={wrapperRef}
                className={styles.drop_file_input}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}>
                <div>
                  {docsLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <img src={UploadIcon} className={styles.uploadImage} />
                      <p className={styles.droptext}>
                        Drag and Drop Supporting Documents{" "}
                      </p>
                    </>
                  )}
                </div>

                <input type="file" value="" onChange={onFileDrop} />
              </div>
              {formik.errors.documents && formik.touched.documents && (
                <div className={classes.error}>{formik.errors.documents}</div>
              )}
              <Grid container>
                {imageList.length > 0 ? (
                  <>
                    {imageList.map((item) => (
                      <Grid
                        key={item.name}
                        container
                        className={classes.uploadDocs}
                        direction="row">
                        <Grid item xs={10} className={classes.imgContainer}>
                          {item.name.endsWith("png") ||
                          item.name.endsWith("jpeg") ||
                          item.name.endsWith("jpg") ? (
                            <img
                              src={item.link}
                              style={{
                                height: 54,
                                width: 54,
                                borderRadius: 10
                              }}
                            />
                          ) : (
                            <img src={FileUploadIcon} />
                          )}
                          <div className={classes.docName}>{item.name}</div>
                        </Grid>
                        <Grid item xs={2} className={classes.deleteBtn}>
                          <img
                            onClick={() => deleteDoc(item.id)}
                            src={CrossIcon}
                            alt="Delete"
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </>
                ) : null}
              </Grid>

              <div className={classes.btnContainer}>
                <button
                  className={styles.cancelbtn}
                  style={{ marginRight: 5, width: "100%" }}
                  type="button"
                  onClick={handleClose}>
                  Cancel
                </button>
                <button
                  className={styles.updatebtn}
                  style={{ marginLeft: 5, width: "100%" }}
                  onClick={() => formik.handleSubmit()}>
                  Add
                </button>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default AddDialog;
