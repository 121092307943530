import React from "react";
import { Grid, IconButton, Box } from "@material-ui/core";
import DocumentsUpload from "../../../../../properties/component/DocumentsUpload/documentsUpload";
import Toast from "components/toast";
import { PropertiesService } from "network/propertiesService";
import CustomSelect from "components/StyledComponents/Select";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import RedDelete from "../../../../../../../assets/icons/NewCardSvg/RedDelete.svg";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";

const PropertyNOI = ({
  id,
  parentIndex,
  noi,
  arrayHelper,
  formik,
  noiCategories,
  cadenceChoices,
  quarterChoices,
  ClickonPropertyEdit
}) => {
  const handleDelete = async () => {
    if (formik.values.properties[parentIndex].noi[id].id) {
      const res = await PropertiesService.deletePropertyNoi(
        formik.values.properties[parentIndex].noi[id].id
      );
      if (res.success) {
        formik.values;
      }
      if (!res.success) {
        return Toast.showErrorToast(res.error.message[0]);
      }
    }

    arrayHelper.remove(id);
  };

  const setDocData = (data) => {
    let allPropertySets = [];
    allPropertySets = data?.map((doc) => ({
      document_media_id: doc.document_media_id ? doc.document_media_id : doc.id,
      category_id: doc.category_id ? doc.category_id : doc.type
    }));
    formik.setFieldValue(
      `properties[${parentIndex}]noi[${id}].documents`,
      allPropertySets
    );
  };

  return (
    <Grid container spacing={2}>
      <Box
        display="flex no-wrap"
        flexDirection={["row"]}
        justifyContent="space-between"
        alignItems="center"
        style={{ display: "inline-flex", gap: "16px", width: "100%" }}>
        <Box>
          <CustomSelect
            disabled={!ClickonPropertyEdit}
            style={{ backgroundColor: "white", width: "120px" }}
            placeholder="Cadence"
            items={cadenceChoices}
            value={formik.values.properties[parentIndex].noi[id].noi_cadence}
            onChange={(e) =>
              formik.setFieldValue(
                `properties[${parentIndex}]noi[${id}].noi_cadence`,
                e.target.value
              )
            }
          />
        </Box>
        <Box>
          <SelectWithInfiniteScroll
            disabled={!ClickonPropertyEdit}
            style={{ backgroundColor: "white", width: "80px" }}
            minValue={
              parseInt(formik.values.properties[parentIndex].noi[id].year) <
              2000
                ? parseInt(formik.values.properties[parentIndex].noi[id].year)
                : 2000
            }
            selectedItem={
              formik.values.properties[parentIndex].noi[id].year ?? ""
            }
            onChange={(value) =>
              formik.setFieldValue(
                `properties[${parentIndex}]noi[${id}].year`,
                value
              )
            }
          />
        </Box>
        {noi?.noi_cadence === "quarterly" && (
          <Box>
            <CustomSelect
              disabled={!ClickonPropertyEdit}
              style={{ backgroundColor: "white", width: "80px" }}
              placeholder="Term"
              items={quarterChoices}
              value={
                formik.values.properties[parentIndex].noi[id].noi_quarter ?? ""
              }
              onChange={(e) =>
                formik.setFieldValue(
                  `properties[${parentIndex}]noi[${id}].noi_quarter`,
                  e.target.value
                )
              }
            />
          </Box>
        )}
        <Box item xs={3}>
          <AppTextFieldWhite
            disabled={!ClickonPropertyEdit}
            style={{ backgroundColor: "white", width: "150px" }}
            id={`properties[${parentIndex}]noi[${id}].property_noi`}
            placeholder="NOI"
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.properties[parentIndex].noi[id].property_noi}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            onChange={formik.handleChange(
              `properties[${parentIndex}]noi[${id}].property_noi`
            )}
            inputProps={{ maxLength: 12 }}
          />
        </Box>

        <Box>
          {noiCategories &&
            noiCategories
              .filter((el) => el.name !== "Misc.")
              .map((category) => (
                <DocumentsUpload
                  key={category.id}
                  type={category.id}
                  title={category.name}
                  listData={
                    formik.values.properties[parentIndex].noi[id].documents
                  }
                  setListData={(list) => setDocData(list)}
                />
              ))}
        </Box>
        <Box alignSelf={"center"} marginLeft="auto">
          <IconButton onClick={handleDelete}>
            <img src={RedDelete} alt="del" />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default PropertyNOI;
