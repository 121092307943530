import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

// Components Imports
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import DistributionCard from "components/Cards/DistributionCard";
import Table from "./components/table/Table";
import Toast from "components/toast";

// Card Icons Imports
import TotalInvestorIcon from "../../../assets/icons/NewCardSvg/totalInvestors.svg";
import TotalInvestementIcon from "../../../assets/icons/NewCardSvg/totalInvestments.svg";
import TotalDistributionIcon from "../../../assets/icons/NewCardSvg/pinkTree.svg";
import TotalDistributionReceivedIcon from "../../../assets/icons/NewCardSvg/totalOutstandingLoan.svg";

import { numFormatter } from "helpers";
import { DistributionService } from "network/distributionService";
import {
  getScreenPermissions,
  getPageWithPermission
} from "helpers/permissionChecker";
import styles1 from "../properties/properties.module.scss";

const Distributions = () => {
  const history = useHistory();
  const [stats, setStats] = useState(null);
  const [permissions, setPermissions] = useState();
  const { userPermissions, user } = useSelector((store) => store.app);

  const processIncommingData = (data) => {
    return data !== null ? data : <Skeleton width={150} />;
  };

  const addDistribution = () => {
    history.push("/u/distributions/addDistribution");
  };

  const fetchStatsData = async () => {
    const response = await DistributionService.getStats();
    if (response.success) {
      setStats(response.data);
    } else {
      Toast.showErrorToast("Failed To Load Distributions Data");
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "DISTRIBUTIONS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(() => {
    checkPermissions();
    fetchStatsData();
  }, []);

  const cardsData = [
    {
      title: "Total Investors",
      link: "/u/investors",
      count: processIncommingData(
        stats?.total_investor ? stats?.total_investor : null
      ),
      icon: TotalInvestorIcon
    },
    {
      title: "Total Investments",
      link: "/u/investments",
      count: processIncommingData(
        stats?.total_investment ? stats?.total_investment : null
      ),
      icon: TotalInvestementIcon
    },
    {
      title: "Total Distributions Count",
      count: processIncommingData(
        stats ? stats.total_distribution_count : null
      ),
      icon: TotalDistributionIcon
    },
    {
      title: "Total Distributions Received",
      count: stats
        ? processIncommingData(
            `$${numFormatter(stats.total_distribution_received)}`
          )
        : processIncommingData(null),
      icon: TotalDistributionReceivedIcon
    }
  ];

  const tooltipData = "Mayur";
  return (
    <>
      <HeaderComponent
        heading="Distributions"
        description="List Of All The Distributions On Simplee"
        addInvestorBtn={false}
        ExtraButtons={
          permissions?.is_create ? (
            <div className={styles1.addPropertyButtonContainer}>
              <p
                className={styles1.addPropertyButtonText}
                onClick={addDistribution}>
                + Add Distribution
              </p>
            </div>
          ) : null
        }
      />
      <Grid container style={{ marginTop: 40 }} direction="row">
        {cardsData.map((item, index) => {
          return (
            <DistributionCard
              key={index}
              title={item.title}
              count={item.count}
              icon={item.icon}
              link={item.link}
              tooltipData={tooltipData}
              last={cardsData.length === index + 1}
            />
          );
        })}
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Table permissions={permissions} />
        </Grid>
      </Grid>
    </>
  );
};

export default Distributions;
