import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  selected: {
    border: "2px solid #445EBE",
    borderRadius: 8,
    color: "#445EBE"
  },
  notSelected: {
    border: "1px solid #BBBBBB",
    borderRadius: 8,
    color: "#BBBBBB"
  },
  container: {
    fontFamily: "Inter SemiBold",
    fontSize: 15,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 35,
    cursor: "pointer"
  },
  main: {
    margin: "0px 0px 12px"
  }
}));

const CadenceSelect = ({
  selectedTimeFrame,
  setSelectedTimeFrame,
  list,
  id,
  formik
}) => {
  const classes = useStyles();

  const CadenceButton = ({ item, md, sm, xs, index }) => {
    const setSelected = () => {
      if (selectedTimeFrame[index]) {
        let investors = formik?.values?.distributionSet[id]?.investors;
        investors = investors.map((investor) => {
          investor.value[item.value] = "0";
          return investor;
        });
        formik.setFieldValue(`distributionSet[${id}].investors`, investors);
        let amountSum = formik?.values?.distributionSet[id]?.amountSum;
        amountSum -=
          formik?.values?.distributionSet[id]?.investorSum[item.value];
        formik.setFieldValue(`distributionSet[${id}].amountSum`, amountSum);
        formik.setFieldValue(
          `distributionSet[${id}].investorSum[${item.value}]`,
          "0"
        );
      }
      setSelectedTimeFrame((prev) => {
        let newValue = [...prev];
        if (newValue[index]) {
          newValue[index] = false;
        } else {
          newValue[index] = item;
        }
        return [...newValue];
      });
    };

    return (
      <Grid item {...{ md, sm, xs }}>
        <div
          onClick={setSelected}
          className={`${classes.container} ${
            selectedTimeFrame[index] ? classes.selected : classes.notSelected
          }`}>
          {item.label}
        </div>
      </Grid>
    );
  };

  return (
    <Grid container spacing={2} className={classes.main}>
      {list.map((item, index) => (
        <CadenceButton
          key={item.value}
          item={item}
          index={index}
          md={Math.floor(12 / list.length)}
          sm={
            list.length > 6
              ? Math.floor((12 / list.length) * 2)
              : Math.floor(12 / list.length)
          }
          xs={4}
        />
      ))}
    </Grid>
  );
};

export default CadenceSelect;
