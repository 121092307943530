import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress
} from "@material-ui/core";
import HeaderComponent from "../../../../components/HeaderComponent/HeaderComponent";
import AppTextFieldWhite from "../../../../components/StyledComponents/AppTextFieldWhite";
import { validationSchemaAddInvestor } from "../../../../helpers/validationSchema";
import styles from "./addInvestors.module.scss";
import AddBtn from "../../../../components/Buttons/addBtn";
import CancelBtn from "../../../../components/Buttons/cancelBtn";
import Toast from "../../../../components/toast";
import { API, NetworkManager } from "../../../../network/core/index";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/Loader/AppLoader";
// import UploadIcon from "../../../../assets/icons/upload.svg";
import FileUploaded from "../../../../assets/icons/FileUpload.svg";
import CancelModal from "../../../../components/Modals/CancelModal";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useSelector } from "react-redux";
import { verifyDocUploadType } from "helpers/Common";

const useHeaderStyle = makeStyles(() => ({
  mainContainer: {
    backgroundColor: "#FAFAFA",
    // paddingTop: 30,
    paddingBottom: 30,
    borderRadius: 6,
    // marginTop: 50,
    paddingLeft: 40
  },
  mainContainer1: {
    backgroundColor: "#FAFAFA",
    paddingTop: 30,
    paddingBottom: 30,
    borderRadius: 6,
    marginTop: 20
  },
  uploadDocsGrid: {
    marginLeft: 40
  },
  addInvestorHeading: {
    marginBottom: 20,
    marginTop: 50
  },
  uploadDocs: {
    backgroundColor: "#fff",
    marginRight: 40,
    marginLeft: 40,
    marginTop: 20,
    marginBottom: 20,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    borderRadius: 8
  },
  imgContainer: {
    display: "flex",
    flexDirection: "row"
  },
  btnSmall: {
    "@media (max-width: 800px)": {
      width: 205,
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      fontSize: 18,
      marginBottom: 20
    },
    width: 233,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    fontSize: 18,
    marginBottom: 20
  },
  btnCtr: {
    display: "flex",
    justifyContent: "space-evenly"
    // margin: "30px 0",
    // flexWrap: "wrap",
  },
  btnBig: {
    "@media (max-width: 800px)": {
      width: 210,
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      fontSize: 18,
      marginBottom: 20
    },
    width: 324,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    fontSize: 18,
    marginBottom: 20
  }
}));

const AddInvestor = () => {
  const classes = useHeaderStyle();
  const [loading, setLoading] = useState(false);
  const [docsLoading, setDocsLoading] = useState(false);
  const history = useHistory();
  const [emailSend, setEmailSend] = useState(1);
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const [confirmCancel, setConfirmCancel] = useState(false);
  const { userPermissions, user } = useSelector((store) => store.app);

  const onFileDrop = (e) => {
    if (!verifyDocUploadType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const instance = new NetworkManager(API.COMMON.IMAGE_UPLOAD, data);
    const resp = await instance.httpRequest(true, true);
    if (resp.success) {
      const updatedFileList = [...imageList, resp.data];
      setImageList(updatedFileList);
      setDocsLoading(false);
      Toast.showSuccessToast("Document Upload SuccessFully");
    } else {
      Toast.showErrorToast("Document Not Uploaded");
    }
  };

  const deleteDoc = (id) => {
    setImageList((prev) => {
      return prev.filter((item) => item.id != id);
    });
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      last_name: ""
    },
    validationSchema: validationSchemaAddInvestor,
    onSubmit: async (values) => {
      const payload = {
        email: values.email,
        first_name: values.first_name,
        last_name: values.last_name,
        is_investor: 1,
        documents: imageList
      };

      setLoading(true);
      if (emailSend === 1) {
        // Invite Send Api Call
        try {
          const instance = new NetworkManager(
            API.INVESTOR.ADD_INVESTOR_EMAIL,
            payload
          );
          const resp = await instance.httpRequest();
          if (resp.success) {
            Toast.showSuccessToast("Successfully added Investor");
            history.push("/u/investors");
          } else {
            Toast.showErrorToast(resp.error.message[0]);
          }
        } catch (err) {
          Toast.showErrorToast("Error Here");
        } finally {
          setLoading(false);
        }
      } else {
        // Skip Invite Api call
        try {
          const instance = new NetworkManager(
            API.INVESTOR.CREATE_INVESTOR,
            payload
          );
          const resp = await instance.httpRequest();
          if (resp.success) {
            Toast.showSuccessToast("Investor Created Successfully");
            history.push("/u/investors");
          } else {
            Toast.showErrorToast(resp.error.message[0]);
          }
        } catch (err) {
          Toast.showErrorToast("Error Here");
        } finally {
          setLoading(false);
        }
      }
    }
  });

  const handleAddInvestor = () => {
    formik.handleSubmit();
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "INVESTORS",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_create) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(checkPermissions, []);
  return (
    <>
      <HeaderComponent
        heading="Add Investor"
        description="Add New Investor’s Credentials And Invite Them To Simplee."
        backBtn={true}
      />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Typography variant="h5" className={classes.addInvestorHeading}>
            Add Investor Information
          </Typography>
          <Grid container xs={12} className={classes.mainContainer}>
            <Grid
              container
              direction="row"
              style={{ marginTop: 10 }}
              spacing={2}>
              <Grid item style={{ display: "flex", flexDirection: "column" }}>
                <label className={styles.lebelsText}>First Name</label>
                <AppTextFieldWhite
                  id="first_name"
                  placeholder="Enter First Name"
                  variant="standard"
                  value={formik.values.first_name}
                  style={{ width: 250 }}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.first_name &&
                    Boolean(formik.errors.first_name)
                  }
                  helperText={
                    formik.touched.first_name && formik.errors.first_name
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                <label className={styles.lebelsText}>Last Name</label>
                <AppTextFieldWhite
                  id="last_name"
                  placeholder="Enter Last Name"
                  variant="standard"
                  value={formik.values.last_name}
                  style={{ width: 250 }}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={
                    formik.touched.last_name && formik.errors.last_name
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "column"
                }}>
                <label className={styles.lebelsText}>Email ID</label>
                <AppTextFieldWhite
                  style={{ width: 250 }}
                  id="email"
                  placeholder="Enter Email Id"
                  variant="standard"
                  value={formik.values.email}
                  // style={{ width: 540 }}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            </Grid>

            <Grid item xs={6}></Grid>

            {/* <Grid
              item
              style={{
                marginLeft: 40,
                display: "flex",
                flexDirection: "column"
              }}>
              <Typography variant="h5" className={classes.addInvestorHeading}>
                Add Investor Information
              </Typography>
              <label className={styles.lebelsText}>Email ID</label>
              <AppTextFieldWhite
                id="email"
                placeholder="Enter investor email id"
                variant="standard"
                value={formik.values.email}
                style={{ width: 400 }}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid> */}
            {/* <Grid container direction="row">
              <Grid
                xs={6}
                item
                direction="column"
                style={{
                  marginLeft: 40,
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 40
                }}
                >
                <label className={styles.lebelsText}>Investor Name</label>
                <AppTextFieldWhite
                  id="name"
                  placeholder="Enter investor name"
                  variant="standard"
                  value={formik.values.name}
                  style={{ width: 400 }}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid
                xs={6}
                item
                style={{
                  marginLeft: 40,
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 40
                }}>
                <label className={styles.lebelsText}>Investor Name</label>
                <AppTextFieldWhite
                  id="name"
                  placeholder="Enter investor name"
                  variant="standard"
                  value={formik.values.name}
                  style={{ width: 400 }}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
            </Grid> */}
          </Grid>
          <Grid container className={classes.mainContainer1} direction="column">
            <Grid item className={classes.uploadDocsGrid} xs={12} md={4}>
              <Typography variant="h5"> Upload Documents </Typography>
              <div
                ref={wrapperRef}
                className={styles.drop_file_input}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}>
                <div>
                  {docsLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {/* <img src={UploadIcon} className={styles.uploadImage} /> */}
                      <p className={styles.droptext}>
                        Drag and drop files here or{" "}
                        <span style={{ color: "#5775E5", fontWeight: 600 }}>
                          Browse
                        </span>
                      </p>
                    </>
                  )}
                </div>

                <input type="file" value="" onChange={onFileDrop} />
              </div>
            </Grid>
            <Grid item>
              {/* {docsLoading ? <Loader/> : ( <>  */}
              {imageList.length > 0 ? (
                <>
                  {imageList.map((item) => (
                    <Grid
                      key={item.file}
                      container
                      className={classes.uploadDocs}
                      direction="row">
                      <Grid item xs={10} className={classes.imgContainer}>
                        {/* {docsLoading ? (<CircularProgress/>) : (<> */}
                        {item.image_type.toLowerCase() === "png" ||
                        item.image_type.toLowerCase() === "jpeg" ? (
                          <img
                            src={item.file}
                            style={{ height: 54, width: 54, borderRadius: 10 }}
                          />
                        ) : (
                          <img src={FileUploaded} />
                        )}
                        {/* </>)} */}
                        <Typography
                          variant="h5"
                          style={{ marginTop: 20, marginLeft: 20 }}>
                          {item.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={2}>
                        <div
                          className={styles.deleteButton}
                          onClick={() => deleteDoc(item.id)}>
                          Delete
                        </div>
                      </Grid>
                    </Grid>
                  ))}
                </>
              ) : null}
              {/* </>)} */}
            </Grid>
          </Grid>

          <Grid xs={12} container className={classes.btnCtr}>
            <Grid item xs={4} md={6}>
              <AddBtn
                text="Skip Invite & Create"
                extraClasses={classes.btnBig}
                onClick={() => {
                  handleAddInvestor();
                  setEmailSend(0);
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={8}
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-end"
              }}>
              <Grid item>
                <CancelBtn
                  // extraClasses={classes.btnSmall}
                  onClick={() => setConfirmCancel(true)}
                />
              </Grid>
              <Grid item>
                <AddBtn
                  text="Send Invite"
                  // extraClasses={classes.btnSmall}
                  onClick={() => {
                    handleAddInvestor();
                    setEmailSend(1);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <CancelModal
        open={confirmCancel}
        title=""
        description={`Are you sure you want to cancel?`}
        handleClose={() => setConfirmCancel(false)}
        handleSubmit={() => history.goBack()}
      />
    </>
  );
};

export default AddInvestor;
