import { Box, Button, Grid, Modal } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import style from "./table/SendEmail.module.scss";
import SendIcon from "../../../../assets/icons/emailSending.svg";
import CrossIcon from "../../../../assets/icons/Close.svg";
// import AttachmentIcon from "../../../../assets/icons/attachment.svg";
import { Formik } from "formik";
import * as Yup from "yup";
import TextCounter from "components/TextCounter";
import ContactDescriptionBox from "./ContactDescriptionBox";
import MessegeDescriptionBox from "./MessegeDescriptionBox";
import ContactSendEmailDocument from "pages/private/properties/component/DocumentsUpload/components/OtherTypeDocUpload/components/ContactSendEmailDocument";
import { AppDispatcher } from "../../../../redux/index";
import { useSelector } from "react-redux";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import Toast from "components/toast";
import { ContactsService } from "network/contactsService";
// import DocumentsUpload from "pages/private/properties/component/DocumentsUpload/documentsUpload";

function SendContactEmail({ open = true }) {
  const { ContactEmailId } = useSelector((store) => store.app);
  const [k1Categories, setK1Categories] = useState(null);
  // const [resume, setResume] = useState(null);
  const { adminEmailId } = useSelector((store) => store.app);

  const K1doccCategory = async () => {
    const invCategories = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENT"
    });

    if (invCategories.success) {
      const catId = invCategories.data[0].doccategory.filter(
        (item) => item.name === "K-1"
      );

      if (catId.length) {
        setK1Categories(catId);
      }
    } else {
      Toast.showErrorToast("Failed To Load Document Categories");
    }
  };

  useEffect(() => {
    K1doccCategory();
  }, []);

  return (
    <Modal
      open={open}
      // onClose={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={style.boxPopUp}>
        <Grid container xs={12}>
          <Grid xs={12} container className={style.topHeader}>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "12px"
              }}
              item
              xs={11}>
              <img
                height={19.5}
                width={19.5}
                src={SendIcon}
                alt="Images Icon"
                style={{ marginRight: 8, cursor: "pointer" }}
              />
              <span
                style={{ color: "#FFFF", fontSize: "26px", fontWeight: 700 }}>
                Email to {ContactEmailId}
              </span>
            </Grid>
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-around"
              }}
              item
              xs={1}
              onClick={() => {
                AppDispatcher.sendEmailContact(false);
              }}>
              <img
                height={25.5}
                width={25.5}
                src={CrossIcon}
                alt="Images Icon"
                style={{ marginRight: 8, cursor: "pointer" }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            style={{
              padding: "20px 20px 0px 20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between"
            }}>
            <Grid>
              <Formik
                initialValues={{
                  subject: "",
                  email: ContactEmailId,
                  message: "",
                  documents: []
                }}
                onSubmit={async (values) => {
                  var formdata = new FormData();
                  values.documents.map((item, index) => {
                    return formdata.append("file", values.documents[index]);
                  });

                  formdata.append("message", values.message);
                  formdata.append("subject", values.subject);
                  formdata.append("emails", values.email);

                  const res = await ContactsService.sendContactMail(formdata);
                  if (res.success) {
                    Toast.showSuccessToast("Email Sent Successfully");
                    AppDispatcher.sendEmailContact(false);
                  } else {
                    Toast.showErrorToast("Unable to send Email");
                  }
                }}
                validationSchema={Yup.object().shape({
                  subject: Yup.string().required("Required"),
                  message: Yup.string().required("Required")
                })}>
                {(props) => {
                  const {
                    setFieldValue,
                    values,
                    isSubmitting,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid style={{ height: "340px" }}>
                        <Grid
                          style={{
                            padding: "0px 0px 20px 0px",
                            borderBottom: "1px solid #E9E9E9"
                          }}>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: 400,
                              color: "#A0A0A0"
                            }}>
                            From
                          </span>

                          <span
                            style={{
                              fontWeight: 600,
                              fontSize: "16px",
                              color: "#191919",
                              paddingLeft: "20px"
                            }}>
                            {adminEmailId}
                          </span>
                        </Grid>
                        <Grid
                          style={{
                            padding: "20px 0px 20px 0px",
                            borderBottom: "1px solid #E9E9E9",
                            display: "flex"
                          }}>
                          {" "}
                          <ContactDescriptionBox
                            id="subject"
                            placeholder="Subject"
                            variant="standard"
                            fullWidth
                            rows={1}
                            onBlur={handleBlur}
                            multiline
                            value={values.subject}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              maxLength: 255
                            }}
                            InputProps={{
                              disableUnderline: true
                            }}
                          />
                          <TextCounter
                            wordLength={256}
                            currentDescriptionLength={values?.subject?.length}
                            maxLength={255}
                          />
                        </Grid>
                        <Grid>
                          <MessegeDescriptionBox
                            id="message"
                            placeholder="Your Message..."
                            variant="standard"
                            fullWidth
                            rows={10}
                            onBlur={handleBlur}
                            multiline
                            value={values.message}
                            onChange={handleChange}
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              maxLength: 1000
                            }}
                            InputProps={{
                              disableUnderline: true
                            }}
                          />
                          <TextCounter
                            wordLength={1001}
                            currentDescriptionLength={values?.message?.length}
                            maxLength={1000}
                          />
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        justifyContent="space-between"
                        style={{
                          height: "90px",
                          display: "flex",
                          paddingTop: "20px 0px 20px 0px",
                          alignItems: "center",
                          borderTop: "1px solid #E9E9E9"
                        }}>
                        <Grid md={9} item>
                          {k1Categories?.map((item, index) => (
                            <ContactSendEmailDocument
                              index={index}
                              key={item.id}
                              type={item.id}
                              title={item.name}
                              listData={[]}
                              setWholeFile={(data) => {
                                setFieldValue("documents", data);
                              }}
                            />
                          ))}
                        </Grid>

                        <Grid style={{ flex: "display" }} item>
                          {values.subject != "" && values.message != "" ? (
                            <Button
                              type="submit"
                              disabled={isSubmitting}
                              style={{
                                fontFamily: "Inter SemiBold",
                                fontSize: 18,
                                height: 45,
                                fontWeight: 600,
                                minWidth: 174,
                                padding: "28px 70px",
                                borderRadius: 4,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                background:
                                  "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)",
                                color: "#FFFF",
                                cursor: "pointer",
                                marginRight: "10px"
                              }}>
                              Send
                            </Button>
                          ) : (
                            <Button
                              type="submit"
                              disabled
                              style={{
                                fontFamily: "Inter SemiBold",
                                fontSize: 18,
                                height: 45,
                                fontWeight: 600,
                                minWidth: 174,
                                padding: "28px 70px",
                                borderRadius: 4,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "#DCDCDC",
                                color: "#1a2027",
                                cursor: "pointer",
                                marginRight: "10px"
                              }}>
                              Send
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default SendContactEmail;
