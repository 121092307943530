import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import React from "react";
import styles from "../profile.module.scss";
import profile from "../../../../assets/icons/defaultProfilePic.svg";

export default function ProfileImage({ data }) {
  return (
    <div className={styles.profile}>
      <Grid container spacing={3}>
        <Grid item>
          <img
            src={
              data.image_url == null || data.image_url == undefined
                ? profile
                : data.image_url
            }
            alt="alt"
            height={200}
            width={200}
            className={styles.defaultProfileStyles}
          />
        </Grid>

        <Grid item>
          <div className={styles.profileName}>
            <Typography
              className={styles.profileName}
              style={{
                fontWeight: 700,
                fontSize: 16,
                width: "200px",
                textAlign: "center"
              }}>
              {`${data.first_name} ${data.last_name}`}
            </Typography>
            <Typography className={styles.roleStyle}>{data.role}</Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
