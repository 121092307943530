import { Grid } from "@material-ui/core";
import formatCurrency from "helpers/formatCurrency";
import formatDate from "helpers/formatDate";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import ViewHistoryPopup from "./ViewHistoryPopup";

function EquityInfo({ data }) {
  const [equity_info, setInvestment] = useState({});
  const [isopen, setIsOpen] = useState(false);

  const handlePopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    setInvestment(data?.equity_info ? data?.equity_info : {});
  }, [data]);
  return (
    <>
      <ViewHistoryPopup
        isopen={isopen}
        setIsOpen={setIsOpen}
        history={equity_info?.equity_history}
      />
      <Grid item xs={12} container wrap="wrap">
        <Grid item>
          <div className={styles.field_e_c}>
            <h4 className={styles.title}>Total Asset Value </h4>
            <div className={styles.value}>
              {equity_info?.total_asset
                ? formatCurrency(equity_info?.total_asset)
                : "-"}
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.field_e_c}>
            <h4 className={styles.title}>Outstanding Loan</h4>
            <div className={styles.value}>
              {equity_info?.outstanding
                ? formatCurrency(equity_info?.outstanding)
                : "-"}
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.field_e_c}>
            <h4 className={styles.title}>Bank Name</h4>
            <div className={styles.value}>
              {equity_info?.bank_name ? equity_info?.bank_name : "-"}
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.field_e_c}>
            <h4 className={styles.title}>Debt Service</h4>
            <div className={styles.value}>
              {equity_info?.debtservice
                ? formatCurrency(equity_info?.debtservice)
                : "-"}
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.field_e_c}>
            <h4 className={styles.title}>Date</h4>
            <div className={styles.value}>
              {equity_info?.date ? formatDate(equity_info?.date) : "-"}
            </div>
          </div>
        </Grid>
        <Grid item>
          <div className={styles.field_e_c}>
            <h4 className={styles.title}>Equity</h4>
            <div className={styles.value}>
              {equity_info?.equity_amount
                ? formatCurrency(equity_info?.equity_amount)
                : "-"}
              <span className={styles.view_his} onClick={handlePopup}>
                View History
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default EquityInfo;
