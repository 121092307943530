import { API, NetworkManager } from "./core";

export class AddInvestmentLoanService {
  static async getProperties(payload) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_LOAN.GET_PROPERTIES,
      {},
      payload
    );
    return await instance.httpRequest();
  }

  static async getLoans(id) {
    const instance = new NetworkManager(API.ADD_INVESTMENT_LOAN.GET_LOANS, {}, [
      `${id}/`
    ]);
    return await instance.httpRequest();
  }

  static async addLoan(payload, id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_LOAN.ADD_LOAN,
      payload,
      [`${id}/`]
    );
    return await instance.httpRequest();
  }

  static async deleteLoan(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_LOAN.DELETE_LOAN,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
}
