import { Grid } from "@material-ui/core";
import FieldHeading from "components/fieldHeading";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
// import CustomSelect from "components/StyledComponents/Select";
import React from "react";

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2)
//   }
// }));

const BasicDetails = ({ formik }) => {
  return (
    <>
      <Grid item xs={5} md={3}>
        <FieldHeading title={"Role Name"} />
        <AppTextFieldWhite
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          placeholder="Enter Role Name"
          value={formik.values.name}
          onChange={formik.handleChange("name")}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          InputProps={{ disableUnderline: true }}
          disabled={false}
        />
      </Grid>
      <Grid item xs={6} md={6}>
        <FieldHeading title={"Description"} />
        <AppTextFieldWhite
          fullWidth
          InputLabelProps={{
            shrink: true
          }}
          placeholder="Enter Role Description"
          value={formik.values.description}
          onChange={formik.handleChange("description")}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
          InputProps={{ disableUnderline: true }}
          disabled={false}
        />
      </Grid>
      {/* <Grid container spacing={1} item xs={12}>
        <Grid item xs={3} style={{ paddingRight: "7px" }}>
          <FieldHeading title={"Status"} />

          <CustomSelect
            defaultMenu="Select Status"
            fullWidth
            value={isEditCase ? formik.values.status : "000"}
            items={statusData}
            error={
              formik.touched.description && Boolean(formik.errors.description)
            }
            touched={formik.touched.status}
            onChange={formik.handleChange("status")}
          />
        </Grid>
      </Grid> */}
    </>
  );
};

export default BasicDetails;
