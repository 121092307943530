import React, { useRef, useState } from "react";
import { CircularProgress, Typography } from "@material-ui/core";
import Toast from "components/toast";
import { CommonService } from "network/commonService";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  drop_file_input: {},
  fileZone: {
    border: "2px dashed #5775E5",
    background: "#fff",
    borderRadius: 12,
    padding: "10px",
    display: "flex",
    width: "100%",
    paddingTop: "20px",
    paddingBottom: "20px",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  }
});

const Image = ({ index, displayImage, setDisplayImage }) => {
  const classes = useStyles();
  const [loading, setDocsLoading] = useState();
  const wrapperRef = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    let ext = e.target.value.match(/\.(.+)$/)[1];
    switch (ext) {
      case "jpg":
        break;
      case "jpeg":
        break;
      case "png":
        break;
      default:
        Toast.showErrorToast(
          "Only JPG, JPEG and PNG File Type Are Allowed. Please Upload Valid File Type"
        );
        return;
    }
    const newFile = e.target.files[0];
    if (newFile) {
      newFile.index = index;
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      const uploadedImageList = await CommonService.imageList();
      if (resp.success) {
        const url = uploadedImageList.data.filter(
          (item) => item.id === resp.data.id
        );
        Toast.showSuccessToast("Image Upload SuccessFully");
        setDisplayImage([...displayImage, ...url]);
        setDocsLoading(false);
      }
    } else {
      Toast.showErrorToast("Document Not Uploaded");
    }
  };

  return (
    <>
      {loading ? (
        <label className={classes.fileZone}>
          <div style={{ paddingLeft: 7 }}>
            <Typography>
              <CircularProgress />
            </Typography>
          </div>
          <div style={{ width: 0, overflow: "hidden" }}>
            <input
              id={`src${index}`}
              type="file"
              value=""
              onChange={onFileDrop}
            />
          </div>
        </label>
      ) : (
        <label
          htmlFor={`src${index}`}
          ref={wrapperRef}
          className={classes.fileZone}
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDrop}>
          <div style={{ paddingLeft: 7 }}>
            <Typography>
              Drag And Drop Files Here Or{" "}
              <text style={{ color: "#5775E5", fontWeight: 600 }}>Browse</text>{" "}
              (Supported Formats: JPEG, JPG, PNG. Maximum File Size: 15MB Each).
            </Typography>
          </div>
          <div style={{ width: 0, overflow: "hidden" }}>
            <input
              id={`src${index}`}
              type="file"
              value=""
              onChange={onFileDrop}
            />
          </div>
        </label>
      )}
    </>
  );
};

export default Image;
