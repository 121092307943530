import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../../components/HeaderComponent/HeaderComponent";
import Container from "./components/container/container";
import ContentContainer from "./components/container/contentContainer";
import {
  makeStyles,
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid
} from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import AddIcon from "../../../../assets/icons/addIcon.svg";
import EntityForm from "./components/forms/entityForm";
import PartnerForm from "./components/forms/partnerForm";
import {
  validationSchemaAddEntityInvestorExsisting,
  validationSchemaAddEntityInvestorNotExsisting,
  validationSchemaAddEntitySponsorExsisting,
  validationSchemaAddEntitySponsorNotExsisting
} from "../../../../helpers/validationSchema";
import { EntityService } from "../../../../network/entityService";
import Toast from "../../../../components/toast";
import { useHistory } from "react-router-dom";
import { CommonService } from "network/commonService";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useSelector } from "react-redux";
import CancelBtn from "components/Buttons/cancelBtn";
import AddBtn from "components/Buttons/addBtn";
import CancelModal from "components/Modals/CancelModal";
import NewCategoryDocTable from "pages/private/properties/component/NewCategoryDocTable";

const useStyles = makeStyles(() => ({
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0
  },
  deleteButton: {
    cursor: "pointer",
    marginTop: 24
  },
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    width: 160
  },
  docsContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    "& > div": {
      marginRight: 10,
      marginBottom: 10
    }
  },
  errorMessage: {
    color: "#E13838",
    fontSize: "0.75rem",
    marginTop: 3
  },
  menuPaper: {
    maxHeight: 200
  },
  mainContainer: {
    display: "flex",
    "@media only screen and (max-width: 1020px)": {
      flexDirection: "column"
    }
  },
  ContainerOne: {
    minWidth: "268px",
    marginRight: "40.52px",

    flexShrink: 1,
    "@media only screen and (max-width: 1020px)": {
      width: "100%",
      marginBottom: 40
    }
  },
  radioGroup: {
    "@media only screen and (max-width: 1020px)": {
      flexDirection: "row",
      width: "400px",
      justifyContent: "space-between"
    }
  },
  ContainerTwo: {
    flexGrow: 1
  }
}));

const AddEntities = () => {
  const classes = useStyles();
  // const roles = useSelector((state) => state?.app?.allRoles);
  const [clickedOnAddbtn, setClickedOnAddbtn] = useState(false);
  const [isSponsor, setIsSponsor] = useState(false);
  const [invDocCategories, setInvDocCategories] = useState([]);
  const [sponDocCategories, setSponDocCategories] = useState([]);
  const [invDocs, setInvDocs] = useState([]);
  const [sponDocs, setSponDocs] = useState([]);
  const { userPermissions, user } = useSelector((store) => store.app);
  const [confirmCancel, setConfirmCancel] = useState(false);
  const [existingEmailSelected, setExistingEmailSelected] = useState(false);
  const history = useHistory();

  const handleSubmit1 = async (values) => {
    const docList = isSponsor ? sponDocs : invDocs;
    const payload = {
      tax_id: values.taxId,
      entity_type: isSponsor ? "SPONSOR" : "INVESTOR",
      ...(existingEmailSelected && { entity_type_id: values.entityTypeId.id }),
      ...(!existingEmailSelected &&
        (isSponsor
          ? {
              sponsors: {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                company_name: values.company_name,
                country_code: `+${values.countryCode}`,
                phone_number: values.phone,
                created_by: user.id
              }
            }
          : {
              investors: {
                email: values.email,
                first_name: values.first_name,
                last_name: values.last_name,
                country_code: `+${values.countryCode}`,
                phone_number: values.phone,
                is_investor: true
              }
            })),
      partners: values.partnerSet,
      address: values.mailingAddress,
      name: values.entityName,
      documents: docList?.map((doc) => ({
        document_media_id: doc.id,
        category_id: doc.type
      })),

      created_by: user.id,
      ...(isSponsor
        ? { admin_sponsor_validate: true }
        : { admin_validate: true })
    };

    let res = await EntityService.addEntity(payload);
    if (res.success) {
      if (res?.data?.data) {
        Toast.showErrorToast(res.data.data);
      } else {
        Toast.showSuccessToast(res.data.message);
        history.push("/u/entities");
      }
    } else {
      if (res.error.message[0] === "Must be a valid UUID.") {
        Toast.showErrorToast(
          `Email Does Not Exist, Add New ${isSponsor ? "Sponsor" : "Investor"}.`
        );
      } else {
        Toast.showErrorToast(res.error.message[0]);
      }
    }
  };

  const handleChangeEntityFor = async (e, formik) => {
    formik.setFieldValue("entityTypeId", "");
    formik.setFieldValue("entityName", "");
    formik.setFieldValue("mailingAddress", "");
    formik.setFieldValue("email", "");
    formik.setFieldValue("taxId", "");
    formik.setFieldValue("first_name", "");
    formik.setFieldValue("last_name", "");
    formik.setFieldValue("countryCode", "");
    formik.setFieldValue("phone", "");
    formik.setFieldValue("company_name", "");
    formik.setFieldValue("partnerSet", [{ name: "", email: "" }]);
    setIsSponsor(e.target.value === "Sponsor");
    setClickedOnAddbtn(false);
  };

  const getCategories = async (categoryName) => {
    const res = await CommonService.getDocCategories({
      name: categoryName
    });
    if (res.success) {
      return res?.data[0]?.doccategory || [];
    } else {
      Toast.showErrorToast("Failed To Load Document Categories");
    }
    return [];
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "ENTITY",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_create) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(async () => {
    checkPermissions();
    let [invCategories, sponCategories] = await Promise.all([
      getCategories("INVESTORENTITY"),
      getCategories("SPONSORENTITY")
    ]);
    setInvDocCategories(invCategories.filter((el) => el.name !== "Misc."));
    setSponDocCategories(sponCategories);
  }, []);

  return (
    <>
      <HeaderComponent
        heading="Add Entity"
        description="Add New Entities To Simplee"
        backBtn={true}
      />
      <Box height={53} />

      <Formik
        initialValues={{
          entityTypeId: "",
          entityName: "",
          mailingAddress: "",
          taxId: "",
          email: "",
          first_name: "",
          last_name: "",
          countryCode: "1",
          phone: "",
          company_name: "",
          partnerSet: [{ name: "", email: "" }]
        }}
        validationSchema={
          isSponsor
            ? clickedOnAddbtn
              ? validationSchemaAddEntitySponsorNotExsisting
              : validationSchemaAddEntitySponsorExsisting
            : clickedOnAddbtn
            ? validationSchemaAddEntityInvestorNotExsisting
            : validationSchemaAddEntityInvestorExsisting
        }
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmit1(values);
        }}>
        {(formik) => (
          <Form>
            <div className={classes.mainContainer}>
              <div className={classes.ContainerOne}>
                {/* radio button */}
                <ContentContainer ontainer heading="Entity Type:">
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="Investor/Sponsor"
                      className={classes.radioGroup}
                      name="controlled-radio-buttons-group"
                      onChange={(e) => handleChangeEntityFor(e, formik)}
                      defaultValue="Investor">
                      <FormControlLabel
                        value="Investor"
                        control={<Radio color="secondary" />}
                        label={<div className={classes.label}>Investor</div>}
                      />
                      <FormControlLabel
                        value="Sponsor"
                        control={<Radio color="secondary" />}
                        label={<div className={classes.label}>Sponsor</div>}
                      />
                    </RadioGroup>
                  </FormControl>
                </ContentContainer>
              </div>
              <div className={classes.ContainerTwo}>
                {/* AddEntity Form */}
                <Container heading="Basic">
                  <EntityForm
                    isSponsor={isSponsor}
                    formik={formik}
                    classes={classes}
                    setExistingEmailSelected={setExistingEmailSelected}
                    setClickedOnAddbtn={setClickedOnAddbtn}
                  />
                </Container>

                <Container heading="Partners">
                  <FieldArray
                    name="partnerSet"
                    render={(arrayHelper) => (
                      <>
                        {formik.values.partnerSet.map((partner, index) => (
                          <>
                            <PartnerForm
                              classes={classes}
                              id={index}
                              key={index}
                              arrayHelper={arrayHelper}
                              formik={formik}
                            />
                            <Box height={30} />
                          </>
                        ))}
                        <div
                          className={classes.addMore}
                          onClick={() =>
                            arrayHelper.push({ name: "", email: "" })
                          }>
                          <img
                            src={AddIcon}
                            alt=""
                            style={{ marginRight: 8 }}
                          />
                          <div>Add More</div>
                        </div>
                      </>
                    )}
                  />
                </Container>
                <Container heading="Upload Documents">
                  <Grid item>
                    {isSponsor ? (
                      <NewCategoryDocTable
                        sponDocCategories={sponDocCategories}
                        listData={sponDocs}
                        setListData={setSponDocs}
                      />
                    ) : (
                      <NewCategoryDocTable
                        sponDocCategories={invDocCategories}
                        listData={invDocs}
                        setListData={setInvDocs}
                      />
                    )}
                  </Grid>
                </Container>
                <Grid
                  container
                  justifyContent="flex-end"
                  className={classes.mTop}>
                  <CancelBtn onClick={() => setConfirmCancel(true)} />
                  <AddBtn
                    // disabled={!dropOpen}
                    type="submit"
                    text="Add Entity"
                    onClick={formik.handleSubmit}
                  />
                </Grid>
                <CancelModal
                  open={confirmCancel}
                  title=""
                  description={`Are you sure you want to cancel?`}
                  handleClose={() => setConfirmCancel(false)}
                  handleSubmit={() => history.goBack()}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AddEntities;
