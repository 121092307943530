import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FAFAFA",
    padding: 20,
    borderRadius: 10,
    marginTop: 20,
    marginBottom: 30
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: 20
  },
  heading: {
    fontFamily: "Inter Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px"
  }
}));

const Container = ({ children, heading }) => {
  const classes = useStyles();
  return (
    <>
      {heading ? (
        <div className={classes.headingContainer}>
          <div className={classes.heading}>{heading}</div>
        </div>
      ) : null}
      <Grid
        container
        item
        xs={12}
        className={classes.container}
        direction="column">
        {children}
      </Grid>
    </>
  );
};

export default Container;
