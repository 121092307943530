import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InvestorPage from "./InvestorPage";
import SponsorPage from "./SponsorPage";
import { AppDispatcher } from "../../../../redux";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";

const EntityDetail = () => {
  const { entitiesDetailData, userPermissions, user } = useSelector(
    (store) => store.app
  );
  const history = useHistory();
  const [permissions, setPermissions] = useState();

  const handleClick = (e, type, id) => {
    e.stopPropagation();
    if (id) {
      switch (type) {
        case "investor":
          AppDispatcher.setInvestorDetailID(id);
          history.push("/u/investors/detail");
          break;
        case "sponsor":
          AppDispatcher.setSponsorDetailID(id);
          history.push("/u/sponsors/detail");
          break;
        case "investment":
          AppDispatcher.setInvestmentDetailID(id);
          history.push("/u/investments/detail");
          break;
        case "property":
          AppDispatcher.setPropertiesDetailID(id);
          history.push("/u/properties/detail");
          break;
      }
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "ENTITY",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(() => {
    checkPermissions();
  }, []);

  return (
    <>
      {entitiesDetailData.entity_type === "INVESTOR" && (
        <InvestorPage handleClick={handleClick} permissions={permissions} />
      )}
      {entitiesDetailData.entity_type === "SPONSOR" && (
        <SponsorPage handleClick={handleClick} permissions={permissions} />
      )}
    </>
  );
};

export default EntityDetail;
