import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { FieldArray, Form, Formik } from "formik";
import styles from "./components/Components.module.scss";
import PlusAdd from "assets/icons/PlusAdd.svg";
import Refinances from "./components/Refinances";
import { useSelector } from "react-redux";
import { formatDate } from "helpers";
import {
  ValidationSchemeAddInvestmentRefinance,
  ValidationSchemeAddInvestmentRefinanceDraft
} from "helpers/validationSchema";
import Loader from "components/Loader/AppLoader";
import PrimaryButton from "components/Buttons/Primary";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Toast from "components/toast";
import { AddInvestmentLoanService } from "network/addInvestmentLoanService";
import { AppDispatcher } from "redux/index";
import formatCurrency from "helpers/formatCurrency";
import UpdateBtn from "components/Buttons/Primary/UpdateBtn";
import { useHistory } from "react-router-dom";

const Refinance = () => {
  const history = useHistory();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isedit, setIsEdit] = useState(false);
  const [investmentStatus, setInvestmentStatus] = useState("Draft");
  const [loading, setLoading] = useState(false);
  const [draft, setDraft] = useState(false);
  const [test, setTest] = useState(null);
  const [dropDownOpRes, setDropDownOpRes] = useState(null);
  const { investmentID, outStandingLoan } = useSelector((store) => store.app);
  const [initialValues, setInitialValues] = useState({
    refinanceSet: []
  });
  const [loanOutStanding, setLoanOutStanding] = useState(0);

  const getDropDownOptions = async () => {
    const resp = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (resp.success) {
      setDropDownOpRes(resp?.data);
    }
  };

  useEffect(() => {
    getDropDownOptions();
  }, []);

  const returnNewArray = (element) => {
    let newArray = [];
    element.length &&
      element.forEach((ele) => {
        ele && newArray.push(ele);
      });
    return newArray;
  };

  const handleLoans = async (values) => {
    setLoading(true);
    let payloadObject = {};
    let payload = [];

    //***********  payload for both save as draft and save  *********** /
    const FilteredLoan = values.refinanceSet.filter(
      (el) => el.isLoanEdit === true
    );

    FilteredLoan?.forEach((element) => {
      element.refinance_loan_type &&
        (payloadObject.type = element.refinance_loan_type);
      element.refinance_loan_amount &&
        (payloadObject.amount = element.refinance_loan_amount);
      payloadObject.outstanding = element.outstanding_loan;
      element.notes && (payloadObject.description = element.notes);
      element.bank_name && (payloadObject.bank = element.bank_name);
      element.refinance_loan_initiation_date &&
        (payloadObject.initiation = formatDate(
          element.refinance_loan_initiation_date
        ));
      element.debt_service &&
        (payloadObject.debtservice = element.debt_service);
      element.last_updated_on &&
        (payloadObject.last_updated_on = formatDate(element.last_updated_on));
      payloadObject.is_refinanced = true;
      element.selectedProperties &&
        element.selectedProperties.length > 0 &&
        (payloadObject.loan_property = returnNewArray(
          element.selectedProperties
        ));
      element.detach_property &&
        element.detach_property.length > 0 &&
        (payloadObject.detach_property = returnNewArray(
          element.detach_property
        ));
      element.selectedLoans &&
        element.selectedLoans.length > 0 &&
        (payloadObject.parentLoan = returnNewArray(element.selectedLoans));
      element.detach_loan &&
        element.detach_loan.length > 0 &&
        (payloadObject.detach_loan = returnNewArray(element.detach_loan));
      element?.documents &&
        element.documents.length > 0 &&
        (payloadObject.documents = element?.documents?.map((doc) => ({
          document_media_id: doc.id,
          category_id: doc.type,
          ...(doc.docId ? { id: doc.docId } : {})
        })));
      element.loan_status &&
        (payloadObject.is_active =
          element.loan_status === "active" ? true : false);
      payloadObject.loan_investment = investmentID;
      if (element?.loan_id) {
        payloadObject.id = element.loan_id;
      }
      payload.push(payloadObject);
      payloadObject = {};
    });
    const newPayload = {
      loan: payload,
      ...(draft ? { is_draft: true } : {})
    };
    const response = await AddInvestmentLoanService.addLoan(newPayload, [
      investmentID
    ]);
    if (response.success) {
      setLoading(false);
      Toast.showSuccessToast("Refinance Added Successfully");
      if (draft) {
        history.push("/u/investments");
      } else {
        if (isUpdate) {
          history.push("/u/investments");
          return;
        }
        AppDispatcher.addInvestmentNextStep();
      }
    } else {
      setLoading(false);
      Toast.showErrorToast(
        response?.error?.message?.[0]
          ? response?.error?.message[0]
          : "Some Error occured"
      );
    }
    setLoading(false);
  };

  const onHandleSubmit = (formik, drafts) => {
    setTest(formik);
    if (drafts) {
      setDraft(true);
    } else {
      setDraft(false);
    }
  };

  useEffect(() => {
    test && test.handleSubmit();
  }, [draft, test]);

  const fetchLoanData = async () => {
    setLoading(true);
    let url = `${investmentID}`;
    const resp = await AddInvestmentLoanService.getLoans(url);
    if (resp.success) {
      setLoading(false);

      let sum = 0;
      resp?.data.loan.map((obj) => {
        sum =
          sum +
          (obj.status?.toLowerCase() != "inactive"
            ? parseInt(obj.outstanding)
            : 0);
      });
      setLoanOutStanding(sum);
      setInvestmentStatus(resp?.investment?.status);
      if (resp?.data?.refinances.length > 0) {
        const filterData = resp?.data?.refinances.filter(
          (loan) => loan.is_refinanced
        );
        const filterDataInactive = resp?.data?.refinances.filter(
          (loan) => !loan.is_active
        );
        setIsEdit(true);
        setInitialData(filterData, filterDataInactive);
      } else {
        setLoading(false);
      }
      setLoading(false);
    }
  };

  const returnInitialPropertyArray = (arr) => {
    let tempArr = [];
    arr &&
      arr.length &&
      arr.forEach((item) => {
        tempArr.push(item.id);
      });
    return tempArr;
  };

  const returnInitialLoanArray = (arr) => {
    let tempArr = [];
    arr &&
      arr.length &&
      arr.forEach((item) => {
        tempArr.push(item.id);
      });
    return tempArr;
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      size: el?.document_media?.file_size,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  const setInitialData = (data) => {
    let initObj = {};
    let initArray = [];
    data.forEach((element) => {
      initObj.isLoanEdit = false;
      initObj.refinance_loan_type = element.type;
      initObj.refinance_loan_amount = element.amount;
      initObj.outstanding_loan = element.outstanding;
      initObj.bank_name = element.bank;
      initObj.refinance_loan_initiation_date = element.initiation;
      initObj.debt_service = element.debtservice;
      initObj.last_updated_on = element.last_updated_on;
      initObj.selectedProperties = returnInitialPropertyArray(
        element.loan_property
      );
      initObj.selectedLoans = returnInitialLoanArray(element?.associated_loans);
      initObj.status = element.status.toLowerCase();
      initObj.is_refinanced = element.is_refinanced;
      initObj.loan_id = element.id;
      initObj.base_id = element.base_id;
      initObj.notes = element.description;
      initObj.documents = formatDocsData(element.documents);
      initArray.push(initObj);
      initObj = {};
    });
    let tempObj = {
      refinanceSet: initArray
    };
    setInitialValues(tempObj);
    setLoading(false);
  };

  useEffect(() => {
    if (investmentID) fetchLoanData();
  }, [investmentID]);

  function RefinanceValueBar({ formik }) {
    const [Pro_ValueSum, SetPro_ValueSum] = useState(0);

    const proValueSum = () => {
      let sum = 0;

      formik.values.refinanceSet?.map((obj) => {
        sum =
          sum +
          (obj.status?.toLowerCase() != "inactive"
            ? parseInt(obj.outstanding_loan)
            : 0);
      });
      return sum;
    };

    useEffect(() => {
      SetPro_ValueSum(proValueSum());
    });

    const green = { fontSize: "16px", fontWeight: 400, color: "green" };
    const red = { fontSize: "16px", fontWeight: 400, color: "red" };
    const Spnvalue = { fontSize: "16px", fontWeight: 700 };
    return (
      <>
        <Grid container item xs={12} justifyContent="flex-end">
          <div
            style={
              Pro_ValueSum + loanOutStanding > outStandingLoan ? red : green
            }>
            Total Outstanding Loan:
            <span style={Spnvalue}>
              {Pro_ValueSum
                ? formatCurrency(Pro_ValueSum + loanOutStanding).split(".")?.[0]
                : loanOutStanding}
            </span>
            /
            <span style={Spnvalue}>
              {outStandingLoan
                ? formatCurrency(outStandingLoan).split(".")?.[0]
                : 0}
            </span>
          </div>
        </Grid>
      </>
    );
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={
            draft
              ? ValidationSchemeAddInvestmentRefinanceDraft
              : ValidationSchemeAddInvestmentRefinance
          }
          enableReinitialize={true}
          onSubmit={(values) => {
            handleLoans(values);
          }}>
          {(formik) => (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <Grid
                    container
                    className={styles.margin}
                    justifyContent="flex-end">
                    <Grid item xs={12}>
                      <RefinanceValueBar formik={formik} />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <FieldArray
                        name="refinanceSet"
                        render={(arrayHelper) => (
                          <>
                            {formik.values.refinanceSet.map((dis, index) => (
                              <>
                                <Refinances
                                  id={index}
                                  key={index}
                                  arrayHelper={arrayHelper}
                                  formik={formik}
                                  dropDownOpRes={dropDownOpRes}
                                  isedit={isedit}
                                />
                              </>
                            ))}
                            <div
                              className={styles.addContact}
                              onClick={() =>
                                arrayHelper.push({
                                  isLoanEdit: true,
                                  outstanding_loan: "",
                                  refinance_loan_type: "",
                                  notes: "",
                                  refinance_loan_amount: "",
                                  bank_name: "",
                                  refinance_loan_initiation_date: "",
                                  debt_service: "",
                                  last_updated_on: "",
                                  status: "",
                                  selectedProperties: [],
                                  selectedLoans: [],
                                  documents: []
                                })
                              }>
                              <div className={styles.addIcon}>
                                <img
                                  src={PlusAdd}
                                  width={30}
                                  height={30}
                                  alt="delete"
                                />
                              </div>
                              <div>
                                <Typography className={styles.addContactText}>
                                  {formik.values.refinanceSet.length > 0
                                    ? "Add Another Refinance"
                                    : "Add Refinance"}
                                </Typography>
                              </div>
                            </div>
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className={styles.btnCtrNormal}>
                <Grid item container xs>
                  {investmentStatus === "Draft" ? (
                    <div className={styles.saveAsDraft}>
                      <Typography
                        className={styles.draftText}
                        onClick={() => onHandleSubmit(formik, true)}>
                        Save As Draft
                      </Typography>
                    </div>
                  ) : (
                    <div className={styles.saveAsDraft}>
                      <Typography
                        className={styles.draftText}
                        onClick={() => history.push("/u/investments")}>
                        Cancle
                      </Typography>
                    </div>
                  )}
                  <div className={styles.saveAsDraft}>
                    <PrimaryButton
                      text={"Next"}
                      onPress={() => onHandleSubmit(formik, false)}
                    />
                  </div>
                </Grid>
                {investmentStatus !== "Draft" && (
                  <Grid item>
                    <UpdateBtn
                      onClick={() => {
                        setIsUpdate(true);
                        onHandleSubmit(formik, false);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default Refinance;
