import React from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";

import blueCloseIcon from "assets/icons/NewCardSvg/blueCloseIcon.svg";
import formatCurrency from "helpers/formatCurrency";
import { truncate } from "helpers/Common";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";

const useStyles = makeStyles(() => ({
  root: {
    "&  .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 838,
      height: "fit-content"
    }
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 40,
    boxSizing: "border-box"
  },
  heading: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "Inter Bold",
    maxWidth: "646px",
    height: "62px,"
  },
  subTextContainer: { marginBottom: "38.5px" },
  subtext: {
    fontFamily: "Inter semiBold",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#191919"
  },
  subtextSpan: {
    fontFamily: "Inter Bold",
    color: "#445EBE"
  },
  btnTopContainer: {
    width: "100%",
    maxWidth: 550,
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px"
  },
  Nobtn: {
    cursor: "pointer",
    margin: "40px 10px",
    width: "174px",
    height: "50px",
    borderRadius: "4px",
    border: "1px solid #445EBE",
    background: "#FFF",
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#445EBE"
  },
  btn: {
    cursor: "pointer",
    margin: "40px 0px",
    width: "174px",
    height: "50px",
    borderRadius: "4px",
    border: "none",
    background:
      "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)",
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF"
  },

  Tbody: {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#191919"
  },
  valueBox: {
    width: "178px",
    height: "48px"
  },
  THead: {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#191919"
  }
}));

const PropertyPopUp = ({
  isopen,
  setIsOpen,
  formik,
  setpopupNoClick,
  PreviousProperty
}) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleClickNO = () => {
    setpopupNoClick(true);
    formik.setFieldValue("properties", PreviousProperty);
  };

  const handleCilckYES = () => {
    setpopupNoClick(true);
  };

  return (
    <Dialog
      className={classes.root}
      open={isopen}
      style={{
        backgroundColor: "rgba(235, 239, 255, 0.6)",
        backdropFilter: "blur(26)"
      }}>
      <DialogContent>
        <div className={classes.MaterialForm}>
          <div className={classes.header}>
            <div>
              <Typography variant="h4" className={classes.heading}>
                Total Asset Value has been modified, would you want to modify
                the property values?
              </Typography>
            </div>
            <div className={classes.closeButton}>
              <img height="48px" src={blueCloseIcon} onClick={handleClose} />
            </div>
          </div>
          <div className={classes.subTextContainer}>
            <p className={classes.subtext}>
              Updated Total Asset Value:
              <span className={classes.subtextSpan}>
                {
                  formatCurrency(
                    formik.values?.equity_info?.[0]?.total_asset
                  ).split(".")?.[0]
                }
              </span>
            </p>
          </div>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.THead}>Property ID</TableCell>
                  <TableCell className={classes.THead}>Property Name</TableCell>
                  <TableCell className={classes.THead}>
                    Property Value ($)
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {formik.values?.properties?.length !== 0 ? (
                  formik.values?.properties?.map((ele, i) => (
                    <TableRow>
                      <TableCell className={classes.Tbody}>
                        {ele?.base_id || "New Property"}
                      </TableCell>
                      <TableCell className={classes.Tbody}>
                        {ele?.name ? truncate(ele?.name, 20) : "-"}
                      </TableCell>
                      <TableCell className={`${classes.Tbody} `}>
                        <AppTextFieldWhite
                          className={classes.valueBox}
                          id={`properties[${i}].value`}
                          placeholder=""
                          numFormat={true}
                          variant="standard"
                          value={ele?.value ? ele?.value : "-"}
                          onChange={(e) => {
                            formik.setFieldValue(
                              `properties[${i}].value`,
                              e.target.value
                            );
                          }}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{
                            disableUnderline: true
                          }}
                          inputProps={{ maxLength: 12 }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell rowSpan={3}>No History Found</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <div className={classes.btnTopContainer}>
              <h4 className={classes.subtext}>Total Property Value</h4>
              <h3 className={classes.subtextSpan}>
                {formatCurrency(
                  formik.values?.properties?.reduce(
                    (acc, { value }) =>
                      (acc += parseInt(value != "-" ? value : 0)),
                    0
                  )
                ).split(".")?.[0] === "$NaN"
                  ? formatCurrency(formik.values?.properties[0].value)
                  : formatCurrency(
                      formik.values?.properties?.reduce(
                        (acc, { value }) =>
                          (acc += parseInt(value != "-" ? value : 0)),
                        0
                      )
                    ).split(".")?.[0]}
              </h3>
            </div>
            <div>
              <button className={classes.Nobtn} onClick={handleClickNO}>
                No
              </button>
              <button className={classes.btn} onClick={handleCilckYES}>
                Yes
              </button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PropertyPopUp;
