import React, { useEffect, useState } from "react";
import { makeStyles, Menu } from "@material-ui/core";
import ExpandIcon from "assets/icons/expandIcon.svg";
import CollapseIcon from "assets/icons/collapseIcon.svg";
import FiltersList from "./components/FiltersList";
import CloseIcon from "assets/icons/cross.svg";
import styles1 from "../../../investments/investments.module.css";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: "12px 20px",
    borderRadius: 16,
    border: "1px solid #EBEFFF",
    cursor: "pointer",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    flexShrink: 0,
    flexGrow: 0,
    width: "fit-content",
    margin: "0 10px 10px 0"
  },
  expand: {
    marginLeft: 15
  },
  itemContainer: {
    padding: "7px 20px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    fontFamily: "Inter Regular",
    fontSize: 14,
    margin: "6px 0",
    "&:hover": {
      backgroundColor: "#F5F7FB"
    }
  },
  listContainer: {
    boxSizing: "border-box",
    border: "1px solid #EBEFFF",
    borderRadius: 16,
    backgroundColor: "#FFFFFF",
    padding: "14px 0"
  }
}));

const quarterList = [
  { label: "Quarter 1", value: "q1" },
  { label: "Quarter 2", value: "q2" },
  { label: "Quarter 3", value: "q3" },
  { label: "Quarter 4", value: "q4" }
];

const tenureChoices = [
  { label: "Yearly", value: "yearly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Custom", value: "custom" }
];

const TimeFilter = ({ selected, setSelected }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isTenureExpanded, setIsTenureExpanded] = useState(false);
  const [anchorElTenure, setAnchorElTenure] = useState(null);
  const [selectedType, setSelectedType] = useState("");
  const open = Boolean(anchorEl);
  const tenureOpen = Boolean(anchorElTenure);
  const [chipsArr, setChipsArr] = useState([]);

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsExpanded(!isExpanded);
  };

  const handleTenureClose = () => {
    setAnchorElTenure(null);
    setIsTenureExpanded(false);
  };

  const handleTenureClick = (event) => {
    setAnchorElTenure(event.currentTarget);
    setIsTenureExpanded(!isTenureExpanded);
  };

  const getTitle = () => {
    switch (selected.tenure) {
      case "yearly":
        return "Yearly";
      case "quarterly":
        return "Quarterly";
      default:
        return "Custom";
    }
  };

  const getformattedDate = (date) => {
    let formattedDate = `${date.from.getDate()}/${
      date.from.getMonth() + 1
    }/${date.from.getFullYear()}`;
    formattedDate += " - ";
    formattedDate += `${date.to.getDate()}/${
      date.to.getMonth() + 1
    }/${date.to.getFullYear()}`;
    return formattedDate;
  };

  const FilterChip = ({ label, onRemove }) => (
    <div className={styles1.chips}>
      <div className={styles1.chipsText}>{label}</div>
      <div onClick={onRemove}>
        <img className={styles1.chipsImg} src={CloseIcon} alt="cross" />
      </div>
    </div>
  );

  const handleFirstFilterChange = () => {
    setChipsArr([]);
    let selectedTenure = selected.tenure;
    setSelected({
      tenure: selectedTenure,
      selectedTenure: {}
    });
  };

  const handleQuarterChange = (val) => {
    let selectedItems = { ...selected };
    selectedItems.quarterly.quarters[val] = false;
    setSelected(selectedItems);
  };

  const handleTenureSelect = (value) => {
    setSelected({ tenure: value });
    setSelectedType(value);
    setAnchorElTenure(null);
  };

  const getTenureList = () => (
    <div className={classes.listContainer} style={{ width: 196 }}>
      {tenureChoices.map((item) => (
        <div
          className={classes.itemContainer}
          key={item.value}
          onClick={() => handleTenureSelect(item.value)}>
          {item.label}
        </div>
      ))}
    </div>
  );

  useEffect(() => {
    let chipArray = [];
    if (selected.tenure) {
      if (selected.tenure === "yearly" || selected.tenure === "quarterly") {
        if (selected[selected.tenure]?.year) {
          chipArray.push(
            <FilterChip
              label={selected[selected.tenure].year}
              onRemove={handleFirstFilterChange}
            />
          );
        }
      }
      if (chipArray.length && selected.tenure === "quarterly") {
        let quarters = selected.quarterly.quarters;
        Object.keys(quarters).forEach((quarter) => {
          if (quarters[quarter]) {
            chipArray.push(
              <FilterChip
                label={quarterList.find((el) => el.value === quarter)?.label}
                onRemove={() => handleQuarterChange(quarter)}
              />
            );
          }
        });
      } else if (selected.tenure === "custom") {
        if (selected[selected.tenure]?.from && selected[selected.tenure]?.to) {
          chipArray.push(
            <FilterChip
              label={getformattedDate(selected[selected.tenure])}
              onRemove={handleFirstFilterChange}
            />
          );
        }
      }
    }
    setChipsArr(chipArray);
  }, [selected]);

  return (
    <>
      <div className={classes.container} onClick={handleTenureClick}>
        <span>Choose Tenure</span>
        <div className={classes.expand}>
          <img
            src={isTenureExpanded ? CollapseIcon : ExpandIcon}
            alt="Expand/Collapse"
          />
        </div>
      </div>
      <Menu
        anchorEl={anchorElTenure}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        keepMounted
        getContentAnchorEl={null}
        open={tenureOpen}
        onClose={handleTenureClose}>
        {getTenureList()}
      </Menu>
      {selected.tenure ? (
        <>
          <div className={classes.container} onClick={handleClick}>
            <span>{getTitle()}</span>
            <div className={classes.expand}>
              <img
                src={isExpanded ? CollapseIcon : ExpandIcon}
                alt="Expand/Collapse"
              />
            </div>
          </div>
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "left" }}
            keepMounted
            getContentAnchorEl={null}
            open={open}
            onClose={handleClose}>
            <FiltersList
              handleClose={handleClose}
              selected={selected}
              setSelected={setSelected}
              quarterList={quarterList}
              selectedType={selectedType}
            />
          </Menu>
        </>
      ) : null}
      {chipsArr}
    </>
  );
};

export default TimeFilter;
