import React from "react";
import { Grid, Typography } from "@material-ui/core";
// import { makeStyles } from "@material-ui/styles";
import styles from "../EntityDetail.module.scss";
import { truncate } from "helpers/Common";
import GeneralInformation from "./GeneralInformation";
import DocumentsTable from "../EntityTable/DocumentsTable";
import PartnerTable from "../EntityTable/PartnerTable";
import { Skeleton } from "@material-ui/lab";
import { numFormatter } from "helpers";

const Overview = ({ data }) => {
  const documentTableHeading = ["Document Name", "Size", "Attachment"];
  const partnersTableHeading = ["Name", "Email"];
  const skeleton = (input) => {
    return Object.keys(data).length != 0
      ? input
      : <Skeleton width={150} variant="text" /> || "-";
  };
  const obj = {};
  data.length != 0 &&
    data?.documents?.map((ele) => {
      if (
        Object.keys(obj)?.findIndex((val) => val === ele?.category?.name) === -1
      ) {
        obj[ele?.category?.name] = [
          {
            name: ele?.document_media?.name,
            type: ele?.category?.id,
            link: ele?.document_media?.file,
            id: ele?.document_media?.id,
            docId: ele.id,
            size: ele?.document_media?.file_size
          }
        ];
      } else {
        obj[ele?.category?.name] = [
          ...obj[ele?.category?.name],
          {
            name: ele?.document_media?.name,
            type: ele?.category?.id,
            link: ele?.document_media?.file,
            id: ele?.document_media?.id,
            docId: ele.id,
            size: ele?.document_media?.file_size
          }
        ];
      }
    });

  const toolData = [
    data?.stats?.net_equity || 0,
    data?.stats?.total_distribution
  ];

  const heading = [
    ["Tax ID (EIN)", skeleton(data?.tax_id)],
    ["Mailing Address", skeleton(truncate(data?.address?.[0]?.address, 25))],
    ["Entity Type", skeleton(data?.entity_type)],
    [
      data?.entity_type === "INVESTOR" ? "Investor Name" : "Sponsor Name",
      skeleton(data?.entity_type_id?.name)
    ],
    [
      "Total Asset Value",
      skeleton(`$${numFormatter(data?.stats?.total_asset_value || 0)}`)
    ],
    [
      "Outstanding Loan",
      skeleton(`$${numFormatter(data?.stats?.outstanding_loan || 0)}`)
    ],
    ["Equity", skeleton(`$${numFormatter(data?.stats?.equity || 0)}`)],
    [
      data?.entity_type == "INVESTOR" ? "Current Value" : null,
      data?.entity_type == "INVESTOR"
        ? skeleton(`$${numFormatter(data?.stats?.current_value || 0)}`)
        : null
    ]
  ];

  return (
    <>
      <Grid
        className={`${styles.basicDetailsContainer} ${styles.detailWrapper}`}
        container>
        <Grid>
          <Typography className={styles.cardHeading}>
            General Information
          </Typography>
        </Grid>
        <Grid className={styles.generalInformationData} container>
          {heading.map((value, index) => {
            return (
              <GeneralInformation
                key={index}
                tableType={data?.entity_type}
                heading={value[0]}
                value={value[1]}
                toolData={toolData}
                managedBy={skeleton(data?.entity_type_id?.managed_by)}
                entityType={skeleton(data?.entity_type)}
              />
            );
          })}
        </Grid>
      </Grid>
      <Grid
        justifyContent="space-between"
        container
        className={styles.overviewPartnerTableContainer}>
        <Grid className={styles.uploadedDocument} sm={12} md={6} item>
          <Typography className={styles.cardHeading}>
            Uploaded Documents
          </Typography>
          <DocumentsTable
            heading={documentTableHeading}
            data={obj}
            tableType="document"
            value={data}
          />
        </Grid>
        {/* <Grid xs={1}></Grid> */}
        <Grid item sm={12} md={6}>
          <Typography className={styles.cardHeading}>Partners</Typography>
          <PartnerTable
            heading={partnersTableHeading}
            tableType="partners"
            data={data?.partners}
            value={data}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Overview;
