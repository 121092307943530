import { Box, Grid, InputAdornment } from "@material-ui/core";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import React, { useEffect, useRef, useState } from "react";
import CadenceSelect from "./components/CadenceSelect";
import DynamicTable from "./components/DynamicTable";
import ErrorIcon from "assets/icons/ErrorIcon.svg";
// import FieldHeading from "components/fieldHeading";
// import TextArea from "components/TextArea";

const Section2 = ({
  styles,
  formik,
  index,
  investorList,
  cadenceData,
  selectedCurrentYear,
  invData,
  invDocCategories,
  cadanceToggle
}) => {
  const [selectedTimeFrame, setSelectedTimeFrame] = useState([]);
  const [cadenceList, setCadenceList] = useState([]);
  const prevSelectedYear = useRef("");
  const distributionErrors =
    (formik.errors.distributionSet &&
      formik.errors.distributionSet.length &&
      formik.errors.distributionSet[index]) ||
    {};
  const distributionTouched =
    (formik.touched.distributionSet &&
      formik.touched.distributionSet.length &&
      formik.touched.distributionSet[index]) ||
    {};

  const setData = (cadenceValues) => {
    setCadenceList(cadenceValues);
    let cadenceValueList = {};
    cadenceValues.forEach((item) => {
      cadenceValueList[item.value] = "0";
    });
    let cadenceDocsList = {};
    cadenceValues.forEach((item) => {
      cadenceDocsList[item.value] = [];
    });
    let cadenceTypeList = {};
    cadenceValues.forEach((item) => {
      cadenceTypeList[item.value] = invDocCategories[0]?.id;
    });
    let investors = investorList.map(() => ({
      isSelected: false,
      value: { ...cadenceValueList },
      docs: { ...cadenceDocsList },
      type: { ...cadenceTypeList }
    }));
    formik.setFieldValue(`distributionSet[${index}].investors`, investors);
    formik.setFieldValue(`distributionSet[${index}].investorSum`, {
      ...cadenceValueList
    });
  };
  useEffect(() => {
    if (
      formik.values.distributionSet[
        index
      ]?.investmentData?.distribution_cadence?.toLowerCase() === "monthly"
    ) {
      setData(cadenceData.MONTH_CHOICES);
    } else {
      setData(cadenceData.QUARTER_CHOICES);
    }
  }, [cadanceToggle]);

  useEffect(() => {
    if (prevSelectedYear.current === selectedCurrentYear[index]) {
      return;
    }

    if (selectedCurrentYear[index]) {
      if (
        formik?.values?.distributionSet[
          index
        ]?.investmentData?.distribution_cadence?.toLowerCase() === "monthly"
      ) {
        setData(
          cadenceData.MONTH_CHOICES.slice(
            formik.values.distributionSet[index].distMonth - 1
          )
        );
      } else {
        let sliceIndex =
          cadenceData.MONTH_QUARTER_MAPPING[
            formik.values.distributionSet[index].distMonth - 1
          ]?.label;
        sliceIndex = Number(sliceIndex) - 1;
        setData(cadenceData.QUARTER_CHOICES.slice(sliceIndex));
      }
    } else {
      if (
        formik.values.distributionSet[
          index
        ]?.investmentData?.distribution_cadence?.toLowerCase() === "monthly"
      ) {
        setData(cadenceData.MONTH_CHOICES);
      } else {
        setData(cadenceData.QUARTER_CHOICES);
      }
    }
    prevSelectedYear.current = selectedCurrentYear[index];
  }, [selectedCurrentYear]);

  return (
    <>
      <Grid container className={styles.innerContainer} spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <Grid
            className={styles.sectionTitle}
            style={{ paddingBottom: "20px" }}>
            Total Distribution
          </Grid>
          <AppTextFieldWhite
            fullWidth
            InputLabelProps={{
              shrink: true
            }}
            numFormat={true}
            onWheel={(e) => e.target.blur()}
            value={formik.values.distributionSet[index]?.amountSum}
            onChange={(e) =>
              formik.setFieldValue(
                `distributionSet[${index}].totalDistribution`,
                e.target.value
              )
            }
            error={
              distributionTouched.totalDistribution &&
              Boolean(distributionErrors.totalDistribution)
            }
            helperText={
              distributionTouched.totalDistribution &&
              distributionErrors.totalDistribution
            }
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <div style={{ fontSize: 16, fontWeight: "bold" }}>$</div>
                </InputAdornment>
              )
            }}
            disabled={true}
          />
          <Box height={20} />
        </Grid>

        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>
            Select Months for distributions
          </div>
        </div>
        <CadenceSelect
          list={cadenceList}
          formik={formik}
          id={index}
          setSelectedTimeFrame={setSelectedTimeFrame}
          selectedTimeFrame={selectedTimeFrame}
        />
        <DynamicTable
          cadenceType={invData.cadence}
          selectedTimeFrame={selectedTimeFrame}
          formik={formik}
          data={investorList}
          index={index}
          invDocCategories={invDocCategories}
        />
        {distributionErrors.investorsSelected &&
        distributionTouched.investorsSelected ? (
          <div className={styles.errorText}>
            <img src={ErrorIcon} alt="" />
            <div>{distributionErrors.investorsSelected}</div>
          </div>
        ) : distributionErrors.amountSum && distributionTouched.amountSum ? (
          <div className={styles.errorText}>
            <img src={ErrorIcon} alt="" />
            <div>{distributionErrors.amountSum}</div>
          </div>
        ) : null}
      </Grid>
    </>
  );
};

export default Section2;
