// default app theme and colors
import { createTheme } from "@material-ui/core/styles";

export const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#5775E5"
    },
    secondary: {
      main: "#445EBE"
    },
    pink: { main: "#FA8072" },
    midPink: { main: "#FFB0A7- #FA8072" },
    lightPink: { main: "#FFE6E4" },
    green: { main: "#55A55E" },
    lightGreen: { main: "#EDFFEF" },
    purple: { main: "#5E5499" },
    lightPurple: { main: "#F4F2FF" },
    blue1: { main: "#445EBE" },
    lightBlue: { main: "#EBEFFF" },
    error: { main: "#E13838" },
    complementing_Error: { main: "#FCEBEB" },
    success: { main: "#50E138" },
    complementing_Success: { main: "#EDFFEF" },

    black: { main: "#191919" },
    grey: { main: "#616161" },
    midGrey: { main: "#BBBBBB" },
    lightGrey: { main: "#E9E9E9" },

    white: { main: "#ffffff" }
  },
  typography: {
    fontFamily: "Inter Regular",
    h1: {
      fontSize: 54,
      lineHeight: 74 / 34,
      fontWeight: 700,
      fontFamily: "Inter Bold"
    },
    h2: {
      fontSize: 46,
      lineHeight: 68 / 30,
      fontWeight: 600,
      fontFamily: "Inter Bold"
    },
    h3: {
      fontSize: 34,
      lineHeight: 46,
      fontWeight: 800,
      fontFamily: "Inter Bold"
    },
    h4: {
      fontSize: 28,
      lineHeight: 40 / 24,
      fontWeight: 700,
      fontFamily: "Inter Bold"
    },
    h5: {
      fontSize: 18,
      lineHeight: 24 / 20,
      fontWeight: 700,
      fontFamily: "Inter SemiBold"
    },
    h6: {
      fontSize: 16,
      lineHeight: 22,
      fontWeight: 400,
      ontFamily: "Inter Regular"
    },
    h7: {
      fontSize: 14,
      lineHeight: 24 / 18,
      fontWeight: 500,
      ontFamily: "Inter Regular"
    },
    p1: {
      fontSize: 16,
      lineHeight: 24 / 15,
      fontWeight: 500
    },
    p2: {
      fontSize: 16,
      lineHeight: 22 / 14,
      fontWeight: 400
    },
    p3: {
      fontSize: 18,
      lineHeight: 22 / 14,
      fontWeight: 800
    },
    button: {
      fontSize: 14,
      lineHeight: 18 / 13,
      letterSpacing: 0.2,
      fontWeight: 700,
      textTransform: "unset"
    },
    c1: {
      fontSize: 13,
      lineHeight: 20 / 13,
      fontWeight: 500
    },
    c2: {
      fontSize: 12,
      lineHeight: 17 / 12,
      fontWeight: 600
    },
    headers: {
      fontSize: 34,
      lineHeight: 46,
      fontWeight: 800,
      fontFamily: "Inter Bold"
    }
  },

  shadows: ["none", "none"],
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: 8
      }
    },
    MuiPickersToolbar: {
      fontFamily: "Inter Regular",
      height: "48",
      toolbar: {
        backgroundColor: "#5775E5"
      }
    },
    MuiPickersDay: {
      day: {
        color: "#616161"
      },
      daySelected: {
        backgroundColor: "#5775E5"
      },
      dayDisabled: {
        color: "#d4d4d4"
      },
      current: {
        color: "#5775E5"
      }
    }
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "Inter Regular"
        }
      }
    }
  }
});
