import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  //   Tooltip,
  Grid
  // Typography
} from "@material-ui/core";
import Illustrations from "components/illustration/Illustrations";
import LightTooltip from "components/LightTooltip";
import NewDropDown from "components/NewDropDown";
import { truncate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import React, { useState } from "react";
import styles from "../../../pages/private/entities/detail/EntityDetail.module.scss";
// import InvestmentToolTipData from "../Investment/InvestmentToolTipData";
import propertiesEmptyScreen from "assets/emptyState/noProperties.svg";
import InvestmentToolTipData from "pages/private/entities/detail/section/Investment/InvestmentToolTipData";
import { numFormatter } from "helpers";
import { AppDispatcher } from "../../../redux/index";
import { useHistory } from "react-router-dom";

function PropertiesTable({ data, heading, dropDownOpRes }) {
  const router = useHistory();

  const [expandIndex, setExpandIndex] = useState(-1);
  let setLoanType = {};

  dropDownOpRes?.PROPERTY_TYPE.map((item) => {
    setLoanType[item?.value] = item?.label;
  });

  const handleCellClick = (name, id) => {
    if (!id) {
      return;
    }
    if (name === "none") {
      return;
    }
    if (name === "investment_id") {
      AppDispatcher.setInvestmentDetailID(id);
      router.push("/u/investments/detail");
    } else if (name === "sponsor_id") {
      router.push(`/user/sponsors/sponsorsDetails/${id}`);
    } else if (name === "property_id") {
      AppDispatcher.setPropertiesDetailID(id);
      router.push(`/u/properties/detail`);
    } else if (name === "entity_id") {
      AppDispatcher.setEntityId(id);
      router.push("/u/entities/detail");
    }
  };

  const propertiesData = data?.map((investment) => {
    return investment?.properties.map((item) => {
      return {
        0: item?.name,
        1: item?.managed_by,
        2: formatCurrency(item?.value) || "-",
        3: item?.loan_id,
        4: item?.area ? numFormatter(item?.area) + " " + item?.units : "-",
        5: setLoanType[item?.type] || "-",
        6: item?.total_units || "-",
        7: item?.occupancy || 0,
        8: item?.investment_status || "-",
        dataName: investment?.investment?.name || investment?.name,
        baseID: item?.base_id,
        units: item?.units,
        propertyId: item?.id
      };
    });
  });

  let setLoanHead = 0;
  propertiesData?.map((value) => {
    if (value?.length > 0) {
      setLoanHead = 1;
    }
  });

  return (
    <>
      {propertiesData?.length != 0 ? (
        <TableContainer
          component={Paper}
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff",
            padding: "20px"
          }}>
          <Table sx={{ minWidth: 650 }} aria-label="sticky table">
            {setLoanHead == 1 ? (
              <TableHead>
                <TableRow>
                  {heading.map((value, index) => {
                    return (
                      <TableCell key={index} className={styles.noiTableHeader}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : (
              <Illustrations
                src={propertiesEmptyScreen}
                heading="No Property found"
                // subHeading="Add Investment Data To View"
              />
            )}
            <TableBody>
              {propertiesData?.map((values, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      {values.length > 0 ? (
                        <TableCell
                          style={{ border: "none" }}
                          align="center"
                          colSpan={12}>
                          <div className={styles.tableGrouping}>
                            {" "}
                            {truncate(values[0]["dataName"], 40)}
                          </div>
                        </TableCell>
                      ) : null}
                    </TableRow>
                    {values.map((val, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 }
                          }}>
                          {heading.map((item, indx) => {
                            return (
                              <LightTooltip
                                key={indx}
                                title={
                                  <InvestmentToolTipData
                                    data={[["Property ID: ", val["baseID"]]]}
                                  />
                                }>
                                {typeof val[indx] === "object" ? (
                                  <TableCell
                                    style={{ border: "none", width: "150px" }}>
                                    {val[indx].length > 0 ? (
                                      <span
                                        style={{
                                          color: "#BD54B9",
                                          backgroundColor:
                                            "rgba(189, 84, 185, 0.05)"
                                        }}>
                                        <NewDropDown
                                          index={indx}
                                          item={
                                            val[indx]?.map((item) => {
                                              return {
                                                id: null,
                                                name: item?.base_id
                                              };
                                            }) || []
                                          }
                                          // handleCellItemClick={(name, id) => handleCellClick(name, id)}
                                          // type="sponsor_id"
                                          expandIndex={expandIndex}
                                          setExpandIndex={setExpandIndex}
                                        />
                                      </span>
                                    ) : (
                                      "-"
                                    )}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    style={{ border: "none" }}
                                    component="th"
                                    scope="row">
                                    <Grid
                                      onClick={() => {
                                        handleCellClick(
                                          "property_id",
                                          val["propertyId"]
                                        );
                                      }}
                                      style={
                                        indx == 0
                                          ? {
                                              cursor: "pointer"
                                            }
                                          : null
                                      }
                                      className={[
                                        indx === 0 && styles.firstCell,
                                        indx == 8 && val["dataName"]
                                          ? val[indx] != "-" &&
                                            (val[indx] === "Draft"
                                              ? styles.draftCell
                                              : val[indx] == "Active"
                                              ? styles.activeCell
                                              : styles.inActiveCell)
                                          : null
                                      ]}>
                                      {truncate(val[indx], 26)}{" "}
                                      {item === "Occupancy" && "%"}
                                      {item === "Area" && val["units"]}
                                    </Grid>
                                  </TableCell>
                                )}
                              </LightTooltip>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff",
            width: "100vw"
          }}>
          <Illustrations
            src={propertiesEmptyScreen}
            heading="No property found"
            // subHeading="Add Investment Data To View"
          />
        </Grid>
      )}
    </>
  );
}

export default PropertiesTable;
