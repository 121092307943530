import * as React from "react";
import { useState, useEffect } from "react";
import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import { Typography, makeStyles, MenuItem, Select } from "@material-ui/core";
import DotsMenu from "../../../../assets/icons/dotsMenu.svg";
import styles from "./changeStatus.module.scss";
import { useHistory } from "react-router-dom";
import { NetworkManager, Endpoint } from "../../../../network/core/index";
import { useSelector } from "react-redux";
import Toast from "../../../../components/toast";
import Loader from "../../../../components/Loader/AppLoader";
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import { EntityService } from "network/entityService";

const useHeaderStyle = makeStyles(() => ({
  editProfileText: {
    fontFamily: "Inter Bold",
    fontSize: 28
  },
  subheading: {
    fontSize: 16,
    fontFamily: "Inter Regular",
    color: "#616161"
  },
  heading: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    marginBottom: 10
  }
}));

const EntitiesStatus = (setOpenModal) => {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState([]);
  const history = useHistory();
  const [currentStatus, setCurrentStatus] = useState("-");
  const [initValues, setInitValues] = useState({
    first_name: "-",
    last_name: "-",
    tax_id: "-",
    status: "-"
  });

  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(setOpenModal);
  };

  const { entityId } = useSelector((store) => store.app);

  const fetchUserDetail = async () => {
    setLoading(true);
    const instance = new NetworkManager(
      new Endpoint(`entity/${entityId}`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      let status = "";
      let temp = resp.data;
      let tempData = { ...initValues };
      tempData["first_name"] = temp.name;
      tempData["tax_id"] = temp.tax_id;
      tempData["status"] = temp.status;
      setInitValues(tempData);
      setCurrentStatus(temp.status);
      temp.status === "Active" ? (status = "Inactive") : (status = "Active");
      setStatus([{ id: 1, value: status }]);
    } else {
      Toast.showErrorToast("Failed to load Entity details");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserDetail();
  }, []);

  const classes = useHeaderStyle();
  const handleClose = () => {
    setOpen(false);
    history.push("/u/entities");
  };

  const handleStatusChange = (e) => {
    let tempData = { ...initValues };
    tempData["status"] = e.target.value;
    setInitValues(tempData);
  };

  const updateStatus = async () => {
    setLoading(true);
    let active = null;
    if (initValues.status === "Active") {
      active = true;
    } else {
      active = false;
    }
    const payload = {
      id: entityId,
      active: active
    };
    const resp = await EntityService.editEntity(payload, [`${entityId}/`]);
    if (resp.success) {
      setOpen(false);
      history.push("/u/entities");
      Toast.showSuccessToast("Status Updated Successfully");
    } else {
      Toast.showErrorToast(resp.error.message[0]);
    }
    setLoading(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <img src={DotsMenu} style={{ height: 20, width: 20 }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{
          backgroundColor: "rgba(235, 239, 255, 0.6)",
          backdropFilter: "blur(26)"
        }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <DialogContent>
              <Grid
                container
                style={{ padding: 40, width: 458 }}
                justifyContent="center">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4">Change Status</Typography>
                  </Grid>
                  <Grid item>
                    <img
                      src={CloseIcon}
                      onClick={handleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginTop: 40 }}>
                  <Grid item>
                    <Typography className={classes.heading}>
                      Entity Name
                    </Typography>
                    <Typography variant="h5">
                      {initValues.first_name}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.heading}>Tax ID</Typography>
                    <Typography variant="h5">{initValues.tax_id}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginTop: 20 }}>
                  <Grid item>
                    <Typography className={classes.heading}>
                      Current Status
                    </Typography>
                    <Typography
                      variant="h5"
                      style={{
                        minHeight: 28,
                        backgroundColor:
                          currentStatus === "Pending"
                            ? "#EBEFFF"
                            : currentStatus == "Active"
                            ? "#EDFFEF"
                            : "#FCEBEB ",
                        borderRadius: 153,
                        fontFamily: "Inter Bold",
                        fontSize: 14,
                        paddingLeft: 14,
                        paddingRight: 14,
                        paddingTop: 4,
                        textAlign: "center",
                        color:
                          currentStatus == "Active"
                            ? "#50E138"
                            : currentStatus === "Pending"
                            ? "#445EBE"
                            : "#E13838",
                        fontWeight: 700
                      }}>
                      {currentStatus}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  xs={12}
                  style={{ marginTop: 40 }}>
                  <Typography className={classes.heading}>
                    Change Status To
                  </Typography>
                  <Select
                    id="dropdown"
                    value={initValues.status}
                    onChange={(e) => handleStatusChange(e)}
                    disableUnderline={true}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    style={{
                      fontFamily: "Inter Regular",
                      fontWeight: 600,
                      fontSize: 16,
                      height: 48,
                      border: "1px solid #BBBBBB",
                      borderRadius: 8,
                      paddingLeft: 10,
                      paddingRight: 10
                    }}>
                    {status.map((item) => (
                      <MenuItem
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = "#F5F7FB")
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = "#ffffff")
                        }
                        key={item.id}
                        value={item.value.toString()}
                        style={{
                          fontSize: 14,
                          fontFamily: "Inter Regular",
                          marginTop: 5
                        }}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid container xs={12} justifyContent="center" direction="row">
                  <div className={styles.changepasswordbutton}>
                    <button
                      type="submit"
                      className={styles.updatebtn}
                      onClick={updateStatus}>
                      Save
                    </button>
                    <button
                      className={styles.cancelbtn}
                      type="button"
                      onClick={handleClose}>
                      Cancel
                    </button>
                  </div>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default EntitiesStatus;
