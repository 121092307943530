import React, { useEffect, useState } from "react";
import { Grid, InputAdornment, makeStyles } from "@material-ui/core";
import FieldHeading from "../../../../../../../components/fieldHeading";
import AddIcon from "assets/icons/addIcon.svg";
import collapseIconRoundRed from "assets/icons/collapseIconRoundRed.svg";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import Calender from "../../../../../../../components/calender";
import CustomSelect from "../../../../../../../components/StyledComponents/Select";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import Toast from "components/toast";
import DeleteModal from "components/Modals/DeleteModal";
import EditIcon from "assets/icons/EditIcon.svg";
import RedDelete from "assets/icons/NewCardSvg/RedDelete.svg";
import NewCategoryDocTable from "pages/private/properties/component/NewCategoryDocTable";
import K1 from "./K1";
import { FieldArray } from "formik";
import NewAutoComplete from "components/NewAutoComplete/NewAutoComplete";
import { EntityService } from "network/entityService";
import InvestorEntityDilogBox from "./Investor/InvestorEntityDilogBox";
import InvestorDilogBox from "./Investor/InvestorDilogBox";
import DescriptionBox from "components/StyledComponents/DescriptionBox";
import { useSelector } from "react-redux";
import { preventNonNumericalInput } from "helpers";
import formatCurrency from "helpers/formatCurrency";
import ViewHistoryPopup from "./InvViewHistory";

const useStyles = makeStyles(() => ({
  mainContainer: {
    cursor: "pointer",
    padding: "16px",
    "&:hover": {
      background: "#F6F8FD"
    }
  },
  email: {
    fontWeight: 400,
    fontSize: " 16px",
    lineHeight: "22px",
    color: "#000000"
  },
  lighttext: {
    padding: "3px 0px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#616161"
  },
  lightTextOverflow: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  darktext: {
    color: "#000000"
  },
  history: {
    cursor: "pointer",
    fontFamily: "Inter semiBold",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#445EBE"
  }
}));

const InvestorForm = ({
  classes,
  index,
  formik,
  arrayHelper,
  IsEditCase,
  docCategories
}) => {
  const EquityAmount = useSelector((state) => state.app.equity_amount);
  /*************Delete This Investor***************** */
  const checkDelete = () => {
    if (formik.values.investorSet[index].id) {
      setDeleteModal(true);
    } else {
      arrayHelper.remove(index);
    }
  };

  const handleDelete = async () => {
    const res = await AddInvestmentInvestorService.deleteInvestor(
      formik.values.investorSet[index].id
    );
    if (res.success) {
      setDeleteModal(false);
      arrayHelper.remove(index);
    } else {
      setDeleteModal(false);
      return Toast.showErrorToast(res.error.message[0]);
    }
  };
  /*******************************/

  const dropDownStyle = useStyles();
  const [investorDilogBox, setInvestorDilogBox] = useState();
  const [investorEntityDilogBox, setInvestorEntityDilogBox] = useState(false);
  const [addInvestorEntity, setAddInvestorEntity] = useState(false);
  const investmentTypes = [
    { label: "New", value: "new" },
    { label: "Rollover", value: "rollover" }
  ];
  const [inv_email, setInvEmail] = useState();
  const [inv_id, setInvId] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [showAddMore, setShowAddMore] = useState("");
  const [taxIDsearch, SettaxIDsearch] = useState("");
  const [Emailsearch, SetEmailSearchQuery] = useState("");
  const [data, setData] = useState([]);
  const [DocCategoryK1, setK1DocCategoryK1] = useState([]);
  const [view_his_open, Set_view_his_open] = useState(false);
  const [showhistoryBtn, setShowHistoryBtn] = useState(false);
  const [isInvetsorEdit, setIsInvestorEdit] = useState(false);

  /**************************************************** */
  /********** fetch dropdown list data ********** */
  /**************************************************** */

  const getEmailList = async () => {
    let url = "";
    if (Emailsearch) {
      url = `?search=${Emailsearch}`;
    }

    const response = await EntityService.getInvestorEmailList([url]);
    if (response.success) {
      setData(response.data);
    } else {
      setData([]);
    }
  };

  const getEntityTaxId = async () => {
    let url = "";
    if (taxIDsearch) {
      url = `?entity_type_id=${inv_id}&search=${taxIDsearch}`;
      const response = await AddInvestmentInvestorService.getEntities([url]);
      if (response.success) {
        setData(response?.data?.results);
      } else {
        setData([]);
      }
    }
  };

  /**************************************************** */
  /*********handle DropDown onClick******************** */
  /**************************************************** */

  const handleEmaildropFromData = (item) => {
    if (
      formik.values?.investorSet?.findIndex(
        (ele) => ele?.investor_id === item.id
      ) === -1
    ) {
      SetEmailSearchQuery("");
      formik.setFieldValue(
        `investorSet[${index}].investor_name`,
        ` ${item.first_name} ${item.last_name}`
      );
      formik.setFieldValue(`investorSet[${index}].investor_id`, item.id);
      setInvEmail(item.email);
      setInvId(item.id);
    } else {
      Toast.showErrorToast(`Investor Already Selected`);
    }
  };

  const handleTaxIDFromData = (item) => {
    SettaxIDsearch("");

    formik.setFieldValue(`investorSet[${index}].entity_id`, item.id);
    formik.setFieldValue(`investorSet[${index}].taxId`, item.tax_id);
    formik.setFieldValue(`investorSet[${index}].entity_name`, item?.name);
    formik.setFieldValue(
      `investorSet[${index}].mailing_address`,
      item?.address?.[0].address
    );
  };

  /**************************************************** */
  /********* Set Fetched data to drop down ************ */
  /**************************************************** */

  const EmaildataList = data?.map((item) => (
    <div
      className={dropDownStyle.mainContainer}
      onClick={() => handleEmaildropFromData(item)}>
      <div
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        Investor Email:
        <span className={dropDownStyle.darktext}> {item.email}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        Investor Name:
        <span
          className={
            dropDownStyle.darktext
          }>{` ${item.first_name} ${item.last_name}`}</span>
      </div>
    </div>
  ));

  const TaxIdList = data?.map((item) => (
    <div
      className={dropDownStyle.mainContainer}
      onClick={() => handleTaxIDFromData(item)}>
      <div
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        Tax ID (EIN):
        <span className={dropDownStyle.darktext}>{` ${item?.tax_id}`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        Entity Name:
        <span className={dropDownStyle.darktext}>{` ${
          item?.name || "-"
        }`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        Investor Email ID:
        <span className={dropDownStyle.darktext}>{` ${
          item?.entity_type_id?.email || "-"
        }`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        {`Mailing Address`}:
        <span className={dropDownStyle.darktext}>{` ${
          item?.address?.[0]?.address || "-"
        }`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        Investor Name:
        <span className={dropDownStyle.darktext}>{` ${
          item?.entity_type_id?.name || "-"
        }`}</span>
      </div>

      <div className={dropDownStyle.lighttext}>
        Managed By:
        <span className={dropDownStyle.darktext}> {item.managed_by}</span>
      </div>

      <div className={dropDownStyle.lighttext}>
        Partners:
        <span className={dropDownStyle.darktext}>
          {item?.partners?.map((ele, i) => {
            if (i === 0) {
              return ele.name;
            } else {
              return `,${ele.name}`;
            }
          })}
        </span>
      </div>
    </div>
  ));
  /**************************************************** */
  /**************************************************** */

  useEffect(() => {
    getEmailList();
    SettaxIDsearch("");
  }, [Emailsearch]);

  useEffect(() => {
    getEntityTaxId();
    SetEmailSearchQuery("");
  }, [taxIDsearch]);

  useEffect(() => {
    setInvId(formik.values.investorSet[index].investor_id);
    setIsInvestorEdit(formik.values.investorSet[index].isInvestorEdited);
  }, [
    formik.values.investorSet[index].investor_id,
    formik.values.investorSet[index].isInvestorEdited
  ]);

  useEffect(() => {
    if (showAddMore === "Entity") {
      setInvestorEntityDilogBox(true);
    }
    if (showAddMore === "Investor") {
      setInvestorDilogBox(true);
    }
  }, [showAddMore]);

  /**************************/

  const ClickonaddaddInvestorEntity = () => {
    if (inv_id) {
      setAddInvestorEntity(!addInvestorEntity);
    } else {
      Toast.showErrorToast(`First Select Investor From DropDown`);
    }
  };

  const setDocData = (data) => {
    formik.setFieldValue(`investorSet[${index}].documents`, data);
  };

  const K1doccCategory = async () => {
    const invCategories = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENT"
    });
    if (invCategories.success) {
      const catId = invCategories.data[0].doccategory.filter(
        (item) => item.name === "K-1"
      );
      if (catId.length) {
        setK1DocCategoryK1(catId);
      }
    } else {
      Toast.showErrorToast("Failed To Load Document Categories");
    }
  };

  useEffect(async () => {
    K1doccCategory();
  }, []);

  function Setequity_amount() {
    let value =
      (EquityAmount * formik.values.investorSet[index].membership) / 100;
    return formatCurrency(value).split(".")?.[0];
  }
  useEffect(() => {
    Setequity_amount();
  }, [formik.values.investorSet[index].membership]);

  useEffect(() => {
    setShowHistoryBtn(true);
  }, [IsEditCase]);

  const investorErrors =
    (formik.errors.investorSet &&
      formik.errors.investorSet.length &&
      formik.errors.investorSet[index]) ||
    {};
  const investorTouched =
    (formik.touched.investorSet &&
      formik.touched.investorSet.length &&
      formik.touched.investorSet[index]) ||
    {};

  return (
    <>
      <InvestorDilogBox
        investorDilogBox={investorDilogBox}
        setInvestorDilogBox={setInvestorDilogBox}
        setShowAddMore={setShowAddMore}
        formik={formik}
        index={index}
        SetEmailSearchQuery={SetEmailSearchQuery}
        setInvEmail={setInvEmail}
      />

      <InvestorEntityDilogBox
        inv_id={inv_id}
        inv_email={inv_email}
        investorEntityDilogBox={investorEntityDilogBox}
        setInvestorEntityDilogBox={setInvestorEntityDilogBox}
        setShowAddMore={setShowAddMore}
        formik={formik}
        index={index}
        SettaxIDsearch={SettaxIDsearch}
      />

      <ViewHistoryPopup
        isopen={view_his_open}
        setIsOpen={Set_view_his_open}
        history={
          formik.values.investorSet[index]?.invested_amount_history?.length
            ? formik.values.investorSet[index]?.invested_amount_history
            : []
        }
      />

      <Grid xs={12}>
        <Grid container alignItems="center" item xs={12}>
          <div className={classes.title}>{`Investor ${index + 1}`}</div>
          {IsEditCase ? (
            <div
              style={{ marginLeft: 20, cursor: "pointer" }}
              onClick={() =>
                formik.setFieldValue(
                  `investorSet[${index}].isInvestorEdited`,
                  true
                )
              }>
              <img src={EditIcon} alt="edit" />
            </div>
          ) : null}
        </Grid>
        <Grid container item xs={12} className={classes.field_con}>
          <Grid container item xs={12} spacing={2}>
            {/* New Investor UI */}
            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Investor Name" />
              <NewAutoComplete
                // setExistingEmailSelected={setExistingEmailSelected}
                // setClickedOnAddbtn={setClickedOnAddbtn}
                disabled={IsEditCase && !isInvetsorEdit}
                setShowAddMore={setShowAddMore}
                placeholder="Add Investor Name"
                search={Emailsearch}
                setSearchQuery={SetEmailSearchQuery}
                dataList={EmaildataList}
                formik={formik}
                fieldValue={formik.values.investorSet[index].investor_name}
                fieldValueError={
                  investorTouched.investor_name &&
                  Boolean(investorErrors.investor_name)
                }
                setFieldValue={(data) => {
                  formik.setFieldValue(
                    `investorSet[${index}].investor_name`,
                    data
                  );
                  formik.setFieldValue(`investorSet[${index}].investor_id`, "");
                }}
                btnText="Investor"
              />
              <div
                style={{ fontSize: "12px", fontWeight: 400, color: "#E13838" }}>
                {investorTouched.investor_name && investorErrors.investor_name}{" "}
                {investorTouched.investor_id && investorErrors.investor_id}
              </div>
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Membership (%)" />
              <AppTextFieldWhite
                disabled={IsEditCase && !isInvetsorEdit}
                id={`membership`}
                placeholder="Add Membership"
                variant="standard"
                onKeyDown={preventNonNumericalInput}
                inputProps={{ maxLength: 5 }}
                fullWidth
                value={formik.values.investorSet[index].membership}
                onChange={formik.handleChange(
                  `investorSet[${index}].membership`
                )}
                error={
                  investorTouched.membership &&
                  Boolean(investorErrors.membership)
                }
                helperText={
                  investorTouched.membership && investorErrors.membership
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Total Invested Amount($)" />
              <AppTextFieldWhite
                disabled={IsEditCase && !isInvetsorEdit}
                id={`total_invested_amount`}
                placeholder="Add Total Invested Amount"
                variant="standard"
                fullWidth
                value={formik.values.investorSet[index].total_invested_amount}
                onChange={formik.handleChange(
                  `investorSet[${index}].total_invested_amount`
                )}
                error={
                  investorTouched.total_invested_amount &&
                  Boolean(investorErrors.total_invested_amount)
                }
                helperText={
                  investorTouched.total_invested_amount &&
                  investorErrors.total_invested_amount
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  disableUnderline: true,
                  ...(!IsEditCase || showhistoryBtn
                    ? {
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={() => {
                              Set_view_his_open(true);
                            }}>
                            <p className={dropDownStyle.history}>
                              {" "}
                              View History
                            </p>
                          </InputAdornment>
                        )
                      }
                    : null)
                }}
                onKeyDown={preventNonNumericalInput}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="As On" />
              <Calender
                disabled={IsEditCase && !isInvetsorEdit}
                id={`investorSet[${index}].asOn`}
                defaultDate={formik.values.investorSet[index].asOn}
                setDate={(date) =>
                  formik.setFieldValue(`investorSet[${index}].asOn`, date)
                }
                error={investorTouched.asOn && Boolean(investorErrors.asOn)}
                helperText={investorTouched.asOn && investorErrors.asOn}
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Commited Investment($)" />
              <AppTextFieldWhite
                disabled={IsEditCase && !isInvetsorEdit}
                id={`commited_investment`}
                placeholder="Add Commited Investment"
                variant="standard"
                fullWidth
                value={formik.values.investorSet[index].commited_investment}
                onChange={formik.handleChange(
                  `investorSet[${index}].commited_investment`
                )}
                error={
                  investorTouched.commited_investment &&
                  Boolean(investorErrors.commited_investment)
                }
                helperText={
                  investorTouched.commited_investment &&
                  investorErrors.commited_investment
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
                onKeyDown={preventNonNumericalInput}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Preferred Return (%)" />
              <AppTextFieldWhite
                disabled={IsEditCase && !isInvetsorEdit}
                id={`preferred_return`}
                placeholder="Add Preferred Return"
                variant="standard"
                fullWidth
                value={formik.values.investorSet[index].preferred_return}
                onChange={formik.handleChange(
                  `investorSet[${index}].preferred_return`
                )}
                error={
                  investorTouched.preferred_return &&
                  Boolean(investorErrors.preferred_return)
                }
                helperText={
                  investorTouched.preferred_return &&
                  investorErrors.preferred_return
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
                onKeyDown={preventNonNumericalInput}
                inputProps={{ maxLength: 5 }}
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Last Date of Prefferred Return" />
              <Calender
                disabled={IsEditCase && !isInvetsorEdit}
                id={`investorSet[${index}].last_date_preferred_return`}
                defaultDate={
                  formik.values.investorSet[index].last_date_preferred_return
                }
                setDate={(date) =>
                  formik.setFieldValue(
                    `investorSet[${index}].last_date_preferred_return`,
                    date
                  )
                }
                error={
                  investorTouched.last_date_preferred_return &&
                  Boolean(investorErrors.last_date_preferred_return)
                }
                helperText={
                  investorTouched.last_date_preferred_return &&
                  investorErrors.last_date_preferred_return
                }
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Date of Investment" />
              <Calender
                disabled={IsEditCase && !isInvetsorEdit}
                id={`investorSet[${index}].date_of_investment`}
                defaultDate={
                  formik.values.investorSet[index].date_of_investment
                }
                setDate={(date) =>
                  formik.setFieldValue(
                    `investorSet[${index}].date_of_investment`,
                    date
                  )
                }
                error={
                  investorTouched.date_of_investment &&
                  Boolean(investorErrors.date_of_investment)
                }
                helperText={
                  investorTouched.date_of_investment &&
                  investorErrors.date_of_investment
                }
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Investment Type" />
              <CustomSelect
                disabled={IsEditCase && !isInvetsorEdit}
                id="type"
                placeholder="Type"
                fullWidth
                items={investmentTypes}
                defaultValue={formik.values.investorSet[index].investment_type}
                onChange={formik.handleChange(
                  `investorSet[${index}].investment_type`
                )}
                error={investorErrors.investment_type}
                touched={investorTouched.investment_type}
              />
            </Grid>

            {addInvestorEntity && (
              <>
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Tax ID (EIN) or Investor Entity" />
                  <NewAutoComplete
                    disabled={IsEditCase && !isInvetsorEdit}
                    placeholder=" Add Tax ID/Entity"
                    // setExistingEmailSelected={setExistingEmailSelected}
                    // setClickedOnAddbtn={setClickedOnAddbtn}
                    setShowAddMore={setShowAddMore}
                    btnText="Entity"
                    search={taxIDsearch}
                    setSearchQuery={SettaxIDsearch}
                    dataList={TaxIdList}
                    formik={formik}
                    fieldValue={formik.values.investorSet[index].taxId}
                    setFieldValue={(data) =>
                      formik.setFieldValue(`investorSet[${index}].taxId`, data)
                    }
                  />
                </Grid>
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Entity Name" />
                  <AppTextFieldWhite
                    disabled={IsEditCase && !isInvetsorEdit}
                    id={`entity_name`}
                    placeholder="Add Entity Name"
                    variant="standard"
                    fullWidth
                    value={formik.values.investorSet[index].entity_name}
                    onChange={formik.handleChange(
                      `investorSet[${index}].entity_name`
                    )}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Grid>
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Mailing Address" />
                  <AppTextFieldWhite
                    disabled={IsEditCase && !isInvetsorEdit}
                    id={`mailing_address`}
                    placeholder="Add Mailing Address"
                    variant="standard"
                    fullWidth
                    value={formik.values.investorSet[index].mailing_address}
                    onChange={formik.handleChange(
                      `investorSet[${index}].mailing_address`
                    )}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Grid>
              </>
            )}

            <Grid item xl={3} lg={4} sm={6}>
              <div
                className={classes.addMore}
                onClick={() => ClickonaddaddInvestorEntity()}>
                <img
                  src={addInvestorEntity ? collapseIconRoundRed : AddIcon}
                  alt="add/Collapse"
                  style={{ marginRight: 8 }}
                />
                {addInvestorEntity ? (
                  <div style={{ color: "#E13838" }}>Collapse</div>
                ) : (
                  <div>Add Investor Entity</div>
                )}
              </div>
            </Grid>

            <Grid container item xs={12}>
              <Grid item sm={12} md={9}>
                <FieldHeading title="Notes" />
                <DescriptionBox
                  disabled={IsEditCase && !isInvetsorEdit}
                  id="description"
                  placeholder="Add Description"
                  variant="standard"
                  fullWidth
                  multiline
                  rows={2}
                  value={formik.values.investorSet[index].notes}
                  onChange={formik.handleChange(`investorSet[${index}].notes`)}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    maxLength: 100
                  }}
                  error={
                    formik.touched?.investorSet?.[index]?.notes &&
                    Boolean(formik.errors?.investorSet?.[index]?.notes)
                  }
                  helperText={
                    formik.touched?.investorSet?.[index]?.notes &&
                    formik.errors?.investorSet?.[index]?.notes
                  }
                />
                <Grid
                  container
                  style={{ color: "#589F11" }}
                  justifyContent="flex-end">
                  {`${formik.values.investorSet[index].notes?.length || 0}/100`}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.EquityStyle}>
                <div>
                  Net Equity:
                  <span className={classes.EquitySpan}>
                    {Setequity_amount()}
                  </span>
                </div>
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.Headings}>Upload Documents</div>
              <NewCategoryDocTable
                sponDocCategories={docCategories}
                listData={formik.values.investorSet[index].documents}
                setListData={(list) => setDocData(list)}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container alignItems="center">
                <div className={classes.Headings}>{`K1'S`}</div>
                {IsEditCase ? (
                  <div
                    style={{ marginLeft: 20, cursor: "pointer" }}
                    onClick={() =>
                      formik.setFieldValue(
                        `investorSet[${index}].isInvestorEdited`,
                        true
                      )
                    }>
                    <img src={EditIcon} alt="edit" />
                  </div>
                ) : null}
              </Grid>
              <FieldArray
                name={`investorSet[${index}].k1`}
                render={(arrayHelper) => (
                  <>
                    {formik.values.investorSet?.[index]?.k1?.map((k1, i) => (
                      <K1
                        key={i}
                        DocCategoryK1={DocCategoryK1}
                        arrayHelper={arrayHelper}
                        k1={k1}
                        parentIndex={index}
                        index={i}
                        formik={formik}
                        disabled={IsEditCase && !isInvetsorEdit}
                      />
                    ))}
                    <Grid container item xs={12} justifyContent="space-between">
                      <div
                        className={classes.addMoreButton}
                        style={{ paddingLeft: "0px" }}
                        onClick={() => {
                          arrayHelper.push({
                            type: "investment",
                            year: "",
                            documents: [],
                            notes: ""
                          });
                        }}>
                        <img src={AddIcon} alt="" height="20px" />
                        <span>Add More k1</span>
                      </div>
                    </Grid>
                    <Grid container item xs={12} justifyContent="flex-end">
                      {isInvetsorEdit ? (
                        <span
                          className={classes.deleteButton}
                          onClick={checkDelete}>
                          <img src={RedDelete} alt="Delete" />
                        </span>
                      ) : null}
                    </Grid>
                  </>
                )}
              />
            </Grid>
            {/* *************** */}
          </Grid>
        </Grid>

        <DeleteModal
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          handleSubmit={handleDelete}
          title="Are You Sure You Want To Delete This Item?"
          description="This will permanently remove all data associated with it from Simplee."
        />
      </Grid>
    </>
  );
};
export default InvestorForm;
