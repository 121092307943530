import { IconButton } from "@material-ui/core";
import React from "react";
import styles from "./selectedColumns.module.scss";
import CloseIcon from "@material-ui/icons/Close";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { List, arrayMove } from "react-movable";

const SelectedColumns = ({ checked, handleToggle, setChecked }) => {
  return (
    <>
      <div className={styles.leftHeading}>Selected columns</div>
      <div className={styles.leftSubHeading}>
        Drag and drop to decide the order of the columns
      </div>
      <div className={styles.outerCardContainer}>
        {/* <div className={styles.cardComponent}>Acronym</div> */}
        <div className={styles.innerCardContainer}>
          <List
            values={checked}
            onChange={({ oldIndex, newIndex }) =>
              setChecked(arrayMove(checked, oldIndex, newIndex))
            }
            renderList={({ children, props }) => (
              <div {...props} className={styles.innerCardContainer}>
                {children}
              </div>
            )}
            renderItem={({ value, props }) =>
              value !== "Action" && (
                <div {...props} className={styles.cardWrapper}>
                  <div className={styles.value}>
                    <IconButton
                      aria-label="menu"
                      className={styles.iconContainer}>
                      <DragIndicatorIcon
                        className={styles.icon}
                        style={{ fontSize: 18 }}
                      />
                    </IconButton>
                    {value}
                  </div>
                  <div>
                    <IconButton aria-label="menu" onClick={handleToggle(value)}>
                      <CloseIcon
                        className={styles.icon}
                        style={{ fontSize: 16 }}
                      />
                    </IconButton>
                  </div>
                </div>
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default SelectedColumns;
