import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import Toast from "components/toast";
// import { verifyDocUploadType } from "helpers/Common";
import { CommonService } from "network/commonService";
import React, { useState } from "react";
// import newuploadDocIcon from "assets/icons/newuploadDocIcon.svg";
import othersdocAccordianButton from "assets/icons/othersdocAccordianButton.svg";
import DocumentsUpload from "./DocumentsUpload/documentsUpload";

const useStyles = makeStyles(() => ({
  doctableHeading: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  docImg: {
    height: 40,
    width: 40,
    borderRadius: 8
  },
  docText: {
    fontFamily: "Inter Regular",
    fontWeight: 400,
    fontSize: 16
  },
  addFile: {
    cursor: "pointer"
  },
  T_Width: {
    maxWidth: 739
  },
  docText_w: {
    width: 331
  },
  docsize: {
    width: 156
  },
  doc_Upld_size: {
    width: 252
  }
}));

const NewCategoryUploadRow = ({
  other = false,
  item,
  listData = [],
  setListData = () => {},
  filterDocList = [],
  deleteHandler
}) => {
  const classes = useStyles();
  const [size, setSize] = useState([]);

  return (
    <TableRow key={`${item.id}Others`} style={{ marginRight: 14 }}>
      <TableCell className={`${classes.docText_w}`}>
        {item.name ?? item?.label}
      </TableCell>
      <TableCell className={`${classes.docsize}`}>{size}</TableCell>
      <TableCell className={`${classes.doc_Upld_size}`}>
        <DocumentsUpload
          filterDocList={filterDocList}
          key={other ? item.value : item.id}
          type={other ? item.value : item.id}
          title={item.name}
          other={other}
          listData={listData}
          setListData={setListData}
          setSize={setSize}
          deleteHandler={deleteHandler}
        />
      </TableCell>
    </TableRow>
  );
};

function NewCategoryDocTable({
  sponDocCategories,
  listData = [],
  setListData = () => {},
  deleteHandler
}) {
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [allCategories, setAllCategories] = React.useState([]);

  const fetchCategories = async () => {
    const res = await CommonService.getAllDocCategories();
    if (res.success) {
      res.data &&
        setAllCategories(
          res.data.map((el) => ({
            label: el.name,
            value: el.id
          }))
        );
    } else {
      Toast.showErrorToast("Failed To Load Categories");
    }
  };

  React.useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Box
      sx={{
        width: "100%"
      }}>
      <Box sx={{ width: "100%", maxWidth: 739, background: "#fff" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.doctableHeading} ${classes.docText_w}`}>
                Document Name
              </TableCell>
              <TableCell
                className={`${classes.doctableHeading} ${classes.docsize}`}>
                Size
              </TableCell>
              <TableCell
                className={`${classes.doctableHeading} ${classes.doc_Upld_size}`}>
                Attachment
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {sponDocCategories.map((item, i) => {
              return (
                <NewCategoryUploadRow
                  key={i}
                  item={item}
                  listData={listData}
                  setListData={setListData}
                  deleteHandler={deleteHandler}
                />
              );
            })}
          </TableBody>
        </Table>
      </Box>
      <Box sx={{ width: "100%", maxWidth: 739, background: "#fff" }}>
        <Box
          sx={{
            m: 1,
            ml: 2,
            maxWidth: 536,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}>
          <div>Others</div>
          <div onClick={() => setShow(!show)}>
            <img
              src={othersdocAccordianButton}
              alt="accordian"
              style={{ cursor: "pointer" }}
            />
          </div>
        </Box>
        {show && (
          <Table>
            <TableBody>
              {allCategories?.map((item, i) => {
                return (
                  <NewCategoryUploadRow
                    key={i}
                    other={true}
                    item={item}
                    filterDocList={allCategories}
                    listData={listData}
                    setListData={setListData}
                    deleteHandler={deleteHandler}
                  />
                );
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </Box>
  );
}

export default NewCategoryDocTable;
