import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  TextField,
  styled,
  Button
} from "@material-ui/core";

import { FieldArray, Formik } from "formik";
import FieldHeading from "../../../../components/fieldHeading";
import AppTextFieldWhite from "../../../../components/StyledComponents/AppTextFieldWhite";
import CustomSelect from "../../../../components/StyledComponents/Select";
import DescriptionBox from "../../../../components/StyledComponents/DescriptionBox";
import Toast from "../../../../components/toast";
import Loader from "components/Loader/AppLoader";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import { useHistory } from "react-router-dom";
import ImageUpload from "../component/ImageUpload/ImageUpload";
import { PropertiesService } from "network/propertiesService";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import { useSelector } from "react-redux";
import { NetworkManager, Endpoint } from "network/core";
import plusAdd from "assets/icons/PlusAdd.svg";
import Noi from "../component/noi";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import { validationSchemaAddProperty } from "helpers/validationSchema";
import EditIcon from "assets/icons/EditIcon.svg";
import Occupancy from "../component/occupancy";
import PropertyEditModal from "components/Modals/PropertyEditModal";

import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import NewCategoryDocTable from "../component/NewCategoryDocTable";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import TextCounter from "components/TextCounter";
// import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
// import DocumentsUpload from "../component/DocumentsUpload/documentsUpload";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import Streetview from "react-google-streetview";

const StyledTextField = styled(TextField)({
  "& label": {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#191919"
  },
  "& label.Mui-focused": {
    color: "#191919"
  },
  "& .MuiInputBase-root": {
    border: "1px solid #BBBBBB",
    padding: "7px 10px",
    borderRadius: 8,
    backgroundColor: "#FFFF"
  },

  "& .MuiInputBase-input": {
    fontFamily: "Inter SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "24px",
    color: "#191919",
    borderRadius: "8px",
    WebkitBoxShadow: "0 0 0 1000px #FFF inset",
    height: "1rem"
  },
  "& .MuiInputBase-input:focus": {},
  "& .Mui-error": {
    padding: "0px 0px",
    border: "none",
    "& .MuiInputBase-input": {
      border: "1px solid #E13838",
      fontFamily: "Inter Regular",
      fontWeight: 400,
      padding: "0px 0px",
      height: "43px",
      width: "100%",
      paddingLeft: "7px"
    }
  },
  "& .Mui-disabled": {
    backgroundColor: "#E9E9E9",
    "& .MuiInputBase-input": {
      backgroundColor: "#E9E9E9",
      WebkitBoxShadow: "0 0 0 1000px #E9E9E9 inset",
      fontFamily: "Inter Regular",
      fontWeight: 600,
      borderRadius: "8px"
    }
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0
  }
});

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: 20,
    borderRadius: 20
  },
  propertyNameDropdown: {
    color: "#191919",
    fontFamily: "Inter Regular !important",
    fontSize: "16px",
    padding: "6px 16px"
  },
  propertyLocation: {
    color: "#A0A0A0",
    fontWeight: 400,
    fontSize: "14px"
  },
  searchAddress: {
    color: "#191919",
    fontWeight: 600,
    fontSize: "16px"
  },
  mapView: {
    height: "450px",
    paddingTop: "34px"
  },
  accordionContainer: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white"
    }
  },
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    marginTop: 10,
    width: "max-content"
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 10,
    marginTop: 20,
    marginLeft: 20
  },
  heading: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0,
    // color: "#616161",
    marginRight: 20,
    flexShrink: 0
  },
  headingData: {
    fontFamily: "Inter Bold",
    fontSize: 18,
    fontWeight: 700,
    color: "#000000"
  },
  divider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: "#E9E9E9"
  },
  whiteContainer: {
    backgroundColor: "#fff",
    margin: 20,
    width: "auto"
  },
  grayContainer: {
    backgroundColor: "#FAFAFA",
    margin: 20,
    width: "100%",
    borderRadius: 12
  },
  container: {
    // backgroundColor: "#FAFAFA"
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 50,
    paddingTop: 30,
    backgroundColor: "#ffffff"
  },
  btnPrimary: {
    border: "1px solid #5775E5",
    backgroundColor: "#5775E5",
    color: "#ffffff",
    width: 218,
    height: 50,
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer"
  },
  btnSecondary: {
    width: 218,
    margin: "0 0px 0 22px",
    color: "#445EBE",
    backgroundColor: "#FFFF",
    fontFamily: "Inter Regular",
    borderRadius: 4,
    fontSize: 18,
    fontWeight: 600,
    cursor: "pointer",
    border: "1px solid #445EBE"
  },
  cellWidth50: {
    minWidth: 50
  },
  cellWidth120: {
    minWidth: 120
  },
  cellWidth150: {
    minWidth: 150
  },
  cellWidth170: {
    minWidth: 170
  },
  cellWidth180: {
    minWidth: 180
  },
  doctableHeading: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  scrollXContainer: {
    overflowX: "scroll",
    overflowY: "hidden"
  }
}));

const CHARACTER_LIMIT = 100;

const PropertyDetails = () => {
  const googleMapsApiKey = "AIzaSyA_J-TXbKBup88CxDVCXEHvfgYBzE3RkYo";

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  });

  const [initialPropertiesData, setInitialPropertiesData] = useState({
    propertiesId: "",
    investmentName: "",
    investmentID: "",
    propertyName: "",
    propertyValue: "",
    type: "",
    status: "",
    address: "",
    addressId: "",
    zip: "",
    units: "",
    totalUnits: "",
    area: "",
    year: "",
    occupancy: [],
    yearBuild: "",
    description: "",
    documents: [],
    noiSet: [],
    addressLane1: "",
    city: "",
    state: "",
    country: ""
  });
  const [address2, setAddress2] = useState("");
  const [location, setLocation] = useState({
    lat: 40.0066723,
    lng: -83.0304546
  });
  const streetViewPanoramaOptions = {
    position: { lat: location.lat, lng: location.lng },
    pov: { heading: 100, pitch: 0 },
    zoom: 1,
    addressControl: true,
    showRoadLabels: true,
    zoomControl: true
  };
  const [address1, setAddress] = useState();
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [noiCadenceChoices, setNoiCadenceChoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [docCategories, setDocCategories] = useState([]);
  const [noiCategories, setNoiCategories] = useState([]);
  const [monthChoices, setMonthChoices] = useState([]);
  const [payloadForPostEdit, setPayloadForPostEdit] = useState({});

  const { propertiesId, userPermissions, user } = useSelector(
    (store) => store.app
  );
  const [displayImage, setDisplayImage] = useState([]);
  const [clickonBasicEdit, setClickonBasicEdit] = useState(false);
  const [clickonPropertyEdit, setClickonPropertyEdit] = useState(false);
  const [postEditData, setPostEditData] = useState();
  const [propertyEditModal, setPropertyEditModal] = useState(false);
  const [deletedImages, setDeletedImages] = useState([]);
  const [deletedNois, setDeletedNois] = useState([]);
  const [deletedOccupancies, setDeletedoccupancies] = useState([]);
  const [deletedDocs, setDeletedDocs] = useState([]);
  const [occsEditableRows, setOccsEditableRows] = useState([]);
  const [noiEditableRows, setNoiEditableRows] = useState([]);

  const classes = useStyles();
  const history = useHistory();

  const fetchPropertiesDetails = async () => {
    const instance = new NetworkManager(
      new Endpoint(`investment/properties/${propertiesId}/`, "GET")
    );
    const resp = await instance.httpRequest();

    if (resp.success) {
      let temp = resp.data;
      const doc = temp?.documents.map((doc) => {
        return {
          id: doc?.document_media?.id,
          type: doc?.category?.id,
          size: doc?.document_media?.file_size,
          link: doc?.document_media?.file,
          name: doc?.document_media?.name,
          docId: doc?.id
        };
      });

      setLocation({
        ...location,
        lat: parseFloat(temp?.property_address?.latitude),
        lng: parseFloat(temp?.property_address?.longitude)
      });

      setAddress2(temp?.property_address?.address_1);

      setInitialPropertiesData({
        investmentName: temp?.investment_name,
        investmentID: temp?.investment?.id,
        propertyID: temp?.id,
        addressId: temp?.property_address?.id,
        propertyName: temp?.name,
        propertyValue: temp?.value || "",
        type: temp?.type,
        units: temp?.units ? temp?.units : "",
        totalUnits: temp?.total_units || "",
        status: temp?.status ? temp?.status : "",
        address: temp?.property_address?.address,
        // zip: temp?.property_address?.zip_code,
        area: temp?.area ? temp?.area : "",
        year: "",
        occupancy: getOccupancySets(temp?.property_occupancy || []),
        yearBuild: temp?.build_year ? temp?.build_year : "",
        description: temp?.description ? temp?.description : "",
        documents: doc,
        noiSet: getNoiSets(temp?.property_noi || []),
        images: temp?.images,
        addressLane1: temp?.property_address?.address,
        city: temp?.property_address?.city,
        state: temp?.property_address?.state,
        zip: temp?.property_address?.zip,
        country: temp?.property_address?.country
      });
      setDisplayImage(temp?.images);
    } else {
      Toast.showErrorToast("Unable To Load Properties Data");
    }
  };

  const makeNoiEditableRow = (id) => {
    setNoiEditableRows([...noiEditableRows, id]);
  };

  const makeOccsEditableRow = (id) => {
    setOccsEditableRows([...occsEditableRows, id]);
  };

  const handleImageDelete = (id) => {
    setDeletedImages([...deletedImages, id]);

    setDisplayImage((prev) => {
      return prev.filter((item) => item && item.id != id);
    });
  };

  const handleLocation = () => {
    setLocation({ ...location, lat: coordinates.lat, lng: coordinates.lng });
  };
  const onPositionChanged = () => {};

  const handleNoiDelete = (id) => {
    setDeletedNois([...deletedNois, id]);
  };
  const handleOccupancyDelete = (id) => {
    setDeletedoccupancies([...deletedOccupancies, id]);
  };
  const handleDocDelete = (id) => {
    setDeletedDocs([...deletedDocs, id]);
  };

  const setDocData = (data, formik) => {
    formik.setFieldValue("documents", data);
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTPROPERTY"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const loadNoiCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTPROPERTYNOI"
    });
    if (res.success) {
      res.data.length && setNoiCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const getOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      const { PROPERTY_TYPE, STATUSES, UNITS } = res.data;
      setPropertyTypeOptions(PROPERTY_TYPE);
      setStatusOptions(STATUSES);
      setUnitOptions(UNITS);
      setNoiCadenceChoices(res.data.NOI_CADENCE_CHOICES);
      setMonthChoices(res.data.MONTH_CHOICES);
      setInitialPropertiesData((prev) => ({
        ...prev,
        type:
          PROPERTY_TYPE.find(
            (el) => el.label === prev.type || el.value === prev.type
          )?.value || prev.type,
        status:
          STATUSES.find(
            (el) => el.label === prev.status || el.value === prev.status
          )?.value || prev.status,
        units:
          UNITS.find((el) => el.label === prev.units || el.value === prev.units)
            ?.value || prev.units
      }));
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const returnOccupancyArray = (item, totalUnits) => {
    let allPropertySets = {};
    let payload = [];

    item &&
      item.forEach((val) => {
        if (val.year !== "" && val.month !== "") {
          allPropertySets.year = val.year;
          allPropertySets.month = val.month;
          allPropertySets.documents = val.documents?.map((doc) => ({
            document_media_id: doc.id,
            category_id: doc.type,
            ...(doc.docId ? { id: doc.docId } : {})
          }));

          const occupancy = (val.units_leased / totalUnits) * 100;
          const collections = (val.rentCollected / val.rentPotential) * 100;
          allPropertySets.units_leased = val.units_leased;
          allPropertySets.occupancy = occupancy.toFixed(2);
          allPropertySets.rent_potential = val.rentPotential;
          allPropertySets.rent_collected = val.rentCollected;
          allPropertySets.collections = collections.toFixed(2);

          if (val.id) {
            allPropertySets.id = val.id;
            allPropertySets.updated_by_id = user.id;
          } else {
            allPropertySets.created_by_id = user.id;
            allPropertySets.updated_by_id = user.id;
          }

          payload.push(allPropertySets);
          allPropertySets = {};
        }
      });
    return payload;
  };

  const returnNoiArray = (item) => {
    let allPropertySets = {};
    let payload = [];

    item &&
      item.forEach((val) => {
        if (val.year !== "" && val.month !== "") {
          allPropertySets.year = val.year;
          allPropertySets.month = val.month;
          allPropertySets.documents = val.documents?.map((doc) => ({
            document_media_id: doc.id,
            category_id: doc.type,
            ...(doc.docId ? { id: doc.docId } : {})
          }));

          allPropertySets.revenue = val.totalRevenue;
          allPropertySets.operating_expenses = val.operatingExpense;
          allPropertySets.finance_expenses = val.financingExpense;
          allPropertySets.non_operating_expenses = val.nonOperatingExpense;
          if (val.id) {
            allPropertySets.id = val.id;
            allPropertySets.updated_by_id = user.id;
          } else {
            allPropertySets.created_by_id = user.id;
            allPropertySets.updated_by_id = user.id;
          }

          payload.push(allPropertySets);
          allPropertySets = {};
        }
      });
    return payload;
  };
  const handleSelect = async (value, formik) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddress(value);
    setCoordinates(ll);
    formik.setFieldValue("address", value);
  };

  const getOccupancySets = (values) => {
    const occupancySetArray = [];
    let occupancySetObj = {};
    values?.length &&
      values?.forEach((el) => {
        occupancySetObj.year = el.year;
        occupancySetObj.month = el.month;
        occupancySetObj.occupancy = el.occupancy;
        occupancySetObj.rentCollected = el.rent_collected;
        occupancySetObj.rentPotential = el.rent_potential;
        occupancySetObj.collections = el.collections;
        occupancySetObj.units_leased = el.units_leased;
        occupancySetObj.id = el.id;
        occupancySetObj.documents = formatDocsData(el?.documents);
        occupancySetArray.push(occupancySetObj);
        occupancySetObj = {};
      });
    return occupancySetArray;
  };

  const getNoiSets = (values) => {
    const noiSetArray = [];
    let noiSetObj = {};
    values?.length &&
      values?.forEach((el) => {
        noiSetObj.property_noi = el.noi;
        noiSetObj.year = el.year;
        noiSetObj.month = el.month;
        noiSetObj.nonOperatingExpense = el.non_operating_expenses;
        noiSetObj.operatingExpense = el.operating_expenses;
        noiSetObj.totalRevenue = el.revenue;
        noiSetObj.financingExpense = el.finance_expenses;
        noiSetObj.id = el.id;
        noiSetObj.documents = formatDocsData(el?.documents);
        noiSetArray.push(noiSetObj);
        noiSetObj = {};
      });
    return noiSetArray;
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  const handleFormSubmit = async (values) => {
    const {
      addressId,
      propertyID,
      propertyName,
      propertyValue,
      status,
      totalUnits,
      type,
      units,
      area,
      yearBuild,
      description
    } = values;
    const payload = {
      id: propertyID,
      property_address: {
        address: address1,
        address_1: address2,
        address_2: "",
        city: "",
        state: "",
        country: "",
        latitude: coordinates.lat != null ? coordinates.lat : location.lat,
        longitude: coordinates.lng != null ? coordinates.lng : location.lng,
        zip_code: ""
      },
      property_noi: returnNoiArray(values.noiSet),
      property_occupancy: returnOccupancyArray(
        values.occupancy,
        values.totalUnits
      ),
      documents: values?.documents?.map((doc) => ({
        document_media_id: doc.id,
        category_id: doc.type,
        id: doc?.docId
      })),
      updated_by: user.id,
      name: propertyName,
      value: +propertyValue,
      type: type,
      ...(area && { area: area }),
      ...(units && { units: units }),
      ...(status && { status: status }),
      description: description,
      ...(totalUnits && { total_units: totalUnits }),
      build_year: yearBuild,
      images_list: displayImage.map((item) => item.id)
    };

    if (addressId) {
      payload.property_address = {
        ...payload.property_address,
        id: addressId
      };
    }

    if (clickonBasicEdit) {
      payload.update_basic = true;
    }

    if (deletedImages?.length > 0) {
      payload.deleted_data = {
        images: deletedImages
      };
    }
    if (deletedOccupancies?.length > 0) {
      payload.deleted_data = {
        ...payload.deleted_data,
        property_occupancy: deletedOccupancies
      };
    }
    if (deletedNois?.length > 0) {
      payload.deleted_data = {
        ...payload.deleted_data,
        property_noi: deletedNois
      };
    }
    if (deletedDocs?.length > 0) {
      payload.deleted_data = {
        ...payload.deleted_data,
        documents: deletedDocs
      };
    }

    setPayloadForPostEdit(payload);
    let res = await PropertiesService.editProperties(payload, propertiesId);
    if (res.success) {
      const data = res.data;
      if (clickonBasicEdit) {
        setPostEditData(data);
        setPropertyEditModal(true);
      } else {
        Toast.showSuccessToast("Properties Updated Successfully");
        history.push("/u/properties");
      }
    } else {
      if (res?.error?.message?.[0] === "A valid number is required.") {
        Toast.showErrorToast("Total Units Can Not Be Zero");
        return;
      }
      Toast.showErrorToast(res?.error?.message?.[0]);
    }
  };

  const onUpdateAssetValuePostEdit = async (value, comments) => {
    let updatedpayload;
    if (value == "newAssetValue") {
      updatedpayload = {
        ...payloadForPostEdit,
        update_basic: true,
        update_asset: true,
        notes: comments
      };
    } else {
      updatedpayload = {
        ...payloadForPostEdit,
        update_asset: false
      };
    }
    let res = await PropertiesService.editProperties(
      updatedpayload,
      propertiesId
    );
    if (res.success) {
      Toast.showSuccessToast("Properties Added Successfully");
      history.push("/u/properties");
    } else {
      Toast.showErrorToast(res?.error?.message[0]);
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "PROPERTIES",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_update) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    checkPermissions();
    setLoading(true);
    Promise.all([
      loadCategories(),
      loadNoiCategories(),
      fetchPropertiesDetails()
    ])
      .then(() => getOptions())
      .then(() => setLoading(false));
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent
            heading="Edit Property"
            description={"Edit And Update Property"}
            backBtn={true}
          />
          <div className={classes.container}>
            <Formik
              initialValues={initialPropertiesData}
              enableReinitialize={true}
              validationSchema={validationSchemaAddProperty}
              onSubmit={(values) => {
                handleFormSubmit(values);
              }}>
              {(formik) => (
                <>
                  <>
                    <>
                      <div className={classes.root}>
                        <Grid xs={12}>
                          <Grid container xs={12} direction="column">
                            <div className={classes.headingContainer}>
                              <div className={classes.heading}>Basic</div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => setClickonBasicEdit(true)}>
                                <img src={EditIcon} alt="edit" />
                              </div>
                            </div>
                          </Grid>
                          <Grid container className={classes.grayContainer}>
                            <Grid container spacing={2} style={{ padding: 20 }}>
                              <Grid item xs={12}>
                                <FieldHeading title="Investment Name" />
                                <Typography
                                  variant="h2"
                                  style={{ fontWeight: 600, fontSize: "16px" }}>
                                  {initialPropertiesData.investmentName}
                                </Typography>
                              </Grid>
                              <Grid item xl={3} lg={4} sm={6}>
                                <FieldHeading title="Property Name" />
                                <AppTextFieldWhite
                                  disabled={!clickonBasicEdit}
                                  placeholder="Property Name*"
                                  id="propertyName"
                                  onChange={formik.handleChange("propertyName")}
                                  variant="standard"
                                  fullWidth
                                  value={formik.values.propertyName}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  error={
                                    formik.touched.propertyName &&
                                    Boolean(formik.errors.propertyName)
                                  }
                                  helperText={
                                    formik.touched.propertyName &&
                                    formik.errors.propertyName
                                  }
                                />
                              </Grid>
                              <Grid item xl={3} lg={4} sm={6}>
                                <FieldHeading title="Property Value" />
                                <AppTextFieldWhite
                                  id="propertyValue"
                                  disabled={!clickonBasicEdit}
                                  placeholder=""
                                  variant="standard"
                                  numFormat={true}
                                  fullWidth
                                  value={formik.values.propertyValue}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  onChange={formik.handleChange(
                                    "propertyValue"
                                  )}
                                  error={
                                    formik.touched.propertyValue &&
                                    Boolean(formik.errors.propertyValue)
                                  }
                                  helperText={
                                    formik.touched.propertyValue &&
                                    formik.errors.propertyValue
                                  }
                                />
                              </Grid>
                              <Grid item xl={3} lg={4} sm={6}>
                                <FieldHeading title="Property Type*" />
                                <CustomSelect
                                  fullWidth
                                  disabled={!clickonBasicEdit}
                                  items={propertyTypeOptions}
                                  touched={formik.touched.type}
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "type",
                                      e.target.value
                                    );
                                  }}
                                  value={formik.values.type}
                                  error={
                                    formik.touched.type &&
                                    Boolean(formik.errors.type)
                                  }
                                  helperText={
                                    formik.touched.type && formik.errors.type
                                  }
                                />
                              </Grid>
                              <Grid item xl={3} lg={4} sm={6}>
                                <FieldHeading title="Status" />
                                <CustomSelect
                                  fullWidth
                                  disabled={!clickonBasicEdit}
                                  items={statusOptions}
                                  value={formik.values.status}
                                  onChange={formik.handleChange("status")}
                                  error={
                                    formik.touched.status &&
                                    Boolean(formik.errors.status)
                                  }
                                  helperText={
                                    formik.touched.status &&
                                    formik.errors.status
                                  }
                                />
                              </Grid>

                              <Grid item xl={3} lg={4} sm={6}>
                                <FieldHeading title="Total Units" />
                                <AppTextFieldWhite
                                  id="totalUnits"
                                  disabled={!clickonBasicEdit}
                                  placeholder=""
                                  numFormat={true}
                                  variant="standard"
                                  fullWidth
                                  value={formik.values.totalUnits}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  onChange={formik.handleChange("totalUnits")}
                                  error={
                                    formik.touched.totalUnits &&
                                    Boolean(formik.errors.totalUnits)
                                  }
                                  helperText={
                                    formik.touched.totalUnits &&
                                    formik.errors.totalUnits
                                  }
                                />
                              </Grid>
                              <Grid item xl={3} lg={4} sm={6}>
                                <FieldHeading title="Area Covered" />
                                <Box sx={{ display: "inline-flex" }}>
                                  <CustomSelect
                                    disabled={!clickonBasicEdit}
                                    style={{ backgroundColor: "white" }}
                                    placeholder="Units"
                                    items={unitOptions}
                                    value={formik.values.units}
                                    onChange={formik.handleChange("units")}
                                    error={formik.errors.units}
                                    touched={formik.touched.units}
                                  />
                                  <AppTextFieldWhite
                                    style={{ paddingLeft: "12px" }}
                                    disabled={!clickonBasicEdit}
                                    id="area"
                                    placeholder="Add Area Covered"
                                    variant="standard"
                                    fullWidth
                                    InputLabelProps={{
                                      shrink: true
                                    }}
                                    InputProps={{
                                      disableUnderline: true
                                    }}
                                    value={formik.values.area}
                                    onChange={formik.handleChange("area")}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xl={3} lg={4} sm={6}>
                                <FieldHeading title="Year of Build" />
                                <SelectWithInfiniteScroll
                                  fullWidth
                                  disabled={!clickonBasicEdit}
                                  minValue={2000}
                                  selectedItem={formik.values.yearBuild}
                                  onChange={(value) =>
                                    formik.setFieldValue("yearBuild", value)
                                  }
                                  error={formik.errors.yearBuild}
                                  touched={formik.errors.yearBuild}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <FieldHeading title="Property Description" />
                                <DescriptionBox
                                  id="description"
                                  disabled={!clickonBasicEdit}
                                  placeholder="Add Description"
                                  variant="standard"
                                  fullWidth
                                  rows={3}
                                  multiline
                                  value={formik.values.description}
                                  onChange={formik.handleChange("description")}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  inputProps={{
                                    maxLength: CHARACTER_LIMIT
                                  }}
                                  // error={formik.errors.description}
                                  error={
                                    formik.touched.description &&
                                    Boolean(formik.errors.description)
                                  }
                                  helperText={
                                    formik.touched.description &&
                                    formik.errors.description
                                  }
                                />
                                <TextCounter
                                  currentDescriptionLength={
                                    formik.values.description
                                      ? formik.values.description?.length
                                      : 0
                                  }
                                  maxLength={100}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid container xs={12} direction="column">
                            <div className={classes.headingContainer}>
                              <div className={classes.heading}>
                                Upload upto 5 images
                              </div>
                            </div>
                          </Grid>
                          <Grid container className={classes.grayContainer}>
                            <Grid container spacing={2} style={{ padding: 20 }}>
                              <ImageUpload
                                displayImage={displayImage}
                                setDisplayImage={setDisplayImage}
                                handleDelete={handleImageDelete}
                              />
                            </Grid>
                          </Grid>
                          <Grid container xs={12} direction="column">
                            <div className={classes.headingContainer}>
                              <div className={classes.heading}>
                                Property Overview
                              </div>
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() => setClickonPropertyEdit(true)}>
                                <img src={EditIcon} alt="edit" />
                              </div>
                            </div>
                          </Grid>
                          <Grid container className={classes.grayContainer}>
                            <Grid
                              container
                              spacing={2}
                              style={{
                                padding: 20
                              }}>
                              <Grid item xs={12} sm={6} md={4}>
                                <FieldHeading title="Address Line 1*" />

                                {window.google && (
                                  <PlacesAutocomplete
                                    value={formik.values.address}
                                    // value={address1}
                                    onChange={formik.handleChange("address")}
                                    // onChange={setAddress}
                                    onSelect={(value) =>
                                      handleSelect(value, formik)
                                    }>
                                    {({
                                      getInputProps,
                                      suggestions,
                                      getSuggestionItemProps,
                                      loading
                                    }) => (
                                      <div>
                                        <StyledTextField
                                          value={formik.values.address}
                                          id="address"
                                          error={
                                            formik.touched.address &&
                                            Boolean(formik.errors.address)
                                          }
                                          helperText={
                                            formik.touched.address &&
                                            formik.errors.address
                                          }
                                          style={{ width: "100%" }}
                                          InputProps={{
                                            disableUnderline: true
                                          }}
                                          {...getInputProps({
                                            placeholder: "Add Address",
                                            className: "location-search-input",
                                            disabled: !clickonPropertyEdit,

                                            defaultValue:
                                              initialPropertiesData.address
                                          })}
                                        />
                                        <div>
                                          {loading && (
                                            <div
                                              style={{ padding: "6px 12px" }}>
                                              Loading...
                                            </div>
                                          )}
                                          {suggestions.map((suggestion) => {
                                            const className =
                                              classes.propertyNameDropdown;

                                            const style = suggestion.active
                                              ? {
                                                  cursor: "pointer",

                                                  backgroundColor: "#F5F7FB"
                                                }
                                              : {
                                                  backgroundColor: "#ffffff",

                                                  cursor: "pointer"
                                                };
                                            return (
                                              <div
                                                {...getSuggestionItemProps(
                                                  suggestion,
                                                  {
                                                    className,
                                                    style
                                                  }
                                                )}>
                                                <span>
                                                  {suggestion.description}
                                                </span>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    )}
                                  </PlacesAutocomplete>
                                )}
                              </Grid>
                              <Grid item xs={12} sm={6} md={4}>
                                <FieldHeading title="Address Line 2" />
                                {/* <AppTextFieldWhite
                                  disabled={!clickonPropertyEdit}
                                  id="address"
                                  placeholder="Add Address"
                                  variant="standard"
                                  fullWidth
                                  value={formik.values.address}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  onChange={formik.handleChange("address")}
                                  error={
                                    formik.touched.address &&
                                    Boolean(formik.errors.address)
                                  }
                                  helperText={
                                    formik.touched.address &&
                                    formik.errors.address
                                  }
                                /> */}
                                <StyledTextField
                                  disabled={!clickonPropertyEdit}
                                  value={address2}
                                  onChange={(e) => {
                                    setAddress2(e.target.value);
                                  }}
                                  style={{ width: "100%" }}
                                  InputProps={{ disableUnderline: true }}
                                />
                              </Grid>

                              {/* <Grid item xs={12} sm={6} md={4}>
                                <FieldHeading title="Zip Code" />
                                <AppTextFieldWhite
                                  id="zip"
                                  disabled={!clickonPropertyEdit}
                                  placeholder="Add Zip Code"
                                  variant="standard"
                                  fullWidth
                                  value={formik.values.zip}
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  InputProps={{
                                    disableUnderline: true
                                  }}
                                  onChange={formik.handleChange("zip")}
                                />
                              </Grid> */}
                              <Grid item xs={12}>
                                <Grid container xs={12}>
                                  <Grid xs={6}>
                                    <Grid className={classes.propertyLocation}>
                                      Location
                                    </Grid>
                                    <Grid
                                      className={classes.searchAddress}
                                      style={{ height: "30px" }}>
                                      {address1 == undefined
                                        ? initialPropertiesData.addressLane1
                                        : address1}
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    container
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                      alignItems: "center"
                                    }}
                                    xs={6}>
                                    {" "}
                                    <Button
                                      onClick={handleLocation}
                                      style={{
                                        fontSize: "18px",
                                        fontFamily: "Inter Regular",
                                        fontWeight: 600,
                                        padding: "13px 45px",
                                        height: "50px",
                                        width: "200px",
                                        color: "#FFFF",
                                        borderRadius: "8px",
                                        background:
                                          "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)"
                                      }}>
                                      Street View
                                    </Button>
                                  </Grid>
                                </Grid>
                                <Grid
                                  xs={12}
                                  container
                                  className={classes.mapView}>
                                  <Grid item xs={12}>
                                    <Streetview
                                      apiKey={googleMapsApiKey}
                                      onPositionChanged={onPositionChanged}
                                      streetViewPanoramaOptions={
                                        streetViewPanoramaOptions
                                      }
                                    />
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container xs={12} direction="column">
                                <div className={classes.headingContainer}>
                                  <div className={classes.heading}>
                                    Occupancy
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                className={`${classes.grayContainer}`}
                                style={{ padding: "20px" }}>
                                <FieldArray
                                  name="occupancy"
                                  render={(arrayHelper) => (
                                    <>
                                      <Grid
                                        container
                                        style={{
                                          boxShadow:
                                            "0px 4px 15px rgb(160 160 160 / 15%)"
                                        }}>
                                        <Grid
                                          container
                                          className={`${classes.scrollXContainer}`}
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: "10px"
                                          }}>
                                          <Grid
                                            style={{
                                              width: "100%",
                                              marginBottom: 5
                                              // borderBottom: "1px solid #A0A0A0"
                                            }}>
                                            <Box
                                              display="flex no-wrap"
                                              flexDirection={["row"]}
                                              alignItems="center"
                                              style={{
                                                display: "inline-flex",
                                                gap: "16px",
                                                width: "100%",
                                                padding: "15px"
                                              }}>
                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Year
                                              </Typography>
                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Month
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Units Leased
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Occupancy
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Rent Potential
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Rent Collected
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading}`}>
                                                Collections
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading}`}>
                                                Documents
                                              </Typography>
                                            </Box>
                                          </Grid>
                                          {/* <Grid
                                    container
                                    style={{ padding: 20 }}
                                    spacing={2}>
                                    <FieldArray
                                      name="occupancy"
                                      render={(arrayHelper) => ( */}
                                          <Grid
                                            container
                                            direction="column"
                                            spacing={2}>
                                            <Grid
                                              item
                                              style={{
                                                borderTop: "1px solid #A0A0A0"
                                              }}>
                                              {formik.values.occupancy.map(
                                                (noi, index) => (
                                                  <Box>
                                                    <Occupancy
                                                      customStyle={{
                                                        backgroundColor: "#fff",
                                                        padding: 20,
                                                        borderRadius: 8,
                                                        margin: "0px 20px"
                                                      }}
                                                      cadenceChoices={
                                                        noiCadenceChoices
                                                      }
                                                      monthChoices={
                                                        monthChoices
                                                      }
                                                      id={index}
                                                      noi={noi}
                                                      key={index}
                                                      arrayHelper={arrayHelper}
                                                      formik={formik}
                                                      noiCategories={
                                                        noiCategories
                                                      }
                                                      isEditable="true"
                                                      deleteHandler={
                                                        handleOccupancyDelete
                                                      }
                                                      occsEditableRows={
                                                        occsEditableRows
                                                      }
                                                      makeOccsEditableRow={
                                                        makeOccsEditableRow
                                                      }
                                                    />
                                                  </Box>
                                                )
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        // color={"primary.main"}
                                        // style={{ cursor: "pointer" }}
                                        className={classes.addMore}
                                        onClick={() =>
                                          arrayHelper.push({
                                            year: 2000,
                                            month: "",
                                            units_leased: "",
                                            occupancy: "",
                                            rentPotential: "",
                                            rentCollected: "",
                                            collections: "",
                                            addNewOccs: "true"
                                          })
                                        }
                                        variant="text">
                                        <img
                                          src={plusAdd}
                                          alt=""
                                          style={{ marginRight: 8 }}
                                        />
                                        Click to add a new row
                                      </Grid>
                                    </>
                                  )}
                                />
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Grid container xs={12} direction="column">
                                <div className={classes.headingContainer}>
                                  <div className={classes.heading}>
                                    Net Operating Income
                                  </div>
                                </div>
                              </Grid>
                              <Grid
                                className={`${classes.grayContainer}`}
                                style={{ padding: "20px" }}>
                                <FieldArray
                                  name="noiSet"
                                  render={(arrayHelper) => (
                                    <>
                                      <Grid
                                        container
                                        style={{
                                          boxShadow:
                                            "0px 4px 15px rgb(160 160 160 / 15%)"
                                        }}>
                                        <Grid
                                          container
                                          className={`${classes.scrollXContainer}`}
                                          style={{
                                            backgroundColor: "#fff",
                                            borderRadius: "10px"
                                          }}>
                                          <Grid
                                            style={{
                                              width: "100%",
                                              marginBottom: 5
                                            }}>
                                            <Box
                                              display="flex no-wrap"
                                              flexDirection={["row"]}
                                              alignItems="center"
                                              style={{
                                                display: "inline-flex",
                                                gap: "16px",
                                                width: "100%",
                                                padding: "15px"
                                              }}>
                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Year
                                              </Typography>
                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Month
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Total Revenue
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth170}`}>
                                                Total Operating Expenses
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Financing Expenses
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                                Non Operating Expenses
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading} ${classes.cellWidth50}`}>
                                                NOI
                                              </Typography>

                                              <Typography
                                                className={`${classes.doctableHeading}`}>
                                                Documents
                                              </Typography>
                                            </Box>
                                          </Grid>

                                          <Grid
                                            container
                                            direction="column"
                                            spacing={2}>
                                            <Grid
                                              item
                                              style={{
                                                borderTop: "1px solid #A0A0A0"
                                              }}>
                                              {formik.values.noiSet.map(
                                                (noi, index) => (
                                                  <Box>
                                                    <Noi
                                                      customStyle={{
                                                        backgroundColor: "#fff",
                                                        padding: 20,
                                                        borderRadius: 8,
                                                        margin: "0px 20px"
                                                      }}
                                                      cadenceChoices={
                                                        noiCadenceChoices
                                                      }
                                                      monthChoices={
                                                        monthChoices
                                                      }
                                                      id={index}
                                                      noi={noi}
                                                      key={index}
                                                      arrayHelper={arrayHelper}
                                                      formik={formik}
                                                      noiCategories={
                                                        noiCategories
                                                      }
                                                      isEditable="true"
                                                      deleteHandler={
                                                        handleNoiDelete
                                                      }
                                                      noiEditableRows={
                                                        noiEditableRows
                                                      }
                                                      makeNoiEditableRow={
                                                        makeNoiEditableRow
                                                      }
                                                    />
                                                  </Box>
                                                )
                                              )}
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>

                                      <Grid
                                        item
                                        className={classes.addMore}
                                        onClick={() =>
                                          arrayHelper.push({
                                            property_noi: "",
                                            year: 2000,
                                            month: "",
                                            totalRevenue: "",
                                            operatingExpense: "",
                                            financingExpense: "",
                                            nonOperatingExpense: "",
                                            addNewNoi: "true"
                                          })
                                        }
                                        variant="text">
                                        <img
                                          src={plusAdd}
                                          alt=""
                                          style={{ marginRight: 8 }}
                                        />
                                        Click to add a new row
                                      </Grid>
                                    </>
                                  )}
                                />
                              </Grid>
                            </Grid>

                            <Grid item xs={12} md={6}>
                              <Grid container xs={12} direction="column">
                                <div className={classes.headingContainer}>
                                  <div className={classes.heading}>
                                    Upload Documents
                                  </div>
                                </div>
                              </Grid>
                              <Grid container className={classes.grayContainer}>
                                <Grid
                                  container
                                  style={{ padding: 20 }}
                                  spacing={2}>
                                  <NewCategoryDocTable
                                    sponDocCategories={docCategories.filter(
                                      (el) => el.name !== "Misc."
                                    )}
                                    listData={formik.values.documents}
                                    setListData={(list) =>
                                      setDocData(list, formik)
                                    }
                                    deleteHandler={handleDocDelete}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </div>
                    </>
                  </>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    width="100%"
                    style={{ gap: "16px" }}>
                    <button
                      className={classes.btnSecondary}
                      onClick={() => history.push("/u/properties")}>
                      Cancel
                    </button>
                    <button
                      className={classes.btnPrimary}
                      onClick={() => formik.handleSubmit()}>
                      Update
                    </button>
                  </Box>
                </>
              )}
            </Formik>
          </div>
        </>
      )}
      <PropertyEditModal
        open={propertyEditModal}
        handleClose={() => setPropertyEditModal(false)}
        handleCancel={() => setPropertyEditModal(false)}
        handleSubmit={onUpdateAssetValuePostEdit}
        postEditData={postEditData}
        title="With Change In  Property Value,"
        description="This will permanently remove all data associated with it from Simplee."
        charLimit={CHARACTER_LIMIT}
      />
    </>
  );
};

export default PropertyDetails;
