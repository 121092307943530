import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import LightTooltip from "components/LightTooltip";
import { truncate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import formatDate from "helpers/formatDate";
import React from "react";
import styles from "./commanstyle.module.scss";

const InvestorTable = ({ Headingdata, mapArray, width, InvDetailPageData }) => {
  const getTooltipData = (item) => {
    const mainDiv = {
      display: "flex",
      gap: "20px",
      padding: "12px",
      width: "fit-content"
    };
    const heading = {
      fontSize: "16px",
      fontWeight: 400,
      color: "#A0A0A0",
      minWidth: "180px"
    };
    const Value = {
      fontSize: "16px",
      fontWeight: 600,
      minWidth: "150px",
      color: "#191919"
    };
    return (
      <>
        <div style={mainDiv}>
          <h3 style={heading}>Investor ID:</h3>
          <h3 style={Value}>{item?.investor?.base_id ?? "-"}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Committed Investment:</h3>
          <h3 style={Value}>
            {item?.overview?.committed_amount
              ? `${formatCurrency(item?.overview?.committed_amount || 0)}`
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Prefered Return:</h3>
          <h3 style={Value}>
            {item?.overview?.prefered_return
              ? `${item?.overview?.prefered_return + "%"}`
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Last Date of Prefered Return:</h3>
          <h3 style={Value}>
            {item?.overview?.last_updated_pref
              ? `${formatDate(item?.overview?.last_updated_pref)}`
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Total Distribution:</h3>
          <h3 style={Value}>
            {item?.stats?.distribution_amount
              ? `${formatCurrency(item?.stats?.distribution_amount || 0)}`
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Net Equity:</h3>
          <h3 style={Value}>
            {item?.stats?.net_equity
              ? formatCurrency(item?.stats?.net_equity)
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Investment Type:</h3>
          <h3 style={Value}>
            {item?.overview?.type ? item?.overview?.type : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Note:</h3>
          <h3 style={Value}>{item?.overview?.notes}</h3>
        </div>
      </>
    );
  };
  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };
  return (
    <>
      <Table
        className={styles.Spon_T_C}
        style={width ? { minWidth: width } : {}}>
        <TableHead>
          <TableRow>
            {Headingdata?.map((item, i) => (
              <TableCell className={styles.T_Heading} key={i}>
                {item.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        {mapArray && (
          <TableBody>
            {mapArray?.map((obj, i) => (
              <TableRow key={i}>
                <TableCell className={styles.T_body}>
                  {obj?.investor?.name
                    ? truncate(obj?.investor?.name, 16)
                    : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.overview?.date ? formatDate(obj?.overview?.date) : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.overview?.prefered_return
                    ? obj?.overview?.prefered_return + "%"
                    : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.overview?.last_updated_pref
                    ? formatDate(obj?.overview?.last_updated_pref)
                    : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.overview?.total_amount
                    ? formatCurrency(obj?.overview?.total_amount)
                    : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.overview?.member_percent
                    ? obj?.overview?.member_percent + "%"
                    : "-"}
                </TableCell>
                <TableCell
                  className={styles.T_body}
                  style={{ textTransform: "capitalize" }}>
                  {obj?.overview?.type ? obj?.overview?.type : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  <DocumentsUpload
                    editable={false}
                    listData={
                      obj.documents ? formatDocsData(obj.documents) : []
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}

        {/* Details page Data */}
        {InvDetailPageData && (
          <TableBody>
            {InvDetailPageData?.map((obj, i) => (
              <TableRow key={i}>
                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell className={styles.T_body}>
                    {obj?.investor?.name
                      ? truncate(obj?.investor?.name, 15)
                      : "-"}
                  </TableCell>
                </LightTooltip>
                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell className={styles.T_body}>
                    {obj?.overview?.member_percent
                      ? obj?.overview?.member_percent + "%"
                      : "-"}
                  </TableCell>
                </LightTooltip>

                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell className={styles.T_body}>
                    {obj?.overview?.total_amount
                      ? formatCurrency(obj?.overview?.total_amount)
                      : "-"}
                  </TableCell>
                </LightTooltip>
                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell className={styles.T_body}>
                    {obj?.overview?.date
                      ? formatDate(obj?.overview?.date)
                      : "-"}
                  </TableCell>
                </LightTooltip>

                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell
                    className={styles.T_body}
                    style={{ whiteSpace: "nowrap" }}>
                    {obj?.overview?.as_on
                      ? formatDate(obj?.overview?.as_on)
                      : "-"}
                  </TableCell>
                </LightTooltip>

                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell className={styles.T_body}>
                    {obj?.entity?.tax_id ? obj?.entity?.tax_id : "-"}
                  </TableCell>
                </LightTooltip>

                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell className={styles.T_body}>
                    {obj?.entity?.name ? obj?.entity.name : "-"}
                  </TableCell>
                </LightTooltip>

                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell className={styles.T_body}>
                    {obj?.entity_address?.address
                      ? obj?.entity_address?.address
                      : "-"}
                  </TableCell>
                </LightTooltip>

                <LightTooltip title={getTooltipData(obj)}>
                  <TableCell className={styles.T_body}>
                    {obj?.stats?.current_value
                      ? formatCurrency(obj?.stats?.current_value)
                      : "-"}
                  </TableCell>
                </LightTooltip>

                <TableCell className={styles.T_body}>
                  <DocumentsUpload
                    editable={false}
                    listData={
                      obj?.documents ? formatDocsData(obj?.documents) : []
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
      </Table>
    </>
  );
};

export default InvestorTable;
