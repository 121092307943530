import { API, NetworkManager } from "./core";

export class AddInvestmentDistributionService {
  static async addDistribution(payload, id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_DISTRIBUTION.ADD_DISTRIBUTION,
      payload,
      [`${id}/`]
    );
    return await instance.httpRequest();
  }

  static async deleteDistribution(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_DISTRIBUTION.DELETE_DISTRIBUTION,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
}
