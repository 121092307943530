import { Grid } from "@material-ui/core";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "redux/index";
import K1Component from "./components/K1Component";
import ManageDataToggle from "./components/ManageDataToggle";
import TemplateComponent from "./components/TemplateComponent";
import styles1 from "./manageData.module.scss";
import {
  getScreenPermissions,
  getPageWithPermission
} from "helpers/permissionChecker";

const ManageData = () => {
  const { manageDataGroupBy } = useSelector((state) => state.app);
  const history = useHistory();
  const [groupBy, setGroupBy] = useState("K1");
  const [permissions, setPermissions] = useState();

  const { userPermissions, user } = useSelector((store) => store.app);

  const onToggleHandle = (value) => {
    setGroupBy(value);
  };

  useEffect(() => {
    setStateOpen(history?.location?.state?.stateValue);
  }, [history?.location?.state?.stateValue]);

  const [openState, setStateOpen] = useState(false);

  const closeAddPopUp = () => {
    setStateOpen(false);
  };

  useEffect(() => {
    setGroupBy(manageDataGroupBy);
  }, [manageDataGroupBy]);

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "Manage Data",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(() => {
    checkPermissions();
  }, []);

  const renderDistributions = () => {
    switch (groupBy) {
      case "K1":
        return (
          <K1Component
            openState={openState}
            closeAddPopUp={closeAddPopUp}
            permissions={permissions}
          />
        );
      case "Template":
        return <TemplateComponent />;
      default:
        return <K1Component permissions={permissions} />;
    }
  };

  return (
    <>
      <Grid container className={styles1.manageDataCtr}>
        <HeaderComponent
          heading="Manage Data"
          description="List Of All Data sets Uploaded By Investors On Simplee"
          addInvestorBtn={false}
          ExtraButtons={
            groupBy === "Template" ? (
              <div className={styles1.addInvestmentButtonContainer}>
                <p
                  className={styles1.addInvestmentButtonText}
                  onClick={() => {
                    history.push("/u/manage_data/upload/template");
                    AppDispatcher.setUploadTemplateStep(0);
                  }}>
                  + Add Template
                </p>
              </div>
            ) : (
              <div className={styles1.addInvestmentButtonContainer}>
                <p
                  className={styles1.addInvestmentButtonText}
                  onClick={() => {
                    setStateOpen(true);
                  }}>
                  + Add K1
                </p>
              </div>
            )
          }
        />
        <Grid item xs={12}>
          <ManageDataToggle groupBy={groupBy} onToggleHandle={onToggleHandle} />
        </Grid>
        <Grid item xs={12}>
          {renderDistributions()}
        </Grid>
      </Grid>
    </>
  );
};

export default ManageData;
