import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import formatDate from "helpers/formatDate";
import formatCurrency from "helpers/formatCurrency";
import React from "react";
import styles from "./commanstyle.module.scss";

const PropertyNoiTable = ({ Title, Headingdata, mapArray, width }) => {
  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };
  return (
    <>
      <div className={styles.TableTitle}>{Title}</div>
      <Table
        className={styles.Spon_T_C}
        style={width ? { minWidth: width } : {}}>
        <TableHead>
          <TableRow>
            {Headingdata?.map((item, i) => (
              <TableCell className={styles.T_Heading} key={i}>
                {item.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mapArray?.map((obj, i) => (
            <TableRow key={i}>
              <TableCell className={styles.T_body}>{obj.year || "-"}</TableCell>
              <TableCell className={styles.T_body}>
                {obj.noi_cadence || "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj.property_noi ? formatCurrency(obj.property_noi) : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {formatDate(obj?.created_at || "-")}
              </TableCell>
              <TableCell className={styles.T_body}>
                <DocumentsUpload
                  editable={false}
                  title="NOI Document"
                  listData={formatDocsData(obj.documents)}
                />
              </TableCell>
            </TableRow>
          ))}
          {mapArray?.length == 0 && (
            <TableRow>
              <TableCell colSpan={Headingdata.length}>
                <Grid container justifyContent="center">
                  NO Data Avalilable
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default PropertyNoiTable;
