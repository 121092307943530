import React from "react";
import HeaderButton from "../components/HeaderButton/HeaderButton";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Checkbox from "@material-ui/core/Checkbox";
import styles from "./manageColumnsMobile.module.scss";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { List, arrayMove } from "react-movable";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageColumnsMobile = ({
  handleToggle,
  columnNames,
  setColumnNames,
  checked,
  open,
  handleClickOpen,
  handleClose,
  handleSave,
  setChecked,
}) => {
  return (
    <div>
      <HeaderButton handleClick={handleClickOpen} />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className={styles.appBar} color="inherit" elevation={0}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <span className={styles.title}>Manage coulmns</span>
          </Toolbar>
        </AppBar>
        <List
          values={columnNames}
          onChange={({ oldIndex, newIndex }) => {
            let val = arrayMove(columnNames, oldIndex, newIndex);
            let result = val.filter((data) => checked.includes(data));
            setChecked(result);
            return setColumnNames(val);
          }}
          renderList={({ children, props }) => (
            <div className={styles.innerCardContainer} {...props}>
              {children}
            </div>
          )}
          renderItem={({ value, props }) => (
            <div className={styles.cardWrapper} {...props}>
              <ListItem>
                <ListItemIcon onClick={handleToggle(value)} key={value}>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    inputProps={{ "aria-labelledby": value }}
                  />
                </ListItemIcon>
                <ListItemText id={value} primary={value} />
              </ListItem>
              <IconButton aria-label="menu" className={styles.iconContainer}>
                <DragIndicatorIcon
                  className={styles.icon}
                  style={{ fontSize: 18 }}
                />
              </IconButton>
            </div>
          )}
        />
        <button
          className={styles.mobileViewButton}
          onClick={() => {
            handleSave();
            handleClose();
          }}
        >
          Save changes
        </button>
      </Dialog>
    </div>
  );
};

export default ManageColumnsMobile;
