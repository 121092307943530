import React, { useEffect, useState } from "react";
import styles from "../../../../../components/AppTable/AppTable.module.css";
import styles1 from "../../../investments/investments.module.css";
import downloadIcon from "assets/icons/downloadIcon.svg";
import DateRange from "components/DateRange/dateRange";
import SearchInput from "components/Search";
import Loader from "components/Loader/AppLoader";
import CloseIcon from "../../../../../assets/icons/cross.svg";
import { ManageDataService } from "network/manageDataService";
import formatDate from "helpers/formatDate";
import { AppDispatcher } from "../../../../../redux/index";
import { useSelector } from "react-redux";
import Illustrations from "components/illustration/Illustrations";
import noInvestments from "assets/emptyState/noInvestments.svg";
import { truncate } from "helpers/Common";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
import ManageColumn from "components/ManageColumns";
import CSV from "assets/icons/NewCardSvg/CSV.svg";
import FWDEmail from "assets/icons/NewCardSvg/FWDEmail.svg";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import NewTable from "components/NewTable/NewTable";
import FrwrdAsEmail from "components/ForwarsAsEmail";

const manageByList = [
  {
    label: "Uploaded By",
    value: "managedby"
  },
  {
    label: "Admin",
    value: "Admin"
  },
  {
    label: "Investor",
    value: "Investor"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "Pdf",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const TableHeaders = [
  {
    id: "document_name",
    name: "Document Name"
  },
  {
    id: "uploaded_by",
    name: "Uploaded By"
  },
  {
    id: "name",
    name: "Name"
  },
  {
    id: "upload_date",
    name: "Upload Date"
  },
  {
    id: "comments",
    name: "Comments"
  },
  {
    id: "documents",
    name: "Documents"
  }
];

function Table() {
  const [THeaders, setTHeaders] = useState(TableHeaders);
  const [manageBy, setManageBy] = useState("managedby");
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [Export, setExport] = useState("exportList");
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState([
    { key: "Investment Name", value: [] },
    { key: "Investor Name", value: [] },
    { key: "Distribution Type", value: [] }
  ]);
  const [filterState] = useState([]); //setFilterState
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [pageResetValue, setPageResetValue] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const { dateRange } = useSelector((store) => store.app);
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getTemplateData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const onDeletedate = () => {
    AppDispatcher.setDateRange({});
  };

  const getTemplateData = async (pageNum, isAppend = false) => {
    setLoading(true);
    let url = "";
    if (searchQuery.length > 0) {
      url += `${url.length ? "&" : "?"}search=${searchQuery}`;
    }
    if (url.length > 0) {
      url += `&page=${pageNum}&page_size=${pageSize}`;
    } else {
      url += `?page=${pageNum}&page_size=${pageSize}`;
    }

    if (manageBy !== "managedby") {
      url += `&uploaded_by=${manageBy}`;
    }

    if (Object?.keys(dateRange)?.length > 0) {
      url += `&custom=True&start_date=${dateRange["start_date"]}&end_date=${dateRange["end_date"]}`;
    }
    const response = await ManageDataService.getTemplateData([url]);
    if (response.success) {
      if (isAppend) {
        setData([...data, ...response.data.results]);
      } else {
        setData(response.data.results);
      }
      setCount(response.data.count);
    }
    setLoading(false);
  };

  const ChipsComponent = () => {
    let newArray = [];
    let newObj = {};
    let tempVal = [];
    let newName = {};
    checked.forEach((item) => {
      item.value.forEach((el) => {
        tempVal = filterState.find((el1) => el1.key === item.key).value;
        newObj.id = el;
        newName = tempVal.find((val) => val.id === el);
        newObj.name = newName.name
          ? newName.name
          : newName.first_name + " " + newName.last_name;
        newObj.key = item.key;
        newArray.push(newObj);
        newObj = {};
        tempVal = [];
        newName = {};
      });
    });
    return (
      <div className={styles1.chipsCtr}>
        {newArray.length > 0
          ? newArray.map((item, index) => {
              return (
                <div key={index} className={styles1.chips}>
                  <div className={styles1.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles1.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
        {dateRange["start_date"] && dateRange["end_date"] ? (
          <div className={styles1.chips}>
            <div
              className={
                styles1.chipsText
              }>{`${dateRange["start_date"]} To ${dateRange["end_date"]}`}</div>

            <div onClick={() => onDeletedate()}>
              <img className={styles1.chipsImg} src={CloseIcon} alt="cross" />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
  };

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getTemplateData(1);
  }, [searchQuery, pageSize, dateRange, manageBy]);

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = "";

    if (emailData) {
      if (preview === "preview") {
        url = `?${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url = `?${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url = `?${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }
    setExport("exportList");
    console.log(url);
  }

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  /******************************************************************** */
  /*********************       New Table Data           *****************/
  /*********************************************************************/

  const Tabledatalist =
    data &&
    data.map((item) => {
      return [
        <div key="document_name" className={`${styles.text} ${styles.hover}`}>
          {truncate(item?.name ? item?.name : "-", 20)}
        </div>,
        <div key="uploaded_by" className={styles.text}>
          <div className={styles.text}>{item?.uploaded_by}</div>
        </div>,
        <div key="name" title={item?.created_by?.name} className={styles.text}>
          {item?.created_by?.name ? (
            <>{truncate(item?.created_by?.name, 20)}</>
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>,
        <div key="upload_date" className={styles.text}>
          {formatDate(item?.created_at)}
        </div>,
        <div key="comments" className={styles.text}>
          {item?.comments || "-"}
        </div>,
        <div key="documents" className={styles.text}>
          <a
            download={`${item?.template_media?.file}`}
            target="_blank"
            rel="noopener noreferrer"
            href={item?.template_media?.file}>
            <img src={downloadIcon} alt="alt" />
          </a>
        </div>
      ];
    });

  /******************************************************************* */
  /******************************************************************* */
  /******************************************************************* */

  return (
    <>
      {loading && <Loader />}
      <FrwrdAsEmail
        AllHeaders={TableHeaders}
        FrdasEmail={FrdasEmail}
        setFrdasEmail={setFrdasEmail}
        open={frdEmailOpen}
        setOpen={setFrdasEmailOpen}
        clickOnShare={(payload) => {
          getExport_PDF_CSV_Link(payload);
        }}
        clickOnPreview={(payload) => {
          getExport_PDF_CSV_Link(payload, "preview");
        }}
      />
      <div className={styles.tableCtrOuter}>
        <TableTopFilter
          tabletViewCSV={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={CSV}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={ExportList}
                clickMenuItem={(obj, close) => {
                  setExport(obj?.value);
                  close();
                }}
              />
            </div>
          }
          tabletViewFWDEmail={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={FWDEmail}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={frdasEamilList}
                clickMenuItem={(obj, close) => {
                  setFrdasEmail(obj?.value);
                  close();
                }}
              />
            </div>
          }
          filterSection1={[
            <SearchInput setQuery={setQuery} />,
            <CustomSelectFilter
              key="0"
              border={true}
              placeholder="Managed By"
              items={manageByList}
              value={manageBy}
              onChange={(e) => setManageBy(e.target.value)}
            />,
            <DateRange />
          ]}
          filterSection2={[
            <ManageColumn
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Forward as Email"
              items={frdasEamilList}
              value={FrdasEmail}
              onChange={(e) => setFrdasEmail(e.target.value)}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Export"
              items={ExportList}
              value={Export}
              onChange={(e) => setExport(e.target.value)}
            />
          ]}
        />

        <ChipsComponent />

        <NewTable
          Header={[...THeaders]}
          tableData={Tabledatalist}
          pageSize={pageSize}
          changePage={changePage}
          changePageSize={changePageSize}
          count={count}
          pageResetValue={pageResetValue}
          labelRowsPerPage="Total No Of Templates Per Page"
          Illustration={
            <Illustrations
              src={noInvestments}
              heading="No Templates Found"
              subHeading="Add Templates To View"
            />
          }
        />
      </div>
    </>
  );
}
export default Table;
