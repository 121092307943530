import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import React from "react";
import styles from "./toggle.module.scss";

const ToggleSwitch = ({
  setSelectedIndex,
  selectedIndex,
  list,
  xs = 2,
  lg = 2,
  md = 2,
  sm = 2
}) => {
  return (
    <>
      {list?.map((item, index) => (
        <Grid
          item
          key={index}
          xs={xs}
          lg={lg}
          md={md}
          sm={sm}
          onClick={() => setSelectedIndex(item.id)}
          className={
            item.id === selectedIndex
              ? styles.toggleSwichActive
              : styles.toggleSwichInActive
          }>
          <div style={{ marginRight: 15, marginTop: 2 }}>
            <img
              alt="img"
              src={
                item.id === selectedIndex ? item.activeIcon : item.inActiveIcon
              }
              width={"22px"}
              height={"22px"}
            />
          </div>
          <Typography
            className={
              item.id === selectedIndex
                ? styles.toggleSwichActiveText
                : styles.toggleSwichInActiveText
            }>
            {item.name}
          </Typography>
        </Grid>
      ))}
    </>
  );
};

export default ToggleSwitch;
