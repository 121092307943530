import React from "react";
import Grid from "@material-ui/core/Grid";
import CloseIcon from "@material-ui/icons/Close";

const Heading = ({ handleClose, Text }) => {
  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      justify="space-between"
      alignItems="center">
      <Grid item xs={6}>
        <div style={{ fontWeight: "700", fontSize: 26 }}>Share {Text}</div>
      </Grid>
      <Grid item xs={6} style={{ textAlign: "end" }}>
        <div onClick={handleClose} style={{ cursor: "pointer" }}>
          <CloseIcon />
        </div>
      </Grid>
    </Grid>
  );
};

export default Heading;
