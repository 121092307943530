import * as React from "react";
import { useState } from "react";
import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import { Typography, makeStyles, MenuItem, Select } from "@material-ui/core";
import DotsMenu from "../../../../assets/icons/dotsMenu.svg";
import styles from "./changeStatus.module.scss";
import { useHistory } from "react-router-dom";
import { NetworkManager, Endpoint } from "../../../../network/core/index";
import { useSelector } from "react-redux";
import Toast from "../../../../components/toast";
import Loader from "../../../../components/Loader/AppLoader";
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";

const useHeaderStyle = makeStyles(() => ({
  editProfileText: {
    fontFamily: "Inter Bold",
    fontSize: 28
  },
  subheading: {
    fontSize: 16,
    fontFamily: "Inter Regular",
    color: "#616161"
  },
  heading: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    marginBottom: 10
  }
}));

const ChangeStatus = (setOpenModal) => {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState([]);
  const history = useHistory();
  const [currentStatus, setCurrentStatus] = useState("-");
  const [initValues, setInitValues] = useState({
    first_name: "-",
    last_name: "-",
    date: "",
    status: "-"
  });

  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(setOpenModal);
  };

  const { investorId } = useSelector((store) => store.app);

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("/");
  };

  const fetchUserDetail = async () => {
    const instance = new NetworkManager(
      new Endpoint(`investor/${investorId}/`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      let temp = resp.data;
      let tempData = { ...initValues };
      tempData["first_name"] = temp.first_name;
      tempData["last_name"] = temp.last_name;
      tempData["date"] = temp.created_at;
      tempData["status"] = temp.status;
      setInitValues(tempData);
      setCurrentStatus(temp.status);
    } else {
      Toast.showErrorToast("Not Working");
    }
  };

  React.useEffect(() => {
    fetchUserDetail();
  }, []);

  const classes = useHeaderStyle();
  const handleClose = () => {
    setOpen(false);
    history.push("/u/investors");
  };

  const getStatus = async () => {
    const instance = new NetworkManager(
      new Endpoint(`status/${investorId}/`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      setStatus(resp.data);
    } else {
      Toast.showErrorToast("Not Success");
    }
  };

  React.useEffect(() => {
    getStatus();
  }, []);

  const handleStatusChange = (e) => {
    let tempData = { ...initValues };
    tempData["status"] = e.target.value;
    setInitValues(tempData);
  };

  const updateInvestorDetails = async () => {
    const payload = {
      status: initValues.status
    };
    setLoading(true);
    try {
      const instance = new NetworkManager(
        new Endpoint(`admin/change-status/${investorId}/`, "PATCH"),
        payload
      );
      const resp = await instance.httpRequest();
      if (resp.success) {
        setOpen(false);
        Toast.showSuccessToast("Status Updated Successfully");
        history.push("/u/investors");
      } else {
        Toast.showErrorToast("Status Not Updated");
      }
    } catch (e) {
      Toast.showErrorToast("Something Went Wrong");
    } finally {
      setLoading(false);
      history.push("/u/investors");
    }
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <img src={DotsMenu} style={{ height: 20, width: 20 }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{
          backgroundColor: "rgba(235, 239, 255, 0.6)",
          backdropFilter: "blur(26)"
        }}>
        {loading ? (
          <Loader />
        ) : (
          <>
            <DialogContent>
              <Grid
                container
                style={{ padding: 40, width: 458 }}
                justifyContent="center">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    <Typography variant="h4">Change Status</Typography>
                  </Grid>
                  <Grid item>
                    <img
                      src={CloseIcon}
                      onClick={handleClose}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginTop: 40 }}>
                  <Grid item>
                    <Typography className={classes.heading}>
                      Investor Name
                    </Typography>
                    <Typography variant="h5">{`${initValues.first_name} ${initValues.last_name}`}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.heading}>
                      Registration Date
                    </Typography>
                    <Typography variant="h5">
                      {initValues.date !== ""
                        ? formatDate(initValues.date)
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  xs={12}
                  direction="row"
                  justifyContent="space-between"
                  style={{ marginTop: 20 }}>
                  <Grid item>
                    <Typography className={classes.heading}>
                      Current Status
                    </Typography>
                    <Typography
                      variant="h5"
                      style={{
                        minHeight: 28,
                        backgroundColor:
                          currentStatus === "Pending"
                            ? "#EBEFFF"
                            : currentStatus == "Active"
                            ? "#EDFFEF"
                            : "#FCEBEB ",
                        borderRadius: 153,
                        fontFamily: "Inter Bold",
                        fontSize: 14,
                        paddingLeft: 14,
                        paddingRight: 14,
                        paddingTop: 4,
                        textAlign: "center",
                        color:
                          currentStatus == "Active"
                            ? "#50E138"
                            : currentStatus === "Pending"
                            ? "#445EBE"
                            : "#E13838",
                        fontWeight: 700
                      }}>
                      {currentStatus}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="column"
                  xs={12}
                  style={{ marginTop: 40 }}>
                  <Typography className={classes.heading}>
                    Change Status To
                  </Typography>
                  <Select
                    id="dropdown"
                    value={initValues.status}
                    onChange={(e) => handleStatusChange(e)}
                    disableUnderline={true}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left"
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left"
                      },
                      getContentAnchorEl: null
                    }}
                    style={{
                      fontFamily: "Inter Regular",
                      fontWeight: 600,
                      fontSize: 16,
                      height: 48,
                      border: "1px solid #BBBBBB",
                      borderRadius: 8,
                      paddingLeft: 10,
                      paddingRight: 10
                    }}>
                    {status.map((item) => (
                      <MenuItem
                        onMouseEnter={(e) =>
                          (e.target.style.backgroundColor = "#F5F7FB")
                        }
                        onMouseLeave={(e) =>
                          (e.target.style.backgroundColor = "#ffffff")
                        }
                        key={item.id}
                        value={item.value.toString()}
                        style={{
                          fontSize: 14,
                          fontFamily: "Inter Regular",
                          marginTop: 5
                        }}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid container xs={12} justifyContent="center" direction="row">
                  <div className={styles.changepasswordbutton}>
                    <button
                      type="submit"
                      className={styles.updatebtn}
                      onClick={updateInvestorDetails}>
                      Save
                    </button>
                    <button
                      className={styles.cancelbtn}
                      type="button"
                      onClick={handleClose}>
                      Cancel
                    </button>
                  </div>
                </Grid>
              </Grid>
              {/* <div className={styles.MaterialForm}>
            <div className={styles.editProfile}>
              <Typography className={classes.editProfileText}>
                Change Status
              </Typography>
            </div>

            <Typography className={classes.subheading}>
              Change the status to reflect on investor side
            </Typography>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "row",
                marginTop: 40,
                width: 330
              }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.heading}>Investor name</div>
                <div className={styles.name}>{initValues.name}</div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className={styles.heading}>Registration Date</div>
                <div className={styles.name}>
                  {initValues.date !== "" ? formatDate(initValues.date) : "-"}
                </div>
              </div>
            </div>
            <div
              style={{
                marginTop: 40,
                display: "flex",
                flexDirection: "column"
              }}>
              <div style={{ marginBottom: 4 , fontFamily: "Inter Regular", color: "#191919"}}>Change Status to</div>
              <Select
                id="dropdown"
                value={initValues.status}
                onChange={(e) => handleStatusChange(e)}
                disableUnderline = {true}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                style={{
                  width: 340,
                  fontFamily: "Inter Regular",
                  fontWeight: 600,
                  fontSize: 16, 
                  height: 48,
                  border: "1px solid #BBBBBB",
                  borderRadius: 8, 
                  paddingLeft: 10,
                  paddingRight: 10,

                }}>
                {status.map((item) => (
                  <MenuItem
                    onMouseEnter={(e) =>
                      (e.target.style.backgroundColor = "#F5F7FB")
                    }
                    onMouseLeave={(e) =>
                      (e.target.style.backgroundColor = "#ffffff")
                    }
                    key={item.id}
                    value={item.value.toString()}
                    style={{
                      fontSize: 14,
                      fontFamily: "Inter Regular",
                      marginTop: 5
                      
                    }}>
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className={styles.changepasswordbutton}>
              <button className={styles.cancelbtn} onClick={handleClose}>
                Cancel
              </button>
              <button
                className={styles.updatebtn}
                onClick={updateInvestorDetails}>
                Update
              </button>
            </div>
          </div> */}
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ChangeStatus;
