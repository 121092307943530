import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import FieldHeading from "components/fieldHeading";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import Toast from "components/toast";
import { FieldArray, Form, Formik } from "formik";
import { truncate } from "helpers/Common";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { validationSchemaAddContancts } from "helpers/validationSchema";
import { ContactsService } from "network/contactsService";
import Contacts from "pages/private/investments/addInvestment/steps/step1/components/contacts";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles({
  MainGrid: { margin: "40px 0 0 0" },
  Gridcontainer: {
    // backgroundColor: "#FAFAFA",
    backgroundColor: "#ffff",
    padding: "0px 20px 20px 20px",
    borderRadius: 8
  },
  GridItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: "20px"
  },
  addTier: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    width: 160,
    marginTop: 10
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "55px"
  },
  buttonCancel: {
    fontFamily: "Inter SemiBold",
    fontSize: 16,
    height: 45,
    minWidth: 180,
    padding: "0px 30px !important",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: "#5775E5",
    cursor: "pointer",
    border: "1px solid #5775E5",
    marginRight: "10px"
  },
  submitButton: {
    backgroundColor: "#5775E6",
    fontFamily: "Inter SemiBold",
    fontSize: 16,
    height: 45,
    minWidth: 180,
    padding: "0px 30px !important",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffff",
    cursor: "pointer",
    border: "1px solid #5775E5",
    "&:hover": {
      backgroundColor: "#5775E6",
      fontFamily: "Inter SemiBold",
      fontSize: 16,
      height: 45,
      minWidth: 180,
      padding: "0px 30px !important",
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#ffff",
      cursor: "pointer",
      border: "1px solid #5775E5"
    }
  }
});

const EditContacts = () => {
  const classes = useStyle();
  const { contactsId, userPermissions, user } = useSelector(
    (store) => store.app
  );

  const history = useHistory();
  const [defaultvalue] = useState(contactsId?.is_primary ? "Yes" : "No");
  const [initialValues, setInitailValues] = useState({
    investment_name: "",
    associatedContactsSet: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: null,
        country_code: "+1",
        designation: "",
        company_name: "",
        is_primary: true
      }
    ]
  });

  const [payload, setPayload] = useState({
    investment: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: null,
    country_code: null,
    designation: "",
    company_name: "",
    is_primary: true
  });

  const skeleton = (input) => {
    return payload.investment !== ""
      ? input
      : <Skeleton width={150} variant="text" /> || "-";
  };

  // get Specific Contacts associated with contact id
  const getContactDetails = async () => {
    const response = await ContactsService.getSingleContact(
      `${contactsId?.id}/`
    );
    if (response.success) {
      const data = response.data;
      setPayload({ ...payload, ["investment"]: data.investment.id });
      setInitailValues({
        investment_name: data.investment.name,
        associatedContactsSet: [
          {
            first_name: data["first_name"],
            last_name: data["last_name"],
            email: data["email"],
            phone_number: data["phone_number"],
            country_code: data["country_code"],
            designation: data["designation"],
            company_name: data["company_name"],
            is_primary: data["is_primary"]
          }
        ]
      });
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "CONTACTS",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_update) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    checkPermissions();
    getContactDetails();
  }, []);

  //sumbmit form and update data
  const handleUpdateContact = (values) => {
    const data = values.associatedContactsSet[0];
    setPayload({
      ...payload,
      ["first_name"]: data.first_name,
      ["last_name"]: data.last_name,
      ["email"]: data.email,
      ["phone_number"]: data.phone_number,
      ["country_code"]: data.country_code,
      ["designation"]: data.designation,
      ["company_name"]: data.company_name,
      ["is_primary"]: data.is_primary
    });
  };

  const sendUpdatedContactData = async () => {
    if (payload.first_name && payload.designation) {
      const response = await ContactsService.editContact(payload, [
        `${contactsId?.id}/`
      ]);
      if (response.success) {
        Toast.showSuccessToast("Contact Updated Successfully");
        history.push("/u/contacts");
      } else {
        return Toast.showErrorToast("Failed to Update Conatct");
      }
    }
  };
  useEffect(() => {
    sendUpdatedContactData();
  }, [handleUpdateContact]);

  return (
    <>
      <HeaderComponent
        heading="Edit contacts"
        description="Edit Relevent Details To The Specific Investor And Investment Profile"
        addInvestorBtn={false}
        backBtn
      />
      <Grid className={classes.MainGrid}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaAddContancts}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleUpdateContact(values);
          }}>
          {(formik) => (
            <Form>
              <Grid container className={classes.Gridcontainer}>
                <Grid
                  container
                  item
                  style={{ padding: "0px  20px 20px 20px" }}
                  direction="column">
                  <FieldHeading title={"Investment Name"} />
                  <Typography variant="h5" component="div">
                    {skeleton(
                      truncate(formik.initialValues.investment_name, 25)
                    )}
                  </Typography>
                </Grid>
                <Grid item xs className={classes.GridItem}>
                  <FieldArray
                    name="associatedContactsSet"
                    render={(arrayHelper) => (
                      <>
                        {formik.values.associatedContactsSet.map(
                          (sponsors, index) => (
                            <Contacts
                              boxType={true}
                              type={true}
                              ClickonContactsEdit={true}
                              key={index}
                              classes={classes}
                              id={index}
                              arrayHelper={arrayHelper}
                              formik={formik}
                              editContact
                              defaultValue={defaultvalue}
                            />
                          )
                        )}
                      </>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.btnContainer}>
                <Grid item>
                  <Button
                    className={classes.buttonCancel}
                    onClick={() => {
                      history.push("/u/contacts");
                    }}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  {/* <AddBtn text="Add Contacts" type="submit" /> */}
                  <Button type="onSubmit" className={classes.submitButton}>
                    Update
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>
    </>
  );
};

export default EditContacts;
