import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { numFormatter } from "helpers";
import { formatDate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import React from "react";
import styles from "../../EntityDetail.module.scss";
import InvestmentTable from "./InvestmentTable";

const Investments = ({ data }) => {
  const heading = [
    "Investment Name",
    "Last Updated On",
    "Managed By",
    "Amount Invested",
    "Total Asset Value",
    "Outstanding Loan",
    "Equity",
    data?.entity_type === "SPONSOR" ? null : "Current Value",
    "Status"
  ];

  const tableData = data?.investment?.map((item) => {
    return {
      0: item?.investment?.name || item?.name || "-",
      1:
        item?.investment?.updated_at || item?.updated_at
          ? formatDate(item?.investment?.updated_at || item?.updated_at)
          : "-",
      2: item?.investment?.managed_by || item?.managed_by || "-",
      3: formatCurrency(item?.stats?.total_invested_amount || "-" || "0"),
      4: formatCurrency(item?.equity?.total_asset || "0"),
      5: formatCurrency(item?.equity?.outstanding || "0"),
      6: formatCurrency(item?.equity?.equity_amount || "0"),
      7:
        data?.entity_type === "SPONSOR"
          ? null
          : formatCurrency(item?.stats?.current_value || "0"),
      8: item?.investment?.status || item?.status || "-",
      id: item?.id,
      toolTipData: [
        ["Investment ID:", item?.base_id || item?.investment?.base_id],
        ["Total Properties:", item?.properties.length],
        [
          data?.entity_type === "SPONSOR" ? null : "Net Equity: ",
          data?.entity_type === "SPONSOR"
            ? null
            : formatCurrency(item?.stats.net_equity)
        ],

        [
          data?.entity_type === "SPONSOR" ? null : "Membership: ",
          data?.entity_type === "SPONSOR"
            ? null
            : item?.member_percent
            ? `${item?.member_percent} %`
            : null || "-"
        ],
        [
          "Total Distribution Amount:",
          `$${numFormatter(item?.stats?.distribution_amount)}`
        ]
      ],
      tableType: false
    };
  });

  return (
    <>
      <Grid item xs={12} className={styles.overviewMainContainer}>
        <Grid container>
          <Typography className={styles.cardHeading}>
            Associated Investments
          </Typography>
        </Grid>
        <Grid container>
          <InvestmentTable
            data={data?.investment}
            tableData={tableData}
            heading={heading}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Investments;
