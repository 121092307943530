import {
  Dialog,
  Grid,
  makeStyles,
  Step,
  StepButton,
  StepConnector,
  StepLabel,
  Stepper,
  withStyles
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import clsx from "clsx";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import Step1 from "./step1/index";
import Step2 from "./step2/index";
import Step3 from "./step3/index";
import { AppDispatcher } from "redux/index";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 1113,
      height: "fit-content",
      padding: 20
    }
  },
  stepperContainer: {
    padding: 30
  },
  label: {
    fontFamily: "Inter SemiBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#5775E6"
  },
  label1: {
    fontFamily: "Inter SemiBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#A0A0A0"
  }
}));

const useQontoStepIconStyles = makeStyles({
  root: {
    color: "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center"
  },
  active: {
    color: "#5775E6"
  },
  circle1: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    backgroundColor: "#5775E6"
  },
  circle2: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "45px",
    height: "45px",
    borderRadius: "50%",
    backgroundColor: "#A0A0A0",
    color: "#FFF"
  },
  completed: {
    color: "#5775E6",
    zIndex: 1,
    fontSize: 18
  }
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)"
  },
  active: {
    "& $line": {
      borderColor: "#5775E6"
    }
  },
  completed: {
    "& $line": {
      borderColor: "#5775E6"
    }
  },
  line: {
    border: "none",
    borderBottom: "3px dashed #A0A0A0",
    borderRadius: 1
  }
})(StepConnector);

const UploadTemplate = () => {
  const history = useHistory();
  const classes = useStyles();
  const [steps] = useState(["Download Template", "Upload File", "Verify Data"]);
  const { uploadTemplateActiveStep } = useSelector((store) => store.app);
  const [open, setOpen] = React.useState(true);
  const loadStep = () => {
    switch (uploadTemplateActiveStep) {
      case 0:
        return <Step1 setOpen={setOpen} />;
      case 1:
        return <Step2 setOpen={setOpen} />;
      case 2:
        return <Step3 setOpen={setOpen} />;
      default:
        return;
    }
  };

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    const icons = {
      1: 1,
      2: 2,
      3: 3
    };

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active
        })}>
        {completed ? (
          <div className={classes.circle1}>{icons[String(props.icon)]}</div>
        ) : (
          <div className={classes.circle2}>{icons[String(props.icon)]}</div>
        )}
      </div>
    );
  }

  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool
  };

  return (
    <Dialog className={classes.root} open={open} keepMounted>
      <Grid container justifyContent="flex-end">
        <Grid item style={{ cursor: "pointer", margin: 10 }}>
          <CloseIcon
            onClick={() => {
              setOpen(false);
              AppDispatcher.setGroupBy("Template");
              history.push("/u/manage_data");
            }}
          />
        </Grid>
      </Grid>
      <div className={classes.stepperContainer}>
        <Stepper
          className={classes.stepper}
          activeStep={uploadTemplateActiveStep}
          connector={<QontoConnector />}>
          {steps.map((label) => (
            <Step key={label}>
              <StepButton>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {<p className={classes.label}>{label}</p>}
                </StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </div>

      {loadStep()}
    </Dialog>
  );
};

export default UploadTemplate;
