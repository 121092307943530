import { Menu, MenuItem } from "@material-ui/core";
import React from "react";

function TabletVIewSelectMenu({ image, menuItems = [], clickMenuItem }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}>
        {image}
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        {menuItems?.map(
          (itemObj, i) =>
            i !== 0 && (
              <MenuItem
                key={i}
                onClick={() => clickMenuItem(itemObj, handleClose)}>
                {itemObj?.label}
              </MenuItem>
            )
        )}
      </Menu>
    </>
  );
}

export default TabletVIewSelectMenu;
