import { Grid } from "@material-ui/core";
import React from "react";
import styles from "./NewComponent.module.scss";

const OverViewTable = ({ mapArray }) => {
  return (
    <>
      <Grid container spacing={2} className={styles.OvrV_con_table}>
        {mapArray?.map((val, i) => (
          <Grid key={i} item xs={2} className={`${styles.item_c}`}>
            <h4 className={styles.heading1}> {val.title} </h4>
            <h2 className={styles.value1}>{val.value} </h2>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default OverViewTable;
