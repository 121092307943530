import { Grid, Typography } from "@material-ui/core";
import { formatDate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import React from "react";
import CommonPropertyDetalTable from "./CommonPropertyDetailTable";
import style from "./propertiesProfile.module.scss";

const heading = [
  "Year",
  "Month",
  "Units Leased",
  "Occupancy",
  "Rent Potential",
  "Rent Collected",
  "Collections",
  "Date of Addition",
  "File"
];

function OccupancyTable({ propertyData }) {
  const tableData = propertyData?.property_occupancy?.map((item) => {
    return {
      0: item?.year || "-",
      1: item?.month || "-",
      2: item?.units_leased || 0,
      3: `${item?.occupancy || 0} %`,
      4: formatCurrency(item?.rent_potential || 0),
      5: formatCurrency(item?.rent_collected || 0),
      6: `${item?.collections || 0} %`,
      7: formatDate(item?.created_at),
      8: item?.documents,

      tableType: false
    };
  });

  return (
    <Grid item xs={12} className={style.overviewMainContainer}>
      <Grid container>
        <Typography className={style.cardHeading}>Occupancy</Typography>
      </Grid>
      <Grid container>
        <CommonPropertyDetalTable
          data={propertyData?.investment}
          tableData={tableData}
          heading={heading}
          type="occupancy"
        />
      </Grid>
    </Grid>
  );
}

export default OccupancyTable;
