import { Grid, makeStyles, Typography } from "@material-ui/core";
import Calender from "components/calender";
import FieldHeading from "components/fieldHeading";
import CustomSelect from "components/StyledComponents/Select";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import React, { useState, useEffect } from "react";
// import SelectInvestment from "./selectInvestment";
import { InvestmentListService } from "network/investment_ListService";
import TextArea from "components/TextArea";
import deleteIcon from "assets/icons/deleteIcon.svg";
import NewAutoComplete from "components/NewAutoComplete/NewAutoComplete";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: 36,
    borderRadius: 20
  },

  propertyLocation: {
    color: "#A0A0A0",
    fontWeight: 400,
    fontSize: "14px"
  },
  searchAddress: {
    color: "#191919",
    fontWeight: 600,
    fontSize: "16px"
  },

  mapView: {
    height: "450px",
    paddingTop: "34px"
  },

  accordionContainer: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white"
    }
  },
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    marginTop: 18,
    width: "max-content"
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 10,
    marginTop: 20,
    marginLeft: 20
  },
  heading: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0,
    marginRight: 20,
    flexShrink: 0
  },
  divider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: "#E9E9E9"
  },
  whiteContainer: {
    backgroundColor: "#fff",
    margin: 20,
    width: "auto"
  },
  grayContainer: {
    backgroundColor: "#FAFAFA",

    width: "100%",
    borderRadius: 12
  },
  container: {
    width: "100%"
  },
  noi: {
    margin: "0px 20px"
  },
  btnPrimary: {
    border: "#5775E5",
    backgroundColor: "#5775E5",
    color: "#ffffff",
    width: 218,
    height: 50,
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer"
  },
  btnSecondary: {
    width: 218,
    margin: "0 0px 0 22px",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    cursor: "pointer",
    border: "1px solid #445EBE"
  },
  mainContainer: {
    cursor: "pointer",
    padding: "16px",
    "&:hover": {
      background: "#F6F8FD"
    }
  },
  lighttext: {
    padding: "3px 0px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#616161"
  },
  darktext: {
    color: "#000000"
  },
  cellWidth50: {
    minWidth: 50
  },
  cellWidth120: {
    minWidth: 120
  },
  cellWidth150: {
    minWidth: 150
  },
  cellWidth170: {
    minWidth: 170
  },
  cellWidth180: {
    minWidth: 180
  },
  doctableHeading: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  scrollXContainer: {
    overflowX: "scroll",
    overflowY: "hidden"
  }
}));

const BasicInformationSection = ({
  styles,
  index,
  arrayHelper,
  formik,
  lists,
  invData,
  setSelectedCurrentYear,
  selectedCurrentYear,
  setInvestmentId
}) => {
  const classes = useStyles();
  const [minYear, setMinYear] = useState(2000);

  const [investmentNamesearch, setInvestmentNamesearch] = useState("");
  const [investmentList, setInvestmentList] = useState([]);

  const handleInvestmentNameFromData = (data) => {
    setInvestmentNamesearch("");
    formik.setFieldValue("investmentName", data?.name);

    setInvestmentId(data?.id);

    formik.setFieldValue(
      `distributionSet[${index}].selectInvestement`,
      data?.id
    );
    formik.setFieldValue(`distributionSet[${index}].investmentData`, data);

    const date = data?.start_date.split("-");
    setMinYear(parseInt(date[0]));
    formik.setFieldValue(
      `distributionSet[${index}].distMonth`,
      parseInt(date[1])
    );
    formik.setFieldValue(
      `distributionSet[${index}].minYear`,
      parseInt(date[0])
    );
  };

  const handleDelete = () => {
    arrayHelper.remove(index);
  };

  const investmentDataList = investmentList?.map((item, idx) => {
    return (
      <div
        key={idx}
        className={classes.mainContainer}
        onClick={() => handleInvestmentNameFromData(item)}>
        <Typography variant="button">{item?.name ?? "---"}</Typography>
        <div className={classes.lighttext}>
          Managed By:
          <span className={classes.darktext}> {item?.managed_by ?? "-"}</span>
        </div>
      </div>
    );
  });

  React.useEffect(() => {
    formik.setFieldValue(`distributionSet[${index}].date`, invData.startDate);
  }, [invData]);
  const distributionErrors =
    (formik.errors.distributionSet &&
      formik.errors.distributionSet.length &&
      formik.errors.distributionSet[index]) ||
    {};
  const distributionTouched =
    (formik.touched.distributionSet &&
      formik.touched.distributionSet.length &&
      formik.touched.distributionSet[index]) ||
    {};

  const setDistYear = (value) => {
    formik.setFieldValue(`distributionSet[${index}].year`, value);
    if (value === minYear) {
      let yearsSelected = [...selectedCurrentYear];
      yearsSelected[index] = true;
      setSelectedCurrentYear(yearsSelected);
    } else if (selectedCurrentYear[index]) {
      let yearsSelected = [...selectedCurrentYear];
      yearsSelected[index] = false;
      setSelectedCurrentYear(yearsSelected);
    }
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;
    if (searchText) {
      params += `&search=${searchText}`;
    }

    const response = await InvestmentListService.getInvestmentListService([
      params
    ]);
    if (response.success) {
      setInvestmentList(response.data.results);
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    setInvestmentList([]);

    return { data: [], maxPage: 1 };
  };

  useEffect(() => {
    getInvestmentName(1, investmentNamesearch);
  }, [investmentNamesearch]);

  return (
    <>
      <Grid container className={styles.innerContainer}>
        <div className={styles.sectionHeader}>
          <div className={styles.sectionTitle}>Distribution {index + 1}</div>
          {index !== 0 && (
            <>
              <div className={styles.divider}></div>
              <span className={styles.delete} onClick={handleDelete}>
                <img src={deleteIcon} alt="deleteIcon" />
              </span>
            </>
          )}
        </div>
        <Grid
          container
          style={{ background: "#FAFAFA", padding: "10px" }}
          spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FieldHeading title="Select Investment" />
            <NewAutoComplete
              placeholder="Investment Name"
              search={investmentNamesearch}
              setSearchQuery={setInvestmentNamesearch}
              dataList={investmentDataList}
              fieldKey="investmentName"
              fieldKeyPlacehodler="investmentNamePlaceholder"
              formik={formik}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <FieldHeading title="Distribution Date" />
            <Calender
              defaultDate={invData.startDate}
              setDate={(date) => {
                formik.setFieldValue(`distributionSet[${index}].date`, date);
              }}
              error={
                distributionTouched.date && Boolean(distributionErrors.date)
              }
              helperText={distributionTouched.date && distributionErrors.date}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldHeading title="Distribution Type" />
            <CustomSelect
              fullWidth
              defaultValue={formik.values.distributionSet[index].type}
              items={lists.distributionType}
              error={distributionErrors.type}
              touched={distributionTouched.type}
              onChange={(e) =>
                formik.setFieldValue(
                  `distributionSet[${index}].type`,
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldHeading title="Select Year of Distributions" />
            <SelectWithInfiniteScroll
              fullWidth={true}
              minValue={formik?.values?.distributionSet[index]?.minYear}
              error={distributionErrors.year}
              touched={distributionTouched.year}
              onChange={(value) => setDistYear(value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <FieldHeading title={"Notes"} />
            <TextArea
              // disabled={!isInvestorEdit && isEdit}
              id="notes"
              value={formik.values.distributionSet[index]?.notes}
              onChange={(e) =>
                formik.setFieldValue(
                  `distributionSet[${index}].notes`,
                  e.target.value
                )
              }
              placeholder="Add Notes"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default BasicInformationSection;
