import { Grid } from "@material-ui/core";
import React from "react";

const ToolTipDataComponent = ({ data, index }) => {
  return (
    <Grid>
      {data[index].map((item, indexx) => {
        return (
          <Grid
            container
            key={indexx}
            spacing={3}
            style={{
              padding: "10px 10px",
              minWidth: "350px",
              maxHeight: "auto"
            }}>
            <Grid
              item
              xs={6}
              style={{
                color: "#A0A0A0",
                fontSize: "16px",
                // margin: "5px 10px",
                whiteSpace: "nowrap"
              }}>
              {item[0]}
            </Grid>
            {/* <Grid item xs={3}></Grid> */}
            <Grid
              item
              xs={6}
              style={{
                // margin: "5px 10px",
                color: "#191919",
                fontSize: "16px",
                fontWeight: "600",
                whiteSpace: "nowrap",
                display: "flex",
                justifyContent: "flex-end"
              }}>
              {item[1]}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default ToolTipDataComponent;
