import React, { useEffect, useRef, useState } from "react";
import { Badge, Grid, makeStyles } from "@material-ui/core";
import DeleteDocIcon from "assets/icons/deleteDocIcon.svg";
import newuploadDocIcon from "assets/icons/attachment.svg";

import { CovertToMb, truncate } from "helpers/Common";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    borderRadius: 4,
    cursor: "pointer",
    justifyContent: "flex-start"
  },
  title: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0,
    marginRight: 5
  },
  count: {
    minWidth: 18,
    minHeight: 18,
    backgroundColor: "#445EBE",
    borderRadius: "50%",
    color: "#FFFFFF",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    margin: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  add: {
    marginRight: 5,
    cursor: "pointer",
    width: 20,
    height: "100%",
    display: "flex",
    justifyContent: "center"
  },
  expand: {
    marginLeft: 15
  },
  progress: {
    width: 18,
    margin: "0 5px"
  },
  docNameContainer: {
    maxWidth: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  docName: {
    fontFamily: "Inter Bold",
    textDecoration: "none",
    fontSize: 16,
    fontWeight: 600,
    // letterSpacing: 0,
    color: "#445EBE"
  },
  deleteDoc: {
    width: 20,
    marginLeft: 10,
    cursor: "pointer"
  },
  docContainer: {
    display: "flex",
    // width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  docImg: {
    height: 20,
    width: 20,
    borderRadius: 8
  },
  addFile: {
    cursor: "pointer"
  },
  docContainerWidth: {
    display: "flex",
    alignItems: "center"
  }
}));

const ContactSendEmailDocument = ({
  filterDocList = [],
  title = "",
  type = "",
  listData = [],
  editable = true,
  setSize = () => {},
  setWholeFile = () => {}
}) => {
  const other = title.toLocaleLowerCase() === "other";
  const classes = useStyles();
  const [docs, setDocs] = useState([]);
  const inputFile = useRef(null);

  const addFile = (e) => {
    e.stopPropagation();
    inputFile.current.click();
  };

  const [file, setFile] = useState([]);

  function uploadSingleFile(e) {
    const newFile = e.target.files[0];
    setFile([...file, newFile]);
  }

  useEffect(() => {
    setWholeFile(file);
  }, [file.length]);

  function deleteFile(e) {
    const s = file.filter((item, index) => index !== e);
    setFile(s);
  }

  function getSize(data) {
    let sum = 0;
    data.forEach((el) => {
      sum += el.size;
    });
    return CovertToMb(sum);
  }

  useEffect(() => {
    if (other) {
      let selectedDocs = [];
      listData.forEach((el) => {
        if (!filterDocList.find((type) => el?.type === type?.id)) {
          selectedDocs.push(el);
        }
      });

      setSize(getSize(selectedDocs));
      setDocs(selectedDocs);
    } else {
      type
        ? (setDocs(listData.filter((e) => e?.type === type)),
          setSize(getSize(listData.filter((e) => e?.type === type))))
        : (setDocs(listData), setSize(getSize(listData)));
    }
  }, [filterDocList.length, listData.length]);

  return (
    <>
      <Grid className={classes.container}>
        <div
          onClick={addFile}
          className={classes.title}
          style={{ color: !editable && docs.length ? "#445EBE" : "#191919" }}>
          <div onClick={!addFile} className={classes.addFile}>
            <Badge
              badgeContent={file.length}
              style={{ color: "#5775E5" }}
              color="primary">
              <img height={35} width={35} src={newuploadDocIcon} alt="upload" />
            </Badge>
          </div>
        </div>
        {editable ? (
          <>
            <input
              type="file"
              id="file"
              ref={inputFile}
              disabled={file.length === 5}
              style={{ display: "none" }}
              onChange={uploadSingleFile}
            />
          </>
        ) : null}

        <Grid style={{ display: "flex", justifyContent: "space-between" }} item>
          {file.length > 0 &&
            file.map((item, index) => {
              return (
                <Grid
                  style={{ display: "flex", justifyContent: "space-between" }}
                  item>
                  <Grid
                    item
                    style={{
                      textDecoration: "underline",
                      paddingLeft: "10px",
                      fontWeight: 600,
                      fontSize: "16px",
                      color: "#445EBE"
                    }}>
                    {truncate(item?.name, 14)}
                  </Grid>

                  <img
                    onClick={() => deleteFile(index)}
                    src={DeleteDocIcon}
                    alt="upload"
                    className={classes.docImg}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </>
  );
};

export default ContactSendEmailDocument;
