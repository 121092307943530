import { Grid, Typography } from "@material-ui/core";
import { numFormatter } from "helpers";
import React from "react";
import Styles from "../EntityDetail.module.scss";

function OverviewToolTip({ data = [] }) {
  return (
    <>
      {data?.map((value, indx) => {
        return (
          <Grid key={indx} justifyContent="space-between" container>
            <Grid item>
              <Typography className={Styles.toolTipInfoData1}>
                {value[0]}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={Styles.toolTipInfoData2}>
                ${numFormatter(value[1])}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

export default OverviewToolTip;
