import { makeStyles, Menu, MenuItem } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ExpandIcon from "assets/icons/expandIconWhite.svg";
import CollapseIcon from "assets/icons/collapseIconWhite.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getScreenPermissions } from "helpers/permissionChecker";
import { AppDispatcher } from "../../../../redux/index";

const useStyle = makeStyles(() => ({
  button: {
    backgroundColor: "#5775E6",
    padding: "4px 22px",
    borderRadius: 100,
    height: 48,
    boxSizing: "border-box",
    width: 150,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 20
  },
  divider: {
    height: "100%",
    width: 1,
    backgroundColor: "#FFFFFF",
    margin: "0 14px"
  },
  addText: {
    fontFamily: "Inter SemiBold",
    fontSize: 18,
    color: "#FFFFFF",
    width: 70,
    textAlign: "center"
  },
  menu: {
    "& .MuiMenu-paper": {
      height: 187,
      width: 224,
      boxShadow: "rgb(233 233 233) 5px 5px 15px",
      marginTop: 10,
      borderRadius: 16,
      overflowY: "scroll"
    }
  },
  itemContainer: {
    height: 35,
    width: "100%",
    padding: "8px 20px",
    fontFamily: "Inter Regular",
    fontSize: 14,
    color: "#616161",
    "&:hover": {
      backgroundColor: "#F5F7FB"
    }
  }
}));

const AddButton = () => {
  const classes = useStyle();
  const [isExpanded, setIsExpanded] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const { userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const [menuItems, setMenuItems] = useState([
    {
      label: "Investor",
      name: "INVESTORS",
      url: "/u/investors/addinvestors"
    },
    {
      label: "Investments",
      name: "INVESTMENTS",
      url: "/u/investments/addInvestment"
    },
    {
      label: "Properties",
      name: "PROPERTIES",
      url: "/u/properties/addProperties"
    },
    {
      label: "Distributions",
      name: "DISTRIBUTIONS",
      url: "/u/distributions/addDistribution"
    },
    {
      label: "Sponsors",
      name: "SPONSORS",
      url: "/u/sponsors/addSponsor"
    },
    {
      label: "Contact",
      name: "CONTACTS",
      url: "/u/contacts/addcontacts"
    },
    {
      label: "K1",
      name: "Manage Data",
      url: "/u/manage_data"
    },
    {
      label: "Entities",
      name: "ENTITY",
      url: "/u/entities/addEntity"
    },
    {
      label: "Roles",
      name: "ROLES",
      url: "/u/roles/addRole"
    },
    {
      label: "Sub-Admins",
      name: "SUB ADMINS",
      url: "/u/sub_admin/addSubAdmin"
    }
  ]);

  const handleExpand = (e) => {
    setIsExpanded(true);
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setIsExpanded(false);
    setAnchorEl(null);
  };

  const handleItemClick = (url, label) => {
    if (label === "Investments") {
      AppDispatcher.resetAddInvestmentStep();
      AppDispatcher.setInvestmentID(null);
    }
    if (label === "K1") {
      history.push({
        pathname: url,
        state: { stateValue: true }
      });
    } else {
      history.push(url);
    }
  };

  useEffect(() => {
    if (user.role !== "ADMIN") {
      setMenuItems(
        menuItems.filter((item) => {
          const screenPermissions = getScreenPermissions(
            userPermissions,
            item.name,
            user
          );
          return screenPermissions?.is_create;
        })
      );
    }
  }, []);

  return (
    <>
      {menuItems.length ? (
        <div className={classes.button} onClick={handleExpand}>
          <div className={classes.addText}>Add</div>
          <div className={classes.divider} />
          <img
            style={{ width: 10 }}
            src={isExpanded ? ExpandIcon : CollapseIcon}
            alt=""
          />
        </div>
      ) : null}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        getContentAnchorEl={null}
        open={open}
        className={classes.menu}
        onClose={handleClose}>
        {menuItems.map((menu) => (
          <MenuItem
            key={menu.label}
            style={{ padding: 0 }}
            onClick={() => handleItemClick(menu.url, menu.label)}>
            <div className={classes.itemContainer}>{menu.label}</div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default AddButton;
