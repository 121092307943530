import { Grid, makeStyles, Typography } from "@material-ui/core";
import DocTable from "components/Common/DocTable";
import Illustrations from "components/illustration/Illustrations";
import { truncate } from "helpers/Common";
import React, { useEffect, useState } from "react";
import GeneralInformation from "../GeneralInformation";
import DocumentEmptyImage from "assets/emptyState/noDistribution.svg";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles({
  cardHeading: {
    fontSize: "18px !important",
    fontFamily: "Inter SemiBold",
    fontWeight: 700,
    color: "#191919",
    paddingBottom: "20px",
    backgroundColor: "#fff"
  },

  documentTable: {
    paddingLeft: "20px !important",
    "@media(max-width: 800px)": {
      paddingLeft: "0px !important",
      paddingTop: "30px !important"
    }
  }
});
function Overview({ data = {} }) {
  const classes = useStyles();
  const [alldocs, setAlldocs] = useState([]);

  const skeleton = (input) => {
    return Object.keys(data).length != 0
      ? input
      : <Skeleton width={150} variant="text" /> || "-";
  };

  let mobileNumber =
    data.country_code && data.phone_number
      ? `${data.country_code}-${data.phone_number}`
      : "-";

  const heading = [
    ["Company Name", skeleton(truncate(data?.company_name, 16) || "-")],
    ["Email ID", skeleton(data?.email)],
    ["Phone Number", skeleton(mobileNumber)]
  ];

  useEffect(() => {
    const val =
      data?.documents?.length > 0
        ? data?.documents?.map((el) => el.document_media)
        : [];
    setAlldocs(val);
  }, [data]);

  return (
    <Grid
      xs={12}
      spacing={2}
      style={{ paddingTop: "30px", paddingRight: "20px" }}
      container>
      <Grid
        item
        style={{
          height: "30%",
          border: "1px solid #E9E9E9",
          borderRadius: "10px",
          padding: "20px"
        }}
        lg={6}
        sm={12}
        xs={12}>
        <Grid xs={12}>
          <Typography className={classes.cardHeading}>
            General Information
          </Typography>
        </Grid>
        <Grid container>
          {heading.map((value, index) => {
            return (
              <GeneralInformation
                key={index}
                heading={value[0]}
                value={value[1]}
              />
            );
          })}
        </Grid>
      </Grid>

      {/* documentTable: {
    paddingLeft: "20px"
    // "@media(max-width: 800px)": { paddingLeft: "0px" }
  } */}
      <Grid item className={classes.documentTable} lg={6} sm={12} xs={12}>
        <Typography className={classes.cardHeading}>
          Uploaded Documents
        </Typography>

        {alldocs.length ? (
          <Grid
            style={{
              boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
              backgroundColor: "#ffff"
            }}>
            <DocTable allDocs={alldocs} />
          </Grid>
        ) : Object.keys(data).length == 0 ? (
          [1, 2, 3, 4, 5].map((value, index) => {
            return (
              <Grid
                key={index}
                style={{
                  boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
                  backgroundColor: "#ffff"
                }}>
                <Skeleton width="100%" variant="text" height={40} />
              </Grid>
            );
          })
        ) : (
          <Grid
            style={{
              boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
              backgroundColor: "#ffff"
            }}>
            <Illustrations
              src={DocumentEmptyImage}
              heading="No uploaded documents"
            />
          </Grid>
        )}
        {/* <DocumentsTable
          heading={documentTableHeading}
          data={obj}
          tableType="document"
          value={data}
          type={true}
        /> */}
      </Grid>
    </Grid>
  );
}

export default Overview;
