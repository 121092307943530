import { InputBase, makeStyles } from "@material-ui/core";
import AddIcon from "assets/icons/addImageIcon.svg";
import ClipIcon from "assets/icons/ClipIcon.svg";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import InvestorDocUpload from "./InvestorDocUpload";

const useStyles = makeStyles(() => ({
  main: {
    width: 90,
    height: 33,
    borderRadius: 8,
    padding: 6,
    boxSizing: "border-box",
    border: "0.75px solid #000000",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 8
  },
  textField: {
    flexGrow: 1,
    fontFamily: "Inter Regular",
    fontSize: 14,
    minWidth: 45,
    color: "#191919",
    "& input[type=number]": {
      "-moz-appearance": "textfield",
      padding: 0
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  addBtn: {
    flexShrink: 0,
    cursor: "pointer"
  },
  text: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    color: "#191919"
  }
}));

const TextDocument = ({
  placeholder = "",
  value = 0,
  getDistData,
  onDocChange = () => {},
  cadence,
  docUploaded,
  invDocCategories,
  onChange = () => {}
}) => {
  const [open, setOpen] = useState(false);
  const [uploadDocVisible, setUploadDocVisible] = useState(false);
  const [distData, setDistData] = useState({});
  const classes = useStyles();

  const handleUpload = () => {
    setDistData({ cadence, ...getDistData() });
    setUploadDocVisible(true);
    setOpen(true);
  };
  return (
    <>
      <div className={classes.main}>
        <span className={classes.text}>$</span>
        <NumberFormat
          placeholder={placeholder}
          value={value}
          className={classes.textField}
          style={{ minWidth: 45 }}
          inputProps={{ maxLength: 12 }}
          thousandSeparator={true}
          customInput={InputBase}
          onValueChange={({ value }) => onChange(value)}
        />
        <img
          className={classes.addBtn}
          src={docUploaded ? ClipIcon : AddIcon}
          onClick={handleUpload}
          alt=""
        />
      </div>

      {uploadDocVisible ? (
        <InvestorDocUpload
          invDocCategories={invDocCategories}
          setData={onDocChange}
          distData={distData}
          setOpen={setOpen}
          open={open}
        />
      ) : null}
    </>
  );
};

export default TextDocument;
