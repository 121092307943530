import { Grid, makeStyles } from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import styles from "../../../investments.module.css";
import PlusAdd from "assets/icons/PlusAdd.svg";
import { validationSchemaAddDistribution } from "helpers/validationSchema";
import Distribution from "./components/distributions";
import { AppDispatcher } from "../../../../../../redux/index";
import { formatDate, stringToDate } from "helpers";
import { AddInvestmentDistributionService } from "network/addInvestmentDistributionService";
import { useSelector } from "react-redux";
import Toast from "components/toast";
import { useHistory } from "react-router-dom";
import { AddInvestmentPreviewService } from "network/addInvestmentPreviewService";
import Loader from "components/Loader/AppLoader";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
    borderRadius: 20
  },
  addMoreButton: {
    padding: "20px",
    borderRadius: "10px",
    background: "#FAFAFA",
    margin: "20px 0px",
    display: "flex",
    alignItems: "center",
    "& img": {
      cursor: "pointer"
    },
    "& span": {
      paddingLeft: 10,
      color: "#445EBE",
      cursor: "pointer",
      fontFamily: "Inter Regular",
      fontSize: 14,
      fontWeight: 600
    }
  },
  header: {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    marginBottom: "20px"
  }
}));

const Distributions = () => {
  const [initialValues, setInitialValues] = useState({
    distributionSet: [
      {
        investor_name: "",
        distribution_amount: "",
        distribution_date: "",
        distribution_type: "",
        distribution_year: "",
        distribution_time_frame: "",
        notes: "",
        documents: []
      }
    ]
  });
  const history = useHistory();
  const { investmentID, user } = useSelector((store) => store.app);
  const [isLoading, setIsLoading] = useState(false);
  const [investmentData, setInvestmentData] = useState({ success: false });
  const [docCategories, setDocCategories] = useState([]);
  const [invDocCategories, setInvDocCategories] = useState([]);
  const [dropDownOpRes, setDropdownOpRes] = useState({});
  const [isedit, setisedit] = useState(false);

  const handleDistributionSubmit = async (values) => {
    if (values.distributionSet.length) {
      const FilteredData = values.distributionSet.filter(
        (el) => el.isDisEdit === true
      );

      const payload = {
        distribution: FilteredData?.map((item) => ({
          ...(item.distribution_id ? { id: item.distribution_id } : {}),
          date: formatDate(item.distribution_date),
          type: item.distribution_type,
          cadence: item.distribution_time_frame,
          year: item.distribution_year,
          documents: item?.documents?.map((doc) => ({
            document_media_id: doc.id,
            category_id: doc.type,
            ...(doc.docId ? { id: doc.docId } : {})
          })),

          investor_distribution: [
            {
              ...(item.investor_distribution_id
                ? { id: item.investor_distribution_id }
                : {}),
              investor_id: item.investor_name,
              amount: item.distribution_amount,
              notes: item.notes,
              added_by: user?.id
            }
          ]
        }))
      };
      const res = await AddInvestmentDistributionService.addDistribution(
        payload,
        investmentID
      );
      if (res.success) {
        Toast.showSuccessToast("Distributions Added Successfully");
      } else {
        return Toast.showErrorToast("Failed to Add Distributions");
      }
    }
    AppDispatcher.addInvestmentNextStep();
    setIsLoading(false);
  };

  const handleSaveAsDraft = async (values) => {
    if (values.distributionSet.length) {
      const payload = {
        distribution: values.distributionSet.map((item) => ({
          ...(item.distribution_date
            ? { date: formatDate(item.distribution_date) }
            : {}),
          ...(item.distribution_type ? { type: item.distribution_type } : {}),
          ...(item.distribution_time_frame
            ? { cadence: item.distribution_time_frame }
            : {}),
          ...(item.distribution_year ? { year: item.distribution_year } : {}),
          documents: item?.documents?.map((doc) => ({
            document_media_id: doc.id,
            category_id: doc.type,
            ...(doc.docId ? { id: doc.docId } : {})
          })),

          investor_distribution: [
            {
              ...(item.investor_name
                ? { investor_id: item.investor_name }
                : {}),
              ...(item.distribution_amount
                ? { amount: item.distribution_amount }
                : {}),
              ...(item.notes ? { notes: item.notes } : {}),
              added_by: user?.id
            }
          ]
        })),
        is_draft: true
      };
      const res = await AddInvestmentDistributionService.addDistribution(
        payload,
        investmentID
      );
      if (res.success) {
        Toast.showSuccessToast("Distributions Data Saved");
      } else {
        return Toast.showErrorToast("Failed to Save Distributions");
      }
    }
    history.push("/u/investments");
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTDISTRIBUTION"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
      const invCategories = await AddInvestmentInvestorService.getDocCategories(
        { name: "INVESTMENTDISTRIBUTIONINVESTOR" }
      );
      if (invCategories.success) {
        invCategories.data.length &&
          setInvDocCategories(invCategories.data[0]?.doccategory);
        return;
      }
    }
    Toast.showErrorToast(`Failed To Load Document Categories`);
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      size: el?.document_media?.file_size,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  const initializeData = async (data) => {
    let distList = [];
    data.distribution &&
      data.distribution.forEach((distribution) => {
        let distData = {
          isDisEdit: false,
          distribution_id: distribution.distribution.id,
          investor_distribution_id: distribution.id,

          investor_name: distribution.investor.id,
          distribution_amount: distribution.amount,
          distribution_date: stringToDate(distribution.distribution.date),
          distribution_type: distribution.distribution.type,
          distribution_year: distribution.distribution.year,
          distribution_time_frame: distribution.distribution.cadence,
          notes: distribution.notes,
          documents: formatDocsData(distribution.distribution.documents)
        };
        distList.push(distData);
      });

    setInitialValues({ distributionSet: distList });
  };

  const setDropdownOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setDropdownOpRes(res);
    }
  };

  useEffect(async () => {
    setIsLoading(true);
    loadCategories();
    const res = await AddInvestmentPreviewService.getInvestmentDetails([
      `${investmentID}/`
    ]);
    if (res.success) {
      if (res.data.distribution?.length > 0) {
        setisedit(true);
      }
      await Promise.all([initializeData(res.data), setDropdownOptions()]);
      setIsLoading(false);
    } else {
      Toast.showErrorToast("Failed To Load Data");
    }
    setInvestmentData(res);
    setIsLoading(false);
  }, []);

  const classes = useStyles();
  return (
    <Grid container className={classes.root}>
      <Grid container item xs={12}>
        <div className={classes.header}>Distribution Overview</div>
      </Grid>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaAddDistribution}
          enableReinitialize={true}
          onSubmit={(values) => {
            setIsLoading(true);
            handleDistributionSubmit(values);
          }}>
          {(formik) => (
            <Form style={{ width: "100%" }}>
              <Grid item xs={12}>
                <FieldArray
                  name="distributionSet"
                  render={(arrayHelper) => (
                    <>
                      {formik.values.distributionSet.map((desc, index) => (
                        <Distribution
                          id={index}
                          key={index}
                          desc={desc}
                          investmentID={investmentID}
                          arrayHelper={arrayHelper}
                          formik={formik}
                          investmentDetail={investmentData}
                          docCategories={docCategories}
                          invDocCategories={invDocCategories}
                          dropDownOpRes={dropDownOpRes}
                          isedit={isedit}
                        />
                      ))}

                      <Grid xs={12}>
                        <div
                          className={classes.addMoreButton}
                          onClick={() =>
                            arrayHelper.push({
                              isDisEdit: true,
                              investor_name: "",
                              distribution_amount: "",
                              distribution_date: "",
                              distribution_type: "",
                              distribution_year: "",
                              distribution_time_frame: "",
                              notes: "",
                              documents: []
                            })
                          }>
                          <img src={PlusAdd} alt="add" />
                          <span>
                            Add{" "}
                            {formik.values?.distributionSet?.length === 0
                              ? ""
                              : "Another"}{" "}
                            Distribution
                          </span>
                        </div>
                      </Grid>
                    </>
                  )}
                />
              </Grid>
              <Grid container className={styles.btncontainforms}>
                <p
                  className={styles.saveAsDraftText}
                  onClick={() => handleSaveAsDraft(formik.values)}>
                  Save As Draft
                </p>
                <div className={styles.addInvestmentButtonContainer2}>
                  <p
                    className={styles.addInvestmentButtonText}
                    onClick={formik.handleSubmit}>
                    Next
                  </p>
                </div>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Grid>
  );
};

export default Distributions;
