import React from "react";
import Profile from "..";

const index = () => {
  return (
    <div>
      <Profile />
    </div>
  );
};

export default index;
