import FieldHeading from "../../../../../../../components/fieldHeading";
import React, { useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import RedDelete from "../../../../../../../assets/icons/NewCardSvg/RedDelete.svg";
import AppTextFieldWhite from "../../../../../../../components/StyledComponents/AppTextFieldWhite";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Toast from "components/toast";
import { preventNonNumericalInput } from "helpers";
import DeleteModal from "components/Modals/DeleteModal";

const useStyles = makeStyles(() => ({
  deleteBtn: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    fontWeight: 400,
    color: "#E13838",
    cursor: "pointer",
    marginRight: "20px"
  },
  headingContainer: {
    display: "flex",
    maxWidth: "753px",
    justifyContent: "space-between"
  },
  heading: {
    fontFamily: "Inter Regular",
    fontSize: 16,
    fontWeight: 700,
    color: "#616161"
  },
  mt30: {
    marginTop: 30
  }
}));

const Tier = ({ id, arrayHelper, formik, ClickonSponsorTiersEdit }) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const checkDelete = () => {
    if (formik.values.sponsorSet[id].id) {
      setDeleteModal(true);
    } else {
      arrayHelper.remove(id);
    }
  };

  const handleDelete = async () => {
    const res = await AddInvestmentBasicDetailsService.deleteTiers(
      formik.values.sponsorSet[id].id
    );
    if (res.success) {
      setDeleteModal(false);
      arrayHelper.remove(id);
    } else {
      return Toast.showErrorToast(res.error.message[0]);
    }
  };

  const classes = useStyles();

  const sponsorsErrors =
    (formik.errors.sponsorSet &&
      formik.errors.sponsorSet.length &&
      formik.errors.sponsorSet[id]) ||
    {};
  const sponsorTouched =
    (formik.touched.sponsorSet &&
      formik.touched.sponsorSet.length &&
      formik.touched.sponsorSet[id]) ||
    {};

  return (
    <Grid container>
      <Grid container direction="column">
        <Grid
          continer
          item
          xs
          className={`${classes.headingContainer} ${id !== 0 && classes.mt30}`}>
          <div className={classes.heading}>{`Tier ${id + 1}`}</div>
          {ClickonSponsorTiersEdit && (
            <div
              className={classes.deleteBtn}
              onClick={ClickonSponsorTiersEdit ? checkDelete : () => {}}>
              <img src={RedDelete} alt="del" className={classes.deleteButton} />
            </div>
          )}
        </Grid>
        <Grid container spacing={2} className={classes.headingContainer}>
          <Grid item xs={12} sm={6} md={4}>
            <FieldHeading title="From" />
            <AppTextFieldWhite
              disabled={!ClickonSponsorTiersEdit}
              id={`sponsorSet[${id}].startfrom`}
              placeholder="Add Percentage"
              variant="standard"
              onKeyDown={preventNonNumericalInput}
              fullWidth
              value={formik.values.sponsorSet[id].startfrom}
              onChange={formik.handleChange(`sponsorSet[${id}].startfrom`)}
              error={
                sponsorTouched.startfrom && Boolean(sponsorsErrors.startfrom)
              }
              helperText={sponsorTouched.startfrom && sponsorsErrors.startfrom}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FieldHeading title="To" />
            <AppTextFieldWhite
              disabled={!ClickonSponsorTiersEdit}
              id={`sponsorSet[${id}].to`}
              placeholder="Add Percentage"
              variant="standard"
              fullWidth
              onKeyDown={preventNonNumericalInput}
              value={formik.values.sponsorSet[id].to}
              error={sponsorTouched.to && Boolean(sponsorsErrors.to)}
              helperText={sponsorTouched.to && sponsorsErrors.to}
              onChange={formik.handleChange(`sponsorSet[${id}].to`)}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FieldHeading title="Sponsor Promote" />
            <AppTextFieldWhite
              disabled={!ClickonSponsorTiersEdit}
              id={`sponsorSet[${id}].promote`}
              placeholder="Add Sponsor Promote"
              variant="standard"
              fullWidth
              onKeyDown={preventNonNumericalInput}
              value={formik.values.sponsorSet[id].promote}
              onChange={formik.handleChange(`sponsorSet[${id}].promote`)}
              error={sponsorTouched.promote && Boolean(sponsorsErrors.promote)}
              helperText={sponsorTouched.promote && sponsorsErrors.promote}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
              inputProps={{ maxLength: 5 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <DeleteModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
        title="Are You Sure You Want To Delete This Item?"
        description="This will permanently remove all data associated with it from Simplee."
      />
    </Grid>
  );
};

export default Tier;
