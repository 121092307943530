import { Box, Button, Grid, Modal } from "@material-ui/core";
// import AddBtn from "components/Buttons/addBtn";
import Toast from "components/toast";
import { FieldArray, Form, Formik } from "formik";
import {
  validationSchemaManageDataAddK1,
  validationSchemaManageDataAddWithPropertyK1
} from "helpers/validationSchema";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
// import { AddInvestmentLoanService } from "network/addInvestmentLoanService";
import React, { useState, useEffect } from "react";

// import { useHistory } from "react-router-dom";
import styles from "./addk1.module.scss";
import AddK1Fields from "./AddK1Fields";
import CloseIcon from "../../../../assets/icons/blueCloseIcon.svg";
import AddIcon from "../../../../assets/icons/addIcon.svg";
import { ManageDataService } from "network/manageDataService";
// import { ManageDataService } from "network/manageDataService";
// import Actions from "redux/actions/appActions";

function AddPopUpManageData({ closeAddPopUp }) {
  const [type, setType] = useState("investment");
  const [k1Categories, setK1Categories] = useState(null);
  const [payload, setPayload] = useState([]);
  const [initialValues] = useState({
    associatedSet: [
      {
        type: "",
        investmentinvestor: "",
        investor: "",
        // property_id: "",
        ...(type === "investment" ? null : { property_id: "" }),

        year: "",
        documents: []
      }
    ]
  });

  useEffect(() => {
    K1doccCategory();
  }, []);

  useEffect(() => {
    AddK1();
  }, [payload]);

  const K1doccCategory = async () => {
    const invCategories = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENT"
    });

    if (invCategories.success) {
      const catId = invCategories.data[0].doccategory.filter(
        (item) => item.name === "K-1"
      );
      if (catId.length) {
        setK1Categories(catId);
      }
    } else {
      Toast.showErrorToast("Failed To Load Document Categories");
    }
  };

  const AddK1payload = (values) => {
    // const investmentId = values.investment_name;
    const data = values.associatedSet;

    const payloadData = data.map((obj) => ({
      // ...obj,
      type: obj["type"],
      year: obj["year"],
      ...(type === "property" &&
        obj["property_id"] != undefined && { property_id: obj["property_id"] }),
      investmentinvestor: obj["investor"],
      documents: obj["documents"]?.map((doc) => ({
        document_media_id: doc.document_media_id
          ? doc.document_media_id
          : doc.id,
        category_id: doc.category_id ? doc.category_id : doc.type,
        ...(doc.docId ? { id: doc.docId } : {})
      }))
    }));
    setPayload(payloadData);
  };

  const AddK1 = async () => {
    if (payload.length) {
      // setLoader(true);
      // const datatobeSend = { contacts: payload };
      // const datatobeSend = { payload };
      const res = await ManageDataService.addManageDataK1(payload);
      if (res.success) {
        Toast.showSuccessToast("K1 Added Successfully");

        // AppDispatcher.openManageDataPopUp(false);
        closeAddPopUp(false);

        // setLoader(false);
      } else {
        // setLoader(false);
        Toast.showErrorToast("Cannot Add K1");
      }
    }
  };
  return (
    <Modal
      open={open}
      // onClose={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Formik
        initialValues={initialValues}
        validationSchema={
          type === "property"
            ? validationSchemaManageDataAddWithPropertyK1
            : validationSchemaManageDataAddK1
        }
        onSubmit={(values) => {
          AddK1payload(values);
        }}>
        {(formik) => (
          <Form>
            <Box className={styles.boxPopUp}>
              <Grid container justifyContent="space-between">
                <Grid className={styles.heading} item>
                  Add K1
                </Grid>
                <Grid item>
                  <img
                    onClick={() => {
                      //   AppDispatcher.openManageDataPopUp(false);
                      closeAddPopUp(false);
                    }}
                    src={CloseIcon}
                    alt="Images Icon"
                    style={{ marginRight: 8, cursor: "pointer" }}
                  />
                </Grid>
              </Grid>
              <Grid xs={12} container className={styles.gridContainerHeading}>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  md={5}
                  lg={3}
                  className={styles.gridItem}></Grid>
              </Grid>
              <Grid container className={styles.gridContainer}>
                <Grid item xs>
                  <FieldArray
                    name="associatedSet"
                    render={(arrayHelper) => (
                      <>
                        {formik.values.associatedSet.map((values, index) => (
                          <>
                            <AddK1Fields
                              id={index}
                              key={index}
                              arrayHelper={arrayHelper}
                              formik={formik}
                              k1Categories={k1Categories}
                              selectProperty={(value) => {
                                setType(value);
                              }}
                            />
                          </>
                        ))}
                        <Grid container style={{ paddingTop: "30px" }}>
                          <Grid
                            container
                            alignItems="center"
                            style={{ display: "flex", cursor: "pointer" }}
                            // className={styles.addTier}
                            onClick={() =>
                              arrayHelper.push({
                                investment: "",
                                property: "",
                                investor: "",
                                year: "",
                                documents: []
                              })
                            }>
                            <img
                              src={AddIcon}
                              alt="Images Icon"
                              style={{ marginRight: 8 }}
                            />
                            <div className={styles.addMore}>Add more K1s</div>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  />
                </Grid>
              </Grid>
              <Grid container className={styles.btnContainer}>
                <Grid style={{ paddingRight: "15px" }} item>
                  <Button
                    style={{
                      fontFamily: "Inter SemiBold",
                      fontSize: 16,
                      height: 45,
                      minWidth: 174,
                      padding: "0px 30px !important",
                      borderRadius: 4,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#ffffff",
                      color: "#5775E5",
                      cursor: "pointer",
                      border: "1px solid #5775E5",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      // AppDispatcher.openManageDataPopUp(false);
                      // setType("investment");
                      closeAddPopUp(false);
                    }}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  {/* <AddBtn text="Add" type="submit" /> */}
                  <Button
                    style={{
                      fontFamily: "Inter SemiBold",
                      fontSize: 16,
                      height: 45,
                      minWidth: 174,
                      padding: "0px 30px !important",
                      borderRadius: 4,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#5775E5",
                      color: "#FFFF",
                      cursor: "pointer",
                      border: "1px solid #5775E5",
                      marginRight: "10px"
                    }}
                    onClick={() => {
                      // AppDispatcher.openManageDataPopUp(false);
                      formik.handleSubmit();
                    }}>
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default AddPopUpManageData;
