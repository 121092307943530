// Export all the public routes

import SubAdminLogin from "pages/public/subAdminLogin";
import ForgotPassword from "../../pages/public/forgotPassword/index";
import Login from "../../pages/public/login";
import SignUp from "../../pages/public/signup";

export const PublicRoutes = [
  { path: "/auth/login", exact: true, component: Login },
  { path: "/auth/signup", exact: true, component: SignUp },
  { path: "/auth/forgotPassword", exact: true, component: ForgotPassword },
  {
    path: "/auth/verify-subAdmin/:id",
    exact: true,
    component: SubAdminLogin
  }
];
