import React, { useState, useRef, useEffect } from "react";
import { useFormik } from "formik";
import {
  Grid,
  makeStyles,
  Typography,
  CircularProgress
} from "@material-ui/core";
import HeaderComponent from "../../../../components/HeaderComponent/HeaderComponent";
import AppTextFieldWhite from "../../../../components/StyledComponents/AppTextFieldWhite";
import { validationSchemaAddSponsor } from "../../../../helpers/validationSchema";
import styles from "./addSponsors.module.scss";
import AddBtn from "../../../../components/Buttons/addBtn";
import CancelBtn from "../../../../components/Buttons/cancelBtn";
import Toast from "../../../../components/toast";
import { useHistory } from "react-router-dom";
import Loader from "../../../../components/Loader/AppLoader";
// import UploadIcon from "../../../../assets/icons/upload.svg";
import FileUploaded from "../../../../assets/icons/FileUpload.svg";
import CancelModal from "../../../../components/Modals/CancelModal";
import { SponsorService } from "../../../../network/sponsorService";
import { CommonService } from "../../../../network/commonService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useSelector } from "react-redux";
import { preventNonNumericalInput } from "helpers";
import { truncate, verifyDocUploadType } from "helpers/Common";

const useHeaderStyle = makeStyles(() => ({
  mainContainer: {
    backgroundColor: "#FAFAFA",
    padding: 20,
    borderRadius: 10
  },
  mainContainer1: {
    backgroundColor: "#FAFAFA"
  },
  btnContainer: {
    marginLeft: 40
  },
  addInvestorHeading: {
    marginBottom: 20
  },
  uploadDocs: {},
  imgContainer: {
    display: "flex",
    flexDirection: "row",
    margin: "20px"
  },
  textFieldContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 20
  },
  textField: {
    maxWidth: 369
  },
  country_code_error: {
    fontSize: "0.75rem",
    marginTop: 3,
    textAlign: "left",
    fontFamily: "Inter Regular",
    fontWeight: 400,
    lineHeight: 1.66,
    color: "#E13838"
  },
  mTop: {
    marginTop: "30px"
  },
  btnBig: {
    "@media (max-width: 800px)": {
      width: 210,
      height: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      fontSize: 18,
      marginBottom: 20
    },
    width: 324,
    height: 50,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 0,
    fontSize: 18,
    marginBottom: 20
  }
}));

const AddSponsor = () => {
  const classes = useHeaderStyle();
  const [loading, setLoading] = useState(false);
  const [docsLoading, setDocsLoading] = useState(false);
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const [imageList, setImageList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const [confirmCancel, setConfirmCancel] = useState(false);
  const { userPermissions, user } = useSelector((store) => store.app);
  const [emailSend, setEmailSend] = useState(1);

  const onFileDrop = (e) => {
    if (!verifyDocUploadType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      const updatedList = [...fileList, newFile];
      setFileList(updatedList);
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      const updatedFileList = [...imageList, resp.data];
      setImageList(updatedFileList);
      setDocsLoading(false);
      Toast.showSuccessToast("Documents Upload SuccessFully");
    } else {
      Toast.showErrorToast("Documents Not Uploaded");
    }
  };

  const deleteDoc = (id) => {
    setImageList((prev) => {
      return prev.filter((item) => item.id != id);
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      company: "",
      email: "",
      phone: "",
      countryCode: "1"
    },
    validationSchema: validationSchemaAddSponsor,
    onSubmit: async (values) => {
      const payload = {
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        company_name: values.company,
        phone_number: values.phone,
        country_code: "+" + values.countryCode,
        documents: imageList
      };
      setLoading(true);
      if (emailSend === 1) {
        // Invite Send Api Call
        try {
          const resp = await SponsorService.addSponsorSendInvite(payload);
          if (resp.success) {
            Toast.showSuccessToast("Sponsor Created Successfully");
            history.push("/u/sponsors");
          } else {
            Toast.showErrorToast(resp.error.message[0]);
          }
        } catch (err) {
          Toast.showErrorToast("Error In Creating Sponsor");
        } finally {
          setLoading(false);
        }
      } else {
        // Skip Invite Api call
        try {
          const resp = await SponsorService.addSponsor(payload);
          if (resp.success) {
            Toast.showSuccessToast("Sponsor Created Successfully");
            history.push("/u/sponsors");
          } else {
            Toast.showErrorToast(resp.error.message[0]);
          }
        } catch (err) {
          Toast.showErrorToast("Error In Creating Sponsor");
        } finally {
          setLoading(false);
        }
      }
    }
  });

  const handleAddSponsor = () => {
    formik.handleSubmit();
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "SPONSORS",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_create) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    checkPermissions();
  }, []);

  return (
    <>
      <HeaderComponent
        heading="Add Sponsor"
        description="Add New Sponsors To Simplee"
        backBtn={true}
      />
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.edit_sp_mainCoantiner}>
          <div>
            <Typography variant="h5" className={classes.addInvestorHeading}>
              Add Sponsor Information{" "}
            </Typography>
            <Grid container spacing={2} className={classes.mainContainer}>
              <Grid
                item
                xl={3}
                lg={4}
                sm={6}
                className={classes.textFieldContainer}>
                <label className={styles.lebelsText}>Email ID</label>
                <AppTextFieldWhite
                  id="email"
                  placeholder="Enter Email ID"
                  variant="standard"
                  value={formik.values.email}
                  className={classes.textField}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={4}
                sm={6}
                className={classes.textFieldContainer}>
                <label className={styles.lebelsText}>First Name</label>
                <AppTextFieldWhite
                  id="firstName"
                  placeholder="Enter First Name"
                  variant="standard"
                  value={formik.values.firstName}
                  className={classes.textField}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={4}
                sm={6}
                className={classes.textFieldContainer}>
                <label className={styles.lebelsText}>Last Name</label>
                <AppTextFieldWhite
                  id="lastName"
                  placeholder="Enter Last Name"
                  variant="standard"
                  value={formik.values.lastName}
                  className={classes.textField}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>
              <Grid
                item
                xl={3}
                lg={4}
                sm={6}
                className={classes.textFieldContainer}>
                <label className={styles.lebelsText}>Company Name</label>
                <AppTextFieldWhite
                  id="company"
                  placeholder="Enter Company Name"
                  variant="standard"
                  value={formik.values.company}
                  className={classes.textField}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.company && Boolean(formik.errors.company)
                  }
                  helperText={formik.touched.company && formik.errors.company}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{ disableUnderline: true }}
                />
              </Grid>

              <Grid
                item
                xl={3}
                lg={4}
                sm={6}
                style={{ width: 408 }}
                className={classes.textFieldContainer}>
                <label className={styles.lebelsText}>Phone Number</label>
                <Grid container wrap="nowrap">
                  <Grid item>
                    <PhoneInput
                      id="countryCode"
                      country={"us"}
                      placeholder="Code"
                      value={formik.values.countryCode}
                      onChange={formik.handleChange("countryCode")}
                      error={
                        formik.touched.countryCode &&
                        Boolean(formik.errors.countryCode)
                      }
                      helperText={
                        formik.touched.countryCode && formik.errors.countryCode
                      }
                      inputStyle={{
                        border: "1px solid #F4F2FF",
                        borderRadius: "6px",
                        fontSize: "15px",
                        width: "110px",
                        height: 50,
                        marginRight: "12px"
                      }}
                      dropdownStyle={{
                        width: "550%",
                        marginBottom: 30,
                        height: 100
                      }}
                      buttonStyle={{
                        borderRadius: "4px"
                      }}></PhoneInput>
                    <div className={classes.country_code_error}>
                      {formik.touched.countryCode && formik.errors.countryCode}
                    </div>
                  </Grid>

                  <Grid item>
                    <AppTextFieldWhite
                      id="phone"
                      placeholder="Enter Phone Number"
                      variant="standard"
                      type="tel"
                      onKeyDown={preventNonNumericalInput}
                      value={formik.values.phone}
                      style={{ width: "100%" }}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.phone && Boolean(formik.errors.phone)
                      }
                      helperText={formik.touched.phone && formik.errors.phone}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div className={classes.mTop}>
            <Typography variant="h5" style={{ marginBottom: 20 }}>
              Upload Documents{" "}
            </Typography>
            <Grid container spacing={2} className={classes.mainContainer1}>
              <Grid item xs={12}>
                <div
                  ref={wrapperRef}
                  className={styles.drop_file_input}
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}>
                  {docsLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      {/* <img src={UploadIcon} className={styles.uploadImage} /> */}
                      <p className={styles.droptext}>
                        Drag & Drop Files Here Or
                        <span style={{ color: "#5775E5" }}> Browse</span>{" "}
                      </p>
                    </>
                  )}

                  <input type="file" value="" onChange={onFileDrop} />
                </div>
              </Grid>
              <Grid item xs={12}>
                {imageList.length > 0 ? (
                  <>
                    {imageList.map((item) => (
                      <Grid
                        key={item.name}
                        container
                        spacing={2}
                        className={classes.uploadDocs}>
                        <Grid item className={classes.imgContainer}>
                          {item.image_type.toLowerCase() === "png" ||
                          item.image_type.toLowerCase() === "jpeg" ||
                          item.image_type.toLowerCase() === "jpg" ? (
                            <img
                              src={item.file}
                              style={{
                                height: 54,
                                width: 54,
                                borderRadius: 10
                              }}
                            />
                          ) : (
                            <img src={FileUploaded} />
                          )}
                          <Typography
                            variant="h5"
                            style={{ marginTop: 20, marginLeft: 20 }}>
                            {truncate(item?.name, 30)}
                          </Typography>
                        </Grid>
                        <Grid item style={{ margin: "auto 0" }}>
                          <div
                            className={styles.deleteButton}
                            onClick={() => deleteDoc(item.id)}>
                            Delete
                          </div>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                ) : null}
              </Grid>
            </Grid>
          </div>

          <Grid xs={12} container className={classes.btnCtr}>
            <Grid item xs={4} md={6}>
              <AddBtn
                text="Skip Invite & Create"
                extraClasses={classes.btnBig}
                onClick={() => {
                  handleAddSponsor();
                  setEmailSend(0);
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={8}
              md={6}
              style={{
                display: "flex",
                justifyContent: "flex-end"
              }}>
              <Grid item>
                <CancelBtn
                  // extraClasses={classes.btnSmall}
                  onClick={() => setConfirmCancel(true)}
                />
              </Grid>
              <Grid item>
                <AddBtn
                  text="Send Invite"
                  // extraClasses={classes.btnSmall}
                  onClick={() => {
                    console.log('sssss===================')
                    handleAddSponsor();
                    setEmailSend(1);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      )}
      <CancelModal
        open={confirmCancel}
        title=""
        description={`Are you sure you want to cancel?`}
        handleClose={() => setConfirmCancel(false)}
        handleSubmit={() => history.goBack()}
      />
    </>
  );
};

export default AddSponsor;
