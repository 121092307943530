import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import { truncate } from "helpers/Common";
import React from "react";
import styles from "./commanstyle.module.scss";

const K1Table = ({ Headingdata, mapArray, width }) => {
  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };
  return (
    <>
      <Table
        className={styles.Spon_T_C}
        style={width ? { minWidth: width } : {}}>
        <TableHead>
          <TableRow>
            {Headingdata?.map((item, i) => (
              <TableCell className={styles.T_Heading} key={i}>
                {item.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mapArray?.map((obj, i) => (
            <TableRow key={i}>
              <TableCell
                title={obj?.investor_data?.investor_name}
                className={styles.T_body}>
                {truncate(obj?.investor_data?.investor_name, 25)}
              </TableCell>
              <TableCell className={styles.T_body}>{obj?.year}</TableCell>
              <TableCell
                className={styles.T_body}
                style={{ maxwidth: "750px" }}>
                {obj?.notes ? obj?.notes : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                <DocumentsUpload
                  title={`K1's Document`}
                  editable={false}
                  listData={obj.documents ? formatDocsData(obj.documents) : []}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default K1Table;
