import { Typography } from "@material-ui/core";
import React from "react";
import styles from "../manageData.module.scss";
import ActiveIcon from "../../../../assets/icons/ActiveK1.svg";
import InactiveIcon from "../../../../assets/icons/InactiveK1.svg";
function ManageDataToggle({ groupBy, onToggleHandle }) {
  return (
    <div style={{ margin: "33px 0px 28px 0px" }} className={styles.mainCtr}>
      <div className={styles.toggleCtr}>
        <div className={styles.groupBy}>Group By</div>
        <div
          className={groupBy === "K1" ? styles.switchActive : styles.switch}
          onClick={() => onToggleHandle("K1")}>
          <div
            className={
              groupBy === "K1" ? styles.switchTextActive : styles.switchText
            }>
            <img
              src={groupBy === "K1" ? ActiveIcon : InactiveIcon}
              alt="icon"
              width="40px"
              height="40px"
              style={{ marginRight: "10px" }}
            />
            <Typography align="center" className={styles.textAlign}>
              K1
            </Typography>
          </div>
        </div>
        <div
          className={
            groupBy === "Template" ? styles.switchActive : styles.switch
          }
          onClick={() => onToggleHandle("Template")}>
          <div
            className={
              groupBy === "Template"
                ? styles.switchTextActive
                : styles.switchText
            }>
            <img
              src={groupBy === "Template" ? ActiveIcon : InactiveIcon}
              alt="icon"
              width="40px"
              height="40px"
              style={{ marginRight: "10px" }}
            />
            <Typography align="center" className={styles.textAlign}>
              Template
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManageDataToggle;
