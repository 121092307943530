import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import Toast from "components/toast";
import { ContactsService } from "network/contactsService";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: "rgba(235, 239, 255, 0.6)",
    backdropFilter: "blur(26)"
  },
  dialogContent: {
    padding: 50,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  container: {
    marginTop: 30,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  heading: {
    fontFamily: "Inter Regular",
    fontSize: 28,
    letterSpacing: 0,
    fontWeight: 700
  },
  content: {
    marginTop: 40,
    width: 551,
    textAlign: "center",
    fontFamily: "Inter SemiBold",
    fontSize: 18,
    color: "#616161",
    fontWeight: 600,
    letterSpacing: 0
  },
  btnContainer: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    marginTop: 40
  },
  btnPrimary: {
    backgroundColor: "#E13838",
    color: "#FFFFFF",
    height: 50,
    width: 174,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 10
  },
  btnSecondary: {
    border: "1px solid #445EBE",
    backgroundColor: "#EBEFFF",
    color: "#445EBE",
    height: 48,
    width: 172,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 10
  }
}));

const DeleteContacts = ({ handleClose }) => {
  const classes = useStyles();
  const { contactsId } = useSelector((store) => store.app);

  const deleteContact = async () => {
    const res = await ContactsService.deleteContact([contactsId?.id]);
    if (res.success) {
      handleClose();
      Toast.showSuccessToast(res.data.message);
    } else {
      Toast.showErrorToast("Failed To Delete Contact");
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      className={classes.dialog}
      maxWidth={false}>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.container}>
          <div className={classes.heading}>
            Are You Sure You Want To Delete Contact?
          </div>
          <div className={classes.content}>
            You Are About To Delete A Contact.This Will Permanently Remove it
            from its Associated investment on Simplee.
          </div>
          <div className={classes.btnContainer}>
            <div className={classes.btnSecondary} onClick={() => handleClose()}>
              Cancel
            </div>
            <div className={classes.btnPrimary} onClick={deleteContact}>
              Delete
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteContacts;
