import { Grid } from "@material-ui/core";
import React from "react";
import noDistribution from "assets/emptyState/noDistribution.svg";
import DistributionTable from "components/Common/DistributionTable";
import HeadingContainer from "../component/HeadingContainer";
import Illustrations from "components/illustration/Illustrations";

const Distribution = ({ data }) => {
  return (
    <Grid
      container
      spacing={4}
      style={{ marginTop: "20px", marginBottom: "54px" }}>
      <Grid item xs={12}>
        <HeadingContainer Title="Distribution Summary">
          {data?.distribution?.length ? (
            <DistributionTable
              Headingdata={[
                { heading: "Investor Name" },
                { heading: "Amount Released" },
                { heading: "Released Date" },
                { heading: "Distribution Type" },
                { heading: "Year" },
                { heading: "Tenure" },
                { heading: "Notes" },
                { heading: "Proof" }
              ]}
              DetailPageData={data?.distribution}
            />
          ) : (
            <Grid item xs={12}>
              <Illustrations
                src={noDistribution}
                subHeading={`No added Distributions.`}
              />
            </Grid>
          )}
        </HeadingContainer>
      </Grid>
    </Grid>
  );
};

export default Distribution;
