import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { formatDate } from "helpers/Common";
import AppDispatcher from "../../redux/dispatchers/appDispatcher";

const useStyles = makeStyles(() => ({
  dateInputContainer: {
    display: "flex",
    justifyContent: "space-between",
    "div:first-child": {
      width: 150
    }
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    color: "#BBBBBB"
  },
  dateContainer: {
    height: 50,
    width: 148,
    borderRadius: 16,
    border: "1px solid #EBEFFF",
    marginTop: 4,
    cursor: "pointer",
    fontFamily: "Inter Regular",
    fontSize: 16,
    color: "#191919",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  datePickerContainer: {
    marginTop: "5px",
    display: "flex",
    fontFamily: "Inter Regular !important",
    justifyContent: "center",
    "& .MuiToolbar-root": {
      display: "none"
    }
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end"
  },
  btn: {
    height: 50,
    width: 112,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#445EBE",
    fontFamily: "Inter SemiBold",
    fontSize: 18,
    color: "#ffffff",
    cursor: "pointer",
    borderRadius: 4
  }
}));

function GetCustomSelectMenu({ handleClose }) {
  const classes = useStyles();

  const [fromSelected, setFromSelected] = useState(true);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  const getformattedDate = (date) =>
    `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

  const handleDateChange = (date) => {
    if (fromSelected) {
      setFrom(date);
      if (date?.toISOString().split("T")[0] > to?.toISOString().split("T")[0]) {
        setTo(date);
      }
    } else {
      setTo(date);
    }
  };

  const handleDateApply = () => {
    let dateRangeData = {
      start_date: formatDate(from),
      end_date: formatDate(to)
    };
    AppDispatcher.setDateRange(dateRangeData);
    setFromSelected(true);
    handleClose();
  };

  return (
    <div id="scroll-content">
      <div className={classes.dateInputContainer}>
        <div>
          <p className={classes.label}>From</p>
          <div
            className={classes.dateContainer}
            style={fromSelected ? { border: "2px solid #add8e6" } : null}
            onClick={() => setFromSelected(true)}>
            {getformattedDate(from)}
          </div>
        </div>
        <div>
          <p className={classes.label}>To</p>
          <div
            className={classes.dateContainer}
            style={fromSelected ? null : { border: "2px solid #add8e6" }}
            onClick={() => setFromSelected(false)}>
            {getformattedDate(to)}
          </div>
        </div>
      </div>
      <div className={classes.datePickerContainer}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            autoOk
            variant="static"
            openTo="date"
            value={fromSelected ? from : to}
            {...(fromSelected ? {} : { minDate: from })}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
      </div>
      <div className={classes.btnContainer}>
        <div className={classes.btn} onClick={handleDateApply}>
          Apply
        </div>
      </div>
    </div>
  );
}

export default GetCustomSelectMenu;
