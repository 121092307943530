import { Grid, makeStyles } from "@material-ui/core";
import NewAutoComplete from "components/NewAutoComplete/NewAutoComplete";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import { preventNonNumericalInput } from "helpers";
import { EntityService } from "network/entityService";
import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";

const useStyles = makeStyles(() => ({
  mainContainer: {
    cursor: "pointer",
    padding: "16px",
    "&:hover": {
      background: "#F6F8FD"
    }
  },
  email: {
    fontWeight: 400,
    fontSize: " 16px",
    lineHeight: "22px",
    color: "#000000"
  },
  lighttext: {
    padding: "3px 0px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#616161"
  },
  lightTextOverflow: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  darktext: {
    color: "#000000"
  }
}));

const SponsorEntityForm = ({
  formik,
  classes,
  setExistingEmailSelected,
  setClickedOnAddbtn
}) => {
  const dropDownStyle = useStyles();

  const [showAddMore, setShowAddMore] = useState("");
  const [taxIDsearch, SettaxIDsearch] = useState("");
  const [Emailsearch, SetEmailSearchQuery] = useState("");
  const [data, setData] = useState([]);

  /**************************************************** */
  /********** fetch dropdown list data ********** */
  /**************************************************** */

  const getEmailList = async () => {
    let url = "";
    if (Emailsearch) {
      url = `?search=${Emailsearch}`;
    }

    const response = await EntityService.getSponsorEmailList([url]);
    if (response.success) {
      setData(response.data.results);
    } else {
      setData([]);
    }
  };

  const getEntityTaxId = async () => {
    let url = "";
    if (taxIDsearch) {
      url = `?search=${taxIDsearch}`;

      const response = await EntityService.getSponsorTaxList([url]);
      if (response.success) {
        setData(response.data);
      } else {
        setData([]);
      }
    }
  };

  /**************************************************** */
  /*********handle DropDown onClick******************** */
  /**************************************************** */

  const handleEmaildropFromData = (item) => {
    SetEmailSearchQuery("");
    setExistingEmailSelected(true);
    setShowAddMore("");
    formik.setFieldValue("entityTypeId", item);
    formik.setFieldValue("email", item.email || "-");
  };

  const handleTaxIDFromData = (item) => {
    SettaxIDsearch("");
    formik.setFieldValue("taxId", item.tax_id);
    formik.setFieldValue("entityName", item?.name);
    formik.setFieldValue("mailingAddress", item?.address?.[0].address);
  };

  /**************************************************** */
  /********* Set Fetched data to drop down ************ */
  /**************************************************** */

  const EmaildataList = data?.map((item) => (
    <div
      className={dropDownStyle.mainContainer}
      onClick={() => handleEmaildropFromData(item)}>
      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Sponsor Email:
        <span className={dropDownStyle.darktext}> {item.email}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Sponsor Name:
        <span
          className={
            dropDownStyle.darktext
          }>{` ${item.first_name} ${item.last_name}`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Company Name:
        <span className={dropDownStyle.darktext}>{` ${
          item?.company_name || "-"
        }`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Contact Number:
        <span className={dropDownStyle.darktext}>{` ${
          item?.country_code || "-"
        } ${item?.phone_number || " "}`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext} ${dropDownStyle.lightTextOverflow}`}>
        Managed By:
        <span className={dropDownStyle.darktext}> {item.managed_by}</span>
      </div>
    </div>
  ));

  const TaxIdList = data?.map((item) => (
    <div
      className={dropDownStyle.mainContainer}
      onClick={() => handleTaxIDFromData(item)}>
      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Tax ID (EIN):
        <span className={dropDownStyle.darktext}>{` ${item?.tax_id}`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Entity Name:
        <span className={dropDownStyle.darktext}>{` ${
          item?.name || "-"
        }`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Sponsor Email ID:
        <span className={dropDownStyle.darktext}>{` ${
          item?.user_email || "-"
        }`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        {`Mailing Address`}:
        <span className={dropDownStyle.darktext}>{` ${
          item?.address?.[0]?.address || "-"
        }`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Sponsor Name:
        <span className={dropDownStyle.darktext}>{` ${
          item?.user_name || "-"
        }`}</span>
      </div>

      <div className={dropDownStyle.lighttext}>
        Managed By:
        <span className={dropDownStyle.darktext}> {item.managed_by}</span>
      </div>

      <div className={dropDownStyle.lighttext}>
        Partners:
        <span className={dropDownStyle.darktext}>
          {" "}
          {item?.partners?.map((ele, i) => {
            if (i === 0) {
              return ele.name;
            } else {
              return `,${ele.name}`;
            }
          })}
        </span>
      </div>
    </div>
  ));
  /**************************************************** */
  /**************************************************** */

  useEffect(() => {
    getEmailList();
    SettaxIDsearch("");
  }, [Emailsearch]);

  useEffect(() => {
    getEntityTaxId();
    SetEmailSearchQuery("");
  }, [taxIDsearch]);

  return (
    <Fragment>
      <Grid container xs={12} spacing={2}>
        <Grid item lg={4} md={6} sm={6}>
          <label className={classes.label}>Tax ID (EIN)/Entity Name</label>
          <NewAutoComplete
            placeholder="Enter TaxId/EntityName"
            search={taxIDsearch}
            setSearchQuery={SettaxIDsearch}
            dataList={TaxIdList}
            fieldKey="taxId"
            formik={formik}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6}>
          <label className={classes.label}>Entity Name</label>
          <AppTextFieldWhite
            id="entityName"
            placeholder="Enter Entity Name"
            variant="standard"
            fullWidth
            value={formik.values.entityName}
            onChange={formik.handleChange("entityName")}
            error={
              formik.touched.entityName && Boolean(formik.errors.entityName)
            }
            helperText={formik.touched.entityName && formik.errors.entityName}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 100 }}
          />
        </Grid>
        <Grid item lg={4} md={6} sm={6}>
          <label className={classes.label}>Mailing Address</label>
          <AppTextFieldWhite
            id="mailingAddress"
            placeholder="Enter Mailing Address"
            variant="standard"
            fullWidth
            value={formik.values.mailingAddress}
            onChange={formik.handleChange("mailingAddress")}
            error={
              formik.touched.mailingAddress &&
              Boolean(formik.errors.mailingAddress)
            }
            helperText={
              formik.touched.mailingAddress && formik.errors.mailingAddress
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>

        <Grid item lg={4} md={6} sm={6}>
          <label className={classes.label}>Sponsor Email ID</label>
          <NewAutoComplete
            placeholder="Enter Email Id"
            setExistingEmailSelected={setExistingEmailSelected}
            setClickedOnAddbtn={setClickedOnAddbtn}
            btnText="Sponsor"
            setShowAddMore={setShowAddMore}
            search={Emailsearch}
            setSearchQuery={SetEmailSearchQuery}
            dataList={EmaildataList}
            fieldKey="email"
            formik={formik}
          />
        </Grid>

        {showAddMore === "Sponsor" && (
          <>
            <Grid item lg={4} md={6} sm={6}>
              <label className={classes.label}>Sponsor First Name</label>
              <AppTextFieldWhite
                id="sponsorFirstName"
                placeholder="Enter Name"
                variant="standard"
                fullWidth
                value={formik.values.first_name}
                onChange={formik.handleChange("first_name")}
                error={
                  formik.touched.first_name && Boolean(formik.errors.first_name)
                }
                helperText={
                  formik.touched.first_name && formik.errors.first_name
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6}>
              <label className={classes.label}>Sponsor Last Name</label>
              <AppTextFieldWhite
                id="sponsorLastName"
                placeholder="Enter Name"
                variant="standard"
                fullWidth
                value={formik.values.last_name}
                onChange={formik.handleChange("last_name")}
                error={
                  formik.touched.last_name && Boolean(formik.errors.last_name)
                }
                helperText={formik.touched.last_name && formik.errors.last_name}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6}>
              <label className={classes.label}>Company Name</label>
              <AppTextFieldWhite
                id="CompanyName"
                placeholder="Enter Company Name"
                variant="standard"
                fullWidth
                value={formik.values.company_name}
                onChange={formik.handleChange("company_name")}
                error={
                  formik.touched.company_name &&
                  Boolean(formik.errors.company_name)
                }
                helperText={
                  formik.touched.company_name && formik.errors.company_name
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
            <Grid item lg={4} md={6} sm={6}>
              <label className={classes.lebel}>Phone Number</label>
              <Grid container wrap="nowrap">
                <Grid item>
                  <PhoneInput
                    country={"us"}
                    id="countryCode"
                    placeholder="Code"
                    value={formik.values.countryCode}
                    onChange={formik.handleChange("countryCode")}
                    error={
                      formik.touched.countryCode &&
                      Boolean(formik.errors.countryCode)
                    }
                    helperText={
                      formik.touched.countryCode && formik.errors.countryCode
                    }
                    inputStyle={{
                      border: "1px solid #F4F2FF",
                      borderRadius: "6px",
                      fontSize: "15px",
                      width: "110px",
                      height: 46,
                      marginRight: "12px"
                    }}
                    dropdownStyle={{
                      width: "550%",
                      marginBottom: 30,
                      height: 100
                    }}
                    buttonStyle={{
                      borderRadius: "4px"
                    }}
                  />
                  <div style={{ color: "red", fontSize: "12px" }}>
                    {formik.touched.countryCode && formik.errors.countryCode}
                  </div>
                </Grid>
                <Grid item xs>
                  <AppTextFieldWhite
                    id="phone"
                    placeholder="Enter Contact Number"
                    variant="standard"
                    fullWidth
                    type="tel"
                    onKeyDown={preventNonNumericalInput}
                    value={formik.values.phone}
                    onChange={formik.handleChange("phone")}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ disableUnderline: true }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Fragment>
  );
};

export default SponsorEntityForm;
