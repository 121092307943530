import {
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  Paper,
  Table,
  Grid,
  TableBody
} from "@material-ui/core";
import Illustrations from "components/illustration/Illustrations";
import LightTooltip from "components/LightTooltip";
import NewDropDown from "components/NewDropDown";
import { truncate } from "helpers/Common";
import InvestmentToolTipData from "pages/private/entities/detail/section/Investment/InvestmentToolTipData";
import React, { useState } from "react";
import styles from "../../../../entities/detail/EntityDetail.module.scss";
import investmentEmptyScreen from "assets/emptyState/noInvestments.svg";
import entitiesEmptyScreen from "assets/emptyState/noEntity.svg";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "../../../../../../redux/index";

function InvestmentSponsorTable({ tableData, heading, tableType = false }) {
  const [expandIndex, setExpandIndex] = useState(-1);
  let setLoanHead = 0;
  tableData?.map((value) => {
    if (Object.keys(value)?.length > 0) {
      setLoanHead = 1;
    }
  });

  const handleCellClick = (e, name, id) => {
    switch (name) {
      case "investment_id":
        AppDispatcher.setInvestmentDetailID(id);
        history.push("/u/investments/detail");
        break;
      case "sponsor_entity_id":
        AppDispatcher.setEntitiesDetailData({
          id: id,
          entity_type: "SPONSOR"
        });
        history.push("/u/entities/detail");
        break;
      case "investor_id":
        AppDispatcher.setEntitiesDetailData({
          id: id,
          entity_type: "INVESTOR"
        });
        history.push("/u/entities/detail");
        break;
    }
  };

  const history = useHistory();

  return (
    <>
      {tableData?.length != 0 ? (
        <TableContainer
          component={Paper}
          style={{ boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {setLoanHead == 1 ? (
              <TableHead>
                <TableRow>
                  {heading.map((value, index) => {
                    return (
                      <TableCell key={index} className={styles.noiTableHeader}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : (
              <Illustrations
                src={tableType ? entitiesEmptyScreen : investmentEmptyScreen}
                heading={
                  tableType ? "No Entities found" : "No Investments found"
                }
                // subHeading="Add Investment Data To View"
              />
            )}
            <TableBody>
              {tableData?.map((value, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {heading.map((item, indx) => {
                      return (
                        <LightTooltip
                          key={indx}
                          title={
                            <InvestmentToolTipData
                              data={value["toolTipData"]}
                            />
                          }>
                          {typeof value[indx] === "object" ? (
                            <TableCell style={{ width: "200px" }}>
                              {value[indx].length > 0 ? (
                                <span
                                  style={{
                                    backgroundColor: "rgba(189, 84, 185, 0.05)"
                                  }}>
                                  <NewDropDown
                                    index={indx}
                                    item={
                                      value[indx]?.map((item) => {
                                        return {
                                          id: item.id,
                                          name: item?.name
                                        };
                                      }) || []
                                    }
                                    // handleCellItemClick={(name, id) => handleCellClick(name, id)}
                                    type="investor_id"
                                    expandIndex={expandIndex}
                                    setExpandIndex={setExpandIndex}
                                    handleCellItemClick={handleCellClick}
                                  />
                                </span>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                          ) : (
                            <TableCell
                              style={
                                indx === 0 || indx === 2
                                  ? { cursor: "pointer" }
                                  : null
                              }
                              onClick={(e) =>
                                indx === 1
                                  ? handleCellClick(
                                      e,
                                      "investment_id",
                                      value["investmentID"]
                                    )
                                  : indx === 2
                                  ? handleCellClick(
                                      e,
                                      "sponsor_entity_id",
                                      value["sponsorEntityID"]
                                    )
                                  : null
                              }
                              align="left"
                              component="th"
                              scope="row">
                              <Grid
                                className={[
                                  indx === 0 || indx === 2
                                    ? styles.firstCell
                                    : null,
                                  tableType
                                    ? indx == 8
                                      ? value[indx] != "-" &&
                                        (value[indx] === "Active"
                                          ? styles.activeCell
                                          : styles.inActiveCell)
                                      : null
                                    : indx == 7
                                    ? value[indx] != "-" &&
                                      (value[indx] === "Active"
                                        ? styles.activeCell
                                        : styles.inActiveCell)
                                    : null
                                ]}>
                                {truncate(value[indx], 20)}
                              </Grid>
                            </TableCell>
                          )}
                        </LightTooltip>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff",
            width: "100vw"
          }}>
          <Illustrations
            src={tableType ? entitiesEmptyScreen : investmentEmptyScreen}
            heading={tableType ? "No Entities found" : "No Investments found"}
            // subHeading="Add Investment Data To View"
          />
        </Grid>
      )}
    </>
  );
}

export default InvestmentSponsorTable;
