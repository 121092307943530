import React from "react";
import styles from "./commanstyle.module.scss";
import noimage from "assets/emptyState/noimage.svg";
// import RedDelete from "assets/icons/NewCardSvg/RedDelete.svg";

const ImageGrid = ({ images }) => {
  return (
    <>
      {images?.length ? (
        <div className={styles.Grid_Container}>
          <div id="item_1" className={styles.item_1}>
            <img
              src={images?.[0]?.file ? images?.[0]?.file : noimage}
              alt="image"
            />
          </div>
          <div id="item_2" className={styles.item_2}>
            <img
              src={images?.[1]?.file ? images?.[1]?.file : noimage}
              alt="image"
            />
          </div>
          <div id="item_3" className={styles.item_3}>
            <img
              src={images?.[2]?.file ? images?.[2]?.file : noimage}
              alt="image"
            />
          </div>
          <div id="item_4" className={styles.item_4}>
            <img
              src={images?.[3]?.file ? images?.[3]?.file : noimage}
              alt="image"
            />
          </div>
          <div id="item_5" className={styles.item_5}>
            <img
              src={images?.[4]?.file ? images?.[4]?.file : noimage}
              alt="image"
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            width: "887px",
            height: "330px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}>
          <img src={noimage} alt="image" />
        </div>
      )}
    </>
  );
};

export default ImageGrid;
