const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [month, day, year].join("/");
};

export default formatDate;

export const dateFormatObj = (date) => {
  let dates = date?.split("-");
  if (dates && dates.length === 3) {
    return `${dates[1]}-${dates[2]}-${dates[0]}`;
  } else {
    return null;
  }
};
