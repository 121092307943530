import { Grid } from "@material-ui/core";
import FieldHeading from "components/fieldHeading";
import DescriptionBox from "components/StyledComponents/DescriptionBox";
import DocumentsUpload from "pages/private/properties/component/DocumentsUpload/documentsUpload";
import RedDelete from "assets/icons/NewCardSvg/RedDelete.svg";
import React, { useState } from "react";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import DeleteModal from "components/Modals/DeleteModal";
import Toast from "components/toast";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";

const K1 = ({
  k1,
  index,
  parentIndex,
  formik,
  arrayHelper,
  DocCategoryK1,
  disabled
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  /*************Delete This Investor***************** */
  const checkDelete = () => {
    if (k1.id) {
      setDeleteModal(true);
    } else {
      arrayHelper.remove(index);
    }
  };

  const handleDelete = async () => {
    const res = await AddInvestmentInvestorService.deleteInvestorK1(k1.id);
    if (res.success) {
      setDeleteModal(false);
      arrayHelper.remove(index);
      Toast.showSuccessToast("K1 Deleted Sucessfully");
    } else {
      return Toast.showErrorToast(res.error.message[0]);
    }
  };
  /*******************************/
  return (
    <Grid container item xs={12} style={index ? { marginTop: "8px" } : {}}>
      <Grid container spacing={1} item xs={10}>
        <Grid item xs={3}>
          <FieldHeading title="year" />
          <SelectWithInfiniteScroll
            disabled={disabled}
            // style={{ width: "80px" }}
            fullWidth
            minValue={
              parseInt(formik.investorSet?.[parentIndex]?.k1?.[index].year) <
              2000
                ? parseInt(formik.investorSet?.[parentIndex]?.k1[index].year)
                : 2000
            }
            selectedItem={k1.year}
            onChange={(value) =>
              formik.setFieldValue(
                `investorSet[${parentIndex}].k1[${index}].year`,
                value
              )
            }
            error={formik.errors.investorSet?.[parentIndex]?.k1?.[index]?.year}
            touched={
              formik.touched.investorSet?.[parentIndex]?.k1?.[index]?.year
            }
          />
        </Grid>
        <Grid item style={{ marginTop: "20px" }}>
          {DocCategoryK1.map((item) => (
            <DocumentsUpload
              key={index}
              type={item.id}
              title={item.name}
              listData={k1.documents}
              setListData={(list) => {
                formik.setFieldValue(
                  `investorSet[${parentIndex}].k1[${index}].documents`,
                  list
                );
              }}
            />
          ))}
        </Grid>

        <Grid item xs={6}>
          <FieldHeading title="Notes" />
          <DescriptionBox
            disabled={disabled}
            id="description"
            placeholder="Add Description"
            variant="standard"
            fullWidth
            multiline
            rows={2}
            value={k1.notes}
            onChange={formik.handleChange(
              `investorSet[${parentIndex}].k1[${index}].notes`
            )}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            inputProps={{
              maxLength: 100
            }}
            error={
              formik.touched.investorSet?.[parentIndex]?.k1?.[index]?.notes &&
              Boolean(
                formik.errors.investorSet?.[parentIndex]?.k1?.[index]?.notes
              )
            }
            helperText={
              formik.touched.investorSet?.[parentIndex]?.k1?.[index]?.notes &&
              formik.errors.investorSet?.[parentIndex]?.k1?.[index]?.notes
            }
          />
          <Grid
            container
            style={{ color: "#589F11" }}
            justifyContent="flex-end">
            {`${k1.notes?.length || 0}/100`}
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={2} justifyContent="flex-end">
        {!disabled ? (
          <div style={{ cursor: "pointer" }} onClick={() => checkDelete()}>
            <img src={RedDelete} alt="del" />
          </div>
        ) : null}
      </Grid>
      <DeleteModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
        title="Are You Sure You Want To Delete This Item?"
        description="This will permanently remove all data associated with it from Simplee."
      />
    </Grid>
  );
};

export default K1;
