import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  Grid,
  CircularProgress,
  makeStyles
} from "@material-ui/core";
import { Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import styles from "./EditSponsor.module.scss";
import { useSelector } from "react-redux";
import { validationSchemaAddSponsor } from "../../../../helpers/validationSchema";
import AppTextField from "../../../../components/StyledComponents/AppTextFeild";
import Toast from "../../../../components/toast";
import { InvestorServices } from "../../../../network/investorServices";
import blueCloseIcon from "../../../../assets/icons/NewCardSvg/blueCloseIcon.svg";
import { SponsorService } from "../../../../network/sponsorService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CommonService } from "../../../../network/commonService";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { verifyDocUploadType } from "helpers/Common";
import NewSelect from "components/StyledComponents/NewSelect";
import DocTable from "components/Common/DocTable";

const useStyles = makeStyles(() => ({
  root: {
    "&  .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 1242,
      height: "fit-content"
    }
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 48.5,
    boxSizing: "border-box"
  },
  MaterialForm: {},
  form: {},
  nameFieldContainer: {
    display: "flex",
    flexDirection: "column",
    // maxWidth: "369px",
    // backgroundColor: "red",
    "@media(min-width: 1259px)": { width: "100%" },
    "@media(max-width: 1259px)": { flexGrow: 1 }
  },
  nameContainer: {
    display: "flex",
    // flexWrap: "wrap",
    gap: 20
  },
  documentHeading: {
    margin: "20px 0px",
    width: "168px",
    height: "22px",
    fontFamily: "Inter Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#191919",
    whiteSpace: "nowrap"
  },

  heading: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "Inter Bold"
  },
  doctableHeading: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#616161"
  },

  textFields: {
    maxWidth: 369
  },
  allDocsContainer: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: "608px",
    background: "#FFFFFF"
  },
  docContainerWrapper: {
    background: "#FAFAFA",
    padding: "20px 20px 22px"
  },
  docContainer: {
    height: 68,
    width: 480,
    backgroundColor: "#E5E9ED",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 20,
    marginBottom: 10
  },
  docImg: {
    height: 40,
    width: 40,
    borderRadius: 8
  },
  docText: {
    fontFamily: "Inter Regular",
    fontWeight: 400,
    fontSize: 16
  },
  docDelete: {
    marginRight: 20,
    marginTop: 10
  },
  country_code_error: {
    fontSize: "0.75rem",
    marginTop: 3,
    textAlign: "left",
    fontFamily: "Inter Regular",
    fontWeight: 400,
    lineHeight: 1.66,
    color: "#E13838"
  },

  statusDropdown: {
    minWidth: "369px",
    "@media(max-width: 800px)": { width: "300px" }
  }
}));

const EditSponsor = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [initValues, setInitValues] = useState({
    firstName: "-",
    lastName: "-",
    company: "-",
    email: "-",
    countryCode: "-",
    phone: "-",
    is_active: "-"
  });
  const [record, setRecord] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const [docsLoading, setDocsLoading] = useState(false);
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [imageList, setImageList] = useState([]);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const { sponsorId, userPermissions, user } = useSelector(
    (store) => store.app
  );

  const fetchSponsorDetail = async () => {
    const resp = await SponsorService.getSponsorDetails([sponsorId + "/"]);
    if (resp.success) {
      let sponsor = resp.data;
      let tempData = { ...initValues };
      tempData.firstName = sponsor.first_name;
      tempData.lastName = sponsor.last_name || "";
      tempData.company = sponsor.company_name;
      tempData.email = sponsor.email;
      tempData.countryCode = sponsor.country_code;
      tempData.phone = sponsor.phone_number;
      tempData.is_active = sponsor.status === "Active" ? true : false;
      setRecord(sponsor.documents);
      let test = [];
      for (let i = 0; i < sponsor.documents.length; i++) {
        test.push(sponsor.documents[i].document_media);
      }
      setAllDocs(test);
      setInitValues({ ...tempData });
    }
  };

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  const handleSubmit1 = async (val) => {
    const payload = {
      first_name: val.firstName,
      last_name: val.lastName,
      company_name: val.company,
      phone_number: val.phone,
      country_code: "+" + val.countryCode.replaceAll("+", ""),
      is_active: val.is_active,
      documents: imageList
    };
    const resp = await SponsorService.editSponsor(payload, [sponsorId + "/"]);
    if (resp.success) {
      setOpen(false);
      history.push("/u/sponsors");
      Toast.showSuccessToast("Sponsor Profile Updated Successfully");
    } else {
      Toast.showErrorToast(resp.error.message[0]);
    }
  };

  const deleteImage = async (id) => {
    setAllDocs((prev) => {
      return prev.filter((item) => item.id != id);
    });
    const index = record.findIndex((item) => item.document_media.id === id);
    const deleteid = record[index].id;

    const resp = await InvestorServices.deleteInvestorDocs(deleteid);
    if (resp.success) {
      Toast.showSuccessToast("Document Deleted Successfully");
    } else {
      Toast.showErrorToast("Unable To Delete Document");
    }
  };

  const onFileDrop = (e) => {
    if (!verifyDocUploadType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      setDocsLoading(false);
      const updatedFileList = [...imageList, resp.data];
      setImageList(updatedFileList);
      const updateAllDocs = [...allDocs, resp.data];
      setAllDocs(updateAllDocs);
      Toast.showSuccessToast("Documents Upload Successfully");
    } else {
      Toast.showErrorToast("Error Uploading Documents");
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "SPONSORS",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_update) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    checkPermissions();
    fetchSponsorDetail();
  }, []);

  return (
    <div>
      <Dialog
        className={classes.root}
        open={open}
        onClose={handleClose}
        style={{
          backgroundColor: "rgba(235, 239, 255, 0.6)",
          backdropFilter: "blur(26)"
        }}>
        <DialogContent>
          <div className={classes.MaterialForm}>
            <div className={classes.header}>
              <div>
                <Typography variant="h4" className={classes.heading}>
                  Edit Sponsor
                </Typography>
              </div>
              <div className={classes.closeButton}>
                <img height="48px" src={blueCloseIcon} onClick={handleClose} />
              </div>
            </div>

            <Formik
              initialValues={initValues}
              validationSchema={validationSchemaAddSponsor}
              enableReinitialize={true}
              className={classes.form}
              onSubmit={(values) => {
                handleSubmit1(values);
              }}>
              {({ errors, touched, values, handleSubmit, handleChange }) => (
                <Form>
                  <Grid spacing={3} container xs={12}>
                    <Grid
                      xs={6}
                      md={4}
                      item
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.labelsEdit}>Email ID</label>
                      <AppTextField
                        id="email"
                        placeholder="Enter Email"
                        variant="standard"
                        value={values.email}
                        className={classes.textFields}
                        onChange={handleChange("email")}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{ disableUnderline: true }}
                        disabled={true}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      md={4}
                      item
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.labelsEdit}>First Name</label>
                      <AppTextField
                        id="firstName"
                        placeholder="Enter First Name"
                        variant="standard"
                        value={values.firstName}
                        className={classes.textFields}
                        onChange={handleChange("firstName")}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 80 }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      md={4}
                      item
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.labelsEdit}>Last Name</label>
                      <AppTextField
                        id="lastName"
                        placeholder="Enter Last Name"
                        variant="standard"
                        value={values.lastName}
                        className={classes.textFields}
                        onChange={handleChange("lastName")}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 80 }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      md={4}
                      item
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.labelsEdit}>Company Name</label>
                      <AppTextField
                        id="company"
                        placeholder="Enter Company Name"
                        variant="standard"
                        value={values.company}
                        className={classes.textFields}
                        onChange={handleChange("company")}
                        error={touched.company && Boolean(errors.company)}
                        helperText={touched.company && errors.company}
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 80 }}
                      />
                    </Grid>
                    <Grid
                      xs={6}
                      md={4}
                      item
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.labelsEdit}>Phone Number</label>
                      <Grid container wrap="nowrap">
                        <Grid item>
                          <PhoneInput
                            country={"us"}
                            placeholder="Code"
                            value={
                              values.countryCode ? values.countryCode : "+1"
                            }
                            onChange={handleChange("countryCode")}
                            error={
                              touched.countryCode && Boolean(errors.countryCode)
                            }
                            helperText={
                              touched.countryCode && errors.countryCode
                            }
                            inputStyle={{
                              border: "1px solid #F4F2FF",
                              borderRadius: "6px",
                              fontSize: "15px",
                              width: "100%",
                              maxWidth: "110px",
                              marginRight: "12px",
                              height: 50
                            }}
                            dropdownStyle={{
                              width: "550%",
                              marginBottom: 30,
                              height: 100
                            }}
                            buttonStyle={{
                              borderRadius: "4px"
                            }}></PhoneInput>
                          <div className={classes.country_code_error}>
                            {touched.countryCode && errors.countryCode}
                          </div>
                        </Grid>

                        <Grid item>
                          <AppTextField
                            id="phone"
                            placeholder="Enter Phone Number"
                            variant="standard"
                            value={values.phone}
                            style={{ maxwidth: "248px" }}
                            onChange={handleChange("phone")}
                            error={touched.phone && Boolean(errors.phone)}
                            helperText={touched.phone && errors.phone}
                            InputLabelProps={{
                              shrink: true
                            }}
                            InputProps={{ disableUnderline: true }}
                            inputProps={{ maxLength: 20 }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={6}
                      md={4}
                      item
                      style={{ display: "flex", flexDirection: "column" }}>
                      <label className={styles.labelsEdit}>Status</label>
                      <NewSelect
                        fullWidth
                        // style={{ minWidth: 369 }}
                        className={classes.statusDropdown}
                        items={[
                          { label: "Active", value: true },
                          { label: "Inactive", value: false }
                        ]}
                        value={values.is_active}
                        onChange={handleChange("is_active")}
                      />
                    </Grid>
                  </Grid>

                  <div>
                    <h4 className={classes.documentHeading}>
                      Upload Documnets
                    </h4>
                    <div className={classes.docContainerWrapper}>
                      <div
                        ref={wrapperRef}
                        className={styles.drop_file_input}
                        onDragEnter={onDragEnter}
                        onDragLeave={onDragLeave}
                        onDrop={onDrop}>
                        <div>
                          {docsLoading ? (
                            <CircularProgress />
                          ) : (
                            <>
                              <p className={styles.droptext}>
                                Drag And Drop Files Here Or <span>Browse</span>
                              </p>
                            </>
                          )}
                        </div>

                        <input type="file" value="" onChange={onFileDrop} />
                      </div>
                      {allDocs.length ? (
                        <Grid container className={classes.allDocsContainer}>
                          <DocTable
                            allDocs={allDocs}
                            deleteImage={deleteImage}
                          />
                        </Grid>
                      ) : (
                        <Box height={20} />
                      )}
                    </div>
                  </div>

                  <div className={styles.buttonContainer}>
                    <button
                      className={styles.cancelbtn}
                      style={{ width: 233, marginRight: 10 }}
                      type="button"
                      onClick={handleClose}>
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={styles.updatebtn}
                      style={{ width: 233, margin: 0 }}
                      onClick={handleSubmit}>
                      Update
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditSponsor;
