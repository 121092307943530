import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import Toast from "components/toast";
import { InvestorServices } from "network/investorServices";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: "rgba(235, 239, 255, 0.6)",
    backdropFilter: "blur(26)"
  },
  dialogContent: {
    width: 560,
    padding: 50,
    boxSizing: "border-box"
  },
  container: {
    marginTop: 30,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  heading: {
    fontFamily: "Inter Bold",
    fontSize: 18
  },
  content: {
    marginTop: 20,
    width: 460,
    textAlign: "center",
    fontFamily: "Inter Regular",
    fontSize: 16,
    color: "#616161"
  },
  btnContainer: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    marginTop: 40
  },
  btnPrimary: {
    backgroundColor: "#E13838",
    color: "#FFFFFF",
    height: 50,
    width: 174,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 10
  },
  btnSecondary: {
    border: "1px solid #445EBE",
    backgroundColor: "#EBEFFF",
    color: "#445EBE",
    height: 48,
    width: 172,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 10
  }
}));

const DeleteInvestor = () => {
  const classes = useStyles();
  const history = useHistory();
  const { investorId } = useSelector((store) => store.app);

  const handleClose = () => {
    history.goBack();
  };

  const deleteSubAdmin = async () => {
    const res = await InvestorServices.deleteInvestor([investorId]);
    if (res.success) {
      Toast.showSuccessToast(res.data.message);
      handleClose();
    } else {
      Toast.showErrorToast(res?.error?.message[0]);
      handleClose();
    }
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      className={classes.dialog}
      maxWidth={false}>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.container}>
          <div className={classes.heading}>
            Are you sure you want to delete this Investor?
          </div>
          <div className={classes.content}>
            You are about to delete a Investor.This will permanently remove all
            their details and assigned permissions from Simplee.
          </div>
          <div className={classes.btnContainer}>
            <div className={classes.btnSecondary} onClick={handleClose}>
              Cancel
            </div>
            <div className={classes.btnPrimary} onClick={deleteSubAdmin}>
              Delete
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteInvestor;
