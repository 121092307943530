import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SelectedColumns from "../components/SelectedColumns/SelectedColumns";
import ChooseColumn from "../components/ChooseColumn/ChooseColumn";
import Heading from "../components/Heading/Heading";
import ChipInput from "components/StyledComponents/ChipInput";
import Button from "@material-ui/core/Button";
// import manageColumns from "../../../assets/icons/manageColumns.svg";

const useStyles = makeStyles((theme) => ({
  secondGrid: {
    margin: "0px 10px"
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  list: {
    height: "310px",
    overflow: "hidden",
    overflowY: "scroll"
  },
  leftContainer: {
    padding: "17px 20px",
    backgroundColor: "#FBFCFE"
  },
  rightContainer: {
    padding: "17px 13px"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    padding: "12px",
    borderRadius: "16px",
    width: "200px",
    cursor: "pointer",
    border: "1px solid #EEF3F8"
  },
  icon: {
    padding: "4px 10px 0px 0px"
  },
  text: {
    color: "#0C0C0C",
    fontFamily: "Inter regular",
    fontSize: "14px",
    letterSpacing: 0,
    lineHeight: "19px",
    fontWeight: 400,
    textTransform: "none",

    "&:focus": {
      outline: "none"
    }
  },
  InputHeading: {
    fontWeight: "700",
    fontSize: "18px",
    paddingBottom: "10px",
    color: "#000000"
  },
  buttonStyleCancel: {
    width: "174px",
    height: "48px",
    border: "1px solid #445EBE",
    marginRight: "15px",
    color: "#445EBE"
  },
  buttonStyle: {
    width: "174px",
    height: "48px",
    background:
      "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)",
    color: "white"
  }
}));
export default function ManageColumnsWeb({
  open,
  columnNames,
  FrdasEmail,
  handleClose,
  checked,
  setChecked,
  handleToggle,
  handleSave,
  handlePreview,
  searchTerm,
  handleSearch,
  handleSelectAll,
  ExtractAllHeaders,
  setEmails
}) {
  const classes = useStyles();
  return (
    <>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Grid container>
          <DialogContent>
            <Heading handleClose={handleClose} Text={FrdasEmail} />
          </DialogContent>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            className={classes.secondGrid}>
            <Grid item xs={6} className={classes.rightContainer}>
              <ChooseColumn
                Text={FrdasEmail}
                handleToggle={handleToggle}
                checked={checked}
                columnNames={columnNames}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                handleSelectAll={handleSelectAll}
                ExtractAllHeaders={ExtractAllHeaders}
                handleClose={handleClose}
                handleSave={handleSave}
              />
            </Grid>
            <Grid item xs={6} className={classes.leftContainer}>
              <SelectedColumns
                checked={checked}
                handleToggle={handleToggle}
                setChecked={setChecked}
              />
            </Grid>
            <Grid item xs={12} className={classes.secondGrid}>
              <div className={classes.InputHeading}>Enter Email ID</div>
              <ChipInput setEmails={setEmails} />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div style={{ padding: 25, display: "flex" }}>
              <Button
                variant="outlined"
                className={classes.buttonStyleCancel}
                onClick={handlePreview}>
                Preview
              </Button>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                disableElevation={true}
                onClick={() => {
                  handleSave();
                }}>
                Share
              </Button>
            </div>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
