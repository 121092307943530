import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DescriptionBox from "components/StyledComponents/DescriptionBox";
import {
  Box,
  //   TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl
} from "@material-ui/core";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import AppTextFieldWhite from "../../components/StyledComponents/AppTextFieldWhite";

// import FormLabel from "@mui/material/FormLabel";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import TextCounter from "components/TextCounter";
import formatCurrency from "helpers/formatCurrency";

const useStyles = makeStyles({
  root: {
    padding: "35px 40px 20px 20px",
    maxWidth: "800px!important"
  },
  header: {
    marginTop: 12,
    fontSize: 20,
    fontFamily: "Inter Bold",
    fontWeight: 700
  },
  desc: {
    fontSize: 18,
    fontFamily: "Inter SemiBold",
    textAlign: "center",
    fontWeight: 600,
    paddingLeft: 70,
    paddingRight: 70
  },
  content: {
    fontSize: 16,
    fontFamily: "Inter Regular",
    fontWeight: 400
  },
  footer: {
    marginBottom: 20,
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  test: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: 30
  },

  //   cancelButton: {
  //     backgroundColor: "#EBEFFF",
  //     paddingTop: 12,
  //     paddingBottom: 12,
  //     border: "1px solid #445EBE",
  //     paddingLeft: 58,
  //     paddingRight: 58,
  //     fontFamily: "Inter Regular",
  //     fontWeight: 600,
  //     color: "#445EBE",
  //     borderRadius: 4,
  //     marginRight: 20,
  //     cursor: "pointer",
  //     fontSize: 18
  //   },
  mainButton: {
    // backgroundColor: "#E13838",
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 58,
    paddingRight: 58,
    fontFamily: "Inter Regular",
    fontWeight: 600,
    color: "#fff",
    borderRadius: 4,
    marginRight: 20,
    cursor: "pointer",
    fontSize: 18
  },
  cancelButton: {
    backgroundColor: "transparent",
    color: "#5775E5",
    width: 218,
    height: 50,
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer",
    border: "1px solid",
    borderColor: "#5775E5",
    marginRight: 10
  },
  btnPrimary: {
    backgroundColor: "#5775E5",
    color: "#ffffff",
    width: 218,
    height: 50,
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer"
  },
  modalHeader: {
    display: "flex",
    "justify-content": "space-between",
    "align-items": "center",
    padding: "10px"
  },
  modalStyle: {
    maxWidth: "800px!important"
  },
  paperStyle: {
    maxWidth: "800px!important"
  }
});

const PropertyEditModal = ({
  title,
  //   description,
  postEditData,
  open,
  handleClose,
  handleSubmit,
  handleCancel,
  charLimit
}) => {
  const classes = useStyles();
  const [value, setValue] = useState("newAssetValue");
  const [comments, setComments] = useState("");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="false"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <div className={classes.modalHeader}>
          <DialogTitle id="alert-dialog-title">
            <Typography className={classes.header}>{title}</Typography>
          </DialogTitle>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close">
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description">
            <Typography variant="h4" className={classes.desc}>
              {description}
            </Typography>
          </DialogContentText> */}
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="property-edit-modal"
              value={value}
              onChange={handleChange}>
              <Box sx={{ minWidth: "50vw" }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center"
                  }}>
                  <FormControlLabel
                    value="newAssetValue"
                    control={<Radio />}
                    label="Total Asset Value will appreciate to "
                  />
                  <Typography
                    style={{
                      fontWeight: 600
                    }}>{`${formatCurrency(
                    postEditData?.new_property_asset
                  )}`}</Typography>
                </Box>
                <Box>
                  <Typography>Comments</Typography>
                  <DescriptionBox
                    id="description"
                    disabled={value !== "newAssetValue"}
                    placeholder="Add comments"
                    variant="standard"
                    fullWidth
                    rows={3}
                    multiline
                    inputProps={{
                      maxlength: charLimit
                    }}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disableUnderline: true
                    }}
                    // error={formik.errors.description}
                    // error={
                    //   formik.touched.description &&
                    //   Boolean(formik.errors.description)
                    // }
                    // helperText={
                    //   formik.touched.description && formik.errors.description
                    // }
                  />
                  <TextCounter
                    currentDescriptionLength={comments.length}
                    maxLength={100}
                  />
                </Box>
              </Box>
              <Typography
                variant="h2"
                style={{ textAlign: "center", color: "#A0A0A0" }}>
                OR
              </Typography>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center"
                }}>
                <FormControlLabel
                  value="currentAssetValue"
                  control={<Radio />}
                  label="Current Total Asset  Value is"
                />
                <Typography
                  style={{
                    fontWeight: 600
                  }}>{`${formatCurrency(
                  postEditData?.current_total_asset
                )}`}</Typography>
              </Box>
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.test}>
          <div className={classes.footer}>
            <div className={classes.cancelButton} onClick={handleCancel}>
              Cancel
            </div>
            <div
              className={classes.btnPrimary}
              onClick={() => handleSubmit(value, comments)}>
              Okay
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PropertyEditModal;
