import React, { useEffect, useState } from "react";
import SponsorCard from "../../../../components/Cards/InvestorCards";
import totalDistributionIcon from "../../../../assets/icons/NewCardSvg/totalDistribution.svg";
import totalEntitiesIcon from "../../../../assets/icons/NewCardSvg/totalEntities.svg";
import totalInvestmentIcon from "../../../../assets/icons/NewCardSvg/totalInvestmentsSponsor.svg";
import { Grid, makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Endpoint, NetworkManager } from "../../../../network/core/index";
import Toast from "components/toast";
import { numFormatter } from "helpers";
import { useHistory } from "react-router-dom";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import ToggleSwitch from "components/Common/ToggleSwitch";
import styles from "components/Common/toggle.module.scss";
import DollerActive from "../../../../assets/icons/tab/DollerActive.svg";
import DollerInactive from "../../../../assets/icons/tab/DollerInactive.svg";
import investorActive from "../../../../assets/icons/tab/investorActive.svg";
import investorInactive from "../../../../assets/icons/tab/investorInactive.svg";
import entitiesActive from "../../../../assets/icons/tab/entitiesActive.svg";
import entitiesInactive from "../../../../assets/icons/tab/entitiesInactive.svg";
import Overview from "./DetailScreen/Overview";
import InvestmentSponsor from "./DetailScreen/Investment/InvestmentSponsor";
import EntitiesSponsor from "./DetailScreen/Entities/EntitiesSponsor";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import { AppDispatcher } from "redux/index";
import { Skeleton } from "@material-ui/lab";
import ExportPDFButton from "components/Buttons/Primary/ExportPDFButton";

const useStyles = makeStyles({
  headerCard: {
    margin: "20px 0px"
  },
  overviewCard: {
    display: "flex",
    marginTop: 20
  },
  sectionContainer: {
    backgroundColor: "#FAFAFA",
    padding: 20,
    borderRadius: "8px",
    marginTop: 20
  },
  header: {
    fontFamily: "Inter Bold",
    fontSize: 18,
    fontWeight: "700",
    fontStyle: "normal"
  },
  docContainer: {
    display: "flex",
    margin: 20,
    width: "fit-content",
    minWidth: "50%",
    maxWidth: "100%",
    flexWrap: "wrap",
    background: "#ffffff",
    borderRadius: "8px",
    padding: 20
  },
  docLength: {
    padding: "1px 5px 2px",
    background: "#445EBE",
    borderRadius: "50%",
    color: "#FFFFFF",
    marginLeft: "5px"
  }
});

const SponsorDetail = () => {
  const classes = useStyles();
  const [permissions, setPermissions] = useState();
  const { sponsorDetailId, userPermissions, user } = useSelector(
    (store) => store.app
  );
  const [sponsorName, setSponsorName] = useState();
  console.log(permissions);
  const [totalInvestment, setTotalInvestment] = useState();
  const [totalDistribution, setTotalDistribution] = useState();
  const [totalEntity, setTotalEntity] = useState();

  const [setEntityDetails] = useState([]);
  const [investments, setInvestments] = useState([]);
  const history = useHistory();
  const [setAllDocs] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [data, setData] = useState();

  const skeleton = (input) => {
    return investments === undefined
      ? input
      : <Skeleton width={150} variant="text" /> || "-";
  };

  const ToggleList = [
    {
      id: 0,
      name: "Overview",
      color: "#5775E5",
      textColor: "#fff",
      inActiveIcon: DollerInactive,
      activeIcon: DollerActive
    },
    {
      id: 1,
      name: "Investments",
      textColor: "#BBBBBB",
      inActiveIcon: investorInactive,
      activeIcon: investorActive
    },
    {
      id: 2,
      name: "Entities",
      textColor: "#BBBBBB",
      inActiveIcon: entitiesInactive,
      activeIcon: entitiesActive
    }
  ];

  const fetchSponsorDetails = async () => {
    const instance = new NetworkManager(
      new Endpoint(`sponsor/detail/${sponsorDetailId}/`, "GET")
    );
    // setLoading(true);
    const resp = await instance.httpRequest();
    if (resp.success) {
      let temp = resp?.data;
      setData(temp);
      setSponsorName(`${temp.first_name} ${temp.last_name}`);
      setTotalInvestment(temp?.stats?.total_investment_count);
      setTotalDistribution(temp?.stats?.total_distribution);
      setTotalEntity(temp?.stats?.total_entity_count);
      setEntityDetails(temp.associated_entity);
      setInvestments(temp.associated_investment);
      setAllDocs(temp?.documents);
    } else {
      Toast.showErrorToast("Failed To Load Sponsor Details");
    }
    // setLoading(false);
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "SPONSORS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  const handleEdit = () => {
    AppDispatcher.setSponsorId(sponsorDetailId);
    history.push("/u/sponsors/editSponsor");
  };

  useEffect(() => {
    checkPermissions();
    fetchSponsorDetails();
  }, []);

  const loadInvestmentDetails = () => {
    switch (selectedIndex) {
      case 0:
        return <Overview data={data} />;
      case 1:
        return <InvestmentSponsor data={data} />;
      case 2:
        return <EntitiesSponsor data={data} />;
      default:
        return <Overview data={data} />;
    }
  };

  return (
    <>
      <HeaderComponent
        backBtn={true}
        heading={skeleton(sponsorName)}
        description={skeleton(`ID: ${data?.base_id}`)}
        investment_Status={skeleton(data?.status)}
        managedBy={skeleton(data?.managed_by)}
        isEditInvestorBtn={true}
        onEditInvestmentBtnClick={handleEdit}
        ExtraButtons={<ExportPDFButton />}
      />
      <Grid container className={classes.headerCard}>
        <Grid item lg={3} md={4} sm={4}>
          <SponsorCard
            Title="Total Distributions"
            count={skeleton(`$${numFormatter(totalDistribution)}`)}
            icon={totalDistributionIcon}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={4}>
          <SponsorCard
            Title="Total Investments"
            count={skeleton(totalInvestment)}
            icon={totalInvestmentIcon}
          />
        </Grid>
        <Grid item lg={3} md={4} sm={4}>
          <SponsorCard
            Title="Total Entities"
            count={skeleton(totalEntity)}
            icon={totalEntitiesIcon}
            link="/u/entities"
          />
        </Grid>
      </Grid>

      {/* ToggleTabs */}

      <div className={styles.toggleresponiveCard}>
        <Grid
          container
          style={{
            backgroundColor: "#FAFAFA",
            height: 56,
            borderRadius: 10
          }}
          className={styles.togglecardGrid}>
          <ToggleSwitch
            setSelectedIndex={setSelectedIndex}
            selectedIndex={selectedIndex}
            list={ToggleList}
            xs={3}
            lg={2}
            md={3}
            sm={3}
          />
        </Grid>
      </div>

      {/*Details*/}
      <Grid container>
        <Grid item xs={12}>
          {loadInvestmentDetails()}
        </Grid>
      </Grid>
    </>
  );
};

export default SponsorDetail;
