import React, { useState } from "react";
import { Grid, IconButton, Box } from "@material-ui/core";
import Toast from "components/toast";
import CustomSelect from "components/StyledComponents/Select";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import RedDelete from "../../../../../../../assets/icons/NewCardSvg/RedDelete.svg";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import DocumentsUpload from "pages/private/properties/component/DocumentsUpload/documentsUpload";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import DeleteModal from "components/Modals/DeleteModal";

const Noi = ({
  id,
  noi,
  arrayHelper,
  formik,
  noiCategories,
  cadenceChoices,
  quarterChoices,
  ClickonNoiEdit
}) => {
  const [deleteModal, setDeleteModal] = useState(false);

  const checkDelete = (deleteid) => {
    if (deleteid) {
      setDeleteModal(true);
    } else {
      arrayHelper.remove(id);
    }
  };
  const handleDelete = async () => {
    if (formik.values.noiSet[id].id) {
      const res = await AddInvestmentBasicDetailsService.deleteNOI(
        formik.values.noiSet[id].id
      );
      if (res.success) {
        Toast.showSuccessToast(res.data.message);
        arrayHelper.remove(id);
      }
      if (!res.success) {
        return Toast.showErrorToast(res.error.message[0]);
      }
    }
  };

  const setDocData = (data) => {
    formik.setFieldValue(`noiSet[${id}].documents`, data);
  };

  return (
    <Grid container>
      <Box
        display="flex no-wrap"
        flexDirection={["row"]}
        justifyContent="space-between"
        alignItems="center"
        style={{
          display: "inline-flex",
          gap: "12px",
          width: "100%",
          ...(id !== 0 && { marginTop: "20px" })
        }}>
        <Box minHeight={"70px"}>
          <CustomSelect
            disabled={!ClickonNoiEdit}
            style={{ width: "120px" }}
            placeholder="Cadence"
            items={cadenceChoices}
            value={
              typeof formik.values.noiSet[id].noi_cadence === "string"
                ? formik.values.noiSet[id].noi_cadence.toLowerCase()
                : formik.values.noiSet[id].noi_cadence
            }
            onChange={(e) =>
              formik.setFieldValue(`noiSet[${id}].noi_cadence`, e.target.value)
            }
            touched={formik.touched?.noiSet?.[id]?.noi_cadence}
            error={formik.errors?.noiSet?.[id]?.noi_cadence}
          />
        </Box>
        <Box minHeight={"70px"}>
          <SelectWithInfiniteScroll
            disabled={!ClickonNoiEdit}
            style={{ width: "80px" }}
            minValue={
              parseInt(formik.values.noiSet[id]?.year) < 2000
                ? parseInt(formik.values.noiSet[id]?.year)
                : 2000
            }
            selectedItem={formik.values.noiSet[id]?.year ?? 2000}
            onChange={(value) =>
              formik.setFieldValue(`noiSet[${id}].year`, value)
            }
            error={formik.errors.noiSet?.[id]?.year}
            touched={formik.touched.noiSet?.[id]?.year}
          />
        </Box>
        {noi?.noi_cadence === "quarterly" && (
          <Box minHeight={"70px"}>
            <CustomSelect
              disabled={!ClickonNoiEdit}
              style={{ width: "80px" }}
              placeholder="Term"
              items={quarterChoices}
              value={formik.values.noiSet[id].noi_quarter ?? ""}
              onChange={(e) =>
                formik.setFieldValue(
                  `noiSet[${id}].noi_quarter`,
                  e.target.value
                )
              }
              error={formik.errors?.noiSet?.[id]?.noi_quarter}
              touched={formik.touched?.noiSet?.[id]?.noi_quarter}
            />
          </Box>
        )}
        <Box minHeight={"70px"} item xs={3}>
          <AppTextFieldWhite
            disabled={!ClickonNoiEdit}
            style={{ width: "150px" }}
            id={`noiSet[${id}].investment_noi`}
            placeholder=""
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.noiSet[id].investment_noi}
            onChange={formik.handleChange(`noiSet[${id}].investment_noi`)}
            error={
              formik?.touched?.noiSet?.[id]?.investment_noi &&
              Boolean(formik?.errors?.noiSet?.[id]?.investment_noi)
            }
            helperText={
              formik.touched?.noiSet?.[id]?.investment_noi &&
              formik.errors?.noiSet?.[id]?.investment_noi
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            inputProps={{ maxLength: 12 }}
          />
        </Box>

        <Box minHeight={"70px"}>
          {noiCategories &&
            noiCategories
              .filter((el) => el.name !== "Misc.")
              .map((category) => (
                <DocumentsUpload
                  key={category.id}
                  type={category.id}
                  title={category.name}
                  listData={formik.values.noiSet?.[id].documents}
                  setListData={(list) => setDocData(list)}
                />
              ))}
        </Box>
        <Box alignSelf={"center"} marginLeft="auto" marginTop={"-16px"}>
          {ClickonNoiEdit && (
            <IconButton
              disableRipple
              onClick={() => checkDelete(formik.values.noiSet[id].id)}>
              <img src={RedDelete} alt="del" />
            </IconButton>
          )}
        </Box>
        <DeleteModal
          open={deleteModal}
          handleClose={() => setDeleteModal(false)}
          handleSubmit={() => handleDelete()}
          title="Are You Sure You Want To Delete This Item?"
          description="This will permanently remove all data associated with it from Simplee."
        />
      </Box>
    </Grid>
  );
};

export default Noi;
