import React, { useState } from "react";
import styles from "./login.module.scss";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { validationSchemaLogin } from "../../../helpers/validationSchema";
import PrimaryButton from "../../../components/Buttons/Primary";
import Checkbox from "../../../components/StyledComponents/AppCheckbox";
import { NetworkManager, API } from "../../../network/core/index";
import { AppDispatcher } from "../../../redux/index";
import Toast from "../../../components/toast";
import IconButton from "@material-ui/core/IconButton";
import VisibilityOff from "../../../assets/icons/eye-off.svg";
import Visibility from "../../../assets/icons/eye.svg";
import { makeStyles } from "@material-ui/core/styles";
import AppTextField from "../../../components/StyledComponents/AppTextFeild";

const useStyles = makeStyles(() => ({
  imageSrc: {
    position: "absolute",
    left: "91%",
    height: "46px",
    width: "40px",
    borderRadius: "0px",
    borderTopRightRadius: "8px",
    borderBottomRightRadius: "8px",
    backgroundColor: "#FAFAFA",
    marginRight: "12px",
    "&:hover": {
      background: "#FAFAFA"
    }
  }
}));

const Login = () => {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  const history = useHistory();
  const [isVisible, setVisible] = useState(false);

  const setPermissions = (data) => {
    let permissions = [];
    if (data?.role !== "ADMIN") {
      data?.access_list?.permission.forEach((perm) => {
        permissions.push({
          module_name: perm?.module_name,
          operations: {
            is_create: perm.is_create,
            is_delete: perm.is_delete,
            is_update: perm.is_update,
            is_retrieve: perm.is_retrieve
          }
        });
      });
    }
    AppDispatcher.setUserPermissions(permissions);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: ""
    },
    validationSchema: validationSchemaLogin,
    onSubmit: async (values) => {
      const payload = {
        email: values.email,
        password: values.password,
        device: {
          device_token: "dummy devices token",
          device_type: "WEB",
          is_safari: 0
        },
        is_remember: checked
      };
      const instance = new NetworkManager(API.AUTH.LOGIN, payload);
      const resp = await instance.httpRequest();
      if (resp.success) {
        setPermissions(resp.data);
        AppDispatcher.setUserLoggedIn(resp.data);
        history.push("/u/dashboard");
      } else {
        Toast.showErrorToast(resp.error.message[0]);
      }
    }
  });
  const handleLogin = () => {
    formik.handleSubmit();
  };

  const handleChange = () => {
    setChecked(!checked);
  };

  const handleForgotPassword = () => {
    history.push("/auth/forgotPassword");
  };

  const keyPress = (e) => {
    if (e.keyCode == 13) {
      handleLogin();
    }
  };

  const stopAction = (e) => {
    e.preventDefault();
  };

  return (
    <div className={styles.container}>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Hi Admin</div>
        <div className={styles.subHeading}></div>
      </div>
      <div className={styles.formContainer}>
        <div className={styles.email}>
          <div
            className={styles.inputText}>
            Email
          </div>
          <AppTextField
            id="email"
            placeholder="Enter Email here"
            variant="standard"
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </div>
        <div className={styles.password}>
          <div
            className={styles.inputText}>
            Password
          </div>
          <AppTextField
            id="password"
            placeholder="Enter Password here"
            variant="standard"
            fullWidth
            onCut={stopAction}
            onCopy={stopAction}
            onPaste={stopAction}
            onKeyDown={keyPress}
            style={{ height: 100, width: 322 }}
            autoComplete="new-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true,
              type: isVisible ? "text" : "password",
              endAdornment: (
                <IconButton
                  style={{ margin: 0 }}
                  aria-label="toggle password visibility"
                  onClick={() => setVisible(!isVisible)}
                  edge="end"
                  className={classes.imageSrc}>
                  {isVisible ? (
                    <img src={Visibility} alt="alt" />
                  ) : (
                    <img src={VisibilityOff} alt="alt" />
                  )}
                </IconButton>
              )
            }}
          />
        </div>
      </div>
      <div className={styles.formTagContainer}>
        <div className={styles.formTagContainer1}>
          <Checkbox
            isOn={checked}
            onToggle={handleChange}
            style={{ cursor: "pointer" }}
            isFilled={true}
          />
          <p className={styles.rememberME}> Remember Me </p>
        </div>
        <button
          className={styles.formTagContainer2}
          onClick={() => handleForgotPassword()}>
          Forgot Password ?
        </button>
      </div>
      <div className={styles.marginTop}>
        <PrimaryButton text="Log In" onPress={handleLogin} width="233px" />
      </div>
    </div>
  );
};

export default Login;
