import React, { useState, useEffect } from "react";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import AppTable from "../../../components/AppTable";
import { Grid } from "@material-ui/core";
import TotalInvestorIcon from "../../../assets/icons/NewCardSvg/totalinvestorIcon.svg";
import TotalInvestementIcon from "../../../assets/icons/NewCardSvg/voiletDollar.svg";
import TotalDistributionIcon from "../../../assets/icons/NewCardSvg/totalEntities.svg";
import TotalDistributionReceivedIcon from "../../../assets/icons/NewCardSvg/totalInvestmentsSponsor.svg";
import { InvestorServices } from "network/investorServices";
import { numFormatter } from "helpers";
import { useSelector } from "react-redux";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import styles1 from "../../private/investments/investments.module.css";
import { useHistory } from "react-router-dom";
import DistributionCard from "components/Cards/DistributionCard";
import { Skeleton } from "@material-ui/lab";

const containerStyle = { marginTop: 32 };

const Investors = () => {
  const [stats, setStats] = useState({});
  const [permissions, setPermissions] = useState();
  const { userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();
  const fetchStats = async () => {
    const res = await InvestorServices.getInvestorStats();
    if (res.success) {
      setStats(res.data);
    }
  };
  const processIncommingData = (data) => {
    return data !== null ? data : <Skeleton width={150} />;
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "INVESTORS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(() => {
    checkPermissions();
    fetchStats();
  }, []);

  const cardsData = [
    {
      title: "Total Investors",
      link: "/u/investors",
      count: processIncommingData(
        stats?.total_investor ? stats?.total_investor : null
      ),
      icon: TotalInvestorIcon,
      toolTipAvailable: (
        <Grid style={{ width: "150px" }}>
          <Grid container spacing={1}>
            <Grid item xs={10} style={{ fontSize: "16px", padding: "5px 5px" }}>
              Total Active
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              item
              xs={2}
              style={{ fontSize: "16px", padding: "5px 5px" }}>
              {stats?.active_investor}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={10} style={{ fontSize: "16px", padding: "5px 5px" }}>
              Total Inactive
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              item
              xs={2}
              style={{ fontSize: "16px", padding: "5px 5px" }}>
              {stats?.inactive_investor}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={10} style={{ fontSize: "16px", padding: "5px 5px" }}>
              Pending
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              item
              xs={2}
              style={{ fontSize: "16px", padding: "5px 5px" }}>
              {stats?.pending_investor}
            </Grid>
          </Grid>
        </Grid>
      )
    },
    {
      title: "Total Investments",
      link: "/u/investments",
      count: processIncommingData(
        stats?.total_investment ? stats?.total_investment : null
      ),
      icon: TotalInvestementIcon
    },
    {
      title: "Total Entities",
      link: "/u/entities",
      count: processIncommingData(
        stats?.total_investment ? stats.total_entity : null
      ),
      icon: TotalDistributionIcon
    },
    {
      title: "Total Invested Amount",
      count: stats.total_invested_amount
        ? processIncommingData(`$${numFormatter(stats.total_invested_amount)}`)
        : processIncommingData(null),
      icon: TotalDistributionReceivedIcon
    }
  ];

  return (
    <>
      <HeaderComponent
        heading="Investors"
        description="List Of All The Investors On Simplee "
        ExtraButtons={
          permissions?.is_create ? (
            <div className={styles1.addInvestmentButtonContainer}>
              <p
                className={styles1.addInvestmentButtonText}
                onClick={() => history.push("/u/investors/addinvestors")}>
                + Add Investor
              </p>
            </div>
          ) : null
        }
      />
      <Grid container style={containerStyle} direction="row">
        {cardsData.map((item, index) => {
          return (
            <DistributionCard
              key={index}
              title={item?.title}
              count={item?.count}
              icon={item?.icon}
              link={item?.link}
              toolTip={item?.toolTipAvailable}
              last={cardsData.length === index + 1}
            />
          );
        })}
      </Grid>

      <Grid container item xs={12}>
        <AppTable permissions={permissions} />
      </Grid>
    </>
  );
};

export default Investors;
