import { Grid } from "@material-ui/core";
import React from "react";
import { Skeleton } from "@material-ui/lab";
import StreetViewComponent from "./StreetViewComponent";

function ImageComponent({ imageList, propertyData }) {
  return (
    <>
      {Object.keys(propertyData).length == 0 ? (
        <Grid justifyContent="center" alignItems="center" container xs={12}>
          <Skeleton
            variant="rounded"
            animation="wave"
            width={600}
            height={400}
          />
        </Grid>
      ) : (
        <Grid
          xs={12}
          style={{ padding: "10px", height: "465px" }}
          item
          container>
          {imageList?.length === 1 && (
            <img
              src={imageList[0].file}
              style={{
                height: "100%",
                borderRadius: "8px",
                width: "100%",
                objectFit: "cover"
              }}
            />
          )}

          {imageList?.length === 2 && (
            <Grid spacing={1} xs={12} container>
              {imageList?.map((item, index) => {
                return (
                  <Grid key={index} item md={12} lg={6}>
                    <img
                      src={item?.file}
                      style={{
                        height: "100%",
                        borderRadius: "8px",
                        width: "100%",
                        objectFit: "cover"
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}

          {imageList?.length === 3 && (
            <Grid spacing={1} xs={12} container>
              <Grid item xs={6}>
                <img
                  src={imageList[0]?.file}
                  style={{
                    height: "100%",
                    borderRadius: "8px",
                    width: "100%",
                    objectFit: "cover"
                  }}
                />
              </Grid>
              <Grid alignItems="center" conatiner item xs={6}>
                <Grid xs={12}>
                  <img
                    src={imageList[1]?.file}
                    style={{
                      height: "100%",
                      borderRadius: "8px",
                      width: "80%",
                      objectFit: "cover"
                    }}
                  />
                </Grid>
                <Grid xs={12}>
                  <img
                    src={imageList[2]?.file}
                    style={{
                      height: "100%",
                      borderRadius: "8px",
                      width: "80%",
                      objectFit: "cover"
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {imageList?.length === 4 && (
            <Grid xs={12} container>
              <Grid alignItems="center" container item xs={6}>
                <Grid
                  style={{ height: "230px" }}
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  container
                  xs={12}>
                  <img
                    src={imageList[0]?.file}
                    style={{
                      height: "100%",
                      borderRadius: "8px",
                      width: "80%",
                      objectFit: "cover"
                    }}
                  />
                </Grid>
                <Grid
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={1}
                  container
                  xs={12}
                  style={{ height: "230px", paddingTop: "5px" }}>
                  <img
                    src={imageList[1]?.file}
                    style={{
                      height: "100%",
                      borderRadius: "8px",
                      width: "80%",
                      objectFit: "cover"
                    }}
                  />
                </Grid>
              </Grid>
              <Grid alignItems="center" container item xs={6}>
                <Grid style={{ height: "230px" }} xs={12}>
                  <img
                    src={imageList[2]?.file}
                    style={{
                      height: "100%",
                      borderRadius: "8px",
                      width: "80%",
                      objectFit: "cover"
                    }}
                  />
                </Grid>
                <Grid style={{ height: "230px", paddingTop: "5px" }} xs={12}>
                  <img
                    src={imageList[3]?.file}
                    style={{
                      height: "100%",
                      borderRadius: "8px",
                      width: "80%",
                      objectFit: "cover"
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {imageList?.length === 5 && (
            <Grid xs={12} container>
              <Grid item xs={6}>
                <img
                  src={imageList[0]?.file}
                  style={{
                    height: "100%",
                    borderRadius: "8px",
                    width: "100%",
                    objectFit: "cover"
                  }}
                />
              </Grid>
              <Grid container item xs={6}>
                <Grid container item xs={6}>
                  <Grid
                    alignItems="center"
                    justifyContent="flex-end"
                    container
                    xs={12}>
                    <img
                      src={imageList[1]?.file}
                      style={{
                        height: "100%",
                        borderRadius: "8px",
                        width: "80%",
                        objectFit: "cover"
                      }}
                    />
                  </Grid>
                  <Grid
                    alignItems="center"
                    justifyContent="flex-end"
                    container
                    xs={12}
                    style={{ paddingTop: "10px", height: "230px" }}>
                    <img
                      src={imageList[2]?.file}
                      style={{
                        height: "100%",
                        borderRadius: "8px",
                        width: "80%",
                        objectFit: "cover"
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={6}>
                  <Grid
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                    container
                    xs={12}>
                    <img
                      src={imageList[3]?.file}
                      style={{
                        height: "100%",
                        borderRadius: "8px",
                        width: "80%",
                        objectFit: "cover"
                      }}
                    />
                  </Grid>
                  <Grid
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={1}
                    container
                    xs={12}
                    style={{ paddingTop: "10px" }}>
                    <img
                      src={imageList[4]?.file}
                      style={{
                        height: "100%",
                        borderRadius: "8px",
                        width: "80%",
                        objectFit: "cover"
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {(imageList?.length > 5 || imageList?.length === 0) && (
            <Grid style={{ height: "100%", width: "100%" }}>
              <StreetViewComponent propertyData={propertyData} />
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
}

export default ImageComponent;
