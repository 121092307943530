import React from "react";
// import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import Table from "./components/table/Table";

const Documents = () => {
  return (
    <>
      <Table />
    </>
  );
};

export default Documents;
