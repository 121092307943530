import { Grid } from "@material-ui/core";
import React from "react";
import styles from "./NewComponent.module.scss";
import EditIcon from "assets/icons/EditIcon.svg";

const SectionLayout = ({ dropShadow, heading, children, goToStep }) => {
  return (
    <Grid item xs={12}>
      <Grid container className={styles.main_continer}>
        <Grid item xs={12} className={styles.sub_c1}>
          <h3 className={styles.c_heading}>{heading}</h3>
          <div className={styles.img_c} onClick={() => goToStep(heading)}>
            <img src={EditIcon} alt="edit" />
          </div>
        </Grid>
        <Grid
          container
          wrap="nowrap"
          item
          xs={12}
          className={styles.sub_c2}
          style={
            dropShadow
              ? {
                  filter: "drop-shadow(0px 4px 15px rgba(160, 160, 160, 0.15))"
                }
              : {}
          }>
          {children}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionLayout;
