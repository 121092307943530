import React, { useState, useEffect, Fragment } from "react";
import { useHistory } from "react-router-dom";
import styles from "./AppTable.module.css";
import DotsMenu from "assets/icons/dotsMenu.svg";
import {
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Grid,
  Hidden
} from "@material-ui/core";
import { AppDispatcher } from "redux/index";
import styles1 from "pages/private/investments/investments.module.css";
import SearchInput from "components/Search";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import NewDropDown from "components/NewDropDown";
import { InvestmentListService } from "network/investment_ListService";
import { EntityService } from "network/entityService";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Toast from "components/toast";
import CloseIcon from "assets/icons/cross.svg";
import { FilterStatus, truncate } from "helpers/Common";
import noInvestors from "assets/emptyState/noInvestors.svg";
import Illustrations from "components/illustration/Illustrations";
import NewTable from "components/NewTable/NewTable";
import ManageColumn from "components/ManageColumns";
import MoreFilterContainer from "components/MoreFilterContainer/MoreFilterContainer";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
// import downloadIcon from "assets/icons/Download.svg";
import filterIcon from "assets/icons/Filter.svg";
import BluefilterIcon from "assets/icons/blueFilter.svg";
import DotFilterIcon from "assets/icons/DotFilter.svg";
// import blueDot from "assets/icons/NewCardSvg/blueDot.svg";
import CSV from "assets/icons/NewCardSvg/CSV.svg";
import FWDEmail from "assets/icons/NewCardSvg/FWDEmail.svg";
import FrwrdAsEmail from "components/ForwarsAsEmail";
import LightTooltip from "components/LightTooltip";
import { PropertiesService } from "network/propertiesService";
import formatCurrency from "helpers/formatCurrency";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
import { numFormatter } from "helpers";

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const manageByList = [
  {
    label: "Managed By",
    value: "managed_by"
  },
  {
    label: "Admin",
    value: "ADMIN"
  },
  {
    label: "Investor",
    value: "INVESTOR"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const TableHeaders = [
  {
    id: "property_name",
    name: "Property Name"
  },
  {
    id: "investment_name",
    name: "Investment Name"
  },
  {
    id: "type",
    name: "Type"
  },
  {
    id: "property_value",
    name: "Property Value"
  },
  {
    id: "noi",
    name: "NOI"
  },
  {
    id: "area",
    name: "Area"
  },
  {
    id: "managed_by",
    name: "Managed By"
  },
  {
    id: "sponsor_name",
    name: "Sponsor"
  },
  {
    id: "investment_status",
    name: "Investment Status"
  }
];

function AppTable({ permissions }) {
  const history = useHistory();
  const [THeaders, setTHeaders] = useState(TableHeaders);
  const [manageBy, setManageBy] = useState("managed_by");
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [tempr, setTempr] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [propertiesStatus, setPropertiesStatus] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [monthChoices, setMonthChoices] = useState([]);
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);
  const [checked, setChecked] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [status, setStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState(10);
  const [Export, setExport] = useState("exportList");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageResetValue, setPageResetValue] = useState(0);
  const [isFilterActive, setFilterActive] = useState(false);
  const [expandIndex, setExpandIndex] = useState(-1);
  const [check, setcheck] = useState("Admin");

  const mappingObject = {
    "Investment Name": "investment_id",
    "Entity Name": "entity_id",
    "Sponsor Name": "sponsor_id",
    "Investor Name": "investor_id",
    "Sponsor Entity Name": "sponsor_entity",
    "Investor Entity Name": "investor_entity",
    "Property Type": "type"
  };

  const handleApply = (data) => {
    setShowFilter(false);
    setChecked([...data]);
  };

  const handleClear = () => {
    setShowFilter(false);
    setChecked([]);
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    AppDispatcher.setPropertiesId(item);
  };

  const handleEdit = () => {
    history.push("/u/properties/editProperties");
  };

  const handleCellClick = (item, cell) => {
    if (cell == "properties") {
      AppDispatcher.setPropertiesDetailID(item.id);
    } else if (cell == "investments") {
      AppDispatcher.setInvestmentDetailID(item.investment);
    } else {
      return;
    }

    history.push(`/u/${cell}/detail`);
  };

  const handleCellSponsorClick = (e, type, itemId) => {
    AppDispatcher.setSponsorDetailID(itemId);
    history.push(`/u/sponsors/detail`);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleClick = () => {
    setShowFilter(!showFilter);
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestmentName([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getEntityNames = async (page = 1, search = "") => {
    const response = await EntityService.getEntityListByRole({
      entity_type: "INVESTOR",
      page,
      ...(search ? { search } : {})
    });
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getPropertyTypes = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      return {
        data: res.data.PROPERTY_TYPE,
        maxPage: 1
      };
    } else {
      return { data: [], maxPage: 1 };
    }
  };

  const getInvestorName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestors([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponserName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getSponsors([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponserEntityName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getSponserEntity([
      `?entity_type=SPONSOR${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      const { PROPERTY_TYPE, STATUSES, MONTH_CHOICES } = res.data;
      setPropertyType(PROPERTY_TYPE);
      setMonthChoices(MONTH_CHOICES);

      setPropertiesStatus([...STATUSES, { label: "All", value: "All" }]);
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const ChipsComponent = () => {
    let chipsArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        chipsArray.push(newObj);
      });
    });

    return (
      <div className={styles1.chipsCtr}>
        {chipsArray.length > 0
          ? chipsArray.map((item, index) => {
              return (
                <div key={index} className={styles1.chips}>
                  <div className={styles1.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles1.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const handleDeleteProperty = () => {
    history.push("/u/properties/deleteProperties");
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let keyData = newCheckedData?.[keyIndex]?.value;
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
  };

  const getInvestorsData = async (pageNum, isAppend = false) => {
    setLoading(true);
    let url = `properties/?status=${status}`;
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          if (item.key === "Property Type") {
            url += `&type=`;
            item.value.forEach((el) => {
              url += `${el.id},`;
            });
          } else {
            url += `&${mappingObject[item.key]}=`;
            item.value.forEach((el) => {
              url += `${el.id},`;
            });
          }
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (manageBy === "ADMIN" || manageBy === "INVESTOR") {
      url += `&managed_by=${manageBy}`;
    }
    url += `&page=${pageNum}&page_size=${pageSize}`;
    const response = await PropertiesService.getProperties([url]);
    if (response.success) {
      if (isAppend) {
        setTempr([...tempr, ...response.data.results]);
      } else {
        setTempr(response.data.results);
      }
      setCount(response.data.count);
    }
    setLoading(false);
  };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getInvestorsData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const setFilterMeta = () => {
    setFilterState([
      {
        key: "Investment Name",
        placeholder: "Select Investment Name",
        value: getInvestmentName
      },
      {
        key: "Sponsor Name",
        placeholder: "Select Sponsor Name",
        value: getSponserName
      },
      {
        key: "Sponsor Entity Name",
        placeholder: "Select Sponsor Entity",
        value: getSponserEntityName
      },
      {
        key: "Investor Name",
        placeholder: "Select Investor Name",
        value: getInvestorName
      },
      {
        key: "Investor Entity Name",
        placeholder: "Select Investor Entity",
        value: getEntityNames
      },
      {
        key: "Property Type",
        placeholder: "Select Property Type",
        value: getPropertyTypes
      }
    ]);
  };

  useEffect(() => {
    getOptions();
    setFilterMeta();
  }, []);

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getInvestorsData(1);
    setFilterActive(FilterStatus(checked));
  }, [status, searchQuery, checked, pageSize, count, manageBy]);

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = `?status=${status}`;

    if (emailData) {
      if (preview === "preview") {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `&${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          if (item.key === "Property Type") {
            url += `&type=`;
            item.value.forEach((el) => {
              url += `${el.id},`;
            });
          } else {
            url += `&${mappingObject[item.key]}=`;
            item.value.forEach((el) => {
              url += `${el.id},`;
            });
          }
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (manageBy === "ADMIN" || manageBy === "INVESTOR") {
      url += `&managed_by=${manageBy}`;
    }
    url += `&page=${page}&page_size=${pageSize}`;

    setExport("exportList");
    const response = await PropertiesService.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          // link.target = "_blank";
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  /******************************************************************** */
  /*********************       New Table Data           *****************/
  /*********************************************************************/
  const getTooltipData = (item) => {
    const mainDiv = {
      display: "flex",
      justifyContent: "space-between",
      padding: "12px",
      width: "300px"
    };
    const heading = { fontSize: "16px", fontWeight: 400, color: "#A0A0A0" };
    const Value = { fontSize: "16px", fontWeight: 600, color: "#191919" };
    return (
      <>
        <div style={mainDiv}>
          <h3 style={heading}>Property ID:</h3>
          <h3 style={Value}>{item?.base_id}</h3>
        </div>
        {/* <div style={mainDiv}>
          <h3 style={heading}>Occupancy:</h3>
          <h3 style={Value}>{item?.occupancy ? `${item?.occupancy}%` : "-"}</h3>
        </div> */}
        <div style={mainDiv}>
          <h3 style={heading}>Total Units:</h3>
          <h3 style={Value}>{numFormatter(item?.total_units)}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>DSCR:</h3>
          <h3 style={Value}>{numFormatter(item?.dscr)}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Loan:</h3>
          <h3 style={Value}>{item?.loan_id?.length ? "Yes" : "No"}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Outstanding Loan:</h3>
          <h3 style={Value}>{formatCurrency(item?.stats?.outstanding_loan)}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Year Of Build:</h3>
          <h3 style={Value}>{item?.build_year}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Property Status:</h3>
          <h3
            style={{
              ...Value,
              color: item?.status === "stabilized" ? "#55a55e" : "#E13838",
              fontWeight: 400,
              textTransform: "capitalize"
            }}>
            {item?.status ? item?.status : "-"}
          </h3>
        </div>
      </>
    );
  };

  const Tabledatalist =
    tempr &&
    tempr.map((item, index) => {
      return [
        <LightTooltip key="property_name" title={getTooltipData(item)}>
          <div
            className={`${styles.text}`}
            style={{ cursor: "pointer" }}
            onClick={() => handleCellClick(item, "properties")}>
            {item?.name}
          </div>
        </LightTooltip>,
        <LightTooltip key="investment_name" title={getTooltipData(item)}>
          <div
            className={`${styles.text}`}
            style={{ cursor: "pointer" }}
            onClick={() => handleCellClick(item, "investments")}>
            {truncate(item?.investment_name, 16)}
          </div>
        </LightTooltip>,
        <LightTooltip key="type" title={getTooltipData(item)}>
          <div className={`${styles.text}`}>
            {propertyType.find((prop) => prop.value == item?.type)?.label}
          </div>
        </LightTooltip>,
        <LightTooltip key="property_value" title={getTooltipData(item)}>
          <div className={`${styles.text}`}>
            {item?.value ? formatCurrency(item?.value || "0") : "-"}
          </div>
        </LightTooltip>,
        <LightTooltip key="noi" title={getTooltipData(item)}>
          <div style={{ width: "max-content" }}>
            <span style={{ marginRight: 5 }} className={styles.text}>
              {item?.property_noi[0]?.noi
                ? formatCurrency(item?.property_noi[0]?.noi || "0")
                : "-"}
            </span>
            <span
              style={{
                padding: "8px 10px 8px 12px",
                backgroundColor: item?.property_noi[0]?.year ? "#edffef" : null,
                borderRadius: 153,
                color: item?.property_noi[0]?.year ? "#55a55e" : null,
                fontWeight: 600,
                fontSize: "12px"
              }}
              className={styles.text}>
              {item?.property_noi.length > 0
                ? `${item?.property_noi[0]?.year} | ${
                    monthChoices.find(
                      (mon) => mon.value == item?.property_noi[0]?.month
                    ).label
                  }`
                : ""}
            </span>
          </div>
        </LightTooltip>,
        <LightTooltip key="area" title={getTooltipData(item)}>
          <div className={`${styles.text}`}>
            {item?.area ? `${item?.area} ${item?.units}` : "-"}
          </div>
        </LightTooltip>,
        <LightTooltip key="managed_by" title={getTooltipData(item)}>
          <div className={styles.text}>{item?.managed_by}</div>
        </LightTooltip>,
        <div
          key="sponsor_name"
          className={styles.text}
          style={{ cursor: "pointer", width: "90px" }}>
          {item?.sponsor_name?.length ? (
            <NewDropDown
              index={index}
              item={item?.sponsor_name?.map((item) => {
                return {
                  name: truncate(item?.first_name + " " + item?.last_name, 12),
                  id: item?.id
                };
              })}
              handleCellItemClick={handleCellSponsorClick}
              type="sponsor"
              expandIndex={expandIndex}
              setExpandIndex={setExpandIndex}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>,
        <LightTooltip key="investment_status" title={getTooltipData(item)}>
          <div
            style={{
              minHeight: 28,
              width: 72,
              alignItems: "center",
              backgroundColor:
                item?.investment_status === "Draft"
                  ? "#EBEFFF"
                  : item?.investment_status === "Active"
                  ? "#EDFFEF"
                  : "#FCEBEB ",
              borderRadius: 153,
              textAlign: "center",
              fontFamily: "Inter Bold",
              fontSize: 14,
              paddingTop: 4,
              color:
                item?.investment_status === "Active"
                  ? "#50E138"
                  : item?.investment_status === "Draft"
                  ? "#445EBE"
                  : "#E13838",
              fontWeight: 700,
              marginLeft: 8
            }}>
            {item?.investment_status}
          </div>
        </LightTooltip>,
        (permissions?.is_update || permissions?.is_delete) && (
          <div key="Action">
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => {
                handleClick(e, item.id);
                setcheck(item?.managed_by);
              }}>
              <img src={DotsMenu} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  marginTop: 40,
                  boxShadow: "5px 5px 5px #E9E9E9"
                }
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}>
              {permissions?.is_update ? (
                <div>
                  <MenuItem
                    onClick={handleEdit}
                    style={{
                      fontFamily: "Inter Regular"
                    }}>
                    Edit
                  </MenuItem>
                </div>
              ) : null}
              {permissions?.is_delete ? (
                <MenuItem
                  disabled={check !== "Admin"}
                  className={styles.menuItemDelete}
                  onClick={handleDeleteProperty}>
                  Delete
                </MenuItem>
              ) : null}
            </Menu>
          </div>
        )
      ];
    });

  /******************************************************************* */
  /******************************************************************* */
  /******************************************************************* */

  return (
    <>
      {/* {loading && <Loader />} */}

      <FrwrdAsEmail
        AllHeaders={TableHeaders}
        FrdasEmail={FrdasEmail}
        setFrdasEmail={setFrdasEmail}
        open={frdEmailOpen}
        setOpen={setFrdasEmailOpen}
        clickOnShare={(payload) => {
          getExport_PDF_CSV_Link(payload);
        }}
        clickOnPreview={(payload) => {
          getExport_PDF_CSV_Link(payload, "preview");
        }}
      />

      <div className={styles.tableCtrOuter}>
        <TableTopFilter
          tabletViewCSV={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={CSV}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={ExportList}
                clickMenuItem={(obj, close) => {
                  setExport(obj?.value);
                  close();
                }}
              />
            </div>
          }
          tabletViewFWDEmail={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={FWDEmail}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={frdasEamilList}
                clickMenuItem={(obj, close) => {
                  setFrdasEmail(obj?.value);
                  close();
                }}
              />
            </div>
          }
          filterSection1={[
            <SearchInput setQuery={setQuery} />,
            <CustomSelectFilter
              border={true}
              placeholder="Status"
              items={propertiesStatus}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Managed By"
              items={manageByList}
              onChange={(e) => setManageBy(e.target.value)}
              value={manageBy}
              defaultValue="managed_by"
            />,
            <Tooltip title="More Filters" arrow>
              <div
                className={styles.clickable}
                onClick={onHandleClick}
                style={{ cursor: "pointer" }}>
                <img
                  src={
                    isFilterActive
                      ? DotFilterIcon
                      : showFilter
                      ? BluefilterIcon
                      : filterIcon
                  }
                  alt="alt"
                  layout="fixed"
                  width={45}
                  height={45}
                />
              </div>
            </Tooltip>,

            showFilter && (
              <>
                <MoreFilterContainer
                  open={showFilter}
                  checked={checked}
                  handleClose={onHandleClick}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filters={filterState}
                />
              </>
            )
          ]}
          filterSection2={[
            <ManageColumn
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Forward as Email"
              items={frdasEamilList}
              value={FrdasEmail}
              onChange={(e) => setFrdasEmail(e.target.value)}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Export"
              items={ExportList}
              value={Export}
              onChange={(e) => setExport(e.target.value)}
            />
          ]}
        />
        <Hidden smUp>
          <Grid item xs={12}>
            <ManageColumn
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />
          </Grid>
        </Hidden>

        <ChipsComponent />
        <NewTable
          retaintiontrial
          loading={loading}
          Header={[
            ...THeaders,
            (permissions?.is_update || permissions?.is_delete) && {
              id: "Action",
              name: "Action"
            }
          ]}
          tableData={Tabledatalist}
          tooltipData={getTooltipData}
          pageSize={pageSize}
          changePage={changePage}
          changePageSize={changePageSize}
          count={count}
          pageResetValue={pageResetValue}
          labelRowsPerPage="Total No Of Properties Per Page"
          Illustration={
            <Illustrations
              src={noInvestors}
              heading="No properties Found"
              subHeading="Add Property Data To View"
            />
          }
        />
      </div>
    </>
  );
}

export default AppTable;
