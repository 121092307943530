import React, { Fragment, useEffect, useState } from "react";
import styles from "../distributionTable.module.css";
import { Menu, Button, MenuItem, Tooltip } from "@material-ui/core";
import { AppDispatcher } from "../../../../../redux/index";
import filterIcon from "assets/icons/Filter.svg";
import SearchInput from "components/Search";
// import Loader from "components/Loader/AppLoader";
// import formatCurrency from "helpers/formatCurrency";
// import MoreFilters from "components/Modals/InvestmentFilterModal";
import CloseIcon from "../../../../../assets/icons/cross.svg";
import { DistributionService } from "network/distributionService";
import { useHistory } from "react-router-dom";
// import SelectWithSearch from "components/StyledComponents/SelectWithSearch";
import { InvestmentListService } from "network/investment_ListService";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Toast from "components/toast";
import downloadIcon from "../../../../../assets/icons/Download.svg";
// import { dateFormatObj } from "helpers/formatDate";
import Illustrations from "components/illustration/Illustrations";
// import noDistribution from "../../../../../assets/emptyState/noDistribution.svg";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import MoreFilterContainer from "components/MoreFilterContainer/MoreFilterContainer";
// import ManageColumn from "components/ManageColumns";
import NewTable from "components/NewTable/NewTable";
import CSV from "../../../../../assets/icons/NewCardSvg/CSV.svg";
import FWDEmail from "../../../../../assets/icons/NewCardSvg/FWDEmail.svg";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import noInvestments from "../../../../../assets/emptyState/noDistribution.svg";
import { FilterStatus, truncate } from "helpers/Common";
import LightTooltip from "components/LightTooltip";
import ManageColumn from "components/ManageColumns";
import { numFormatter } from "helpers";
import formatDate from "helpers/formatDate";
// import NewDropDown from "components/NewDropDown";
import BluefilterIcon from "assets/icons/blueFilter.svg";
import DotFilterIcon from "assets/icons/DotFilter.svg";
import DotsMenu from "assets/icons/dotsMenu.svg";
import FrwrdAsEmail from "components/ForwarsAsEmail";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
import { saveAs } from "file-saver";

// import { maskedDateFormatter } from "@material-ui/pickers/_helpers/text-field-helper";

const manageByList = [
  {
    label: "Managed By",
    value: "managedby"
  },

  {
    label: "Admin",
    value: "ADMIN"
  },
  {
    label: "Investor",
    value: "INVESTOR"
  }
];

const addedByList = [
  {
    label: "Added By",
    value: "addedby"
  },

  {
    label: "Admin",
    value: "ADMIN"
  },
  {
    label: "Investor",
    value: "INVESTOR"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "Pdf",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const TableHeaders = [
  {
    id: "investment_name",
    name: "Investment Name"
  },
  {
    id: "managed_by",
    name: "Managed By"
  },
  {
    id: "released_date",
    name: "Release Date"
  },
  {
    id: "amount_released",
    name: "Amount Released"
  },
  {
    id: "year",
    name: "Year"
  },
  {
    id: "tenure",
    name: "Tenure"
  },
  {
    id: "type",
    name: "Type"
  },
  {
    id: "investor_name",
    name: "Investor Name"
  },
  {
    id: "mode",
    name: "Mode"
  },
  {
    id: "added_by",
    name: "Added By"
  },
  {
    id: "documents",
    name: "Download Proof"
  }
];

function Table({ permissions }) {
  const [THeaders, setTHeaders] = useState(TableHeaders);
  const [manageBy, setManageBy] = useState("managedby");
  const [addedBy, setAddedBy] = useState("addedby");
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [Export, setExport] = useState("exportList");
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [isFilterActive, setFilterActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);

  const [dropDownOpRes, setDropDownOpRes] = useState(null);

  const open = Boolean(anchorEl);
  const [checked, setChecked] = useState([
    { key: "Investment Name", value: [] },
    { key: "Investor Name", value: [] },
    { key: "Distribution Type", value: [] }
  ]);
  const [filterState, setFilterState] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  // const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageResetValue, setPageResetValue] = useState(0);

  const mappingObject = {
    "Investment Name": "investment_id",
    "Investor Name": "investor_id",
    "Distribution Type": "type",
    Tenure: "tenure",
    Year: "year",
    "Investment Status": "investment_status"
  };

  const handleApply = (data) => {
    setShowFilter(false);
    setChecked([...data]);
  };

  // const handleDeleteInvestment = () => {
  //   history.push("/u/investments/deleteInvestment");
  // };

  // const handleChangeStatus = () => {
  //   AppDispatcher.setInvestmentID(id);
  //   history.push("/u/investments/changeStatus");
  // };

  const handleEditDistribution = () => {
    history.push("/u/distributions/editDistribution");
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const getDistributionData = async (pageNum, isAppend = false) => {
    let url = "";
    if (searchQuery.length > 0) {
      url += `${url.length ? "&" : "?"}search=${searchQuery}`;
    }
    if (manageBy !== "managedby") {
      url += `${url.length ? "&" : "?"}managed_by=${manageBy}`;
    }
    if (addedBy !== "addedby") {
      url += `${url.length ? "&" : "?"}added_by=${addedBy}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `${url.length ? "&" : "?"}${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (url.length > 0) {
      url += `&page=${pageNum}&page_size=${pageSize}`;
    } else {
      url += `?page=${pageNum}&page_size=${pageSize}`;
    }
    const response = await DistributionService.getDistributions([url]);
    if (response.success) {
      setLoading(false);
      if (isAppend) {
        setData([...data, ...response.data.results]);
      } else {
        setData(response.data.results);
      }
      setCount(response.data.count);
    }
    // setLoading(false);
  };

  const getYears = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;
    if (searchText) {
      params += `&search=${searchText}`;
    }
    const response = await DistributionService.getYears([params]);
    if (response.success) {
      return {
        data: response.data.results?.map((el) => ({
          id: el.year,
          name: el.year
        })),
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const handleClear = () => {
    setShowFilter(false);
    setChecked([
      { key: "Investment Name", value: [] },
      { key: "Investor Name", value: [] },
      { key: "Distribution Type", value: [] }
    ]);
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    AppDispatcher.setDistributionId(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleClick = () => {
    setShowFilter(!showFilter);
  };

  const ChipsComponent = () => {
    let newArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        newArray.push(newObj);
      });
    });

    return (
      <>
        <div className={styles.chipsCtr}>
          {newArray.length > 0
            ? newArray.map((item, index) => {
                return (
                  <div key={index} className={styles.chips}>
                    <div className={styles.chipsText}>{item.name}</div>
                    <div onClick={() => onDeleteClick(item.key, item.id)}>
                      <img
                        className={styles.chipsImg}
                        src={CloseIcon}
                        alt="cross"
                      />
                    </div>
                  </div>
                );
              })
            : null}
        </div>
      </>
    );
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
  };

  // const setSelectedInvestments = (item) => {
  //   let selectedItems = [...checked];
  //   let index = selectedItems?.[0]?.value?.findIndex((el) => el.id === item.id);
  //   if (index > -1) {
  //     selectedItems[0].value.splice(index, 1);
  //   } else {
  //     selectedItems[0].value.push(item);
  //   }
  //   setChecked(selectedItems);
  // };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestmentName([
      `?inv_drop_value=false&fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const saveFile = (link, name) => {
    saveAs(link, name);
  };

  const getInvestorName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestors([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setDropDownOpRes(res?.data);

      let newFilterState = [
        {
          key: "Distribution Type",
          isStaticData: true,
          value: res?.data?.DISTRIBUTION_TYPE_CHOICES?.map((el) => ({
            id: el.value,
            name: el.label
          }))
        },
        {
          key: "Tenure",
          isStaticData: true,
          value:
            res.data &&
            [...res.data.QUARTER_CHOICES, ...res.data.MONTH_CHOICES].map(
              (el) => ({
                id: el.value,
                name: el.label
              })
            )
        },
        {
          key: "Investment Status",
          hasSearchBar: false,
          isStaticData: true,
          value: res?.data?.INVESTMENT_STATUS?.map((el) => ({
            id: el.value,
            name: el.label
          }))
        }
      ];
      setFilterState((prev) => [...prev, ...newFilterState]);
    } else {
      Toast.showErrorToast("Failed To Load Distribution Types");
    }
  };

  let setLoanType = {};

  dropDownOpRes?.DISTRIBUTION_TYPE_CHOICES.map((item) => {
    setLoanType[item?.value] = item?.label;
  });
  dropDownOpRes?.PROOF_CHOICE.map((item) => {
    setLoanType[item?.value] = item?.label;
  });
  dropDownOpRes?.CADENCE_CHOICES.map((item) => {
    setLoanType[item?.value] = item?.label;
  });

  // const getInvestorFullname = (name) => {
  //   let fullName = "";
  //   fullName += name?.first_name ? name?.first_name + " " : "";
  //   fullName += name?.last_name || "";
  //   return fullName || "-";
  // };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getDistributionData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const handleCellItemClick = (e, type, id) => {
    e.stopPropagation();
    if (id) {
      switch (type) {
        case "investor":
          AppDispatcher.setInvestorDetailID(id);
          history.push("/u/investors/detail");
          break;
        case "investment":
          AppDispatcher.setInvestmentDetailID(id);
          history.push("/u/investments/detail");
          break;
      }
    }
  };
  // const getQueryParams = () => {
  //   let url = "";
  //   if (searchQuery.length > 0) {
  //     url += `${url.length ? "&" : "?"}search=${searchQuery}`;
  //   }
  //   if (checked.length > 0) {
  //     checked.forEach((item) => {
  //       if (item.value.length > 0) {
  //         url += `${url.length ? "&" : "?"}${mappingObject[item.key]}=`;
  //         item.value.forEach((el) => {
  //           url += `${el.id},`;
  //         });
  //         url = url.substring(0, url.length - 1);
  //       }
  //     });
  //   }
  //   return url;
  // };
  // const downloadCsv = async () => {
  //   setLoading(true);
  //   let url = getQueryParams();
  //   if (url.length > 0) {
  //     url += `&csv-download=true`;
  //   } else {
  //     url += `?csv-download=true`;
  //   }
  //   const resp = await DistributionService.getDistributions([url]);
  //   if (resp.success) {
  //     const link = document.createElement("a");
  //     link.href = resp?.data?.url;
  //     link.setAttribute("download", `${resp?.data?.url}`);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   } else {
  //     Toast.showErrorToast("Failed To Download CSV");
  //   }
  //   setLoading(false);
  // };

  // const download = (e) => {
  //   // setLoading(true);
  //   fetch(e, {
  //     method: "GET"
  //   })
  //     .then((response) => {
  //       let fileName = e.split("/").pop();
  //       response.arrayBuffer().then(function (buffer) {
  //         const url = window.URL.createObjectURL(new Blob([buffer]));
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.setAttribute("download", fileName); //or any other extension
  //         document.body.appendChild(link);
  //         link.click();
  //       });
  //       // setLoading(false);
  //     })
  //     .catch(() => {
  //       // setLoading(false);
  //     });
  // };

  const setFilterMeta = () => {
    setFilterState([
      {
        key: "Investor Name",
        value: getInvestorName
      },
      {
        key: "Investment Name",
        value: getInvestmentName
      },
      {
        key: "Year",
        value: getYears
      }
    ]);
  };

  useEffect(() => {
    setFilterMeta();
    getOptions();
  }, []);

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getDistributionData(1);
    setFilterActive(FilterStatus(checked));
  }, [searchQuery, checked, pageSize, count, manageBy, addedBy]);

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = "";

    if (emailData) {
      if (preview === "preview") {
        url += `?${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `?${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `?${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    if (searchQuery.length > 0) {
      url += `${url.length ? "&" : "?"}search=${searchQuery}`;
    }
    if (manageBy !== "managedby") {
      url += `${url.length ? "&" : "?"}managed_by=${manageBy}`;
    }
    if (addedBy !== "addedby") {
      url += `${url.length ? "&" : "?"}added_by=${addedBy}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `${url.length ? "&" : "?"}${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (url.length > 0) {
      url += `&page=${page}&page_size=${pageSize}`;
    } else {
      url += `?page=${page}&page_size=${pageSize}`;
    }

    setExport("exportList");
    const response = await DistributionService.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          // link.target = "_blank";
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  /******************************************************************** */
  /*********************       New Table Data           *****************/
  /*********************************************************************/

  const getTooltipData = (item) => {
    const mainDiv = {
      display: "flex",
      // gap: "20px",
      padding: "12px",
      width: "fit-content"
    };
    const heading = {
      fontSize: "16px",
      fontWeight: 400,
      color: "#A0A0A0",
      minWidth: "180px"
    };
    const Value = {
      fontSize: "16px",
      fontWeight: 600,
      color: "#191919",
      marginleft: "30px"
    };
    return (
      <>
        <div style={mainDiv}>
          <h3 style={heading}>Distribution ID:</h3>
          <h3 style={Value}>
            {item?.distribution?.ditribution_base_id ?? "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Investement Status:</h3>
          <div
            className={styles.status}
            style={{
              backgroundColor:
                item?.investment?.investment_status === "Draft"
                  ? "#EBEFFF"
                  : item?.investment?.investment_status == "Active"
                  ? "#EDFFEF"
                  : "#FCEBEB ",
              color:
                item?.investment?.investment_status == "Active"
                  ? "#50E138"
                  : item?.investment?.investment_status === "Draft"
                  ? "#445EBE"
                  : "#E13838"
            }}>
            {item?.investment?.investment_status}
          </div>
        </div>
      </>
    );
  };
  const Tabledatalist =
    data &&
    data.map((item) => {
      return [
        <LightTooltip key="investment_name" title={getTooltipData(item)}>
          <div
            className={`${styles.text} ${styles.hover}`}
            style={{ cursor: "pointer" }}
            onClick={(e) =>
              handleCellItemClick(
                e,
                "investment",
                item?.investment?.investment_id
              )
            }>
            {truncate(
              item?.investment?.investment_name
                ? item?.investment?.investment_name
                : "-",
              20
            )}
          </div>
        </LightTooltip>,
        <LightTooltip key="managed_by" title={getTooltipData(item)}>
          <div className={styles.text}>{item?.investment?.managed_by}</div>
        </LightTooltip>,
        <LightTooltip key="released_date" title={getTooltipData(item)}>
          <div className={styles.text} style={{ cursor: "pointer" }}>
            {item?.distribution?.released_date
              ? formatDate(item?.distribution?.released_date)
              : "-"}
          </div>
        </LightTooltip>,
        <LightTooltip key="amount_released" title={getTooltipData(item)}>
          <div className={styles.text}>
            {item.amount !== null ? "$" + numFormatter(item.amount) : "-"}
          </div>
        </LightTooltip>,
        <LightTooltip key="year" title={getTooltipData(item)}>
          <div>{item.distribution.year}</div>
        </LightTooltip>,
        <LightTooltip key="tenure" title={getTooltipData(item)}>
          <div className={styles.text}>
            {setLoanType[item?.distribution?.tenure] || "-"}
          </div>
        </LightTooltip>,
        <LightTooltip key="type" title={getTooltipData(item)}>
          <div className={styles.text}>
            {setLoanType[item.distribution.distribution_type] || "-"}
          </div>
        </LightTooltip>,
        <LightTooltip key="investor_name" title={getTooltipData(item)}>
          <div
            onClick={(e) =>
              handleCellItemClick(e, "investor", item?.investor?.investor_id)
            }
            title={item?.investor?.investor_name}
            className={styles.text}
            style={{ cursor: "pointer" }}>
            {truncate(item?.investor?.investor_name, 20)}
          </div>
        </LightTooltip>,
        <LightTooltip key="mode" title={getTooltipData(item)}>
          <div className={styles.text}>{setLoanType[item?.type] || "-"}</div>
        </LightTooltip>,
        <LightTooltip key="added_by" title={getTooltipData(item)}>
          <div className={styles.text}>{item?.added_by || "-"}</div>
        </LightTooltip>,
        <Tooltip title="Download" key="documents">
          <div
            className={styles.text}
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer"
            }}>
            <div>
              {item?.documents?.length ? (
                <a
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    saveFile(
                      item?.documents[0]?.document_media?.file,
                      item?.documents[0]?.document_media?.name
                    )
                  }>
                  <img src={downloadIcon} alt="alt" height="40px" />
                </a>
              ) : (
                <div>-</div>
              )}
            </div>
          </div>
        </Tooltip>,
        // <LightTooltip key="amount_released" title={getTooltipData(item)}>
        //   <div
        //     key="status"
        //     style={{
        //       minHeight: 28,
        //       width: 72,
        //       alignItems: "center",
        //       backgroundColor:
        //         item?.status === "Draft"
        //           ? "#EBEFFF"
        //           : item?.status == "Active"
        //           ? "#EDFFEF"
        //           : "#FCEBEB ",
        //       borderRadius: 153,
        //       textAlign: "center",
        //       fontFamily: "Inter Bold",
        //       fontSize: 14,
        //       paddingTop: 4,
        //       color:
        //         item?.status == "Active"
        //           ? "#50E138"
        //           : item?.status === "Draft"
        //           ? "#445EBE"
        //           : "#E13838",
        //       fontWeight: 700
        //     }}>
        //     {item?.status ? item?.status : "-"}
        //   </div>
        // </LightTooltip>,
        (permissions?.is_update || permissions?.is_delete) && (
          <div key="Action">
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) =>
                handleClick(e, item?.distribution?.distribution_id)
              }>
              <img src={DotsMenu} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  marginTop: 40,
                  boxShadow: "2px 2px 4px #E9E9E9"
                }
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}>
              {permissions?.is_update ? (
                <MenuItem
                  onClick={handleEditDistribution}
                  className={styles.menuItem}>
                  Edit
                </MenuItem>
              ) : null}
              {permissions?.is_delete ? (
                <MenuItem
                  onClick={() =>
                    history.push("/u/distributions/deleteDistribution")
                  }
                  className={styles.menuItemDelete}>
                  Delete
                </MenuItem>
              ) : null}
            </Menu>
          </div>
        )
      ];
    });

  /******************************************************************* */
  /******************************************************************* */
  /******************************************************************* */

  return (
    <>
      {/* {showFilter && (
        <MoreFilters
          open={showFilter}
          checked={checked}
          handleClose={onHandleClick}
          filters={filterState}
          handleApply={handleApply}
          handleClear={handleClear}
        />
      )} */}

      <FrwrdAsEmail
        AllHeaders={TableHeaders}
        FrdasEmail={FrdasEmail}
        setFrdasEmail={setFrdasEmail}
        open={frdEmailOpen}
        setOpen={setFrdasEmailOpen}
        clickOnShare={(payload) => {
          getExport_PDF_CSV_Link(payload);
        }}
        clickOnPreview={(payload) => {
          getExport_PDF_CSV_Link(payload, "preview");
        }}
      />

      <div className={styles.tableCtrOuter}>
        <TableTopFilter
          tabletViewCSV={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={CSV}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={ExportList}
                clickMenuItem={(obj, close) => {
                  setExport(obj?.value);
                  close();
                }}
              />
            </div>
          }
          tabletViewFWDEmail={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={FWDEmail}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={frdasEamilList}
                clickMenuItem={(obj, close) => {
                  setFrdasEmail(obj?.value);
                  close();
                }}
              />
            </div>
          }
          filterSection1={[
            <SearchInput setQuery={setQuery} />,

            <CustomSelectFilter
              key="3"
              border={true}
              placeholder="Managed By"
              items={manageByList}
              value={manageBy}
              onChange={(e) => setManageBy(e.target.value)}
            />,
            <CustomSelectFilter
              key="4"
              border={true}
              placeholder="Added By"
              items={addedByList}
              value={addedBy}
              onChange={(e) => setAddedBy(e.target.value)}
            />,
            <Tooltip title="More Filters" arrow>
              <div className={styles.clickable} onClick={onHandleClick}>
                <img
                  src={
                    isFilterActive
                      ? DotFilterIcon
                      : showFilter
                      ? BluefilterIcon
                      : filterIcon
                  }
                  alt="alt"
                  layout="fixed"
                  width={45}
                  height={45}
                />
              </div>
            </Tooltip>,

            showFilter && (
              <>
                <MoreFilterContainer
                  open={showFilter}
                  checked={checked}
                  handleClose={onHandleClick}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filters={filterState}
                />
              </>
            )
          ]}
          filterSection2={[
            <ManageColumn
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Forward as Email"
              items={frdasEamilList}
              value={FrdasEmail}
              onChange={(e) => setFrdasEmail(e.target.value)}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Export"
              items={ExportList}
              value={Export}
              onChange={(e) => setExport(e.target.value)}
            />
          ]}
        />

        <ChipsComponent />

        <NewTable
          loading={loading}
          Header={[
            ...THeaders,
            (permissions?.is_update || permissions?.is_delete) && {
              id: "Action",
              name: "Action"
            }
          ]}
          tableData={Tabledatalist}
          pageSize={pageSize}
          changePage={changePage}
          changePageSize={changePageSize}
          count={count}
          pageResetValue={pageResetValue}
          labelRowsPerPage="Total No Of Distributions Per Page"
          Illustration={
            <Illustrations
              src={noInvestments}
              heading="No Distributions"
              subHeading="Invest in deals to receive Distributions."
            />
          }
        />

        {/* Old code */}

        {/* <div className={styles.searhCtr}>
          <div className={styles.leftCtr}>
            <SearchInput setQuery={setQuery} width="358px" />
            <Tooltip title="More Filters" arrow>
              <div style={{ cursor: "pointer" }} onClick={onHandleClick}>
                <img src={filterIcon} alt="alt" />
              </div>
            </Tooltip>
            <div style={{ marginLeft: "8px" }}>
              <SelectWithSearch
                title="Investments"
                getData={getInvestmentName}
                selectedItems={checked && checked[0]?.value}
                onChange={setSelectedInvestments}
              />
            </div>
            <Tooltip title="Download CSV" arrow>
              <div
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={downloadCsv}>
                <img src={downloadIcon} alt="alt" />
              </div>
            </Tooltip>
          </div>
          {permissions?.is_create ? (
            <div className={styles.addInvestmentButtonContainer}>
              <p
                className={styles.addInvestmentButtonText}
                onClick={() =>
                  history.push("/u/distributions/addDistribution")
                }>
                Add Distribution
              </p>
            </div>
          ) : null}
        </div> */}
        {/* <div className={styles.tableContainer}>
          {data.length ? (
            <ThemeProvider theme={theme}>
              <MUIDataTable
                data={
                  data &&
                  data.map((item) => {
                    return {
                      investment_name: (
                        <div
                          className={styles.text}
                          onClick={(e) =>
                            handleCellItemClick(
                              e,
                              "investment",
                              item?.investment?.id
                            )
                          }>
                          {item?.investment?.name || "-"}
                        </div>
                      ),
                      released_date: (
                        <div className={styles.text}>
                          {dateFormatObj(item?.date) || "-"}
                        </div>
                      ),
                      amount_released: (
                        <div className={styles.text}>
                          {formatCurrency(
                            item?.investor_distribution_data[0]?.amount || "0"
                          )}
                        </div>
                      ),
                      year: (
                        <div className={styles.text}>{item?.year || "-"}</div>
                      ),
                      tenure: (
                        <div className={styles.text}>
                          {item?.cadence || "-"}
                        </div>
                      ),
                      distribution_type: (
                        <div className={styles.text}>{item?.type || "-"}</div>
                      ),
                      investor_name: (
                        <div
                          className={styles.text}
                          onClick={(e) =>
                            handleCellItemClick(
                              e,
                              "investor",
                              item?.investor_distribution_data[0]?.investor
                                ?.investor?.id
                            )
                          }>
                          {getInvestorFullname(
                            item?.investor_distribution_data[0]?.investor
                              ?.investor
                          )}
                        </div>
                      ),

                      mode: (
                        <div className={styles.text}>
                          {item?.investor_distribution_data[0]?.documents[0]
                            ?.category?.name || "-"}
                        </div>
                      ),

                      investment_status: (
                        <div
                          className={styles.status}
                          style={{
                            backgroundColor:
                              item?.investment_status === "Draft"
                                ? "#EBEFFF"
                                : item?.investment_status == "Active"
                                ? "#EDFFEF"
                                : "#FCEBEB ",
                            color:
                              item?.investment_status == "Active"
                                ? "#50E138"
                                : item?.investment_status === "Draft"
                                ? "#445EBE"
                                : "#E13838"
                          }}>
                          {item?.investment_status}
                        </div>
                      ),
                      distribution_id: (
                        <div className={styles.text}>
                          {item?.base_id || "-"}
                        </div>
                      ),
                      documents: (
                        <div>
                          {item?.investor_distribution_data[0]?.documents
                            ?.length ? (
                            <a
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() =>
                                download(
                                  item?.investor_distribution_data[0]
                                    ?.documents[0].document_media?.file
                                )
                              }>
                              <img src={downloadIcon} alt="alt" />
                            </a>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      ),
                      ...(!permissions?.is_update && !permissions?.is_delete
                        ? {}
                        : {
                            Action: (
                              <div>
                                <Button
                                  id="basic-button"
                                  aria-controls="basic-menu"
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => handleClick(e, item.id)}>
                                  <img src={DotsMenu} />
                                </Button>
                                <Menu
                                  id="basic-menu"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  PaperProps={{
                                    style: {
                                      marginTop: 40,
                                      boxShadow: "2px 2px 4px #E9E9E9",
                                      minWidth: 155
                                    }
                                  }}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button"
                                  }}>
                                  {permissions?.is_update ? (
                                    <MenuItem
                                      onClick={handleEditDistribution}
                                      className={styles.menuItem}>
                                      Edit
                                    </MenuItem>
                                  ) : null}
                                  {permissions?.is_delete ? (
                                    <MenuItem
                                      onClick={() =>
                                        history.push(
                                          "/u/distributions/deleteDistribution"
                                        )
                                      }
                                      className={styles.menuItemDelete}>
                                      Delete
                                    </MenuItem>
                                  ) : null}
                                </Menu>
                              </div>
                            )
                          })
                    };
                  })
                }
                columns={columns}
                options={options}
                title={<ChipsComponent />}
              />
            </ThemeProvider>
          ) : (
            <div>
              <ChipsComponent />
              <Illustrations
                src={noDistribution}
                heading="No Distribution Found"
                subHeading="Contact Admin For Details"
              />
            </div>
          )}
        </div> */}
      </div>
    </>
  );
}
export default Table;
