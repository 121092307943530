import { API, NetworkManager } from "./core";

export class InvestorServices {
  static async deleteInvestorDocs(id) {
    const instance = new NetworkManager(API.INVESTOR.DELETE_DOCS, {}, [
      `${id}/`
    ]);
    return await instance.httpRequest();
  }

  static async get_Pdf_Or_Csv(params) {
    const instance = new NetworkManager(
      API.INVESTOR.EXPORT_PDF_CSV,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getInvestorsList(params) {
    const instance = new NetworkManager(API.INVESTOR.INVESTORS_FILTER, {}, [
      params
    ]);
    return await instance.httpRequest();
  }

  static async getInvestorStats() {
    const instance = new NetworkManager(API.INVESTOR.INVESTOR_STATS);
    return await instance.httpRequest();
  }

  static async deleteInvestor(params) {
    const instance = new NetworkManager(
      API.INVESTOR.DELETE_INVESTOR,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
