import React from "react";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    // backgroundColor: "#FFFFFF",
    borderRadius: 8
  },
  heading: {
    width: "105px",
    height: "22px",
    fontFamily: "Inter Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    marginBottom: "20px"
  },
  child: {
    padding: 20,
    background: "#FFFFFF",
    boxShadow: "0px 4px 15px rgba(160, 160, 160, 0.15)",
    borderRadius: "10px"
  }
}));

const ContentContainer = ({
  children,
  heading,
  width,
  direction = "column",
  spacing = "0"
}) => {
  const classes = useStyles();
  return (
    <Grid
      item
      container
      lg={width || 12}
      sm={12}
      className={classes.container}
      spacing={spacing}
      direction={direction}>
      {heading ? <div className={classes.heading}>{heading}</div> : null}
      <div className={classes.child}>{children}</div>
    </Grid>
  );
};

export default ContentContainer;
