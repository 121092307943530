import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  makeStyles,
  Menu,
  MenuItem
} from "@material-ui/core";
import SearchInput from "components/Search";
import ExpandIcon from "../../assets/icons/expandIcon.svg";
import CollapseIcon from "../../assets/icons/collapseIcon.svg";
import CheckCheckBox from "assets/icons/CheckCheckBox.svg";
import UnCheckCheckBox from "assets/icons/UnCheckCheckbox.svg";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: "13px 20px",
    borderRadius: 16,
    border: "1px solid #EBEFFF",
    cursor: "pointer",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400
  },
  mainMenu: {
    width: 315,
    maxHeight: 257,
    boxSizing: "border-box",
    border: "1px solid #EBEFFF",
    borderRadius: 16,
    backgroundColor: "#FFFFFF"
  },
  searchCtr: {
    width: "100%",
    padding: "20px 20px 0",
    "& div:first-child": {
      width: "100% !important"
    }
  },
  expand: {
    marginLeft: 15
  },
  listContainer: {
    width: "100%",
    marginTop: 5,
    maxHeight: 160,
    boxSizing: "border-box",
    overflowY: "scroll"
  },
  itemContainer: {
    padding: "5px 15px 2px 20px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: "#F5F7FB"
    }
  },
  itemText: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  loaderCtr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 0"
  }
}));

const SelectWithSearch = ({
  title = "",
  getData = () => {
    [];
  },
  selectedItems = [],
  onChange = () => {}
}) => {
  const classes = useStyles(title);
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [query, setQuery] = useState("");
  const isLoadingRef = useRef(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsExpanded(!isExpanded);
  };

  const handleSelect = (item) => {
    onChange(item);
  };

  const loadMore = async () => {
    isLoadingRef.current = true;
    const resp = await getData(page + 1, query);
    setMaxPage(resp.maxPage || 1);
    setPage((prev) => prev + 1);
    setData((prev) => [...prev, ...resp.data]);
    isLoadingRef.current = false;
  };

  const handleScroll = (e) => {
    if (isLoadingRef.current) {
      return;
    }
    if (
      page < maxPage &&
      e.target.scrollHeight - e.target.scrollTop - 25 <= e.target.clientHeight
    ) {
      loadMore();
    }
  };

  useEffect(async () => {
    setPage(1);
    const resp = await getData(1, query);
    setMaxPage(resp.maxPage);
    setData(resp.data);
  }, [query]);

  return (
    <>
      <div className={classes.container} onClick={handleClick}>
        <span>{title}</span>
        <div className={classes.expand}>
          <img
            src={isExpanded ? CollapseIcon : ExpandIcon}
            alt="Expand/Collapse"
          />
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}>
        <div className={classes.mainMenu}>
          {title === "Primary" || title === "Access Role" ? null : (
            <div className={classes.searchCtr}>
              <SearchInput setQuery={setQuery} width="275px" />
            </div>
          )}
          <div className={classes.listContainer} onScroll={handleScroll}>
            {data.map((item) => (
              <MenuItem
                key={item?.id}
                style={{ padding: 0 }}
                onClick={() => handleSelect(item)}>
                <div className={classes.itemContainer}>
                  <div className={classes.itemText}>{item.name}</div>
                  <img
                    className={classes.checkbox}
                    src={
                      selectedItems.find((el) => el.id === item.id)
                        ? CheckCheckBox
                        : UnCheckCheckBox
                    }
                  />
                </div>
              </MenuItem>
            ))}
            {page < maxPage ? (
              <div className={classes.loaderCtr}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  classes={{
                    circle: classes.circle
                  }}
                  size={30}
                  thickness={4}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Menu>
    </>
  );
};

export default SelectWithSearch;
