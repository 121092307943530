import React, { useState, useEffect } from "react";
import ProfileIcon from "../../assets/icons/defaultProfilePic.svg";
import NotificationIcon from "../../assets/icons/NotificationIcon.svg";
import MenuIconNew from "../../assets/icons/MenuIconNew.svg";
import { Grid, Typography, makeStyles, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import { API, NetworkManager, Endpoint } from "../../network/core/index";
import { AppDispatcher } from "../../redux/index";
import BackBtn from "../../assets/icons/Back.svg";
import Styles from "./HeaderComponent.module.css";
import { useSelector } from "react-redux";
import EditIconBlue from "../../assets/icons/edit.svg";
import Toast from "components/toast";

const useHeaderStyle = makeStyles(() => ({
  heading: {
    fontFamily: "Inter Bold",
    fontWeight: 800,
    fontSize: 36,
    height: 50,
    color: "#5775E6",
    textTransform: "capitalize"
  },
  extrabutton: {
    "@media (max-width: 817px)": {
      marginRight: "5vw"
    }
  },
  headingMedia: {
    "@media (min-width: 1080px)": {
      display: "none"
    }
  },
  addInvestmentheadingMedia: {
    fontFamily: "Inter Bold",
    fontWeight: 800,
    fontSize: 36,
    height: 46,
    color: "#5775E6",
    marginTop: 10,
    "@media (min-width: 1023px)": {
      display: "none"
    },
    "@media (max-width: 828px)": {
      lineHeight: "34px"
    }
  },
  title: {
    color: "#616161",
    fontFamily: "Inter Regular",
    fontSize: 18,
    marginTop: 10,
    fontWeight: 400,
    lineHeight: "25px"
  },
  headingGrid: {
    height: 70,
    display: "flex",
    flexDirection: "row"
  },
  profileGrid: {
    flexDirection: "row",
    display: "flex",
    marginTop: 20,
    alignItems: "center"
  },
  backBtn: {
    marginTop: "-10px",
    marginRight: "0px",
    cursor: "pointer",
    "@media (min-width: 1170px)": {
      marginRight: "-20px"
    }
  },
  propertyStatus: {
    fontFamily: "Inter Regular",
    fontWeight: 700,
    fontSize: 14,
    color: "#55A55E",
    padding: "4px 14px",
    borderRadius: 153,
    backgroundColor: "#EDFFEF",
    marginLeft: 10,
    marginBottom: 4,
    alignSelf: "center"
  },
  inv_status: {
    width: "fit-content",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: " 4px 14px",
    borderRadius: "153px",
    fontFamily: "Inter Regular",
    fontWeight: 600,
    fontSize: 14,
    marginLeft: 20,
    lineHeight: "17px",
    cursor: "pointer"
  }
}));

const HeaderComponent = ({
  backBtn,
  heading,
  description,
  investment_Status = "",
  managedBy,
  addedVia,
  ExtraButtons,
  entity_type = "",
  isEditInvestorBtn = false,
  onEditInvestmentBtnClick
}) => {
  const classes = useHeaderStyle();
  const history = useHistory();
  const [dpage, setDpage] = useState(false);
  const [record, setRecord] = useState({
    profileImage: null
  });
  const { user, drawer } = useSelector((store) => store.app);

  const fetchAdminDetails = async () => {
    const instance = new NetworkManager(
      new Endpoint(`admin/profile/${user.id}/`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      let temp = resp.data;
      let tempData = { ...record };
      tempData["profileImage"] =
        temp.profile_image === null
          ? temp.profile_image
          : temp.profile_image.file;
      setRecord(tempData);
      AppDispatcher.setAdminEmailId(temp?.email);
    }
  };

  const fetchSubAdminDetails = async () => {
    const instance = new NetworkManager(
      new Endpoint(`subadmin/sub-admin/${user.id}/`, "GET")
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      let temp = resp?.data?.user;
      let tempData = { ...record };
      tempData["profileImage"] =
        temp?.profile_image === null
          ? temp?.profile_image
          : temp?.profile_image?.file;
      setRecord(tempData);
    }
  };

  const route = () => {
    switch (history.location.pathname) {
      case "/u/investments/detail":
        return setDpage(true);
      case "/u/investors/detail":
        return setDpage(true);
      case "/u/sponsors/detail":
        return setDpage(true);
      case "/u/properties/detail":
        return setDpage(true);
      case "/u/investors/addinvestors":
        return setDpage(true);
      case "/u/distributions/addDistribution":
        return setDpage(true);
      case "/u/distributions/editDistribution":
        return setDpage(true);
      case "/u/sponsors/addSponsor":
        return setDpage(true);
      case "/u/contacts/addcontacts":
        return setDpage(true);
      case "/u/contacts/editcontacts":
        return setDpage(true);
      case "/u/entities/addEntity":
        return setDpage(true);
      case "/u/roles/addRole":
        return setDpage(true);
      case "/u/roles/editRole":
        return setDpage(true);
      case "/u/investments/addInvestment":
        return setDpage(true);
      case "/u/entities/detail":
        return setDpage(true);
      case "/u/sub_admin/addSubAdmin":
        return setDpage(true);
      default:
        return setDpage(false);
    }
  };

  useEffect(() => {
    if (user.role === "ADMIN") {
      fetchAdminDetails();
    } else {
      fetchSubAdminDetails();
    }
    route();
  }, []);

  /* Pop Over Drop down */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMyProfile = () => {
    history.push("/u/editprofile");
  };

  const handleLogout = async () => {
    try {
      const instance = new NetworkManager(API.AUTH.LOGOUT);
      const resp = await instance.httpRequest();
      if (resp.success) {
        AppDispatcher.setUserLoggedOut();
      } else {
        Toast.showErrorToast("Logout Unsuccessfull");
      }
    } catch {
      Toast.showErrorToast("Something Went Wrong");
    }
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid container alignItems="center" item xs={6}>
          {backBtn ? (
            <Grid xs={1} item className={classes.backBtn}>
              <img src={BackBtn} onClick={() => history.goBack()} alt="alt" />
            </Grid>
          ) : (
            <Grid item xs={1.5} className={classes.headingMedia}>
              {!dpage && (
                <IconButton
                  edge="start"
                  style={{ marginTop: "-12px" }}
                  onClick={() => AppDispatcher.setDraweropen(!drawer)}>
                  <img src={MenuIconNew} alt="menu" height={"24px"} />
                </IconButton>
              )}
            </Grid>
          )}

          <Grid item xs={8}>
            <Grid container wrap="nowrap" item xs={12} alignItems="baseline">
              <Grid
                item
                xs={investment_Status ? (heading?.length > 20 ? 8 : true) : 12}>
                <Typography
                  title={heading}
                  noWrap
                  variant="h4"
                  className={classes.heading}>
                  {heading}
                </Typography>
              </Grid>

              {investment_Status !== "" ? (
                <Grid container alignItems="center" item xs>
                  <div
                    style={{
                      textTransform: "capitalize",
                      background:
                        investment_Status === "Active" ||
                        investment_Status === "stabilized"
                          ? "#EDFFEF"
                          : investment_Status === "Pending"
                          ? "#EBEFFF"
                          : "#FCEBEB",
                      color:
                        investment_Status === "stabilized" ||
                        investment_Status === "Active"
                          ? "#50E138"
                          : investment_Status === "Pending"
                          ? "#445EBE"
                          : "#E13838"
                    }}
                    className={classes.inv_status}>
                    {investment_Status}
                  </div>
                </Grid>
              ) : null}
            </Grid>
            <Typography
              variant="h5"
              noWrap
              className={classes.title}
              title={description}>
              {description}
              {addedVia && (
                <div className={`${Styles.respodescr}`}>
                  {" "}
                  Added Via: <b>{addedVia}</b>
                </div>
              )}
              {managedBy && (
                <div className={`${Styles.respodescr}`}>
                  {" "}
                  Managed By: <b>{managedBy}</b>
                </div>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="flex-end"
          alignItems="center"
          wrap="nowrap"
          item
          xs={6}>
          {managedBy && (
            <Grid item className={Styles.managed_by}>
              <h4>
                Managed By: <span>{managedBy}</span>
              </h4>
            </Grid>
          )}

          {addedVia && (
            <Grid item className={Styles.managed_by}>
              <h4>
                Added Via: <span>{addedVia}</span>
              </h4>
            </Grid>
          )}

          {entity_type ? (
            <Grid item>
              <div
                style={{
                  height: 27,
                  width: 87,
                  marginRight: 20,
                  borderRadius: 153,
                  padding: "4px 14px",
                  color: "#55A55E",
                  fontFamily: "Inter SemiBold",
                  fontWeight: 600,
                  fontSize: 14,
                  backgroundColor: "#EDFFEF"
                }}>
                {entity_type === "INVESTOR" ? "Investor" : "Sponsor"}
              </div>
            </Grid>
          ) : null}

          {/* {page === "entitydetail" ? (
            <Grid item>
              <div
                onClick={() => handleEdit("entity")}
                style={{
                  width: 48,
                  height: 48,
                  background: "#5775E5",
                  borderRadius: 16,
                  marginRight: 20,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                <img
                  alt="alt"
                  src={EditIconBlue}
                  style={{
                    height: 29,
                    width: 18,
                    borderRadius: 51
                  }}
                />
              </div>
            </Grid>
          ) : null} */}

          {/* {page === "propertiesDetail" ? (
            <Grid item>
              <div
                onClick={() => handleEdit("property")}
                style={{
                  width: 48,
                  height: 48,
                  borderRadius: 16,
                  marginRight: 20,
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                <img
                  alt="alt"
                  src={EditIconBlue}
                  style={{
                    height: 29,
                    width: 18,
                    borderRadius: 50
                  }}
                />
              </div>
            </Grid>
          ) : null} */}

          {ExtraButtons && (
            <Grid
              item
              container
              justifyContent="center"
              xs={7}
              sm={6}
              lg={4}
              xl={3}
              className={classes.extrabutton}>
              {ExtraButtons}
            </Grid>
          )}

          {isEditInvestorBtn ? (
            <Grid item xs={2} sm={2} md={2} lg={1}>
              <img
                src={EditIconBlue}
                onClick={() => onEditInvestmentBtnClick()}
                className={Styles.editIconBlue}
              />
            </Grid>
          ) : null}

          <Grid container justifyContent="center" item xs={2} sm={1}>
            <img src={NotificationIcon} alt="alt" />
          </Grid>

          <Grid item xs={2} sm={1}>
            <div className={Styles.clickprofile}>
              <Button
                id="basic-button"
                aria-controls="basic-menu"
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}>
                {record.profileImage ? (
                  <img
                    alt="alt"
                    src={record.profileImage}
                    style={{
                      height: 48,
                      width: 48,
                      borderRadius: 50
                    }}
                  />
                ) : (
                  <img
                    alt="alt"
                    src={ProfileIcon}
                    style={{
                      height: 48,
                      width: 48,
                      borderRadius: 50
                    }}
                  />
                )}
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    marginTop: 50,
                    boxShadow: "10px 10px 10px #E9E9E9"
                  }
                }}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button"
                }}>
                <MenuItem
                  onClick={handleMyProfile}
                  style={{ fontFamily: "Inter Regular" }}>
                  My Profile
                </MenuItem>
                <MenuItem
                  onClick={handleLogout}
                  style={{ fontFamily: "Inter Regular", color: "red" }}>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default HeaderComponent;
