import React, { useEffect, useState } from "react";
import styles from "./NewTable.module.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { TablePagination } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";

const StyledTableCell = withStyles((theme) => ({
  root: { padding: "10px" },
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    borderBottom: "1px solid #616161"
  },
  body: {
    fontSize: 14,
    cursor: "default"
  }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: { verticalAlign: "top" }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700
  }
});

function NewTable({
  retaintiontrial = false,
  tableData,
  count,
  changePage,
  pageSize,
  changePageSize,
  pageResetValue,
  Header,
  labelRowsPerPage,
  Illustration,
  loading
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(pageResetValue);
  const [body, setBody] = useState(tableData);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    changePage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    changePageSize(parseInt(event.target.value));
    !retaintiontrial && changePage(1);
  };

  /****Retain sorted colum when fetch data while applying filters*******/

  function RetainSortedColumn() {
    let copy_body = tableData;
    let sortedArr = [];
    let bodyArr = [];

    for (let i = 0; i < copy_body.length; i++) {
      Header.map((Header) => {
        copy_body[i]?.map((item) => {
          if (item.key === Header?.id) {
            sortedArr.push(item);
          }
        });
      });
      bodyArr.push(sortedArr);
      sortedArr = [];
    }

    setBody([...bodyArr]);
    sortedArr = [];
  }

  useEffect(() => {
    RetainSortedColumn();
    setPage(pageResetValue);
  }, [tableData, Header]);

  /*******************************************************************/

  return (
    <>
      <div className={styles.tableContainer}>
        {body && body.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {Header.map((Header, index) => (
                      <StyledTableCell key={index}>
                        <div className={styles.mainContainer}>
                          <div className={styles.headingText}>
                            {Header?.name}
                          </div>
                        </div>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(pageSize > 0
                    ? body.slice(page * pageSize, page * pageSize + pageSize)
                    : body
                  ).map((arr, index) => (
                    <StyledTableRow key={index} hover>
                      {arr.map((items, index) => (
                        <StyledTableCell key={index}>{items}</StyledTableCell>
                      ))}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage={labelRowsPerPage}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={count}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        ) : !loading && body?.length === 0 ? (
          <div className={styles.marginTop20}>{Illustration}</div>
        ) : (
          <>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {Header.map((Header, index) => (
                      <StyledTableCell key={index}>
                        <div className={styles.mainContainer}>
                          <div className={styles.headingText}>
                            {Header?.name}
                          </div>
                        </div>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {new Array(15).fill(null).map((item, index) => (
                    <TableRow>
                      {Header.map(() => {
                        return (
                          <StyledTableCell key={index}>
                            <div className={styles.mainContainer}>
                              <div className={styles.headingText}>
                                <Skeleton width={150} height={17} />
                              </div>
                            </div>
                          </StyledTableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage={labelRowsPerPage}
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={count}
              rowsPerPage={pageSize}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </div>
    </>
  );
}
export default NewTable;
