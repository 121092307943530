import React, { useEffect, useState } from "react";
import { makeStyles, MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Select, withStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 200,
  },
}));

const StyledSelect = withStyles({
  root: {
    fontFamily: "Inter Regular",
    fontWeight: 400,
    fontSize: 14,
    height: 46,
    width: "100%",
    border: "1px solid #445EBE !important",
    borderRadius: 8,
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    borderColor: "#445EBE",
    color: "#445EBE",
    "&.Mui-disabled": {
      backgroundColor: "#E9E9E9",
      WebkitBoxShadow: "0 0 0 1000px #E9E9E9 inset",
      fontFamily: "Inter Regular",
      borderRadius: "8px",
      color: "#191919",
    },
    "&:focus": {
      borderRadius: 8,
      backgroundColor: "#fff",
    },
  },
})(Select);

const StyledMenu = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#F5F7FB",
    },
    fontFamily: "Inter Regular",
    fontSize: "16px",
    width: "100%",
    color: "#445EBE",
  },
  selected: {
    backgroundColor: "#F5F7FB !important",
  },
})(MenuItem);

const InfiniteyearDropDown = ({
  id,
  onChange,
  fullWidth,
  style,
  disabled,
  selectedItem = "",
  error,
  touched,
  minValue = 0,
  dropdownCustomText = (e) => e,
}) => {
  const classes = useStyles();
  const [list, setList] = useState([]);
  const [dropdownValue, setDropdownValue] = useState(selectedItem || "");

  const loadMoreItems = (event) => {
    if (event.target.scrollTop + 200 === event.target.scrollHeight) {
      let data = [...list];
      let value = data[data.length - 1] + 1;

      if (value > Number.MAX_SAFE_INTEGER - 10) {
        return;
      }
      for (let i = value; i < value + 8; i++) {
        data.push(i);
      }
      setList(data);
    }
  };

  useEffect(() => {
    const max = Math.max(minValue + 10, selectedItem + 30);
    let data = [];
    for (let i = minValue; i < max; i++) {
      data.push(i);
    }
    setList(data);
    setDropdownValue(selectedItem);
  }, [selectedItem, minValue]);

  useEffect(() => {
    onChange(dropdownValue);
  }, [dropdownValue]);

  return (
    <>
      <StyledSelect
        labelId={id || "simple-select"}
        fullWidth
        disabled={disabled}
        defaultValue={selectedItem}
        disableUnderline
        style={{
          fontFamily: "Inter SemiBold",
          width: fullWidth ? "100%" : 107,
          ...style,
        }}
        value={dropdownValue}
        onChange={(e) => setDropdownValue(e.target.value)}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
          PaperProps: {
            onScroll: loadMoreItems,
          },
        }}
      >
        {list.map((item, index) => (
          <StyledMenu value={item} key={index.toString()}>
            {dropdownCustomText(item)}
          </StyledMenu>
        ))}
        {list.length === 0 && (
          <StyledMenu disabled>No options available</StyledMenu>
        )}
      </StyledSelect>
      {error && touched && (
        <div style={{ color: "#E13838", fontSize: "0.75rem", marginTop: 3 }}>
          {error}
        </div>
      )}
    </>
  );
};
export default InfiniteyearDropDown;
