import React from "react";
import RedDelete from "assets/icons/NewCardSvg/RedDelete.svg";
import { Box, Grid } from "@material-ui/core";
import { Field } from "formik";
import AppTextFieldWhite from "../../../../../../components/StyledComponents/AppTextFieldWhite";
const PartnerForm = ({ id, classes, arrayHelper, formik }) => {
  const handleDelete = () => {
    arrayHelper.remove(id);
  };
  const partnerErrors =
    (formik.errors.partnerSet &&
      formik.errors.partnerSet.length &&
      formik.errors.partnerSet[id]) ||
    {};
  const partnerTouched =
    (formik.touched.partnerSet &&
      formik.touched.partnerSet.length &&
      formik.touched.partnerSet[id]) ||
    {};
  return (
    <Grid container spacing={2} wrap="nowrap">
      <Grid item>
        <Box sx={{ maxWidth: 369 }}>
          <label className={classes.label}>Partner Name</label>
          <Field name={`partnerSet[${id}].name`}>
            {({ field }) => (
              <AppTextFieldWhite
                {...field}
                name={`partnerSet[${id}].name`}
                placeholder="Add Partner Name"
                variant="standard"
                onChange={formik.handleChange(`partnerSet[${id}].name`)}
                error={partnerTouched.name && Boolean(partnerErrors.name)}
                helperText={partnerTouched.name && partnerErrors.name}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            )}
          </Field>
        </Box>
      </Grid>
      <Grid item>
        <Box sx={{ maxWidth: 369 }}>
          <label className={classes.label}>Add Email ID</label>
          <Field name={`partnerSet[${id}].email`}>
            {({ field }) => (
              <AppTextFieldWhite
                {...field}
                name={`partnerSet[${id}].email`}
                placeholder="Add Email ID"
                variant="standard"
                onChange={formik.handleChange(`partnerSet[${id}].email`)}
                error={partnerTouched.email && Boolean(partnerErrors.email)}
                helperText={partnerTouched.email && partnerErrors.email}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            )}
          </Field>
        </Box>
      </Grid>
      <Grid item xs container justifyContent="flex-end" alignItems="center">
        <span
          onClick={handleDelete}
          style={{ cursor: "pointer", marginTop: "13px" }}>
          <img src={RedDelete} alt="del" />
        </span>
      </Grid>
    </Grid>
  );
};
export default PartnerForm;
