import { Box } from "@material-ui/core";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Table from "./components/table/Table";
import styles1 from "../../private/investments/investments.module.css";

const SubAdmin = () => {
  const history = useHistory();
  const [permissions, setPermissions] = useState();
  const { userPermissions, user } = useSelector((store) => store.app);

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "SUB ADMINS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(() => {
    checkPermissions();
  }, []);
  return (
    <>
      <HeaderComponent
        heading="Sub Admins"
        description="List Of All Sub-Admins Assigned By Admin"
        addInvestorBtn={false}
        ExtraButtons={
          permissions?.is_create ? (
            <div className={styles1.addInvestmentButtonContainer}>
              <p
                className={styles1.addInvestmentButtonText}
                onClick={() => history.push("/u/sub_admin/addSubAdmin")}>
                + Add Sub Admin
              </p>
            </div>
          ) : null
        }
      />
      <Box height={20} />
      <Table permissions={permissions} />
    </>
  );
};

export default SubAdmin;
