import React from "react";
import { makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  headingContainer: {
    display: "flex",
    alignItems: "center"
    // paddingLeft: 20
  },
  heading: {
    fontFamily: "Inter SemiBold",
    fontSize: "18px",
    fontWeight: 400,
    letterSpacing: 0,
    color: "#191919",
    marginRight: 20,
    flexShrink: 0
  },
  divider: {
    flexGrow: 1,
    height: "1px",
    backgroundColor: "#E9E9E9"
  }
}));

const AccordionHeading = ({ title, padding = 20 }) => {
  const classes = useStyles(padding);
  return (
    <>
      <Grid container xs={12} direction="column">
        <div
          style={{ paddingLeft: padding }}
          className={classes.headingContainer}>
          <div className={classes.heading}>{title}</div>
          <div className={classes.divider}></div>
        </div>
      </Grid>
    </>
  );
};

export default AccordionHeading;
