// import CustomSelect from "@components/Form/CustomSelect";

// import SelectWithInfiniteScrollManageData from "@components/StyledComponents/SelectWithInfinitScrollManageData";

// import { verifyDocUploadType } from "@helpers/Common";

import {
  validationSchemaManageDataAddK1,
  validationSchemaManageDataAddWithPropertyK1
} from "helpers/validationSchema";

import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup
} from "@material-ui/core";

import { useFormik } from "formik";

import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import style from "./editk1.module.scss";

import CustomSelect from "components/StyledComponents/Select";

import { verifyDocUploadType } from "helpers/Common";

import { ManageDataService } from "network/manageDataService";

import Actions from "redux/actions/appActions";

import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";

import Toast from "components/toast";

// import { DocumentService } from "network/documentService";

import CustomSearchSelect from "components/StyledComponents/CustomSearchSelect";

import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";

import CloseIcon from "../../../../assets/icons/blueCloseIcon.svg";

import { AppDispatcher } from "../../../../redux/index";

// import { DocumentService } from "network/documentService";

import { CommonService } from "network/commonService";
import DocTableManageData from "components/Common/DocTableManageData";

function EditPopUpManageData() {
  const dispatch = useDispatch();

  const [selectedItem, setSelectedItem] = useState({});

  const [type, setType] = useState();

  const { manageDataK1Id } = useSelector((store) => store.app);

  const [payload, setPayload] = useState({
    type: "",

    investmentinvestor: "",

    ...(type == "investment" ? null : { property_id: "" }),

    year: "",

    documents: []
  });

  const [initialValues, setInitialValues] = useState({
    type: "",

    investmentinvestor: "",

    ...(type == "investment" ? null : { property_id: "" }),

    year: "",

    documents: [],

    investor: ""
  });

  const [invDocCategories, setInvDocCategories] = useState();

  const investmentManageID = manageDataK1Id?.id;

  const [propertyList, setPropertyList] = useState([]);

  const [investorList, setInvestorList] = useState([]);

  const wrapperRef = useRef(null);

  const onDragEnter = () => wrapperRef.current.classList.add("dragover");

  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");

  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const [docsLoading, setDocsLoading] = useState(false);

  const [allDocs, setAllDocs] = useState([]);

  useEffect(() => {
    getK1Details();

    getInvestmentName();
  }, []);

  useEffect(async () => {
    const invCategories = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENT"
    });

    if (invCategories.success) {
      const catId = invCategories.data[0].doccategory.filter(
        (item) => item.name === "K-1"
      );

      if (catId.length) {
        setInvDocCategories(catId[0]?.id);
      }
    } else {
      Toast.showErrorToast("Failed To Load Document Categories");
    }
  }, []);

  const onFileDrop = (e) => {
    if (!verifyDocUploadType(e)) {
      return;
    }

    const newFile = e.target.files[0];

    if (newFile) {
      let data = new FormData();

      data.append("file", newFile);

      data.append("name", newFile.name);

      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);

    const resp = await CommonService.imageUpload(data);

    if (resp.success) {
      setDocsLoading(false);

      // const updatedFileList = [...imageList, resp.data];

      // setImageList(updatedFileList);

      const updateAllDocs = [...allDocs, resp.data];

      setAllDocs(updateAllDocs);

      formik.setFieldValue("documents", updateAllDocs);

      Toast.showSuccessToast("Document Added Successfully");
    } else {
      dispatch({
        type: Actions.NOTIFICATIONS.SHOW,

        data: {
          type: "error",

          message: "Error Uploading Documents"
        }
      });
    }
  };

  const getPropertyListByInvestment = async (investmentId) => {
    const res = await ManageDataService.getPropertiesListByInvestment({
      investment_id: investmentId
    });

    if (res.success) {
      const temp = res.data;

      setPropertyList(
        temp?.map((data) => ({
          label: data.name,

          value: data.id
        }))
      );
    } else {
      Toast.showErrorToast("Failed To Load Property Names");
    }
  };

  const getInvestorListByInvestmentList = async (investmentId) => {
    const res = await ManageDataService.getInvestorListByInvestment({
      investment_id: investmentId
    });

    if (res.success) {
      const temp = res.data;

      setInvestorList(
        temp?.map((data) => ({
          label: data.investor_name,

          value: data.investment_investor_id
        }))
      );
    } else {
      Toast.showErrorToast("Failed To Load Property Names");
    }
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";

    params += `?page=${page}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }

    const response = await ManageDataService.getInvestmentData([`${params}`]);

    if (response.success) {
      return {
        data: response.data,

        maxPage: response.data.pages
      };
    }

    return { data: [], maxPage: 1 };
  };

  const deleteImage = async (id) => {
    setAllDocs((prev) => {
      return prev.filter((item) => (item?.docId ? item.docId : item.id) != id);
    });

    const selectDeletedItem = allDocs.filter(
      (item) => (item.docId ? item.docId : item.id) === id
    );

    let finalArray = [...allDocs];

    const resp = selectDeletedItem[0].docId
      ? await CommonService.deleteDoc(selectDeletedItem[0].docId)
      : null;

    if (resp?.success || resp == null) {
      finalArray = allDocs.filter((value) => {
        return (value.docId ? value.docId : value.id) != id;
      });

      formik.setFieldValue("documents", finalArray);
      Toast.showSuccessToast("Document Deleted Successfully");
    } else {
      Toast.showErrorToast("Unable To Delete Document");
    }
  };

  const formatDocsData = (docsList) => {
    return docsList?.map((el) => ({
      name: el?.document_media?.name,

      type: el?.category?.id,

      link: el?.document_media?.file,

      id: el?.document_media?.id,

      docId: el.id
    }));
  };

  const getK1Details = async () => {
    const response = await ManageDataService.getK1([investmentManageID]);

    if (response.success) {
      const data = response.data;

      setPayload({ ...payload });

      getPropertyListByInvestment(data?.investmentinvestor?.investment);

      getInvestorListByInvestmentList(data?.investmentinvestor?.investment);

      setSelectedItem({
        name: data?.investor_data?.investment_name,

        id: data?.investmentinvestor?.id
      });

      let test = [];

      for (let i = 0; i < data?.documents?.length; i++) {
        test.push({
          ...data?.documents[i].document_media,

          category_id: data?.documents[i]?.category?.id,

          docId: data?.documents[i]?.id
        });
      }

      // let allPropertySets = [];

      // allPropertySets = data?.documents?.map((doc) => ({

      // document_media_id: doc.document_media_id

      // ? doc.document_media_id

      // : doc.id,

      // category_id: doc.category_id ? doc.category_id : doc.type,

      // ...(doc.docId ? { id: doc.docId } : {})

      // }));

      // document_media_id: el?.document_media?.id,

      // category_id: invDocCategories

      setAllDocs(test);

      setType(data?.type === "By Investment" ? "investment" : "property");

      setInitialValues({
        type: data?.type === "By Investment" ? "investment" : "property",

        investmentinvestor: data?.investmentinvestor?.investment,

        ...(data?.type === "By Investment"
          ? null
          : { property_id: data?.property?.id }),

        year: data.year,

        documents: formatDocsData(data?.documents),

        investor: data?.investmentinvestor?.id
      });
    }
  };

  const formik = useFormik({
    initialValues: initialValues,

    enableReinitialize: true,

    validationSchema:
      type === "property"
        ? validationSchemaManageDataAddWithPropertyK1
        : validationSchemaManageDataAddK1,

    onSubmit: async (values) => {
      let payload = {
        // id: investmentManageID,

        type: type,

        year: values.year,

        investmentinvestor: values.investor,

        documents: values.documents.map((el) => ({
          document_media_id: el?.document_media?.id || el?.id,

          ...(el?.docId && { id: el?.docId }),

          category_id: el?.category_id ? el?.category_id : invDocCategories
        }))

        // documents : imageList
      };

      if (type === "property") {
        payload = { ...payload, property_id: values.property_id || "" };
      }

      let res = await ManageDataService.editManageDataK1(payload, [
        `${investmentManageID}`
      ]);

      if (res.success) {
        Toast.showSuccessToast("K1 Updated Successfully");
        AppDispatcher.openK1Edit(false);
      } else {
        Toast.showWarnToast("Cannot Add K1");
      }
    }
  });

  const onHandleChange = (data) => {
    setSelectedItem(data);

    formik.setFieldValue(`investmentinvestor`, data?.investment_investor_id);

    if (type === "property") {
      getPropertyListByInvestment(data?.id);
    }
    getInvestorListByInvestmentList(data?.id);
  };

  return (
    <Modal
      open={open}
      // onClose={false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box className={style.boxPopUp}>
        <Grid container className={style.Gridcontainer}>
          <Grid container justifyContent="space-between">
            <Grid className={style.heading} item>
              Edit K1
            </Grid>
            <Grid tem>
              <img
                onClick={() => {
                  AppDispatcher.openK1Edit(false);
                }}
                src={CloseIcon}
                alt="Images Icon"
                style={{ marginRight: 8, cursor: "pointer" }}
              />
            </Grid>
          </Grid>

          <Grid
            style={{
              padding: "30px 12px 0px 7px",
              background: "#fafafa",
              marginTop: "20px"
            }}
            container
            spacing={3}
            item
            xs
            className={style.GridItem}>
            <Grid item xs={12}>
              <div className={style.label}>Choose One</div>

              <FormControl>
                <RadioGroup
                  row
                  value={formik.values.type}
                  name="controlled-radio-buttons-group"
                  onChange={(e) => {
                    formik.setFieldValue(
                      `type`,
                      e.target.value ? e.target.value : "investment"
                    );
                    setType(e.target.value);
                    // selectProperty(e.target.value)
                  }}>
                  <FormControlLabel
                    value="investment"
                    control={<Radio color="secondary" />}
                    label="By Investment"
                  />
                  <FormControlLabel
                    value="property"
                    control={<Radio color="secondary" />}
                    label="By Property"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <div className={style.label}>Select Investment</div>

              <CustomSearchSelect
                getData={getInvestmentName}
                onChange={onHandleChange}
                selectedItem={selectedItem}
                // selectedItem={formik.values.investmentinvestor}
              />
            </Grid>
            <Grid item xs={12}>
              <div className={style.label}>Select Investor</div>
              <CustomSelect
                placeholder={"Select Investor"}
                items={investorList}
                value={formik.values.investor}
                onChange={(e) =>
                  formik.setFieldValue("investor", e.target.value)
                }
                fullWidth={true}
                error={formik.errors.investor}
                touched={formik.touched.investor}
              />
            </Grid>
            {formik.values.type === "property" && (
              <Grid item xs={12}>
                <div className={style.label}>Select Property</div>
                <CustomSelect
                  placeholder={"Select Property"}
                  items={propertyList}
                  value={formik.values.property_id}
                  onChange={(e) =>
                    formik.setFieldValue("property_id", e.target.value)
                  }
                  fullWidth={true}
                  error={formik.errors.property_id}
                  touched={formik.touched.property_id}
                />
              </Grid>
            )}
            <Grid xs={12} item>
              <div className={style.label}>Select Year</div>
              <SelectWithInfiniteScroll
                id="infiniteYear"
                fullWidth={true}
                minValue={2000}
                selectedItem={formik.values.year}
                onChange={(value) => formik.setFieldValue(`year`, value)}
                error={formik?.errors?.year}
                touched={formik?.touched?.year}
              />
            </Grid>
            <Grid xs={12} item>
              <div className={style.label}>Upload Documents</div>
              <div className={style.docContainerWrapper}>
                <div
                  ref={wrapperRef}
                  className={style.drop_file_input}
                  onDragEnter={onDragEnter}
                  onDragLeave={onDragLeave}
                  onDrop={onDrop}>
                  <div>
                    {docsLoading ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <p className={style.droptext}>
                          Drag And Drop Files Here Or <span>Browse</span>
                        </p>
                      </>
                    )}
                  </div>

                  <input type="file" value="" onChange={onFileDrop} />
                </div>
                {allDocs.length ? (
                  <Grid container className={style.allDocsContainer}>
                    <DocTableManageData
                      allDocs={allDocs}
                      deleteImage={deleteImage}
                    />
                  </Grid>
                ) : (
                  <Box height={10} />
                )}
              </div>
            </Grid>
            <Grid
              xs={12}
              justifyContent="space-between"
              item
              container
              spacing={2}>
              <Grid xs={5} item>
                <Button
                  style={{
                    fontFamily: "Inter SemiBold",
                    fontSize: 16,
                    height: 45,
                    width: "140px",

                    // padding: "0px 20px !important",
                    padding: "13px 90px !important",

                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#ffffff",
                    color: "#5775E5",
                    cursor: "pointer",
                    border: "1px solid #5775E5",
                    marginRight: "10px"
                  }}
                  // className={style.buttonCancel}
                  onClick={() => {
                    // AppDispatcher.openEditManageDataPopUp(false)
                    // AppDispatcher.openEditManageDataPopUp(false);
                    AppDispatcher.openK1Edit(false);
                  }}>
                  Cancel
                </Button>
              </Grid>
              <Grid xs={5} item>
                {/* <AddBtn text="Update" type="submit" /> */}
                <Button
                  onClick={() => formik.handleSubmit()}
                  style={{
                    fontFamily: "Inter SemiBold",
                    fontSize: 16,
                    height: 45,
                    // minWidth: 170,
                    width: "140px",
                    background:
                      "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)",
                    padding: "13px 90px !important",
                    borderRadius: 4,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // backgroundColor: "#ffffff",
                    color: "#FFFF",
                    cursor: "pointer",
                    border: "1px solid #5775E5",
                    marginRight: "10px"
                  }}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default EditPopUpManageData;
