import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const PropertyCard = ({
  Title,
  count,
  icon,
  style,
  link = false,
  backgroundColor = "white",
  showSideData = false,
  year = "",
  month = ""
}) => {
  //   const color = customColor ? customColor : "#BBBBBB";
  const history = useHistory();
  const handleClick = () => {
    if (link) {
      history.push(link);
    }
  };

  return (
    <>
      <Grid
        onClick={handleClick}
        container
        item
        // className={classes.main}
        style={{
          background: backgroundColor,
          cursor: link ? "pointer" : "",
          ...style,
          padding: "20px 10px 10px 0px"
        }}
        direction="row">
        <Grid xs={3} lg={3} item>
          <img src={icon} style={{ height: 44, width: 44 }} />
        </Grid>
        <Grid xs={9} lg={9} item style={{ paddingLeft: 5 }}>
          <Grid container direction="row" justifyContent="flex-start">
            <Typography
              style={{
                // whiteSpace: "nowrap"
                flexWrap: "wrap"
                //   marginTop: 20,
                //   fontSize: 14,
                //   fontFamily: "Inter Regular",
                //   fontWeight: 600,
                //   color: color,
                //   whiteSpace: "nowrap"
              }}>
              {Title}
            </Typography>
          </Grid>

          <Grid
            container
            item
            onClick={handleClick}
            style={{
              fontSize: "18px",
              fontFamily: "Inter Regular",
              fontWeight: 700,
              color: "#616161",
              lineHeight: "31px",
              cursor: link ? "pointer" : ""
            }}>
            <Grid xs={7} md={10} lg={6} item>
              {count}
            </Grid>
            <Grid xs={7} md={10} lg={6} item>
              {year && month && showSideData == true ? (
                <span
                  style={{
                    marginLeft: "10px",
                    whiteSpace: "nowrap",
                    // flexWrap: "wrap",
                    padding: "8px 10px 8px 12px",
                    backgroundColor: "#FFF4EA",
                    borderRadius: 153,
                    color: "#FF9029",
                    fontWeight: 600,
                    fontSize: "12px"
                  }}>
                  {`${year} | ${month}`}
                </span>
              ) : (
                false
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default PropertyCard;
