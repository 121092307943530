import { Grid, Tooltip, Typography } from "@material-ui/core";
import { truncate } from "helpers/Common";
import React from "react";
import style from "../dashboard.module.css";
import DashboardToolTip from "./ToolTip/DashboardToolTip";
import ToolTipImage from "../../../../assets/icons/Group.svg";

function PlatformMatrics({ heading, subHeading, toolData = [] }) {
  return (
    <Grid
      justifyContent="space-between"
      style={{ paddingBottom: "20px" }}
      container
      item>
      <Grid style={{ display: "flex" }} item>
        <Typography className={style.pageHeading}>{heading}</Typography>

        {heading === "Total Invested Amount" ? (
          <Tooltip
            placement="top"
            title={
              <div
                style={{ fontSize: "16px", fontWeight: 400, padding: "10px" }}>
                Only for Active Investments
              </div>
            }
            arrow>
            <img
              style={{ marginLeft: "6px", marginTop: "2px" }}
              src={ToolTipImage}
              alt="cross"
              width={19.2}
              height={20.2}
            />
          </Tooltip>
        ) : null}
      </Grid>

      <Grid item>
        {heading === "Total Investors" ||
        heading === "Total Investments" ||
        heading === "Total Entities" ? (
          <>
            <Tooltip
              placement="bottom-down"
              title=<DashboardToolTip
                data={
                  heading === "Total Investors"
                    ? [
                        ["Active: ", toolData[0]],
                        ["Inactive: ", toolData[1]],
                        ["Pending: ", toolData[2]]
                      ]
                    : heading === "Total Investments"
                    ? [
                        ["Active: ", toolData[3]],
                        ["Inactive: ", toolData[4]]
                      ]
                    : heading === "Total Entities"
                    ? [
                        ["Active: ", toolData[5]],
                        ["Inactive: ", toolData[6]]
                      ]
                    : null
                }
              />
              arrow>
              <Typography className={style.subHeading}>
                {truncate(subHeading, 13)}
              </Typography>
            </Tooltip>
          </>
        ) : (
          <Typography className={style.subHeading}>
            {truncate(subHeading, 13)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}

export default PlatformMatrics;
