import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Grid } from "@material-ui/core";
import TotalPropertiesIcon from "assets/icons/NewCardSvg/totalProperties.svg";
import TotalInvestmentsIcon from "assets/icons/NewCardSvg/totalInvestments.svg";
import TotalAssetValueIcon from "assets/icons/NewCardSvg/totalAssetValue.svg";
import TotalOutstandingLoanIcon from "assets/icons/NewCardSvg/totalOutstandingLoan.svg";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import PropertiesCards from "components/Cards/InvestorCards";
import Toast from "components/toast";
import { numFormatter } from "helpers";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { PropertiesService } from "network/propertiesService";
import { AppDispatcher } from "../../../redux/index";
import AppTable from "./component/PropertyListView/AppTable";
import styles1 from "./properties.module.scss";
import { Skeleton } from "@material-ui/lab";

const Properties = () => {
  const [statsData, setStatsData] = useState();
  const [permissions, setPermissions] = useState();
  const [loading, setLoading] = useState(false);
  const { userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();

  const fetchData = async () => {
    setLoading(true);
    const resp = await PropertiesService.getStats();
    if (resp.success) {
      setLoading(false);
      let data = resp.data;
      let temp = data;
      setStatsData(temp);
    } else {
      Toast.showErrorToast("Failed To Load Properties Stats");
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "PROPERTIES",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  const addProperty = () => {
    AppDispatcher.setPropertiesId(null);
    history.push({ pathname: "/u/properties/addProperties" });
  };

  useEffect(() => {
    checkPermissions();
    fetchData();
  }, []);

  const skeleton = (values) => {
    return !loading ? values : <Skeleton width={150} variant="text" /> || "-";
  };

  return (
    <>
      <HeaderComponent
        heading="Properties"
        description="Add and manage your properties"
        ExtraButtons={
          permissions?.is_create ? (
            <div className={styles1.addPropertyButtonContainer}>
              <p
                className={styles1.addPropertyButtonText}
                onClick={addProperty}>
                + Add Property
              </p>
            </div>
          ) : null
        }
      />
      <Grid xs={12} md={12} container style={{ marginTop: 28 }}>
        <Grid xs={4} md={3} item>
          <PropertiesCards
            Title="Total Investments"
            link="/u/investments"
            count={skeleton(statsData ? statsData.total_investment_count : "-")}
            icon={TotalInvestmentsIcon}
          />
        </Grid>
        <Grid xs={4} md={3} item>
          <PropertiesCards
            Title="Total Properties"
            count={skeleton(statsData ? statsData.total_property_count : "-")}
            icon={TotalPropertiesIcon}
          />
        </Grid>
        <Grid xs={4} md={3} item>
          <PropertiesCards
            Title="Total Asset Value"
            count={skeleton(
              statsData ? `$${numFormatter(statsData.total_asset_value)}` : "$0"
            )}
            icon={TotalAssetValueIcon}
          />
        </Grid>
        <Grid xs={4} md={3} item>
          <PropertiesCards
            Title="Total Outstanding Loan"
            count={skeleton(
              statsData
                ? `$${numFormatter(statsData.total_outstanding_amount || 0)}`
                : "-"
            )}
            icon={TotalOutstandingLoanIcon}
          />
        </Grid>
      </Grid>
      <AppTable permissions={permissions} />
    </>
  );
};

export default Properties;
