import React, { useEffect, useState } from "react";
// import MUIDataTable from "mui-datatables";
import styles from "../../../../../components/AppTable/AppTable.module.css";
// import styles1 from "../../../investments/investments.module.css";
import DotsMenu from "../../../../../assets/icons/dotsMenu.svg";
import { Menu, Button, MenuItem, Grid } from "@material-ui/core";
// import { createTheme } from "@material-ui/core";
// import { ThemeProvider } from "@material-ui/styles";
import SearchInput from "components/Search";
// import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import { useHistory } from "react-router-dom";
import { RoleService } from "network/rolesService";
import Toast from "components/toast";
import { AppDispatcher } from "../../../../../redux";
import Illustrations from "components/illustration/Illustrations";
import noRoles from "../../../../../assets/emptyState/noRoles.svg";
import NewTable from "components/NewTable/NewTable";
// import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
// import ManageColumn from "components/ManageColumns";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import { capitalize } from "helpers";
// import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
// import CSV from "../../../../../assets/icons/NewCardSvg/CSV.svg";
// import FWDEmail from "../../../../../assets/icons/NewCardSvg/FWDEmail.svg";
// import StyledSwitch from "components/StyledComponents/StyledSwitch";
// const theme = createTheme({
//   overrides: {
//     MuiTableCell: {
//       root: {
//         borderBottom: "0px"
//       },
//       head: {
//         background: "#FAFAFA",
//         padding: "8px 18px"
//       }
//     },
//     MuiTableSortLabel: {
//       root: {
//         alignItems: "flex-start"
//       }
//     },
//     MuiTableFooter: {
//       root: {
//         background: "#fafafa"
//       }
//     },
//     MUIDataTableHeadCell: {
//       sortLabelRoot: {
//         height: undefined
//       }
//     }
//   }
// });

// const useStyles = makeStyles(() => ({
//   text: {
//     fontFamily: "Inter Regular",
//     fontSize: 14,
//     whiteSpace: "nowrap"
//   },
//   headerLabelCtr: {
//     display: "flex",
//     alignItems: "center",
//     width: "100%",
//     flexShrink: 0
//   }
// }));

// const statusTypes = [{ label: "All", value: "all" }];

const TableHeaders = [
  {
    id: "role_name",
    name: "Role Name"
  },
  {
    id: "description",
    name: "Description"
  }
];

const Table = ({ permissions }) => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [search, setSearch] = useState("");
  const [statusFilter] = useState("all");
  const history = useHistory();
  const open = Boolean(anchorEl);
  const [loading, setLoading] = useState(true);
  const [THeaders] = useState(TableHeaders);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(10);
  const [pageResetValue, setPageResetValue] = useState(0);

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      fetchData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    AppDispatcher.setRoleId(id);
  };

  const handleEditRole = () => {
    history.push("/u/roles/editRole");
  };

  const Tabledatalist =
    data &&
    data.map((item) => {
      return [
        <div key="role_name">{capitalize(item?.name)}</div>,
        <div key="description">{capitalize(item?.description)}</div>,

        (permissions?.is_update || permissions?.is_delete) && (
          <Grid container justifyContent="flex-start" key="Action">
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleClick(e, item.id)}>
              <img src={DotsMenu} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  marginTop: 40,
                  boxShadow: "5px 5px 5px #E9E9E9"
                }
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}>
              {permissions?.is_update ? (
                <div key="action">
                  <MenuItem
                    className={styles.menuItem}
                    onClick={handleEditRole}
                    style={{
                      fontFamily: "Inter Regular"
                    }}>
                    Edit
                  </MenuItem>
                </div>
              ) : null}
              {permissions?.is_delete ? (
                <MenuItem
                  className={styles.menuItemDelete}
                  onClick={() => history.push("/u/roles/deleteRole")}>
                  Delete
                </MenuItem>
              ) : null}
            </Menu>
          </Grid>
        )
      ];
    });

  const setQuery = (val) => {
    setSearch(val);
  };

  const fetchData = async (pageNum, append = false) => {
    const params = {};
    let url = "";
    if (search.length > 0) {
      url += `${url.length ? "&" : "?"}search=${search}`;
    }
    if (statusFilter !== "all") {
      params.status = statusFilter;
    }

    if (url.length > 0) {
      url += `&page=${pageNum}&page_size=${pageSize}`;
    } else {
      url += `?page=${pageNum}&page_size=${pageSize}`;
    }

    const res = await RoleService.getRoles([url]);
    if (res.success) {
      setLoading(false);
      setCount(res.data.count);
      if (append) {
        setData([...data, ...res.data.results]);
      } else {
        setData(res?.data?.results);
      }
    } else {
      Toast.showErrorToast("Failed To Load Roles List");
    }
  };

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    fetchData(1);
  }, [search, statusFilter, pageSize]);

  return (
    <>
      <div className={styles.tableCtrOuter}>
        <TableTopFilter
          filterSection1={[<SearchInput setQuery={setQuery} />]}
          filterSection2={[]}
        />

        {/* <ChipsComponent /> */}
        <div className={styles.tableContainer}>
          <NewTable
            loading={loading}
            Header={[
              ...THeaders,
              (permissions?.is_update || permissions?.is_delete) && {
                id: "Action",
                name: "Action"
              }
            ]}
            tableData={Tabledatalist}
            pageSize={pageSize}
            changePage={changePage}
            changePageSize={changePageSize}
            count={count}
            pageResetValue={pageResetValue}
            labelRowsPerPage="Total No Of Roles Per Page"
            Illustration={
              <Illustrations
                src={noRoles}
                heading="No Roles"
                subHeading="No Roles To Show."
              />
            }
          />
        </div>
      </div>
    </>
  );
};

export default Table;
