import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import { truncate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import formatDate from "helpers/formatDate";
import React from "react";
import styles from "./commanstyle.module.scss";

const DistributionTable = ({
  Headingdata,
  mapArray,
  width,
  DetailPageData
}) => {
  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };
  return (
    <>
      <Table
        className={styles.Spon_T_C}
        style={width ? { minWidth: width } : {}}>
        <TableHead>
          <TableRow>
            {Headingdata?.map((item, i) => (
              <TableCell className={styles.T_Heading} key={i}>
                {item.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mapArray?.map((obj, i) => (
            <TableRow key={i}>
              <TableCell className={styles.T_body}>
                {obj?.investor_name}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.amount ? formatCurrency(obj?.amount) : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.updated_at ? formatDate(obj?.updated_at) : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.distribution?.type ? obj?.distribution?.type : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.distribution?.year ? obj?.distribution?.year : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.distribution?.cadence ? obj?.distribution?.cadence : "-"}
              </TableCell>
              <TableCell
                className={styles.T_body}
                style={{ maxwidth: "642px" }}>
                {obj?.notes ? obj?.notes : "-"}
              </TableCell>
            </TableRow>
          ))}

          {DetailPageData &&
            DetailPageData?.map((obj, i) => (
              <TableRow key={i}>
                <TableCell className={styles.T_body}>
                  {obj?.investor_name}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.amount ? formatCurrency(obj?.amount) : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.updated_at ? formatDate(obj?.updated_at) : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.distribution?.type ? obj?.distribution?.type : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.distribution?.year ? obj?.distribution?.year : "-"}
                </TableCell>
                <TableCell className={styles.T_body}>
                  {obj?.distribution?.cadence
                    ? obj?.distribution?.cadence
                    : "-"}
                </TableCell>
                <TableCell className={styles.T_body} style={{ width: "642px" }}>
                  {obj?.notes ? (
                    <div title={obj?.notes}>{truncate(obj?.notes, 30)}</div>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell className={styles.T_body}>
                  <DocumentsUpload
                    editable={false}
                    listData={
                      obj.documents
                        ? formatDocsData(obj.distribution?.documents)
                        : []
                    }
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  );
};

export default DistributionTable;
