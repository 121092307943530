import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import SelectedColumns from "../components/SelectedColumns/SelectedColumns";
import ChooseColumn from "../components/ChooseColumn/ChooseColumn";
import Heading from "../components/Heading/Heading";
import manageColumns from "../../../assets/icons/manageColumns.svg";

const useStyles = makeStyles((theme) => ({
  secondGrid: {
    margin: "0px 10px"
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  list: {
    height: "310px",
    overflow: "hidden",
    overflowY: "scroll"
  },
  leftContainer: {
    padding: "17px 20px",
    backgroundColor: "#FBFCFE"
  },
  rightContainer: {
    padding: "17px 13px"
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    boxSizing: "border-box",
    padding: "12px",
    borderRadius: "16px",
    width: "200px",
    cursor: "pointer",
    border: "1px solid #EEF3F8"
  },
  icon: {
    padding: "4px 10px 0px 0px"
  },
  text: {
    color: "#0C0C0C",
    fontFamily: "Inter regular",
    fontSize: "14px",
    letterSpacing: 0,
    lineHeight: "19px",
    fontWeight: 400,
    textTransform: "none",

    "&:focus": {
      outline: "none"
    }
  }
}));
export default function ManageColumnsWeb({
  open,
  columnNames,
  handleClickOpen,
  handleClose,
  checked,
  setChecked,
  handleToggle,
  handleSave,
  searchTerm,
  handleSearch,
  handleSelectAll,
  ExtractAllHeaders
}) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.container} onClick={handleClickOpen}>
        <div className={classes.icon}>
          <img src={manageColumns} width="16px" height="16px" />
        </div>
        <div className={classes.text}>Manage Columns</div>
      </div>
      <Dialog
        open={open}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <Grid container>
          <DialogContent>
            <Heading handleClose={handleClose} />
          </DialogContent>
          <Grid
            item
            xs={12}
            container
            direction="row"
            justify="space-between"
            // alignItems="center"
            className={classes.secondGrid}>
            <Grid item xs={6} className={classes.rightContainer}>
              <ChooseColumn
                handleToggle={handleToggle}
                checked={checked}
                columnNames={columnNames}
                searchTerm={searchTerm}
                handleSearch={handleSearch}
                handleSelectAll={handleSelectAll}
                ExtractAllHeaders={ExtractAllHeaders}
                handleClose={handleClose}
                handleSave={handleSave}
              />
            </Grid>
            <Grid item xs={6} className={classes.leftContainer}>
              <SelectedColumns
                checked={checked}
                handleToggle={handleToggle}
                setChecked={setChecked}
              />
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
