import { Grid, Tooltip, useMediaQuery } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    marginBottom: 20,
    "&:hover": {
      cursor: "pointer"
    }
  },
  title: {
    fontFamily: "Inter Regular",
    fontStyle: "semi-bold",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17px",
    color: "#BBBBBB",
    whiteSpace: "no-wrap"
  },
  text: {
    fontFamily: "Inter Bold",
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "26px",
    lineHeight: "21px !important",
    color: "#616161 !important"
  }
});

const DistributionCard = ({ title, count, icon, link, last, toolTip, key }) => {
  const classes = useStyles();
  const history = useHistory();
  const matches = useMediaQuery("(min-width:1465px)");

  const handleclickNavigation = (link) => {
    link && history.push(link);
  };

  return (
    <React.Fragment key={key}>
      {toolTip ? (
        <Tooltip title={toolTip}>
          <Grid
            container
            item
            lg
            md={4}
            sm={6}
            xs={4}
            spacing={1}
            className={classes.root}
            justifyContent="space-between"
            onClick={() => {
              handleclickNavigation(link);
            }}>
            <Grid item xs="auto">
              <img src={icon} width="44px" height="44px" />
            </Grid>
            <Grid container item xs>
              <Grid item xs={12} className={classes.title}>
                {title}
              </Grid>
              <Grid item xs={12} className={classes.text}>
                {count}
              </Grid>
            </Grid>
          </Grid>
        </Tooltip>
      ) : (
        <Grid
          container
          item
          lg
          md={4}
          xs={4}
          spacing={1}
          className={classes.root}
          justifyContent="space-between"
          onClick={() => {
            handleclickNavigation(link);
          }}>
          <Grid item xs="auto">
            <img src={icon} width="44px" height="44px" />
          </Grid>
          <Grid container item xs>
            <Grid item xs={12} className={classes.title}>
              {title}
            </Grid>
            <Grid item xs={12} className={classes.text}>
              {count}
            </Grid>
          </Grid>
        </Grid>
      )}

      {last && <Grid item lg={matches ? 2 : 0}></Grid>}
    </React.Fragment>
  );
};

export default DistributionCard;
