import * as React from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import styles from "./publicLayout.module.scss";
import { ReactComponent as LogoTransparent } from "../assets/icons/LogoTransparent.svg";
import AppIcon from "../components/AppIcon";

export default function PublicLayout({ children }) {
  return (
    <Box className={styles.root}>
      <Grid container wrap="wrap-reverse" className={styles.mainRoot}>
        <Grid item xs={12} md={8} lg={8} className={styles.container1}>
          {children}
        </Grid>

        <Grid
          item
          container
          md={4}
          sx={12}
          lg={4}
          className={styles.container2}
          direction="column">
          {/* <div className={styles.emptyContainer} /> */}

          <div className={styles.logoTransparent}>
            <AppIcon
              icon={LogoTransparent}
              style={{ height: "100%", width: "100%" }}
            />
          </div>
          <div className={styles.backgroundBox} />
        </Grid>
      </Grid>
    </Box>
  );
}
