import { Grid } from "@material-ui/core";
import React from "react";
import styles from "./InvDetail.module.scss";

const HeadingContainer = ({ Title, children, border, dropShadow }) => {
  return (
    <Grid
      container
      item
      xs={12}
      className={border ? styles.main_con_wth_Border : styles.main_con}>
      <Grid item xs={12}>
        <h4 className={styles.invDetial_title}>{Title}</h4>
      </Grid>
      <Grid
        item
        xs={12}
        className={styles.invDetial_child}
        style={
          dropShadow
            ? {
                filter: "drop-shadow(0px 4px 15px rgba(160, 160, 160, 0.15))"
              }
            : {}
        }>
        {children}
      </Grid>
    </Grid>
  );
};

export default HeadingContainer;
