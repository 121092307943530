import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Typography,
  Box
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styles from "../InvestmentDetails.module.scss";
import LightTooltip from "components/LightTooltip";
import NewDropDown from "components/NewDropDown";
import { useHistory } from "react-router-dom";
import Illustrations from "components/illustration/Illustrations";
import ToolTipDataComponent from "./ToolTipDataComponent";
import FileDownloadDropDown from "./FileDownloadDropDown";
import noInvestorIcon from "../../../assets/emptyState/noInvestments.svg";
import noEntityIcon from "../../../assets/emptyState/noEntity.svg";

// import { AppDispatcher } from "redux";
import { AppDispatcher } from "../../../redux/index";

const shapeStyles = {
  bgcolor: "primary.main",
  width: 27,
  height: 27,
  borderRadius: 5,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: 15,
  color: "#fff"
};

const DetailsTable = ({
  fixHeight,
  tableTitle,
  tableHeading,
  tooltipData,
  sponserData,
  removeBorderBottom,
  handleCellClickName
}) => {
  const router = useHistory();
  const [expandIndex, setExpandIndex] = useState(-1);
  const [emptyStateHeading, setEmptyStateHeading] = useState(null);
  const [emptyStateImagesrc, setEmptyStateImagesrc] = React.useState(
    "/assets/emptyState/noSponsors.svg"
  );
  const [emptyStateSubHeading, setEmptyStateSubHeading] =
    React.useState("No record found");

  const handleCellClick = (name, id) => {
    if (!id) {
      return;
    }
    if (id) {
      AppDispatcher.setInvestmentDetailID(id);
    }
    if (name === "none") {
      return;
    }
    if (name === "investment_id") {
      AppDispatcher.setInvestmentDetailID(id);
      router.push("/u/investments/detail");
    } else if (name === "sponsor_id") {
      router.push(`/user/sponsors/sponsorsDetails/${id}`);
    } else if (name === "entity_id") {
      AppDispatcher.setEntitiesDetailData({
        id: id,
        entity_type: "INVESTOR"
      });
      router.push("/u/entities/detail");
    }
  };

  useEffect(() => {
    if (tableTitle === "Sponsors") {
      setEmptyStateSubHeading("No sponsors to view");
      setEmptyStateImagesrc("/assets/emptyState/noSponsors.svg");
    } else if (tableTitle === "Sponsor Tiers") {
      setEmptyStateSubHeading("No sponsor tiers to view");
      setEmptyStateImagesrc("/assets/emptyState/noSponsors.svg");
    } else if (tableTitle === "Contacts") {
      setEmptyStateSubHeading("No added contacts");
      setEmptyStateImagesrc("/assets/emptyState/noContacts.svg");
    } else if (tableTitle === "K1s") {
      setEmptyStateSubHeading("No k1s to view");
      setEmptyStateImagesrc("/assets/emptyState/nomanageData.svg");
    } else if (tableTitle === "Net Operating Income") {
      setEmptyStateSubHeading("No Net operating income to view");
      setEmptyStateImagesrc("/assets/emptyState/nomanageData.svg");
    } else if (tableTitle === "Associated Entities") {
      setEmptyStateSubHeading("Add Entities To View.");
      setEmptyStateImagesrc(noEntityIcon);
      setEmptyStateHeading("No Entities");
    } else if (tableTitle === "Associated Investments") {
      setEmptyStateSubHeading("Add Investments To View.");
      setEmptyStateImagesrc(noInvestorIcon);
      setEmptyStateHeading("No Investments");
    } else if (tableTitle === "Uploaded Documents") {
      setEmptyStateSubHeading("There are no added Documents.");
      setEmptyStateImagesrc("/assets/emptyState/noDocuments.svg");
      setEmptyStateHeading("No Documents");
    }
  }, []);

  return (
    <Grid container className={styles.table_main_container}>
      {tableTitle ? (
        <Typography className={styles.cardHeading}>
          {tableTitle === "Tagged Properties" ? (
            <Grid container alignItems="center">
              <Grid item xs="auto" style={{ marginRight: 14 }}>
                Tagged Properties
              </Grid>
              <Box component="span" sx={shapeStyles}>
                {sponserData.length}
              </Box>
            </Grid>
          ) : (
            tableTitle
          )}
        </Typography>
      ) : null}
      <TableContainer
        component={Paper}
        // style={!tableTitle ? { height: 250 } : null}
        container
        justifyContent="center"
        alignItems="center"
        style={{
          boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
          backgroundColor: "#fff"
        }}>
        <Grid
          item
          xs={12}
          style={
            fixHeight
              ? { height: 250, marginLeft: 0, marginRight: 0 }
              : { margin: "0px 0px 0px 0px" }
          }>
          <Table
            // stickyHeader
            style={{ minWidth: 600 }}
            aria-label="simple table">
            {sponserData?.length ? (
              <>
                <TableHead>
                  <TableRow>
                    {tableHeading?.map((item, index) => {
                      return (
                        <TableCell
                          key={index}
                          className={styles.table_header}
                          noWrap
                          style={
                            item === "Notes" ? { width: "500px" } : undefined
                          }>
                          {item}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sponserData?.map((rowData, index) => {
                    const checkLast = sponserData.length === index + 1;
                    return (
                      <TableRow key={index}>
                        {rowData.map((item, indexx) => {
                          return typeof item === "object" &&
                            tableTitle === "Associated Entities" &&
                            indexx === 2 ? (
                            <TableCell
                              key={indexx}
                              className={[
                                (removeBorderBottom || checkLast) &&
                                  styles.removeborderbottom
                              ]}
                              style={{ minWidth: 200 }}>
                              <NewDropDown
                                enableNumbering
                                index={index}
                                item={item || []}
                                handleCellItemClick={(e, name, id) =>
                                  handleCellClick(
                                    handleCellClickName || "none",
                                    id
                                  )
                                }
                                type="sponsor_id"
                                expandIndex={expandIndex}
                                setExpandIndex={setExpandIndex}
                              />
                            </TableCell>
                          ) : (typeof item === "object" &&
                              tableTitle === "Associated Investments") ||
                            (tableTitle === "Associated Entities" &&
                              item.length === 3) ? (
                            <LightTooltip
                              // toolTipWidth="100px"
                              title={
                                tooltipData && (
                                  <ToolTipDataComponent
                                    data={tooltipData}
                                    index={index}
                                  />
                                )
                              }
                              arrow="true"
                              key={indexx}>
                              <TableCell
                                className={[
                                  (removeBorderBottom || checkLast) &&
                                    styles.removeborderbottom
                                ]}
                                style={{
                                  minWidth: 200,
                                  cursor: "pointer"
                                  // backgroundColor: "#f6f8fd"
                                }}
                                onClick={() =>
                                  handleCellClick(item[1], item[2])
                                }>
                                {item[0]}
                              </TableCell>
                            </LightTooltip>
                          ) : typeof item === "object" ? (
                            <TableCell
                              key={indexx}
                              className={[
                                (removeBorderBottom || checkLast) &&
                                  styles.removeborderbottom
                              ]}>
                              <FileDownloadDropDown
                                data={item}
                                tableTitle={tableTitle}
                              />
                            </TableCell>
                          ) : typeof item !== "object" &&
                            [
                              "Distressed",
                              "Stabilized",
                              "Active",
                              "Inactive",
                              "Draft",
                              "Sold"
                            ].includes(item) ? (
                            <TableCell
                              key={indexx}
                              component="th"
                              scope="row"
                              // style={{ borderBottom: "none" }}
                              className={[
                                (removeBorderBottom || checkLast) &&
                                  styles.removeborderbottom
                              ]}>
                              <Grid
                                className={
                                  ["Stabilized", "Active"].includes(item)
                                    ? styles.stabilizedwrapper
                                    : ["Draft"].includes(item)
                                    ? styles.draftWrapper
                                    : styles.Distresseddwrapper
                                }>
                                {item}
                              </Grid>
                            </TableCell>
                          ) : (
                            <LightTooltip
                              // toolTipWidth="100px"
                              title={
                                tooltipData && (
                                  <ToolTipDataComponent
                                    data={tooltipData}
                                    index={index}
                                  />
                                )
                              }
                              arrow="true"
                              key={indexx}>
                              <TableCell
                                style={{
                                  // wordBreak: "break-word",
                                  whiteSpace: "pre-wrap"
                                }}
                                className={[
                                  (removeBorderBottom || checkLast) &&
                                    styles.removeborderbottom
                                ]}
                                key={indexx}>
                                {item || "-"}
                              </TableCell>
                            </LightTooltip>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </>
            ) : (
              <Illustrations
                noFixHeight
                src={emptyStateImagesrc}
                heading={emptyStateHeading}
                subHeading={emptyStateSubHeading}
              />
            )}
          </Table>
        </Grid>
      </TableContainer>
    </Grid>
  );
};

export default DetailsTable;
