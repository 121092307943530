import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import PlusAdd from "../../../../../../assets/icons/PlusAdd.svg";
import InvestorForm from "./components/investorForm";
import { FieldArray, Formik } from "formik";
import { validationSchemaInvestorForm } from "helpers/validationSchema";
import styles from "../../../investments.module.css";
import { AppDispatcher } from "../../../../../../redux/index";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import Toast from "components/toast";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Loader from "components/Loader/AppLoader";
import { AddInvestmentPreviewService } from "network/addInvestmentPreviewService";
import { stringToDate } from "helpers";
import { EntityService } from "network/entityService";
import UpdateBtn from "components/Buttons/Primary/UpdateBtn";
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    borderRadius: 20
  },
  accordionContainer: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8
  },
  accordionContainer1: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8,
    marginTop: 20
  },
  basicContainer2: {
    marginTop: 10
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white"
    }
  },
  title: {
    fontFamily: "Inter Bold",
    fontSize: 18,
    fontWeight: 700,
    background: "#FFF",
    padding: "20px 0px"
  },
  field_con: {
    backgroundColor: "#FAFAFA",
    padding: 20,
    borderRadius: 10,
    marginBottom: 20
  },
  EquityStyle: {
    color: "#A0A0A0",
    fontFamily: "Inter Regular",
    fontSize: 16,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    marginTop: "20px"
  },
  EquitySpan: {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontSize: 18,
    fontWeight: 700,
    color: "#191919"
  },
  Headings: {
    fontFamily: "Inter Regular",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "19px",
    color: "#616161",
    margin: "20px 0px"
  },
  addMoreButton: {
    padding: "20px",
    borderRadius: "10px",
    background: "#FAFAFA",
    margin: "20px 0px",
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap",
    "& img": {
      cursor: "pointer"
    },
    "& span": {
      paddingLeft: 10,
      color: "#445EBE",
      cursor: "pointer",
      fontFamily: "Inter Regular",
      fontSize: 14,
      fontWeight: 600
    }
  },
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    width: 160,
    marginTop: "23.5px"
  },
  deleteButton: {
    // marginTop: "20px",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    color: "#E13838",
    cursor: "pointer"
  }
}));
const Investor = () => {
  const classes = useStyles();
  const [IsEditCase, setIsEditCase] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const history = useHistory();
  const { investmentID } = useSelector((store) => store.app);
  const [initialValues, setInitialValues] = useState({
    investorSet: [
      {
        isInvestorEdited: true,
        investor_name: "",
        investor_id: "",
        membership: "",
        total_invested_amount: "",
        asOn: "",
        commited_investment: "",
        preferred_return: "",
        last_date_preferred_return: "",
        date_of_investment: "",
        investment_type: "",

        taxId: "",
        entity_name: "",
        mailing_address: "",

        notes: "",

        documents: [],

        k1: [{ type: "investment", year: "", documents: [], notes: "" }]
      }
    ]
  });
  const [investmentStatus, setInvestmentStatus] = useState("Draft");
  const [docCategories, setDocCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const formatDate = (investmentdate) => {
    const date = new Date(investmentdate);
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  };
  const addInvestor = (array) => {
    array.push({
      isInvestorEdited: true,
      investor_name: "",
      membership: "",
      total_invested_amount: "",
      asOn: new Date(),
      commited_investment: "",
      preferred_return: "",
      last_date_preferred_return: "",
      investment_type: "",

      taxId: "",
      entity_name: "",
      mailing_address: "",

      notes: "",
      documents: [],
      k1: []
    });
    return true;
  };
  const handleSubmit1 = async (values) => {
    if (!values.investorSet.length) {
      return AppDispatcher.addInvestmentNextStep();
    }
    const FiletredData = values.investorSet.filter(
      (el) => el.isInvestorEdited === true
    );
    const payload = {
      investor: FiletredData?.map((investor) => ({
        ...(investor.id ? { id: investor.id } : {}),
        investor_id: investor.investor_id,
        member_percent: investor.membership,
        total_amount: investor.total_invested_amount,
        as_on: formatDate(investor.asOn),
        committed_amount: investor.commited_investment,
        prefered_return: investor.preferred_return,
        last_updated_pref: formatDate(investor.last_date_preferred_return),
        type: investor.investment_type,
        date: formatDate(investor.date_of_investment),

        entity_id: investor.entity_id,
        mailing_address: investor.mailing_address,
        notes: investor.notes,

        documents: investor?.documents?.map((doc) => ({
          document_media_id: doc.id,
          category_id: doc.type,
          ...(doc.docId ? { id: doc.docId } : {})
        })),

        manage_data_k1: investor.k1.length
          ? investor.k1.map((el) => {
              return {
                ...(el.id ? { id: el.id } : {}),
                type: "investment",
                year: el?.year,
                documents: el?.documents?.map((doc) => ({
                  document_media_id: doc.id,
                  category_id: doc.type,
                  ...(doc.docId ? { id: doc.docId } : {})
                })),
                notes: el?.notes || "-"
              };
            })
          : []
      }))
    };
    setIsLoading(true);
    const res = await AddInvestmentInvestorService.addInvestors(
      payload,
      investmentID
    );
    setIsLoading(false);
    if (res.success) {
      Toast.showSuccessToast("Investors Data Saved");
      if (isUpdate) {
        history.push("/u/investments");
        return;
      }

      AppDispatcher.addInvestmentNextStep();
    } else {
      Toast.showErrorToast(`Failed To Save Investors Data`);
    }
  };

  const saveAsDraft = async (values) => {
    if (!values.investorSet.length) {
      return history.push("/u/investments");
    }
    const investorArray = values.investorSet.map((investor) => {
      const data = {
        ...(investor.investor_id ? { investor_id: investor.investor_id } : {}),
        ...(investor.membership ? { member_percent: investor.membership } : {}),
        ...(investor.total_invested_amount
          ? { total_amount: investor.total_invested_amount }
          : {}),
        ...(investor.asOn ? { as_on: formatDate(investor.asOn) } : {}),
        ...(investor.date_of_investment
          ? { date: formatDate(investor.date_of_investment) }
          : {}),
        ...(investor.commited_investment
          ? { committed_amount: investor.commited_investment }
          : {}),
        ...(investor.preferred_return
          ? { prefered_return: investor.preferred_return }
          : {}),
        ...(investor.last_date_preferred_return
          ? {
              last_updated_pref: formatDate(investor.last_date_preferred_return)
            }
          : {}),
        ...(investor.investment_type ? { type: investor.investment_type } : {}),

        ...(investor.notes ? { notes: investor.notes } : {}),

        documents: investor?.documents?.length
          ? investor?.documents?.map((doc) => ({
              document_media_id: doc.id,
              category_id: doc.type,
              ...(doc.docId ? { id: doc.docId } : {})
            }))
          : [],

        ...(investor.k1
          ? {
              manage_data_k1: investor.k1.length
                ? investor.k1.map((el) => {
                    return {
                      type: "investment",
                      year: el?.year,
                      documents:
                        el?.documents?.length &&
                        el?.documents?.map((doc) => ({
                          document_media_id: doc.id,
                          category_id: doc.type,
                          ...(doc.docId ? { id: doc.docId } : {})
                        })),
                      notes: el?.notes || "-"
                    };
                  })
                : []
            }
          : {})
      };
      return Object.keys(data).length === 0 || !data.investor_id ? false : data;
    });
    const payload = {
      investor: investorArray.filter(Boolean),
      is_draft: true
    };
    if (payload.investor.length === 0) {
      return history.push("/u/investments");
    }
    setIsLoading(true);
    const res = await AddInvestmentInvestorService.addInvestors(
      payload,
      investmentID
    );
    setIsLoading(false);
    if (res.success) {
      Toast.showSuccessToast("Investors Data Saved");
      history.push("/u/investments");
    } else {
      Toast.showErrorToast(`Failed To Save Investors Data`);
    }
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTINVESTOR"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      size: el?.document_media?.file_size,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  const getInvestorRoleData = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;
    if (searchText) {
      params += `&search=${searchText}`;
    }
    let res = await EntityService.getInvByRole([params]);
    if (res.success) {
      return {
        data: res.data.results.map((item) => {
          return {
            id: item.id,
            name: `${item.first_name} ${item.last_name}`
          };
        }),
        maxPage: res.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  useEffect(async () => {
    setIsLoading(true);
    loadCategories();

    const Investors = await AddInvestmentPreviewService.getInvestmentDetails([
      investmentID + "?fields=investor,status"
    ]);
    if (Investors.success) {
      if (Investors.data.investors?.length) {
        let savedData = [];
        setInvestmentStatus(Investors.data?.status);
        Investors.data.investors.forEach((inv) => {
          let obj = {
            id: inv?.id,
            isInvestorEdited: false,
            invested_amount_history: inv?.invested_amount_history,
            investor_name: inv?.investor.name,
            investor_id: inv?.investor.id,
            membership: inv?.overview.member_percent,
            total_invested_amount: inv?.overview.total_amount,
            asOn: inv?.overview.as_on ? stringToDate(inv?.overview.as_on) : "-",
            commited_investment: inv?.overview.committed_amount,
            preferred_return: inv?.overview.prefered_return,
            last_date_preferred_return: inv?.overview.last_updated_pref
              ? stringToDate(inv?.overview.last_updated_pref)
              : "-",
            date_of_investment: inv?.overview.date
              ? stringToDate(inv?.overview.date)
              : new Date(),
            investment_type: inv?.overview.type,

            taxId: inv?.entity?.tax_id || "-",
            entity_name: inv?.entity?.name || "-",
            entity_id: inv?.entity?.id,
            mailing_address: inv?.entity_address?.address || "-",
            notes: inv?.overview?.notes || "-",

            documents: inv?.documents?.length
              ? formatDocsData(inv?.documents)
              : [],

            k1: inv?.manage_data?.length
              ? inv?.manage_data?.map((el) => {
                  return {
                    id: el.id,
                    type: "investment",
                    year: el?.year,
                    documents: el?.documents?.length
                      ? formatDocsData(el?.documents)
                      : [],
                    notes: el?.notes || "-"
                  };
                })
              : []
          };
          savedData.push(obj);
          setIsEditCase(true);
        });
        setInitialValues({ investorSet: savedData });
      }
    } else {
      Toast.showErrorToast(`Failed To Retrieve Saved Data`);
    }
    setIsLoading(false);
  }, []);

  return (
    <div className={classes.root}>
      {isLoading ? (
        <Loader />
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchemaInvestorForm}
          enableReinitialize={true}
          onSubmit={(values) => {
            handleSubmit1(values);
          }}>
          {(formik) => (
            <>
              <Grid xs={12} container>
                <FieldArray
                  name="investorSet"
                  render={(arrayHelper) => (
                    <>
                      {formik.values.investorSet.map((investor, index) => (
                        <InvestorForm
                          key={`investor${index}`}
                          index={index}
                          classes={classes}
                          formik={formik}
                          docCategories={docCategories}
                          getInvestorData={getInvestorRoleData}
                          IsEditCase={IsEditCase}
                          arrayHelper={arrayHelper}
                        />
                      ))}
                      <Grid xs={12}>
                        <div
                          className={classes.addMoreButton}
                          onClick={() => {
                            addInvestor(arrayHelper);
                          }}>
                          <img src={PlusAdd} alt="add" />
                          <span>Add Additional Investor</span>
                        </div>
                      </Grid>
                    </>
                  )}
                />
              </Grid>

              <Grid container className={styles.btncontainforms}>
                <Grid item xs container alignItems="center">
                  {investmentStatus === "Draft" ? (
                    <p
                      className={styles.saveAsDraftText}
                      onClick={() => saveAsDraft(formik.values)}>
                      Save As Draft
                    </p>
                  ) : (
                    <p
                      className={styles.saveAsDraftText}
                      onClick={() => history.push("/u/investments")}>
                      Cancle
                    </p>
                  )}
                  <div
                    className={styles.addInvestmentButtonContainerRectangular}
                    onClick={formik.handleSubmit}>
                    <p className={styles.addInvestmentButtonText}>Next</p>
                  </div>
                </Grid>
                {investmentStatus !== "Draft" && (
                  <Grid item>
                    <UpdateBtn
                      onClick={() => {
                        setIsUpdate(true);
                        formik.handleSubmit();
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}
        </Formik>
      )}
    </div>
  );
};
export default Investor;
