import React from "react";
import CheckboxOnImage from "../../assets/icons/CheckCheckBox.svg";
import CheckboxOffImage from "../../assets/icons/UnCheckCheckbox.svg";


const Checkbox = ({ isOn = false, style = {}, onToggle }) => {
  return (
    <div
      onClick={onToggle}
      style={{
        ...style,
      }}
      className="cursor-pointer"
    >
     <img
        src={
          !isOn
            ? CheckboxOffImage
            : CheckboxOnImage
        }
        style={{ marginRight: 14, height: 24, width: 24 }}
      />
    </div>
  );
};

export default Checkbox;