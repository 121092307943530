import { API, NetworkManager } from "./core";

export class RoleService {

  static async getRoles(params) {
    const instance = new NetworkManager(API.ROLE.GET_ROLES, {}, params);
    return await instance.httpRequest()
  }

  static async getRoleModules() {
    const instance = new NetworkManager(API.ROLE.GET_ROLE_MODULES);
    return await instance.httpRequest()
  }

  static async addRole(payload) {
    const instance = new NetworkManager(API.ROLE.ADD_ROLE, payload);
    return await instance.httpRequest()
  }

  static async getRoleById(params) {
    const instance = new NetworkManager(API.ROLE.GET_ROLE, {}, params);
    return await instance.httpRequest()
  }

  static async editRole(payload, params) {
    const instance = new NetworkManager(API.ROLE.EDIT_ROLE, payload, params);
    return await instance.httpRequest()
  }

  static async deleteRole(params) {
    const instance = new NetworkManager(API.ROLE.DELETE_ROLE, {}, params);
    return await instance.httpRequest()
  }
}