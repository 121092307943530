// import SearchInput from "@components/Common/Search";
// import Header from "@components/layout/Header";
// import Sidebar from "@components/layout/Sidebar";
// import Loader from "@components/Loader/AppLoader";
// import CustomSelectFilter from "@components/StyledComponents/customSelectFilter";
// import TableTopFilter from "@components/TableTopFilter/TableTopFilter";
// import ManageColumn from "@components/ManageColumns";
import ManageColumn from "components/ManageColumns";
// import DocumentsUpload from "@components/DocumentsUpload/documentsUpload";
import { Button, Grid, Menu, MenuItem, Tooltip } from "@material-ui/core";
// import { ManageDataService } from "@network/manageDataService";
import React, { useEffect, useState } from "react";
import styles from "../manageData.module.scss";
// import MoreFilterContainer from "@components/MoreFilterContainer/MoreFilterContainer";
// import NewTable from "@components/NewTable/NewTable";
// import { formatDate, truncate } from "@helpers/Common";
// import Illustrations from "@components/illustration/Illustration";
// import { setSession } from "@session/cookie";
// import AddPopUpManageData from "@pages/user/managedata/addK1/AddPopUpManageData";
import SearchInput from "components/Search";
import FWDEmail from "../../../../assets/icons/NewCardSvg/FWDEmail.svg";
import { ManageDataService } from "network/manageDataService";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import MoreFilterContainer from "components/MoreFilterContainer/MoreFilterContainer";
import NewTable from "components/NewTable/NewTable";
import { formatDate, truncate } from "helpers/Common";
import Illustrations from "components/illustration/Illustrations";
// import noManageData from "assets/emptyState/noManageData.svg";
import ThreeDot from "assets/icons/More.svg";
import BluefilterIcon from "assets/icons/blueFilter.svg";
import CloseIcon from "../../../../assets/icons/CrossIcon.svg";
import CSV from "../../../../assets/icons/NewCardSvg/CSV.svg";
import DotFilterIcon from "assets/icons/DotFilter.svg";
import filterIcon from "assets/icons/Filter.svg";
import { useSelector } from "react-redux";
import AddPopUpManageData from "../addData/AddPopUpManageData";
import EditPopUpManageData from "../editData/EditPopUpManageData";
import { AppDispatcher } from "../../../../redux/index";
import NewDropDown from "components/NewDropDown";
import DateRange from "components/DateRange/dateRange";
import FrwrdAsEmail from "components/ForwarsAsEmail";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
import Toast from "components/toast";
import k1EmptyState from "../../../../assets/emptyState/noDocuments.svg";
import InvestmentToolTipData from "pages/private/entities/detail/section/Investment/InvestmentToolTipData";
import LightTooltip from "components/LightTooltip";
import DeleteManageDataK1 from "../deleteData";
import DeleteDocIcon from "../../../../assets/icons/newDocImg.svg";
import { useHistory } from "react-router-dom";

const manageByList = [
  {
    label: "Managed By",
    value: "managedby"
  },
  {
    label: "Investor",
    value: "INVESTOR"
  },
  {
    label: "Admin",
    value: "ADMIN"
  }
];

const TableHeaders = [
  {
    id: "investment_name",
    name: "Investment Name"
  },
  {
    id: "property_name",
    name: "Property Name"
  },
  {
    id: "managed_by",
    name: "Managed By"
  },
  {
    id: "investor_name",
    name: "Investor Name"
  },
  {
    id: "investor_entity",
    name: "Investor Entity"
  },
  {
    id: "sponsor_name",
    name: "Sponsor Name"
  },
  {
    id: "sponsor_entity",
    name: "Sponsor Entity"
  },
  { id: "issued_on", name: "Issued On" },
  {
    id: "year",
    name: "Year"
  },
  {
    id: "documents",
    name: "Documents"
  },
  {
    id: "status",
    name: "Status"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "Pdf",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const investmentStatus = [
  {
    name: "status",
    id: "status"
  },
  {
    name: "Active",
    id: "active"
  },
  {
    name: "Inactive",
    id: "inactive"
  }
];

function K1Component({ openState, closeAddPopUp, permissions }) {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [expandIndex, setExpandIndex] = useState(-1);
  // const [expandIndexSponsor, setExpandIndexSponsor] = useState(-1);
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);
  const [handleRefresh, changeHandleRefresh] = useState(false);

  const [count, setCount] = useState(10);
  // const [open, setOpen] = useState(false);
  const [status] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [manageBy, setManageBy] = useState("managedby");
  const [isFilterActive, setFilterActive] = useState(false);
  const { openAddK1PopUp } = useSelector((store) => store.app);
  const { openEditK1PopUp } = useSelector((store) => store.app);
  const { dateRange } = useSelector((store) => store.app);
  const [deleteItem, setDeleteItem] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filterState, setFilterState] = useState([]);
  const [THeaders, setTHeaders] = useState(TableHeaders);
  const [page, setPage] = useState(1);
  const [pageResetValue, setPageResetValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const openEL = Boolean(anchorEl);
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [Export, setExport] = useState("exportList");

  useEffect(() => {}, [openAddK1PopUp]);

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = `?status=${status}`;

    if (emailData) {
      if (preview === "preview") {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `&${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (manageBy !== "managedby") {
      url += `${url.length ? "&" : "?"}managed_by=${manageBy}`;
    }

    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el?.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    url += `&page=${page}&page_size=${pageSize}`;

    setExport("exportList");
    const response = await ManageDataService.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          // link.target = "_blank";
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  const [checked, setChecked] = useState([
    { key: "Investment Name", value: [] }
  ]);
  const [pageSize, setPageSize] = useState(10);

  // const handleToggleDrawer = () => {
  //   setOpen(!open);
  // };

  const onHandleClick = () => {
    setShowFilter(!showFilter);
  };

  const handleDeleteClick = (item) => {
    setDeleteItem(item);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    changeHandleRefresh(!handleRefresh);
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    AppDispatcher.setManageDataK1Id(item);
  };

  const getInvestmentStatus = async () => {
    return {
      data: investmentStatus,
      maxPage: 0
    };
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }
    const response = await ManageDataService.getInvestmentNameData([
      `${params}`
    ]);
    if (response.success) {
      return {
        data: response.data,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getManageDataYear = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }

    const response = await ManageDataService.getManageDataYears([`${params}`]);
    if (response.success) {
      const year = [];
      response?.data?.results.map((values) => {
        year.push({ name: values.year, id: values.year });
      });

      return {
        data: year,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getInvestorName = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;
    params += `&fields=id,name`;
    if (searchText) {
      params += `&search=${searchText}`;
    }

    const response = await ManageDataService.getInvestorName([`${params}`]);
    if (response.success) {
      return {
        data: response?.data?.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getInvestorEntityName = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&entity_type=INVESTOR`;

    const response = await ManageDataService.getInvestorEntityName([
      `${params}`
    ]);
    if (response.success) {
      return {
        data: response?.data?.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponsorName = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }

    params += `&fields=id,name`;

    const response = await ManageDataService.getSponsorNameList([`${params}`]);
    if (response.success) {
      return {
        data: response?.data?.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponsorEntityName = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }

    params += `&entity_type=SPONSOR`;
    const response = await ManageDataService.getSponsorEntityList([
      `${params}`
    ]);
    if (response.success) {
      return {
        data: response?.data?.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getPropertyName = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }

    const response = await ManageDataService.getPropertyNameList([`${params}`]);
    if (response.success) {
      return {
        data: response?.data,
        maxPage: response?.data?.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const mappingObject = {
    "Investment Name": "investment_id",
    "Sponsor Name": "sponsor_id",
    "Property Name": "property_id",
    Year: "year",
    "Investor Name": "investor_id",
    "Investor Entity": "investor_entity_id",
    "Investment Status": "investment_status",
    "Sponsor Entity": "sponsor_entity_id"
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
    let chipsArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        chipsArray.push(newObj);
      });
    });
    !chipsArray.length && setFilterActive(false);
  };

  const onDeletedate = () => {
    AppDispatcher.setDateRange({});
  };

  useEffect(() => {
    setFilterState([
      {
        key: "Investment Name",
        value: getInvestmentName
      },
      {
        key: "Investment Status",
        value: getInvestmentStatus
      },
      {
        key: "Year",
        value: getManageDataYear
      },
      {
        key: "Investor Name",
        value: getInvestorName
      },
      {
        key: "Investor Entity",
        value: getInvestorEntityName
      },
      {
        key: "Sponsor Name",
        value: getSponsorName
      },

      {
        key: "Sponsor Entity",
        value: getSponsorEntityName
      },
      {
        key: "Property Name",
        value: getPropertyName
      }
      // {
      //   key : "Date Range",

      //   value : getManageDataYear,
      // }
    ]);
  }, []);

  const handleCellClick = (e, type, id) => {
    if (id) {
      switch (type) {
        case "investment_id":
          AppDispatcher.setInvestmentDetailID(id);
          history.push("/u/investments/detail");
          break;

        case "investor_entity_id":
          AppDispatcher.setEntitiesDetailData({
            id: id,
            entity_type: "INVESTOR"
          });
          history.push("/u/entities/detail");
          break;
        case "property_id":
          AppDispatcher.setPropertiesDetailID(id);
          history.push("/u/properties/detail");
          break;
        case "investor_id":
          AppDispatcher.setInvestorDetailID(id);
          history.push("/u/investors/detail");
          break;
        case "sponsor":
          AppDispatcher.setSponsorDetailID(id);
          history.push("/u/sponsors/detail");
          break;
        case "sponsor_entity_id":
          AppDispatcher.setEntitiesDetailData({
            id: id,
            entity_type: "SPONSOR"
          });
          history.push("/u/entities/detail");
          break;
        default:
          return;
      }
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  let sponsor_entity_id = "";

  const sponsorEntityFunction = (item) => {
    let objVal = {};
    let objValId = {};

    let count = 0;
    item.map((value) => {
      if (value?.entity_name != "") {
        objVal[count] = value?.entity_name;
        objValId[count] = value?.entity_id;
        count++;
      }
    });

    sponsor_entity_id = objValId?.[0];
    return objVal?.["0"];
  };

  const handleCloseAnchor = () => {
    setAnchorEl(null);
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const handleApply = (data) => {
    setShowFilter(false);
    setChecked([...data]);
  };

  const handleClear = () => {
    setShowFilter(false);
    setChecked([]);
  };

  const getContactsData = async (pageNum, isAppend = false, csv) => {
    setLoading(true);
    let url = `?status=${status}`;
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (manageBy !== "managedby") {
      url += `${url.length ? "&" : "?"}managed_by=${manageBy}`;
    }

    if (Object.keys(dateRange).length > 0) {
      url += "&custom=true";
      url += `&start_date=${dateRange["start_date"]}&end_date=${dateRange["end_date"]}`;
    }

    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el?.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    url += `&page=${pageNum}&page_size=${pageSize}`;
    if (csv) {
      url += `&csv-download=${csv}`;
    }
    const response = await ManageDataService.getManageData([url]);
    if (response.success) {
      if (csv) {
        const element = document.createElement("a");
        element.setAttribute("href", response.data.url);
        element.setAttribute("download", "document");
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      } else {
        if (isAppend) {
          setData([...data, ...response.data.results]);
        } else {
          setData(response.data.results);
        }
      }
      setCount(response.data.count);
    }
    setLoading(false);
  };

  function FilterStatus(checked) {
    const data = checked.filter((obj) => obj.value.length !== 0);
    if (data.length) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    getContactsData(1);
    setFilterActive(FilterStatus(checked));
    setPageResetValue(0);
  }, [
    checked,
    openState,
    openEditK1PopUp,
    searchQuery,
    manageBy,
    dateRange,
    handleRefresh,
    pageSize
  ]);

  const ChipsComponent = () => {
    let chipsArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        chipsArray.push(newObj);
      });
    });
    return (
      <div className={styles.chipsCtr}>
        {chipsArray.length > 0
          ? chipsArray.map((item, index) => {
              return (
                <div key={index} className={styles.chips}>
                  <div className={styles.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                      width={12}
                      height={10}
                    />
                  </div>
                </div>
              );
            })
          : null}
        {dateRange["start_date"] && dateRange["end_date"] ? (
          <div className={styles.chips}>
            <div
              className={
                styles.chipsText
              }>{`${dateRange["start_date"]} To ${dateRange["end_date"]}`}</div>
            <div onClick={() => onDeletedate()}>
              <img
                className={styles.chipsImg}
                src={CloseIcon}
                alt="cross"
                width={10}
                height={10}
              />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getContactsData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const Tabledatalist = data.map((item, index) => {
    return [
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="investment_name">
        <div
          className={`${styles.text} ${styles.clickable} ${styles.onHover}`}
          onClick={(e) =>
            handleCellClick(
              e,
              "investment_id",
              item?.investmentinvestor?.investment
            )
          }>
          <div>{item?.investor_data?.investment_name || "-"}</div>
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="property_name">
        <div
          onClick={(e) => handleCellClick(e, "property_id", item?.property?.id)}
          key="property_name"
          className={`${styles.text} `}>
          {item?.property?.name || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="managed_by">
        <div key="managed_by" className={`${styles.text}  ${styles.onHover}`}>
          {item?.managed_by || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="investor_name">
        <div
          key="investor_name"
          className={`${styles.text}  ${styles.onHover}`}
          onClick={(e) =>
            handleCellClick(
              e,
              "investor_id",
              item?.investmentinvestor?.investor
            )
          }>
          {item?.investor_data?.investor_name || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="investor_entity">
        <div
          onClick={(e) =>
            handleCellClick(
              e,
              "investor_entity_id",
              item?.investmentinvestor?.entity
            )
          }
          className={`${styles.text}  ${styles.onHover}`}>
          {item?.investor_data?.investor_entity_name || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="sponsor_name">
        <div className={styles.text} style={{ cursor: "pointer" }}>
          {item?.sponsor_data?.length ? (
            <NewDropDown
              index={index}
              item={item?.sponsor_data?.map((item) => {
                return {
                  id: item?.sponsor_id,
                  name: truncate(item?.sponsor_name, 16) || "-"
                };
              })}
              handleCellItemClick={handleCellClick}
              type="sponsor"
              expandIndex={expandIndex}
              setExpandIndex={setExpandIndex}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="sponsor_entity">
        <div
          onClick={(e) =>
            handleCellClick(e, "sponsor_entity_id", sponsor_entity_id)
          }
          className={styles.text}
          style={{ cursor: "pointer" }}>
          {item?.sponsor_data?.length ? (
            sponsorEntityFunction(item?.sponsor_data)
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="issued_on">
        <div className={`${styles.text} }`} style={{ whiteSpace: "nowrap" }}>
          {formatDate(item?.created_at) || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by_name]]}
          />
        }
        arrow
        key="year">
        <div className={`${styles.displayFlex} `}>
          <div className={styles.text}>{item?.year || "-"}</div>
        </div>
      </LightTooltip>,
      <div key="documents">
        <div>
          {/* {item?.documents?.length ? (
          <a
            download
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => download(item?.documents[0]?.document_media?.file)}
          >
            <img
              src="/assets/icons/svgs/dwnDoc.svg"
              alt="alt"
              height="40px"
            />
          </a>
        ) : (
          <div></div>
        )} */}
          {item?.documents?.length ? (
            <DocumentsUpload
              editable={false}
              listData={formatDocsData(item?.documents)}
            />
          ) : (
            <img src={DeleteDocIcon} alt="Delete Document" />
          )}
        </div>
      </div>,

      <div
        key="status"
        style={{
          minHeight: 28,
          width: 72,
          alignItems: "center",
          backgroundColor:
            item?.investor_data?.investment_status === "Draft"
              ? "#EBEFFF"
              : item?.investor_data?.investment_status == "Active"
              ? "#EDFFEF"
              : "#FCEBEB",
          borderRadius: 153,
          textAlign: "center",
          fontFamily: "Inter Bold",
          fontSize: 14,
          paddingTop: 4,
          color:
            item?.investor_data?.investment_status == "Active"
              ? "#50E138"
              : item?.investor_data?.investment_status === "Draft"
              ? "#445EBE"
              : "#E13838",
          fontWeight: 700,
          margin: "auto"
        }}>
        {item?.investor_data?.investment_status || "-"}
      </div>,

      // dwnDoc.svg

      <div key="Action" className={styles.text}>
        <Button
          id="basic-button"
          aria-controls="basic-menu"
          aria-haspopup="true"
          aria-expanded={openEL ? "true" : undefined}
          onClick={(e) => handleClick(e, item)}>
          <img src={ThreeDot} alt="three_dot_menu" width={24} height={24} />
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openEL}
          onClose={handleCloseAnchor}
          PaperProps={{
            style: {
              marginTop: 40,
              boxShadow: "2px 2px 4px #E9E9E9"
            }
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}>
          {permissions?.is_update ? (
            <MenuItem className={styles.menuItem}>
              <div
                //  onClick={() => goToEditDistributions(item?.id)}
                onClick={() => {
                  AppDispatcher.openK1Edit(true);
                  handleCloseAnchor();
                }}>
                {" "}
                Edit
              </div>
            </MenuItem>
          ) : null}

          {permissions?.is_delete ? (
            <MenuItem
              style={{ color: "#E13838" }}
              className={styles.menuItemDelete}
              onClick={() => {
                handleCloseAnchor();
                handleDeleteClick(item);
              }}>
              Delete
            </MenuItem>
          ) : null}
        </Menu>
      </div>
    ];
  });

  return (
    <>
      {/* {loading && <Loader />} */}
      {openState && <AddPopUpManageData closeAddPopUp={closeAddPopUp} />}
      {openEditK1PopUp && <EditPopUpManageData />}
      {openDelete ? (
        <DeleteManageDataK1
          item={deleteItem}
          open={openDelete}
          handleClose={handleCloseDelete}
          getPropertyList={getContactsData}
          setAnchorEl={setAnchorEl}
        />
      ) : null}
      {/* <Sidebar open={open} handleToggleDrawer={handleToggleDrawer} /> */}

      {/* <Header
        handleToggleDrawer={handleToggleDrawer}
        addBtn={"Add K1"}
        addPath="/user/managedata/addK1"
      /> */}
      <Grid container>
        <Grid item xs={12}>
          <FrwrdAsEmail
            AllHeaders={TableHeaders}
            FrdasEmail={FrdasEmail}
            setFrdasEmail={setFrdasEmail}
            open={frdEmailOpen}
            setOpen={setFrdasEmailOpen}
            clickOnShare={(payload) => {
              getExport_PDF_CSV_Link(payload);
            }}
            clickOnPreview={(payload) => {
              getExport_PDF_CSV_Link(payload, "preview");
            }}
          />
          <TableTopFilter
            tabletViewCSV={
              <div className={styles.clickable}>
                <TabletVIewSelectMenu
                  image={
                    <img
                      src={CSV}
                      alt="doc"
                      layout="fixed"
                      height={48}
                      width={48}
                    />
                  }
                  menuItems={ExportList}
                  clickMenuItem={(obj, close) => {
                    setExport(obj?.value);
                    close();
                  }}
                />
              </div>
            }
            tabletViewFWDEmail={
              <div className={styles.clickable}>
                <TabletVIewSelectMenu
                  image={
                    <img
                      src={FWDEmail}
                      alt="doc"
                      layout="fixed"
                      height={48}
                      width={48}
                    />
                  }
                  menuItems={frdasEamilList}
                  clickMenuItem={(obj, close) => {
                    setFrdasEmail(obj?.value);
                    close();
                  }}
                />
              </div>
            }
            filterSection1={[
              <SearchInput setQuery={setQuery} key={0} />,
              <CustomSelectFilter
                key="1"
                border={true}
                placeholder="Managed By"
                items={manageByList}
                value={manageBy}
                onChange={(e) => setManageBy(e.target.value)}
              />,
              <DateRange key={2} />,
              <Tooltip title="More Filters" arrow>
                <div className={styles.clickable} onClick={onHandleClick}>
                  <img
                    src={
                      isFilterActive
                        ? DotFilterIcon
                        : showFilter
                        ? BluefilterIcon
                        : filterIcon
                    }
                    alt="alt"
                    layout="fixed"
                    width={45}
                    height={45}
                  />
                </div>
              </Tooltip>,
              // ...........................................................
              showFilter && (
                <>
                  <MoreFilterContainer
                    key={3}
                    open={showFilter}
                    checked={checked}
                    handleClose={onHandleClick}
                    handleApply={handleApply}
                    handleClear={handleClear}
                    filters={filterState}
                  />
                </>
              )
            ]}
            filterSection2={[
              <ManageColumn
                AllHeaders={TableHeaders}
                setTableHeaders={setTHeaders}
                key={0}
              />,
              <CustomSelectFilter
                border={true}
                key="1"
                placeholder="Forward as Email"
                items={frdasEamilList}
                value={FrdasEmail}
                onChange={(e) => setFrdasEmail(e.target.value)}
              />,
              <CustomSelectFilter
                key="2"
                border={true}
                placeholder="Export"
                items={ExportList}
                value={Export}
                onChange={(e) => setExport(e.target.value)}
              />
              //   <CustomSelectFilter
              //     border={true}
              //     key="2"
              //     placeholder="Forward as Email"
              //     items={frdasEamilList}
              //     value={FrdasEmail}
              //     onChange={(e) => setFrdasEmail(e.target.value)}
              //   />,
              //   <CustomSelectFilter
              //     key="3"
              //     border={true}
              //     placeholder="Export"
              //     items={ExportList}
              //     value={Export}
              //     onChange={(e) => setExport(e.target.value)}
              //   />,
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <ChipsComponent />

          <NewTable
            loading={loading}
            tableData={Tabledatalist}
            paginationLabel="Total Investments Per Page"
            ChipsComponent={ChipsComponent}
            pageSize={pageSize}
            changePage={changePage}
            changePageSize={changePageSize}
            labelRowsPerPage="Total No Of K1 Per Page"
            count={count}
            pageResetValue={pageResetValue}
            Header={[
              ...THeaders,
              (permissions?.is_delete || permissions?.is_update) && {
                id: "Action",
                name: "Action"
              }
            ]}
            Illustration={
              <Illustrations
                src={k1EmptyState}
                heading="No K1 Found"
                subHeading="Add k1 Data To View"
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
}

export default K1Component;
