import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import Table from "./components/table/Table";
import { InvestmentListService } from "../../../network/investment_ListService";
import InvestorCards from "../../../components/Cards/InvestorCards";
import TotalAmountInvestedIcon from "../../../assets/icons/NewCardSvg/voiletDollar.svg";
import TotalEntities from "../../../assets/icons/NewCardSvg/pinkHome.svg";
import TotalInvestor from "../../../assets/icons/NewCardSvg/pinkPerson.svg";
import InvestmentIcon from "../../../assets/icons/NewCardSvg/greenDollar.svg";
import styles1 from "./investments.module.css";
import { numFormatter } from "helpers";
import { useSelector } from "react-redux";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "../../../redux/index";
import { Skeleton } from "@material-ui/lab";

const containerStyle = { marginTop: 28 };

const Investments = () => {
  const [statsData, setStatsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState();
  const { userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();

  const getStatsData = async () => {
    setLoading(true);
    const response = await InvestmentListService.getInvestmentStats();
    if (response.success) {
      setLoading(false);
      setStatsData(response.data);
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "INVESTMENTS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  const addInvestment = () => {
    AppDispatcher.resetAddInvestmentStep();
    AppDispatcher.setInvestmentID(null);
    history.push({ pathname: "/u/investments/addInvestment" });
  };

  useEffect(() => {
    checkPermissions();
    getStatsData();
  }, []);

  const skeleton = (values) => {
    return !loading ? values : <Skeleton width={150} variant="text" /> || "-";
  };

  return (
    <>
      <HeaderComponent
        heading="Investments"
        description="Add Or Update Your Recent Investments"
        ExtraButtons={
          permissions?.is_create ? (
            <div className={styles1.addInvestmentButtonContainer}>
              <p
                className={styles1.addInvestmentButtonText}
                onClick={addInvestment}>
                + Add Investment
              </p>
            </div>
          ) : null
        }
      />
      <Grid container style={containerStyle}>
        <Grid item lg={2} xs={4}>
          <InvestorCards
            Title="Total Investors"
            link="/u/investors"
            count={skeleton(statsData && statsData.total_investor) || 0}
            icon={TotalInvestor}
          />
        </Grid>
        <Grid item lg={2} xs={4}>
          <InvestorCards
            Title="Total Investments"
            count={skeleton(statsData && statsData.total_investment) || 0}
            icon={InvestmentIcon}
          />
        </Grid>
        <Grid item lg={2} xs={4}>
          <InvestorCards
            Title="Total Entities"
            link="/u/entities"
            count={skeleton(statsData && statsData.total_entity) || 0}
            icon={TotalEntities}
          />
        </Grid>
        <Grid item lg={2} xs={4}>
          <InvestorCards
            Title="Total Invested Amount"
            count={
              skeleton(
                statsData &&
                  `$${numFormatter(statsData.total_investment_amount || 0)}`
              ) || `$0`
            }
            icon={TotalAmountInvestedIcon}
          />
        </Grid>
      </Grid>
      <Table permissions={permissions} />
    </>
  );
};

export default Investments;
