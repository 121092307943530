import React from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@material-ui/core";

import blueCloseIcon from "assets/icons/NewCardSvg/blueCloseIcon.svg";
import formatDate from "helpers/formatDate";
import formatCurrency from "helpers/formatCurrency";

const useStyles = makeStyles(() => ({
  root: {
    "&  .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 590,
      height: "fit-content"
    }
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 48.5,
    boxSizing: "border-box"
  },
  heading: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "Inter Bold"
  },
  btn: {
    cursor: "pointer",
    margin: "40px 0px",
    width: "174px",
    height: "50px",
    borderRadius: "4px",
    border: "none",
    background:
      "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)",
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    color: "#FFFFFF"
  },
  Tbody: {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#191919",
    borderBottom: "none"
  },
  THead: {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#191919"
  }
}));

const ViewHistoryPopup = ({ isopen, setIsOpen, history }) => {
  const classes = useStyles();

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={isopen}
      onClose={handleClose}
      style={{
        backgroundColor: "rgba(235, 239, 255, 0.6)",
        backdropFilter: "blur(26)"
      }}>
      <DialogContent>
        <div className={classes.MaterialForm}>
          <div className={classes.header}>
            <div>
              <Typography variant="h4" className={classes.heading}>
                Invested Amount History
              </Typography>
            </div>
            <div className={classes.closeButton}>
              <img height="48px" src={blueCloseIcon} onClick={handleClose} />
            </div>
          </div>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.THead}>Date</TableCell>
                  <TableCell className={classes.THead}>
                    Invested Amount
                  </TableCell>
                  <TableCell className={classes.THead}>As On</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {history?.length !== 0 ? (
                  history?.map((ele) => (
                    <TableRow>
                      <TableCell className={classes.Tbody}>
                        {ele?.created_at ? formatDate(ele?.created_at) : "-"}
                      </TableCell>
                      <TableCell className={classes.Tbody}>
                        {ele?.invested_amount
                          ? formatCurrency(ele?.invested_amount)
                          : "-"}
                      </TableCell>
                      <TableCell className={classes.Tbody}>
                        {ele?.as_on ? formatDate(ele?.as_on) : "-"}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={3} style={{ textAlign: "center" }}>
                      No History Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <button className={classes.btn} onClick={handleClose}>
              Okay
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewHistoryPopup;
