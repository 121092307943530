// const { TextareaAutosize, makeStyles } = require("@material-ui/core");
import React from "react";
import { makeStyles, TextareaAutosize } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  textArea: {
    height: "auto",
    width: "100%",
    border: "1px solid #BBB",
    borderRadius: "8px",
    padding: "16px",
    fontFamily: "Inter Regular",
    fontSize: "16px",
    fontWeight: 600,
    "&:disabled": {
      background: "#E9E9E9"
    }
  }
}));

const TextArea = ({
  disabled,
  placeholder,
  value,
  onChange,
  id,
  minRows = 2
}) => {
  const classes = useStyles();
  return (
    <TextareaAutosize
      id={id}
      disabled={disabled}
      minRows={minRows}
      maxLength={100}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      className={classes.textArea}
    />
  );
};

export default TextArea;
