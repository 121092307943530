import {
  createTheme,
  Grid,
  makeStyles,
  ThemeProvider
} from "@material-ui/core";
import React from "react";
import MUIDataTable from "mui-datatables";
import styles from "components/AppTable/AppTable.module.css";
import CheckCheckBox from "assets/icons/CheckCheckBox.svg";
import UnCheckCheckBox from "assets/icons/UnCheckCheckbox.svg";
import TextDocument from "./TextDocument";
import formatCurrency from "helpers/formatCurrency";

const theme = createTheme({
  overrides: {
    MuiTableRow: {
      root: {
        cursor: "default"
        // borderBottom: "1px solid red"
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: "0px"
      },
      head: {
        background: "#ffffff",
        padding: "16px 18px"
      }
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start"
      }
    },
    MuiTableFooter: {
      root: {
        background: "#fafafa"
      }
    },
    MUIDataTableHeadCell: {
      sortLabelRoot: {
        height: undefined
      }
    }
  }
});

const useStyles = makeStyles(() => ({
  main: {
    width: `100vw`,
    marginLeft: 8,
    overflowX: "auto",
    "& div:first-child": {
      minWidth: "100%"
    }
  },
  headLabel: {
    cursor: "default",
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexShrink: 0
  },
  investorNameContainer: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& img": {
      cursor: "pointer",
      margin: "0 2px 0 8px"
    }
  }
}));

const DynamicTable = ({
  selectedTimeFrame,
  formik,
  index,
  data,
  cadenceType,
  invDocCategories
}) => {
  const classes = useStyles();

  const getColumns = () => [
    {
      name: "investor_name",
      label: "Investor Name",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={classes.headLabel} style={{ minWidth: 140 }}>
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    {
      name: "entity_name",
      label: "Entity Name",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={classes.headLabel}>
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    {
      name: "invested_amount",
      label: "Invested Amount",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={classes.headLabel}>
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    {
      name: "membership",
      label: "Membership",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={classes.headLabel}>
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    {
      name: "preferred_return",
      label: "Preferred Return",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={classes.headLabel}>
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    ...selectedTimeFrame
      .filter((el) => el)
      .map((time) => ({
        name: time.value,
        label: time.label,
        options: {
          customHeadLabelRender: (columnMeta) => (
            <div className={classes.headLabel}>
              <div className={styles.headingText}> {columnMeta.label}</div>
            </div>
          )
        }
      }))
  ];

  const getOptions = () => ({
    draggableColumns: {
      enabled: false
    },
    pagination: false,
    textLabels: {
      body: {
        noMatch: (
          <div
            style={{
              fontSize: 16,
              fontFamily: "Inter Regular",
              color: "#191919"
            }}>
            No Investors Found
          </div>
        )
      }
    },
    filter: false,
    responsive: "standard",
    download: false,
    sort: false,
    selectableRows: false,
    print: false,
    viewColumns: false,
    searchOpen: false,
    search: false,
    customSearchRender: () => null,
    fixedHeader: false,
    elevation: 0
  });

  const handleFieldValueChange = (invIndex, element, value) => {
    formik.setFieldValue(
      `distributionSet[${index}].investors[${invIndex}].value[${element.value}]`,
      value
    );
    let sum = 0;
    let investors = formik?.values?.distributionSet[index]?.investors || [];
    investors[invIndex].value[element.value] = value;
    investors.forEach((item) => {
      if (item.isSelected) {
        sum += Number(item.value[element.value]);
      }
    });
    let totalSum =
      formik?.values?.distributionSet[index]?.investorSum[element.value];
    totalSum = sum - Number(totalSum);
    formik.setFieldValue(
      `distributionSet[${index}].amountSum`,
      Number(formik?.values?.distributionSet[index]?.amountSum || 0) + totalSum
    );
    formik.setFieldValue(
      `distributionSet[${index}].totalDistribution`,
      Number(formik?.values?.distributionSet[index]?.amountSum || 0) + totalSum
    );
    formik.setFieldValue(
      `distributionSet[${index}].investorSum[${element.value}]`,
      sum
    );
  };

  const handleDocChange = (invIndex, element, list, type) => {
    formik.setFieldValue(
      `distributionSet[${index}].investors[${invIndex}].docs[${element.value}]`,
      list
    );
    formik.setFieldValue(
      `distributionSet[${index}].investors[${invIndex}].type[${element.value}]`,
      type
    );
  };

  const getDynamicFields = (invIndex, item) => {
    let data = {};
    const getDistData = () => ({
      investor: item.investor_name,
      entity: item.entity_name,
      year: formik?.values?.distributionSet[index]?.year,
      cadenceType: cadenceType
    });
    selectedTimeFrame
      .filter((el) => el)
      .forEach((element) => {
        data[element.value] = (
          <TextDocument
            invDocCategories={invDocCategories}
            getDistData={getDistData}
            cadence={element.label}
            docUploaded={
              formik?.values?.distributionSet[index]?.investors[invIndex]?.docs[
                element.value
              ]?.length > 0
            }
            value={
              formik?.values?.distributionSet[index]?.investors[invIndex]
                ?.value[element.value] || ""
            }
            onDocChange={(list, type) =>
              handleDocChange(invIndex, element, list, type)
            }
            onChange={(value) =>
              handleFieldValueChange(invIndex, element, value)
            }
          />
        );
      });
    return data;
  };

  const handleInvestorSelect = (invIndex, id) => {
    let investors = formik?.values?.distributionSet[index]?.investors;
    const isSelected = investors[invIndex]?.isSelected;
    let amountSum = formik?.values?.distributionSet[index]?.amountSum;
    let investorSum = formik?.values?.distributionSet[index]?.investorSum;
    selectedTimeFrame
      .filter((el) => el)
      .forEach((time) => {
        let value = Number(investors[invIndex].value[time.value]);
        if (isSelected) {
          investorSum[time.value] = Number(investorSum[time.value]) - value;
          amountSum = Number(amountSum) - value;
        } else {
          investorSum[time.value] = Number(investorSum[time.value]) + value;
          amountSum = Number(amountSum) + value;
        }
      });
    formik.setFieldValue(`distributionSet[${index}]investorSum`, investorSum);
    formik.setFieldValue(`distributionSet[${index}]amountSum`, amountSum);
    if (isSelected) {
      formik.setFieldValue(
        `distributionSet[${index}]investorsSelected`,
        formik?.values?.distributionSet[index]?.investorsSelected - 1
      );
      formik.setFieldValue(
        `distributionSet[${index}].investors[${invIndex}].isSelected`,
        false
      );
    } else {
      formik.setFieldValue(
        `distributionSet[${index}]investorsSelected`,
        formik?.values?.distributionSet[index]?.investorsSelected + 1
      );
      formik.setFieldValue(
        `distributionSet[${index}].investors[${invIndex}].isSelected`,
        id
      );
    }
  };

  const getTotalSum = () => {
    let data = {};
    selectedTimeFrame
      .filter((el) => el)
      .forEach((element) => {
        data[element.value] = (
          <div className={styles.text}>
            {formatCurrency(
              formik?.values?.distributionSet[index]?.investorSum[element.value]
            )}
          </div>
        );
      });
    return data;
  };

  const getData = () => {
    let rows = data.map((item, invIndex) => {
      return {
        investor_name: (
          <div
            className={classes.investorNameContainer}
            onClick={() => handleInvestorSelect(invIndex, item.investorId)}>
            <img
              width="16px"
              src={
                formik?.values?.distributionSet[index]?.investors[invIndex]
                  ?.isSelected
                  ? CheckCheckBox
                  : UnCheckCheckBox
              }
            />
            <div className={styles.text}>{item?.investor_name || "-"}</div>
          </div>
        ),
        entity_name: (
          <div style={{ whiteSpace: "nowrap" }}>{item?.entity_name || "-"}</div>
        ),
        invested_amount: (
          <div className={styles.text}>{item?.invested_amount || "-"}</div>
        ),
        membership: (
          <div className={styles.text}>{item?.membership || "-"}</div>
        ),
        preferred_return: (
          <div className={styles.text}>{item?.preferred_return || "-"}</div>
        ),
        ...getDynamicFields(invIndex, item)
      };
    });
    rows.push({
      investor_name: <div className={styles.text}>{"Total Distributions"}</div>,
      ...getTotalSum()
    });
    return rows;
  };

  return (
    <Grid container className={classes.main}>
      <ThemeProvider theme={theme}>
        <MUIDataTable
          columns={getColumns()}
          options={getOptions()}
          data={getData()}
        />
      </ThemeProvider>
    </Grid>
  );
};

export default DynamicTable;
