import { CircularProgress, Grid, makeStyles } from "@material-ui/core";
import React, { useRef, useState } from "react";
import style from "../../../manage_data.module.scss";
import FileUploadIcon from "assets/icons/FileUpload.svg";
import { CommonService } from "network/commonService";
import { useFormik } from "formik";
import Toast from "components/toast";
import CrossIcon from "assets/icons/CrossBlack.svg";
import styles from "../../../manage_data.module.scss";
import FieldHeading from "components/fieldHeading";
import DescriptionBox from "components/StyledComponents/DescriptionBox";
import { ManageDataService } from "network/manageDataService";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "../../../../../../redux/index";
import VerificationProgress from "./verificationProgress";
import VerificationError from "./verifyError";
import { validationSchemaUploadTemplate } from "helpers/validationSchema";
import { allowOnlyXlsFileType } from "helpers/Common";

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: "rgba(235, 239, 255, 0.6)",
    backdropFilter: "blur(26)"
  },
  dialogContent: {
    width: 458,
    padding: 50,
    boxSizing: "border-box"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  header: {
    margin: "30px 0 40px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  title: {
    fontFamily: "Inter Bold",
    fontSize: 28
  },
  closeIcon: {
    cursor: "pointer"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    marginTop: 10
  },
  btnContainer: {
    marginTop: 40,
    display: "flex"
  },
  deleteBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      cursor: "pointer"
    }
  },
  uploadDocs: {
    backgroundColor: "#E5E9ED",
    padding: 10,
    marginTop: 20,
    width: "100%"
  },
  docName: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    marginLeft: 20,
    maxWidth: 200,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  imgContainer: {
    display: "flex",
    alignItems: "center",
    borderRadius: 4
  },
  error: {
    color: "#E13838",
    fontSize: "0.75rem",
    marginTop: 3
  },
  uploadbtnContainer: {
    maxWidth: "320px",
    width: "320px"
  },
  uploadButton: {
    minWidth: "50%",
    display: "flex",
    justifyContent: "end",
    marginRight: 40
  },
  skipButton: {
    minWidth: "50%",
    display: "flex",
    marginTop: 8,
    justifyContent: "center",
    fontSize: 18,
    fontFamily: "Inter Regular",
    fontWeight: 600,
    color: "#445EBE",
    cursor: "pointer"
  }
}));

const Step2 = ({ setOpen }) => {
  const wrapperRef = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const [imageList, setImageList] = useState([]);
  const [invDocCategories] = useState();
  const classes = useStyles();
  const [docsLoading, setDocsLoading] = useState(false);
  const [uploadState, setUploadState] = useState("Upload");
  const [errorList, setErrorList] = useState();
  const history = useHistory();

  const [initialValue] = useState({
    notes: "",
    documents: []
  });

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    validationSchema: validationSchemaUploadTemplate,
    onSubmit: async (values) => {
      setUploadState("Progress");
      let payload = {
        name: values?.documents[0]?.name,
        comments: values.notes,
        template_media_id: values?.documents[0]?.id,
        excel: false
      };
      let res = await ManageDataService.templateUpload(payload);
      if (res.success) {
        AppDispatcher.setUploadTemplateStep(2);
      } else {
        setUploadState("Failed");
        let sheetNameList = [];
        let errorData = [];
        res.error.message.map((item) =>
          sheetNameList.push(Object.keys(item)[0])
        );
        res.error.message.map((item, index) =>
          item[sheetNameList[index]].forEach((el) =>
            errorData.push({
              sheetName: sheetNameList[index],
              msg: el[0],
              column: el[1],
              row: el[2]
            })
          )
        );
        setErrorList(errorData);
      }
    }
  });

  const onFileDrop = (e) => {
    if (!allowOnlyXlsFileType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      resp.data.type = invDocCategories;
      resp.data.link = resp.data.file;
      const updatedFileList = [...imageList, resp.data];
      formik.setFieldValue("documents", updatedFileList);
      setImageList(updatedFileList);
      Toast.showSuccessToast("Documents Upload SuccessFully");
      setDocsLoading(false);
    } else {
      Toast.showErrorToast("Failed To Uploaded Documents");
    }
  };

  const deleteDoc = async (id) => {
    const resp = await CommonService.deleteMedia(id);
    const updateDocList = formik.values.documents.filter(
      (item) => item.id !== id
    );

    if (resp.success) {
      formik.setFieldValue("documents", updateDocList);
      Toast.showSuccessToast("Documents Deleted Successfully");
      setImageList((prev) => {
        return prev.filter((item) => item.id != id);
      });
    } else {
      Toast.showErrorToast("Failed to Delete Document");
    }
  };

  return (
    <>
      {uploadState === "Progress" ? <VerificationProgress /> : null}
      {uploadState === "Upload" ? (
        <>
          <div className={style.downloadContainer}>
            <div className={style.downloadTitleConatiner}>
              <div className={style.title}>Upload Dataset</div>
              <div>Please Note: Upload Excel (.csv) File Only</div>
            </div>
            <div className="downloadMsgConatiner"></div>
          </div>
          <div className={style.uploadTemplateContainer}>
            <div className={classes.uploadbtnContainer}>
              <FieldHeading title="Attach Files" />
              <label
                htmlFor="upload"
                style={{ width: "100%", marginTop: 10 }}
                ref={wrapperRef}
                className={styles.drop_file_input}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}>
                <>
                  {docsLoading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <p className={styles.droptext}>
                        Drag And Drop Files Here Or{" "}
                        <span style={{ color: "#445EBE" }}>Browse</span>
                      </p>
                      <input
                        id="upload"
                        style={{ width: 0 }}
                        type="file"
                        value=""
                        onChange={onFileDrop}
                      />
                    </>
                  )}
                </>
              </label>
              {formik.errors.documents && formik.touched.documents && (
                <div className={classes.error}>{formik.errors.documents}</div>
              )}
              <Grid container>
                {imageList.length > 0 ? (
                  <>
                    {imageList.map((item) => (
                      <Grid
                        key={item.name}
                        container
                        className={classes.uploadDocs}
                        direction="row">
                        <Grid item xs={10} className={classes.imgContainer}>
                          {item.name.endsWith("png") ||
                          item.name.endsWith("jpeg") ||
                          item.name.endsWith("jpg") ? (
                            <img
                              src={item.link}
                              style={{
                                height: 54,
                                width: 54,
                                borderRadius: 10
                              }}
                            />
                          ) : (
                            <img src={FileUploadIcon} />
                          )}
                          <div className={classes.docName}>{item.name}</div>
                        </Grid>
                        <Grid item xs={2} className={classes.deleteBtn}>
                          <img
                            onClick={() => deleteDoc(item.id)}
                            src={CrossIcon}
                            alt="Delete"
                          />
                        </Grid>
                      </Grid>
                    ))}
                  </>
                ) : null}
              </Grid>
            </div>
            <Grid item xs={12} style={{ flexBasis: "34%" }}>
              <FieldHeading title="Add Notes" />
              <DescriptionBox
                id="notes"
                placeholder="Add Notes"
                variant="standard"
                fullWidth
                rows={4}
                multiline
                value={formik.values.notes}
                onChange={formik.handleChange("notes")}
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{
                  disableUnderline: true
                }}
                inputProps={{
                  maxLength: 100
                }}
              />
              <Grid
                container
                style={{ color: "#589F11" }}
                justifyContent="flex-end">
                {`${formik.values.notes?.length || 0}/100`}
              </Grid>
            </Grid>
          </div>
          <div className={style.uploadButton}>
            <div>
              <button
                className={style.updatebtn}
                onClick={() => formik.handleSubmit()}>
                Upload
              </button>
              <div className={classes.skipButton}>
                <p
                  onClick={() => {
                    setOpen(false);
                    AppDispatcher.setGroupBy("Template");
                    history.push("/u/manage_data");
                  }}>
                  Cancel
                </p>
              </div>
            </div>
          </div>
        </>
      ) : null}
      {uploadState === "Failed" ? (
        <VerificationError data={errorList} formik={formik} />
      ) : null}
    </>
  );
};

export default Step2;
