import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { truncate } from "helpers/Common";
import React from "react";
import styles from "./NewComponent.module.scss";

const SponsorTable = ({ Headingdata, mapArray, width }) => {
  return (
    <>
      <Table
        className={styles.Spon_T_C}
        style={width ? { minWidth: width } : {}}>
        <TableHead>
          <TableRow>
            {Headingdata?.map((item, i) => (
              <TableCell className={styles.T_Heading} key={i}>
                {item.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mapArray?.map((arr) => (
            <TableRow>
              {arr?.map((bodyitem, i) => (
                <TableCell key={i} className={styles.T_body}>
                  <div title={bodyitem.val}>{truncate(bodyitem.val, 25)}</div>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default SponsorTable;
