import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Filler,
  registerables
} from "chart.js";
import { Chart } from "react-chartjs-2";
import styles from "./graphLayout.module.scss";
import ToggleButton from "./Components/ToggleButton";
import InfiniteYearDropDown from "./Components/InfiniteYearDropdown";
import YearRange from "./Components/YearRange";

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Filler,
  ...(registerables || [])
);

const Months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];
const Quarter = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];

const CustomGraph = ({
  id,
  setgraphInfo,
  Title = "",
  legend,
  yearRange,
  MultiAxis,
  yAxisLabel,
  yAxisticks = "",
  y1AxisLabel,
  y1Axisticks = "",
  graphData,
  tooltipData
}) => {
  const Curentyear = new Date().getFullYear();
  const [toggle, setToggle] = useState("Monthly");
  const [year, setYear] = useState(Curentyear);
  const [year_range, setyear_range] = useState([Curentyear, Curentyear - 1]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    interaction: {
      mode: "index",
      intersect: false
    },

    stacked: false,
    plugins: {
      title: {
        display: false
      },

      legend: {
        display: legend ? true : false,
        labels: {
          color: "#616161",
          font: {
            size: 14,
            weight: 600
          },
          usePointStyle: true,
          pointStyle: "circle"
        },
        position: "top",
        align: "center"
      },

      tooltip: {
        yAlign: "bottom",
        displayColors: false,
        ...(tooltipData && { callbacks: tooltipData })
      }
    },
    scales: {
      x: {
        display: true,
        grid: {
          borderDash: [0, 10],
          color: "#A0A0A0",
          borderWidth: 0
        },
        ticks: {
          padding: 16
        }
      },
      y: {
        display: true,
        position: "left",

        title: {
          display: yAxisLabel ? true : false,
          text: yAxisLabel,
          color: "#616161",
          font: {
            size: 16,
            family: "Inter SemiBold"
          }
        },
        grid: {
          borderDash: [5, 10],
          borderDashOffset: 100,
          color: "#A0A0A0",
          borderWidth: 0,
          lineWidth: 0.5
        },
        ticks: {
          beginAtZero: true,
          callback: function (value) {
            return (yAxisticks === "K" ? value / 1000 : value) + yAxisticks;
          },
          padding: 8
        }
      },
      /* multiAxis*/
      ...(MultiAxis && {
        y1: {
          display: true,
          position: "right",
          title: {
            display: y1AxisLabel ? true : false,
            text: y1AxisLabel,
            color: "#616161",
            font: {
              size: 16,
              family: "Inter SemiBold"
            }
          },
          grid: {
            borderDash: [0, 10],
            color: "#A0A0A0",
            borderWidth: 0
          },
          ticks: {
            beginAtZero: true,
            callback: function (value) {
              return value + y1Axisticks;
            },
            padding: 8
          }
        }
      })
    }
  };

  const data = {
    labels: toggle !== "Monthly" ? Quarter : Months,
    datasets: graphData
  };

  useEffect(() => {
    setgraphInfo({
      id: id,
      toggleStatus: toggle,
      year: year,
      yearRange: year_range
    });
  }, [toggle, year, year_range]);

  return (
    <div className={styles.main_Continer}>
      <h3>{Title}</h3>
      <div className={styles.subContainerone}>
        <div>
          <ToggleButton toggle={toggle} setToggle={setToggle} />
        </div>
        <div>
          {yearRange ? (
            <YearRange
              yearRangeArray={yearRange}
              year_range={year_range}
              setyear_range={setyear_range}
            />
          ) : (
            <InfiniteYearDropDown
              selectedItem={year}
              onChange={(y) => setYear(y)}
              minValue={2000}
            />
          )}
        </div>
      </div>
      <div className={styles.subContainertwo} style={{ height: 400 }}>
        <Chart options={options} data={data} />
      </div>
    </div>
  );
};

export default CustomGraph;
