import { Grid, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 8,
    color: "rgba(160, 160, 160, 1)"
  },
  text: {
    fontSize: 16,
    color: "#191919",
    // marginBottom: 20,
    fontWeight: 600
  }
}));

const TextWithLabel = ({ md = 2, sm = 3, xs = 4, label, text }) => {
  const classes = useStyles();

  return (
    <Grid item {...{ md, sm, xs }} lg className={classes.container}>
      <Grid item xs={12} className={classes.label}>
        {label}
      </Grid>
      <Grid xs={12} className={classes.text}>
        {text}
      </Grid>
    </Grid>
  );
};

export default TextWithLabel;
