import { Grid, Typography } from "@material-ui/core";
import React from "react";
import Styles from "../../dashboard.module.css";

function DashboardToolTip({ data = [] }) {
  return (
    <>
      {data?.map((value, indx) => {
        return (
          <Grid spacing={1} key={indx} justifyContent="space-between" container>
            <Grid item>
              <Typography className={Styles.toolTipInfoData1}>
                {value[0]}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={Styles.toolTipInfoData2}>
                {value[1]}
              </Typography>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

export default DashboardToolTip;
