import { API, NetworkManager } from "./core";

export class ContactsService {
  static async getContacts(params) {
    const instance = new NetworkManager(
      API.LIST_CONTACTS.GET_CONTACTS,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async get_Pdf_Or_Csv(params) {
    const instance = new NetworkManager(
      API.LIST_CONTACTS.EXPORT_PDF_CSV,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getinvestmentDropdownValues(params) {
    const instance = new NetworkManager(
      API.LIST_CONTACTS.GET_ADD_INVESTMENT_CONTACT,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async getInvestmentData(params) {
    const instance = new NetworkManager(
      API.LIST_CONTACTS.GET_INVESTMENT_CONTACT,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getSingleContact(params) {
    const instance = new NetworkManager(
      API.LIST_CONTACTS.GET_SINGLE_CONTACT,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async editContact(payload, params) {
    const instance = new NetworkManager(
      API.LIST_CONTACTS.EDIT_CONTACTS,
      payload,
      params
    );
    return await instance.httpRequest();
  }

  static async sendContactMail(payload) {
    const instance = new NetworkManager(
      API.LIST_CONTACTS.SEND_CONTACT_EMAIL,
      payload
    );
    return await instance.httpRequest(true, true);
  }

  static async deleteContact(params) {
    const instance = new NetworkManager(
      API.LIST_CONTACTS.DELETE_CONTACTS,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async addContact(payload) {
    const instance = new NetworkManager(API.LIST_CONTACTS.ADD_CONTACT, payload);
    return await instance.httpRequest();
  }
}
