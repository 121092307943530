import React from "react";
import TextField from "@material-ui/core/TextField";
import { styled } from "@material-ui/core/styles";
import NumberFormat from "react-number-format";

const StyledTextField = styled(TextField)({
  "& label": {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#191919"
  },
  "& label.Mui-focused": {
    color: "#191919"
  },
  "& .MuiInputBase-root": {
    border: "1px solid #BBBBBB",
    padding: "7px 10px",
    borderRadius: 8,
    backgroundColor: "#FFFF"
  },

  "& .MuiInputBase-input": {
    fontFamily: "Inter SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "24px",
    color: "#191919",
    borderRadius: "8px",
    WebkitBoxShadow: "0 0 0 1000px #FFF inset",
    height: "1rem"
  },
  "& .MuiInputBase-input:focus": {},
  "& .Mui-error": {
    padding: "0px 0px",
    border: "none",
    "& .MuiInputBase-input": {
      border: "1px solid #E13838",
      fontFamily: "Inter Regular",
      fontWeight: 400,
      padding: "0px 0px",
      height: "43px",
      paddingLeft: "7px"
    }
  },
  "& .Mui-disabled": {
    backgroundColor: "#E9E9E9",
    "& .MuiInputBase-input": {
      backgroundColor: "#E9E9E9",
      WebkitBoxShadow: "0 0 0 1000px #E9E9E9 inset",
      fontFamily: "Inter Regular",
      fontWeight: 600,
      borderRadius: "8px"
    }
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0
  }
});

const AppTextFieldWhite = ({ onChange, ...props }) => {
  return (
    <>
      {!props.type && (props.numFormat || props.onKeyDown) ? (
        <NumberFormat
          thousandSeparator={true}
          customInput={StyledTextField}
          onValueChange={({ value }) => onChange({ target: { value } })}
          {...props}
        />
      ) : (
        <StyledTextField {...props} onChange={onChange} />
      )}
    </>
  );
};

export default AppTextFieldWhite;
