import React from "react";
import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import AppTextFieldWhite from "./AppTextFieldWhite";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAutocomplete-inputRoot": {
      padding: "6px"
    },
    "& .MuiFilledInput-underline::before": {
      border: "none"
    },
    "& .MuiFilledInput-underline::after": {
      border: "none"
    },
    "& .MuiAutocomplete-input": {
      height: "20px"
    }
  }
}));

export default function ChipInput({ setEmails }) {
  const emails = [];
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="emails-filled"
        options={emails.map((option) => option?.title)}
        freeSolo
        onChange={(event, newValue) => {
          setEmails(newValue);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              style={{
                backgroundColor: "#EEF1FC",
                borderRadius: "4px",
                fontSize: "16px",
                fontWeight: 400
              }}
              deleteIcon={<ClearIcon style={{ color: "#445EBE" }} />}
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <AppTextFieldWhite {...params} variant="filled" />
        )}
      />
    </div>
  );
}
