import { Typography } from "@material-ui/core";
import React from "react";
import styles from "./Components.module.scss";
import CheckCheckBox from "assets/icons/CheckCheckBox.svg";
import { truncate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import formatDate from "helpers/formatDate";

const loanType = {
  fixedratemortgage: "Fixed Rate Mortgage",
  adjustratemortgage: "Adjustable Rate Mortgage",
  fedhousadmin: "Federal Housing Administration",
  convenloan: "Conventional loan",
  vetaffloan: "Veteran Affairs (VA) loan",
  usdaloan: "U.S. Department of Agriculture (USDA) loan",
  jumboloan: "Jumbo loan",
  constloan: "Construction loan",
  ballonloan: "Balloon loans",
  piggyloan: "Piggybank loan",
  intonmortgages: "Interest-only mortgages"
};

const Loan = ({ formik, item, index, id, handleCheck = () => {} }) => {
  return (
    <div className={styles.bodyCtr}>
      <div className={styles.checkboxCtr}>
        <div
          onClick={() => handleCheck(formik, index, item)}
          className={styles.checkbox}>
          <>
            {formik?.values?.refinanceSet[id]?.selectedLoans.some(
              (el) => el == item.id
            ) ? (
              <img src={CheckCheckBox} width={23} height={23} alt="check" />
            ) : (
              <div className={styles.unChecked} />
            )}
          </>
        </div>
      </div>
      <div title={loanType?.[item?.type]} className={styles.bodyLoan}>
        <Typography className={styles.bodyText}>
          {item?.type ? truncate(loanType?.[item?.type], 18) : "-"}
        </Typography>
      </div>
      <div className={styles.bodyLoan}>
        <Typography className={styles.bodyText}>
          {item?.amount ? formatCurrency(item?.amount).split(".")?.[0] : "-"}
        </Typography>
      </div>
      <div className={styles.bodyLoan}>
        <Typography className={styles.bodyText}>
          {item?.outstanding
            ? formatCurrency(item?.outstanding).split(".")?.[0]
            : "-"}
        </Typography>
      </div>
      <div className={styles.bodyLoan}>
        <Typography className={styles.bodyText}>{item?.bank || "-"}</Typography>
      </div>
      <div className={styles.bodyLoan}>
        <Typography className={styles.bodyText}>
          {formatDate(item?.created_at) || "-"}
        </Typography>
      </div>
    </div>
  );
};

export default Loan;
