import { API, NetworkManager } from "./core";

export class AddInvestmentPreviewService {
  static async getInvestmentDetails(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_PREVIEW.GET_INVESTMENT_DETAILS,
      {},
      [id]
    );
    return await instance.httpRequest();
  }

  static async getInvestmentDetailsFilter(params) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_PREVIEW.GET_INVESTMENT_DETAILS,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async setStatus(payload, id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_INVESTOR.ADD_INVESTORS,
      payload,
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
}
