import { Grid } from "@material-ui/core";
import React from "react";
import LoanSponsorTable from "./LoanSponsorTable";
import RefinanceSponsorTable from "./RefinanceSponsorTable";

function LoanInformation({ data }) {
  const tableHeading = [
    "Loan Type",
    "Outstanding Amount",
    "Notes",
    "Loan Amount",
    "Loan Date",
    "Debt Service",
    "Status",
    "Documents"
  ];
  const tableHeading1 = [
    "Refinance Loan Type",
    "Outstanding Amount",
    "Notes",
    "Refinance Loan Amount",
    "Refinance Loan Date",
    "Debt Service",
    "Status",
    "Documents"
  ];
  return (
    <>
      <Grid style={{ marginTop: "20px" }}></Grid>
      <LoanSponsorTable
        tabHead="Loan Information"
        value="loan"
        tableHeading={tableHeading}
        loanTableData={data}
      />
      <Grid style={{ marginTop: "20px" }}></Grid>

      <RefinanceSponsorTable
        tabHead="Refinances"
        value="refinances"
        tableHeading={tableHeading1}
        loanTableData={data}
      />
    </>
  );
}

export default LoanInformation;
