import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Grid,
  Box,
  Typography,
  TextField,
  styled,
  Button
} from "@material-ui/core";
import plusAdd from "assets/icons/PlusAdd.svg";
import { FieldArray, useFormik, FormikProvider, Form } from "formik";
import FieldHeading from "../../../../components/fieldHeading";
import AppTextFieldWhite from "../../../../components/StyledComponents/AppTextFieldWhite";
import CustomSelect from "../../../../components/StyledComponents/Select";
import DescriptionBox from "../../../../components/StyledComponents/DescriptionBox";
import Toast from "../../../../components/toast";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import { useHistory } from "react-router-dom";
import { InvestmentListService } from "network/investment_ListService";
import { CommonService } from "../../../../network/commonService";
import { PropertiesService } from "network/propertiesService";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Noi from "../component/noi";
import Occupancy from "../component/occupancy";
import { validationSchemaAddProperty } from "helpers/validationSchema";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useSelector } from "react-redux";
import ImageUpload from "../component/ImageUpload/ImageUpload";
import NewCategoryDocTable from "../component/NewCategoryDocTable";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import NewAutoComplete from "../component/NewAutoComplete/NewAutoComplete";
import TextCounter from "components/TextCounter";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import Streetview from "react-google-streetview";

const StyledTextField = styled(TextField)({
  "& label": {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "18px",
    color: "#191919"
  },
  "& label.Mui-focused": {
    color: "#191919"
  },
  "& .MuiInputBase-root": {
    border: "1px solid #BBBBBB",
    padding: "7px 10px",
    borderRadius: 8,
    backgroundColor: "#FFFF"
  },

  "& .MuiInputBase-input": {
    fontFamily: "Inter SemiBold",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    lineHeight: "24px",
    color: "#191919",
    borderRadius: "8px",
    WebkitBoxShadow: "0 0 0 1000px #FFF inset",
    height: "1rem"
  },
  "& .MuiInputBase-input:focus": {},
  "& .Mui-error": {
    padding: "0px 0px",
    border: "none",
    "& .MuiInputBase-input": {
      border: "1px solid #E13838",
      fontFamily: "Inter Regular",
      fontWeight: 400,
      padding: "0px 0px",
      height: "43px",
      width: "100%",
      paddingLeft: "7px"
    }
  },
  "& .Mui-disabled": {
    backgroundColor: "#E9E9E9",
    "& .MuiInputBase-input": {
      backgroundColor: "#E9E9E9",
      WebkitBoxShadow: "0 0 0 1000px #E9E9E9 inset",
      fontFamily: "Inter Regular",
      fontWeight: 600,
      borderRadius: "8px"
    }
  },
  "& input[type=number]": {
    "-moz-appearance": "textfield"
  },
  "& input[type=number]::-webkit-outer-spin-button": {
    "-webkit-appearance": "none",
    margin: 0
  },
  "& input[type=number]::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0
  }
});

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: 36,
    borderRadius: 20
  },
  accordionContainer: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white"
    }
  },
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    marginTop: 18,
    width: "max-content"
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 10,
    marginTop: 20,
    marginLeft: 20
  },

  propertyNameDropdown: {
    color: "#191919",
    fontFamily: "Inter Regular !important",
    fontSize: "16px",
    padding: "6px 16px"
  },
  heading: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0,
    marginRight: 20,
    flexShrink: 0
  },
  divider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: "#E9E9E9"
  },
  whiteContainer: {
    backgroundColor: "#fff",
    margin: 20,
    width: "auto"
  },
  grayContainer: {
    backgroundColor: "#FAFAFA",
    margin: 20,
    width: "100%",
    borderRadius: 12
  },
  scrollXContainer: {
    overflowX: "scroll",
    overflowY: "hidden"
  },
  container: {},
  noi: {
    margin: "0px 20px"
  },
  btnPrimary: {
    border: "1px solid #445EBE",

    backgroundColor: "#5775E5",
    color: "#ffffff",
    width: 218,
    height: 50,
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer"
  },
  btnSecondary: {
    width: 218,
    margin: "0 0px 0 22px",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    cursor: "pointer",
    borderRadius: 4,
    border: "1px solid #445EBE",
    background: "#FFF"
  },
  mainContainer: {
    cursor: "pointer",
    padding: "16px",
    "&:hover": {
      background: "#F6F8FD"
    }
  },
  lighttext: {
    padding: "3px 0px",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "21px",
    color: "#616161"
  },
  darktext: {
    color: "#000000"
  },
  cellWidth50: {
    minWidth: 50
  },
  cellWidth120: {
    minWidth: 120
  },
  cellWidth150: {
    minWidth: 150
  },
  cellWidth170: {
    minWidth: 170
  },
  cellWidth180: {
    minWidth: 180
  },
  doctableHeading: {
    fontSize: 14,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  propertyLocation: {
    color: "#A0A0A0",
    fontWeight: 400,
    fontSize: "14px"
  },
  searchAddress: {
    color: "#191919",
    fontWeight: 600,
    fontSize: "16px"
  },
  mapView: {
    height: "450px",
    paddingTop: "34px"
  }
}));

const CHARACTER_LIMIT = 100;

const PropertyDetails = () => {
  const classes = useStyles();

  const [location, setLocation] = useState({
    lat: 40.0066723,
    lng: -83.0304546
  });

  const googleMapsApiKey = "AIzaSyA_J-TXbKBup88CxDVCXEHvfgYBzE3RkYo";

  const streetViewPanoramaOptions = {
    position: { lat: location.lat, lng: location.lng },
    pov: { heading: 100, pitch: 0 },
    zoom: 1,
    addressControl: true,
    showRoadLabels: true,
    zoomControl: true
  };

  const [allProperties] = useState({
    investmentName: "",
    investmentNamePlacehodler: "",
    propertyName: "",
    propertyValue: "",
    type: "",
    status: "",
    totalUnits: "",
    address: "",
    zip: "",
    area: "",
    units: "",
    year: "",
    yearBuild: "",
    description: "",
    documents: [],
    occupancy: [],
    noiSet: [],
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    country: "",
    latitude: "",
    longitude: ""
  });

  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [noiCadenceChoices, setNoiCadenceChoices] = useState([]);
  const [monthChoices, setMonthChoices] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [displayImage, setDisplayImage] = useState([]);

  const { userPermissions, user } = useSelector((store) => store.app);
  const handleDisplayImageList = () => {};
  useEffect(() => {
    handleDisplayImageList();
  }, [displayImage]);
  const history = useHistory();
  const [docCategories, setDocCategories] = useState([]);
  const [noiCategories, setNoiCategories] = useState([]);
  const [investmentNamesearch, setInvestmentNamesearch] = useState("");
  const [investmentList, setInvestmentList] = useState([]);
  const [address1, setAddress] = useState("");
  const [address2, setAddress2] = useState("");

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  });

  const handleSelect = async (value) => {
    const result = await geocodeByAddress(value);
    const ll = await getLatLng(result[0]);
    setAddress(value);
    setCoordinates(ll);
    formik.setFieldValue("address", value);
  };

  const formik = useFormik({
    initialValues: allProperties,
    enableReinitialize: true,
    validationSchema: validationSchemaAddProperty,

    onSubmit: async (values) => {
      const {
        area,
        description,
        investmentName,
        type,
        propertyName,
        propertyValue,
        status,
        totalUnits,
        units,
        yearBuild
      } = values;
      const payload = {
        property_address: {
          address: address1,
          address_1: address2,
          address_2: "",
          city: "",
          state: "",
          country: "",
          latitude: coordinates.lat,
          longitude: coordinates.lng,
          zip_code: ""
        },

        property_noi: returnNoiArray(values.noiSet),
        property_occupancy: returnOccupancyArray(values.occupancy),
        documents: values?.documents?.map((doc) => ({
          document_media_id: doc.id,
          category_id: doc.type
        })),
        investment: investmentName, //investment_id in ""
        name: propertyName,
        value: propertyValue ? +propertyValue : null,
        type: type,
        area: area ? area : null,
        units: units,
        status: status,
        description: description,
        total_units: totalUnits ? totalUnits : null,
        build_year: yearBuild ? yearBuild : null,
        created_by_id: user.id,
        updated_by_id: user.id,
        images_list: displayImage.map((item) => item.id)
      };

      let res = await PropertiesService.addProperties(
        payload,
        values.investmentName
      );
      if (res.success) {
        Toast.showSuccessToast("Properties Added Successfully");
        history.push("/u/properties");
      } else {
        Toast.showErrorToast(res.error.message[0]);
      }
    }
  });

  const setDocData = (data, formik) => {
    formik.setFieldValue("documents", [...data]);
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTPROPERTY"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const loadNoiCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTPROPERTYNOI"
    });
    if (res.success) {
      res.data.length && setNoiCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const handleLocation = () => {
    setLocation({ ...location, lat: coordinates.lat, lng: coordinates.lng });
  };

  const onPositionChanged = () => {};

  const getOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setPropertyTypeOptions(res.data.PROPERTY_TYPE);
      setStatusOptions(res.data.STATUSES);
      setUnitOptions(res.data.UNITS);
      setNoiCadenceChoices(res.data.NOI_CADENCE_CHOICES);
      setMonthChoices(res.data.MONTH_CHOICES);
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `search=${searchText}`;
    }
    params += `&page=${page}`;
    const response =
      await InvestmentListService.getInvestmentListServiceAllData([
        `?${params}`
      ]);
    if (response.success) {
      // setInvestmentList(response.data.results);
      setInvestmentList(response.data);

      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    setInvestmentList([]);
    return { data: [], maxPage: 1 };
  };

  const returnNoiArray = (item) => {
    let allPropertySets = {};
    let payload = [];
    item &&
      item.forEach((val) => {
        if (val.year !== "" && val.month !== "") {
          (allPropertySets.year = val.year),
            (allPropertySets.month = val.month);
          allPropertySets.documents = val.documents?.map((doc) => ({
            document_media_id: doc.id,
            category_id: doc.type,
            ...(doc.docId ? { id: doc.docId } : {})
          }));

          allPropertySets.revenue = val.totalRevenue;
          allPropertySets.operating_expenses = val.operatingExpense;
          allPropertySets.finance_expenses = val.financingExpense;
          allPropertySets.non_operating_expenses = val.nonOperatingExpense;
          allPropertySets.created_by_id = user.id;
          allPropertySets.updated_by_id = user.id;
          payload.push(allPropertySets);
          allPropertySets = {};
        }
      });
    return payload;
  };

  const returnOccupancyArray = (item) => {
    let allPropertySets = {};
    let payload = [];
    item &&
      item.forEach((val) => {
        if (val.year !== "" && val.month !== "") {
          (allPropertySets.year = val.year),
            (allPropertySets.month = val.month);
          allPropertySets.documents = val.documents?.map((doc) => ({
            document_media_id: doc.id,
            category_id: doc.type,
            ...(doc.docId ? { id: doc.docId } : {})
          }));
          const totalUnits = formik.values.totalUnits;
          const occupancy = (val.units_leased / totalUnits) * 100;
          const collections = (val.rentCollected / val.rentPotential) * 100;
          allPropertySets.units_leased = val.units_leased;
          allPropertySets.occupancy = occupancy.toFixed(2);
          allPropertySets.rent_potential = val.rentPotential;
          allPropertySets.rent_collected = val.rentCollected;
          allPropertySets.collections = collections.toFixed(2);
          allPropertySets.created_by_id = user.id;
          allPropertySets.updated_by_id = user.id;

          payload.push(allPropertySets);
          allPropertySets = {};
        }
      });
    return payload;
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "PROPERTIES",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_create) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    checkPermissions();
    loadCategories();
    loadNoiCategories();
  }, []);

  useEffect(async () => {
    getOptions();
    getInvestmentName();
  }, []);

  /**************************************************** */
  /*********handle DropDwon onClick******************** */
  /**************************************************** */

  const handleInvestmentNameFromData = (item) => {
    setInvestmentNamesearch("");
    formik.setFieldValue("investmentName", item?.id);
    formik.setFieldValue("investmentNamePlaceholder", item?.name);
  };

  useEffect(() => {
    getInvestmentName(1, investmentNamesearch);
  }, [investmentNamesearch]);

  const investmentDataList = investmentList?.map((item) => {
    return (
      <div
        className={classes.mainContainer}
        onClick={() => handleInvestmentNameFromData(item)}>
        <Typography variant="button">{item?.name ?? "---"}</Typography>
        <div className={classes.lighttext}>
          Sponsor Name:
          <span className={classes.darktext}>{` ${
            item?.sponsor?.map(
              (item) => item.first_name + " " + item.last_name
            ) || "---"
          }`}</span>
        </div>
        <div className={classes.lighttext}>
          Managed By:
          <span className={classes.darktext}>{item?.managed_by ?? "-"}</span>
        </div>
      </div>
    );
  });

  const handleImageDelete = async (id) => {
    const resp = await CommonService.deleteMedia(id);

    if (resp.success) {
      Toast.showSuccessToast("Image Deleted Successfully");
      setDisplayImage((prev) => {
        return prev.filter((item) => item && item.id != id);
      });
    } else {
      Toast.showErrorToast("Failed To Delete Image");
    }
  };

  const currentDescriptionLength = formik.values.description.length;

  return (
    <>
      <HeaderComponent
        heading="Add Properties"
        description="Add new properties"
        page="editProperties"
        backBtn={true}
      />
      <div className={classes.container}>
        <FormikProvider value={formik}>
          <Form>
            <>
              <>
                <>
                  <div className={classes.root}>
                    <Grid item xs={12}>
                      <Grid container xs={12} direction="column">
                        <div className={classes.headingContainer}>
                          <div className={classes.heading}>Basic</div>
                        </div>
                      </Grid>
                      <Grid container className={classes.grayContainer}>
                        <Grid container spacing={2} style={{ padding: 20 }}>
                          <Grid item xl={3} lg={4} sm={6}>
                            <FieldHeading title="Investment Name*" />
                            <NewAutoComplete
                              placeholder="Investment Name"
                              search={investmentNamesearch}
                              setSearchQuery={setInvestmentNamesearch}
                              dataList={investmentDataList}
                              fieldKey="investmentName"
                              fieldKeyPlacehodler="investmentNamePlaceholder"
                              formik={formik}
                            />
                          </Grid>
                          <Grid item xl={3} lg={4} sm={6}>
                            <FieldHeading title="Property Name*" />
                            <AppTextFieldWhite
                              placeholder="Property Name"
                              id="propertyName"
                              onChange={formik.handleChange("propertyName")}
                              variant="standard"
                              fullWidth
                              value={formik.values.propertyName}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                disableUnderline: true
                              }}
                              error={
                                formik.touched.propertyName &&
                                Boolean(formik.errors.propertyName)
                              }
                              helperText={
                                formik.touched.propertyName &&
                                formik.errors.propertyName
                              }
                            />
                          </Grid>
                          <Grid item xl={3} lg={4} sm={6}>
                            <FieldHeading title="Property Type*" />
                            <CustomSelect
                              style={{ backgroundColor: "#fff" }}
                              fullWidth
                              items={propertyTypeOptions}
                              onChange={formik.handleChange("type")}
                              value={formik.values.type}
                              error={formik.errors.type}
                              touched={formik.touched.type}
                            />
                          </Grid>
                          <Grid item xl={3} lg={4} sm={6}>
                            <FieldHeading title="Property Value" />
                            <AppTextFieldWhite
                              id="propertyValue"
                              placeholder=""
                              numFormat={true}
                              variant="standard"
                              fullWidth
                              value={formik.values.propertyValue}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                disableUnderline: true
                              }}
                              onChange={formik.handleChange("propertyValue")}
                              error={
                                formik.touched.propertyValue &&
                                Boolean(formik.errors.propertyValue)
                              }
                              helperText={
                                formik.touched.propertyValue &&
                                formik.errors.propertyValue
                              }
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          spacing={2}
                          style={{
                            paddingLeft: 20,
                            paddingRight: 20,
                            paddingBottom: 20
                          }}>
                          <Grid item xl={3} lg={4} sm={6}>
                            <FieldHeading title="Status" />
                            <CustomSelect
                              style={{ backgroundColor: "#fff" }}
                              fullWidth
                              items={statusOptions}
                              value={formik.values.status}
                              onChange={formik.handleChange("status")}
                              error={formik.errors.status}
                              touched={formik.touched.status}
                            />
                          </Grid>
                          <Grid item xl={3} lg={4} sm={6}>
                            <FieldHeading title="Total Units" />
                            <AppTextFieldWhite
                              id="totalUnits"
                              placeholder=""
                              numFormat={true}
                              variant="standard"
                              fullWidth
                              value={formik.values.totalUnits}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                disableUnderline: true
                              }}
                              onChange={formik.handleChange("totalUnits")}
                              error={
                                formik.touched.totalUnits &&
                                Boolean(formik.errors.totalUnits)
                              }
                              helperText={
                                formik.touched.totalUnits &&
                                formik.errors.totalUnits
                              }
                            />
                          </Grid>
                          <Grid item xl={3} lg={4} sm={6}>
                            <FieldHeading title="Area Covered" />
                            <Box sx={{ display: "inline-flex" }}>
                              <CustomSelect
                                style={{ backgroundColor: "white" }}
                                placeholder="Units"
                                items={unitOptions}
                                value={formik.values.units}
                                onChange={formik.handleChange("units")}
                                error={formik.errors.units}
                                touched={formik.touched.units}
                              />
                              <AppTextFieldWhite
                                style={{ paddingLeft: "12px" }}
                                id="area"
                                placeholder="Add Area Covered"
                                variant="standard"
                                fullWidth
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  disableUnderline: true
                                }}
                                value={formik.values.area}
                                onChange={formik.handleChange("area")}
                              />
                            </Box>
                          </Grid>
                          <Grid item xl={3} lg={4} sm={6}>
                            <FieldHeading title="Year of Build" />
                            <SelectWithInfiniteScroll
                              style={{ backgroundColor: "#fff" }}
                              fullWidth
                              minValue={2000}
                              selectedItem={2000}
                              onChange={(value) =>
                                formik.setFieldValue("yearBuild", value)
                              }
                              error={formik.errors.yearBuild}
                              touched={formik.errors.yearBuild}
                            />
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <FieldHeading title="Property Description" />
                            <DescriptionBox
                              id="description"
                              placeholder="Add Description"
                              variant="standard"
                              fullWidth
                              rows={3}
                              multiline
                              value={formik.values.description}
                              onChange={formik.handleChange("description")}
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                disableUnderline: true
                              }}
                              inputProps={{
                                maxLength: CHARACTER_LIMIT
                              }}
                              // error={formik.errors.description}
                              error={
                                formik.touched.description &&
                                Boolean(formik.errors.description)
                              }
                              helperText={
                                formik.touched.description &&
                                formik.errors.description
                              }
                            />
                            <TextCounter
                              currentDescriptionLength={
                                currentDescriptionLength
                              }
                              maxLength={100}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container xs={12} direction="column">
                        <div className={classes.headingContainer}>
                          <div className={classes.heading}>
                            Upload upto 5 images
                          </div>
                        </div>
                      </Grid>
                      <Grid container className={classes.grayContainer}>
                        <Grid container spacing={2} style={{ padding: 20 }}>
                          <ImageUpload
                            displayImage={displayImage}
                            setDisplayImage={setDisplayImage}
                            handleDelete={handleImageDelete}
                          />
                        </Grid>
                      </Grid>
                      <Grid container xs={12} direction="column">
                        <div className={classes.headingContainer}>
                          <div className={classes.heading}>
                            Property Overview
                          </div>
                        </div>
                      </Grid>
                      <Grid container className={classes.grayContainer}>
                        <Grid container spacing={2} style={{ padding: 20 }}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FieldHeading title="Address Line 1*" />
                            {window.google && (
                              <PlacesAutocomplete
                                value={formik.values.address}
                                // onChange={setAddress}
                                onChange={formik.handleChange("address")}
                                onSelect={handleSelect}>
                                {({
                                  getInputProps,
                                  suggestions,
                                  getSuggestionItemProps,
                                  loading
                                }) => (
                                  <div>
                                    <StyledTextField
                                      value={formik.values.address}
                                      id="address"
                                      error={
                                        formik.touched.address &&
                                        Boolean(formik.errors.address)
                                      }
                                      helperText={
                                        formik.touched.address &&
                                        formik.errors.address
                                      }
                                      style={{ width: "100%" }}
                                      InputProps={{ disableUnderline: true }}
                                      {...getInputProps({
                                        placeholder: "Add Address",
                                        className: "location-search-input"
                                      })}
                                    />
                                    <div>
                                      {loading && (
                                        <div style={{ padding: "6px 12px" }}>
                                          Loading...
                                        </div>
                                      )}
                                      {suggestions.map((suggestion) => {
                                        const className =
                                          classes.propertyNameDropdown;

                                        const style = suggestion.active
                                          ? {
                                              cursor: "pointer",

                                              backgroundColor: "#F5F7FB"
                                            }
                                          : {
                                              backgroundColor: "#ffffff",

                                              cursor: "pointer"
                                            };
                                        return (
                                          <div
                                            {...getSuggestionItemProps(
                                              suggestion,
                                              {
                                                className,
                                                style
                                              }
                                            )}>
                                            <span>
                                              {suggestion.description}
                                            </span>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FieldHeading title="Address Line 2" />
                            <StyledTextField
                              onChange={(e) => {
                                setAddress2(e.target.value);
                              }}
                              value={address2}
                              style={{ width: "100%" }}
                              InputProps={{
                                placeholder: "Add Address 2",
                                disableUnderline: true
                              }}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <Grid container xs={12}>
                              <Grid xs={6}>
                                <Grid className={classes.propertyLocation}>
                                  Location
                                </Grid>
                                <Grid
                                  className={classes.searchAddress}
                                  style={{ height: "30px" }}>
                                  {address1}
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "center"
                                }}
                                xs={6}>
                                {" "}
                                <Button
                                  onClick={handleLocation}
                                  style={{
                                    fontSize: "18px",
                                    fontFamily: "Inter Regular",
                                    fontWeight: 600,
                                    padding: "13px 45px",
                                    height: "50px",
                                    width: "200px",
                                    color: "#FFFF",
                                    borderRadius: "8px",
                                    background:
                                      "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)"
                                  }}>
                                  Street View
                                </Button>
                              </Grid>
                            </Grid>
                            <Grid xs={12} container className={classes.mapView}>
                              <Grid item xs={12}>
                                {/* <GoogleMapReact
                                  bootstrapURLKeys={{ key: "" }}
                                  defaultCenter={defaultProps.center}
                                  defaultZoom={defaultProps.zoom}
                                  center={center}>
                                  {checked ? (
                                    <img
                                      lat={center.lat}
                                      lng={center.lng}
                                      src={locationMarker}
                                      alt="Marker"
                                    />
                                  ) : null}
                                </GoogleMapReact> */}

                                {/* <ReactStreetview
                                  apiKey={googleMapsApiKey}
                                  onPositionChanged={onPositionChanged}
                                  streetViewPanoramaOptions={
                                    streetViewPanoramaOptions
                                  }
                                /> */}
                                <Streetview
                                  apiKey={googleMapsApiKey}
                                  onPositionChanged={onPositionChanged}
                                  streetViewPanoramaOptions={
                                    streetViewPanoramaOptions
                                  }
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                          {/* <Grid item xs={12} sm={6} md={4}>
                            <FieldHeading title="Zip Code" />
                            <AppTextFieldWhite
                              id="zip"
                              placeholder="Add Zip Code"
                              variant="standard"
                              fullWidth
                              InputLabelProps={{
                                shrink: true
                              }}
                              InputProps={{
                                disableUnderline: true
                              }}
                              value={formik.values.zip}
                              onChange={formik.handleChange("zip")}
                            />
                          </Grid> */}
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container xs={12} direction="column">
                            <div className={classes.headingContainer}>
                              <div className={classes.heading}>Occupancy</div>
                            </div>
                          </Grid>
                          <Grid
                            className={`${classes.grayContainer}`}
                            style={{ padding: "20px" }}>
                            <FieldArray
                              name="occupancy"
                              render={(arrayHelper) => (
                                <>
                                  <Grid
                                    container
                                    // className={`${classes.scrollXContainer}`}
                                    style={{
                                      "box-shadow":
                                        "0px 4px 15px rgb(160 160 160 / 15%)"
                                    }}>
                                    <Grid
                                      container
                                      className={`${classes.scrollXContainer}`}
                                      style={{
                                        backgroundColor: "#fff",
                                        borderRadius: "10px"
                                      }}>
                                      <Grid
                                        style={{
                                          width: "100%",
                                          marginBottom: 5
                                          // borderBottom: "1px solid #A0A0A0"
                                        }}>
                                        <Box
                                          display="flex no-wrap"
                                          flexDirection={["row"]}
                                          alignItems="center"
                                          style={{
                                            display: "inline-flex",
                                            gap: "16px",
                                            width: "100%",
                                            padding: "15px"
                                          }}>
                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Year
                                          </Typography>
                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Month
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Units Leased
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Occupancy
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Rent Potential
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Rent Collected
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading}`}>
                                            Collections
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading}`}>
                                            Documents
                                          </Typography>
                                        </Box>
                                      </Grid>

                                      <Grid
                                        container
                                        direction="column"
                                        spacing={2}>
                                        <Grid
                                          item
                                          style={{
                                            borderTop: "1px solid #A0A0A0"
                                          }}>
                                          {formik.values.occupancy.map(
                                            (occupancy, index) => (
                                              <Box key={index}>
                                                <Occupancy
                                                  customStyle={{
                                                    backgroundColor: "#fff",
                                                    padding: 20,
                                                    borderRadius: 8,
                                                    margin: "0px 20px"
                                                  }}
                                                  monthChoices={monthChoices}
                                                  id={index}
                                                  occupancy={occupancy}
                                                  key={index}
                                                  arrayHelper={arrayHelper}
                                                  formik={formik}
                                                  noiCategories={noiCategories}
                                                  isEditable="false"
                                                />
                                              </Box>
                                            )
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    className={classes.addMore}
                                    onClick={() =>
                                      arrayHelper.push({
                                        year: 2000,
                                        month: "",
                                        units_leased: "",
                                        occupancy: "",
                                        rentPotential: "",
                                        rentCollected: "",
                                        collections: ""
                                      })
                                    }
                                    variant="text">
                                    <img
                                      src={plusAdd}
                                      alt=""
                                      style={{ marginRight: 8 }}
                                    />
                                    Click to add a new row
                                  </Grid>
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Grid container xs={12} direction="column">
                            <div className={classes.headingContainer}>
                              <div className={classes.heading}>
                                Net Operating Income
                              </div>
                            </div>
                          </Grid>
                          <Grid
                            className={`${classes.grayContainer}`}
                            style={{ padding: "20px" }}>
                            <FieldArray
                              name="noiSet"
                              render={(arrayHelper) => (
                                <>
                                  <Grid
                                    container
                                    style={{
                                      "box-shadow":
                                        "0px 4px 15px rgb(160 160 160 / 15%)"
                                    }}>
                                    <Grid
                                      container
                                      className={`${classes.scrollXContainer}`}
                                      style={{
                                        backgroundColor: "#fff",
                                        borderRadius: "10px"
                                      }}>
                                      <Grid
                                        style={{
                                          width: "100%",
                                          marginBottom: 5
                                        }}>
                                        <Box
                                          display="flex no-wrap"
                                          flexDirection={["row"]}
                                          alignItems="center"
                                          style={{
                                            display: "inline-flex",
                                            gap: "16px",
                                            width: "100%",
                                            padding: "15px"
                                          }}>
                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Year
                                          </Typography>
                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Month
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Total Revenue
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth170}`}>
                                            Total Operating Expenses
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Financing Expenses
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth150}`}>
                                            Non Operating Expenses
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading} ${classes.cellWidth50}`}>
                                            NOI
                                          </Typography>

                                          <Typography
                                            className={`${classes.doctableHeading}`}>
                                            Documents
                                          </Typography>
                                        </Box>
                                      </Grid>
                                      <Grid
                                        container
                                        direction="column"
                                        spacing={2}>
                                        <Grid
                                          item
                                          style={{
                                            borderTop: "1px solid #A0A0A0"
                                          }}>
                                          {formik.values.noiSet.map(
                                            (noi, index) => (
                                              <Box key={index}>
                                                <Noi
                                                  customStyle={{
                                                    backgroundColor: "#fff",
                                                    padding: 20,
                                                    borderRadius: 8,
                                                    margin: "0px 20px"
                                                  }}
                                                  cadenceChoices={
                                                    noiCadenceChoices
                                                  }
                                                  monthChoices={monthChoices}
                                                  id={index}
                                                  noi={noi}
                                                  key={index}
                                                  arrayHelper={arrayHelper}
                                                  formik={formik}
                                                  noiCategories={noiCategories}
                                                  isEditable="false"
                                                />
                                              </Box>
                                            )
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    // color={"primary.main"}
                                    style={{ cursor: "pointer" }}
                                    className={classes.addMore}
                                    onClick={() =>
                                      arrayHelper.push({
                                        property_noi: "",
                                        year: 2000,
                                        month: "",
                                        totalRevenue: "",
                                        operatingExpense: "",
                                        financingExpense: "",
                                        nonOperatingExpense: ""
                                      })
                                    }
                                    variant="text">
                                    <img
                                      src={plusAdd}
                                      alt=""
                                      style={{ marginRight: 8 }}
                                    />
                                    Click to add a new row
                                  </Grid>
                                </>
                              )}
                            />
                          </Grid>
                        </Grid>
                        {/* </Grid> */}
                        <Grid item xs={12} md={6}>
                          <Grid container xs={12} direction="column">
                            <div className={classes.headingContainer}>
                              <div className={classes.heading}>
                                Upload Documents
                              </div>
                            </div>
                          </Grid>
                          <Grid container className={classes.grayContainer}>
                            <Grid container style={{ padding: 20 }} spacing={2}>
                              {/* <DocumentUploadArea /> */}
                              <NewCategoryDocTable
                                sponDocCategories={docCategories.filter(
                                  (el) => el.name !== "Misc."
                                )}
                                listData={formik.values.documents}
                                setListData={(list) => setDocData(list, formik)}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </>
              </>
              <Box
                display="flex"
                justifyContent="flex-end"
                width="100%"
                style={{ gap: "16px" }}>
                <button
                  className={classes.btnSecondary}
                  onClick={() => history.push("/u/properties")}>
                  Cancel
                </button>
                <button type="submit" className={classes.btnPrimary}>
                  Add Property
                </button>
              </Box>
            </>
          </Form>
        </FormikProvider>
      </div>
    </>
  );
};

export default PropertyDetails;
