import React, { useState } from "react";
import ErrorIcon from "../../../../../../assets/icons/ErrorIcon.svg";
import style from "../../../manage_data.module.scss";
import styles from "../../../../../../components/AppTable/AppTable.module.css";
import MUIDataTable from "mui-datatables";
import SortingToggle from "../../../../../../assets/icons/SortingToggle.svg";
import { ThemeProvider } from "@material-ui/styles";
import { createTheme } from "@material-ui/core";
import { ManageDataService } from "network/manageDataService";
import Loader from "components/Loader/AppLoader";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "../../../../../../redux/index";
const theme = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        borderColor: "#E9E9E9"
      },
      head: {
        background: "#fafafa"
      }
    },
    MuiTableSortLabel: {
      root: {
        alignItems: "flex-start"
      }
    },
    MuiTableFooter: {
      root: {
        background: "#fafafa"
      }
    },
    MUIDataTableHeadCell: {
      sortLabelRoot: {
        height: undefined
      }
    }
  }
});
const VerifyError = ({ data, formik }) => {
  const [loading, setLoading] = useState();
  const history = useHistory();

  const download = (e) => {
    setLoading(true);
    fetch(e, {
      method: "GET"
    })
      .then((response) => {
        let fileName = e?.split("/")?.pop()?.split("?")?.[0];
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDownloadExcel = async () => {
    let payload = {
      name: formik?.values?.documents[0]?.name,
      comments: formik?.values.notes,
      template_media_id: formik?.values?.documents[0]?.id,
      excel: true
    };
    let res = await ManageDataService.templateUpload(payload);
    if (!res.success) {
      let downloadUrl = res?.error?.message?.[0]?.download_url;
      download(downloadUrl);
    }
  };

  const handleGoToList = () => {
    AppDispatcher.setGroupBy("Template");
    history.push("/u/manage_data");
  };

  const columns = [
    {
      name: "s_no",
      label: "S. No.",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={styles.mainContainer}>
            <img src={SortingToggle} />
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    {
      name: "sheet_name",
      label: "Sheet Name",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={styles.mainContainer}>
            <img src={SortingToggle} />
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    {
      name: "row",
      label: "Row",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={styles.mainContainer} style={{ width: "155px" }}>
            <img src={SortingToggle} />
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    {
      name: "column",
      label: "Column",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={styles.mainContainer}>
            <img src={SortingToggle} />
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    },
    {
      name: "error_msg",
      label: "Error Message",
      options: {
        customHeadLabelRender: (columnMeta) => (
          <div className={styles.mainContainer}>
            <img src={SortingToggle} />
            <div className={styles.headingText}> {columnMeta.label}</div>
          </div>
        )
      }
    }
  ];
  const options = {
    draggableColumns: {
      enabled: true
    },
    pagination: false,
    textLabels: {
      body: {
        noMatch: (
          <div
            style={{
              fontSize: 16,
              fontFamily: "Inter Regular",
              color: "#191919"
            }}>
            No Record Found
          </div>
        )
      }
    },
    filter: false,
    responsive: "standard",
    download: false,
    sort: false,
    selectableRows: false,
    print: false,
    viewColumns: true,
    searchOpen: false,
    search: false,
    customSearchRender: () => null,
    fixedHeader: false,
    customHeadRender: () => null
  };
  return (
    <>
      {loading && <Loader />}
      <div className={style.verifyContainer}>
        <div className={style.verifyErrorContainer}>
          <img className={style.errorIcon} src={ErrorIcon} />
          <div className={style.verifyFailedContainer}>
            <div className={style.btnContainer1}>
              <div className={style.failedTitle}>Verification Error</div>
              <div className={style.failedTitle}>({data?.length})</div>
            </div>
            <div>
              These are the following errors in your template. Please try again.
            </div>
          </div>
          <div className={styles.tableContainer}>
            <ThemeProvider theme={theme}>
              <MUIDataTable
                data={
                  data &&
                  data.map((item, index) => {
                    return {
                      s_no: (
                        <div className={styles.text}>{index + 1 || "-"}</div>
                      ),
                      sheet_name: (
                        <div className={styles.text}>
                          {item?.sheetName || "-"}
                        </div>
                      ),
                      row: (
                        <div className={styles.text}>{item?.row || "-"}</div>
                      ),
                      column: (
                        <div className={styles.text}>{item?.column || "-"}</div>
                      ),
                      error_msg: (
                        <div className={styles.text}>{item?.msg || "-"}</div>
                      )
                    };
                  })
                }
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          </div>
          <div className={style.btnContainer}>
            <div>
              <button
                className={style.cancelbtn}
                onClick={() => handleGoToList()}>
                Go to List
              </button>
            </div>
            <p
              className={style.downloadBtn}
              style={{ cursor: "pointer", whiteSpace: "nowrap" }}
              onClick={() => handleDownloadExcel()}>
              Download Excel
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default VerifyError;
