import React, { useEffect } from "react";
import { AppDispatcher } from "redux/index";
import Template from "../template/index";

function TemplateComponent() {
  useEffect(() => {
    AppDispatcher.setUploadTemplateStep(0);
  }, []);

  return <Template />;
}

export default TemplateComponent;
