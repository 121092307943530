import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  makeStyles,
  Grid
} from "@material-ui/core";
import { Form, Formik } from "formik";
import styles from "../../../../../../sponsors/editSponsor/EditSponsor.module.scss";
import Toast from "components/toast";
import blueCloseIcon from "assets/icons/NewCardSvg/blueCloseIcon.svg";
import "react-phone-input-2/lib/style.css";
import { validationSchemaAddInvestmentInvestor } from "helpers/validationSchema";
import { useSelector } from "react-redux";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import { API, NetworkManager } from "network/core";

const useStyles = makeStyles(() => ({
  root: {
    "&  .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 1242,
      height: "430px"
    }
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 48.5,
    boxSizing: "border-box"
  },
  MaterialForm: {},
  form: {},
  buttonContainer: {
    display: "flex",
    gap: "10px",
    marginBottom: 40,
    marginTop: 60
  },
  heading: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "Inter Bold"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0
  }
}));

const InvestorDilogBox = ({
  investorDilogBox,
  setInvestorDilogBox,
  setShowAddMore,
  formik,
  index,
  SetEmailSearchQuery,
  setInvEmail
}) => {
  const classes = useStyles();

  const { user } = useSelector((store) => store.app);

  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setInvestorDilogBox(false);
    setShowAddMore("");
  };

  const handleSubmit1 = async (values) => {
    setLoading(true);
    const payload = {
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      is_investor: 1,
      created_by: user.id
    };

    /*************** */
    // Invite Send Api Call
    try {
      const instance = new NetworkManager(
        API.INVESTOR.ADD_INVESTOR_EMAIL,
        payload
      );
      const resp = await instance.httpRequest();
      if (resp.success) {
        const data = resp.data;
        SetEmailSearchQuery("");
        formik.setFieldValue(
          `investorSet[${index}].investor_name`,
          ` ${data.first_name} ${data.last_name}`
        );
        formik.setFieldValue(`investorSet[${index}].investor_id`, data.id);
        setInvEmail(data.email);
        Toast.showSuccessToast("Successfully added Investor");
        handleClose();
      } else {
        Toast.showErrorToast(resp.error.message[0]);
      }
    } catch (err) {
      Toast.showErrorToast(err);
    }

    /************** */
    setLoading(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={investorDilogBox}
      style={{
        backgroundColor: "rgba(235, 239, 255, 0.6)",
        backdropFilter: "blur(26)"
      }}>
      <DialogContent>
        <div className={classes.MaterialForm}>
          <div className={classes.header}>
            <div>
              <Typography variant="h4" className={classes.heading}>
                Add Investor
              </Typography>
            </div>
            <div className={classes.closeButton}>
              <img height="48px" src={blueCloseIcon} onClick={handleClose} />
            </div>
          </div>

          <Formik
            initialValues={{
              email: "",
              first_name: "",
              last_name: ""
            }}
            validationSchema={validationSchemaAddInvestmentInvestor}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit1(values);
            }}>
            {(formik) => (
              <Form>
                <Grid container xs={12} spacing={2}>
                  <Grid item lg={4} md={6} sm={6}>
                    <label className={classes.label}>Investor Email</label>
                    <AppTextFieldWhite
                      id="entityName"
                      placeholder="Enter Email Id"
                      variant="standard"
                      fullWidth
                      value={formik.values.email}
                      onChange={formik.handleChange("email")}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid item lg={4} md={6} sm={6}>
                    <label className={classes.label}>First Name</label>
                    <AppTextFieldWhite
                      id="first_name"
                      placeholder="Enter First Name"
                      variant="standard"
                      fullWidth
                      value={formik.values.first_name}
                      onChange={formik.handleChange("first_name")}
                      error={
                        formik.touched?.first_name &&
                        Boolean(formik.errors?.first_name)
                      }
                      helperText={
                        formik.touched?.first_name && formik.errors?.first_name
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid item lg={4} md={6} sm={6}>
                    <label className={classes.label}>Last Name</label>
                    <AppTextFieldWhite
                      id="last_name"
                      placeholder="Enter Entity Name"
                      variant="standard"
                      fullWidth
                      value={formik.values.last_name}
                      onChange={formik.handleChange("last_name")}
                      error={
                        formik.touched?.last_name &&
                        Boolean(formik.errors?.last_name)
                      }
                      helperText={
                        formik.touched?.last_name && formik.errors?.last_name
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                </Grid>

                <div className={classes.buttonContainer}>
                  <button
                    className={styles.cancelbtn}
                    style={{ width: 174, margin: 0 }}
                    type="button"
                    onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={styles.updatebtn}
                    style={{ width: 174, margin: 0 }}
                    onClick={formik.handleSubmit}>
                    {loading ? "Loading..." : "Add"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvestorDilogBox;
