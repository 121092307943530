import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SearchInput from "components/Search";
import styles1 from "../../pages/private/investments/investments.module.css";
import CloseIcon from "assets/icons/cross.svg";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    paddingTop: 0
  },
  heading: {
    color: "#0C0C0C",
    fontFamily: "Inter Regular",
    fontSize: "16px",
    fontWeight: 600
  },
  summaryRoot: {
    minHeight: "62px",
    padding: "0px 23px"
  },
  iconColor: {
    color: "black"
  },
  details: {
    padding: "0px 16px"
  },
  listIcon: {
    minWidth: "0px"
  },
  title: {
    color: "#191919",
    fontFamily: "Inter SemiBold",
    fontSize: "18px",
    fontWeight: 600,
    flex: 1
  },
  searchCtr: {
    width: "100%",
    boxSizing: "border-box",
    "& div": {
      width: "100% !important"
    }
  },
  showMore: {
    fontFamily: "Inter Regular",
    fontSize: 12,
    color: "#5775E5",
    margin: 16,
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    }
  },
  listCtr: {
    maxHeight: 380,
    overflowY: "scroll"
  },
  listItemText: {
    margin: "6px 0",
    "& span": {
      fontSize: 14,
      color: "#000000",
      lineHeight: "19px",
      maxWidth: 280,
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  loaderCtr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 0"
  }
}));
export default function AccordionFilter({
  getData,
  isStaticData = false,
  hasSearchBar = true,
  checked,
  title,
  handleToggle,
  index,
  handleDelete
}) {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [search, setSearch] = useState("");
  const isLoadingRef = useRef(false);
  const searchRef = useRef(false);

  const getName = (value) => {
    if (!value) {
      return "";
    }
    return value.name ? value.name : `${value.first_name} ${value.last_name}`;
  };

  const getFilterChips = () => {
    return (
      <div className={styles1.chipsCtr} style={{ margin: 0 }}>
        {checked[index]?.value?.map((selected) => (
          <div
            key={selected.id}
            className={styles1.chips}
            style={{ margin: "0 20px 20px 0" }}>
            <div className={styles1.chipsText}>{selected.name}</div>
            <div
              onClick={() => handleDelete(checked?.[index].key, selected.id)}>
              <img className={styles1.chipsImg} src={CloseIcon} alt="cross" />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const loadMore = async () => {
    isLoadingRef.current = true;
    const resp = await getData(page + 1, search);
    setMaxPage(resp.maxPage || 1);
    setPage((prev) => prev + 1);
    setData((prev) => [...prev, ...resp.data]);
    isLoadingRef.current = false;
  };

  const handleScroll = (e) => {
    if (isLoadingRef.current) {
      return;
    }
    if (
      page < maxPage &&
      e.target.scrollHeight - e.target.scrollTop - 25 <= e.target.clientHeight
    ) {
      loadMore();
    }
  };

  useEffect(async () => {
    if (!searchRef.current) {
      searchRef.current = true;
      return;
    }
    if (isStaticData) {
      if (search) {
        setData(
          getData.filter((el) =>
            el.name.toLowerCase().includes(search.toLowerCase())
          )
        );
      } else {
        setData(getData);
      }
    } else {
      setPage(1);
      const resp = await getData(1, search);
      setMaxPage(resp.maxPage);
      setData(resp.data);
    }
  }, [search, isStaticData]);

  useEffect(async () => {
    let resp = {};
    if (isStaticData) {
      resp.data = getData;
      setMaxPage(1);
    } else {
      resp = await getData(1, "");
      setMaxPage(resp.maxPage);
    }
    setData(resp.data);
  }, [isStaticData]);

  return (
    <div className={classes.root}>
      <Accordion
        TransitionProps={{ unmountOnExit: true }}
        square={true}
        elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon classes={{ root: classes.iconColor }} />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          classes={{ root: classes.summaryRoot }}>
          <div className={classes.title}>{title}</div>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          <List className={classes.root}>
            {getFilterChips()}
            {hasSearchBar ? (
              <div className={classes.searchCtr}>
                <SearchInput setQuery={(e) => setSearch(e)} />
              </div>
            ) : null}
            <div className={classes.listCtr} onScroll={handleScroll}>
              {data.map((value) => {
                const labelId = `checkbox-list-label-${value.id}`;
                return (
                  <ListItem
                    key={labelId}
                    role={undefined}
                    dense
                    button
                    onClick={() =>
                      handleToggle(title, value.id, getName(value))
                    }>
                    <ListItemText
                      id={labelId}
                      className={classes.listItemText}
                      primary={getName(value)}
                    />
                  </ListItem>
                );
              })}
              {page < maxPage ? (
                <div className={classes.loaderCtr}>
                  <CircularProgress
                    variant="indeterminate"
                    disableShrink
                    classes={{
                      circle: classes.circle
                    }}
                    size={30}
                    thickness={4}
                  />
                </div>
              ) : null}
            </div>
          </List>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
