import React, { useEffect, useState } from "react";
import InvestmentDetailCard from "./sections/InvestmentDetailCard";
import Investors from "./sections/Investors";
import LoanInfo from "./sections/loanInfo/LoanInfo";
import Properties from "./sections/Properties";
import { InvestmentListDetailService } from "network/listInvestmentDetailService";
import { useSelector } from "react-redux";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import Toast from "components/toast";
import { numFormatter } from "helpers";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "../../../../redux/index";
import { Grid } from "@material-ui/core";
import InvestmentToggleSwitch from "./InvestmentToggleSwitch";
import styles from "./Investment.module.scss";
import Distribution from "./sections/Distribution";
import OverViewSection from "./sections/OverView/OverViewSection";
import Analytics from "./sections/Analytics/Analytics";
import { Skeleton } from "@material-ui/lab";
import ExportPDFButton from "components/Buttons/Primary/ExportPDFButton";

const InvestmentDetail = () => {
  const history = useHistory();
  const { investmentDetailId, userPermissions, user } = useSelector(
    (state) => state.app
  );
  const [data, setData] = useState();
  const [investmentName, setinvestmentName] = useState();
  const [investmentId, setInvestmentId] = useState("");
  const [investmentHeaderData, setInvestmentHeaderData] = useState({
    investment_id: "",
    status: "",
    managed_by: "",
    noiCard: "",
    purchase_price: 0,
    noiYear: ""
  });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "INVESTMENTS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };
  const skeleton = (input) => {
    return data !== undefined
      ? input
      : <Skeleton width={150} variant="text" /> || "-";
  };

  useEffect(async () => {
    checkPermissions();
    const res =
      await InvestmentListDetailService.getInvestmentListDetailService([
        investmentDetailId
      ]);

    if (res.success) {
      const data = res.data;
      setData(data);
      let headerData = { ...investmentHeaderData };

      setInvestmentId(data?.id);
      setinvestmentName(data?.investment?.name);

      headerData["investment_id"] = data?.investment?.base_id;
      headerData["status"] = data?.investment?.status;
      headerData["managed_by"] = data?.investment?.managed_by;
      headerData["noiCard"] = data?.inv_noi[0]?.investment_noi;
      headerData["purchase_price"] = data?.purchase_price;
      headerData["noiYear"] = data?.inv_noi[0]?.year;
      setInvestmentHeaderData(headerData);
    } else {
      Toast.showErrorToast("Unable To Load Investment Data");
    }
    setSelectedIndex(0);
  }, []);

  const onEditInvestmentBtnClick = () => {
    AppDispatcher.setInvestmentID(investmentId);
    AppDispatcher.resetAddInvestmentStep();
    history.push({ pathname: "/u/investments/addInvestment" });
  };

  const loadInvestmentDetails = () => {
    switch (selectedIndex) {
      case 0:
        return <Analytics />;
      case 1:
        return <OverViewSection data={data} />;
      case 2:
        return <Investors data={data} />;
      case 3:
        return <Properties data={data} />;
      case 4:
        return <LoanInfo data={data} />;
      case 5:
        return <Distribution data={data} />;
    }
  };

  return (
    <>
      <HeaderComponent
        backBtn={true}
        heading={skeleton(investmentName)}
        description={skeleton(`ID: ${investmentHeaderData.investment_id}`)}
        investment_Status={skeleton(investmentHeaderData.status)}
        managedBy={skeleton(investmentHeaderData.managed_by)}
        isEditInvestorBtn={true}
        onEditInvestmentBtnClick={onEditInvestmentBtnClick}
        ExtraButtons={<ExportPDFButton />}
      />

      <>
        {/* Cards */}
        <InvestmentDetailCard
          InvestedAmount={
            skeleton(data?.stats?.total_invested_amount)
              ? skeleton(`$${numFormatter(data?.stats?.total_invested_amount)}`)
              : "0"
          }
          DistributionAmount={
            skeleton(data?.stats?.distribution_amount)
              ? skeleton(`$${numFormatter(data?.stats?.distribution_amount)}`)
              : "0"
          }
          TotalDistribution={skeleton(data?.stats?.distribution_count || 0)}
          IRR={
            skeleton(data?.stats?.irr) && skeleton(!data?.stats?.irr == "NA")
              ? skeleton(data?.stats?.irr)
              : "-"
          }
        />

        {/* ToggleTabs */}
        <div className={styles.toggleresponiveCard}>
          <Grid
            container
            style={{
              backgroundColor: "#FAFAFA",
              borderRadius: 10
            }}
            className={styles.togglecardGrid}>
            <InvestmentToggleSwitch
              setSelectedIndex={setSelectedIndex}
              selectedIndex={selectedIndex}
            />
          </Grid>
        </div>

        {/* details */}
        <Grid container>
          <Grid item xs={12}>
            {loadInvestmentDetails()}
          </Grid>
        </Grid>
      </>
    </>
  );
};

export default InvestmentDetail;
