import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "../entityTable.module.css";
import DotsMenu from "../../../../../assets/icons/dotsMenu.svg";
import {
  Menu,
  MenuItem,
  Button,
  Tooltip,
  Hidden,
  Grid
} from "@material-ui/core";
import { AppDispatcher } from "../../../../../redux/index";
import filterIcon from "assets/icons/Filter.svg";
import BluefilterIcon from "assets/icons/blueFilter.svg";
import DotFilterIcon from "assets/icons/DotFilter.svg";
import CSV from "assets/icons/NewCardSvg/CSV.svg";
import FWDEmail from "assets/icons/NewCardSvg/FWDEmail.svg";
import SearchInput from "components/Search";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import { InvestmentListService } from "network/investment_ListService";
import Toast from "components/toast";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import { EntityService } from "network/entityService";
import CloseIcon from "../../../../../assets/icons/cross.svg";
import noEntity from "assets/emptyState/noEntity.svg";
import Illustrations from "components/illustration/Illustrations";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import MoreFilterContainer from "components/MoreFilterContainer/MoreFilterContainer";
import NewTable from "components/NewTable/NewTable";
import ManageColumn from "components/ManageColumns";
import { FilterStatus, truncate } from "helpers/Common";
import NewDropDown from "components/NewDropDown";
import LightTooltip from "components/LightTooltip";
import formatCurrency from "helpers/formatCurrency";
import FrwrdAsEmail from "components/ForwarsAsEmail";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";

const manageByList = [
  {
    label: "Managed By",
    value: "managed_by"
  },
  {
    label: "Admin",
    value: "ADMIN,SUB-ADMIN"
  },
  {
    label: "Investor",
    value: "INVESTOR"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const TableHeaders = [
  {
    id: "entity_name",
    name: "Entity Name"
  },
  {
    id: "entity_type",
    name: "Entity Type"
  },
  {
    id: "managed_by",
    name: "Managed By"
  },
  {
    id: "tax_id",
    name: "Tax ID (EIN)"
  },
  {
    id: "mailing_address",
    name: "Mailing Address"
  },
  {
    id: "associated_investments",
    name: "Associated Investments"
  },
  {
    id: "total_invested_amount",
    name: "Total Invested Amount"
  },
  {
    id: "status",
    name: "Status"
  }
];
function Table({ tableData, permissions }) {
  const history = useHistory();
  const [data, setData] = useState(tableData);
  const [THeaders, setTHeaders] = useState(TableHeaders);
  const [manageBy, setManageBy] = useState("managed_by");
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [Export, setExport] = useState("exportList");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showFilter, setShowFilter] = useState(false);
  const [checked, setChecked] = useState([]);
  const [entityStatus, setEntityStatus] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [loading, setLoading] = useState(false);
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);

  // const [groupBy, setGroupBy] = useState("All");
  // const [groupByList] = useState([
  //   { label: "Investor", value: "INVESTOR" },
  //   { label: "Sponsor", value: "SPONSOR" },
  //   { label: "All", value: "All" }
  // ]);
  const [status, setStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageResetValue, setPageResetValue] = useState(0);
  const [isFilterActive, setFilterActive] = useState(false);
  const [expandIndex, setExpandIndex] = useState(-1);
  const [apprenace, setApperence] = useState();

  const mappingObject = {
    "Associated Investments": "investment_id",
    "Entity Name": "entity_id"
  };

  const handleApply = (data) => {
    setShowFilter(false);
    setChecked([...data]);
  };

  const handleCellClick = (item) => {
    AppDispatcher.setEntityId(item?.id);
    AppDispatcher.setEntitiesDetailData({
      id: item?.id,
      entity_type: item?.entity_type
    });
    history.push("/u/entities/detail");
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const getAllRoles = async () => {
    const response = await EntityService.getAllRoles();
    if (response) {
      const role = response.data;
      let allRole = {};
      role.map((item) => {
        allRole = { ...allRole, [item.name]: item.id };
      });
      AppDispatcher.setALLRole(allRole);
    }
  };

  const getEntityData = async (pageNum, isAppend = false) => {
    setLoading(true);
    let url = `?status=${status}`;
    // if (groupBy !== "All") {
    // url += `&entity_type=${groupBy}`;
    // }
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (manageBy !== "managed_by") {
      url += `&managed_by=${manageBy}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    url += `&page=${pageNum}&page_size=${pageSize}`;
    const response = await EntityService.filterEntities([url]);
    if (response.success) {
      if (isAppend) {
        setData([...data, ...response.data.results]);
      } else {
        setData(response.data.results);
      }
      setCount(response.data.count);
    }
    setLoading(false);
  };

  const getEntityNames = async (page = 1, searchText = "") => {
    let params = "";
    params += `?query={id,name}&page=${page}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }
    const response = await EntityService.filterEntities([params]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getInvestmentNames = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestmentName([
      `?query={id,name}${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const handleClear = () => {
    setShowFilter(false);
    setChecked([]);
  };

  const handleClick = (event, item, type, managed_by) => {
    setAnchorEl(event.currentTarget);
    AppDispatcher.setEntityId(item);
    AppDispatcher.setEntitiesIdAndType({ id: item, entity_type: type });

    setApperence(managed_by === "Admin");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    history.push("/u/entities/editEntity");
  };

  const onHandleClick = () => {
    setShowFilter(!showFilter);
  };

  const ChipsComponent = () => {
    let newArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        newArray.push(newObj);
      });
    });

    return (
      <div className={styles.chipsCtr}>
        {newArray.length > 0
          ? newArray.map((item, index) => {
              return (
                <div key={index} className={styles.chips}>
                  <div className={styles.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
  };

  const handleDeleteEntity = () => {
    history.push("/u/entities/deleteEntities");
  };

  const fetchStatus = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setEntityStatus(res.data.ENTITY_STATUS);
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const handleCellItemClick = (e, type, id) => {
    e.stopPropagation();
    if (id) {
      switch (type) {
        case "investment":
          AppDispatcher.setInvestmentDetailID(id);
          history.push("/u/investments/detail");
          break;
      }
    }
  };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getEntityData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  // const getQueryParams = () => {
  //   let url = `?status=${status}`;
  // if (groupBy !== "All") {
  // url += `&entity_type=${groupBy}`;
  //   }
  //   if (searchQuery.length > 0) {
  //     url += `&search=${searchQuery}`;
  //   }
  //   if (checked.length > 0) {
  //     checked.forEach((item) => {
  //       if (item.value.length > 0) {
  //         url += `&${mappingObject[item.key]}=`;
  //         item.value.forEach((el) => {
  //           url += `${el.id},`;
  //         });
  //         url = url.substring(0, url.length - 1);
  //       }
  //     });
  //   }
  //   return url;
  // };

  // const downloadCsv = async () => {
  //   setLoading(true);
  //   let url = getQueryParams();
  //   url += `&csv-download=true`;

  //   const resp = await EntityService.filterEntities([url]);
  //   if (resp.success) {
  //     const link = document.createElement("a");
  //     link.href = resp?.data?.url;
  //     link.setAttribute("download", `${resp?.data?.url}`);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   } else {
  //     Toast.showErrorToast("Failed To Download CSV");
  //   }
  //   setLoading(false);
  // };

  const setFilterMeta = () => {
    setFilterState([
      {
        key: "Associated Investments",
        value: getInvestmentNames
      },
      {
        key: "Entity Name",
        value: getEntityNames
      }
    ]);
  };

  useEffect(() => {
    setData(tableData);
  }, [tableData]);

  useEffect(() => {
    setFilterMeta();
    fetchStatus();
    getAllRoles();
  }, []);

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getEntityData(1);
    setFilterActive(FilterStatus(checked));
  }, [
    status,
    searchQuery,
    checked,
    pageSize,
    count,
    manageBy
    // groupBy
  ]);

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = `?status=${status}`;

    if (emailData) {
      if (preview === "preview") {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `&${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    // if (groupBy !== "All") {
    // url += `&entity_type=${groupBy}`;
    // }
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (manageBy !== "managed_by") {
      url += `&managed_by=${manageBy}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    url += `&page=${page}&page_size=${pageSize}`;

    setExport("exportList");
    const response = await EntityService.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          // link.target = "_blank";
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  /******************************************************************** */
  /*********************       New Table Data           *****************/
  /*********************************************************************/

  const getTooltipData = (item) => {
    const mainDiv = {
      display: "flex",
      gap: "20px",
      padding: "12px",
      width: "fit-content"
    };
    const heading = {
      fontSize: "16px",
      fontWeight: 400,
      color: "#A0A0A0",
      minWidth: "180px"
    };
    const Value = {
      fontSize: "16px",
      fontWeight: 600,
      color: "#191919"
    };
    return (
      <>
        <div style={mainDiv}>
          <h3 style={heading}>Entity ID:</h3>
          <h3 style={Value}>{item?.base_id || "-"}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Partners:</h3>
          <h3 style={Value}>
            {item?.partners.length
              ? item?.partners?.map((ele, i) => {
                  if (i == 0) {
                    return <div key={i}>{`${ele.name}`}</div>;
                  } else {
                    return <div key={i}>{`,${ele.name}`}</div>;
                  }
                })
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Created By:</h3>
          <h3 style={Value}>{item?.created_by?.name || "-"}</h3>
        </div>
      </>
    );
  };

  const Tabledatalist =
    data &&
    data.map((item, index) => {
      return [
        <LightTooltip key="entity_name" title={getTooltipData(item)}>
          <div
            className={`${styles.text} ${styles.dotsMenu}`}
            onClick={() => handleCellClick(item)}>
            {truncate(item.name, 15) || "-"}
          </div>
        </LightTooltip>,
        <div
          key="entity_type"
          className={`${styles.text} ${styles.d_flex_centre} }`}>
          {item?.entity_type ? <div>{item?.entity_type}</div> : "-"}
        </div>,
        <div key="managed_by" className={`${styles.text} `}>
          {item?.managed_by || "-"}
        </div>,
        <div key="tax_id" className={`${styles.text} `}>
          {item.tax_id || "-"}
        </div>,
        <div key="mailing_address" className={`${styles.text}`}>
          {truncate(
            item?.address?.length ? item?.address[0]?.address : "-",
            15
          )}
        </div>,
        <div key="associated_investments">
          {item?.investment?.length ? (
            <NewDropDown
              index={index}
              item={item?.investment?.map((item) => {
                return {
                  name: item?.name,
                  id: item?.id
                };
              })}
              handleCellItemClick={handleCellItemClick}
              type="investment"
              expandIndex={expandIndex}
              setExpandIndex={setExpandIndex}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>,
        <div key="total_invested_amount" className={`${styles.text} `}>
          {item?.total_invested
            ? `${formatCurrency(item?.total_invested).split(".")?.[0]}`
            : "$0"}
        </div>,
        <div
          key="status"
          style={{
            minHeight: 28,
            width: 72,
            alignItems: "center",
            backgroundColor:
              item?.status === "Pending"
                ? "#EBEFFF"
                : item?.status == "Active"
                ? "#EDFFEF"
                : "#FCEBEB ",
            borderRadius: 153,
            textAlign: "center",
            fontFamily: "Inter Bold",
            fontSize: 14,
            paddingTop: 4,
            color:
              item?.status == "Active"
                ? "#50E138"
                : item?.status === "Pending"
                ? "#445EBE"
                : "#E13838",
            fontWeight: 700
          }}>
          {item?.status}
        </div>,
        (permissions?.is_update || permissions?.is_delete) && (
          <div key="Action">
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) =>
                handleClick(e, item?.id, item?.entity_type, item?.managed_by)
              }>
              <img src={DotsMenu} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  marginTop: 40,
                  boxShadow: "2px 2px 4px #E9E9E9"
                }
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}>
              {permissions?.is_update ? (
                <div>
                  <MenuItem onClick={handleEdit} className={styles.menuItem}>
                    Edit Entity
                  </MenuItem>
                </div>
              ) : null}
              {permissions?.is_delete ? (
                <MenuItem
                  className={styles.menuItemDelete}
                  onClick={() => {
                    apprenace && handleDeleteEntity();
                  }}>
                  {apprenace ? (
                    "Delete"
                  ) : (
                    <div style={{ color: "#ababa2" }}>Delete</div>
                  )}
                </MenuItem>
              ) : null}
            </Menu>
          </div>
        )
      ];
    });

  /******************************************************************* */
  /******************************************************************* */
  /******************************************************************* */

  return (
    <>
      <FrwrdAsEmail
        AllHeaders={TableHeaders}
        FrdasEmail={FrdasEmail}
        setFrdasEmail={setFrdasEmail}
        open={frdEmailOpen}
        setOpen={setFrdasEmailOpen}
        clickOnShare={(payload) => {
          getExport_PDF_CSV_Link(payload);
        }}
        clickOnPreview={(payload) => {
          getExport_PDF_CSV_Link(payload, "preview");
        }}
      />
      <div className={styles.tableCtrOuter}>
        <TableTopFilter
          tabletViewCSV={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={CSV}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={ExportList}
                clickMenuItem={(obj, close) => {
                  setExport(obj?.value);
                  close();
                }}
              />
            </div>
          }
          tabletViewFWDEmail={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={FWDEmail}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={frdasEamilList}
                clickMenuItem={(obj, close) => {
                  setFrdasEmail(obj?.value);
                  close();
                }}
              />
            </div>
          }
          filterSection1={[
            <SearchInput key={0} setQuery={setQuery} />,
            <CustomSelectFilter
              key={1}
              border={true}
              placeholder="Managed By"
              items={manageByList}
              onChange={(e) => setManageBy(e.target.value)}
              value={manageBy}
            />,
            <CustomSelectFilter
              key={2}
              border={true}
              placeholder="Status"
              items={entityStatus}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            />,

            // <CustomSelect
            //   placeholder="Status"
            //   style={{
            //     width: "13vw",
            //     maxWidth: "333px",
            //     border: "none "
            //   }}
            //   items={groupByList}
            //   onChange={(e) => setGroupBy(e.target.value)}
            //   value={groupBy}
            // />,
            <Tooltip key={3} title="More Filters" arrow>
              <div className={styles.clickable} onClick={onHandleClick}>
                <img
                  src={
                    isFilterActive
                      ? DotFilterIcon
                      : showFilter
                      ? BluefilterIcon
                      : filterIcon
                  }
                  alt="alt"
                  layout="fixed"
                  width={45}
                  height={45}
                />
              </div>
            </Tooltip>,

            showFilter && (
              <>
                <MoreFilterContainer
                  key={4}
                  open={showFilter}
                  checked={checked}
                  handleClose={onHandleClick}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filters={filterState}
                />
              </>
            )
          ]}
          filterSection2={[
            <ManageColumn
              key={0}
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />,
            <CustomSelectFilter
              key={1}
              border={true}
              placeholder="Forward as Email"
              items={frdasEamilList}
              value={FrdasEmail}
              onChange={(e) => setFrdasEmail(e.target.value)}
            />,
            <CustomSelectFilter
              key={2}
              border={true}
              placeholder="Export"
              items={ExportList}
              value={Export}
              onChange={(e) => setExport(e.target.value)}
            />
          ]}
        />
        <Hidden smUp>
          <Grid item xs={12}>
            <ManageColumn
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />
          </Grid>
        </Hidden>

        <ChipsComponent />

        <NewTable
          loading={loading}
          Header={[
            ...THeaders,
            (permissions?.is_update || permissions?.is_delete) && {
              id: "Action",
              name: "Action"
            }
          ]}
          tableData={Tabledatalist}
          pageSize={pageSize}
          changePage={changePage}
          changePageSize={changePageSize}
          count={count}
          pageResetValue={pageResetValue}
          labelRowsPerPage="Total No Of Entities Per Page"
          Illustration={
            <Illustrations
              src={noEntity}
              heading="No Entities Found"
              subHeading="Add Entities To View The List"
            />
          }
        />
      </div>
    </>
  );
}
export default Table;
