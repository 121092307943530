import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, Grid, Box } from "@material-ui/core";
import { FieldArray, Form, Formik } from "formik";
import BasicDetails from "./components/basicDetail";
import Noi from "./components/noi";
import styles from "../../../investments.module.css";
// import AddIcon from "../../../../../../assets/icons/addIcon.svg";
import plusAdd from "../../../../../../assets/icons/PlusAdd.svg";
import {
  ValidationSchemeAddInvestmentBasicDetails,
  ValidationSchemeAddInvestmentBasicDetailsDraft
} from "../../../../../../helpers/validationSchema";
import Tier from "./components/tier";
import Contacts from "./components/contacts";
import { AppDispatcher } from "../../../../../../redux/index";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import Toast from "components/toast";
import { formatDate } from "helpers";
import Loader from "components/Loader/AppLoader";
import { AddInvestmentPreviewService } from "network/addInvestmentPreviewService";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FieldLayout } from "../../components/FieldLayout";
import EquityInformation from "./components/EquityInformation";
import NewCategoryDocTable from "pages/private/properties/component/NewCategoryDocTable";
import StepOneProperties from "./components/Properties";
import NewSponsorEntity from "./components/NewSponsorEntity";
import formatCurrency from "helpers/formatCurrency";
import ViewHistoryPopup from "pages/private/investments/detail/sections/OverView/ViewHistoryPopup";
import PropertyPopUp from "./components/PropertyPopUp";
import UpdateBtn from "components/Buttons/Primary/UpdateBtn";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    marginTop: 36,
    borderRadius: 20
  },
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    alignItems: "center"
  },
  addTier: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    width: 160,
    marginTop: 33.5
  }
}));

const Basic = () => {
  const { user } = useSelector((store) => store.app);
  const classes = useStyles();
  const [isUpdate, setisUpdate] = useState(false);
  const [isEditButton, setisEditButton] = useState(false);
  const [ClickonOverViewEdit, SetClickonOverViewEdit] = useState(true);
  const [ClickonEquitInfoEdit, SetClickonEquitInfoEdit] = useState(true);
  const [ClickonSponsorEdit, SetClickonSponsorEdit] = useState(true);
  const [ClickonSponsorTiersEdit, SetClickonSponsorTiersEdit] = useState(true);
  const [ClickonNoiEdit, SetClickonNoiEdit] = useState(true);
  const [ClickonPropertyEdit, SetClickonPropertyEdit] = useState(true);
  const [ClickonContactsEdit, SetClickonContactsEdit] = useState(true);

  const [initialValues, setInitialValues] = useState({
    isEditCase: false,
    /**************  Basic ******************/
    investment_name: "",
    purchase_price: "",
    start_date: "",
    distribution_cadence: "",
    dateOfSale: null,
    priceOfSale: "",

    // not Required Fields
    is_refinanced: "",
    projected_returns: "",
    closing_date: "",
    distribution_time_frame: "",
    cap_rate: "",
    total_raise: "",

    /*****************Equity Information******************* */
    equity_info: [
      {
        total_asset: "",
        outstanding: "",
        bank_name: "",
        // optional Field
        debtservice: "",
        date: "",
        notes: "",
        equity_amount: ""
      }
    ],
    /********************Sponsor**************************** */
    Sponsor: {
      taxId: "",
      id: [],
      sponsor_entity_name: "",
      mailing_address: "",
      master_sponsor: [
        {
          sponsor: ""
        }
      ],
      sponsors_details: [
        { email: "", name: "", company_name: "", code: "", phone: "" }
      ]
    },

    /**********************Sponsor Tiers**************** */
    sponsorSet: [],
    /**********************Net Operating Income*************** */
    noiSet: [],
    /**********************Property************************** */
    properties: [],
    /*********************Contacts************************** */
    associatedContactsSet: [],
    /*****************Upload Documents************************* */
    documents: []
  });

  const [investmentStatus, setInvestmentStatus] = useState("Draft");
  const [draft, setDraft] = useState(false);
  const [test, setTest] = useState("");
  const [cadenceChoices, setCadenceChoices] = useState([]);
  const [noiCadenceChoices, setNoiCadenceChoices] = useState([]);
  const [quarterChoices, setQuarterChoices] = useState([]);
  const [timeFrameChoices, setTimeFrameChoices] = useState([]);
  const [cadenceMapping, setCadenceMapping] = useState([]);
  const [loading, setLoading] = useState(false);
  const { investmentID } = useSelector((store) => store.app);
  const [docCategories, setDocCategories] = useState([]);
  const [noiCategories, setNoiCategories] = useState([]);
  const [historyOpen, setHistoryOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [PropertyValuePopup, setPropertyValuePopup] = useState(false);
  const [PreviousProperty, setPreviousProperty] = useState([]);
  const [popupNoClick, setpopupNoClick] = useState(false);

  const getOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setCadenceChoices([
        res.data.DISTRIBUTION_CADENCE_CHOICES[0],
        res.data.DISTRIBUTION_CADENCE_CHOICES[1]
      ]);
      setTimeFrameChoices(res.data.DISTRIBUTION_TIME_FRAME_CHOICES);
      setCadenceMapping(res.data.DISTRIBUTION_CADENCE_CHOICES_MAPPING);
      setNoiCadenceChoices(res.data.NOI_CADENCE_CHOICES);
      setQuarterChoices(res.data.QUARTER_CHOICES);
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const history = useHistory();

  const getNoiSets = (values) => {
    const noiSetArray = [];
    let noiSetObj = {};

    values.length &&
      values.forEach((el) => {
        noiSetObj.investment_noi = el.investment_noi;
        noiSetObj.year = el.year;
        noiSetObj.noi_cadence = el.noi_cadence;
        noiSetObj.noi_quarter = el?.noi_quarter;
        noiSetObj.id = el.id;
        noiSetObj.documents = formatDocsData(el?.documents);

        noiSetArray.push(noiSetObj);
        noiSetObj = {};
      });
    return noiSetArray;
  };

  const getTiers = (values) => {
    const tierArray = [];
    let tierObj = {};
    values.length &&
      values.forEach((el) => {
        tierObj.startfrom = el.startfrom;
        tierObj.to = el.to;
        tierObj.promote = el.promote;
        tierObj.id = el.id;
        tierArray.push(tierObj);
        tierObj = {};
      });
    return tierArray;
  };

  const getSponsorArray = (sponsors) => {
    let sponsorArray = [];
    let sponsorObj = {};
    sponsors &&
      sponsors.length &&
      sponsors.forEach((element) => {
        sponsorObj.id = element.id;
        sponsorObj.email = element.sponsor_detail.email;
        sponsorObj.name = element.sponsor_detail.name;
        sponsorObj.company_name = element.sponsor_detail.company_name;
        sponsorObj.code = element.sponsor_detail.country_code;
        sponsorObj.phone = element.sponsor_detail.phone_number;

        sponsorArray.push(sponsorObj);
        sponsorObj = {};
      });
    return sponsorArray;
  };

  const getProperties = (items) => {
    const properties =
      items.length &&
      items.map((el) => {
        const obj = {};
        obj["base_id"] = el?.base_id;
        obj["id"] = el?.id;
        obj["name"] = el?.name;
        obj["value"] = el?.value;
        obj["address"] = [
          {
            address: el?.property_address?.address,
            zip_code: el?.property_address?.zip_code
          }
        ];
        obj["area"] = el?.area;
        obj["units"] = el?.units;
        obj["status"] = el?.status;
        obj["type"] = el?.type;
        obj["occupancy"] = el?.occupancy;
        obj["build_year"] = el?.build_year;
        obj["description"] = el?.description;
        obj["images"] = el?.images;
        obj["documents"] = formatDocsData(el?.documents);

        obj["noi"] = el?.property_noi?.map((property) => {
          return {
            id: property?.id,
            property_noi: property?.property_noi,
            noi_cadence: property?.noi_cadence,
            noi_quarter: property?.noi_quarter,
            year: property?.year,
            documents: formatDocsData(property?.documents)
          };
        });
        return obj;
      });
    return properties;
  };

  const getContacts = (values) => {
    const contactsArray = [];
    let contactsObj = {};

    values.length &&
      values.forEach((el) => {
        contactsObj.first_name = el.first_name;
        (contactsObj.last_name = el.last_name),
          (contactsObj.email = el.email),
          (contactsObj.phone_number = el.phone_number),
          (contactsObj.country_code = el.country_code),
          (contactsObj.designation = el.designation),
          (contactsObj.company_name = el.company_name),
          (contactsObj.is_primary = el.is_primary);
        contactsObj.id = el.id;
        contactsArray.push(contactsObj);
        contactsObj = {};
      });
    return contactsArray;
  };

  const formatDocsData = (docsList) => {
    return docsList?.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      size: el?.document_media?.file_size,
      id: el?.document_media?.id,
      document_id: el?.id,
      docId: el?.id
    }));
  };

  const getBasicDetails = async (id) => {
    setLoading(true);
    const resp = await AddInvestmentPreviewService.getInvestmentDetails(
      `${id}/`
    );
    if (resp.success) {
      let result = resp.data;
      let apiData = { ...initialValues };
      /***************OverView**************** */
      apiData["isEditCase"] = true;
      apiData["investment_name"] = result?.investment?.name;
      apiData["purchase_price"] = result?.investment?.purchase_price;
      apiData["start_date"] = result?.investment?.start_date;
      apiData["distribution_cadence"] =
        result?.investment?.distribution_cadence?.toLowerCase();
      apiData["dateOfSale"] = result?.investment?.sold_date;
      apiData["priceOfSale"] = result?.investment?.sold_price;
      apiData["is_refinanced"] = result?.investment?.is_refinanced;

      apiData["projected_returns"] = result?.investment?.projected_returns;
      apiData["closing_date"] = result?.investment?.closing_date;
      apiData["distribution_time_frame"] =
        result?.investment?.distribution_time_frame;
      apiData["cap_rate"] = result?.investment?.cap_rate;
      apiData["total_raise"] = result?.investment?.total_raise;

      /*************** Equity Information ***************************/
      apiData["equity_info"] = [result.equity_info];
      setHistoryData(result?.equity_info?.equity_history);
      /***************      Sponsor   ********************** */
      const entityTypeIdArray = [];
      result?.sponsor_data.map((item) => {
        if (Object.keys(item.entity).length !== 0) {
          entityTypeIdArray.unshift(item);
        } else {
          entityTypeIdArray.push(item);
        }
      });

      apiData["Sponsor"] = {
        taxId: entityTypeIdArray?.[0]?.entity?.tax_id,
        id: [],
        sponsorLength: entityTypeIdArray.length - 1,
        sponsor_entity_name: entityTypeIdArray?.[0]?.entity?.name,
        mailing_address: entityTypeIdArray?.[0]?.address?.[0]?.address,
        master_sponsor: [
          {
            id: entityTypeIdArray?.[0].id,
            sponsor: entityTypeIdArray?.[0].entity_id
          }
        ],
        sponsors_details: getSponsorArray(entityTypeIdArray)
      };

      /********************Sponsor Tiers ************************* */
      result?.sponsor_tier
        ? (apiData["sponsorSet"] = getTiers(result?.sponsor_tier))
        : (apiData["sponsorSet"] = []);

      /******************** Net Operating Income*********************/
      result.inv_noi.length
        ? (apiData["noiSet"] = getNoiSets(result.inv_noi))
        : (apiData["noiSet"] = []);

      /*******************Properties******************************* */
      result.properties?.length
        ? (apiData["properties"] = getProperties(result.properties))
        : (apiData["properties"] = []);
      /**********************  Contacts ************************** */
      result?.contacts?.length
        ? (apiData["associatedContactsSet"] = getContacts(result?.contacts))
        : (apiData["associatedContactsSet"] = []);

      /****************** Upload Documents ************************ */
      apiData["documents"] = formatDocsData(result.documents);

      setInitialValues(apiData);
      setInvestmentStatus(result?.investment?.status);
      setLoading(false);
    } else {
      Toast.showErrorToast("Failed To Fetch Investment Detail.");
    }
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENT"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const loadNoiCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTNOI"
    });
    if (res.success) {
      res.data.length && setNoiCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const returnEquityInfo = (item) => {
    const equityObj = {};
    item.map((ele) => {
      ele?.id ? (equityObj["id"] = ele.id) : "";
      ele.total_asset ? (equityObj["total_asset"] = ele.total_asset) : "";
      ele.outstanding ? (equityObj["outstanding"] = ele.outstanding) : "";
      ele.bank_name ? (equityObj["bank_name"] = ele.bank_name) : "";

      ele.debtservice ? (equityObj["debtservice"] = ele.debtservice) : "";
      if (ele.date === "NaN-NaN-NaN") {
        ele.date ? "" : "";
      } else {
        ele.date ? (equityObj["date"] = ele.date) : "";
      }
      ele.updated_at
        ? (equityObj["as_on"] = formatDate(ele.updated_at))
        : (equityObj["as_on"] = formatDate(new Date()));
      ele.notes ? (equityObj["notes"] = ele.notes) : "";
      ele.equity_amount ? (equityObj["equity_amount"] = ele.equity_amount) : "";
    });

    if (Object.keys(equityObj).length === 0) {
      return [];
    } else {
      return [equityObj];
    }
  };

  const ReturnProperty = (item) => {
    let pro_array = [];
    item.map((ele) => {
      const obj1 = {};
      ele?.id ? (obj1.id = ele?.id) : "";
      ele?.id
        ? (obj1.updated_by_id = user?.id)
        : ele.name &&
          ((obj1.updated_by_id = user?.id), (obj1.created_by_id = user?.id));
      ele.name ? (obj1.name = ele.name) : "";
      ele.value ? (obj1.value = ele.value) : "";

      let adobj = {};
      ele?.address?.[0]?.address
        ? (adobj.address = ele?.address?.[0]?.address)
        : "";
      ele?.address?.[0]?.zip_code
        ? (adobj.zip_code = ele?.address?.[0]?.zip_code)
        : "";

      Object.keys(adobj).length > 0 ? (obj1.property_address = adobj) : "";

      ele.area ? (obj1.area = ele.area) : "";
      ele.units ? (obj1.units = ele.units) : "";
      ele.status ? (obj1.status = ele.status) : "";
      ele.type ? (obj1.type = ele.type) : "";
      ele.occupancy ? (obj1.occupancy = ele.occupancy) : "";
      ele.build_year ? (obj1.build_year = ele.build_year) : "";
      ele.description ? (obj1.description = ele.description) : "";
      ele.images_list?.length > 0 ? (obj1.images_list = ele.images_list) : "";
      ele.documents?.length > 0
        ? (obj1.documents = ele.documents?.map((doc) => ({
            document_media_id: doc.id,
            category_id: doc.type,
            ...(doc.docId ? { id: doc.docId } : {})
          })))
        : "";

      let noiArray = [];
      ele.noi?.length > 0 &&
        ele.noi.map((item) => {
          const obj2 = {};
          item?.id ? (obj2.id = item?.id) : "";
          item.property_noi ? (obj2.property_noi = item.property_noi) : "";
          item.noi_cadence ? (obj2.noi_cadence = item.noi_cadence) : "";
          item.noi_quarter ? (obj2.noi_quarter = item.noi_quarter) : "";
          item.year ? (obj2.year = item.year) : "";
          item.documents.length > 0
            ? (obj2.documents = item.documents?.map((doc) => ({
                document_media_id: doc.id,
                category_id: doc.type,
                ...(doc.docId ? { id: doc.docId } : {})
              })))
            : "";

          if (Object.keys(obj2).length === 0) {
            noiArray = [];
          } else {
            noiArray.push(obj2);
          }
        });

      noiArray.length > 0 ? (obj1.noi = noiArray) : "";

      if (Object.keys(obj1).length === 0) {
        pro_array = [];
      } else {
        pro_array.push(obj1);
      }
    });

    return pro_array;
  };

  const returnNoiArray = (item) => {
    let allPropertySets = {};
    let payload = [];
    item &&
      item.forEach((val) => {
        if (val.investment_noi && val.year !== "") {
          allPropertySets.id = val.id;
          allPropertySets.investment_noi = val.investment_noi;
          allPropertySets.year = val.year;
          allPropertySets.noi_cadence = val.noi_cadence;
          allPropertySets.noi_quarter = val.noi_quarter;
          allPropertySets.documents = val.documents?.map((doc) => ({
            document_media_id: doc.id,
            category_id: doc.type,
            ...(doc.docId ? { id: doc.docId } : {})
          }));
          payload.push(allPropertySets);
          allPropertySets = {};
        }
      });
    return payload;
  };

  const returnSponsorArray = (item) => {
    let allPropertySets = {};
    let payload = [];
    item &&
      item.forEach((val) => {
        if (val.startfrom && val.to && val.promote) {
          (allPropertySets.startfrom = val.startfrom),
            (allPropertySets.to = val.to);
          allPropertySets.promote = val.promote;
          if (val.id) {
            allPropertySets.id = val.id;
          }
          payload.push(allPropertySets);
          allPropertySets = {};
        }
      });
    return payload;
  };

  const returnContactArray = (item) => {
    let allPropertySets = {};
    let payload = [];
    item &&
      item.forEach((val) => {
        if (
          val.first_name &&
          val.last_name &&
          val.email &&
          val.phone_number &&
          val.country_code &&
          val.designation
        ) {
          allPropertySets.first_name = val.first_name;
          allPropertySets.last_name = val.last_name;
          allPropertySets.email = val.email;
          allPropertySets.phone_number = val.phone_number;
          allPropertySets.country_code = val.country_code;
          allPropertySets.designation = val.designation;
          allPropertySets.company_name = val.company_name;
          allPropertySets.is_primary = val.is_primary;
          if (val.id) {
            allPropertySets.id = val.id;
          }
          payload.push(allPropertySets);
          allPropertySets = {};
        }
      });
    return payload;
  };

  const handleBasicDetails = async (val) => {
    setLoading(true);
    AppDispatcher.setEquityAmount(val.equity_info[0].equity_amount);
    AppDispatcher.setOutStandingLoan(val.equity_info[0].outstanding);

    let sposorArray = val.Sponsor.id.filter((item) => {
      return item;
    });

    const payload = {
      /**********OverView*********** */
      ...(ClickonOverViewEdit &&
        investmentID && { id: investmentID, updated_by: user?.id }),
      ...(ClickonOverViewEdit && { name: val.investment_name }),
      ...(ClickonOverViewEdit && { purchase_price: val.purchase_price }),
      ...(ClickonOverViewEdit && { start_date: formatDate(val.start_date) }),
      ...(ClickonOverViewEdit && {
        distribution_cadence: val.distribution_cadence
      }),
      ...(ClickonOverViewEdit && {
        sold_date: val.dateOfSale ? formatDate(val.dateOfSale) : null
      }),
      ...(ClickonOverViewEdit && {
        sold_price: val.priceOfSale ? val.priceOfSale : null
      }),
      ...(ClickonOverViewEdit &&
        val.is_refinanced && { is_refinanced: val.is_refinanced }),

      // not mandatory field
      ...(ClickonOverViewEdit &&
        val.projected_returns && {
          projected_returns: val.projected_returns
        }),
      ...(ClickonOverViewEdit &&
        val.closing_date && { closing_date: formatDate(val.closing_date) }),
      ...(ClickonOverViewEdit &&
        val.distribution_time_frame && {
          distribution_time_frame: val.distribution_time_frame
        }),
      ...(ClickonOverViewEdit && val.cap_rate && { cap_rate: val.cap_rate }),
      ...(ClickonOverViewEdit &&
        val.total_raise && { total_raise: val.total_raise }),

      /*********** Equity Information ******************* */
      ...(ClickonEquitInfoEdit &&
        returnEquityInfo(val.equity_info).length > 0 && {
          equity_info: returnEquityInfo(val.equity_info)
        }),

      /**************** SPONSOR ******************** */
      ...(ClickonSponsorEdit && {
        master_sponsor: val.Sponsor.master_sponsor
      }),

      ...(ClickonSponsorEdit && {
        ...(sposorArray.length && { sponsor: sposorArray })
      }),

      /*****************Sponsor Tiers ************* */
      ...(ClickonSponsorTiersEdit && {
        tier: returnSponsorArray(val.sponsorSet)
      }),

      /*******NOI********* */
      ...(ClickonNoiEdit && { inv_noi: returnNoiArray(val.noiSet) }),

      /***********Properties********* */
      ...(ClickonPropertyEdit && {
        properties: ReturnProperty(val.properties)
      }),

      /*************Contacts********** */
      ...(ClickonContactsEdit && {
        contacts: returnContactArray(val.associatedContactsSet)
      }),

      /***********Documents *********** */
      documents: val.documents?.map((doc) => ({
        document_media_id: doc.id,
        category_id: doc.type,
        ...(doc.docId ? { id: doc.docId } : {})
      })),
      ...(!isEditButton && { created_by: user.id }),
      updated_by: user.id
    };

    let payloadSaveAsDraft = {};

    /************OverView****************** */
    val.investment_name && (payloadSaveAsDraft.name = val.investment_name);
    val.purchase_price &&
      (payloadSaveAsDraft.purchase_price = val.purchase_price);
    val.start_date &&
      (payloadSaveAsDraft.start_date = formatDate(val.start_date));
    val.dateOfSale &&
      (payloadSaveAsDraft.sold_date = formatDate(val.dateOfSale));
    val.priceOfSale &&
      (payloadSaveAsDraft.sold_price = formatDate(val.priceOfSale));
    val.distribution_cadence &&
      (payloadSaveAsDraft.distribution_cadence = val.distribution_cadence);
    val.is_refinanced && (payloadSaveAsDraft.is_refinanced = val.is_refinanced);

    val.projected_returns &&
      (payloadSaveAsDraft.projected_returns = val.projected_returns);
    val.closing_date &&
      (payloadSaveAsDraft.closing_date = formatDate(val.closing_date));
    val.distribution_timeFrame &&
      (payloadSaveAsDraft.distribution_time_frame = val.distribution_timeFrame);
    val.cap_rate && (payloadSaveAsDraft.cap_rate = val.cap_rate);
    val.total_raise && (payloadSaveAsDraft.total_raise = val.total_raise);

    /*******************Equity Information ************** */
    returnEquityInfo(val.equity_info).length > 0 &&
      (payloadSaveAsDraft.equity_info = returnEquityInfo(val.equity_info));

    /********************Sponsors********************* */
    val.Sponsor.master_sponsor[0].sponsor &&
      (payloadSaveAsDraft.master_sponsor = val.Sponsor.master_sponsor);
    sposorArray.length && (payloadSaveAsDraft.sponsor = sposorArray);

    /******************Sponsors Tiers ***************** */
    payloadSaveAsDraft.tier = returnSponsorArray(val.sponsorSet);

    /*******************NOI******************* */
    payloadSaveAsDraft.inv_noi = returnNoiArray(val.noiSet);

    /*********************Properties ********* */
    ReturnProperty(val.properties).length > 0 &&
      (payloadSaveAsDraft.properties = ReturnProperty(val.properties));

    /********************Contacts ************ */
    payloadSaveAsDraft.contacts = returnContactArray(val.associatedContactsSet);

    payloadSaveAsDraft.created_by = user.id;
    payloadSaveAsDraft.updated_by = user.id;

    if (val.isEditCase) {
      payload.id = investmentID;
      payloadSaveAsDraft.id = investmentID;
    }

    if (draft) {
      if (val.isEditCase) {
        const res = await AddInvestmentInvestorService.addInvestors(
          payloadSaveAsDraft,
          investmentID
        );
        if (res.success) {
          history.goBack();
        } else {
          Toast.showErrorToast(res.error.message[0].non_field_errors[0]);
        }
      } else {
        const resp = await AddInvestmentBasicDetailsService.addInvestment(
          payloadSaveAsDraft
        );
        if (resp.success) {
          setLoading(false);
          history.goBack();
          Toast.showSuccessToast("Basic Details Saved Successfully");
          AppDispatcher.setInvestmentID(resp.data.instance.id);
        } else {
          Toast.showErrorToast(resp.error.message[0].non_field_errors[0]);
        }
      }
    } else {
      if (val.isEditCase) {
        const respon = await AddInvestmentInvestorService.addInvestors(
          payload,
          investmentID
        );
        if (respon.success) {
          Toast.showSuccessToast("Basic Details Added");
          if (isUpdate) {
            history.push("/u/investments");
            return;
          }
          AppDispatcher.addInvestmentNextStep();
        } else {
          Toast.showErrorToast(respon.error?.message[0]?.non_field_errors[0]);
        }
      } else {
        const response = await AddInvestmentBasicDetailsService.addInvestment(
          payload
        );
        if (response.success) {
          setLoading(false);
          Toast.showSuccessToast("Basic Details Added");
          AppDispatcher.setInvestmentID(response.data.instance.id);
          if (isUpdate) {
            history.push("/u/investments");
            return;
          }
          AppDispatcher.addInvestmentNextStep();
        } else {
          Toast.showErrorToast(response.error.message[0].non_field_errors[0]);
        }
      }
    }
    setLoading(false);
  };

  const onHandleSubmit = (formik, drafts) => {
    setTest(formik);
    if (drafts) {
      setDraft(true);
    } else {
      setDraft(false);
    }
  };

  useEffect(() => {
    setPropertyValuePopup(false);
    getOptions();
    loadCategories();
    loadNoiCategories();
    if (investmentID) {
      getBasicDetails(investmentID);
      setisEditButton(true);
      SetClickonOverViewEdit(false);
      SetClickonEquitInfoEdit(false);
      SetClickonSponsorEdit(false);
      SetClickonSponsorTiersEdit(false);
      SetClickonNoiEdit(false);
      SetClickonPropertyEdit(false);
      SetClickonContactsEdit(false);
    } else {
      setInitialValues({
        isEditCase: false,
        /**************  Basic ******************/
        investment_name: "",
        purchase_price: "",
        start_date: "",
        distribution_cadence: "",
        is_refinanced: "",
        // not Required Fields
        projected_returns: "",
        closing_date: "",
        distribution_time_frame: "",
        cap_rate: "",
        total_raise: "",
        /*****************Equity Information******************* */
        equity_info: [
          {
            total_asset: "",
            outstanding: "",
            bank_name: "",
            //optional Field
            debtservice: "",
            date: "",
            notes: "",
            equity_amount: ""
          }
        ],
        /********************Sponsor**************************** */
        Sponsor: {
          taxId: "",
          id: [],
          sponsor_entity_name: "",
          mailing_address: "",
          master_sponsor: [
            {
              sponsor: ""
            }
          ],
          sponsors_details: [
            { email: "", name: "", company_name: "", code: "", phone: "" }
          ]
        },
        /**********************Sponsor Tiers**************** */
        sponsorSet: [],
        /**********************Net Operating Income*************** */
        noiSet: [],
        /**********************Property************************** */
        properties: [],
        /*********************Contacts************************** */
        associatedContactsSet: [],
        /*****************Upload Documents************************* */
        documents: []
      });
    }
  }, []);

  useEffect(() => {
    if (isEditButton && (ClickonEquitInfoEdit || ClickonPropertyEdit)) {
      if (Object.keys(test.errors)?.length > 0) {
        Toast.showErrorToast("Please Fill Mandatory Fields");
        test && test.handleSubmit();
      } else {
        if (test.values?.properties?.length > 0) {
          SetClickonPropertyEdit(true);
          setPropertyValuePopup(true);
        } else {
          test && test.handleSubmit();
        }
      }
      setPreviousProperty(test?.values.properties);
    } else {
      test && test.handleSubmit();
    }
  }, [draft, test]);

  useEffect(() => {
    test && test.handleSubmit();
  }, [popupNoClick]);

  function PropertyValueBar({ formik }) {
    const [Pro_ValueSum, SetPro_ValueSum] = useState(0);
    const [Ttl_asset, setTtl_asset] = useState(
      formik.values.equity_info[0].total_asset
    );

    const getvalue = () => {
      let sum = 0;
      formik.values.properties.map((el) => (sum += parseInt(el.value)));
      return sum;
    };

    useEffect(() => {
      SetPro_ValueSum(getvalue());
      setTtl_asset(formik.values.equity_info[0].total_asset);
    });

    const green = { fontSize: "16px", fontWeight: 400, color: "#398175" };
    const red = { fontSize: "16px", fontWeight: 400, color: "red" };
    const grid = { position: "relative", top: -60 };
    const Spnvalue = { fontSize: "16px", fontWeight: 700 };
    return (
      <>
        <Grid container item xs={12} justifyContent="flex-end" style={grid}>
          <div style={Pro_ValueSum > Ttl_asset ? red : green}>
            Total Property Value:
            <span style={Spnvalue}>
              {Pro_ValueSum
                ? formatCurrency(Pro_ValueSum || 0).split(".")?.[0]
                : 0}
            </span>
            /
            <span style={Spnvalue}>
              {formatCurrency(Ttl_asset || 0).split(".")?.[0]}
            </span>
          </div>
        </Grid>
      </>
    );
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Formik
            initialValues={initialValues}
            validationSchema={
              draft
                ? ValidationSchemeAddInvestmentBasicDetailsDraft
                : ValidationSchemeAddInvestmentBasicDetails
            }
            enableReinitialize={true}
            onSubmit={(values) => {
              handleBasicDetails(values);
            }}>
            {(formik) => (
              <Form>
                <div className={classes.root}>
                  {/* //Basic Details */}
                  <FieldLayout
                    heading="Overview"
                    isEditButton={isEditButton}
                    click={ClickonOverViewEdit}
                    SetClickEdit={SetClickonOverViewEdit}>
                    <BasicDetails
                      formik={formik}
                      cadenceChoices={cadenceChoices}
                      timeFrameChoices={timeFrameChoices}
                      cadenceMapping={cadenceMapping}
                      setLoading={setLoading}
                      ClickonOverViewEdit={ClickonOverViewEdit}
                      investmentStatus={investmentStatus}
                    />
                  </FieldLayout>

                  {/* Equity Information */}
                  <ViewHistoryPopup
                    isopen={historyOpen}
                    setIsOpen={setHistoryOpen}
                    history={historyData}
                  />
                  <FieldLayout
                    heading="Equity Information"
                    showHistory
                    openHistoryPopUp={setHistoryOpen}
                    isEditButton={isEditButton}
                    click={ClickonEquitInfoEdit}
                    SetClickEdit={SetClickonEquitInfoEdit}>
                    <FieldArray
                      name="equity_info"
                      render={(arrayHelper) => (
                        <>
                          {formik.values.equity_info?.map(
                            (equity_info, index) => (
                              <Fragment key={index}>
                                <EquityInformation
                                  ClickonEquitInfoEdit={ClickonEquitInfoEdit}
                                  index={index}
                                  equity_info={equity_info}
                                  arrayHelper={arrayHelper}
                                  formik={formik}
                                  isEditButton={isEditButton}
                                />
                              </Fragment>
                            )
                          )}
                        </>
                      )}
                    />
                  </FieldLayout>

                  {/* Sponsor */}
                  <FieldLayout
                    heading="Sponsor"
                    isEditButton={isEditButton}
                    click={ClickonSponsorEdit}
                    SetClickEdit={SetClickonSponsorEdit}>
                    <NewSponsorEntity
                      formik={formik}
                      classes={classes}
                      ClickonSponsorEdit={ClickonSponsorEdit}
                    />
                  </FieldLayout>

                  {/* Sponsor Tiers */}
                  <FieldLayout
                    heading="Sponsor Tiers"
                    isEditButton={isEditButton}
                    click={ClickonSponsorEdit}
                    SetClickEdit={SetClickonSponsorEdit}>
                    <FieldArray
                      name="sponsorSet"
                      render={(arrayHelper) => (
                        <>
                          {formik.values.sponsorSet.map((sponsors, index) => (
                            <Fragment key={index}>
                              <Tier
                                classes={classes}
                                id={index}
                                arrayHelper={arrayHelper}
                                formik={formik}
                                ClickonSponsorTiersEdit={ClickonSponsorEdit}
                              />
                            </Fragment>
                          ))}

                          <div
                            className={classes.addTier}
                            style={
                              formik.values.sponsorSet.length == 0
                                ? { marginTop: 0 }
                                : {}
                            }
                            onClick={() =>
                              arrayHelper.push({
                                startfrom: "",
                                to: "",
                                promote: ""
                              })
                            }>
                            <img
                              src={plusAdd}
                              alt=""
                              style={{ marginRight: 8 }}
                            />
                            <div>Add Tier</div>
                          </div>
                        </>
                      )}
                    />
                  </FieldLayout>

                  {/* Net Operating Income */}
                  <FieldLayout
                    heading="Net Operating Income"
                    isEditButton={isEditButton}
                    click={ClickonNoiEdit}
                    SetClickEdit={SetClickonNoiEdit}>
                    <FieldArray
                      name="noiSet"
                      render={(arrayHelper) => (
                        <>
                          {formik.values.noiSet.map((noi, index) => (
                            <Fragment key={index}>
                              <Noi
                                customStyle={{
                                  backgroundColor: "#fff",
                                  padding: 20,
                                  borderRadius: 8,
                                  margin: "0px 20px"
                                }}
                                cadenceChoices={noiCadenceChoices}
                                quarterChoices={quarterChoices}
                                id={index}
                                noi={noi}
                                arrayHelper={arrayHelper}
                                formik={formik}
                                noiCategories={noiCategories}
                                ClickonNoiEdit={ClickonNoiEdit}
                              />
                            </Fragment>
                          ))}

                          <div
                            className={classes.addMore}
                            onClick={() =>
                              arrayHelper.push({
                                investment_noi: "",
                                year: "",
                                noi_cadence: "",
                                noi_quarter: "",
                                documents: []
                              })
                            }>
                            <img
                              src={plusAdd}
                              alt=""
                              style={{ marginRight: 8 }}
                            />
                            <div>Add NOI</div>
                          </div>
                        </>
                      )}
                    />
                  </FieldLayout>

                  {/* Property */}

                  <FieldLayout
                    heading="Property"
                    isEditButton={isEditButton}
                    click={ClickonPropertyEdit}
                    SetClickEdit={SetClickonPropertyEdit}>
                    <PropertyValueBar formik={formik} />
                    <FieldArray
                      name="properties"
                      render={(arrayHelper) => (
                        <>
                          {formik.values.properties.map((properties, index) => (
                            <Fragment key={index}>
                              <StepOneProperties
                                index={index}
                                properties={properties}
                                arrayHelper={arrayHelper}
                                formik={formik}
                                ClickonPropertyEdit={ClickonPropertyEdit}
                              />
                            </Fragment>
                          ))}

                          {arrayHelper.form.values.properties.length !== 0 && (
                            <Box
                              height={"30px"}
                              width={"105%"}
                              bgcolor={"#FFF"}
                              mt="20px"
                              ml="-20px"
                              mb="20px"
                            />
                          )}

                          <div
                            className={classes.addMore}
                            style={
                              formik.values?.properties?.length == 0
                                ? { marginTop: "-26px" }
                                : {}
                            }
                            onClick={() =>
                              arrayHelper.push({
                                name: "",
                                value: "",
                                address: [{ address: "", zip_code: "" }],
                                area: "",
                                units: "",
                                status: "",
                                type: "",
                                occupancy: "",
                                build_year: "",
                                description: "",
                                images_list: [],
                                noi: [
                                  {
                                    property_noi: "",
                                    noi_cadence: "",
                                    noi_quarter: "",
                                    year: "",
                                    documents: []
                                  }
                                ]
                              })
                            }>
                            <img
                              src={plusAdd}
                              alt=""
                              style={{ marginRight: 8 }}
                            />
                            <div>
                              {formik.values?.properties?.length > 0
                                ? "Add Additional Property"
                                : "Add Property"}
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </FieldLayout>

                  {/*Contacts */}
                  <FieldLayout
                    heading="Contacts"
                    isEditButton={isEditButton}
                    click={ClickonContactsEdit}
                    SetClickEdit={SetClickonContactsEdit}>
                    <FieldArray
                      name="associatedContactsSet"
                      render={(arrayHelper) => (
                        <>
                          {formik.values.associatedContactsSet.map(
                            (sponsors, index) => (
                              <Fragment key={index}>
                                <Contacts
                                  ClickonContactsEdit={ClickonContactsEdit}
                                  classes={classes}
                                  id={index}
                                  arrayHelper={arrayHelper}
                                  formik={formik}
                                  defaultValue={
                                    sponsors.is_primary ? "Yes" : "No"
                                  }
                                />
                              </Fragment>
                            )
                          )}

                          <div
                            className={classes.addMore}
                            onClick={() => {
                              arrayHelper.push({
                                first_name: "",
                                last_name: "",
                                email: "",
                                phone_number: "",
                                country_code: "+1",
                                designation: "",
                                company_name: "",
                                is_primary: true
                              });
                            }}>
                            <img
                              src={plusAdd}
                              alt=""
                              style={{ marginRight: 8 }}
                            />
                            <div>
                              {formik.values.associatedContactsSet?.length > 0
                                ? "Add Additional Contact"
                                : "Add Contact"}
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </FieldLayout>

                  {/* Upload Documents */}
                  <FieldLayout heading="Upload Documents">
                    <Grid container>
                      <NewCategoryDocTable
                        sponDocCategories={docCategories}
                        listData={formik.values.documents}
                        setListData={(data) =>
                          formik.setFieldValue(`documents`, data)
                        }
                      />
                    </Grid>
                  </FieldLayout>

                  {/* Buttons */}
                  <Grid
                    container
                    justifyContent="space-between"
                    className={styles.btncontainforms}>
                    <Grid item xs container alignItems="center">
                      {investmentStatus === "Draft" ? (
                        <p
                          className={styles.saveAsDraftText}
                          onClick={() => onHandleSubmit(formik, true)}>
                          Save As Draft
                        </p>
                      ) : (
                        <p
                          className={styles.saveAsDraftText}
                          onClick={() => {
                            history.push("/u/investments");
                          }}>
                          Cancle
                        </p>
                      )}
                      <div
                        className={
                          styles.addInvestmentButtonContainerRectangular
                        }
                        onClick={() => onHandleSubmit(formik, false)}>
                        <p className={styles.addInvestmentButtonText}>Next</p>
                      </div>
                    </Grid>
                    {investmentStatus !== "Draft" && (
                      <Grid item>
                        <UpdateBtn
                          onClick={() => {
                            setisUpdate(true);
                            onHandleSubmit(formik, false);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>

                  {/*Ask to Change Property value Popup while Edit Investment */}
                  <PropertyPopUp
                    isopen={PropertyValuePopup}
                    setIsOpen={setPropertyValuePopup}
                    formik={formik}
                    setpopupNoClick={setpopupNoClick}
                    PreviousProperty={PreviousProperty}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
    </>
  );
};

export default Basic;
