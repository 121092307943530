import { API, NetworkManager } from "./core";

export class ManageDataService {
  static async getStats() {
    const instance = new NetworkManager(API.ManageData.GET_STATS);
    return await instance.httpRequest();
  }

  static async getSponsorEntityList(params) {
    const instance = new NetworkManager(
      API.ManageData.GET_MANAGEDATA_SPONSOR_ENTITY_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async get_Pdf_Or_Csv(params) {
    const instance = new NetworkManager(
      API.ManageData.EXPORT_PDF_CSV,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getPropertyNameList(params) {
    const instance = new NetworkManager(
      API.ManageData.GET_MANAGEDATA_PROPERTY_NAME_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getManageDataYears(params) {
    const instance = new NetworkManager(API.ManageData.MANAGEDATA_YEARS, {}, [
      params
    ]);
    return await instance.httpRequest();
  }
  static async getSponsorNameList(params) {
    const instance = new NetworkManager(
      API.ManageData.GET_MANAGEDATA_SPONSOR_NAME_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async getInvestorName(params) {
    const instance = new NetworkManager(
      API.ManageData.GET_INVESTOR_NAME,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async getInvestorEntityName(params) {
    const instance = new NetworkManager(
      API.ManageData.GET_INVESTOR_ENTITY_NAME,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async getYears(params) {
    const instance = new NetworkManager(API.ManageData.GET_YEARS, {}, params);
    return await instance.httpRequest();
  }

  static async getK1(params) {
    const instance = new NetworkManager(
      API.ManageData.K1_DATA_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async addManageDataK1(payload, params) {
    const instance = new NetworkManager(
      API.ManageData.ADD_MANAGEDATA,
      payload,
      params
    );
    return await instance.httpRequest();
  }

  static async getManageData(params) {
    const instance = new NetworkManager(
      API.ManageData.GET_MANAGEDATA_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getInvestmentData(params) {
    const instance = new NetworkManager(
      API.ManageData.MANAGEDATA_INVESTMENT_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getInvestmentNameData(params) {
    const instance = new NetworkManager(
      API.ManageData.MANAGEDATA_INVESTMENT_NAME_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getTemplateData(params) {
    const instance = new NetworkManager(
      API.ManageData.GET_TEMPLATE_DATA_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async templateDownload(params) {
    const instance = new NetworkManager(
      API.ManageData.TEMPLATE_DOWNLOAD,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async templateUpload(payload) {
    const instance = new NetworkManager(
      API.ManageData.TEMPLATE_DATA_UPLOAD,
      payload
    );
    return await instance.httpRequest();
  }
  static async getManageDataK1(params) {
    const instance = new NetworkManager(
      API.ManageData.GET_MANAGEDATA_K1,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async dropdownOptions() {
    const instance = new NetworkManager(API.DROPDOWN_OPTIONS.OPTIONS);
    return await instance.httpRequest();
  }

  static async addManageData(payload) {
    const instance = new NetworkManager(API.ManageData.ADD_MANAGEDATA, payload);
    return await instance.httpRequest();
  }
  static async editManageDataK1(payload, id) {
    const instance = new NetworkManager(
      API.ManageData.EDIT_MANAGE_DATA_K1,
      payload,
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
  static async deleteManageDataK1(params) {
    const instance = new NetworkManager(
      API.ManageData.DELETE_MANAGE_DATA_K1,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async getPropertiesListByInvestment(params) {
    const instance = new NetworkManager(
      API.ManageData.LIST_PROPERTY,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getInvestorListByInvestmentList(params) {
    const instance = new NetworkManager(
      API.ManageData.LIST_INVESTOR,
      {},
      params
    );
    return await instance.httpRequest();
  }
  static async getInvestorListByInvestment(params) {
    const instance = new NetworkManager(
      API.ManageData.INVESTOR_LIST_PROPERTY,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
