import { Typography } from "@material-ui/core";
import React from "react";
import styles from "./Components.module.scss";
import CheckCheckBox from "assets/icons/CheckCheckBox.svg";
import { truncate } from "helpers/Common";

const propertyType = {
  singlefamily: "Single Family Home",
  condominums: "Condominiums",
  townhomes: "Townhomes",
  cooperativehousing: "Cooperative housing (a co-op)",
  duplexes: "Duplexes",
  triplexes: "Triplexes",
  fourplexes: "Fourplexes",
  individualmobile: "Individual mobile homes (not an entire mobile home park)",
  officespace: "Office space",
  hotelandlodging: "Hotel and lodging",
  selfstorage: "Self-storage or mini-storage",
  multifamilyproperties: "Multifamily properties",
  retailproperties: "Retail properties",
  industrialbuildings: "Industrial buildings",
  healthcarefacilites: "Health care facilities",
  specialpurposes: "Special purposes"
};

const Property = ({ formik, item, index, id, handleCheck = () => {} }) => {
  return (
    <div className={styles.bodyCtr}>
      <div className={styles.checkboxCtr}>
        <div
          onClick={() => handleCheck(formik, index, item)}
          className={styles.checkbox}>
          <>
            {formik?.values?.loanSet[id]?.selectedProperties.some(
              (el) => el == item.id
            ) ? (
              <img src={CheckCheckBox} width={23} height={23} alt="check" />
            ) : (
              <div className={styles.unChecked} />
            )}
          </>
        </div>
      </div>
      <div title={item.name} className={styles.body}>
        <Typography className={styles.bodyText}>
          {item.name ? truncate(item.name, 16) : "-"}
        </Typography>
      </div>
      <div title={propertyType?.[item.type]} className={styles.header}>
        <Typography className={styles.bodyText}>
          {item.type ? truncate(propertyType?.[item.type], 16) : "-"}
        </Typography>
      </div>
      <div className={styles.body}>
        <Typography className={styles.bodyText}>
          {item.area && item.units ? item.area + " " + item.units : "-"}
        </Typography>
      </div>
      <div className={styles.body}>
        <Typography className={styles.bodyText}>
          {item.occupancy ? item.occupancy + " " + "%" : "-"}
        </Typography>
      </div>
      <div className={styles.body}>
        <Typography className={styles.bodyText}>
          {item.build_year || "-"}
        </Typography>
      </div>
      <div title={item?.property_address?.[0]?.address} className={styles.body}>
        <Typography noWrap className={styles.bodyText}>
          {item?.property_address?.[0]?.address
            ? truncate(item?.property_address?.[0]?.address, 16)
            : "-"}
        </Typography>
      </div>
      <div className={styles.body}>
        <Typography className={styles.bodyText}>
          {item?.property_address?.[0]?.zip_code || "-"}
        </Typography>
      </div>
      <div className={styles.body}>
        <Typography className={styles.bodyText}>
          <div
            style={{
              padding: "4px 14px",
              borderRadius: "153px",
              backgroundColor:
                item?.status === "stabilized" ? "#EDFFEF" : "#FEF8F9",
              color: item?.status === "stabilized" ? "#55A55E" : "#EF798A"
            }}>
            <b>{item?.status || "-"}</b>
          </div>
        </Typography>
      </div>
    </div>
  );
};

export default Property;
