import React, { useEffect } from "react";
import EditDialog from "./editDialog";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPageWithPermission, getScreenPermissions } from "helpers/permissionChecker";

const EditManageDataK1 = () => {
  const history = useHistory();
  const { userPermissions, user } = useSelector((store) => store.app);

  const handleClose = () => {
    history.push("/u/manage_data");
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(userPermissions, "Manage Data", user);
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_update) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  }

  useEffect(checkPermissions, []);

  return <EditDialog handleClose={handleClose} />;
};

export default EditManageDataK1;
