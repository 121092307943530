import React from "react";
import styles from "./primary.module.scss";

const PrimaryButton = ({ width = "100%", text, onPress }) => {
  return (
    <button
      className={styles.container}
      style={{ width: width }}
      onClick={() => onPress()}>
      {text}
    </button>
  );
};

export default PrimaryButton;
