import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography
} from "@material-ui/core";
import Calender from "components/calender";
import FieldHeading from "components/fieldHeading";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import CustomSelect from "components/StyledComponents/Select";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import TextWithLabel from "../components/TextWithLabel";
import styles from "../../sponsors/addSponsor/addSponsors.module.scss";
import Toast from "components/toast";
import { CommonService } from "network/commonService";
import { validationSchemaEditDistribution } from "helpers/validationSchema";
import { useHistory } from "react-router-dom";
import { DistributionService } from "network/distributionService";
import { useSelector } from "react-redux";
import { capitalize, formatDate, stringToDate } from "helpers";
import FileUploaded from "assets/icons/FileUpload.svg";
import Loader from "components/Loader/AppLoader";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import { AddInvestmentDistributionService } from "network/addInvestmentDistributionService";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { truncate, verifyDocUploadType } from "helpers/Common";
import NewCategoryDocTable from "pages/private/properties/component/NewCategoryDocTable";
import formatCurrency from "helpers/formatCurrency";
import TextArea from "components/TextArea";

const useStyles = makeStyles(() => ({
  mainContainer: {
    borderRadius: 8,
    marginTop: 10
  },
  container: {},
  inputContainer: {
    padding: 20,
    background: "#FAFAFA",
    borderRadius: "10px"
  },
  editData: {
    padding: "20px 20px",
    border: "1px solid #E9E9E9",
    borderRadius: "10px"
  },
  labelText: {
    fontFamily: "Inter Bold",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "22px",
    color: "#191919",
    margin: "30px 0px 20px 0px"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400
  },
  proofContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%"
  },
  accordionContainer: {
    padding: 0
  },
  MuiAccordionroot: {
    "&.MuiAccordion-root:before": {
      backgroundColor: "white"
    }
  },
  docContainer: {
    marginTop: 40
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 50,
    marginTop: 30
  },
  btnPrimary: {
    backgroundColor: "#5775E5",
    color: "#ffffff",
    width: 218,
    height: 50,
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    cursor: "pointer"
  },
  btnSecondary: {
    margin: "0 62px 0 22px",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    cursor: "pointer"
  },
  uploadDocs: {
    backgroundColor: "#FAFAFA",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 20,
    borderRadius: 8,
    marginTop: 20
  },
  imgContainer: {
    display: "flex",
    flexDirection: "row"
  },
  buttton: {
    fontFamily: "Inter SemiBold",
    fontSize: 16,
    height: 45,
    minWidth: 180,
    padding: "0px 30px !important",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#5775E5",
    color: "#ffffff",
    cursor: "pointer"
  },
  butttonCancel: {
    fontFamily: "Inter SemiBold",
    fontSize: 16,
    height: 45,
    minWidth: 180,
    padding: "0px 30px !important",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: "#5775E5",
    cursor: "pointer",
    border: "1px solid #5775E5"
  }
}));

const EditDistribution = () => {
  const classes = useStyles();
  const history = useHistory();
  const wrapperRef = useRef(null);
  const [imageList, setImageList] = useState([]);
  const [buttonLoader, setButtonLoader] = useState(false);

  const { distributionId, userPermissions, user } = useSelector(
    (store) => store.app
  );
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");
  const [docsLoading, setDocsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [distributionData, setDistributionData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [CadenceData, setCadenceData] = useState({
    MONTH_CHOICES: [],
    QUARTER_CHOICES: []
  });
  const [distributionType, setDistributionType] = useState([]);
  const [cadenceSelected, setCadenceSelected] = useState("monthly");
  const [distYear, setDistYear] = useState(false);
  const [distmonth, setDistMonth] = useState(2);
  const [distTimeframeList, setDistTimeframeList] = useState([]);
  const [docCategories, setDocCategories] = useState([]);
  const [invDocCategories, setInvDocCategories] = useState([]);
  const [minYear, setMinYear] = useState(2000);
  const [docType, setDocType] = useState("");
  const [proofChoices, setProofChoices] = useState([]);

  const onFileDrop = (e) => {
    if (!verifyDocUploadType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      resp.data.type = docType;
      resp.data.link = resp.data.file;
      const updatedFileList = [...imageList, resp.data];
      setImageList(updatedFileList);
      setDocsLoading(false);
      Toast.showSuccessToast("Documents Upload SuccessFully");
    } else {
      Toast.showErrorToast("Failed To Uploaded Documents");
    }
  };

  const deleteDoc = async (docId, id) => {
    let resp = { success: true };
    if (docId) {
      resp = await CommonService.deleteDoc(docId);
    }
    if (resp.success) {
      resp = await CommonService.deleteMedia(id);
    }
    if (resp.success) {
      setDocType(null);
      Toast.showSuccessToast("Documents Deleted Successfully");
      setImageList((prev) => {
        return prev.filter((item) => item.id != id);
      });
    } else {
      Toast.showErrorToast("Failed to Delete Document");
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchemaEditDistribution,
    onSubmit: async (values) => {
      setButtonLoader(true);
      const categoryname = invDocCategories.find(
        (el) => el.id === docType
      )?.name;
      let categoryValue = proofChoices.length && proofChoices[0]?.value;
      if (categoryname) {
        categoryValue = proofChoices.find(
          (el) =>
            el.label ===
            (categoryname === "Wire-Transfer" ? "Wire Transfer" : categoryname)
        )?.value;
      } else {
        categoryValue = null;
      }
      const payload = {
        distribution: [
          {
            date: formatDate(values.distribution_date),
            id: values.distributionId,
            type: values.distribution_type,
            total_distribution: values.total_distribution,
            cadence: values.distribution_timeframe,
            year: values.distribution_year,
            investor_distribution: [
              {
                investor_id: values.investorId,
                id: values.id,
                added_by: user?.id,
                type: categoryValue,
                notes: values.notes,
                amount: values.amount_released,
                documents: imageList
                  ?.filter((el) => el.type === docType)
                  ?.map((doc) => ({
                    document_media_id: doc.id,
                    category_id: doc.type,
                    ...(doc.docId ? { id: doc.docId } : {})
                  }))
              }
            ],
            documents: values.documents?.map((doc) => ({
              document_media_id: doc.id,
              category_id: doc.type,
              ...(doc.docId ? { id: doc.docId } : {})
            }))
          }
        ]
      };
      const res = await AddInvestmentDistributionService.addDistribution(
        payload,
        values.investmentId
      );
      if (res.success) {
        Toast.showSuccessToast("Distribution Data Updated");
        setButtonLoader(false);

        history.goBack();
      } else {
        Toast.showErrorToast("Failed To Update Distribution Data");
        setButtonLoader(false);
      }
    }
  });

  const initDropdownValues = async (cadence, date) => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      if (formik.distribution_year) {
        if (formik.values.distribution_year > parseInt(date[0])) {
          if (cadence.toLowerCase() === "monthly") {
            setDistTimeframeList(res.data.MONTH_CHOICES);
          } else {
            setDistTimeframeList(res.data.QUARTER_CHOICES);
          }
        } else {
          if (cadence.toLowerCase() === "monthly") {
            setDistTimeframeList(
              res?.data?.MONTH_CHOICES?.slice(parseInt(date[1]) - 1) || []
            );
          } else {
            setDistTimeframeList(
              res?.data?.QUARTER_CHOICES?.slice(
                (res?.data?.MONTH_QUARTER_MAPPING?.length &&
                  res?.data?.MONTH_QUARTER_MAPPING[parseInt(date[1]) - 1]
                    ?.label) - 1
              ) || []
            );
          }
        }
      }
      setProofChoices(res?.data?.PROOF_CHOICE);
      setDistributionType(res?.data.DISTRIBUTION_TYPE_CHOICES);
      setCadenceData({
        MONTH_CHOICES: res?.data.MONTH_CHOICES,
        QUARTER_CHOICES: res?.data.QUARTER_CHOICES,
        MONTH_QUARTER_MAPPING: res?.data.MONTH_QUARTER_MAPPING
      });
    }
  };

  const selectYear = (value) => {
    formik.setFieldValue(`distribution_year`, value);
    if (value > distYear) {
      if (cadenceSelected.toLowerCase() === "monthly") {
        setDistTimeframeList(CadenceData.MONTH_CHOICES);
      } else {
        setDistTimeframeList(CadenceData.QUARTER_CHOICES);
      }
    } else {
      if (cadenceSelected.toLowerCase() === "monthly") {
        setDistTimeframeList(
          CadenceData?.MONTH_CHOICES?.slice(distmonth - 1) || []
        );
      } else {
        setDistTimeframeList(
          CadenceData?.QUARTER_CHOICES?.slice(
            (CadenceData?.MONTH_QUARTER_MAPPING?.length &&
              CadenceData?.MONTH_QUARTER_MAPPING[distmonth - 1]?.label) - 1
          ) || []
        );
      }
    }
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTDISTRIBUTION"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
      const invCategories = await AddInvestmentInvestorService.getDocCategories(
        { name: "INVESTMENTDISTRIBUTIONINVESTOR" }
      );
      if (invCategories.success) {
        invCategories.data.length &&
          setInvDocCategories(invCategories.data[0]?.doccategory);
        return;
      }
    }
    Toast.showErrorToast(`Failed To Load Document Categories`);
  };

  // const getInvestorFullname = (name) => {
  //   let fullName = "";
  //   fullName += name?.first_name ? name?.first_name + " " : "";
  //   fullName += name?.last_name || "";
  //   return fullName || "-";
  // };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      size: el?.document_media?.file_size,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  const initData = async () => {
    const res = await DistributionService.getDistributionData(
      `${distributionId}/`
    );
    if (res.success) {
      setMinYear(res?.data?.year);
      const date = res.data.investment.start_date.split("-");
      setDistYear(parseInt(date[0]));
      setDistMonth(parseInt(date[1]));
      setCadenceSelected(res.data.investment.distribution_cadence);
      await initDropdownValues(res.data.investment.distribution_cadence, date);
      setInitialValues({
        investmentId: res?.data?.investment?.id,
        distributionId: res?.data?.id,
        id: res?.data?.investor_distribution_data[0]?.id,
        investorId:
          res?.data?.investor_distribution_data[0]?.investor
            ?.investor_investment_id,
        distribution_date: stringToDate(res?.data?.date),
        distribution_type: res?.data?.type,
        distribution_year: res?.data?.year,
        notes: res?.data?.investor_distribution_data[0]?.notes || "",
        distribution_timeframe: res?.data?.cadence,
        total_distribution: res?.data?.total_distribution,
        amount_released: res?.data?.investor_distribution_data[0]?.amount,
        documents: formatDocsData(res?.data?.documents || []),
        docType:
          res?.data?.investor_distribution_data?.[0]?.documents?.[0]?.category
            ?.id
      });
      setDocType(
        res?.data?.investor_distribution_data?.[0]?.documents?.[0]?.category?.id
      );
      setImageList(
        formatDocsData(
          res?.data?.investor_distribution_data[0]?.documents || []
        )
      );
      setDistributionData({
        investmentName: res?.data?.investment.name,
        managedBy: res?.data?.investor_distribution_data[0]?.investor
          ?.managed_by
          ? res?.data?.investor_distribution_data[0]?.investor?.managed_by
          : "-",
        investorName:
          res?.data?.investor_distribution_data[0]?.investor?.investor_name,
        entityName:
          res?.data?.investor_distribution_data[0]?.investor?.entity_name ||
          "-",
        investedAmount:
          formatCurrency(
            res?.data?.investor_distribution_data[0]?.investor.invested_amount
          ) || "0",
        membership:
          (res?.data?.investor_distribution_data[0]?.investor.member_percent ||
            "0") + "%",
        preferredReturn: res?.data?.investor_distribution_data[0]?.investor
          ?.preferred_return
          ? res?.data?.investor_distribution_data[0]?.investor
              ?.preferred_return + "%"
          : "-",
        distributionCadance: res?.data?.investment?.distribution_cadence
          ? capitalize(res?.data?.investment?.distribution_cadence)
          : "-",
        notes: res?.data.investor_distribution_data?.notes
          ? res?.data.investor_distribution_data[0]?.notes
          : ""
      });
    } else {
      Toast.showErrorToast("Failed To Load Data");
    }
  };

  const setDocData = (data) => {
    formik.setFieldValue(`documents`, data);
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "DISTRIBUTIONS",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_update) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(async () => {
    setIsLoading(true);
    await Promise.all([checkPermissions(), loadCategories(), initData()]);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setInitialValues((prev) => ({
      ...prev,
      distribution_type:
        distributionType.find(
          (el) =>
            el.label === prev.distribution_type ||
            el.value === prev.distribution_type
        )?.value || prev.distribution_type,
      distribution_timeframe:
        distTimeframeList.find(
          (el) =>
            el.label === prev.distribution_timeframe ||
            el.value === prev.distribution_timeframe
        )?.value || prev.distribution_timeframe
    }));
  }, [distTimeframeList, distributionType]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <HeaderComponent
            heading="Edit Distributions"
            description="Edit And Update Your Distributions"
            backBtn={true}
          />
          <Box height={26} />
          <Grid container className={classes.mainContainer}>
            <Grid container spacing={2} className={classes.editData}>
              <TextWithLabel
                text={distributionData.investmentName}
                label={"Investment Name"}
              />
              <TextWithLabel
                text={distributionData.managedBy}
                label={"Managed By"}
              />
              <TextWithLabel
                text={distributionData.investorName}
                label={"Investor Name"}
              />
              <TextWithLabel
                text={truncate(distributionData.entityName, 20)}
                label={"Entity Name"}
              />
              <TextWithLabel
                text={distributionData.investedAmount}
                label={"Invested Amount"}
              />
              <TextWithLabel
                text={distributionData.membership}
                label={"Membership"}
              />
              <TextWithLabel
                text={distributionData.preferredReturn}
                label={"Preferred Return"}
              />
              <TextWithLabel
                text={distributionData.distributionCadance}
                label={"Distribution Cadence"}
              />
            </Grid>
            <Grid container>
              <label className={classes.labelText}>Basic</label>
            </Grid>
            <Grid container className={classes.container}>
              <Grid container className={classes.inputContainer}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3}>
                    <FieldHeading title="Distribution Date" />
                    <Calender
                      error={
                        formik.touched.distribution_date &&
                        Boolean(formik.errors.distribution_date)
                      }
                      helperText={
                        formik.touched.distribution_date &&
                        formik.errors.distribution_date
                      }
                      setDate={(date) =>
                        formik.setFieldValue(`distribution_date`, date)
                      }
                      defaultDate={formik.values.distribution_date}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FieldHeading title="Distribution Type" />
                    <CustomSelect
                      fullWidth
                      items={distributionType}
                      error={formik.errors.distribution_type}
                      touched={formik.touched.distribution_type}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "distribution_type",
                          e.target.value
                        );
                      }}
                      value={formik.values.distribution_type}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FieldHeading title="Distribution Year" />
                    <SelectWithInfiniteScroll
                      fullWidth
                      minValue={minYear}
                      selectedItem={formik.values.distribution_year}
                      error={formik.errors.distribution_year}
                      touched={formik.touched.distribution_year}
                      onChange={(value) => selectYear(value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <FieldHeading title="Distribution Time-Frame" />
                    <CustomSelect
                      fullWidth
                      error={formik.errors.distribution_timeframe}
                      touched={formik.touched.distribution_timeframe}
                      items={distTimeframeList}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "distribution_timeframe",
                          e.target.value
                        );
                      }}
                      value={formik.values.distribution_timeframe}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <FieldHeading title="Distribution Amount Released" />
                    <AppTextFieldWhite
                      fullWidth
                      placeholder="$30,000"
                      onWheel={(e) => e.target.blur()}
                      numFormat={true}
                      InputLabelProps={{
                        shrink: true
                      }}
                      value={formik.values.amount_released}
                      onChange={formik.handleChange("amount_released")}
                      error={
                        formik.touched.amount_released &&
                        Boolean(formik.errors.amount_released)
                      }
                      helperText={
                        formik.touched.amount_released &&
                        formik.errors.amount_released
                      }
                      InputProps={{ disableUnderline: true }}
                      inputProps={{ maxLength: 12 }}
                      disabled={false}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <FieldHeading title={"Notes"} />
                    <TextArea
                      // disabled={!isInvestorEdit && isEdit}
                      id="notes"
                      value={formik.values.notes}
                      onChange={formik.handleChange("notes")}
                      placeholder="Add Notes"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container justifyContent="space-between">
                <Grid item xs={5} className={classes.proofContainer}>
                  <Box height={20} />
                  <label className={classes.labelText}>
                    Distribution Proof
                  </label>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      name="controlled-radio-buttons-group"
                      value={docType}
                      onChange={(e) => setDocType(e.target.value)}>
                      {invDocCategories &&
                        invDocCategories
                          .filter((e) => e.name != "Misc.")
                          .map((category) => (
                            <FormControlLabel
                              value={category.id}
                              control={<Radio color="secondary" />}
                              key={category.id}
                              label={
                                <div className={classes.label}>
                                  {category.name}
                                </div>
                              }
                            />
                          ))}
                    </RadioGroup>
                  </FormControl>
                  <div
                    style={{ width: "100%" }}
                    ref={wrapperRef}
                    className={styles.drop_file_input}
                    onDragEnter={onDragEnter}
                    onDragLeave={onDragLeave}
                    onDrop={onDrop}>
                    <div>
                      {docsLoading ? (
                        <CircularProgress />
                      ) : (
                        <>
                          <p className={styles.droptext}>
                            Drag And Drop Files Here Or <span>Browse</span>
                          </p>
                        </>
                      )}
                    </div>

                    <input type="file" value="" onChange={onFileDrop} />
                  </div>
                  <Grid container>
                    {imageList.length > 0 ? (
                      <>
                        {imageList
                          .filter((el) => el.type === docType)
                          .map((item) => (
                            <Grid
                              key={item.name}
                              container
                              className={classes.uploadDocs}
                              direction="row">
                              <Grid
                                item
                                xs={10}
                                className={classes.imgContainer}>
                                {item.name.endsWith("png") ||
                                item.name.endsWith("jpeg") ||
                                item.name.endsWith("jpg") ? (
                                  <img
                                    src={item.link}
                                    style={{
                                      height: 54,
                                      width: 54,
                                      borderRadius: 10
                                    }}
                                  />
                                ) : (
                                  <img src={FileUploaded} />
                                )}
                                <Typography
                                  title={item.name}
                                  variant="h5"
                                  style={{ marginTop: 20, marginLeft: 20 }}>
                                  {truncate(item.name, 30)}
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <div
                                  className={styles.deleteButton}
                                  onClick={() => deleteDoc("", item.id)}>
                                  Delete
                                </div>
                              </Grid>
                            </Grid>
                          ))}
                      </>
                    ) : null}
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.docContainer}>
                  <label className={classes.labelText}>
                    Uploaded Documents
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      boxShadow: "0px 4px 15px rgb(160 160 160 / 15%)",
                      marginTop: "15px"
                    }}>
                    <NewCategoryDocTable
                      sponDocCategories={docCategories}
                      listData={formik.values.documents}
                      setListData={(list) => setDocData(list)}
                    />
                  </div>
                  {/* </AccordionDetails>
                </Accordion> */}
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={3}
            style={{
              // position: "fixed",
              // bottom: "0px",
              backgroundColor: "#fff",
              marginTop: 40
            }}
            justifyContent="flex-end">
            <Grid item>
              <div
                className={classes.butttonCancel}
                onClick={() => history.push("/u/distributions")}>
                Cancel
              </div>
            </Grid>
            <Grid item>
              <div
                className={classes.buttton}
                onClick={() => formik.handleSubmit()}>
                {buttonLoader ? (
                  <CircularProgress color="#fff" size="1.5rem" />
                ) : (
                  "Update"
                )}
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default EditDistribution;
