import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Grid,
  makeStyles
} from "@material-ui/core";
import { Form, Formik } from "formik";
import styles from "../../../../../../sponsors/editSponsor/EditSponsor.module.scss";
import { validationSchemaAddSponsor } from "helpers/validationSchema";
import Toast from "components/toast";
import blueCloseIcon from "assets/icons/NewCardSvg/blueCloseIcon.svg";
import { SponsorService } from "network/sponsorService";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FieldHeading from "components/fieldHeading";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import { preventNonNumericalInput } from "helpers";

const useStyles = makeStyles(() => ({
  root: {
    "&  .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 1242,
      height: "fit-content"
    }
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 48.5,
    boxSizing: "border-box"
  },
  MaterialForm: {},
  form: {},
  nameFieldContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "369px",
    "@media(min-width: 1259px)": { width: "100%" },
    "@media(max-width: 1259px)": { flexGrow: 1 }
  },
  nameContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20
  },
  documentHeading: {
    margin: "20px 0px",
    width: "168px",
    height: "22px",
    fontFamily: "Inter Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#191919",
    whiteSpace: "nowrap"
  },
  buttonContainer: {
    display: "flex",
    gap: "10px",
    marginBottom: 40,
    marginTop: 60
  },
  heading: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "Inter Bold"
  },
  doctableHeading: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#616161"
  },

  textFields: {
    maxWidth: 369
  },
  allDocsContainer: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: "608px",
    background: "#FFFFFF"
  },
  docContainerWrapper: {
    background: "#FAFAFA",
    padding: "20px 20px 22px"
  },
  docContainer: {
    height: 68,
    width: 480,
    backgroundColor: "#E5E9ED",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 20,
    marginBottom: 10
  },
  docImg: {
    height: 40,
    width: 40,
    borderRadius: 8
  },
  docText: {
    fontFamily: "Inter Regular",
    fontWeight: 400,
    fontSize: 16
  },
  docDelete: {
    marginRight: 20,
    marginTop: 10
  },
  country_code_error: {
    fontSize: "0.75rem",
    marginTop: 3,
    textAlign: "left",
    fontFamily: "Inter Regular",
    fontWeight: 400,
    lineHeight: 1.66,
    color: "#E13838"
  }
}));

const SponsorDilogBox = ({
  sponsorDilogOpen,
  setSposorDilogOpen,
  setShowAddMore,
  formik,
  SetEmailSearchQuery,
  index
}) => {
  const classes = useStyles();
  const [loading, setloading] = useState(false);

  const handleClose = () => {
    setSposorDilogOpen(false);
    setShowAddMore("");
  };

  const handleSubmit1 = async (val) => {
    setloading(true);
    const payload = {
      email: val.email,
      first_name: val.firstName,
      last_name: val.lastName,
      company_name: val.company,
      phone_number: val.phone,
      country_code: "+" + val.countryCode.replaceAll("+", "")
    };
    try {
      const resp = await SponsorService.addSponsor(payload);
      if (resp.success) {
        const data = resp.data;
        SetEmailSearchQuery("");
        const ArrayOfIds = [...formik.values.Sponsor.id];
        ArrayOfIds.push(data.id);
        formik.setFieldValue(`Sponsor.id`, ArrayOfIds);
        formik.setFieldValue(
          `Sponsor.sponsors_details[${index}].email`,
          data.email || "-"
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[${index}].name`,
          `${data.first_name} ${data.last_name}` || "-"
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[${index}].company_name`,
          data.company_name || "-"
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[${index}].code`,
          data.country_code || "-"
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[${index}].phone`,
          data.phone_number || "-"
        );
        Toast.showSuccessToast("Sponsor Created Successfully");
        handleClose();
      } else {
        Toast.showErrorToast(resp.error.message[0]);
      }
    } catch (err) {
      Toast.showErrorToast("Error In Creating Sponsor");
    }
    setloading(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={sponsorDilogOpen}
      //   onClose={handleClose}
      style={{
        backgroundColor: "rgba(235, 239, 255, 0.6)",
        backdropFilter: "blur(26)"
      }}>
      <DialogContent>
        <div className={classes.MaterialForm}>
          <div className={classes.header}>
            <div>
              <Typography variant="h4" className={classes.heading}>
                Add Sponsor
              </Typography>
            </div>
            <div className={classes.closeButton}>
              <img height="48px" src={blueCloseIcon} onClick={handleClose} />
            </div>
          </div>

          <Formik
            initialValues={{
              email: "",
              firstName: "",
              lastName: "",
              company: "",
              countryCode: "+1",
              phone: ""
            }}
            validationSchema={validationSchemaAddSponsor}
            enableReinitialize={true}
            className={classes.form}
            onSubmit={(values) => {
              handleSubmit1(values);
            }}>
            {(formik) => (
              <Form>
                <Grid container spacing={2}>
                  <Grid item lg={4} md={4} sm={6}>
                    <FieldHeading title="Email ID" />
                    <AppTextFieldWhite
                      id="email"
                      placeholder="Enter Sponsor Email"
                      variant="standard"
                      fullWidth
                      value={formik.values.email}
                      className={classes.textFields}
                      onChange={formik.handleChange("email")}
                      error={
                        formik.touched?.email && Boolean(formik.errors?.email)
                      }
                      helperText={formik.touched?.email && formik.errors?.email}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6}>
                    <FieldHeading title="First Name" />
                    <AppTextFieldWhite
                      id="firstName"
                      placeholder="Enter First Name"
                      variant="standard"
                      fullWidth
                      value={formik.values.firstName}
                      className={classes.textFields}
                      onChange={formik.handleChange("firstName")}
                      error={
                        formik.touched?.firstName &&
                        Boolean(formik.errors?.firstName)
                      }
                      helperText={
                        formik.touched?.firstName && formik.errors?.firstName
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6}>
                    <FieldHeading title="Last Name" />
                    <AppTextFieldWhite
                      id="lastName"
                      placeholder="Enter Last Name"
                      variant="standard"
                      fullWidth
                      value={formik.values.lastName}
                      className={classes.textFields}
                      onChange={formik.handleChange("lastName")}
                      error={
                        formik.touched?.lastName &&
                        Boolean(formik.errors?.lastName)
                      }
                      helperText={
                        formik.touched?.lastName && formik.errors?.lastName
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6}>
                    <FieldHeading title="Company Name" />
                    <AppTextFieldWhite
                      id="company"
                      placeholder="Enter Company Name"
                      variant="standard"
                      fullWidth
                      value={formik.values.company}
                      className={classes.textFields}
                      onChange={formik.handleChange("company")}
                      error={
                        formik.touched?.company &&
                        Boolean(formik.errors?.company)
                      }
                      helperText={
                        formik.touched?.company && formik.errors?.company
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} style={{ width: 408 }}>
                    <FieldHeading title="Phone Number" />
                    <Grid container wrap="nowrap">
                      <Grid item>
                        <PhoneInput
                          id="countryCode"
                          country={"us"}
                          placeholder="Code"
                          value={formik.values.countryCode}
                          onChange={formik.handleChange(`countryCode`)}
                          error={
                            formik.touched?.countryCode &&
                            Boolean(formik.errors?.countryCode)
                          }
                          helperText={
                            formik.touched?.countryCode &&
                            formik.errors?.countryCode
                          }
                          inputStyle={{
                            border: "1px solid #F4F2FF",
                            borderRadius: "6px",
                            fontSize: "15px",
                            width: "110px",
                            height: 46,
                            marginRight: "12px"
                          }}
                          dropdownStyle={{
                            width: "550%",
                            marginBottom: 30,
                            height: 100
                          }}
                          buttonStyle={{
                            borderRadius: "4px"
                          }}></PhoneInput>
                        <div className={classes.country_code_error}>
                          {formik.touched?.countryCode &&
                            formik.errors?.countryCode}
                        </div>
                      </Grid>

                      <Grid item>
                        <AppTextFieldWhite
                          id="phone"
                          placeholder="Enter Phone Number"
                          variant="standard"
                          type="tel"
                          onKeyDown={preventNonNumericalInput}
                          value={formik.values.phone}
                          style={{ width: "100%" }}
                          onChange={formik.handleChange(`phone`)}
                          error={
                            formik.touched?.phone &&
                            Boolean(formik?.errors.phone)
                          }
                          helperText={
                            formik.touched?.phone && formik.errors?.phone
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <div className={classes.buttonContainer}>
                  <button
                    className={styles.cancelbtn}
                    style={{ width: 174, margin: 0 }}
                    type="button"
                    onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={styles.updatebtn}
                    style={{ width: 174, margin: 0 }}
                    onClick={formik.handleSubmit}>
                    {loading ? "Loading..." : "Add"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SponsorDilogBox;
