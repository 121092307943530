import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../../components/HeaderComponent/HeaderComponent";
import { Box, Grid, makeStyles } from "@material-ui/core";
import EntityCard from "components/Cards/EntityCard";
import { useSelector } from "react-redux";
import { Endpoint, NetworkManager } from "../../../../network/core/index";
import PropertyInactive from "../../../../assets/icons/tab/PropertyInactive.svg";
import PropertyActive from "../../../../assets/icons/tab/PropertyActive.svg";
import Overview from "./section/Overview";
// import Loader from "components/Loader/AppLoader";
import { numFormatter } from "helpers";
// import EntityDocuments from "./section/EntityDocuments";
import DollerInactive from "../../../../assets/icons/tab/DollerInactive.svg";
import DollerActive from "../../../../assets/icons/tab/DollerActive.svg";
import investorInactive from "../../../../assets/icons/tab/investorInactive.svg";
import investorActive from "../../../../assets/icons/tab/investorActive.svg";
import LoansInactive from "../../../../assets/icons/tab/LoansInactive.svg";
import LoansActive from "../../../../assets/icons/tab/LoansActive.svg";
import ToggleSwitch from "components/Common/ToggleSwitch";
import styles from "components/Common/toggle.module.scss";
import Toast from "components/toast";
// import { truncate } from "helpers/Common";
import Investments from "./section/Investment/Investments";
import Properties from "./section/Properties/Properties";
import totalInvestmentDollar from "../../../../assets/icons/tab/totalInvestmentDollar.svg";
import distribtutionAmount from "../../../../assets/icons/tab/distribtutionAmount.svg";
import totalDistribution from "../../../../assets/icons/tab/totalDistribution.svg";
import totalInvestedDollar from "../../../../assets/icons/tab/totalInvestedDollar.svg";
import LoanInformation from "./section/Loan/LoanInformation";
import { AppDispatcher } from "../../../../redux/index";
import { Skeleton } from "@material-ui/lab";
import ExportPDFButton from "components/Buttons/Primary/ExportPDFButton";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  cardsContainer: {
    marginTop: 20
  },
  sectionContainer: {
    marginTop: 20,
    marginBottom: 20
  }
});

const ToggleList = [
  {
    id: 0,
    name: "Overview",
    color: "#5775E5",
    textColor: "#fff",
    inActiveIcon: DollerInactive,
    activeIcon: DollerActive
  },
  {
    id: 1,
    name: "Investments",
    textColor: "#BBBBBB",
    inActiveIcon: investorInactive,
    activeIcon: investorActive
  },
  {
    id: 2,
    name: "Properties",
    textColor: "#BBBBBB",
    inActiveIcon: PropertyInactive,
    activeIcon: PropertyActive
  },
  {
    id: 3,
    name: "Loans",
    textColor: "#BBBBBB",
    inActiveIcon: LoansInactive,
    activeIcon: LoansActive
  }
];

const SponsorPage = ({ handleClick }) => {
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [overViewData, setOverViewData] = useState({});
  const [sponsorData, setSponsorData] = useState({
    id: "",
    totalRaise: "",
    totalInvestments: "",
    totalDistributionsReceived: "",
    address: "",
    taxId: "",
    name: "",
    partner: [],
    equity: 0,
    outLoan: 0,
    totalAssetValue: 0,
    investmentData: [],
    loanData: []
  });
  const { entitiesDetailData } = useSelector((store) => store.app);

  const getFullName = (data) => {
    let name = data?.first_name;
    if (name) {
      name += " " + (data?.last_name || "");
    } else {
      return "-";
    }
    return name;
  };

  const skeleton = (input) => {
    return Object.keys(overViewData).length != 0
      ? input
      : <Skeleton width={150} variant="text" /> || "-";
  };

  const fetchEntitiesDetails = async () => {
    const instance = new NetworkManager(
      new Endpoint(`entity/detail/${entitiesDetailData?.id}/`, "GET")
    );

    const resp = await instance.httpRequest();

    if (resp.success) {
      let temp = resp.data;
      setOverViewData(temp);
      setSponsorData({
        entityName: temp?.name,
        id: temp?.base_id,
        documents: temp?.documents,
        totalRaise: temp?.stats?.total_raise,
        totalInvestments: temp?.investment[0]?.total_investment,
        totalDistributionValue:
          temp?.investment[0]?.total_distribution?.amount__sum,
        totalDistributionCount: temp?.investment[0]?.distribution_count,
        address: temp?.address[0]?.address,
        taxId: temp?.tax_id,
        entityTypeId: temp?.entity_type_id?.id,
        name: getFullName(temp?.entity_type_id),
        partner: temp?.partners,
        equity: temp?.stats?.equity,
        outLoan: temp?.stats?.outstanding_loan,
        totalAssetValue: temp?.stats?.total_asset_value,
        investmentData: temp?.investment,
        stats: temp?.stats
      });
    } else {
      Toast.showErrorToast("not working");
    }
  };

  const onEditInvestmentBtnClick = () => {
    AppDispatcher.setEntitiesDetailData({
      id: overViewData?.id,
      entity_type: overViewData?.entity_type
    });
    history.push("/u/entities/editEntity");
  };

  const loadInvestmentDetails = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <>
            <Grid container>
              <Overview data={overViewData} handleClick={handleClick} />
            </Grid>
          </>
        );
      case 1:
        return (
          <Investments
            data={overViewData}
            entity_type={"Sponsor"}
            handleClick={handleClick}
          />
        );
      case 2:
        return (
          <Properties
            data={overViewData?.investment}
            handleClick={handleClick}
          />
        );
      case 3:
        return (
          <LoanInformation data={overViewData} handleClick={handleClick} />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    fetchEntitiesDetails();
  }, []);
  const classes = useStyles();

  return (
    <>
      <HeaderComponent
        backBtn={true}
        heading={skeleton(sponsorData?.entityName)}
        description={skeleton(overViewData?.base_id)}
        investment_Status={overViewData?.status}
        managedBy={overViewData?.managed_by}
        isEditInvestorBtn={true}
        ExtraButtons={<ExportPDFButton />}
        onEditInvestmentBtnClick={() => {
          onEditInvestmentBtnClick();
        }}
      />

      <>
        <Grid
          container
          className={classes.cardsContainer}
          spacing={2}
          direction="row">
          <EntityCard
            Title="Total Invested Amount"
            count={skeleton(
              `$${numFormatter(overViewData?.stats?.total_raise)}` || `$0`
            )}
            color="green"
            icon={totalInvestedDollar}
          />
          <EntityCard
            Title="Total Investments"
            count={skeleton(overViewData?.stats?.total_investments) || `$0`}
            color="green"
            icon={totalInvestmentDollar}
            link="/u/investments"
          />
          <EntityCard
            Title="Total Distributions"
            count={skeleton(
              `$${numFormatter(overViewData?.stats?.total_distribution)}` || 0
            )}
            color="#5E5499"
            icon={distribtutionAmount}
          />
          <EntityCard
            Title="Total Distributions"
            count={skeleton(overViewData?.stats?.distribution_count) || "0"}
            color="#5E5499"
            icon={totalDistribution}
            link="/u/distributions"
          />
        </Grid>

        {/* ToggleTabs */}
        <div style={{ padding: "20px 0" }}>
          <div className={styles.toggleresponiveCard}>
            <Grid
              container
              style={{
                backgroundColor: "#FAFAFA",
                height: 56,
                borderRadius: 10
              }}
              className={styles.togglecardGrid}>
              <ToggleSwitch
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
                list={ToggleList}
                xs={3}
                lg={2}
                md={3}
                sm={3}
              />
            </Grid>
          </div>
        </div>

        {/*Details*/}
        <Grid container>
          <Grid item xs={12}>
            {loadInvestmentDetails()}
          </Grid>
        </Grid>
        <Box height={50} />
      </>
    </>
  );
};

export default SponsorPage;
