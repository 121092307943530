import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import OverViewTable from "./NewComponents/OverViewTable";
import SectionLayout from "./NewComponents/SectionLayout";
import SponsorTable from "./NewComponents/SponsorTable";
import styles from "../steps.module.css";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { AddInvestmentPreviewService } from "network/addInvestmentPreviewService";
import { AppDispatcher } from "../../../../../../redux/index";
import Toast from "components/toast";
import Loader from "components/Loader/AppLoader";
import DocumentsUpload from "../../../../../../components/DocumentsUpload/documentsUpload";
import PropertiesAccordionTable from "./NewComponents/PropertiesAccordian";
import K1Table from "components/Common/K1Table";
import DistributionTable from "components/Common/DistributionTable";
import InvestorTable from "components/Common/InvestorTable";
import ContactsTable from "components/Common/ContactsTable";
import LoanAccordionTable from "components/Common/LoanAccordian/LoanAccordianTable";
import RefinanceAccordionTable from "components/Common/RefinanceAccordian/RefinanceAccordianTable";
import Illustrations from "components/illustration/Illustrations";
import noSponsorTiers from "assets/emptyState/NoSponsoeTiers.svg";
import noProperties from "assets/emptyState/noProperties.svg";
import noContacts from "assets/emptyState/noContacts.svg";
import noLoans from "assets/emptyState/noLoans.svg";
import noManageData from "assets/emptyState/noManageData.svg";
import noInvestors from "assets/emptyState/noInvestors.svg";
import noDistribution from "assets/emptyState/noDistribution.svg";
import formatCurrency from "helpers/formatCurrency";
import formatDate from "helpers/formatDate";
import { truncate } from "helpers/Common";

const dtf = {
  startofmonth: "Start Of The Month",
  midofmonth: "Mid Of The Month",
  endofmonth: "End Of The Month",
  startofquarter: "Start Of The Quarter",
  midofquarter: "Mid Of The Quarter",
  endofquarter: "End Of The Quarter"
};

function NewPreview() {
  const history = useHistory();
  const [data, setData] = useState({});
  const [overView, setOverView] = useState([]);
  const [Equity, setEquity] = useState([]);
  const [Sponsors, setSponsors] = useState([]);
  const [SpoTiers, setSponTiers] = useState([]);
  const [NOI, setNOI] = useState([]);
  const [property, setProperty] = useState([]);
  const { investmentID } = useSelector((store) => store.app);
  const [isLoading, setIsLoading] = useState(false);
  const [investmentStatus, setInvestmentStatus] = useState("Draft");

  const goToStep = (heading) => {
    switch (heading) {
      case "Investors": {
        AppDispatcher.setAddInvestmentStep(1);
        break;
      }
      case "Loan Information": {
        AppDispatcher.setAddInvestmentStep(2);
        break;
      }
      case "Refinance": {
        AppDispatcher.setAddInvestmentStep(3);
        break;
      }
      case "Distribution": {
        AppDispatcher.setAddInvestmentStep(4);
        break;
      }
      default: {
        AppDispatcher.setAddInvestmentStep(0);
        break;
      }
    }
  };

  const setDraftStatus = async (isDraft) => {
    setIsLoading(true);
    const res = await AddInvestmentPreviewService.setStatus(
      { is_draft: isDraft },
      investmentID
    );
    if (res.success) {
      history.push("/u/investments");
    } else {
      Toast.showErrorToast("Error Saving Data");
    }
    setIsLoading(false);
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  useEffect(async () => {
    setIsLoading(true);
    const res = await AddInvestmentPreviewService.getInvestmentDetails(
      investmentID
    );
    if (res.success) {
      setData(res.data);
      setInvestmentStatus(res.data?.status);
    } else {
      Toast.showErrorToast("Error Loading Investment Details");
    }
    setIsLoading(false);
  }, []);

  function getSponsorArray(item) {
    const arrOf_SpoArr = [];

    item?.forEach((ele) => {
      let spo_arr = [];
      spo_arr.push({
        id: "1",
        val: ele?.sponsor_detail?.name
      });
      spo_arr.push({
        id: "2",
        val: ele?.sponsor_detail?.email
      });
      spo_arr.push({
        id: "3",
        val: ele?.sponsor_detail?.company_name
      });
      spo_arr.push({
        id: "4",
        val: `${ele?.sponsor_detail?.country_code} ${ele?.sponsor_detail?.phone_number}`
      });
      spo_arr.push({ id: "5", val: ele?.entity?.tax_id });
      spo_arr.push({ id: "6", val: ele?.entity?.name });
      spo_arr.push({
        id: "7",
        val: ele?.address?.[0]?.address
      });

      arrOf_SpoArr.push(spo_arr);
      spo_arr = [];
    });

    return arrOf_SpoArr;
  }

  function getSponsorTiers(item) {
    const arrOf_SpoArr = [];

    item?.forEach((ele, i) => {
      let spo_arr = [];
      spo_arr.push({ id: "1", val: `Tier ${i + 1}` });
      spo_arr.push({ id: "2", val: `${ele?.startfrom || "-"}%` });
      spo_arr.push({ id: "3", val: `${ele?.to || "-"}%` });
      spo_arr.push({ id: "4", val: `${ele?.promote || "-"}%` });

      arrOf_SpoArr.push(spo_arr);
      spo_arr = [];
    });

    return arrOf_SpoArr;
  }

  function getNOI(item) {
    const arrOf_SpoArr = [];

    item?.forEach((ele) => {
      let spo_arr = [];
      spo_arr.push({ id: "1", val: `${ele?.year || "-"}` });
      spo_arr.push({ id: "2", val: `${ele?.noi_cadence || "-"}` });
      spo_arr.push({
        id: "3",
        val: `${
          ele?.investment_noi ? formatCurrency(ele?.investment_noi) : "-"
        }`
      });
      spo_arr.push({
        id: "4",
        val: `${ele?.created_at ? formatDate(ele?.created_at) : "-"}`
      });
      spo_arr.push({
        id: "5",
        val: (
          <DocumentsUpload
            editable={false}
            title="NOI"
            listData={formatDocsData(ele?.documents)}
          />
        )
      });

      arrOf_SpoArr.push(spo_arr);
      spo_arr = [];
    });

    return arrOf_SpoArr;
  }

  useEffect(() => {
    setOverView([
      {
        id: "1",
        title: "Investment Name",
        value: data.investment?.name ? truncate(data.investment?.name, 16) : "-"
      },
      {
        id: "2",
        title: "Purchase Price",
        value: `${
          data.investment?.purchase_price
            ? formatCurrency(data.investment?.purchase_price)
            : "-"
        }`
      },
      {
        id: "3",
        title: "Distribution Start Date",
        value: `${
          data?.investment?.start_date
            ? formatDate(data.investment?.start_date)
            : "-"
        }`
      },
      {
        id: "4",
        title: "Distribution Cadence",
        value: `${data.investment?.distribution_cadence || "-"}`
      },
      {
        id: "5",
        title: "Refinance",
        value: `${data.investment?.is_refinanced ? "Yes" : "No"}`
      },
      {
        id: "6",
        title: "Projected Return (%)",
        value: `${
          data.investment?.projected_returns
            ? data.investment?.projected_returns + "%"
            : "-"
        }`
      },
      {
        id: "7",
        title: "Deal Close Date",
        value: `${
          data?.investment?.closing_date
            ? formatDate(data?.investment?.closing_date)
            : "-"
        }`
      },
      {
        id: "8",
        title: "Distribution Time Frame",
        value: `${
          data.investment?.distribution_time_frame
            ? dtf[data.investment?.distribution_time_frame]
            : "-"
        }`
      },
      {
        id: "9",
        title: "Cap Rate",
        value: `${
          data.investment?.cap_rate ? data.investment?.cap_rate + "%" : "-"
        }`
      },
      {
        id: "10",
        title: "Total Deal Size",
        value: `${
          data?.investment?.total_raise
            ? formatCurrency(data.investment?.total_raise)
            : "-"
        }`
      },
      {
        ...(data?.investment?.sold_date && {
          id: "11",
          title: "Date Of Sale",
          value: `${
            data?.investment?.sold_date
              ? formatDate(data.investment?.sold_date)
              : "-"
          }`
        })
      },
      {
        ...(data?.investment?.sold_price && {
          id: "12",
          title: "Price Of Sale",
          value: `${
            data?.investment?.sold_price
              ? formatCurrency(data.investment?.sold_price)
              : "-"
          }`
        })
      }
    ]);
    setEquity([
      {
        id: "1",
        title: "Total Asset Value",
        value: data.equity_info?.total_asset
          ? formatCurrency(data.equity_info?.total_asset)
          : "-"
      },
      {
        id: "7",
        title: "As On",
        value: `${
          data?.equity_info?.as_on ? formatDate(data?.equity_info?.as_on) : "-"
        }`
      },
      {
        id: "2",
        title: "Outstanding Loan",
        value: `${
          data.equity_info?.outstanding
            ? formatCurrency(data.equity_info?.outstanding)
            : "-"
        }`
      },
      {
        id: "3",
        title: "Bank Name",
        value: `${data.equity_info?.bank_name || "-"}`
      },
      {
        id: "4",
        title: "Debt Service",
        value: `${
          data?.equity_info?.debtservice
            ? formatCurrency(data?.equity_info?.debtservice)
            : "-"
        }`
      },
      {
        id: "5",
        title: "Date",
        value: `${
          data?.equity_info?.date ? formatDate(data?.equity_info?.date) : "-"
        }`
      },
      {
        id: "6",
        title: "Equity",
        value: `${
          data.equity_info?.equity_amount
            ? formatCurrency(data.equity_info?.equity_amount)
            : "-"
        }`
      }
    ]);

    setSponsors(getSponsorArray(data?.sponsor_data));
    setSponTiers(getSponsorTiers(data?.sponsor_tier));
    setNOI(getNOI(data?.inv_noi));
    setProperty(data?.properties);
  }, [data]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={4}>
          {/* overView */}
          <SectionLayout heading={"Overview"} goToStep={goToStep}>
            <OverViewTable mapArray={overView} />
          </SectionLayout>

          {/* Equity Information */}
          <SectionLayout heading={"Equity Information"} goToStep={goToStep}>
            <OverViewTable mapArray={Equity} />
          </SectionLayout>

          {/* Sponsors */}
          <SectionLayout heading={"Sponsors"} dropShadow goToStep={goToStep}>
            <SponsorTable
              Headingdata={[
                { heading: "Sponsor Name" },
                { heading: "Email ID" },
                { heading: "Company Name" },
                { heading: "Contact Number" },
                { heading: "Tax ID (EIN)" },
                { heading: "Sponsor Entity Name" },
                { heading: "Mailing Address" }
              ]}
              mapArray={Sponsors}
            />
          </SectionLayout>

          <Grid container item xs={12} spacing={4} style={{ margin: 0 }}>
            <Grid container item lg={6} md={12} sm={12}>
              {/* SponsorsTiers */}
              <SectionLayout
                heading={"Sponsors Tiers"}
                dropShadow
                goToStep={goToStep}>
                {SpoTiers.length ? (
                  <SponsorTable
                    width={"714px"}
                    Headingdata={[
                      { heading: "Tiers" },
                      { heading: "From" },
                      { heading: "To" },
                      { heading: "Promote" }
                    ]}
                    mapArray={SpoTiers}
                  />
                ) : (
                  <Grid item xs={12}>
                    <Illustrations
                      src={noSponsorTiers}
                      subHeading={"No Added Tiers."}
                    />
                  </Grid>
                )}
              </SectionLayout>
            </Grid>
            <Grid container item lg={6} md={12} sm={12}>
              {/* Net Operating Income */}
              <SectionLayout
                heading={"Net Operating Income"}
                dropShadow
                goToStep={goToStep}>
                {NOI.length ? (
                  <SponsorTable
                    width={"714px"}
                    Headingdata={[
                      { heading: "Year" },
                      { heading: "Tenure" },
                      { heading: "Amount" },
                      { heading: "Update Date" },
                      { heading: "Documents" }
                    ]}
                    mapArray={NOI}
                  />
                ) : (
                  <Grid item xs={12}>
                    <Illustrations
                      src={noSponsorTiers}
                      subHeading={`No Added Nois.`}
                    />
                  </Grid>
                )}
              </SectionLayout>
            </Grid>
          </Grid>

          {/* Properties Accordian Table*/}
          <SectionLayout heading={"Properties"} dropShadow goToStep={goToStep}>
            {property.length ? (
              <PropertiesAccordionTable data={property} />
            ) : (
              <Grid item xs={12}>
                <Illustrations
                  src={noProperties}
                  subHeading={`No Added Properties.`}
                />
              </Grid>
            )}
          </SectionLayout>

          {/* Contacts */}
          <SectionLayout heading={"Contacts"} dropShadow goToStep={goToStep}>
            {data?.contacts?.length ? (
              <ContactsTable
                Headingdata={[
                  { heading: "Contact Name" },
                  { heading: "Company Name" },
                  { heading: "Email ID" },
                  { heading: "Contact Number" },
                  { heading: "Designation" },
                  { heading: "Primary Contact" }
                ]}
                mapArray={data?.contacts}
              />
            ) : (
              <Grid item xs={12}>
                <Illustrations
                  src={noContacts}
                  subHeading={`No Added Contacts.`}
                />
              </Grid>
            )}
          </SectionLayout>

          {/* K1's */}
          <SectionLayout heading={"K1's"} dropShadow goToStep={goToStep}>
            {data?.manage_data?.length ? (
              <K1Table
                Headingdata={[
                  { heading: "Investor" },
                  { heading: "Year" },
                  { heading: "Notes" },
                  { heading: "File" }
                ]}
                mapArray={data?.manage_data}
              />
            ) : (
              <Grid item xs={12}>
                <Illustrations
                  src={noManageData}
                  subHeading={`No Added K1s.`}
                />
              </Grid>
            )}
          </SectionLayout>

          {/* Investors */}
          <SectionLayout heading={"Investors"} dropShadow goToStep={goToStep}>
            {data?.investors?.length ? (
              <InvestorTable
                Headingdata={[
                  { heading: "Investor Name" },
                  { heading: "Date of Investment" },
                  { heading: "Preferred Return (%)" },
                  { heading: "Last Date of Preferred Return" },
                  { heading: "Invested Amount" },
                  { heading: "Membership (%)" },
                  { heading: "Investment Type" },
                  { heading: "Documents" }
                ]}
                mapArray={data?.investors}
              />
            ) : (
              <Grid item xs={12}>
                <Illustrations
                  src={noInvestors}
                  subHeading={`No Added Investors.`}
                />
              </Grid>
            )}
          </SectionLayout>

          {/* Loan Information Accordian Table */}
          <SectionLayout
            heading={"Loan Information"}
            dropShadow
            goToStep={goToStep}>
            {data?.loan?.length ? (
              <LoanAccordionTable data={data?.loan} />
            ) : (
              <Grid item xs={12}>
                <Illustrations src={noLoans} subHeading={`No Added Loans.`} />
              </Grid>
            )}
          </SectionLayout>

          {/* Refinance Accordian Table*/}
          <SectionLayout heading={"Refinance"} dropShadow goToStep={goToStep}>
            {data?.refinances?.length ? (
              <RefinanceAccordionTable data={data?.refinances} />
            ) : (
              <Grid item xs={12}>
                <Illustrations
                  src={noLoans}
                  subHeading={`No Added Refinance.`}
                />
              </Grid>
            )}
          </SectionLayout>

          {/* Distribution */}
          <SectionLayout
            heading={"Distribution"}
            dropShadow
            goToStep={goToStep}>
            {data?.distribution?.length ? (
              <DistributionTable
                Headingdata={[
                  { heading: "Investor Name" },
                  { heading: "Amount" },
                  { heading: "Date Received" },
                  { heading: "Type" },
                  { heading: "Year" },
                  { heading: "Tenure" },
                  { heading: "Notes" }
                ]}
                mapArray={data?.distribution}
              />
            ) : (
              <Grid item xs={12}>
                <Illustrations
                  src={noDistribution}
                  subHeading={`No Added Distributions.`}
                />
              </Grid>
            )}
          </SectionLayout>

          {/* buttons */}
          <Grid container className={styles.btncontainforms}>
            {investmentStatus === "Draft" ? (
              <p
                className={styles.saveAsDraftText}
                onClick={() => setDraftStatus(true)}>
                Save As Draft
              </p>
            ) : null}
            <div
              style={investmentStatus === "Draft" ? {} : { marginLeft: "20px" }}
              className={styles.addInvestmentButtonContainer}
              onClick={() => setDraftStatus(false)}>
              <p className={styles.addInvestmentButtonText}>Upload</p>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default NewPreview;
