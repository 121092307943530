import { Hidden } from "@material-ui/core";
import React from "react";
import MoreFilters from "components/NewMoreFilters/FilterModal";
import MobileMoreFilters from "components/Modals/InvestmentFilterModal";
import { AnimatePresence, motion } from "framer-motion";

function MoreFilterContainer({
  open,
  checked,
  handleClose,
  handleApply,
  handleClear,
  filters
}) {
  return (
    <>
      <Hidden xsDown>
        <AnimatePresence>
          <motion.div
            key={filters}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 1, height: 0 }
            }}
            transition={{
              duration: 0.5,
              ease: [0.04, 0.62, 0.93, 0.98]
            }}>
            <MoreFilters
              open={open}
              checked={checked}
              handleClose={handleClose}
              handleApply={handleApply}
              handleClear={handleClear}
              filters={filters}
            />
          </motion.div>
        </AnimatePresence>
      </Hidden>
      <Hidden smUp>
        <MobileMoreFilters
          open={open}
          checked={checked}
          handleClose={handleClose}
          handleApply={handleApply}
          handleClear={handleClear}
          filters={filters}
        />
      </Hidden>
    </>
  );
}

export default MoreFilterContainer;
