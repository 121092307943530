import {
  Table,
  TableContainer,
  TableRow,
  TableHead,
  TableCell,
  Paper,
  TableBody,
  Grid
} from "@material-ui/core";
import React from "react";
import styles from "../EntityDetail.module.scss";

import { Skeleton } from "@material-ui/lab";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import Illustrations from "components/illustration/Illustrations";
import DocumentEmptyImage from "assets/emptyState/noDistribution.svg";
import { CovertToMb } from "helpers/Common";

function DocumentsTable({ data, heading, value, type = false }) {
  function getSize(item) {
    return item?.reduce((total, { size }) => total + size, 0);
  }
  return (
    <>
      {Object.keys(data)?.length != 0 ? (
        <TableContainer
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#fff"
          }}
          component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                {heading.map((value, index) => {
                  return (
                    <TableCell key={index} className={styles.noiTableHeader}>
                      {value}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(data)?.map((row) => (
                <TableRow
                  key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <>
                    {type ? (
                      <TableCell component="th" scope="row">
                        Sponsor Document
                      </TableCell>
                    ) : (
                      <TableCell component="th" scope="row">
                        {row}
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row">
                      {/* {data[row].map((value) => {
                        let ans = 0;
                        return (ans = ans + value?.size);
                      })}{" "}
                      MB */}
                      {CovertToMb(getSize(data[row]))}
                    </TableCell>
                    <TableCell>
                      <DocumentsUpload editable={false} listData={data[row]} />
                    </TableCell>
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : Object.keys(value)?.length != 0 && Object.keys(data)?.length == 0 ? (
        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff"
          }}>
          <Illustrations
            src={DocumentEmptyImage}
            heading="No uploaded documents"
          />
        </Grid>
      ) : (
        [1, 2, 3, 4, 5].map((value, index) => {
          return (
            <Grid
              key={index}
              style={{
                boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
                backgroundColor: "#ffff"
              }}>
              <Skeleton width="100%" variant="text" height={40} />
            </Grid>
          );
        })
      )}
    </>
  );
}

export default DocumentsTable;
