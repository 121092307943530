import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,

  //   Tooltip,
  Grid,
  withStyles
} from "@material-ui/core";
import Illustrations from "components/illustration/Illustrations";

import { truncate } from "helpers/Common";
import React, { useState } from "react";
import style from "../dashboard.module.css";
import { AppDispatcher } from "../../../../redux/index";
import investorEmptyState from "assets/emptyState/noInvestors.svg";
import investmentEmptyState from "assets/emptyState/noInvestments.svg";
import distributionEmptyState from "assets/emptyState/noDistribution.svg";
// import occupancyEmptyState from "assets/emptyState/occupancyEmptyState.svg";

import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import NewDropDown from "components/NewDropDown";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const StyledTableCell = withStyles((theme) => ({
  root: { paddingLeft: "10px" },
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.white,
    borderBottom: "1px solid #616161"
  },
  body: {
    fontSize: 14,
    cursor: "default"
  }
}))(TableCell);

function CommonDashboardTable({ tableData, heading, type, trigger = false }) {
  const history = useHistory();
  const [expandIndex, setExpandIndex] = useState(-1);
  let setLoanHead = 0;
  tableData?.map((value) => {
    if (Object.keys(value)?.length > 0) {
      setLoanHead = 1;
    }
  });

  const handleCellClick = (name, id) => {
    switch (name) {
      case "investor_id":
        AppDispatcher.setInvestorDetailID(id);
        history.push("/u/investors/detail");
        break;
      case "investment_id":
        AppDispatcher.setInvestmentDetailID(id);
        history.push("/u/investments/detail");
        break;
    }
  };

  let illustration = {
    investor: [investorEmptyState, "No Investor found"],
    investment: [investmentEmptyState, "No Investment found"],
    distribution: [distributionEmptyState, "No Distribution found"]
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  return (
    <>
      {tableData?.length != 0 ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            {setLoanHead == 1 ? (
              <TableHead>
                <TableRow>
                  {heading.map((value, index) => {
                    return (
                      <TableCell
                        style={{
                          borderBottom: "1px solid #A0A0A0",
                          fontWeight: 600,
                          fontSize: "16px"
                        }}
                        key={index}
                        className={style.noiTableHeader}>
                        {value}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            ) : (
              <Illustrations
                src={illustration[type][0]}
                heading={illustration[type][1]}
                // subHeading="Add Investment Data To View"
              />
            )}
            <TableBody>
              {tableData?.map((value, index) => {
                return (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                    {heading.map((item, indx) => {
                      return (
                        <TableCell
                          style={
                            indx === 0
                              ? index == tableData.length - 1
                                ? {
                                    cursor: "pointer",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    borderBottom: "none",
                                    whiteSpace: "nowrap"
                                  }
                                : {
                                    cursor: "pointer",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    whiteSpace: "nowrap"
                                  }
                              : item === "Investors" &&
                                index == tableData.length - 1
                              ? {
                                  width: "250px",
                                  borderBottom: "none",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  whiteSpace: "nowrap"
                                }
                              : index == tableData.length - 1
                              ? item === "Investor Name"
                                ? {
                                    borderBottom: "none",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    whiteSpace: "nowrap",
                                    padding: "21px 0px 22px 14px"
                                  }
                                : {
                                    borderBottom: "none",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    whiteSpace: "nowrap"
                                  }
                              : item === "Investor Name"
                              ? {
                                  // backgroundColor: "red",
                                  padding: "22px 0px 22px 14px",
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  whiteSpace: "nowrap"
                                }
                              : {
                                  fontWeight: 400,
                                  fontSize: "16px",
                                  whiteSpace: "nowrap"
                                }
                          }
                          align="left"
                          component="th"
                          scope="row">
                          <Grid
                            className={[
                              indx === 0 && style.firstCell,

                              indx == 4
                                ? value[indx] != "-" &&
                                  (value[indx] === "Active"
                                    ? style.activeCell
                                    : value[indx] === "Pending"
                                    ? style.pendingStatus
                                    : style.inActiveCell)
                                : null
                            ]}>
                            {item === "File" ? (
                              <DocumentsUpload
                                editable={false}
                                listData={formatDocsData(value[indx])}
                              />
                            ) : item === "Investors" ? (
                              <span
                                style={{
                                  //   color: "#BD54B9",
                                  backgroundColor: "rgba(189, 84, 185, 0.05)"
                                }}>
                                <NewDropDown
                                  index={indx}
                                  item={
                                    value[indx]?.map((item) => {
                                      return {
                                        id: null,
                                        name: item?.name
                                      };
                                    }) || []
                                  }
                                  expandIndex={expandIndex}
                                  setExpandIndex={setExpandIndex}
                                />
                              </span>
                            ) : item == "Amount Invested" ? (
                              <span style={{ color: "#E13838" }}>
                                -{truncate(value[indx], 20)}
                              </span>
                            ) : item === "Distribution Received" ||
                              item === "Amount Received" ? (
                              <span style={{ color: "#398175" }}>
                                +{truncate(value[indx], 20)}
                              </span>
                            ) : (
                              <span
                                onClick={() =>
                                  indx == 0 &&
                                  handleCellClick(value["type"], value["id"])
                                }>
                                {truncate(value[indx], 20)}
                              </span>
                            )}
                          </Grid>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : trigger ? (
        <TableBody style={{ overflow: "hidden" }}>
          {new Array(6).fill(null).map((item, index) => (
            <TableRow>
              {[1, 2, 3, 4, 5].map(() => {
                return (
                  <StyledTableCell key={index}>
                    <Skeleton width={150} height={17} />
                  </StyledTableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      ) : (
        <Grid
          style={{
            backgroundColor: "#ffff",
            width: "100vw"
          }}>
          <Illustrations
            src={illustration[type][0]}
            heading={illustration[type][1]}
            // subHeading="Add Investment Data To View"
          />
        </Grid>
      )}
    </>
  );
}

export default CommonDashboardTable;

{
  /* <Grid
          style={{
            backgroundColor: "#ffff",
            width: "100vw"
          }}>
          <Illustrations
            src={illustration[type][0]}
            heading={illustration[type][1]}
            // subHeading="Add Investment Data To View"
          />
        </Grid> */
}
