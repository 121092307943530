import { Grid, makeStyles, Typography } from "@material-ui/core";
import formatCurrency from "helpers/formatCurrency";
import React from "react";
import InvestmentSponsorTable from "./InvestmentSponsorTable";
import { numFormatter } from "helpers";

const useStyles = makeStyles({
  cardHeading: {
    fontSize: "18px !important",
    fontFamily: "Inter SemiBold",
    fontWeight: 700,
    color: "#191919",
    paddingBottom: "20px",
    backgroundColor: "#fff"
  }
});

const heading = [
  "Investment Name",
  "Managed By",
  "Sponsor Entity",
  "Investor Entity",
  "Total Asset Value",
  "Outstanding Loan",
  "Equity",
  "Status"
];

function InvestmentSponsor({ data }) {
  const classes = useStyles();
  const tableData = data?.investments?.map((item) => {
    return {
      0: item?.name || "-",
      1: item?.managed_by || "-",
      2: item?.sponsor_entity?.name || "-",
      3: item?.investor_entity || [],
      4: formatCurrency(item?.stats?.total_asset || "0"),
      5: formatCurrency(item?.stats?.outstanding || "0"),
      6: formatCurrency(item?.stats?.equity_amount || "0"),
      7: item?.status || "-",
      toolTipData: [
        ["Investment ID:", item?.base_id],
        ["Distribution Count:", item?.stats?.distribution_count || "0"],
        ["Purchase Price:", `$${numFormatter(item?.purchase_price)}`],
        ["Created By:", item?.created_by]
      ],
      tableType: false,
      investmentID: item?.id,
      sponsorEntityID: item?.sponsor_entity?.id
    };
  });

  return (
    <Grid style={{ paddingTop: "30px" }}>
      <Grid xs={12}>
        <Typography className={classes.cardHeading}>
          Associated Investments
        </Typography>
      </Grid>
      <Grid container>
        <InvestmentSponsorTable
          data={data?.investments}
          tableData={tableData}
          heading={heading}
        />
      </Grid>
    </Grid>
  );
}

export default InvestmentSponsor;
