import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../../components/HeaderComponent/HeaderComponent";
import { Box, Grid, makeStyles } from "@material-ui/core";
import EntityCard from "components/Cards/EntityCard";
import { useSelector } from "react-redux";
import { Endpoint, NetworkManager } from "../../../../network/core/index";

import Overview from "./section/Overview";
import { numFormatter } from "helpers";
import { AppDispatcher } from "../../../../redux/index";

// import EntityDocuments from "./section/EntityDocuments";
import DollerInactive from "../../../../assets/icons/tab/DollerInactive.svg";
import DollerActive from "../../../../assets/icons/tab/DollerActive.svg";
import investorInactive from "../../../../assets/icons/tab/investorInactive.svg";
import investorActive from "../../../../assets/icons/tab/investorActive.svg";
import PropertyInactive from "../../../../assets/icons/tab/PropertyInactive.svg";
import PropertyActive from "../../../../assets/icons/tab/PropertyActive.svg";
import ToggleSwitch from "components/Common/ToggleSwitch";
import styles from "components/Common/toggle.module.scss";
import totalInvestmentDollar from "../../../../assets/icons/tab/totalInvestmentDollar.svg";
import distribtutionAmount from "../../../../assets/icons/tab/distribtutionAmount.svg";
import totalDistribution from "../../../../assets/icons/tab/totalDistribution.svg";
import totalInvestedDollar from "../../../../assets/icons/tab/totalInvestedDollar.svg";
import Investments from "./section/Investment/Investments";
import Properties from "./section/Properties/Properties";
import { Skeleton } from "@material-ui/lab";
import ExportPDFButton from "components/Buttons/Primary/ExportPDFButton";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  cardsContainer: {
    marginTop: 20,
    fontFamily: "Inter SemiBold"
  },
  sectionContainer: {
    marginTop: 20,
    marginBottom: 20
  }
});

const ToggleList = [
  {
    id: 0,
    name: "Overview",
    color: "#5775E5",
    textColor: "#fff",
    inActiveIcon: DollerInactive,
    activeIcon: DollerActive
  },
  {
    id: 1,
    name: "Investments",
    textColor: "#BBBBBB",
    inActiveIcon: investorInactive,
    activeIcon: investorActive
  },
  {
    id: 2,
    name: "Properties",
    textColor: "#BBBBBB",
    inActiveIcon: PropertyInactive,
    activeIcon: PropertyActive
  }
];

const InvestorPage = ({ handleClick }) => {
  const history = useHistory();
  const { entitiesDetailData } = useSelector((store) => store.app);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [overViewData, setOverViewData] = useState({});

  const onEditInvestmentBtnClick = () => {
    AppDispatcher.setEntitiesDetailData({
      id: overViewData?.id,
      entity_type: overViewData?.entity_type
    });
    history.push("/u/entities/editEntity");
  };

  const skeleton = (input) => {
    return Object.keys(overViewData).length != 0
      ? input
      : <Skeleton width={150} variant="text" /> || "-";
  };

  const fetchEntitiesDetails = async () => {
    const instance = new NetworkManager(
      new Endpoint(`entity/detail/${entitiesDetailData.id}/`, "GET")
    );

    const resp = await instance.httpRequest();
    if (resp.success) {
      let temp = resp.data;
      setOverViewData(temp);
    }
  };

  const loadInvestmentDetails = () => {
    switch (selectedIndex) {
      case 0:
        return (
          <>
            <Grid container>
              <Overview data={overViewData} handleClick={handleClick} />
            </Grid>
          </>
        );
      case 1:
        return <Investments data={overViewData} handleClick={handleClick} />;
      case 2:
        return (
          <Properties
            data={overViewData?.investment}
            handleClick={handleClick}
          />
        );
      default:
        return;
    }
  };

  useEffect(() => {
    fetchEntitiesDetails();
  }, []);
  const classes = useStyles();

  return (
    <>
      <HeaderComponent
        backBtn={true}
        heading={skeleton(overViewData?.name)}
        description={skeleton(overViewData?.base_id)}
        investment_Status={skeleton(overViewData?.status)}
        managedBy={skeleton(overViewData?.managed_by)}
        isEditInvestorBtn={true}
        ExtraButtons={<ExportPDFButton />}
        onEditInvestmentBtnClick={onEditInvestmentBtnClick}
      />

      <>
        <Grid
          container
          className={classes.cardsContainer}
          spacing={2}
          direction="row">
          <EntityCard
            Title="Total Invested Amount"
            count={skeleton(
              `$${numFormatter(overViewData?.stats?.total_raise)}` || "$0"
            )}
            color="green"
            icon={totalInvestedDollar}
          />
          <EntityCard
            Title="Total Investments"
            count={skeleton(overViewData?.stats?.total_investments)}
            color="green"
            icon={totalInvestmentDollar}
            link="/u/investments"
          />
          <EntityCard
            Title="Total Distributions Received"
            count={
              skeleton(
                `$${numFormatter(overViewData?.stats?.total_distribution)}`
              ) || "$0"
            }
            color="#5E5499"
            icon={distribtutionAmount}
          />
          <EntityCard
            Title="Total Distributions"
            count={skeleton(overViewData?.stats?.distribution_count)}
            color="#5E5499"
            icon={totalDistribution}
            link="/u/distributions"
          />
        </Grid>

        {/* ToggleTabs */}
        <div
          style={{
            padding: "20px 0px"
          }}>
          <div className={styles.toggleresponiveCard}>
            <Grid
              container
              style={{
                backgroundColor: "#FAFAFA",
                height: 56,
                borderRadius: 10
              }}
              className={styles.togglecardGrid}>
              <ToggleSwitch
                setSelectedIndex={setSelectedIndex}
                selectedIndex={selectedIndex}
                list={ToggleList}
                xs={3}
                lg={2}
                md={3}
                sm={3}
              />
            </Grid>
          </div>
        </div>

        {/*Details*/}
        <Grid container>
          <Grid item xs={12}>
            {loadInvestmentDetails()}
          </Grid>
        </Grid>

        <Box height={50} />
      </>
    </>
  );
};

export default InvestorPage;
