import React, { useEffect, useState } from "react";
import styles from "../ContactsTable.module.css";

import {
  Menu,
  Button,
  MenuItem,
  Grid,
  Typography,
  makeStyles
} from "@material-ui/core";
import SearchInput from "components/Search";
import { useHistory } from "react-router-dom";
import Toast from "components/toast";
import { ContactsService } from "network/contactsService";
import { AppDispatcher } from "../../../../../redux/index";
import CloseIcon from "../../../../../assets/icons/cross.svg";
import CSV from "../../../../../assets/icons/NewCardSvg/CSV.svg";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import FWDEmail from "../../../../../assets/icons/NewCardSvg/FWDEmail.svg";

import Illustrations from "components/illustration/Illustrations";
import noContacts from "../../../../../assets/emptyState/noContacts.svg";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import InvestmentToolTipData from "pages/private/entities/detail/section/Investment/InvestmentToolTipData";
import LightTooltip from "components/LightTooltip";
import NewTable from "components/NewTable/NewTable";
import ThreeDot from "assets/icons/More.svg";
import disabledMoreIcon from "assets/icons/Disabledmore.svg";
import sendArrow from "assets/icons/sendArrow.svg";
import CustomSearchContact from "components/StyledComponents/CustomSearchContact";
import { truncate } from "helpers/Common";
import DeleteContacts from "../../deleteContacts/deleteContacts";
import ManageColumn from "components/ManageColumns";
import FrwrdAsEmail from "components/ForwarsAsEmail";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
import SendContactEmail from "../SendContactEmail";
import { useSelector } from "react-redux";

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "Pdf",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const TableHeaders = [
  {
    id: "investment_name",
    name: "Investment Name"
  },
  {
    id: "managed_by",
    name: "Managed By"
  },
  {
    id: "contact_name",
    name: "Contact Name"
  },
  {
    id: "primary_contact",
    name: "Primary Contact"
  },
  { id: "company_name", name: "Company Name" },
  {
    id: "designation",
    name: "Designation"
  },
  {
    id: "contact_number",
    name: "Contact Number"
  },
  {
    id: "email",
    name: "Email Id"
  }
];
const useStyles = makeStyles({
  associated_inv: {
    display: "flex",
    gap: 10
  },
  expandContainer: {
    maxHeight: 160,
    overflow: "auto"
  },

  hide: {
    display: "none"
  },
  clickableName: {
    marginBottom: 8,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#E9E9E9"
    }
  },
  hoverClass: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#F5F7FB"
    }
  },
  count: {
    color: "#5775E5"
  },
  managedby_container: {
    display: "flex",
    gap: 8
  },
  menuItemDelete: {
    color: "#E13838"
  }
});
function Table({ permissions }) {
  const classes = useStyles();
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [Export, setExport] = useState("exportList");

  const [manageBy, setManageBy] = useState("managedby");
  const [selectedItem, setSelectedItem] = useState({});

  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  const [checked, setChecked] = useState([
    { key: "Investment Name", value: [] },
    { key: "Primary Contacts", value: [] }
  ]);
  const [searchQuery, setSearchQuery] = useState("");
  const [status, setStatus] = useState("type");
  const openEL = Boolean(anchorEl);
  const [primary] = useState([
    {
      label: "Type",
      value: "type"
    },
    {
      label: "Primary",
      value: "True"
    },
    {
      label: "Non-Primary",
      value: "False"
    }
  ]);
  const manageByList = [
    {
      label: "Managed By",
      value: "managedby"
    },
    {
      label: "Investor",
      value: "INVESTOR"
    },
    {
      label: "Admin",
      value: "ADMIN"
    }
  ];
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageResetValue, setPageResetValue] = useState(0);
  const history = useHistory();
  const [deleteItem, setDeleteItem] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [handleRefresh, changeHandleRefresh] = useState(false);
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);
  const [THeaders, setTHeaders] = useState(TableHeaders);
  const mappingObject = {
    "Investment Name": "investment_id",
    "Primary Contacts": "filt_status"
  };
  const { openSendEmailContact } = useSelector((store) => store.app);
  const [openEmail, setOpenEmail] = useState(false);

  useEffect(() => {
    setOpenEmail(openSendEmailContact);
  }, [openSendEmailContact]);

  const handleEditContacts = () => {
    history.push("/u/contacts/editcontacts");
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  //fetching Contact data and apply filters

  const getContactsData = async (pageNum, isAppend = false) => {
    setLoading(true);
    let url = `?filt_status=${status}`;
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (manageBy !== "managedby") {
      url += `${url.length ? "&" : "?"}managed_by=${manageBy}`;
    }
    if (status !== "type") {
      url += `${url.length ? "&" : "?"}primary=${status}`;
    }
    if (selectedItem?.name != "Investment" && selectedItem?.name != undefined) {
      url += `${url.length ? "&" : "?"}investment_id=${selectedItem?.id}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    url += `&page=${pageNum}&page_size=${pageSize}`;
    const response = await ContactsService.getContacts([url]);
    if (response.success) {
      if (isAppend) {
        setData([...data, ...response.data.results]);
      } else {
        setData(response.data.results);
      }
      setCount(response.data.count);
    }
    setLoading(false);
  };

  //chpis functionality

  const ChipsComponent = () => {
    let newArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        newArray.push(newObj);
      });
    });

    return (
      <div className={styles.chipsCtr}>
        {newArray.length > 0
          ? newArray.map((item, index) => {
              return (
                <div key={index} className={styles.chips}>
                  <div className={styles.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
  };

  const handleClick = (event, item, isprimary) => {
    setAnchorEl(event.currentTarget);
    AppDispatcher.setIsPrimary(isprimary);
    AppDispatcher.setContactsId(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getInvestmentNames = async (searchText = "") => {
    let params = "";
    params += `?query={id,name,managed_by}`;

    if (searchText) {
      params += `&search=${searchText}`;
    }
    // params += `&page=${page}`;
    const response = await ContactsService.getInvestmentData([`${params}`]);

    if (response.success) {
      return {
        data: [{ name: "Investment", id: "123" }, ...response.data.results],
        maxPage: 0
      };
    }
    return { data: [], maxPage: 1 };
  };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getContactsData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const onHandleChange = (data) => {
    setSelectedItem(data);
  };

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getContactsData(1);
  }, [
    status,
    searchQuery,
    checked,
    pageSize,
    manageBy,
    handleRefresh,
    selectedItem
  ]);

  useEffect(() => {
    getInvestmentNames();
  });

  const handleDeleteClick = (item) => {
    setDeleteItem(item);
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
    setAnchorEl(null);
    changeHandleRefresh(!handleRefresh);
  };

  const handleCellClick = (name, id) => {
    AppDispatcher.setInvestmentDetailID(id);
    history.push("/u/investments/detail");
  };

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = `?filt_status=${status}`;

    if (emailData) {
      if (preview === "preview") {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `&${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    setLoading(true);
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (manageBy !== "managedby") {
      url += `${url.length ? "&" : "?"}managed_by=${manageBy}`;
    }
    if (status !== "type") {
      url += `${url.length ? "&" : "?"}primary=${status}`;
    }
    if (selectedItem?.name != "Investment" && selectedItem?.name != undefined) {
      url += `${url.length ? "&" : "?"}investment_id=${selectedItem?.id}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    url += `&page=${page}&page_size=${pageSize}`;

    setExport("exportList");
    const response = await ContactsService.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          // link.target = "_blank";
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  const sendEmail = (id) => {
    AppDispatcher.sendEmailContact(true);
    AppDispatcher.sendEmailIdContact(id);
  };

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  const Tabledatalist = data.map((item) => {
    return [
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By: ", item?.investment?.owner || "-"]]}
          />
        }
        arrow
        key="investment_name">
        <div
          className={[
            `${styles.text} ${styles.clickable} ${styles.onHover}`,
            classes.hoverClass
          ]}
          // onClick={() => handleCellClick("investment_id", item?.id)}
        >
          <div
            onClick={() =>
              handleCellClick("investment_id", item?.investment?.id)
            }>
            {truncate(item?.investment?.name, 16) || "-"}
          </div>
        </div>
      </LightTooltip>,

      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By: ", item?.investment?.owner || "-"]]}
          />
        }
        arrow
        key="managed_by">
        <div className={`${styles.text} `}>
          <div className={classes.managedby_container}>
            <Typography display="inline">
              {item?.investment?.managed_by === "ADMIN" ? "Admin" : "Investor"}
            </Typography>
          </div>
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By: ", item?.investment?.owner || "-"]]}
          />
        }
        arrow
        key="contact_name">
        <div className={`${styles.text}  ${styles.onHover}`}>
          {truncate(item?.first_name, 16) || "-"}{" "}
          {truncate(item?.last_name, 16)}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By: ", item?.investment?.owner || "-"]]}
          />
        }
        arrow
        key="primary_contact">
        <div className={`${styles.text} ${styles.clickable} ${styles.onHover}`}>
          {item?.is_primary == true ? "Yes" : "No"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By: ", item?.investment?.owner || "-"]]}
          />
        }
        arrow
        key="company_name">
        <div className={`${styles.text} }`}>
          {truncate(item?.company_name, 16)}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By: ", item?.investment?.owner || "-"]]}
          />
        }
        arrow
        key="designation">
        <div className={`${styles.displayFlex} `}>
          <div className={styles.text}>{truncate(item?.designation, 16)}</div>
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By: ", item?.investment?.owner || "-"]]}
          />
        }
        arrow
        key="contact_number">
        <div className={`${styles.text} ${styles.clickable} ${styles.onHover}`}>
          {item?.country_code}
          {"-"}
          {item?.phone_number || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By: ", item?.investment?.owner || "-"]]}
          />
        }
        arrow
        key="email">
        <div className={`${styles.text} `}>{item?.email}</div>
      </LightTooltip>,

      (permissions?.is_update || permissions?.is_delete) && (
        <div
          key="Action"
          style={{ display: "flex", justifyContent: "space-evenly" }}
          className={styles.text}>
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            onClick={() => sendEmail(item?.email)}>
            <img src={sendArrow} alt="send" width={24} height={24} />
          </Button>
          {item?.investment?.managed_by === "INVESTOR" ? (
            <Button>
              <img
                src={disabledMoreIcon}
                alt="three_dot_menu"
                width={24}
                height={24}
              />
            </Button>
          ) : (
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={openEL ? "true" : undefined}
              onClick={(e) => handleClick(e, item)}>
              <img src={ThreeDot} alt="three_dot_menu" width={24} height={24} />
            </Button>
          )}
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openEL}
            onClose={handleClose}
            PaperProps={{
              style: {
                marginTop: 40,
                boxShadow: "2px 2px 4px #E9E9E9"
              }
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}>
            {permissions?.is_update ? (
              <MenuItem className={styles.menuItem}>
                <div onClick={() => handleEditContacts()}>Edit</div>
              </MenuItem>
            ) : null}

            {permissions?.is_delete ? (
              <MenuItem
                style={{ color: "#E13838" }}
                className={styles.menuItemDelete}
                onClick={() => handleDeleteClick(item)}>
                Delete
              </MenuItem>
            ) : null}
          </Menu>
        </div>
      )
    ];
  });

  return (
    <>
      {openDelete ? (
        <DeleteContacts
          item={deleteItem}
          open={openDelete}
          handleClose={handleCloseDelete}
          getPropertyList={getContactsData}
          setAnchorEl={setAnchorEl}
        />
      ) : null}
      {openEmail && <SendContactEmail />}
      <FrwrdAsEmail
        AllHeaders={TableHeaders}
        FrdasEmail={FrdasEmail}
        setFrdasEmail={setFrdasEmail}
        open={frdEmailOpen}
        setOpen={setFrdasEmailOpen}
        clickOnShare={(payload) => {
          getExport_PDF_CSV_Link(payload);
        }}
        clickOnPreview={(payload) => {
          getExport_PDF_CSV_Link(payload, "preview");
        }}
      />

      <div className={styles.tableCtrOuter}>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <TableTopFilter
            tabletViewCSV={
              <div className={styles.clickable}>
                <TabletVIewSelectMenu
                  image={
                    <img
                      src={CSV}
                      alt="doc"
                      layout="fixed"
                      height={48}
                      width={48}
                    />
                  }
                  menuItems={ExportList}
                  clickMenuItem={(obj, close) => {
                    setExport(obj?.value);
                    close();
                  }}
                />
              </div>
            }
            tabletViewFWDEmail={
              <div className={styles.clickable}>
                <TabletVIewSelectMenu
                  image={
                    <img
                      src={FWDEmail}
                      alt="doc"
                      layout="fixed"
                      height={48}
                      width={48}
                    />
                  }
                  menuItems={frdasEamilList}
                  clickMenuItem={(obj, close) => {
                    setFrdasEmail(obj?.value);
                    close();
                  }}
                />
              </div>
            }
            filterSection1={[
              <SearchInput setQuery={setQuery} key={1} />,
              <CustomSelectFilter
                key="2"
                border={true}
                placeholder="Status"
                label="Status"
                items={primary}
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                style={{ width: "138px" }}
              />,
              <CustomSelectFilter
                key="3"
                border={true}
                placeholder="Managed By"
                items={manageByList}
                value={manageBy}
                onChange={(e) => setManageBy(e.target.value)}
              />,
              <CustomSearchContact
                key="4"
                getData={getInvestmentNames}
                onChange={(data) => onHandleChange(data)}
                selectedItem={selectedItem}
                showSubheading={true}
              />
            ]}
            filterSection2={[
              <ManageColumn
                AllHeaders={TableHeaders}
                setTableHeaders={setTHeaders}
                key={1}
              />,
              <CustomSelectFilter
                border={true}
                key="2"
                placeholder="Forward as Email"
                items={frdasEamilList}
                value={FrdasEmail}
                onChange={(e) => setFrdasEmail(e.target.value)}
              />,
              <CustomSelectFilter
                key="3"
                border={true}
                placeholder="Export"
                items={ExportList}
                value={Export}
                onChange={(e) => setExport(e.target.value)}
              />
            ]}
          />
        </Grid>
        <div className={styles.tableContainer}>
          <NewTable
            loading={loading}
            tableData={Tabledatalist}
            paginationLabel="Total Investments Per Page"
            ChipsComponent={ChipsComponent}
            pageSize={pageSize}
            changePage={changePage}
            changePageSize={changePageSize}
            count={count}
            pageResetValue={pageResetValue}
            labelRowsPerPage="Total No Of Contact Per Page"
            Header={[
              ...THeaders,
              (permissions?.is_update || permissions?.is_delete) && {
                id: "Action",
                name: ""
              }
            ]}
            Illustration={
              <Illustrations
                src={noContacts}
                heading="No Contact Found"
                subHeading="Add Contact Data To View"
              />
            }
          />
        </div>
      </div>
    </>
  );
}
export default Table;
