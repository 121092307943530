import menuItems from "layout/menuItems";


export const getScreenPermissions = (userPermissions = [], module_name, user) => {
    const module = userPermissions.find((permission) => (
        permission.module_name === module_name
    ))
    if (user?.role === "ADMIN") {
        return {
            is_create: true,
            is_delete: true,
            is_update: true,
            is_retrieve: true,
        }
    }
    return {
        is_create: false,
        is_delete: false,
        is_update: false,
        is_retrieve: false,
        ...(module?.operations ? module.operations : {})
    }
}

export const getPageWithPermission = (userPermissions = []) => {
    let menuItem = menuItems.find((menu) => {
        const module = userPermissions.find((permission) => (
            permission.module_name === menu.module_name
        ))
        return module?.operations?.is_retrieve
    })
    return menuItem?.link;
}