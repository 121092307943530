import React, { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  makeStyles,
  Menu,
  MenuItem
} from "@material-ui/core";
import SearchInput from "components/Search";
import ExpandIcon from "../../assets/icons/expandIcon.svg";
import CollapseIcon from "../../assets/icons/collapseIcon.svg";

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: "13px 5px 13px 20px",
    borderRadius: "8px",
    // border: "1px solid #BBBBBB",
    cursor: "pointer",
    fontFamily: "Inter semibold",
    fontSize: 14,
    fontWeight: 600
  },
  mainMenu: {
    width: 353,
    maxHeight: 257,
    boxSizing: "border-box",
    border: "1px solid #EBEFFF",
    borderRadius: 16,
    backgroundColor: "#FFFFFF"
  },
  searchCtr: {
    width: "100%",
    padding: "20px 20px 0",
    "& div:first-child": {
      width: "100% !important"
    }
  },
  expand: {
    marginLeft: "10px"
  },
  listContainer: {
    width: "100%",
    marginTop: 5,
    maxHeight: 160,
    boxSizing: "border-box",
    overflowY: "scroll"
  },
  itemContainer: {
    padding: "5px 15px 2px 20px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: "#F5F7FB"
    }
  },
  itemText: {
    fontFamily: "Inter semibold",
    fontSize: 14,
    // fontWeight: 600,
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  loaderCtr: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px 0"
  },
  title: {
    width: "90%"
  },
  icon: {
    height: "6.48px",
    width: "10.61px"
  }
}));

const CustomSearchSelect = ({
  getData = () => {
    [];
  },
  selectedItem = "",
  onChange = () => {},
  showSubheading = false
}) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [maxPage, setMaxPage] = useState(1);
  const [query, setQuery] = useState("");
  const isLoadingRef = useRef(false);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsExpanded(!isExpanded);
  };

  const handleSelect = (item) => {
    onChange(item);
    handleClose();
  };

  const loadMore = async () => {
    isLoadingRef.current = true;
    const resp = await getData(page + 1, query);
    setMaxPage(resp.maxPage || 1);
    setPage((prev) => prev + 1);
    setData((prev) => [...prev, ...resp.data]);
    isLoadingRef.current = false;
  };

  const handleScroll = (e) => {
    if (isLoadingRef.current) {
      return;
    }
    if (
      page < maxPage &&
      e.target.scrollHeight - e.target.scrollTop - 25 <= e.target.clientHeight
    ) {
      loadMore();
    }
  };

  useEffect(async () => {
    setPage(1);
    const resp = await getData(1, query);
    setMaxPage(resp.maxPage);
    setData(resp.data);
  }, [query]);

  return (
    <>
      <div
        style={
          showSubheading
            ? {
                border: "1px solid #EBEFFF"
              }
            : {
                border: "1px solid #BBBBBB",
                fontFamily: "Inter SemiBold"
              }
        }
        className={classes.container}
        onClick={handleClick}>
        <span className={classes.title}>
          {" "}
          {selectedItem.name
            ? selectedItem.name
            : showSubheading && "Investment"}
        </span>
        <div className={classes.expand}>
          <img
            src={isExpanded ? CollapseIcon : ExpandIcon}
            alt="Expand/Collapse"
            className={classes.icon}
          />
        </div>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        keepMounted
        getContentAnchorEl={null}
        open={open}
        onClose={handleClose}>
        <div className={classes.mainMenu}>
          <div className={classes.searchCtr}>
            <SearchInput setQuery={setQuery} width="275px" />
          </div>
          <div className={classes.listContainer} onScroll={handleScroll}>
            {data.map((item) => (
              <MenuItem
                key={item?.id}
                style={{ padding: 0 }}
                onClick={() => handleSelect(item)}>
                <div className={classes.itemContainer}>
                  <div
                    style={
                      showSubheading
                        ? {
                            fontWeight: 400
                          }
                        : {
                            fontWeight: 600
                          }
                    }
                    className={classes.itemText}>
                    {item.name}
                  </div>
                </div>
              </MenuItem>
            ))}
            {page < maxPage ? (
              <div className={classes.loaderCtr}>
                <CircularProgress
                  variant="indeterminate"
                  disableShrink
                  classes={{
                    circle: classes.circle
                  }}
                  size={30}
                  thickness={4}
                />
              </div>
            ) : null}
          </div>
        </div>
      </Menu>
    </>
  );
};

export default CustomSearchSelect;
