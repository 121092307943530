import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  makeStyles
} from "@material-ui/core";
import { Form, Formik } from "formik";
import styles from "../../../../../../sponsors/editSponsor/EditSponsor.module.scss";
import Toast from "components/toast";
import blueCloseIcon from "assets/icons/NewCardSvg/blueCloseIcon.svg";
import "react-phone-input-2/lib/style.css";
import SponsorEntityForm from "./Components/SponsorEntityForm";
import {
  validationSchemaAddEntitySponsorExsisting,
  validationSchemaAddEntitySponsorNotExsisting
} from "helpers/validationSchema";
import { useSelector } from "react-redux";
import { EntityService } from "network/entityService";

const useStyles = makeStyles(() => ({
  root: {
    "&  .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 1242,
      height: "fit-content"
    },
    "& .MuiDialogContent-root:first-child": {
      paddingBottom: "200px"
    }
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 48.5,
    boxSizing: "border-box"
  },
  MaterialForm: {},
  form: {},
  buttonContainer: {
    display: "flex",
    gap: "10px",
    marginBottom: 40,
    marginTop: 60
  },
  heading: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "Inter Bold"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0
  }
}));

const SponsorEntityDilogBox = ({
  sponsorEntityDilogBox,
  setSponsorEntityDilogBox,
  setShowAddMore,
  formik,
  SettaxIDsearch
}) => {
  const classes = useStyles();

  const { user } = useSelector((store) => store.app);

  const [clickedOnAddbtn, setClickedOnAddbtn] = useState(false);
  const [existingEmailSelected, setExistingEmailSelected] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setSponsorEntityDilogBox(false);
    setShowAddMore("");
  };

  const handleSubmit1 = async (values) => {
    setLoading(true);
    const payload = {
      tax_id: values.taxId,
      entity_type: "SPONSOR",
      ...(existingEmailSelected && { entity_type_id: values.entityTypeId.id }),
      ...(!existingEmailSelected && {
        sponsors: {
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
          company_name: values.company_name,
          country_code: "+" + values.countryCode.replaceAll("+", ""),
          phone_number: values.phone,
          created_by: user.id
        }
      }),
      address: values.mailingAddress,
      name: values.entityName,
      created_by: user.id,
      admin_sponsor_validate: true
    };

    let res = await EntityService.addEntity(payload);
    if (res.success) {
      if (res?.data?.data) {
        Toast.showErrorToast(res.data.data);
      } else {
        Toast.showSuccessToast(res.data.message);
        SettaxIDsearch("");
        let instance = res.data.instance;
        formik.setFieldValue(`Sponsor.id[0]`, instance.entity_type_id);
        formik.setFieldValue(`Sponsor.master_sponsor[0].sponsor`, instance?.id);
        formik.setFieldValue(`Sponsor.taxId`, instance.tax_id);
        formik.setFieldValue(`Sponsor.sponsor_entity_name`, instance.name);
        formik.setFieldValue(
          `Sponsor.mailing_address`,
          instance?.address?.[0].address
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[0].email`,
          instance?.user_email
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[0].name`,
          instance?.user_name
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[0].company_name`,
          instance?.user_data[0]?.company_name
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[0].code`,
          instance?.user_data[0]?.country_code
        );
        formik.setFieldValue(
          `Sponsor.sponsors_details[0].phone`,
          instance?.user_data[0]?.phone_number
        );
        handleClose();
      }
    } else {
      if (res.error.message[0] === "Must be a valid UUID.") {
        Toast.showErrorToast(`Email Does Not Exist, Add New Sponsor.`);
      } else {
        !existingEmailSelected
          ? Toast.showErrorToast("Add New Sponsor")
          : Toast.showErrorToast(res.error.message[0]);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={sponsorEntityDilogBox}
      style={{
        backgroundColor: "rgba(235, 239, 255, 0.6)",
        backdropFilter: "blur(26)"
      }}>
      <DialogContent>
        <div className={classes.MaterialForm}>
          <div className={classes.header}>
            <div>
              <Typography variant="h4" className={classes.heading}>
                Add Sponsor Entity
              </Typography>
            </div>
            <div className={classes.closeButton}>
              <img height="48px" src={blueCloseIcon} onClick={handleClose} />
            </div>
          </div>

          <Formik
            initialValues={{
              entityTypeId: "",
              entityName: "",
              mailingAddress: "",
              taxId: "",
              email: "",
              first_name: "",
              last_name: "",
              countryCode: "+1",
              phone: "",
              company_name: ""
            }}
            validationSchema={
              clickedOnAddbtn
                ? validationSchemaAddEntitySponsorNotExsisting
                : validationSchemaAddEntitySponsorExsisting
            }
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit1(values);
            }}>
            {(formik) => (
              <Form>
                <SponsorEntityForm
                  formik={formik}
                  classes={classes}
                  setExistingEmailSelected={setExistingEmailSelected}
                  setClickedOnAddbtn={setClickedOnAddbtn}
                />

                <div className={classes.buttonContainer}>
                  <button
                    className={styles.cancelbtn}
                    style={{ width: 174, margin: 0 }}
                    type="button"
                    onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={styles.updatebtn}
                    style={{ width: 174, margin: 0 }}
                    onClick={formik.handleSubmit}>
                    {loading ? "Loading ..." : "Add"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SponsorEntityDilogBox;
