import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead
} from "@material-ui/core";
import { CovertToMb, truncate } from "helpers/Common";
import React from "react";
import FileUploadIcon from "../../assets/icons/FileUpload.svg";
import DeleteIcon from "../../assets/icons/delete.svg";

const useStyles = makeStyles(() => ({
  doctableHeading: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  docImg: {
    height: 40,
    width: 40,
    borderRadius: 8
  },
  docText: {
    fontFamily: "Inter Regular",
    fontWeight: 400,
    fontSize: 16
  },
  docDelete: {
    marginRight: 20,
    marginTop: 10
  }
}));

function DocTable({ allDocs, deleteImage }) {
  const classes = useStyles();
  const download = (path, filename) => {
    const anchor = document.createElement("a");
    anchor.target = "_blank";
    anchor.href = path;
    anchor.download = filename;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };
  return (
    <Table>
      <TableHead>
        <TableCell className={classes.doctableHeading}>Document Name</TableCell>
        <TableCell className={classes.doctableHeading}>Size</TableCell>
        <TableCell className={classes.doctableHeading}>Attachment</TableCell>
      </TableHead>

      {allDocs?.map((item) => (
        <TableBody>
          <TableCell className={classes.docText}>
            {truncate(item?.name, 30)}
          </TableCell>
          <TableCell>{CovertToMb(item?.file_size)}</TableCell>
          <TableCell>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => download(item?.file, item?.name)}>
              <img src={FileUploadIcon} alt="doc" className={classes.docImg} />
            </div>
          </TableCell>

          {deleteImage && (
            <div
              style={{ cursor: "pointer", marginTop: 16 }}
              onClick={() => deleteImage(item.id)}>
              <img src={DeleteIcon} className={classes.docDelete} />
            </div>
          )}
        </TableBody>
      ))}
    </Table>
  );
}

export default DocTable;
