import React, { useEffect, useState } from "react";
import styles from "../subadminTable.module.css";
import DotsMenu from "../../../../../assets/icons/dotsMenu.svg";
import { Menu, Button, MenuItem, makeStyles, Grid } from "@material-ui/core";
import StyledSwitch from "components/StyledComponents/StyledSwitch";
import SearchInput from "components/Search";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import SelectWithSearch from "components/StyledComponents/SelectWithSearch";
import { useHistory } from "react-router-dom";
import { SubAdminService } from "network/subAdminService";
import { AppDispatcher } from "../../../../../redux";
import Toast from "components/toast";
import CloseIcon from "../../../../../assets/icons/cross.svg";
import Illustrations from "components/illustration/Illustrations";
import noSubAdmins from "../../../../../assets/emptyState/noSubAdmins.svg";
import NewTable from "components/NewTable/NewTable";
import ManageColumn from "components/ManageColumns";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    whiteSpace: "nowrap"
  },
  accessRole: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    whiteSpace: "nowrap",
    color: "#445EBE",
    padding: "5px 10px",
    backgroundColor: "#EBEFFF",
    borderRadius: 153,
    fontWeight: 700
  },
  headerLabelCtr: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexShrink: 0
  }
}));

const TableHeaders = [
  {
    id: "id",
    name: "ID"
  },

  {
    id: "name",
    name: "Name"
  },
  {
    id: "email",
    name: "Email"
  },
  {
    id: "phone_number",
    name: "Phone Number"
  },
  {
    id: "access_role",
    name: "Access Role"
  },

  {
    id: "status",
    name: "Status"
  }
];

const Table = ({ permissions }) => {
  const history = useHistory();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [THeaders, setTHeaders] = useState(TableHeaders);

  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(10);
  const [loading, setLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [status, setStatus] = useState("All");
  const [checked, setChecked] = useState([{ key: "Access Role", value: [] }]);
  const [page, setPage] = useState(1);
  const [pageResetValue, setPageResetValue] = useState(0);

  const mappingObject = {
    "Access Role": "access_role_list"
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    AppDispatcher.setSubAdminId(item);
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
  };

  const ChipsComponent = () => {
    let newArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        newArray.push(newObj);
      });
    });

    return (
      <div className={styles.chipsCtr}>
        {newArray.length > 0
          ? newArray.map((item, index) => {
              return (
                <div key={index} className={styles.chips}>
                  <div className={styles.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const getAccessRoleList = async (page = 1) => {
    const response = await SubAdminService.getAccessRoleList({ page });
    if (response.success) {
      return {
        data: response.data.results.map(({ id, name }) => ({ id, name })),
        maxPage: response.data.pages
      };
    } else {
      Toast.showErrorToast("Failed To Load Access Role List");
    }
    return { data: [], maxPage: 1 };
  };
  const getOptions = async () => {
    const res = await SubAdminService.dropdownOptions();
    if (res.success) {
      setStatusList(res.data.INVESTOR_STATUS);
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getSubAdminData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const handleEditSubAdmin = () => {
    history.push("/u/sub_admin/editSubAdmin");
  };

  // const columns = [
  //   {
  //     name: "id",
  //     label: "ID",
  //     options: {
  //       customHeadLabelRender: (columnMeta) => (
  //         <div className={classes.headerLabelCtr}>
  //           <div className={styles.headingText}> {columnMeta.label}</div>
  //         </div>
  //       )
  //     }
  //   },
  //   {
  //     name: "name",
  //     label: "Name",
  //     options: {
  //       customHeadLabelRender: (columnMeta) => (
  //         <div className={classes.headerLabelCtr}>
  //           <div className={styles.headingText}> {columnMeta.label}</div>
  //         </div>
  //       )
  //     }
  //   },
  //   {
  //     name: "email",
  //     label: "Email",
  //     options: {
  //       customHeadLabelRender: (columnMeta) => (
  //         <div className={classes.headerLabelCtr}>
  //           <div className={styles.headingText}> {columnMeta.label}</div>
  //         </div>
  //       )
  //     }
  //   },
  //   {
  //     name: "phone_number",
  //     label: "Phone Number",
  //     options: {
  //       customHeadLabelRender: (columnMeta) => (
  //         <div className={classes.headerLabelCtr}>
  //           <div className={styles.headingText}> {columnMeta.label}</div>
  //         </div>
  //       )
  //     }
  //   },
  //   {
  //     name: "access_role",
  //     label: "Role",
  //     options: {
  //       customHeadLabelRender: (columnMeta) => (
  //         <div className={classes.headerLabelCtr}>
  //           <div className={styles.headingText}> {columnMeta.label}</div>
  //         </div>
  //       )
  //     }
  //   },
  //   ...(permissions?.is_update
  //     ? [
  //         {
  //           name: "status",
  //           label: "Status",
  //           options: {
  //             customHeadLabelRender: (columnMeta) => (
  //               <div className={classes.headerLabelCtr}>
  //                 <div className={styles.headingText}> {columnMeta.label}</div>
  //               </div>
  //             )
  //           }
  //         }
  //       ]
  //     : []),
  //   ...(!permissions?.is_update && !permissions?.is_delete
  //     ? []
  //     : [
  //         {
  //           name: "action",
  //           options: {
  //             customHeadLabelRender: () => (
  //               <div className={classes.headerLabelCtr} style={{ width: 0 }}>
  //                 <div className={styles.headingText}> {""}</div>
  //               </div>
  //             )
  //           }
  //         }
  //       ])
  // ];

  // const options = {
  //   count: count,
  //   draggableColumns: {
  //     enabled: true
  //   },
  //   pagination: true,
  //   rowsPerPage: pageSize,
  //   rowsPerPageOptions: [10, 20, 30, 40, 50],
  //   textLabels: {
  //     pagination: {
  //       next: "Next",
  //       previous: "Previous",
  //       rowsPerPage: (
  //         <div
  //           style={{
  //             fontSize: 16,
  //             fontFamily: "Inter Regular",
  //             color: "#191919"
  //           }}>
  //           Total Sub Admins Per Page
  //         </div>
  //       ),
  //       displayRows: "of"
  //     },
  //     body: {
  //       noMatch: (
  //         <div
  //           style={{
  //             fontSize: 16,
  //             fontFamily: "Inter Regular",
  //             color: "#191919"
  //           }}>
  //           No Record Found
  //         </div>
  //       )
  //     }
  //   },
  //   filter: false,
  //   responsive: "standard",
  //   download: false,
  //   sort: false,
  //   selectableRows: false,
  //   print: false,
  //   viewColumns: true,
  //   searchOpen: false,
  //   search: false,
  //   customSearchRender: () => null,
  //   fixedHeader: false,
  //   onChangeRowsPerPage: (val) => {
  //     changePageSize(val);
  //   },
  //   onTableChange: (action, tableState) => {
  //     if (action === "changePage") {
  //       changePage(tableState.page + 1);
  //     }
  //   },
  //   page: pageResetValue
  // };

  const getSubAdminData = async (pageNum, isAppend = false) => {
    setLoading(true);
    let url = `?status=${status}`;
    if (searchQuery.length > 0) {
      url += `${url.length ? "&" : "?"}search=${searchQuery}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (url.length > 0) {
      url += `&page=${pageNum}&page_size=${pageSize}`;
    } else {
      url += `?page=${pageNum}&page_size=${pageSize}`;
    }
    const response = await SubAdminService.getSubAdmin([url]);
    if (response.success) {
      if (isAppend) {
        setData([...data, ...response.data.results]);
      } else {
        setData(response.data.results);
      }
      setCount(response.data.count);
    }
    setLoading(false);
  };

  const handleDeleteSubAdmin = () => {
    history.push("/u/sub_admin/deleteSubAdmin");
  };

  const setSelectedAccessRole = (item) => {
    let selectedItems = [...checked];
    let index = selectedItems[0].value.findIndex((el) => el === item);
    if (index > -1) {
      selectedItems[0].value.splice(index, 1);
    } else {
      selectedItems[0].value.push(item);
    }
    setChecked(selectedItems);
  };

  const handleStatusChange = async (index) => {
    setLoading(true);

    let prevdata = JSON.parse(JSON.stringify(data));
    const id = prevdata[index]?.user?.id;
    const payload = {
      id: id,
      is_active: prevdata[index]?.user?.is_active,
      is_verified: prevdata[index]?.user?.is_verified
        ? prevdata[index]?.user?.is_verified
        : false
    };
    if (prevdata[index]?.user?.status !== "Pending") {
      if (prevdata[index]?.user.status === "Active") {
        prevdata[index].user.status = "Inactive";
        payload.is_active = false;
      } else {
        prevdata[index].user.status = "Active";
        payload.is_active = true;
      }
    }
    const res = await SubAdminService.editSubAdmin(payload, id + "/");
    if (res.success) {
      setData(prevdata);
      Toast.showSuccessToast(
        prevdata[index]?.user?.is_verified
          ? "Status Updated Successfully"
          : "Invitation E-Mail Sent"
      );
    } else {
      Toast.showErrorToast("Failed To Change Status");
    }
    setLoading(false);
  };

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getSubAdminData(1);
  }, [status, checked, searchQuery, pageSize]);

  useEffect(() => {
    getOptions();
  }, []);

  const Tabledatalist =
    data &&
    data.map((item, index) => {
      return [
        <div key="id" className={classes.text}>
          {item?.user?.base_id || "-"}
        </div>,
        <div key="name" className={classes.text}>
          {`${item?.user?.first_name} ${item?.user?.last_name}` || "-"}
        </div>,
        <div key="email" className={classes.text}>
          {item?.user?.email || "-"}
        </div>,
        <div key="phone_number" className={classes.text}>
          {`${
            item?.user?.country_code?.[0] === "+"
              ? item?.user?.country_code
              : `+${item?.user?.country_code}`
          }-${item?.user?.phone_number}` || "-"}
        </div>,
        <div key="access_role">
          <span className={classes.accessRole}>
            {item?.access_role?.access_roles?.name || "-"}
          </span>
        </div>,

        (permissions?.is_retrieve || permissions?.is_update) && (
          <div key="status" style={{ width: 44 }}>
            <StyledSwitch
              checked={item?.user?.status === "Active" ? true : false}
              onChange={() =>
                permissions?.is_update ? handleStatusChange(index) : null
              }
            />
          </div>
        ),
        <div key="action">
          <Button
            id="basic-button"
            aria-controls="basic-menu"
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={(e) => handleClick(e, item?.user?.id)}>
            <img src={DotsMenu} />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                marginTop: 40,
                boxShadow: "2px 2px 4px #E9E9E9",
                minWidth: 155
              }
            }}
            MenuListProps={{
              "aria-labelledby": "basic-button"
            }}>
            {permissions?.is_update ? (
              <MenuItem
                className={styles.menuItem}
                onClick={handleEditSubAdmin}>
                Edit
              </MenuItem>
            ) : null}
            {permissions?.is_delete ? (
              <MenuItem
                className={styles.menuItemDelete}
                onClick={handleDeleteSubAdmin}>
                Delete
              </MenuItem>
            ) : null}
          </Menu>
        </div>
      ];
    });

  return (
    <>
      <Grid container>
        <Grid style={{ paddingTop: "30px" }} item xs={12}>
          <TableTopFilter
            filterSection1={[
              <SearchInput setQuery={setQuery} key="1" width="358px" />,

              <CustomSelectFilter
                radius={true}
                key={2}
                border={true}
                placeholder="Status"
                items={statusList}
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              />,
              <SelectWithSearch
                key={3}
                title="Role"
                getData={getAccessRoleList}
                selectedItems={checked && checked[0]?.value}
                onChange={setSelectedAccessRole}
              />

              // ...........................................................
            ]}
            filterSection2={[
              <ManageColumn
                AllHeaders={TableHeaders}
                setTableHeaders={setTHeaders}
                key={0}
              />
            ]}
          />
        </Grid>
        {/* <div className={styles.searhCtr}>
          <div className={styles.leftCtr}>
            <SearchInput setQuery={setQuery} width="358px" />

            <CustomSelectFilter
              border={true}
              placeholder="Status"
              items={statusList}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            />
            <SelectWithSearch
              title="Role"
              getData={getAccessRoleList}
              selectedItems={checked && checked[0]?.value}
              onChange={setSelectedAccessRole}
            />
          </div>

          {[
            <ManageColumn
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />
          ]}
        </div> */}
        <div className={styles.tableContainer}>
          <ChipsComponent />
          <NewTable
            loading={loading}
            Header={[
              ...THeaders,
              (permissions?.is_update || permissions?.is_delete) && {
                id: "action",
                name: "Action"
              }
            ]}
            tableData={Tabledatalist}
            pageSize={pageSize}
            changePage={changePage}
            changePageSize={changePageSize}
            count={count}
            pageResetValue={pageResetValue}
            labelRowsPerPage="Total No Of Investors Per Page"
            Illustration={
              <Illustrations
                src={noSubAdmins}
                heading="No Investors"
                subHeading="There are no Investors added yet."
              />
            }
          />
        </div>
      </Grid>
    </>
  );
};

export default Table;
