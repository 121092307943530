import { API, NetworkManager } from "./core";

export class AddInvestmentInvestorService {
  static async getInvestors() {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_INVESTOR.GET_INVESTORS
    );
    return await instance.httpRequest();
  }

  static async getEntities(payload) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_INVESTOR.GET_ENTITIES,
      {},
      payload
    );
    return await instance.httpRequest();
  }

  static async addInvestors(payload, id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_INVESTOR.ADD_INVESTORS,
      payload,
      [`${id}/`]
    );
    return await instance.httpRequest();
  }

  static async deleteInvestor(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_INVESTOR.DELETE_INVESTOR,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }

  static async deleteInvestorK1(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_INVESTOR.DELETE_INVESTOR_K1,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }

  static async getDocCategories(params) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT.GET_DOC_CATEGORIES,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
