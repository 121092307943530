import React, { useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Select, withStyles } from "@material-ui/core";

const StyledSelect = withStyles({
  root: {
    fontFamily: "Inter Regular !important",
    fontWeight: "400px !important",
    fontSize: 14,
    height: 46,
    width: "100%",
    border: "1px solid #EBEFFF !important",
    borderRadius: "16px !important",
    padding: "0 19px !important",
    display: "flex",
    alignItems: "center",
    "& .Mui-disabled": {
      backgroundColor: "#E9E9E9",
      WebkitBoxShadow: "0 0 0 1000px #E9E9E9 inset",
      fontFamily: "Inter Regular",
      borderRadius: "16px"
    },
    "&:focus": {
      borderRadius: 16,
      backgroundColor: "#fff"
    }
  }
})(Select);

const StyledMenu = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#F5F7FB"
    },
    fontFamily: "Inter Regular",
    fontSize: 16,
    width: "100%",
    color: "#191919"
  },
  selected: {
    backgroundColor: "#F5F7FB !important"
  }
})(MenuItem);

const CustomSelectFilter = ({
  id,
  // radius = false,
  placeholder,
  items = [],
  value,
  onChange,
  // fullWidth,
  // style,
  disabled,
  error,
  touched,
  defaultValue = ""
}) => {
  useEffect(() => {}, [value, defaultValue]);

  return (
    <>
      <StyledSelect
        labelId={id || "simple-select"}
        placeholder={placeholder}
        fullWidth
        disabled={disabled}
        defaultValue={items.find((e) => e.value == defaultValue)?.value}
        disableUnderline
        // style={{
        //   fontFamily: "Inter SemiBold",
        //   fontWeight: 600,
        //   minwidth: fullWidth ? "100%" : 90,
        //   ...style
        // }}
        value={value}
        onChange={(e) => onChange && onChange(e)}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left"
          },
          getContentAnchorEl: null
        }}>
        {items.map((item, index) => (
          <StyledMenu value={item.value} key={index.toString()}>
            <div style={{ minWidth: 65, marginRight: "8px" }}>{item.label}</div>
          </StyledMenu>
        ))}
        {items.length === 0 && (
          <StyledMenu disabled>No options available</StyledMenu>
        )}
      </StyledSelect>
      {error && touched && (
        <div style={{ color: "#E13838", fontSize: "0.75rem", marginTop: 3 }}>
          {error}
        </div>
      )}
    </>
  );
};
export default CustomSelectFilter;
