import * as React from "react";
import { useState } from "react";
import { Button, Dialog, DialogContent, Grid } from "@material-ui/core";
import { Typography, makeStyles, MenuItem, Select } from "@material-ui/core";
import DotsMenu from "../../../../assets/icons/dotsMenu.svg";
// import styles from "../../investors/ChangeStatus/changeStatus.module.scss";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Toast from "../../../../components/toast";
import Loader from "../../../../components/Loader/AppLoader";
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import { AddInvestmentPreviewService } from "network/addInvestmentPreviewService";
import { Form, Formik } from "formik";
import Calender from "components/calender";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import { ValidationSchemaInvestmentChangeSatatus } from "helpers/validationSchema";
import { formatDate } from "helpers";

const useHeaderStyle = makeStyles(() => ({
  root: {
    backgroundColor: "rgba(235, 239, 255, 0.6)",
    backdropFilter: "blur(26)",
    "& .MuiDialogContent-root": {
      width: "458px",
      padding: "20px 50px"
    }
  },
  editProfileText: {
    fontFamily: "Inter Bold",
    fontSize: 28
  },
  subheading: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  heading: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    marginBottom: 10,
    fontWeight: 400,
    color: "#616161"
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: 40,
    width: "358px",
    justifyContent: "space-between"
  },
  btnPrimary: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    backgroundColor: "#FFFFFF",
    color: "#445EBE",
    height: 50,
    width: 174,
    border: "1px solid #445EBE",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  btnSecondary: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    border: "1px solid #445EBE",
    background:
      "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)",
    color: "#FFF",
    height: 50,
    width: 174,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  }
}));

const InvestmentChangeStatus = (setOpenModal) => {
  const [open, setOpen] = useState(true);
  const [status, setStatus] = useState([]);
  const history = useHistory();
  const [currentStatus, setCurrentStatus] = useState("-");
  const [initValues, setInitValues] = useState({
    name: "",
    date: "",
    status: "-",
    dateOfSale: "",
    priceOfsale: ""
  });

  const [loading, setLoading] = useState(false);

  const handleClickOpen = () => {
    setOpen(setOpenModal);
  };

  const { investmentID } = useSelector((store) => store.app);

  const formatdate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("-");
  };

  const fetchUserDetail = async () => {
    const resp = await AddInvestmentPreviewService.getInvestmentDetails([
      investmentID + "?fields=name,created_at,status"
    ]);
    if (resp.success) {
      let temp = resp.data;
      let tempData = { ...initValues };
      tempData["name"] = temp.name;
      tempData["date"] = temp.created_at;
      tempData["status"] = temp.investment.status;
      setInitValues(tempData);
      setCurrentStatus(temp.investment.status);
      switch (temp.investment.status.toLowerCase()) {
        case "active":
          setStatus({ label: "Sold", value: false });
          break;
        case "sold":
          setStatus({ label: "Active", value: true });
          break;
        default:
          history.push("/u/investments");
      }
    } else {
      Toast.showErrorToast("Failed To Load Investment Data");
    }
  };

  React.useEffect(() => {
    fetchUserDetail();
  }, []);

  const classes = useHeaderStyle();
  const handleClose = () => {
    setOpen(false);
    history.push("/u/investments");
  };

  const handleStatusChange = (e) => {
    let tempData = { ...initValues };
    tempData["status"] = e.target.value;
    setInitValues(tempData);
  };

  const updateStatus = async (val) => {
    const payload = {
      ...(!initValues.status
        ? { is_sold: !initValues.status }
        : { is_active: initValues.status }),
      ...(!initValues.status && { sold_date: formatDate(val.dateOfSale) }),
      ...(!initValues.status &&
        val.priceOfsale && { sold_price: val.priceOfsale })
    };
    setLoading(true);
    const resp = await AddInvestmentPreviewService.setStatus(
      payload,
      investmentID
    );
    if (resp.success) {
      setOpen(false);
      Toast.showSuccessToast("Status Updated Successfully");
      history.push("/u/investments");
    } else {
      Toast.showErrorToast("Failed To Update Status");
    }
    setLoading(false);
  };

  const checkSold = () => {
    return !initValues.status;
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        <img src={DotsMenu} style={{ height: 20, width: 20 }} />
      </Button>
      <Dialog open={open} onClose={handleClose} className={classes.root}>
        {loading ? (
          <Loader />
        ) : (
          <DialogContent>
            <Formik
              initialValues={initValues}
              {...(checkSold() && {
                validationSchema: ValidationSchemaInvestmentChangeSatatus
              })}
              enableReinitialize={true}
              onSubmit={(val) => updateStatus(val)}>
              {(formik) => {
                return (
                  <>
                    <Form>
                      <Grid container justifyContent="center">
                        <Grid container justifyContent="space-between">
                          <Grid item>
                            <Typography variant="h4">Change Status</Typography>
                          </Grid>
                          <Grid item>
                            <img
                              src={CloseIcon}
                              onClick={handleClose}
                              style={{ cursor: "pointer" }}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          justifyContent="space-between"
                          style={{ marginTop: 40 }}>
                          <Grid item xs={8}>
                            <Typography noWrap className={classes.heading}>
                              Investment Name
                            </Typography>
                            <Typography
                              noWrap
                              variant="h5">{`${initValues.name}`}</Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography className={classes.heading}>
                              Creation Date
                            </Typography>
                            <Typography variant="h5">
                              {initValues.date !== ""
                                ? formatdate(initValues.date)
                                : "-"}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 20 }}>
                          <Grid item>
                            <Typography className={classes.heading}>
                              Current Status
                            </Typography>
                            <Typography
                              variant="h5"
                              style={{
                                minHeight: 28,
                                backgroundColor:
                                  currentStatus == "Active"
                                    ? "#EDFFEF"
                                    : "#FCEBEB ",
                                borderRadius: 153,
                                fontFamily: "Inter Bold",
                                fontSize: 14,
                                paddingLeft: 14,
                                paddingRight: 14,
                                paddingTop: 4,
                                textAlign: "center",
                                color:
                                  currentStatus == "Active"
                                    ? "#50E138"
                                    : "#E13838",
                                fontWeight: 700
                              }}>
                              {currentStatus}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          direction="column"
                          style={{ marginTop: 40 }}>
                          <Typography className={classes.heading}>
                            Change Status To
                          </Typography>
                          <Select
                            id="dropdown"
                            value={initValues.status}
                            onChange={(e) => handleStatusChange(e)}
                            disableUnderline={true}
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left"
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left"
                              },
                              getContentAnchorEl: null
                            }}
                            style={{
                              fontFamily: "Inter Regular",
                              fontWeight: 600,
                              fontSize: 16,
                              height: 48,
                              border: "1px solid #BBBBBB",
                              borderRadius: 8,
                              paddingLeft: 10,
                              paddingRight: 10
                            }}>
                            {status ? (
                              <MenuItem
                                onMouseEnter={(e) =>
                                  (e.target.style.backgroundColor = "#F5F7FB")
                                }
                                onMouseLeave={(e) =>
                                  (e.target.style.backgroundColor = "#ffffff")
                                }
                                value={status.value}
                                style={{
                                  fontSize: 14,
                                  fontFamily: "Inter Regular",
                                  marginTop: 5
                                }}>
                                {status.label}
                              </MenuItem>
                            ) : null}
                          </Select>
                        </Grid>

                        {checkSold() && (
                          <>
                            <Grid item xs={12} style={{ marginTop: 40 }}>
                              <Typography className={classes.heading}>
                                Date Of Sale
                              </Typography>
                              <Calender
                                name="dateOfSale"
                                setDate={(date) =>
                                  formik.setFieldValue("dateOfSale", date)
                                }
                                defaultDate={formik.values.dateOfSale}
                                error={
                                  formik.touched.dateOfSale &&
                                  Boolean(formik.errors.dateOfSale)
                                }
                                helperText={
                                  formik.touched.dateOfSale &&
                                  formik.errors.dateOfSale
                                }
                              />
                            </Grid>
                            <Grid item xs={12} style={{ marginTop: 40 }}>
                              <Typography className={classes.heading}>
                                Price of Sale (Optional)
                              </Typography>
                              <AppTextFieldWhite
                                id="priceOfSale"
                                name="priceOfSale"
                                variant="standard"
                                fullWidth
                                disabled={false}
                                placeholder="price Of Sale"
                                numFormat
                                value={formik.values.priceOfsale}
                                onChange={formik.handleChange("priceOfsale")}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{ disableUnderline: true }}
                                inputProps={{ maxLength: 12 }}
                              />
                            </Grid>
                          </>
                        )}

                        <Grid container justifyContent="center">
                          <div className={classes.btnContainer}>
                            <button
                              className={classes.btnPrimary}
                              type="submit"
                              onClick={handleClose}>
                              Cancel
                            </button>
                            <button
                              className={classes.btnSecondary}
                              onClick={() => {
                                formik.handleSubmit();
                              }}>
                              Update
                            </button>
                          </div>
                        </Grid>
                      </Grid>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};

export default InvestmentChangeStatus;
