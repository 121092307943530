import React, { useState } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  makeStyles,
  Grid
} from "@material-ui/core";
import { Form, Formik } from "formik";
import styles from "../../../../../../sponsors/editSponsor/EditSponsor.module.scss";
import Toast from "components/toast";
import blueCloseIcon from "assets/icons/NewCardSvg/blueCloseIcon.svg";
import "react-phone-input-2/lib/style.css";
import { validationSchemaAddEntityInvestorExsisting } from "helpers/validationSchema";
import { useSelector } from "react-redux";
import { EntityService } from "network/entityService";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";

const useStyles = makeStyles(() => ({
  root: {
    "&  .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 1242,
      height: "430px"
    }
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 48.5,
    boxSizing: "border-box"
  },
  MaterialForm: {},
  form: {},
  buttonContainer: {
    display: "flex",
    gap: "10px",
    marginBottom: 40,
    marginTop: 60
  },
  heading: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "Inter Bold"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0
  }
}));

const InvestorEntityDilogBox = ({
  inv_id,
  inv_email,
  investorEntityDilogBox,
  setInvestorEntityDilogBox,
  setShowAddMore,
  formik,
  index,
  SettaxIDsearch
}) => {
  const classes = useStyles();

  const { user } = useSelector((store) => store.app);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setInvestorEntityDilogBox(false);
    setShowAddMore("");
  };

  const handleSubmit1 = async (values) => {
    setLoading(true);
    const payload = {
      entity_type: "INVESTOR",

      tax_id: values.taxId,
      name: values.entityName,
      address: values.mailingAddress,

      entity_type_id: values.id,

      created_by: user.id,
      admin_validate: true
    };

    let res = await EntityService.addEntity(payload);
    if (res.success) {
      const data = res.data.instance;
      if (res?.data?.data) {
        Toast.showErrorToast(res.data.data);
      } else {
        SettaxIDsearch("");
        formik.setFieldValue(`investorSet[${index}].entity_id`, data.id);
        formik.setFieldValue(`investorSet[${index}].taxId`, data.tax_id);
        formik.setFieldValue(`investorSet[${index}].entity_name`, data?.name);
        formik.setFieldValue(
          `investorSet[${index}].mailing_address`,
          data?.address?.[0].address
        );
        Toast.showSuccessToast(res.data.message);
        handleClose();
      }
    } else {
      if (res.error.message[0] === "Must be a valid UUID.") {
        Toast.showErrorToast(`Email Does Not Exist, Add New Investor.`);
      } else {
        Toast.showErrorToast(res.error.message[0]);
      }
    }
    setLoading(false);
  };

  return (
    <Dialog
      className={classes.root}
      open={investorEntityDilogBox}
      style={{
        backgroundColor: "rgba(235, 239, 255, 0.6)",
        backdropFilter: "blur(26)"
      }}>
      <DialogContent>
        <div className={classes.MaterialForm}>
          <div className={classes.header}>
            <div>
              <Typography variant="h4" className={classes.heading}>
                Add Investor Entity
              </Typography>
            </div>
            <div className={classes.closeButton}>
              <img height="48px" src={blueCloseIcon} onClick={handleClose} />
            </div>
          </div>

          <Formik
            initialValues={{
              taxId: "",
              entityName: "",
              mailingAddress: "",
              email: inv_email,
              id: inv_id
            }}
            validationSchema={validationSchemaAddEntityInvestorExsisting}
            enableReinitialize={true}
            onSubmit={(values) => {
              handleSubmit1(values);
            }}>
            {(formik) => (
              <Form>
                <Grid container xs={12} spacing={2}>
                  <Grid item lg={4} md={6} sm={6}>
                    <label className={classes.label}>
                      Tax ID (EIN)/Entity Name
                    </label>
                    <AppTextFieldWhite
                      id="entityName"
                      placeholder="Enter TaxId/EntityName"
                      variant="standard"
                      fullWidth
                      value={formik.values.taxId}
                      onChange={formik.handleChange("taxId")}
                      error={
                        formik.touched.taxId && Boolean(formik.errors.taxId)
                      }
                      helperText={formik.touched.taxId && formik.errors.taxId}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6}>
                    <label className={classes.label}>Entity Name</label>
                    <AppTextFieldWhite
                      id="entityName"
                      placeholder="Enter Entity Name"
                      variant="standard"
                      fullWidth
                      value={formik.values.entityName}
                      onChange={formik.handleChange("entityName")}
                      error={
                        formik.touched.entityName &&
                        Boolean(formik.errors.entityName)
                      }
                      helperText={
                        formik.touched.entityName && formik.errors.entityName
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>
                  <Grid item lg={4} md={6} sm={6}>
                    <label className={classes.label}>Mailing Address</label>
                    <AppTextFieldWhite
                      id="mailingAddress"
                      placeholder="Enter Mailing Address"
                      variant="standard"
                      fullWidth
                      value={formik.values.mailingAddress}
                      onChange={formik.handleChange("mailingAddress")}
                      error={
                        formik.touched.mailingAddress &&
                        Boolean(formik.errors.mailingAddress)
                      }
                      helperText={
                        formik.touched.mailingAddress &&
                        formik.errors.mailingAddress
                      }
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                    />
                  </Grid>

                  <Grid item lg={4} md={6} sm={6}>
                    <label className={classes.label}>Investor Email</label>
                    <AppTextFieldWhite
                      id="entityName"
                      placeholder="Enter Email Id"
                      variant="standard"
                      fullWidth
                      value={formik.values.email}
                      onChange={formik.handleChange("email")}
                      error={
                        formik.touched.email && Boolean(formik.errors.email)
                      }
                      helperText={formik.touched.email && formik.errors.email}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{ disableUnderline: true }}
                      disabled
                    />
                  </Grid>
                </Grid>

                <div className={classes.buttonContainer}>
                  <button
                    className={styles.cancelbtn}
                    style={{ width: 174, margin: 0 }}
                    type="button"
                    onClick={handleClose}>
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={styles.updatebtn}
                    style={{ width: 174, margin: 0 }}
                    onClick={formik.handleSubmit}>
                    {loading ? "Loading..." : "Add"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default InvestorEntityDilogBox;
