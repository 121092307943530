import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "../../../../../components/AppTable/AppTable.module.css";
import CloseIcon from "../../../../../assets/icons/cross.svg";
import DotsMenu from "../../../../../assets/icons/dotsMenu.svg";
import {
  Menu,
  MenuItem,
  Button,
  Tooltip
  // Hidden,
  // Grid,
} from "@material-ui/core";
import DateFormatter from "../../../../../helpers/formatDate";
import { AppDispatcher } from "../../../../../redux/index";
import styles1 from "../../investments.module.css";
import filterIcon from "../../../../../assets/icons/Filter.svg";
import BluefilterIcon from "../../../../../assets/icons/blueFilter.svg";
import DotFilterIcon from "../../../../../assets/icons/DotFilter.svg";
// import blueDot from "../../../../../assets/icons/NewCardSvg/blueDot.svg";
// import orangeDot from "../../../../../assets/icons/NewCardSvg/orangeDot.svg";
import CSV from "../../../../../assets/icons/NewCardSvg/CSV.svg";
import FWDEmail from "../../../../../assets/icons/NewCardSvg/FWDEmail.svg";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Toast from "components/toast";
import { InvestmentListService } from "network/investment_ListService";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import SearchInput from "components/Search";
// import Loader from "components/Loader/AppLoader";
import { FilterStatus, truncate } from "helpers/Common";
import FrwrdAsEmail from "components/ForwarsAsEmail";

// import DropComp from "components/DropComp";
import noInvestments from "../../../../../assets/emptyState/noInvestments.svg";
import Illustrations from "components/illustration/Illustrations";
import NewTable from "components/NewTable/NewTable";
import ManageColumn from "components/ManageColumns";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import MoreFilterContainer from "components/MoreFilterContainer/MoreFilterContainer";
import LightTooltip from "components/LightTooltip";
import NewDropDown from "components/NewDropDown";
import { numFormatter } from "helpers";
import formatDate from "../../../../../helpers/formatDate";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";

const manageByList = [
  {
    label: "Managed By",
    value: "managedby"
  },
  {
    label: "Admin",
    value: "ADMIN"
  },
  {
    label: "Investor",
    value: "INVESTOR"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "Pdf",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const TableHeaders = [
  {
    id: "investment_name",
    name: "Investment Name"
  },
  {
    id: "managed_by",
    name: "Managed By"
  },
  {
    id: "sponsor_name",
    name: "Sponsor Name"
  },
  {
    id: "sponsor_entity",
    name: "Sponsor Entity"
  },
  {
    id: "investors",
    name: "Investors"
  },
  {
    id: "last_updated",
    name: "Last Updated"
  },
  {
    id: "amount_invested",
    name: "Amount Invested"
  },
  {
    id: "distribution_value",
    name: "Distribution Value"
  },
  {
    id: "status",
    name: "Status"
  }
  // {
  //   id: "sponsor",
  //   name: "Sponsor"
  // },

  // {
  //   id: "sponsor_entity_name",
  //   name: "Sponsor Entity"
  // },
  // {
  //   id: "loan_count",
  //   name: "Loan Count"
  // },
  // {
  //   id: "investment_id",
  //   name: "Investment ID"
  // },
];

function Table({ permissions }) {
  const history = useHistory();
  const [THeaders, setTHeaders] = useState(TableHeaders);
  const [manageBy, setManageBy] = useState("managedby");
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [Export, setExport] = useState("exportList");
  const [anchorEl, setAnchorEl] = useState(null);
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [showFilter, setShowFilter] = useState(false);
  const open = Boolean(anchorEl);
  const [investmentStatus, setInvestmentStatus] = useState([
    {
      label: "Status",
      value: "All"
    }
  ]);
  const [checked, setChecked] = useState([]);
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);
  const [filterState, setFilterState] = useState([]);
  const [status, setStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [statusDisabled, setStatusDisabled] = useState(false);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageResetValue, setPageResetValue] = useState(0);
  const [isFilterActive, setFilterActive] = useState(false);
  const [expandIndex, setExpandIndex] = useState(-1);
  const mappingObject = {
    "Investment Name": "id",
    "Sponsor Entity": "sponsor_entity_id",
    "Investor Name": "investor_id",
    "Sponsor Name": "sponsor_id"
  };

  const handleApply = (data) => {
    setShowFilter(false);
    setChecked([...data]);
  };

  const handleClear = () => {
    setShowFilter(false);
    setChecked([]);
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const handleChangeStatus = () => {
    AppDispatcher.setInvestmentID(id);
    history.push("/u/investments/changeStatus");
  };

  const handleDeleteInvestment = () => {
    history.push("/u/investments/deleteInvestment");
  };

  const ChipsComponent = () => {
    let newArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        newArray.push(newObj);
      });
    });

    return (
      <div className={styles1.chipsCtr}>
        {newArray.length > 0
          ? newArray.map((item, index) => {
              return (
                <div key={index} className={styles1.chips}>
                  <div className={styles1.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles1.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
  };

  const handleClick = (event, item) => {
    if (item?.status === "Draft") {
      setStatusDisabled(true);
    } else {
      setStatusDisabled(false);
    }
    setId(item?.id);
    setAnchorEl(event.currentTarget);
    AppDispatcher.setInvestmentID(item?.id);
  };

  const handleEdit = () => {
    AppDispatcher.resetAddInvestmentStep();
    AppDispatcher.setInvestmentID(id);
    history.push({ pathname: "/u/investments/addInvestment" });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setInvestmentStatus(res.data.INVESTMENT_STATUS);
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const getQueryParams = () => {
    let url = `?status=${status}`;
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    return url;
  };

  const getInvestmentData = async (pageNum, isAppend = false) => {
    setLoading(true);
    let url = getQueryParams();
    url += `&page=${pageNum}&page_size=${pageSize}`;
    if (manageBy !== "managedby") {
      url += `&created_by=${manageBy}`;
    }
    const response = await InvestmentListService.getInvestmentListService([
      url
    ]);
    if (response.success) {
      if (isAppend) {
        setData([...data, ...response.data.results]);
      } else {
        setData(response.data.results);
      }
      setCount(response.data.count);
    }
    setLoading(false);
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestmentName([
      `?query={id,name}${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results
          ?.map((item) => {
            return { id: item?.id, name: item?.name };
          })
          .filter((item) => !!item?.name),
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponserEntityName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getSponserEntity([
      `?query={id,name}&entity_type=SPONSOR${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results?.filter((item) => !!item?.name),
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getInvestorName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestors([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponserName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getSponsors([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const onHandleClick = () => {
    setShowFilter(!showFilter);
  };

  // const downloadCsv = async () => {
  //   setLoading(true);
  //   let url = getQueryParams();
  //   if (url.length > 0) {
  //     url += `&csv-download=true`;
  //   } else {
  //     url += `?csv-download=true`;
  //   }
  //   const resp = await InvestmentListService.getInvestmentListService([url]);
  //   if (resp.success) {
  //     const link = document.createElement("a");
  //     link.href = resp?.data?.url;
  //     link.setAttribute("download", `${resp?.data?.url}`);
  //     document.body.appendChild(link);
  //     link.click();
  //     link.parentNode.removeChild(link);
  //   } else {
  //     Toast.showErrorToast("Failed To Download CSV");
  //   }
  //   setLoading(false);
  // };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getInvestmentData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const handleCellItemClick = (e, type, id) => {
    e.stopPropagation();
    if (id) {
      switch (type) {
        case "sponsor":
          AppDispatcher.setSponsorDetailID(id);
          history.push("/u/sponsors/detail");
          break;
        case "investor":
          AppDispatcher.setInvestorDetailID(id);
          history.push("/u/investors/detail");
          break;
        case "investment":
          AppDispatcher.setInvestmentDetailID(id);
          history.push("/u/investments/detail");
          break;
      }
    }
  };

  const setFilterMeta = () => {
    setFilterState([
      {
        key: "Investment Name",
        value: getInvestmentName
      },
      {
        key: "Sponsor Name",
        value: getSponserName
      },
      {
        key: "Sponsor Entity",
        value: getSponserEntityName
      },
      {
        key: "Investor Name",
        value: getInvestorName
      }
    ]);
  };

  useEffect(() => {
    getOptions();
    setFilterMeta();
  }, []);

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getInvestmentData(1);
    setFilterActive(FilterStatus(checked));
  }, [status, searchQuery, checked, pageSize, count, manageBy]);

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = getQueryParams();

    if (emailData) {
      if (preview === "preview") {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `&${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    url += `&page=${page}&page_size=${pageSize}`;
    if (manageBy !== "managedby") {
      url += `&created_by=${manageBy}`;
    }

    setExport("exportList");
    const response = await InvestmentListService.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          // link.target = "_blank";
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  /******************************************************************** */
  /*********************       New Table Data           *****************/
  /*********************************************************************/

  const getTooltipData = (item) => {
    const mainDiv = {
      display: "flex",
      gap: "20px",
      padding: "12px",
      width: "fit-content"
    };
    const heading = {
      fontSize: "16px",
      fontWeight: 400,
      color: "#A0A0A0",
      minWidth: "180px"
    };
    const Value = {
      fontSize: "16px",
      fontWeight: 600,
      minWidth: "150px",
      color: "#191919"
    };
    return (
      <>
        <div style={mainDiv}>
          <h3 style={heading}>Investment ID:</h3>
          <h3 style={Value}>{item?.base_id ?? "-"}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Purchase Price:</h3>
          <h3 style={Value}>
            {item?.purchase_price
              ? `$${numFormatter(item?.purchase_price || 0)}`
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Total Asset Value:</h3>
          <h3 style={Value}>
            {item?.equity_info?.total_asset
              ? `$${numFormatter(item?.equity_info?.total_asset || 0)}`
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Outstanding Loan:</h3>
          <h3 style={Value}>
            {item?.equity_info?.outstanding
              ? `$${numFormatter(item?.equity_info?.outstanding || 0)}`
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Equity:</h3>
          <h3 style={Value}>
            {item?.equity_info?.equity_amount
              ? `$${numFormatter(item?.equity_info?.equity_amount || 0)}`
              : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Refinance:</h3>
          <h3 style={Value}>{item?.is_refinanced ? "Yes" : "No"}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Distribution Start Date:</h3>
          <h3 style={Value}>
            {item?.start_date ? formatDate(item?.start_date) : "-"}
          </h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Property Count:</h3>
          <h3 style={Value}>{item?.stats?.properties_count}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Loan Count:</h3>
          <h3 style={Value}>{item?.stats?.loan_count}</h3>
        </div>
        <div style={mainDiv}>
          <h3 style={heading}>Created By:</h3>
          <h3 style={Value}>{item?.created_by ?? "-"}</h3>
        </div>
      </>
    );
  };

  const Tabledatalist =
    data &&
    data.map((item, index) => {
      return [
        <LightTooltip key="investment_name" title={getTooltipData(item)}>
          <div
            className={`${styles.text} ${styles.hover}`}
            style={{ cursor: "pointer" }}
            onClick={(e) => handleCellItemClick(e, "investment", item?.id)}>
            {truncate(item?.name ? item?.name : "-", 20)}
          </div>
        </LightTooltip>,
        <div key="managed_by" className={styles.text}>
          {item?.managed_by}
        </div>,
        <div
          key="sponsor_name"
          className={styles.text}
          style={{ cursor: "pointer" }}>
          {item?.sponsor_data?.length ? (
            <NewDropDown
              index={index}
              item={item?.sponsor_data?.map((item) => {
                return {
                  name: item?.sponsor_detail?.name,
                  id: item?.entity?.entity_type_id
                };
              })}
              handleCellItemClick={handleCellItemClick}
              type="sponsor"
              expandIndex={expandIndex}
              setExpandIndex={setExpandIndex}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>,
        <div
          key="sponsor_entity"
          title={item?.sponsor_data?.[0]?.entity?.name}
          className={styles.text}>
          {item?.sponsor_data?.length ? (
            <>{truncate(item?.sponsor_data?.[0]?.entity?.name, 20)}</>
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>,
        <Fragment key="investors">
          {item?.investors?.length ? (
            <NewDropDown
              index={index}
              item={item?.investors?.map((item) => {
                return { name: item?.investor, id: item?.investor_id };
              })}
              handleCellItemClick={handleCellItemClick}
              type="investor"
              expandIndex={expandIndex}
              setExpandIndex={setExpandIndex}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </Fragment>,
        <div key="last_updated" className={styles.text}>
          {DateFormatter(item?.updated_at)}
        </div>,
        <div key="amount_invested" className={styles.text}>
          {item?.stats?.total_invested_amount >= 0
            ? `$${numFormatter(item?.stats?.total_invested_amount)}`
            : "-"}
        </div>,
        <div key="distribution_value" className={styles.text}>
          {item?.stats?.distribution_amount >= 0
            ? `$${numFormatter(item?.stats?.distribution_amount)}`
            : "-"}
        </div>,
        <div
          key="status"
          style={{
            minHeight: 28,
            width: 72,
            alignItems: "center",
            backgroundColor:
              item?.status === "Draft"
                ? "#EBEFFF"
                : item?.status == "Active"
                ? "#EDFFEF"
                : "#FCEBEB ",
            borderRadius: 153,
            textAlign: "center",
            fontFamily: "Inter Bold",
            fontSize: 14,
            paddingTop: 4,
            color:
              item?.status == "Active"
                ? "#50E138"
                : item?.status === "Draft"
                ? "#445EBE"
                : "#E13838",
            fontWeight: 700
          }}>
          {item?.status ? item?.status : "-"}
        </div>,

        permissions?.is_update || permissions?.is_delete ? (
          <div key="Action">
            <Button
              id={`basic-button-${index}`}
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) =>
                (permissions?.is_update || permissions?.is_delete) &&
                handleClick(e, item)
              }>
              <img src={DotsMenu} />
            </Button>
            <Menu
              id={`basic-menu-${index}`}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  marginTop: 40,
                  boxShadow: "2px 2px 4px #E9E9E9"
                }
              }}
              MenuListProps={{
                "aria-labelledby": `basic-button-${index}`
              }}>
              {permissions?.is_update && (
                <div>
                  <MenuItem
                    disabled={!permissions?.is_update}
                    onClick={handleEdit}
                    className={styles.menuItem}>
                    Edit Investment
                  </MenuItem>
                  {!statusDisabled && (
                    <MenuItem
                      onClick={handleChangeStatus}
                      className={styles.menuItem}>
                      Change Status
                    </MenuItem>
                  )}
                </div>
              )}
              {permissions?.is_delete && (
                <MenuItem
                  className={styles.menuItemDelete}
                  onClick={handleDeleteInvestment}>
                  Delete
                </MenuItem>
              )}
            </Menu>
          </div>
        ) : (
          <div key="Action"></div>
        )
      ];
    });

  /******************************************************************* */
  /******************************************************************* */
  /******************************************************************* */
  return (
    <>
      {/* {loading && <Loader />} */}

      <FrwrdAsEmail
        AllHeaders={TableHeaders}
        FrdasEmail={FrdasEmail}
        setFrdasEmail={setFrdasEmail}
        open={frdEmailOpen}
        setOpen={setFrdasEmailOpen}
        clickOnShare={(payload) => {
          getExport_PDF_CSV_Link(payload);
        }}
        clickOnPreview={(payload) => {
          getExport_PDF_CSV_Link(payload, "preview");
        }}
      />

      <div className={styles.tableCtrOuter}>
        <TableTopFilter
          tabletViewCSV={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={CSV}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={ExportList}
                clickMenuItem={(obj, close) => {
                  setExport(obj?.value);
                  close();
                }}
              />
            </div>
          }
          tabletViewFWDEmail={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={FWDEmail}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={frdasEamilList}
                clickMenuItem={(obj, close) => {
                  setFrdasEmail(obj?.value);
                  close();
                }}
              />
            </div>
          }
          filterSection1={[
            <SearchInput setQuery={setQuery} />,
            <CustomSelectFilter
              border={true}
              placeholder="Status"
              items={investmentStatus}
              onChange={(e) => setStatus(e.target.value)}
              value={status}
            />,
            <CustomSelectFilter
              key="3"
              border={true}
              placeholder="Managed By"
              items={manageByList}
              value={manageBy}
              onChange={(e) => setManageBy(e.target.value)}
            />,
            <Tooltip title="More Filters" arrow>
              <div className={styles.clickable} onClick={onHandleClick}>
                <img
                  src={
                    isFilterActive
                      ? DotFilterIcon
                      : showFilter
                      ? BluefilterIcon
                      : filterIcon
                  }
                  alt="alt"
                  layout="fixed"
                  width={45}
                  height={45}
                />
              </div>
            </Tooltip>,

            showFilter && (
              <>
                <MoreFilterContainer
                  open={showFilter}
                  checked={checked}
                  handleClose={onHandleClick}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filters={filterState}
                />
              </>
            )
          ]}
          filterSection2={[
            <ManageColumn
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Forward as Email"
              items={frdasEamilList}
              value={FrdasEmail}
              onChange={(e) => setFrdasEmail(e.target.value)}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Export"
              items={ExportList}
              value={Export}
              onChange={(e) => setExport(e.target.value)}
            />
          ]}
        />

        <ChipsComponent />

        <NewTable
          loading={loading}
          Header={[
            ...THeaders,
            (permissions?.is_update || permissions?.is_delete) && {
              id: "Action",
              name: "Action"
            }
          ]}
          tableData={Tabledatalist}
          pageSize={pageSize}
          changePage={changePage}
          changePageSize={changePageSize}
          count={count}
          pageResetValue={pageResetValue}
          labelRowsPerPage="Total No Of Investments Per Page"
          Illustration={
            <Illustrations
              src={noInvestments}
              heading="No Investments Found"
              subHeading="Add Investment Data To View"
            />
          }
        />
      </div>
    </>
  );
}

export default Table;
