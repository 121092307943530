import { CircularProgress } from "@material-ui/core";
import React from "react";
import style from "../../../manage_data.module.scss";

const VerificationProgress = () => {
  return (
    <>
      <div className={style.verifyContainer}>
        <>
          <div className="downloadMsgConatiner">
            <CircularProgress />
          </div>
          <div className={style.downloadTitleConatiner}>
            <div className={style.title}>Verifying Data</div>
            <div>This might take a few seconds</div>
          </div>
        </>
      </div>
    </>
  );
};

export default VerificationProgress;
