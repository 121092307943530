import { makeStyles } from "@material-ui/core";
import EditIcon from "assets/icons/EditIcon.svg";
import React from "react";

const useStyles = makeStyles(() => ({
  headercontainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "33px 0px"
  },
  historytext: {
    fontFamily: "Inter Regular",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "19px",
    color: "#445EBE",
    cursor: "pointer"
  },
  Heading: {
    height: "22px",
    fontFamily: "Inter Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#191919",
    display: "flex",
    alignItems: "center"
  },
  childContainer: {
    padding: "20px",
    background: "#FAFAFA",
    borderRadius: "10px"
  }
}));

export const FieldLayout = ({
  heading,
  children,
  isEditButton,
  showHistory,
  openHistoryPopUp,
  // click,
  SetClickEdit
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.headercontainer}>
        <div className={classes.Heading}>
          {heading}
          {isEditButton && (
            <div
              style={{ marginLeft: 20, cursor: "pointer" }}
              onClick={() => SetClickEdit(true)}>
              <img src={EditIcon} alt="edit" />
            </div>
          )}
        </div>
        {isEditButton && showHistory && (
          <div
            className={classes.historytext}
            onClick={() => {
              openHistoryPopUp(true);
            }}>
            View History
          </div>
        )}
      </div>
      <div className={classes.childContainer}>{children}</div>
    </>
  );
};
