import { Grid, makeStyles } from "@material-ui/core";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import Loader from "components/Loader/AppLoader";
import { FieldArray, Form, Formik } from "formik";
import { validationSchemaAddSubAdmin } from "helpers/validationSchema";
import SubAdmin from "../components/SubAdmin";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AddIcon from "assets/icons/PlusAdd.svg";
import AddBtn from "components/Buttons/addBtn";
import { SubAdminService } from "network/subAdminService";
import Toast from "components/toast";

const useStyle = makeStyles({
  MainGrid: { margin: "50px 0 0 0" },
  Gridcontainer: {
    // backgroundColor: "#FAFAFA",
    // padding: 20,
    borderRadius: 8,
    marginBottom: 20
  },
  GridItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: "20px"
  },
  addTier: {
    backgroundColor: "#FAFAFA",
    padding: 20,
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    minWidth: 160,
    marginTop: 10
  },
  btnContainer: {
    marginLeft: 20
  },
  addBtn: {
    marginLeft: 10
  },
  addMoreText: {
    fontWeight: 600,
    fontSize: 14
  }
});

const AddSubAdmin = () => {
  const classes = useStyle();
  const history = useHistory();
  const [loader, setLoading] = useState(false);
  const [initialValues] = useState({
    associatedSubAdminSet: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: null,
        country_code: "+1",
        access_role: ""
      }
    ]
  });

  const handleClose = () => {
    history.push("/u/sub_admin");
  };

  const handleFormSubmit = async (values) => {
    setLoading(true);
    const payload = values.associatedSubAdminSet.map((item) => ({
      email: item?.email,
      first_name: item?.first_name,
      last_name: item?.last_name,
      country_code: item?.country_code,
      phone_number: item?.phone_number,
      access_role: item?.access_role,
      permission: []
    }));

    let res = await SubAdminService.addSubAdmin(payload);
    if (res.success) {
      Toast.showSuccessToast("Sub-Admin Added Successfully");
      history.push("/u/sub_admin");
    } else {
      Toast.showErrorToast(res?.error?.message);
    }
    setLoading(false);
  };

  return (
    <>
      <HeaderComponent
        heading="Add Sub-Admin"
        description="Add New Sub-Admins Here"
        addInvestorBtn={false}
        backBtn
      />
      {loader ? (
        <Loader />
      ) : (
        <Grid className={classes.MainGrid}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaAddSubAdmin}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}>
            {(formik) => (
              <Form>
                <Grid container className={classes.Gridcontainer}>
                  <Grid item xs>
                    <FieldArray
                      name="associatedSubAdminSet"
                      render={(arrayHelper) => (
                        <>
                          {formik.values.associatedSubAdminSet.map(
                            (sponsors, index) => (
                              <SubAdmin
                                key={index}
                                classes={classes}
                                id={index}
                                arrayHelper={arrayHelper}
                                formik={formik}
                              />
                            )
                          )}
                          <div className={classes.addTier}>
                            <img
                              src={AddIcon}
                              alt=""
                              style={{ marginRight: 8 }}
                              onClick={() =>
                                arrayHelper.push({
                                  first_name: "",
                                  last_name: "",
                                  email: "",
                                  phone_number: null,
                                  country_code: "+1",
                                  access_role: ""
                                })
                              }
                            />
                            <div
                              onClick={() =>
                                arrayHelper.push({
                                  first_name: "",
                                  last_name: "",
                                  email: "",
                                  phone_number: null,
                                  country_code: "+1",
                                  access_role: ""
                                })
                              }
                              className={classes.addMoreText}>
                              Add Additional Sub-Admin
                            </div>
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.btnContainer}>
                  <Grid item>
                    <AddBtn
                      text="Cancel"
                      type="submit"
                      style={{
                        backgroundColor: "#FFFF",

                        color: "#445EBE",
                        borderRadius: 4,
                        border: "1px solid #445EBE",
                        fontFamily: "Inter Regular",
                        fontWeight: 600
                      }}
                      onClick={() => handleClose()}
                    />
                  </Grid>
                  <Grid className={classes.addBtn} item>
                    <AddBtn text="Add" type="submit" />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
};

export default AddSubAdmin;
