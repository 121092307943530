import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { formatDateMMDDYY, numFormatter } from "helpers";
import TimeFilter from "./components/TimeFilter";
import { handlePermission } from "../../../APN";
import style from "./dashboard.module.css";

const useStyles = makeStyles(() => ({
  filterContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center"
  }
}));
import styles from "./dashboard.module.css";
import { DashboardSerivice } from "network/dashboardService";
import { useHistory } from "react-router-dom";
import Loader from "components/Loader/AppLoader";
import { useSelector } from "react-redux";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import AddButton from "./components/addButton";
import PlatformMatrics from "./components/PlatformMatrics";
import RecentInvestor from "./components/RecentInvestor";
import RecentInvestment from "./components/RecentInvestment";
import RecentDistribution from "./components/RecentDistribution";
import { Skeleton } from "@material-ui/lab";

function Dashboard() {
  const classes = useStyles();
  const [trigger, setTrigger] = useState(true);
  const [statsData, setStatsData] = useState({});
  const [selected, setSelected] = useState({
    tenure: ""
  });
  const [investment, setInvestments] = useState([]);
  const [investors, setInvestors] = useState([]);
  const [distribution, setDistributions] = useState([]);
  const [loading, setLoading] = useState(false);
  const { userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();
  const handleSafariNotification = (token) => {
    this.setState({ apnToken: token });
  };

  const skeleton = (values) => {
    return Object.keys(statsData).length > 0
      ? values
      : <Skeleton width={150} variant="text" /> || "-";
  };

  const heading = [
    ["Total Investors", skeleton(statsData?.total_investors) || 0],
    ["Total Investments", skeleton(statsData?.total_investments) || 0],
    [
      "Total Invested Amount",
      skeleton(`$${numFormatter(statsData?.total_amount_invested)}`)
    ],
    ["Total Entities", skeleton(statsData?.total_entities) || 0],
    ["Total Distributions", skeleton(statsData?.total_distributions) || 0],
    [
      "Total Distributions Amount",
      skeleton(`$${numFormatter(statsData?.total_distribution_received)}`)
    ],
    ["K1 Count", skeleton(statsData?.k1_received) || 0]
  ];

  const prepareUrl = () => {
    let url = "";
    let newArr = [];
    switch (selected?.tenure) {
      case "yearly":
        if (selected?.yearly?.year) {
          url += `?year=${selected.yearly.year}`;
        }
        return url;
      case "quarterly":
        Object.keys(selected?.quarterly?.quarters || {}).forEach((item) => {
          if (selected.quarterly.quarters[item]) {
            newArr.push(item);
          }
        });
        if (newArr.length > 0) {
          url += `?year=${selected.quarterly.year}`;
          url += "&quarters=";
          newArr.forEach((ele) => {
            url += `${ele},`;
          });
          url = url.substring(0, url.length - 1);
        }
        return url;
      case "custom":
        if (selected?.custom?.from) {
          url += "?custom=true";
          url += `&start_date=${formatDateMMDDYY(selected?.custom?.from)}`;
          url += `&end_date=${formatDateMMDDYY(selected?.custom?.to)}`;
        }
        return url;
      default:
        return url;
    }
  };

  const getStatsData = async () => {
    let url = prepareUrl();
    const response = await DashboardSerivice.getDashboardStats([url]);
    if (response.success) {
      setTrigger(false);
      setStatsData(response.data);
    }
  };

  const getRecentInvestments = async () => {
    let url = prepareUrl();
    if (url.length > 0) {
      url += "&limit=3";
    } else {
      url += "?limit=3";
    }
    const response = await DashboardSerivice.getRecentInvestments([url]);
    if (response.success) {
      setTrigger(false);

      setInvestments(response.data.results);
    }
  };

  const getRecentInvestors = async () => {
    let url = prepareUrl();
    if (url.length > 0) {
      url += "&limit=4";
    } else {
      url += "?limit=4";
    }
    const response = await DashboardSerivice.getRecentInvestor([url]);
    if (response.success) {
      setTrigger(false);

      setInvestors(response.data.results);
    }
  };

  const getRecentDistribution = async () => {
    let url = prepareUrl();
    if (url.length > 0) {
      url += "&limit=3";
    } else {
      url += "?limit=3";
    }
    const response = await DashboardSerivice.getRecentDistribution([url]);
    if (response.success) {
      setDistributions(response.data.results);
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "DASHBOARD",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.push(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    setLoading(true);
    getStatsData();
    getRecentDistribution();
    getRecentInvestments();
    getRecentInvestors();
    setLoading(false);
    if (window.safari) {
      let data = window.safari.pushNotification.permission(
        "web.com.admin.simplee"
      );
      if (data.permission === "granted") {
        handlePermission(handleSafariNotification);
      }
    }
  }, [selected]);

  useEffect(() => {
    checkPermissions();
  }, []);

  const toolData = [
    statsData?.active_investors || 0,

    statsData?.inactive_investors || 0,
    statsData?.pending_investors || 0,
    statsData?.active_investments || 0,
    statsData?.inactive_investments || 0,
    statsData?.active_entities || 0,
    statsData?.inactive_entities || 0
  ];

  return (
    <>
      <HeaderComponent
        heading="Dashboard"
        description="Have A Quick Glance On Simplee"
        ExtraButtons={<AddButton />}
      />
      {loading && <Loader />}
      <Grid container className={styles.mainCtr}>
        <Grid item xs={12}>
          <div className={classes.filterContainer}>
            <TimeFilter selected={selected} setSelected={setSelected} />
          </div>
        </Grid>

        <Grid
          style={{
            boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
            backgroundColor: "#ffff",
            marginTop: "40px",
            borderRadius: "10px",
            padding: "20px",
            width: "496px"
          }}
          xs={12}
          sm={12}
          md={4}
          xl={4}
          item
          container>
          <Typography className={style.heading}>Platform Metrics</Typography>
          <Grid xs={12} container>
            {heading.map((value, index) => {
              return (
                <PlatformMatrics
                  toolData={toolData}
                  key={index}
                  heading={value[0]}
                  subHeading={value[1]}
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid
          xs={12}
          className={style.investorContainer}
          sm={12}
          md={8}
          xl={8}
          container>
          <Grid
            style={{
              boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
              backgroundColor: "#ffff",
              marginTop: "40px",
              borderRadius: "10px",
              padding: "20px"
            }}
            item
            container>
            <Grid container>
              <RecentInvestor trigger={trigger} data={investors} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} style={{ paddingTop: "20px" }}>
          <Grid
            style={{
              boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
              backgroundColor: "#ffff",
              marginTop: "40px",
              borderRadius: "10px",
              padding: "20px"
            }}
            container
            xs={12}
            md={6}>
            <RecentInvestment trigger={trigger} data={investment} />
          </Grid>
          <Grid
            className={style.distributionContainer}
            container
            xs={12}
            md={6}>
            <Grid
              style={{
                boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)",
                backgroundColor: "#ffff",
                padding: "20px"
              }}
              xs={12}>
              <RecentDistribution trigger={trigger} data={distribution} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;
