import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppRouter from "./router";
import { Store, persistor } from "../src/redux";
import {
  ThemeProvider,
} from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { defaultTheme } from "./themes/defaultTheme";
import "./themes/fonts.scss";
/**
 * @description Check if browser is Safar
 * @description It'll be usefull for web notifications
 */

function App() {
  return (
    <Provider store={Store}>
      <PersistGate persistor={persistor}>
        <ThemeProvider theme={defaultTheme}>
          <CssBaseline>
            <AppRouter />
          </CssBaseline>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
