import { API, NetworkManager } from "./core";

export class CommonService {
  static async imageUpload(payload) {
    const instance = new NetworkManager(API.COMMON.IMAGE_UPLOAD, payload);
    return await instance.httpRequest(true, true);
  }

  static async deleteDoc(id) {
    const instance = new NetworkManager(API.COMMON.DELETE_DOCS, {}, [`${id}/`]);
    return await instance.httpRequest();
  }

  static async deleteMedia(id) {
    const instance = new NetworkManager(API.COMMON.DELETE_MEDIA, {}, [
      `${id}/`
    ]);
    return await instance.httpRequest();
  }

  static async getAllDocCategories() {
    const instance = new NetworkManager(API.COMMON.GET_CATEGORIES);
    return await instance.httpRequest();
  }

  static async imageList() {
    const instance = new NetworkManager(API.COMMON.IMAGE_LIST);
    return await instance.httpRequest(true, true);
  }

  static async getDocCategories(params) {
    const instance = new NetworkManager(
      API.COMMON.GET_DOC_CATEGORIES,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
