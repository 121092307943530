import React, { useEffect } from "react";
import { MenuItem, makeStyles } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import { Select, withStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: 300
  }
}));

const StyledSelect = withStyles({
  root: {
    fontFamily: "Inter Regular",
    fontWeight: 600,
    fontSize: 16,
    height: 46,
    background: "#fff",
    width: "100%",
    border: "1px solid #BBBBBB !important",
    borderRadius: 8,
    padding: "0 10px",
    display: "flex",
    alignItems: "center",
    borderColor: "#445EBE",
    "&.Mui-disabled": {
      backgroundColor: "#E9E9E9",
      WebkitBoxShadow: "0 0 0 1000px #E9E9E9 inset",
      fontFamily: "Inter Regular",
      borderRadius: "8px",
      fontWeight: 600,
      color: "#191919"
    },
    "&:focus": {
      borderRadius: 8,
      backgroundColor: "#fff",
      borderColor: "#000000 !important"
    }
  }
})(Select);

const StyledMenu = withStyles({
  root: {
    "&:hover": {
      backgroundColor: "#F5F7FB"
    },
    fontFamily: "Inter Regular",
    fontSize: 16,
    width: "100%",
    color: "#191919"
  },
  selected: {
    backgroundColor: "#F5F7FB !important"
  }
})(MenuItem);

const CustomSelect = React.forwardRef(
  (
    {
      id,
      placeholder,
      items = [],
      value,
      onChange,
      fullWidth,
      style,
      disabled,
      sameYear,
      error,
      touched,
      defaultValue = "",
      defaultMenu
    },
    ref
  ) => {
    const classes = useStyles();
    useEffect(() => {}, [value, defaultValue]);

    return (
      <>
        <StyledSelect
          labelId={id || "simple-select"}
          ref={ref}
          tabIndex="0"
          placeholder={placeholder}
          fullWidth
          disabled={disabled}
          defaultValue={items.find((e) => e.value == defaultValue)?.value}
          disableUnderline
          style={{
            fontFamily: "Inter SemiBold",
            width: fullWidth ? "100%" : 200,
            fontWeight: 600,
            ...style
          }}
          value={value}
          onChange={(e) => onChange && onChange(e)}
          IconComponent={KeyboardArrowDownIcon}
          MenuProps={{
            classes: { paper: classes.menuPaper },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}>
          {defaultMenu && (
            <StyledMenu disabled value={"000"} key={"defaultKeyX125Z"}>
              {defaultMenu}
            </StyledMenu>
          )}
          {items.map((item, index) => (
            <StyledMenu
              value={item.value}
              key={index.toString()}
              style={{
                textDecoration: sameYear?.includes(item.value)
                  ? "line-through"
                  : "none"
              }}>
              {item.label}
            </StyledMenu>
          ))}
          {items.length === 0 && (
            <StyledMenu disabled>No options available</StyledMenu>
          )}
        </StyledSelect>
        {error && touched && (
          <div style={{ color: "#E13838", fontSize: "0.75rem", marginTop: 3 }}>
            {error}
          </div>
        )}
      </>
    );
  }
);
export default CustomSelect;
