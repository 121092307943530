import { Grid, makeStyles, Typography } from "@material-ui/core";
import LightTooltip from "components/LightTooltip";
import { truncate } from "helpers/Common";
import React from "react";

const useStyle = makeStyles({
  container: {
    background: "#FAFAFA",
    padding: "20px"
  },
  heading: {
    color: "#BBBBBB",
    fontSize: "14px !important",
    fontWeight: 400
  },
  subheading: {
    fontFamily: "Inter Regular !important",
    fontSize: "16px",
    color: "#191919",

    fontWeight: 600
  }
});

function GeneralInformation({ heading, value }) {
  const classes = useStyle();

  const showToolTip = value && value.length > 13 ? true : false;

  return (
    <>
      <Grid xs={4} item>
        <Grid xs={12} style={{ width: "auto" }}>
          <Typography className={classes.heading}>{heading}</Typography>
        </Grid>

        <Grid xs={12}>
          <LightTooltip
            title={
              showToolTip && (
                <div
                  style={{ color: "#000", fontSize: "16px", padding: "5px" }}>
                  {value}
                </div>
              )
            }>
            <Typography className={classes.subheading}>
              {showToolTip ? truncate(value, 13) : value}
            </Typography>
          </LightTooltip>
        </Grid>
      </Grid>
    </>
  );
}

export default GeneralInformation;
