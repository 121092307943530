import React from 'react';
import styles from './headerButton.module.scss';
import TableChartIcon from '@material-ui/icons/TableChart';

const HeaderButton = ({ handleClick }) => {
	return (
		<div className={styles.container} onClick={handleClick}>
			<div className={styles.icon}>
				<TableChartIcon />
			</div>
			<div className={styles.text}>Manage Columns</div>
		</div>
	);
};

export default HeaderButton;
