import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { truncate } from "helpers/Common";
import React from "react";
import styles from "../commanstyle.module.scss";

const TaggedPropertyTable = ({ Headingdata, mapArray, width }) => {
  return (
    <>
      <Table
        className={styles.Spon_T_C}
        style={width ? { minWidth: width } : {}}>
        <TableHead>
          <TableRow>
            {Headingdata?.map((item, i) => (
              <TableCell
                className={styles.T_Heading}
                key={i}
                style={i == 0 ? { paddingLeft: 54 } : {}}>
                {item.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mapArray?.map((obj, i) => (
            <TableRow key={i}>
              <TableCell
                title={obj?.name}
                className={styles.T_body}
                style={{ paddingLeft: 54 }}>
                {obj?.name ? truncate(obj?.name, 20) : "-"}
              </TableCell>
              <TableCell title={obj?.type} className={styles.T_body}>
                {obj?.type ? truncate(obj?.type, 20) : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.units && obj?.area ? `${obj?.area} ${obj?.units}` : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.occupancy ? obj?.occupancy + "%" : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.build_year ? obj?.build_year : "-"}
              </TableCell>
              <TableCell
                title={obj?.address?.[0]?.address}
                className={styles.T_body}>
                {obj?.address?.[0]?.address
                  ? truncate(obj?.address?.[0]?.address, 30)
                  : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.address?.[0]?.zip_code
                  ? obj?.address?.[0]?.zip_code
                  : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.status ? (
                  <div
                    style={{
                      width: "fit-content",
                      height: 30,
                      borderRadius: 153,
                      padding: "4px 14px 4px 14px",
                      backgroundColor:
                        obj?.status !== "Distressed" ? "#EDFFEF" : "#FCEBEB",
                      color:
                        obj?.status !== "Distressed" ? "#55A55E" : "#E13838",
                      fontFamily: "Inter Bold",
                      fontWeight: 600,
                      fontSize: 14
                    }}>
                    {obj?.status}
                  </div>
                ) : (
                  "-"
                )}
              </TableCell>
            </TableRow>
          ))}
          {mapArray?.length == 0 && (
            <TableRow>
              <TableCell colSpan={Headingdata?.length}>
                <Grid container justifyContent="center">
                  No Data Available
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </>
  );
};

export default TaggedPropertyTable;
