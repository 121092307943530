import { Grid, Typography } from "@material-ui/core";
import { AddInvestmentLoanService } from "network/addInvestmentLoanService";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./Components.module.scss";
import Loan from "./loan";

const TagLoan = ({ id, formik, isedit }) => {
  const [loanData, setLoanData] = useState([]);
  const { investmentID } = useSelector((store) => store.app);

  const handleCheck = (formik, index, item) => {
    if (formik.values.refinanceSet[id].selectedLoans[index]) {
      formik.setFieldValue(
        `refinanceSet[${id}].selectedLoans[${index}]`,
        false
      );
      if (isedit) {
        formik.setFieldValue(
          `refinanceSet[${id}].detach_loan[${index}]`,
          item.id
        );
      }
    } else {
      formik.setFieldValue(
        `refinanceSet[${id}].selectedLoans[${index}]`,
        item.id
      );
      if (isedit) {
        formik.setFieldValue(
          `refinanceSet[${id}].detach_loan[${index}]`,
          false
        );
      }
    }
  };

  const getLoansData = async () => {
    const resp = await AddInvestmentLoanService.getLoans(investmentID);
    if (resp.success) {
      const filterData = resp?.data?.loan.filter((loan) => !loan.is_refinanced);
      setLoanData(filterData);
    }
  };

  useEffect(() => {
    getLoansData();
  }, []);

  return (
    <Grid container className={styles.tagpropertyCtr}>
      <Grid item xs={12}>
        <Grid container className={styles.headerCtr}>
          <div className={styles.headerEmp}></div>
          <div className={styles.headerLoan}>
            <Typography className={styles.headerText}>Loan Type</Typography>
          </div>
          <div className={styles.headerLoan}>
            <Typography className={styles.headerText}>Loan Amount</Typography>
          </div>
          <div className={styles.headerLoan}>
            <Typography className={styles.headerText}>
              Outstanding Loan
            </Typography>
          </div>
          <div className={styles.headerLoan}>
            <Typography className={styles.headerText}>Bank</Typography>
          </div>
          <div className={styles.headerLoan}>
            <Typography className={styles.headerText}>Loan Date</Typography>
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {loanData.length === 0 && (
          <Grid container justifyContent="center">
            {" "}
            No Loans Found
          </Grid>
        )}
        {loanData &&
          loanData.length > 0 &&
          loanData.map((item, index) => {
            return (
              <Loan
                key={item.id}
                formik={formik}
                item={item}
                index={index}
                id={id}
                handleCheck={handleCheck}
              />
            );
          })}
      </Grid>
    </Grid>
  );
};

export default TagLoan;
