import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import { Grid, makeStyles } from "@material-ui/core";
import SponsorCards from "../../../components/Cards/SponsorCard";
import greenHand from "../../../assets/icons/NewCardSvg/greenHand.svg";
import pinkDollar from "../../../assets/icons/NewCardSvg/pinkDollar.svg";
import orangeHome from "../../../assets/icons/NewCardSvg/orangeHome.svg";
import Table from "./components/table/Table";
import { SponsorService } from "../../../network/sponsorService";
import Toast from "components/toast";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import styles1 from "../investments/investments.module.css";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  cardsContainer: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: -16
  }
}));

const Sponsors = () => {
  const classes = useStyles();
  const [sponsors, setSponsors] = useState([]);
  const [stats, setStats] = useState({});
  const [permissions, setPermissions] = useState();
  const [loading, setLoading] = useState(false);
  const { userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();

  const fetchStatsData = async () => {
    setLoading(true);
    const response = await SponsorService.getSponsorStats();
    if (response.success) {
      setLoading(false);

      setStats(response.data);
    } else {
      Toast.showErrorToast("Failed To Load Sponsors Data");
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "SPONSORS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(async () => {
    checkPermissions();
    fetchStatsData();
    const response = await SponsorService.getSponsors();
    if (response.success) {
      setSponsors(response.data.results);
    } else {
      Toast.showErrorToast("Failed To Load Sponsors List");
    }
  }, []);

  const skeleton = (values) => {
    return !loading ? values : <Skeleton width={150} variant="text" /> || "-";
  };
  return (
    <>
      <HeaderComponent
        heading="Sponsors"
        description="All Sponsors On Simplee For Your Respective Investments"
        addInvestorBtn={false}
        ExtraButtons={
          permissions?.is_create ? (
            <div className={styles1.addInvestmentButtonContainer}>
              <p
                className={styles1.addInvestmentButtonText}
                onClick={() => {
                  history.push("/u/sponsors/addSponsor");
                }}>
                + Add Sponsor
              </p>
            </div>
          ) : null
        }
      />
      <Grid
        container
        className={classes.cardsContainer}
        spacing={4}
        direction="row">
        <Grid item>
          <SponsorCards
            Title="Total Sponsors"
            count={skeleton(stats.total_sponsor) || "0"}
            icon={greenHand}
          />
        </Grid>
        <Grid item>
          <SponsorCards
            Title="Total Investments"
            link="/u/investments"
            count={skeleton(stats.total_investment) || "0"}
            icon={pinkDollar}
          />
        </Grid>
        <Grid item>
          <SponsorCards
            Title="Total Entities"
            link="/u/entities"
            count={skeleton(stats.total_entity) || "0"}
            icon={orangeHome}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Table tableData={sponsors} permissions={permissions} />
      </Grid>
    </>
  );
};

export default Sponsors;
