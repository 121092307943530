import React from "react";
import PrimaryButton from "../../../../components/Buttons/Primary";
import AppTextField from "../../../../components/StyledComponents/AppTextFeild";
import styles from "../forgotPassword.module.scss";
const SendOTP = ({ formik, handlePress }) => {
  return (
    <>
      <div className={styles.headingContainer}>
        <div className={styles.heading}>Forgot Password</div>
        {/* <div className={styles.subHeading}>Enter OTP Shared Over Email. Please Check Your Junk/Spam Folders</div> */}
      </div>
      <div className={styles.formContainer}>
        <div className={styles.email}>
          <AppTextField
            id="email"
            placeholder="Enter Your Email"
            variant="standard"
            fullWidth
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </div>
      </div>
      <div className={styles.formTagContainer}></div>
      <div>
        <PrimaryButton text="Send OTP" onPress={handlePress} width="68%" />
      </div>
    </>
  );
};

export default SendOTP;
