import { Grid, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { truncate } from "helpers/Common";
import formatCurrency from "helpers/formatCurrency";
import React from "react";
import { useHistory } from "react-router-dom";
import style from "../dashboard.module.css";
import CommonDashboardTable from "./CommonDashboardTable";

const investorHeading = [
  "Investment Name",
  "Investors",
  "Amount Invested",
  "Date",
  "Status"
];

function RecentInvestment({ data, trigger }) {
  const history = useHistory();
  const tableData = data?.map((item) => {
    return {
      0: truncate(item?.name, 16) || "-",
      1: item?.investors || "-",
      2: formatCurrency(item?.amount_invested) || "-",
      3: item?.investment_date || "-" || "0",
      4: item?.status || "-",
      id: item?.id,
      type: "investment_id"
    };
  });

  return (
    <Grid item xs={12} className={style.overviewMainContainer}>
      <Grid container justifyContent="space-between">
        <Typography className={style.heading}>Recent Investments</Typography>
        <ChevronRight
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/u/investments")}
        />
      </Grid>
      <Grid container>
        <CommonDashboardTable
          trigger={trigger}
          data={data}
          tableData={tableData}
          heading={investorHeading}
          type="investment"
        />
      </Grid>
    </Grid>
  );
}

export default RecentInvestment;
