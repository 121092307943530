import { API, NetworkManager } from "./core";

export class InvestmentListDetailService {
  static async getInvestmentListDetailService(id) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT_Detail.GET_INVESTMENT_Detail,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
  static async InvestmentDetailGraph(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT_Detail.INVESTMENT_DETAILS_GRAPHS,
      {},
      [params]
    );
    return await instance.httpRequest();
  }
}
