import React from "react";
import styles from "./commanstyle.module.scss";

const DescriptionCard = ({ heading, text }) => {
  return (
    <div className={styles.card_container}>
      <h3>{heading}</h3>
      <p>{text}</p>
    </div>
  );
};

export default DescriptionCard;
