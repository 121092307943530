import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
  Box
} from "@material-ui/core";
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import Toast from "components/toast";
import { FieldArray, Formik } from "formik";
import { CommonService } from "../../../../network/commonService";
import AddIcon from "../../../../assets/icons/addIcon.svg";
import DocumentUploadCard from "./components/documentUploadCard";
import { validationSchemaDocsUploadPopup } from "helpers/validationSchema";

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: "rgba(235, 239, 255, 0.6)",
    backdropFilter: "blur(26)"
  },
  container: {
    minWidth: 464,
    margin: "25px 16px 32px"
  },
  addMoreButton: {
    display: "flex",
    alignItems: "center",
    width: 150,
    "& img": {
      cursor: "pointer"
    },
    "& span": {
      paddingLeft: 8,
      color: "#445EBE",
      cursor: "pointer",
      fontFamily: "Inter Regular",
      fontSize: 14,
      fontWeight: 400
    }
  },
  heading: {
    fontSize: 28,
    fontWeight: 700,
    fontFamily: "Inter Bold"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  closeButton: {
    cursor: "pointer",
    width: 48,
    height: 48
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    "& button": {
      height: 50,
      width: 174,
      backgroundColor: "#445EBE",
      fontFamily: "Inter Regular",
      fontSize: 18,
      fontWeight: 600,
      color: "#FFFFFF",
      borderRadius: 4,
      cursor: "pointer"
    }
  }
}));

const OtherTypeDocUpload = ({
  open,
  setOpen,
  setList,
  allDocs,
  setAllDocs,
  listData
}) => {
  const classes = useStyles();
  const [initialValues, setInititalValues] = useState({ docsSet: [] });
  const [allCategories, setAllCategories] = useState([]);

  const fetchCategories = async () => {
    const res = await CommonService.getAllDocCategories();
    if (res.success) {
      res.data &&
        setAllCategories(
          res.data.map((el) => ({
            label: el.name,
            value: el.id
          }))
        );
      setInititalData(res?.data[0]?.id);
    } else {
      Toast.showErrorToast("Failed To Load Categories");
    }
  };

  const submitDocs = (values) => {
    let SelectedDocs = [];
    values.docsSet.forEach((docItem) => {
      docItem.documents.forEach((doc) => {
        doc.type = docItem.selectedType;
        SelectedDocs.push(doc);
      });
    });
    setAllDocs([...allDocs, ...SelectedDocs]);
    setList([...listData, ...SelectedDocs]);
    setOpen(false);
  };

  const handleAddMore = (array) => {
    array.push({
      selectedType: allCategories[0]?.value || "",
      documents: []
    });
  };

  const setInititalData = (initialType) => {
    setInititalValues({
      docsSet: [
        {
          selectedType: initialType,
          documents: []
        }
      ]
    });
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      className={classes.dialog}>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.headerContainer}>
            <Typography variant="h4" className={classes.heading}>
              Add Other Documents
            </Typography>
            <div className={classes.closeButton}>
              <img
                height="48px"
                src={CloseIcon}
                onClick={() => setOpen(false)}
              />
            </div>
          </div>
          <Box height={32} />
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaDocsUploadPopup}
            enableReinitialize={true}
            onSubmit={(values) => {
              submitDocs(values);
            }}>
            {(formik) => (
              <>
                <FieldArray
                  name="docsSet"
                  render={(arrayHelper) => (
                    <>
                      {formik.values.docsSet.map((d, index) => (
                        <DocumentUploadCard
                          key={index + "documentUpload"}
                          formik={formik}
                          index={index}
                          arrayHelper={arrayHelper}
                          allCategories={allCategories}
                        />
                      ))}
                      <div
                        className={classes.addMoreButton}
                        onClick={() => handleAddMore(arrayHelper)}>
                        <img src={AddIcon} alt="" height="20px" />
                        <span>Add More</span>
                      </div>
                    </>
                  )}
                />
                <div className={classes.btnContainer}>
                  <button type="button" onClick={() => formik.handleSubmit()}>
                    Upload
                  </button>
                </div>
              </>
            )}
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default OtherTypeDocUpload;
