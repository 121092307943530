import React, { useState } from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import styles from "./previewProperty.module.scss";
import downPA from "assets/icons/downPA.svg";
import upPA from "assets/icons/upPA.svg";
import DescriptionCard from "components/Common/DescriptionCard";
import ImageGrid from "components/Common/ImageGrid";
import PropertyNoiTable from "../../../../../../../components/Common/PropertyNoiTable";
import PropertyDocTable from "components/Common/PropertyDocTable";
import formatCurrency from "helpers/formatCurrency";
import { truncate } from "helpers/Common";

const propertyType = {
  singlefamily: "Single Family Home",
  condominums: "Condominiums",
  townhomes: "Townhomes",
  cooperativehousing: "Cooperative housing (a co-op)",
  duplexes: "Duplexes",
  triplexes: "Triplexes",
  fourplexes: "Fourplexes",
  individualmobile: "Individual mobile homes (not an entire mobile home park)",
  officespace: "Office space",
  hotelandlodging: "Hotel and lodging",
  selfstorage: "Self-storage or mini-storage",
  multifamilyproperties: "Multifamily properties",
  retailproperties: "Retail properties",
  industrialbuildings: "Industrial buildings",
  healthcarefacilites: "Health care facilities",
  specialpurposes: "Special purposes"
};

const width_1 = "167px";
const width_2 = "137px";
const width_3 = "153px";
const width_4 = "140px";
const width_5 = "113px";
const width_6 = "100px";
const width_7 = "121px";
const width_8 = "116px";
const width_9 = "93px";

const PropertiesAccordionTable = ({ data }) => {
  const [accordianHeight, setAccordianHeight] = useState([]);

  const handleAccordian = (index) => {
    let height = { ...accordianHeight };
    height[index] = !height[index];
    setAccordianHeight(height);
  };

  return (
    <Box className={styles.container}>
      {/*************************Table******************************* */}

      <div className={styles.tableCtr}>
        <div className={styles.headerCtr} style={{ paddingLeft: 20 }}>
          <div className={styles.fieldHeder} style={{ width: width_1 }}>
            <Typography className={styles.fieldHeadingtext}>
              Property Name
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_2 }}>
            <Typography className={styles.fieldHeadingtext}>Type</Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_3 }}>
            <Typography className={styles.fieldHeadingtext}>Address</Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_4 }}>
            <Typography className={styles.fieldHeadingtext}>
              Property Value
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_5 }}>
            <Typography className={styles.fieldHeadingtext}>
              Area/Units
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_6 }}>
            <Typography className={styles.fieldHeadingtext}>
              Occupancy
            </Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_7 }}>
            <Typography className={styles.fieldHeadingtext}>
              Year of Build
            </Typography>
          </div>
          {/* {zip && (
            <div className={styles.fieldHeder} style={{ width: width_7 }}>
              <Typography className={styles.fieldHeadingtext}>ZIP</Typography>
            </div>
          )} */}
          <div className={styles.fieldHeder} style={{ width: width_8 }}>
            <Typography className={styles.fieldHeadingtext}>Status</Typography>
          </div>
          <div className={styles.fieldHeder} style={{ width: width_9 }}>
            <Typography className={styles.fieldHeadingtext}></Typography>
          </div>
        </div>

        {data?.map((item, index) => {
          return (
            <div
              key={index}
              className={
                accordianHeight[index] === true
                  ? styles.mainTest
                  : styles.mainTest1
              }>
              <div
                className={styles.bodyCtr}
                key={index}
                style={{ paddingLeft: 20 }}>
                <div className={styles.fieldBody} style={{ width: width_1 }}>
                  <Typography
                    className={`${styles.fieldBodyText} ${styles.clickable}`}>
                    {item?.name || "-"}
                  </Typography>
                </div>
                <div
                  title={propertyType?.[item?.type]}
                  className={styles.fieldBody}
                  style={{ width: width_2 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.type
                      ? truncate(propertyType?.[item?.type], 16)
                      : "-"}
                  </Typography>
                </div>
                <div
                  title={item?.property_address?.[0]?.address}
                  className={styles.fieldBody}
                  style={{ width: width_3 }}>
                  <Typography noWrap className={styles.fieldBodyText}>
                    {item?.property_address?.address
                      ? truncate(item?.property_address?.address, 16)
                      : "-"}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_4 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.value ? formatCurrency(item?.value) : "-"}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_5 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.area && item?.units
                      ? `${item?.area} ${item?.units}`
                      : "-"}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_6 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.occupancy ? `${item?.occupancy}%` : "-"}
                  </Typography>
                </div>
                <div className={styles.fieldBody} style={{ width: width_7 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.build_year || "-"}
                  </Typography>
                </div>
                {/* 
                {zip && (
                  <div className={styles.fieldBody} style={{ width: width_7 }}>
                    <Typography className={styles.fieldBodyText}>
                      {item?.property_address?.zip_code === "Empty"
                        ? "-"
                        : item?.property_address?.zip_code || "-"}
                    </Typography>
                  </div>
                )} */}

                <div className={styles.fieldBody} style={{ width: width_8 }}>
                  <Typography className={styles.fieldBodyText}>
                    {item?.status ? (
                      <div
                        style={{
                          width: "fit-content",
                          height: 30,
                          borderRadius: 153,
                          padding: "4px 14px 4px 14px",
                          backgroundColor:
                            item.status !== "distressed"
                              ? "#EDFFEF"
                              : "#FCEBEB",
                          color:
                            item.status !== "distressed"
                              ? "#55A55E"
                              : "#E13838",
                          fontFamily: "Inter Bold",
                          fontWeight: 600,
                          fontSize: 14
                        }}>
                        {item?.status}
                      </div>
                    ) : (
                      "-"
                    )}
                  </Typography>
                </div>
                <div
                  className={styles.accordianIconDiv}
                  style={{ width: width_9 }}>
                  <div
                    onClick={() => handleAccordian(index)}
                    className={styles.accordianArrowImg}>
                    <img
                      alt="icon"
                      src={accordianHeight[index] === true ? upPA : downPA}
                    />
                  </div>
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <Grid
                  container
                  item
                  xs={12}
                  style={{
                    marginTop: "20px",
                    marginBottom: "45px",
                    padding: 20
                  }}>
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent="space-between"
                    style={{ marginBottom: "30px" }}>
                    {/* image */}
                    <Grid item>
                      <ImageGrid images={item?.images} />
                    </Grid>

                    {/* description */}
                    <Grid item>
                      <DescriptionCard
                        heading={"Description"}
                        text={item?.description || "No Description"}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    justifyContent="space-between">
                    {/* noitable */}
                    <Grid item>
                      <PropertyNoiTable
                        width={"714px"}
                        Title="Net Operating Income"
                        Headingdata={[
                          { heading: "Year" },
                          { heading: "Tenure" },
                          { heading: "Amount" },
                          { heading: "Update Date" },
                          { heading: "Documents" }
                        ]}
                        mapArray={item?.property_noi}
                      />
                    </Grid>

                    {/* upload Doc Table */}
                    <Grid item>
                      <PropertyDocTable
                        width={"714px"}
                        Title="Uploaded Documents"
                        Headingdata={[
                          { heading: "Document Name" },
                          { heading: "Size" },
                          { heading: "Attachment" }
                        ]}
                        mapArray={item?.documents}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          );
        })}
        {data?.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
              backgroundColor: "#fff",
              padding: 20
            }}>
            No Properties Found
          </div>
        )}
      </div>
      {/* ******************************************************** */}
    </Box>
  );
};

export default PropertiesAccordionTable;
