import { Grid, Typography } from "@material-ui/core";
import HeaderComponent from "../../../../components/HeaderComponent/HeaderComponent";
import { truncate } from "helpers/Common";
import { Endpoint, NetworkManager } from "network/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CommonOverView from "./CommonDetailComponent/CommonOverView";
import style from "./CommonDetailComponent/propertiesProfile.module.scss";
import LoanTable from "./CommonDetailComponent/LoanTable";
import RefinanceTable from "./CommonDetailComponent/RefinanceTable";
import NetOperatingIncomeTable from "./CommonDetailComponent/NetOperationIncomeTable";
import OccupancyTable from "./CommonDetailComponent/OccupancyTable";
import propertyIcon from "assets/icons/property.svg";
import outstandingIcon from "assets/icons/outstanding.svg";
import noiIcon from "assets/icons/noi.svg";
import debtIcon from "assets/icons/debtservices.svg";
import formatCurrency from "helpers/formatCurrency";
import { numFormatter } from "helpers";
import ImageComponent from "./CommonDetailComponent/ImageComponent";
import { Skeleton } from "@material-ui/lab";
import PropertyCard from "./CommonDetailComponent/PropertyCard";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import DocumentsTable from "pages/private/entities/detail/EntityTable/DocumentsTable";
import PropertyGrpah from "./CommonDetailComponent/PropertyGrpah";
import { useHistory } from "react-router-dom";
import { AppDispatcher } from "redux/index";
import ExportPDFButton from "components/Buttons/Primary/ExportPDFButton";
import StreetViewComponent from "./CommonDetailComponent/StreetViewComponent";

function index() {
  const { propertiesDetailId } = useSelector((store) => store.app);
  const [dropDownOpRes, setDropDownOpRes] = useState(null);
  const history = useHistory();
  const [propertyData, setPropertyData] = useState({});

  const documentTableHeading = ["Document Name", "Size", "Attachment"];
  const skeleton = (input) => {
    return Object.keys(propertyData).length != 0
      ? input
      : <Skeleton width={100} variant="text" /> || "-";
  };

  const fetchPropertiesDetails = async () => {
    const instance = new NetworkManager(
      new Endpoint(`investment/properties/${propertiesDetailId}/detail/`, "GET")
    );

    const resp = await instance.httpRequest();

    if (resp.success) {
      let temp = resp.data;
      setPropertyData(temp);
    }
  };

  useEffect(() => {
    getDropDownOptions();
  }, []);

  useEffect(() => {
    fetchPropertiesDetails();
  }, []);

  const heading = [
    ["Address Line 1", skeleton(propertyData?.property_address?.address)],
    [
      "Address Line 2",
      skeleton(propertyData?.property_address?.address_1) || "-"
    ],
    [
      "Area/Units",
      skeleton(
        propertyData?.area
          ? `${propertyData?.area} ${propertyData?.units}`
          : "-"
      )
    ],
    ["Year Build", skeleton(propertyData?.build_year || "-")]
  ];

  const headingBasicDetail = [
    ["Investment Name", skeleton(propertyData?.investment?.name)],
    ["Total Units", skeleton(truncate(propertyData?.total_units) || "-")],
    ["Property Type", skeleton(propertyData?.type || "-")],
    ["Property Status", skeleton(propertyData?.status || "-")]
  ];

  let obj = {};
  propertyData?.documents?.length != 0 &&
    propertyData?.documents?.map((ele) => {
      if (
        Object.keys(obj)?.findIndex((val) => val === ele?.category?.name) === -1
      ) {
        obj[ele?.category?.name] = [
          {
            name: ele?.document_media?.name,
            type: ele?.category?.id,
            link: ele?.document_media?.file,
            id: ele?.document_media?.id,
            docId: ele.id,
            size: ele?.document_media?.file_size
          }
        ];
      } else {
        obj[ele?.category?.name] = [
          ...obj[ele?.category?.name],
          {
            name: ele?.document_media?.name,
            type: ele?.category?.id,
            link: ele?.document_media?.file,
            id: ele?.document_media?.id,
            docId: ele.id,
            size: ele?.document_media?.file_size
          }
        ];
      }
    });

  let setLoanType = {};

  dropDownOpRes?.MONTH_CHOICES.map((item) => {
    setLoanType[item?.value] = item?.label;
  });

  const getDropDownOptions = async () => {
    const resp = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (resp.success) {
      setDropDownOpRes(resp?.data);
    }
  };

  return (
    <>
      <HeaderComponent
        backBtn={true}
        heading={skeleton(propertyData?.name)}
        description={skeleton(propertyData?.base_id)}
        investment_Status={skeleton(propertyData?.status)}
        managedBy={skeleton(propertyData?.investment?.managed_by)}
        isEditInvestorBtn={true}
        onEditInvestmentBtnClick={() => {
          AppDispatcher.setPropertiesId(propertiesDetailId);
          history.push("/u/properties/editProperties");
        }}
        ExtraButtons={<ExportPDFButton />}
      />
      <Grid container>
        <Grid style={{ padding: "20px" }} xs={12} container>
          <Grid
            justifyContent="space-between"
            container
            xs={4}
            md={3}
            lg={2}
            item>
            <PropertyCard
              Title="Property Value"
              count={skeleton(formatCurrency(propertyData?.value || 0))}
              icon={propertyIcon}
              alt={"property"}
            />
            <PropertyCard
              Title="Outstanding Loan"
              count={skeleton(
                `$${numFormatter(propertyData?.stats?.outstanding_loan || 0)}`
              )}
              icon={outstandingIcon}
              alt={"Oustanding"}
            />
            <PropertyCard
              Title="Net Operating Income"
              count={skeleton(
                `$${numFormatter(propertyData?.latest_noi?.noi)}`
              )}
              showSideData={true}
              year={skeleton(propertyData?.latest_noi?.year)}
              month={skeleton(setLoanType[propertyData?.latest_noi?.month])}
              icon={noiIcon}
              alt={"Noi"}
            />
            <PropertyCard
              Title="Debt Service"
              count={skeleton(
                formatCurrency(propertyData?.stats?.debtservice || 0)
              )}
              icon={debtIcon}
              alt={"debt Services"}
            />
          </Grid>
          <Grid container xs={8} md={9} lg={10} item>
            <Grid alignItems="center" xs={12} md={9} container item>
              {Object.keys(propertyData).length == 0 ? (
                <Grid
                  justifyContent="center"
                  alignItems="center"
                  container
                  xs={12}>
                  <Skeleton
                    variant="rounded"
                    animation="wave"
                    width={600}
                    height={400}
                  />
                </Grid>
              ) : (
                <ImageComponent
                  propertyData={propertyData}
                  imageList={propertyData.images}
                />
              )}
            </Grid>
            <Grid
              style={{ paddingLeft: "10px" }}
              xs={12}
              md={3}
              item
              className={`${style.basicDetailsContainer}`}>
              <Typography noWrap className={style.description}>
                Description
              </Typography>
              <Grid className={style.descriptionContent}>
                {skeleton(propertyData?.description)
                  ? skeleton(propertyData?.description)
                  : "No description for this property."}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid style={{ padding: "10px" }} container>
          <Grid item container xs={12}>
            <Grid
              className={`${style.basicDetailsContainer} ${style.detailWrapper}`}
              container
              item
              xs={12}
              md={6}>
              <Grid>
                <Typography className={style.cardHeading}>
                  Property Overview
                </Typography>
              </Grid>
              <Grid className={style.generalInformationData} container>
                {heading.map((value, index) => {
                  return (
                    <CommonOverView
                      key={index}
                      tableType={propertyData?.entity_type}
                      heading={value[0]}
                      value={value[1]}
                      managedBy={propertyData?.entity_type_id?.managed_by}
                      entityType={propertyData?.entity_type}
                    />
                  );
                })}
              </Grid>
            </Grid>
            <Grid container item xs={12} md={6} className={style.basicDetail}>
              <Grid
                item
                container
                className={`${style.basicDetailsContainer} ${style.detailWrapper}`}>
                <Grid>
                  <Typography className={style.cardHeading}>
                    Basic Detail
                  </Typography>
                </Grid>
                <Grid className={style.generalInformationData} container>
                  {headingBasicDetail.map((value, index) => {
                    return (
                      <CommonOverView
                        key={index}
                        tableType={propertyData?.entity_type}
                        heading={value[0]}
                        value={value[1]}
                        color="#589F11"
                        managedBy={propertyData?.entity_type_id?.managed_by}
                        entityType={propertyData?.entity_type}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {propertyData?.images?.length <= 5 &&
          propertyData?.images?.length != 0 ? (
            <Grid
              style={{ padding: "20px 0px 10px 0px", height: "450px" }}
              item
              xs={12}>
              <StreetViewComponent propertyData={propertyData} />{" "}
            </Grid>
          ) : null}

          <Grid style={{ padding: "20px 0px 10px 0px" }} item xs={12}>
            <Typography className={style.cardHeading}>
              Uploaded Documents
            </Typography>
            <DocumentsTable
              heading={documentTableHeading}
              data={obj}
              tableType="document"
              value={propertyData}
            />
          </Grid>
          <Grid container>
            <LoanTable propertyData={propertyData} />
          </Grid>
          <Grid container>
            <RefinanceTable propertyData={propertyData} />
          </Grid>
          <Grid container>
            <NetOperatingIncomeTable propertyData={propertyData} />
          </Grid>
          <Grid container>
            <OccupancyTable propertyData={propertyData} />
          </Grid>
          <PropertyGrpah />
        </Grid>
      </Grid>
    </>
  );
}

export default index;
