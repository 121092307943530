import DashboardIconActive from "../assets/icons/Sidebar/DashboardIconActive.svg";
import InvestorIcon from "../assets/icons/Sidebar/InvestorIcon.svg";
import InvestorIconActive from "../assets/icons/Sidebar/InvestorIconActive.svg";
import DashboardIcon from "../assets/icons/Sidebar/Dashboard.svg";
import InvestmentIcon from "../assets/icons/Sidebar/InvestmentIcon.svg";
import PropertiesIcon from "../assets/icons/Sidebar/PropertiesIcon.svg";
import PropertiesIconActive from "../assets/icons/Sidebar/PropertiesIconActive.svg";
import DistributionIcon from "../assets/icons/Sidebar/DistributionIcon.svg";
import DistributionIconActive from "../assets/icons/Sidebar/DistributionIconActive.svg";
import SponsorIcon from "../assets/icons/Sidebar/SponsorIcon.svg";
import SponsorIconActive from "../assets/icons/Sidebar/SponsorIconActive.svg";
import ContactIcon from "../assets/icons/Sidebar/ContactIcon.svg";
import ManageDataIcon from "../assets/icons/Sidebar/ManageDataIcon1.svg";
import DocumentIcon from "../assets/icons/Sidebar/DocumentIcon.svg";
import EntitiesIcon from "../assets/icons/Sidebar/EntitiesIcon.svg";
import EntitiesIconActive from "../assets/icons/Sidebar/EntitiesIconActive.svg";
import SubAdminIcon from "../assets/icons/Sidebar/SubAdminIcon.svg";
import ProfileIcon from "../assets/icons/Sidebar/ProfileIcon.svg";
import InvestmentIconActive from "../assets/icons/Sidebar/InvestmentIconActive.svg";
import ProfileIconActive from "../assets/icons/Sidebar/ProfileActive.svg";
import RoleIcon from "../assets/icons/Sidebar/RoleIcon.svg";
import RoleIconActive from "../assets/icons/Sidebar/RoleIconActive.svg";
const menuItems = [
  {
    id: "dashboard",
    title: "Dashboard",
    module_name: "DASHBOARD",
    link: "/u/dashboard",
    active: DashboardIcon,
    inActive: DashboardIconActive,
    isActive: false,
  },
  {
    id: "investors",
    title: "Investors",
    module_name: "INVESTORS",
    link: "/u/investors",
    active: InvestorIcon,
    inActive: InvestorIconActive,
    isActive: false
  },
  {
    id: "investments",
    title: "Investments",
    module_name: "INVESTMENTS",
    link: "/u/investments",
    active: InvestmentIcon,
    inActive: InvestmentIconActive,
    isActive: false
  },
  {
    id: "properties",
    title: "Properties",
    module_name: "PROPERTIES",
    link: "/u/properties",
    active: PropertiesIcon,
    inActive: PropertiesIconActive,
    isActive: false
  },
  {
    id: "distributions",
    title: "Distributions",
    module_name: "DISTRIBUTIONS",
    link: "/u/distributions",
    active: DistributionIcon,
    inActive: DistributionIconActive,
    isActive: false
  },
  {
    id: "sponsors",
    title: "Sponsors",
    module_name: "SPONSORS",
    link: "/u/sponsors",
    active: SponsorIcon,
    inActive: SponsorIconActive,
    isActive: false
  },
  {
    id: "contacts",
    title: "Contacts",
    module_name: "CONTACTS",
    link: "/u/contacts",
    active: ContactIcon,
    inActive: DashboardIconActive,
    isActive: false
  },
  {
    id: "manage_data",
    title: "Manage Data",
    module_name: "Manage Data",
    link: "/u/manage_data",
    active: ManageDataIcon,
    inActive: DashboardIconActive,
    isActive: false
  },
  {
    id: "documents",
    title: "Documents",
    module_name: "DOCUMENTS",
    link: "/u/documents",
    active: DocumentIcon,
    inActive: DashboardIconActive,
    isActive: false
  },
  {
    id: "entities",
    title: "Entities",
    module_name: "ENTITY",
    link: "/u/entities",
    active: EntitiesIcon,
    inActive: EntitiesIconActive,
    isActive: false
  },
  {
    id: "roles",
    title: "Roles",
    module_name: "ROLES",
    link: "/u/roles",
    active: RoleIcon,
    inActive: RoleIconActive,
    isActive: false
  },
  {
    id: "sub_admin",
    title: "Sub Admins",
    module_name: "SUB ADMINS",
    link: "/u/sub_admin",
    active: SubAdminIcon,
    inActive: DashboardIconActive,
    isActive: false
  },
  {
    id: "profile",
    title: "Profile",
    module_name: "PROFILE",
    link: "/u/editprofile",
    active: ProfileIcon,
    inActive: ProfileIconActive,
    isActive: false,
    hasViewPermission: true
  }
];
export default menuItems;
