import { Grid } from "@material-ui/core";
import formatCurrency from "helpers/formatCurrency";
import formatDate from "helpers/formatDate";
import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";

const dtf = {
  startofmonth: "Start Of The Month",
  midofmonth: "Mid Of The Month",
  endofmonth: "End Of The Month",
  startofquarter: "Start Of The Quarter",
  midofquarter: "Mid Of The Quarter",
  endofquarter: "End Of The Quarter"
};

function OverViewNewTable({ data }) {
  const [investment, setInvestment] = useState({});
  useEffect(() => {
    setInvestment(data?.investment ? data?.investment : {});
  }, [data]);
  return (
    <Grid item xs={12} container wrap="wrap">
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Investment Name</h4>
          <div className={styles.value}>{investment?.name || "-"}</div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Purchase Price</h4>
          <div className={styles.value}>
            {investment?.purchase_price
              ? formatCurrency(investment?.purchase_price)
              : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Distribution Start Date</h4>
          <div className={styles.value}>
            {investment?.start_date ? formatDate(investment?.start_date) : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Distribution Cadence</h4>
          <div className={styles.value}>
            {investment?.distribution_cadence
              ? investment?.distribution_cadence
              : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Refinance</h4>
          <div className={styles.value}>
            {investment?.is_refinanced ? "Yes" : "No"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Projected Return (%)</h4>
          <div className={styles.value}>
            {investment?.projected_returns
              ? investment?.projected_returns + "%"
              : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Deal Close Date</h4>
          <div className={styles.value}>
            {investment?.closing_date
              ? formatDate(investment?.closing_date)
              : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Distribution Time Frame</h4>
          <div className={styles.value}>
            {investment?.distribution_time_frame
              ? dtf[investment?.distribution_time_frame]
              : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Cap Rate</h4>
          <div className={styles.value}>
            {investment?.cap_rate ? investment?.cap_rate + "%" : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Total Deal Size</h4>
          <div className={styles.value}>
            {investment?.purchase_price
              ? formatCurrency(investment?.purchase_price)
              : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Date Of Sale</h4>
          <div className={styles.value}>
            {investment?.sold_date ? formatDate(investment?.sold_date) : "-"}
          </div>
        </div>
      </Grid>
      <Grid item>
        <div className={styles.field_c}>
          <h4 className={styles.title}>Price Of Sale</h4>
          <div className={styles.value}>
            {investment?.sold_date
              ? formatCurrency(investment?.sold_price)
              : "-"}
          </div>
        </div>
      </Grid>
    </Grid>
  );
}

export default OverViewNewTable;
