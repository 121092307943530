import React from "react";
import { Grid, IconButton, Box, Typography } from "@material-ui/core";
import DocumentsUpload from "./DocumentsUpload/documentsUpload";
import CustomSelect from "components/StyledComponents/Select";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import ClearIcon from "@material-ui/icons/Clear";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import EditIcon from "assets/icons/EditIcon2.svg";

const Noi = ({
  id,
  arrayHelper,
  formik,
  noiCategories,
  monthChoices,
  isEditable,
  deleteHandler,
  noiEditableRows,
  makeNoiEditableRow
}) => {
  const handleDelete = async () => {
    if (formik.values.noiSet[id].id) {
      deleteHandler(formik.values.noiSet[id].id);
    }
    arrayHelper.remove(id);
  };

  const setDocData = (data) => {
    formik.setFieldValue(`noiSet[${id}].documents`, data);
  };

  const NOI =
    formik.values.noiSet[id].totalRevenue -
    formik.values.noiSet[id].operatingExpense -
    formik.values.noiSet[id].financingExpense -
    formik.values.noiSet[id].nonOperatingExpense;

  const currentNoiYearSelected = formik.values.noiSet[id].year;

  const sameYearNois = formik.values.noiSet.map((item) => {
    if (item.year === currentNoiYearSelected) {
      return item.month;
    }
    return "no";
  });

  return (
    <Grid container spacing={2}>
      <Box
        display="flex no-wrap"
        flexDirection={["row"]}
        justifyContent="space-between"
        alignItems="center"
        style={{
          display: "inline-flex",
          gap: "16px",
          width: "100%",
          padding: 20,
          paddingBottom: id == formik.values.noiSet.length - 1 ? 20 : 0
        }}>
        <Box>
          <SelectWithInfiniteScroll
            disabled={
              isEditable === "true" &&
              !noiEditableRows.includes(id) &&
              formik.values.noiSet[id]?.addNewNoi !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            minValue={
              parseInt(formik.values.noiSet[id]?.year) < 2000
                ? parseInt(formik.values.noiSet[id]?.year)
                : 2000
            }
            selectedItem={formik.values.noiSet[id]?.year ?? 2000}
            onChange={(value) =>
              formik.setFieldValue(`noiSet[${id}].year`, value)
            }
          />
        </Box>
        <Box>
          <CustomSelect
            disabled={
              isEditable === "true" &&
              !noiEditableRows.includes(id) &&
              formik.values.noiSet[id]?.addNewNoi !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            placeholder="Month"
            items={monthChoices}
            value={formik.values.noiSet[id].month}
            onChange={(e) => {
              if (sameYearNois.includes(e.target.value)) {
                return null;
              }
              formik.setFieldValue(`noiSet[${id}].month`, e.target.value);
            }}
            sameYear={sameYearNois}
          />
        </Box>
        <Box item xs={3}>
          <AppTextFieldWhite
            disabled={
              isEditable === "true" &&
              !noiEditableRows.includes(id) &&
              formik.values.noiSet[id]?.addNewNoi !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            id={`noiSet[${id}].totalRevenue`}
            placeholder="Total Revenue"
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.noiSet[id].totalRevenue}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            onChange={formik.handleChange(`noiSet[${id}].totalRevenue`)}
          />
        </Box>

        <Box item xs={4} style={{ width: "170px" }}>
          <AppTextFieldWhite
            disabled={
              isEditable === "true" &&
              !noiEditableRows.includes(id) &&
              formik.values.noiSet[id]?.addNewNoi !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            id={`noiSet[${id}].operatingExpense`}
            placeholder="Expense"
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.noiSet[id].operatingExpense}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            onChange={formik.handleChange(`noiSet[${id}].operatingExpense`)}
          />
        </Box>
        <Box item xs={3}>
          <AppTextFieldWhite
            disabled={
              isEditable === "true" &&
              !noiEditableRows.includes(id) &&
              formik.values.noiSet[id]?.addNewNoi !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            id={`noiSet[${id}].financingExpense`}
            placeholder="Expense"
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.noiSet[id].financingExpense}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            onChange={formik.handleChange(`noiSet[${id}].financingExpense`)}
          />
        </Box>
        <Box item xs={3} style={{ width: "165px" }}>
          <AppTextFieldWhite
            disabled={
              isEditable === "true" &&
              !noiEditableRows.includes(id) &&
              formik.values.noiSet[id]?.addNewNoi !== "true"
            }
            style={{ backgroundColor: "white", width: "150px" }}
            id={`noiSet[${id}].nonOperatingExpense`}
            placeholder="NOE"
            numFormat={true}
            variant="standard"
            fullWidth
            value={formik.values.noiSet[id].nonOperatingExpense}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            onChange={formik.handleChange(`noiSet[${id}].nonOperatingExpense`)}
          />
        </Box>
        <Box item xs={3} style={{ minWidth: "50px" }}>
          <Typography sx={{ fontWeight: 600 }}>{`$${
            NOI ? NOI : "0"
          }`}</Typography>
        </Box>

        <Box style={{ minWidth: "90px" }}>
          {noiCategories &&
            noiCategories
              .filter((el) => el.name !== "Misc.")
              .map((category) => (
                <DocumentsUpload
                  disabled={
                    isEditable === "true" &&
                    !noiEditableRows.includes(id) &&
                    formik.values.noiSet[id]?.addNewNoi !== "true"
                  }
                  key={category.id}
                  type={category.id}
                  title={category.name}
                  listData={formik.values.noiSet[id].documents}
                  setListData={(list) => setDocData(list)}
                />
              ))}
        </Box>
        {isEditable === "true" && (
          <Box alignSelf={"center"} marginLeft="auto">
            <IconButton
              onClick={() => makeNoiEditableRow(id)}
              style={{ color: "#ff0000", padding: "5px" }}>
              <img src={EditIcon} alt="edit" />
            </IconButton>
          </Box>
        )}
        <Box alignSelf={"center"} marginLeft={isEditable !== "true" && "auto"}>
          <IconButton onClick={handleDelete} style={{ color: "#ff0000" }}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
    </Grid>
  );
};

export default Noi;
