import { Grid, Hidden } from "@material-ui/core";
import React from "react";
import style from "./tableStyle.module.css";

function TableTopFilter({
  filterSection1,
  filterSection2,
  MobileViewCSV,
  MobileViewForwrdAsEmail,
  tabletViewCSV,
  tabletViewFWDEmail
}) {
  const MoreFilters = (
    <Grid container>
      <Grid item xs={12} className={style.m_t}>
        {filterSection1[4]}
      </Grid>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={3} justifyContent="space-between" wrap="nowrap">
        <Grid container>
          <div className={`${style.m_r} ${style.fg}`}>{filterSection1[0]}</div>
          <Hidden xsDown>
            <div className={style.m_r}> {filterSection1[1]}</div>
          </Hidden>
          <Hidden xsDown>
            <div className={style.m_r}> {filterSection1[2]}</div>
          </Hidden>
          <div className={`${style.m_r} ${style.csvMrgnTop}`}>
            {filterSection1[3]}
          </div>
          <Hidden smUp>
            <div className={style.m_r}>{MobileViewForwrdAsEmail}</div>
          </Hidden>
          <Hidden smUp>
            <div className={style.m_r}>{MobileViewCSV}</div>
          </Hidden>
        </Grid>

        <Hidden xsDown>
          <Grid className="filterSection2">
            <Grid container wrap="nowrap">
              <div className={style.m_r}>{filterSection2[0]}</div>
              <Hidden mdDown>
                <div className={style.m_r}> {filterSection2[1]}</div>
                <div className={style.m_r}> {filterSection2[2]}</div>
              </Hidden>
              <Hidden lgUp>
                <div className={style.m_r}>{tabletViewFWDEmail}</div>
                <div className={style.m_r}>{tabletViewCSV}</div>
              </Hidden>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>

      {MoreFilters}
    </>
  );
}

export default TableTopFilter;
