const Actions = {
  LOGIN: "SET_USER_STATE",
  SET_TOKEN: "SET_AUTH_TOKEN",
  LOGOUT: "SET_USER_LOGOUT",
  SET_EMAIL: "SET_EMAIL",
  SET_INVESTOR_NAME: "SET_INVESTOR_NAME",
  SET_INVESTOR_ID: "SET_INVESTOR_ID",
  SET_SPONSOR_ID: "SET_SPONSOR_ID",
  SET_ENTITY_ID: "SET_ENTITY_ID",
  SET_DISTRIBUTION_ID: "SET_DISTRIBUTION_ID",
  SET_ADD_INVESTMENT_STEP: "SET_ADD_INVESTMENT_STEP",
  RESET_ADD_INVESTMENT_STEP: "RESET_ADD_INVESTMENT_STEP",
  ADD_INVESTMENT_NEXT_STEP: "ADD_INVESTMENT_NEXT_STEP",
  SET_OTP: "SET_OTP",
  SET_PROPERTY_DATA: "SET_PROPERTY_DATA",
  ADD_INVESTMENT_ID: "ADD_INVESTMENT_ID",
  SET_INVESTOR_STATUS: "SET_INVESTOR_STATUS",
  SET_INVESTMENT_DETAIL_ID: "SET_INVESTMENT_DETAIL_ID",
  SET_SPONSOR_DETAIL_ID: "SET_SPONSOR_DETAIL_ID",
  SET_INVESTOR_DETAIL_ID: "SET_INVESTOR_DETAIL_ID",
  SET_PROPERTIES_DETAIL_ID: "SET_PROPERTIES_DETAIL_ID",
  SET_ENTITIES_DETAIL_DATA: "SET_ENTITIES_DETAIL_DATA",
  SET_PROPERTIES_ID: "SET_PROPERTIES_ID",
  SET_CONTACTS_ID: "SET_CONTACTS_ID",
  SET_IsPrimary: "SET_IsPrimary",
  SET_MANAGEDATAK1_ID: "SET_MANAGEDATAK1_ID",
  SET_ROLE_ID: "SET_ROLE_ID",
  SET_SUB_ADMIN_ID: "SET_SUB_ADMIN_ID",
  SET_USER_PERMISSIONS: "SET_USER_PERMISSIONS",
  SET_DOCUMENT_ID: "SET_DOCUMENT_ID",
  SET_DATE_RANGE: "SET_DATE_RANGE",
  SET_DRAWER_OPEN: "SET_DRAWER_OPEN",
  SET_ENTITIES_ID_TYPE: "SET_ENTITIES_ID_TYPE",
  SET_UPLOAD_TEMPLATE_STEP: "SET_UPLOAD_TEMPLATE_STEP",
  SET_GROUPBY: "SET_GROUPBY",
  SET_ALL_ROLES: "SET_ALL_ROLES",
  SET_EQUITY_AMOUNT: "SET_EQUITY_AMOUNT",
  SET_OUTSTANDING_LOAN: "SET_OUTSTANDING_LOAN",
  SET_LOANSECTION_BAR_VALUE: "SET_LOANSECTION_BAR_VALUE",
  OPEN_ADD_MANAGE_DATA: "OPEN_ADD_MANAGE_DATA",
  OPEN_EDIT_MANAGE_DATA: "OPEN_EDIT_MANAGE_DATA",
  SEND_EMAIL_CONTACT: "SEND_EMAIL_CONTACT",
  SEND_EMAIL_ID_CONTACT: "SEND_EMAIL_ID_CONTACT",
  ADMIN_EMAIL_ID: "ADMIN_EMAIL_ID",
  RETAIN_FILTERS: "RETAIN_FILTERS",
  RETAIN_COUNT: "RETAIN_COUNT",
  RETAIN_PAGE: "RETAIN_PAGE",
  RETAIN_PAGERESETVALUE: "RETAIN_PAGERESETVALUE",
  RETAIN_MANAGECOLUMN: "RETAIN_MANAGECOLUMN",
  RETAIN_PAGESIZE: "RETAIN_PAGESIZE"
};

export default Actions;
