import FieldHeading from "../../../../../../../components/fieldHeading";
import React, { useEffect, useState, useRef } from "react";
import AddIcon from "../../../../../../../assets/icons/addIcon.svg";
import collapseIconRoundRed from "../../../../../../../assets/icons/collapseIconRoundRed.svg";
import { makeStyles, Grid } from "@material-ui/core";
import AppTextFieldWhite from "../../../../../../../components/StyledComponents/AppTextFieldWhite";
import CustomSelect from "../../../../../../../components/StyledComponents/Select";
import Calender from "../../../../../../../components/calender";
import { preventNonNumericalInput } from "helpers";

const useStyles = makeStyles(() => ({
  basicContainer2: {
    marginTop: 10
  },
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    width: 160,
    marginTop: "23.5px"
  }
}));

const BasicDetails = ({
  formik,
  cadenceChoices,
  ClickonOverViewEdit,
  investmentStatus
}) => {
  const classes = useStyles();
  const [isDataSet, setIsDataSet] = useState(false);
  const [addMore, setAddMore] = useState(false);
  const dropdownRef = useRef(null);
  const cadence = {
    monthly: [
      { label: "Start Of The Month", value: "startofmonth" },
      { label: "Mid Of The Month", value: "midofmonth" },
      { label: "End Of The Month", value: "endofmonth" }
    ],
    quarterly: [
      { label: "Start Of The Quarter", value: "startofquarter" },
      { label: "Mid Of The Quarter", value: "midofquarter" },
      { label: "End Of The Quarter", value: "endofquarter" }
    ]
  };

  const [timeFrameChoices, setTimeFrameChoices] = useState([]);

  const handleMapping = async (item) => {
    setTimeFrameChoices(cadence[item]);
    await formik.setFieldValue("distribution_cadence", item);
  };

  useEffect(() => {
    setIsDataSet(false);
    if (formik.values.distribution_cadence) {
      handleMapping(formik.values.distribution_cadence);
      let cadenceArray = cadence[formik.values.distribution_cadence];
      cadenceArray.forEach((item) => {
        if (item.label === formik.values.distribution_timeFrame) {
          formik.setFieldValue("distribution_time_frame", item.value);
        }
      });
    }
    setIsDataSet(true);
  }, []);

  const onkeyPress = (e, ref) => {
    if (e.keyCode == 9) {
      ref.current.focus();
    }
  };

  const onKeyDownActions = (e, ref) => {
    preventNonNumericalInput();
    onkeyPress(e, ref);
  };

  return (
    <Grid container item xs={12}>
      {isDataSet ? (
        <>
          <Grid container spacing={2}>
            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Investment Name" />
              <AppTextFieldWhite
                disabled={!ClickonOverViewEdit}
                id="investment_name"
                placeholder="Add Investment Name"
                variant="standard"
                fullWidth
                value={formik.values.investment_name}
                onChange={formik.handleChange("investment_name")}
                error={
                  formik.touched.investment_name &&
                  Boolean(formik.errors.investment_name)
                }
                helperText={
                  formik.touched.investment_name &&
                  formik.errors.investment_name
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Purchase Price ($)" />
              <AppTextFieldWhite
                disabled={!ClickonOverViewEdit}
                id="purchase_price"
                placeholder=""
                variant="standard"
                onKeyDown={(e) => onKeyDownActions(e, dropdownRef)}
                fullWidth
                value={formik.values.purchase_price}
                onChange={formik.handleChange("purchase_price")}
                error={
                  formik.touched.purchase_price &&
                  Boolean(formik.errors.purchase_price)
                }
                helperText={
                  formik.touched.purchase_price && formik.errors.purchase_price
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
                inputProps={{ maxLength: 12 }}
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Distribution Start Date" />
              <Calender
                disabled={!ClickonOverViewEdit}
                id="Distribution Start Date"
                setDate={(date) => formik.setFieldValue(`start_date`, date)}
                defaultDate={formik.values.start_date}
                error={
                  formik.touched.start_date && Boolean(formik.errors.start_date)
                }
                helperText={
                  formik.touched.start_date && formik.errors.start_date
                }
              />
            </Grid>

            <Grid item xl={3} lg={4} sm={6}>
              <FieldHeading title="Distribution Cadence" />
              <CustomSelect
                disabled={!ClickonOverViewEdit}
                id="Distribution Cadence"
                ref={dropdownRef}
                placeholder="Quaterly"
                fullWidth
                items={cadenceChoices}
                onChange={(e) => {
                  handleMapping(e.target.value);
                }}
                value={formik.values.distribution_cadence}
                error={formik.errors.distribution_cadence}
                touched={formik.touched.distribution_cadence}
              />
            </Grid>

            {(investmentStatus === "Draft" || investmentStatus === "Sold") && (
              <>
                {" "}
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Date Of Sale (Only Enter If Sold)" />
                  <Calender
                    disabled={!ClickonOverViewEdit}
                    id="dateOfSale"
                    setDate={(date) => formik.setFieldValue(`dateOfSale`, date)}
                    defaultDate={formik.values.dateOfSale}
                    error={
                      formik.touched.dateOfSale &&
                      Boolean(formik.errors.dateOfSale)
                    }
                    helperText={
                      formik.touched.dateOfSale && formik.errors.dateOfSale
                    }
                  />
                </Grid>
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Price Of Sale ($)" />
                  <AppTextFieldWhite
                    disabled={!ClickonOverViewEdit}
                    id="priceOfSale"
                    placeholder=""
                    variant="standard"
                    onKeyDown={(e) => onKeyDownActions(e, dropdownRef)}
                    fullWidth
                    value={formik.values.priceOfSale}
                    onChange={formik.handleChange("priceOfSale")}
                    error={
                      formik.touched.priceOfSale &&
                      Boolean(formik.errors.priceOfSale)
                    }
                    helperText={
                      formik.touched.priceOfSale && formik.errors.priceOfSale
                    }
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>
              </>
            )}

            {/*Show when Clicked On add More button( not mendotory field ) */}

            {addMore && (
              <>
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Refinance" />
                  <CustomSelect
                    disabled={!ClickonOverViewEdit}
                    id="Refinance"
                    placeholder="Refinance"
                    fullWidth
                    items={[
                      { label: "Yes", value: "true" },
                      { label: "No", value: "false" }
                    ]}
                    onChange={formik.handleChange("is_refinanced")}
                    value={formik.values.is_refinanced}
                    error={formik.errors.is_refinanced}
                    touched={formik.touched.is_refinanced}
                  />
                </Grid>
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Projected Return (%)" />
                  <AppTextFieldWhite
                    disabled={!ClickonOverViewEdit}
                    id="projected_returns"
                    placeholder=""
                    variant="standard"
                    fullWidth
                    onKeyDown={preventNonNumericalInput}
                    value={formik.values.projected_returns}
                    onChange={formik.handleChange("projected_returns")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Close Date" />
                  <Calender
                    disabled={!ClickonOverViewEdit}
                    id="Close Date"
                    setDate={(date) =>
                      formik.setFieldValue(`closing_date`, date)
                    }
                    defaultDate={formik.values.closing_date}
                  />
                </Grid>

                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Distribution Time Frame" />
                  <CustomSelect
                    disabled={!ClickonOverViewEdit}
                    id="Distribution Time Frame"
                    placeholder="Quaterly"
                    fullWidth
                    items={timeFrameChoices}
                    onChange={formik.handleChange("distribution_time_frame")}
                    defaultValue={formik.values.distribution_time_frame}
                  />
                </Grid>

                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Cap Rate (%)" />
                  <AppTextFieldWhite
                    disabled={!ClickonOverViewEdit}
                    id="cap_rate"
                    placeholder=""
                    variant="standard"
                    fullWidth
                    onKeyDown={preventNonNumericalInput}
                    value={formik.values.cap_rate}
                    onChange={formik.handleChange("cap_rate")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 5 }}
                  />
                </Grid>

                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Total Deal Size ($)" />
                  <AppTextFieldWhite
                    disabled={!ClickonOverViewEdit}
                    id="Total Deal Size"
                    onKeyDown={preventNonNumericalInput}
                    placeholder=""
                    variant="standard"
                    fullWidth
                    value={formik.values.total_raise}
                    onChange={formik.handleChange("total_raise")}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 12 }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid container>
            {ClickonOverViewEdit && (
              <div
                className={classes.addMore}
                onClick={() => setAddMore(!addMore)}>
                <img
                  src={addMore ? collapseIconRoundRed : AddIcon}
                  alt="add/Collapse"
                  style={{ marginRight: 8 }}
                />
                {addMore ? (
                  <div style={{ color: "#E13838" }}>Collapse</div>
                ) : (
                  <div>Add Details</div>
                )}
              </div>
            )}
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default BasicDetails;
