import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { makeStyles } from "@material-ui/core/styles";
import { Badge, Grid } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { saveAs } from "file-saver";
import { truncate } from "helpers/Common";
// import Image from "next/image";
// import { truncate } from "@helpers/Common";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    zIndex: 1000
  },
  paper: {
    marginRight: theme.spacing(2)
  }
}));

export default function FileDownloadDropDown({ data, tableTitle }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const saveFile = (link, name) => {
    saveAs(link, name);
  };

  return (
    <Grid className={classes.root}>
      <Grid
        container
        item
        xs={2}
        justifyContent={["Loans"].includes(tableTitle) ? "center" : "left"}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={data?.length ? handleToggle : null}
        style={{
          color: "#445EBE",
          fontSize: "0.85rem",
          paddingTop: 0,
          paddingBottom: 0
        }}>
        <Badge badgeContent={data?.length} color="primary">
          <Grid container alignItems="center" justifyContent="center">
            {[
              "K1s",
              "Distributions",
              "Loans",
              "Uploaded Documents",
              "Net Operating Income"
            ].includes(tableTitle) ? (
              <img
                src="/assets/icons/NewCardIconsvg/newDocImg.svg"
                width="40px"
                height="40px"
                alt="miltiple-docs"
              />
            ) : (
              <>
                <Grid item>Download</Grid>
                <Grid item style={{ paddingTop: "5px" }}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Grid>
              </>
            )}
          </Grid>
        </Badge>
      </Grid>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{
          zIndex: 10
        }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom"
            }}>
            <Paper
              variant="outlined"
              style={{
                boxShadow: "0px 2px 4px 3px rgba(20,20,20,0.06)"
              }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}>
                  {data.map((item, index) => {
                    return (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          saveFile(item.link, item.name);
                        }}
                        style={{
                          fontSize: "0.85rem",
                          borderBottom: "1px solid #e9e9e9"
                        }}>
                        <Grid
                          container
                          justifyContent="space-between"
                          spacing={3}>
                          <Grid item xs={9}>
                            {truncate(item.name, 15)}
                          </Grid>
                          <Grid item xs={3} container alignItems="center">
                            <img
                              src="/assets/icons/svgs/downloadDarkBlue.svg"
                              width="16px"
                              height="16px"
                              alt="download-icon"
                            />
                          </Grid>
                        </Grid>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Grid>
  );
}
