import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Dialog,
  DialogContent,
  makeStyles,
  Grid
} from "@material-ui/core";
import styles from "../../myProfile/profile.module.scss";
import { FieldArray, Form, Formik } from "formik";
import { useHistory } from "react-router-dom";
import { validationSchemaEditEntity } from "../../../../helpers/validationSchema";
import Toast from "../../../../components/toast";
// import blueDot from "../../../../assets/icons/NewCardSvg/blueDot.svg";
// import orangeDot from "assets/icons/NewCardSvg/orangeDot.svg";
import CloseIcon from "../../../../assets/icons/CloseIcon.svg";
import { EntityService } from "../../../../network/entityService";
import AddIcon from "../../../../assets/icons/addIcon.svg";
import EntityForm from "./components/forms/entityForm";
import PartnerForm from "./components/forms/partnerForm";
import { useSelector } from "react-redux";
import { CommonService } from "network/commonService";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import NewCategoryDocTable from "pages/private/properties/component/NewCategoryDocTable";

const useStyles = makeStyles(() => ({
  root: {
    "&  .MuiDialog-container .MuiDialog-paperWidthSm": {
      width: "100%",
      maxWidth: 1233,
      height: "fit-content"
    }
  },
  closeButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  addedByText: {
    marginRight: 34,
    fontFamily: "Inter Regular",
    fontSize: "16px",
    fontWeight: 400,
    LineHeight: "19.36px"
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: 38.5,
    boxSizing: "border-box"
  },
  MaterialForm: {},
  form: {},
  nameFieldContainer: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "369px",
    "@media(min-width: 1259px)": { width: "100%" },
    "@media(max-width: 1259px)": { flexGrow: 1 }
  },
  nameContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
    marginBottom: "20px"
  },
  documentHeading: {
    margin: "20px 0px",
    width: "168px",
    height: "22px",
    fontFamily: "Inter Bold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#191919",
    whiteSpace: "nowrap"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
    marginBottom: 40,
    marginTop: 60
  },
  heading: {
    fontSize: 26,
    fontWeight: 700,
    fontFamily: "Inter Bold"
  },
  doctableHeading: {
    fontSize: 16,
    fontWeight: 600,
    fontFamily: "Inter Regular",
    color: "#616161"
  },

  textFields: {
    maxWidth: 369
  },
  allDocsContainer: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: "608px",
    background: "#FFFFFF"
  },
  docContainerWrapper: {
    background: "#FAFAFA",
    padding: "20px 20px 22px"
  },
  docContainer: {
    height: 68,
    width: 480,
    backgroundColor: "#E5E9ED",
    borderRadius: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: 20,
    marginBottom: 10
  },
  docImg: {
    height: 40,
    width: 40,
    borderRadius: 8
  },
  docText: {
    fontFamily: "Inter Regular",
    fontWeight: 400,
    fontSize: 16
  },
  docDelete: {
    marginRight: 20,
    marginTop: 10
  },
  country_code_error: {
    fontSize: "0.75rem",
    marginTop: 3,
    textAlign: "left",
    fontFamily: "Inter Regular",
    fontWeight: 400,
    lineHeight: 1.66,
    color: "#E13838"
  },
  comman_label: {
    fontSize: "18px",
    fontFamily: "Inter Regular",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#191919",
    marginBottom: "5px"
  },
  P_F_container: {
    background: "#FAFAFA",
    padding: 20
  },
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    width: 160
  }
}));

const EditEntity = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [docCategories, setDocCategories] = useState([]);
  const [partnerid, setPartnerId] = useState([]);
  const [managedByAdmin, setManagedByAdmin] = useState();
  const [initValues, setInitValues] = useState({
    entityFor: "-",
    firstName: "-",
    entityName: "-",
    address: "-",
    taxId: "-",
    documents: [],
    is_active: false,
    partners: [
      {
        id: "",
        name: "",
        email: "",
        isChecked: true
      }
    ]
  });
  const { entityId, userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();

  const fetchDocCategories = async (type) => {
    let res;
    if (type === "SPONSOR") {
      res = await CommonService.getDocCategories({
        name: "SPONSORENTITY"
      });
    } else {
      res = await CommonService.getDocCategories({
        name: "INVESTORENTITY"
      });
    }
    if (res.success) {
      setDocCategories(
        res?.data[0]?.doccategory?.filter((el) => el.name !== "Misc.")
      );
    } else {
      Toast.showErrorToast("Failed To Load Document Categories");
    }
  };

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      size: el?.document_media?.file_size,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  const fetchEntityDetail = async () => {
    const resp = await EntityService.getEntityDetails([`${entityId}/`]);
    if (resp.success) {
      let entity = resp.data;
      fetchDocCategories(entity?.entity_type);
      let tempData = { ...initValues };
      tempData.entityFor =
        entity?.entity_type?.charAt(0).toUpperCase() +
        entity?.entity_type?.slice(1)?.toLowerCase();
      tempData.firstName = entity?.entity_type_id?.name || "-";

      tempData.entityName = entity?.name;
      tempData.address = entity?.address[0]?.address;
      tempData.documents = formatDocsData(entity.documents);
      tempData.taxId = entity?.tax_id;
      tempData.is_active = entity?.active;
      setManagedByAdmin(entity?.managed_by);

      let partners = [];
      for (let i = 0; i < entity?.partners?.length; i++) {
        let partner = { id: "", name: "", email: "", isChecked: true };
        partner.id = entity.partners[i].id;
        partner.name = entity.partners[i].name;
        partner.email = entity.partners[i].email;
        partners.push(partner);
      }
      tempData.partners = partners;
      setInitValues(tempData);
    }
  };

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  const handleSubmit1 = async (val) => {
    for (let i = 0; i < partnerid.length; i++) {
      if (partnerid[i]) {
        const respo = await EntityService.deletePartners([`${partnerid[i]}/`]);
        if (respo.success) {
          setOpen(false);
          history.push("/u/entities");
        } else {
          Toast.showErrorToast("Partners Delete unsuccessful");
        }
      }
    }

    let partners = val.partners.filter((item) => item.isChecked);
    const payload = {
      entity_type: val.entityFor.toUpperCase(),
      name: val.entityName,
      address: val.address,
      documents: val?.documents?.map((doc) => ({
        document_media_id: doc.id,
        category_id: doc.type,
        ...(doc.docId ? { id: doc.docId } : {})
      })),
      active: val.is_active,
      partners: partners.map((item) => ({ name: item.name, email: item.email }))
    };
    const resp = await EntityService.editEntity(payload, [`${entityId}/`]);
    if (resp.success) {
      setOpen(false);
      history.goBack();
      Toast.showSuccessToast("Entity Details Updated Successfully");
    } else {
      Toast.showErrorToast(resp.error.message[0].name[0]);
    }
  };

  const setDocs = (formik, docsList) => {
    formik.setFieldValue("documents", docsList);
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "ENTITY",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_update) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    checkPermissions();
    fetchEntityDetail();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        className={classes.root}
        style={{
          backgroundColor: "rgba(235, 239, 255, 0.6)",
          backdropFilter: "blur(26)"
        }}>
        <DialogContent>
          <div className={classes.MaterialForm}>
            <div className={classes.header}>
              <div>
                <Typography variant="h4" className={classes.heading}>
                  Edit Entity
                </Typography>
              </div>
              <div className={classes.closeButton}>
                <div className={classes.addedByText}>
                  Managed By:
                  <span style={{ fontWeight: 600 }}>
                    {managedByAdmin === "Admin" ? "Admin" : "Investor"}
                  </span>{" "}
                </div>
                <img alt="close" src={CloseIcon} onClick={handleClose} />
              </div>
            </div>

            <Formik
              initialValues={initValues}
              validationSchema={validationSchemaEditEntity}
              enableReinitialize={true}
              className={classes.form}
              onSubmit={(values) => {
                handleSubmit1(values);
              }}>
              {(formik) => (
                <Form>
                  <EntityForm formik={formik} classes={classes} />

                  <div className={classes.nameContainer}>
                    <label className={classes.comman_label}>Partners</label>

                    <FieldArray
                      name="partners"
                      render={(arrayHelper) => (
                        <Grid
                          container
                          item
                          xs={12}
                          className={classes.P_F_container}>
                          {formik.values.partners.map((partner, index) => (
                            <>
                              <PartnerForm
                                id={index}
                                setPartnerId={setPartnerId}
                                key={index}
                                arrayHelper={arrayHelper}
                                formik={formik}
                                styles={classes}
                              />
                            </>
                          ))}
                          <Box height={12} />
                          <div
                            className={classes.addMore}
                            onClick={() =>
                              arrayHelper.push({
                                name: "",
                                email: "",
                                isChecked: true
                              })
                            }>
                            <img
                              src={AddIcon}
                              alt="add"
                              style={{ marginRight: 8 }}
                            />
                            <div>Add More</div>
                          </div>
                        </Grid>
                      )}
                    />
                  </div>

                  <Box height={20} />
                  <label className={classes.comman_label}>
                    Upload Documents
                  </label>
                  <Box height={10} />
                  <div style={{ backgroundColor: "#FAFAFA", padding: "10px" }}>
                    <NewCategoryDocTable
                      sponDocCategories={docCategories}
                      listData={formik.values.documents}
                      setListData={(docs) => setDocs(formik, docs)}
                    />
                  </div>

                  <Box height={32} />
                  <div className={classes.buttonContainer}>
                    <button
                      className={styles.cancelbtn}
                      style={{ width: 233, marginRight: 10 }}
                      type="button"
                      onClick={handleClose}>
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={styles.updatebtn}
                      style={{ width: 233, margin: 0 }}
                      onClick={formik.handleSubmit}>
                      Update
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default EditEntity;
