import { Grid, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { truncate } from "helpers/Common";
import styles from "../EntityDetail.module.scss";
import ToolTipImage from "../../../../../../src/assets/icons/Group.svg";
import React from "react";
import OverviewToolTip from "./OverviewToolTip";

const useStyles = makeStyles(() => ({
  dataValue: {
    fontFamily: "Inter Regular !important",
    fontSize: "16px",
    fontWeight: 600,
    color: "#191919 !important"
  },
  managedBy: {
    fontSize: "14px !important",
    fontWeight: "400 !important",
    color: "#191919 !important",
    paddingBottom: "10px"
  }
}));

function GeneralInformation({
  heading,
  value,
  headingColor = "#BBBBBB",
  toolData = [],
  managedBy
}) {
  const classes = useStyles();

  return (
    <>
      <Grid xs={12} sm={4} md={2} xl={1} style={{ paddingBottom: "15px" }}>
        <Grid style={{ color: headingColor }} container>
          <Grid style={{ width: "auto" }} item>
            <Typography className={styles.GeneralInfoHeading}>
              {heading}
            </Typography>
          </Grid>
          <Grid style={{ paddingLeft: "10px" }} item>
            {heading == "Current Value" ? (
              <Tooltip
                placement="top-start"
                title=<OverviewToolTip
                  data={[
                    ["Net Equity : ", toolData[0]],
                    ["Total Distribution Amount : ", toolData[1]]
                  ]}
                />
                arrow>
                <Grid className={styles.toolTip}>
                  <img
                    src={ToolTipImage}
                    alt="cross"
                    width={19.2}
                    height={20.2}
                  />
                </Grid>
              </Tooltip>
            ) : null}
          </Grid>
        </Grid>

        <Grid>
          <Typography className={classes.dataValue}>
            {truncate(value, 13)}
          </Typography>
          {heading === "Sponsor Name" ? (
            <Typography className={classes.managedBy}>
              {`(Managed By: ${managedBy})`}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default GeneralInformation;
