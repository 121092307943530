import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import ContactsTable from "components/Common/ContactsTable";
import Illustrations from "components/illustration/Illustrations";
import HeadingContainer from "../../component/HeadingContainer";
import noContacts from "assets/emptyState/noContacts.svg";
import noManageData from "assets/emptyState/noManageData.svg";
import noSponsorTiers from "assets/emptyState/NoSponsoeTiers.svg";
import K1Table from "components/Common/K1Table";
import DocumentsUpload from "components/DocumentsUpload/documentsUpload";
import SponsorTable from "pages/private/investments/addInvestment/steps/step7/NewComponents/SponsorTable";
import formatDate from "helpers/formatDate";
import OverViewNewTable from "./OverViewNewTable";
import EquityInfo from "./EquityInfo";
import formatCurrency from "helpers/formatCurrency";

const OverViewSection = ({ data }) => {
  const [Sponsors, setSponsors] = useState([]);
  const [SpoTiers, setSponTiers] = useState([]);
  const [NOI, setNOI] = useState([]);

  const formatDocsData = (docsList) => {
    return docsList.map((el) => ({
      name: el?.document_media?.name,
      type: el?.category?.id,
      link: el?.document_media?.file,
      id: el?.document_media?.id,
      docId: el.id
    }));
  };

  function getSponsorArray(item) {
    const arrOf_SpoArr = [];

    item?.forEach((ele) => {
      let spo_arr = [];
      spo_arr.push({ id: "1", val: ele?.sponsor_detail?.name });
      spo_arr.push({ id: "2", val: ele?.sponsor_detail?.email });
      spo_arr.push({ id: "3", val: ele?.sponsor_detail?.company_name });
      spo_arr.push({
        id: "4",
        val: `${ele?.sponsor_detail?.country_code} ${ele?.sponsor_detail?.phone_number}`
      });
      spo_arr.push({ id: "5", val: ele?.entity?.tax_id });
      spo_arr.push({ id: "6", val: ele?.entity?.name });
      spo_arr.push({ id: "7", val: ele?.address?.[0]?.address });

      arrOf_SpoArr.push(spo_arr);
      spo_arr = [];
    });

    return arrOf_SpoArr;
  }

  function getSponsorTiers(item) {
    const arrOf_SpoArr = [];

    item?.forEach((ele, i) => {
      let spo_arr = [];
      spo_arr.push({ id: "1", val: `Tier ${i + 1}` });
      spo_arr.push({ id: "2", val: `${ele?.startfrom || "-"}%` });
      spo_arr.push({ id: "3", val: `${ele?.to || "-"}%` });
      spo_arr.push({ id: "4", val: `${ele?.promote || "-"}%` });

      arrOf_SpoArr.push(spo_arr);
      spo_arr = [];
    });

    return arrOf_SpoArr;
  }

  function getNOI(item) {
    const arrOf_SpoArr = [];

    item?.forEach((ele) => {
      let spo_arr = [];
      spo_arr.push({ id: "1", val: `${ele?.year || "-"}` });
      spo_arr.push({ id: "2", val: `${ele?.noi_cadence || "-"}` });
      spo_arr.push({
        id: "3",
        val: `${
          ele?.investment_noi ? formatCurrency(ele?.investment_noi) : "-"
        }`
      });
      spo_arr.push({ id: "4", val: `${formatDate(ele?.created_at) || "-"}` });
      spo_arr.push({
        id: "5",
        val: (
          <DocumentsUpload
            editable={false}
            title="NOI"
            listData={formatDocsData(ele?.documents)}
          />
        )
      });

      arrOf_SpoArr.push(spo_arr);
      spo_arr = [];
    });

    return arrOf_SpoArr;
  }

  useEffect(() => {
    setSponsors(getSponsorArray(data?.sponsor_data));
    setSponTiers(getSponsorTiers(data?.sponsor_tier));
    setNOI(getNOI(data?.inv_noi));
  }, [data]);
  return (
    <Grid
      container
      spacing={4}
      style={{ marginTop: "20px", marginBottom: "54px" }}>
      {/* overView */}
      <Grid item xs={12}>
        <HeadingContainer Title="Overview" border>
          <OverViewNewTable data={data} />
        </HeadingContainer>
      </Grid>

      {/* Equity Information */}
      <Grid item xs={12}>
        <HeadingContainer Title="Equity Information" border>
          <EquityInfo data={data} />
        </HeadingContainer>
      </Grid>

      {/*Sponsors */}
      <Grid item xs={12}>
        <HeadingContainer Title="Sponsors" dropShadow>
          <SponsorTable
            Headingdata={[
              { heading: "Sponsor Name" },
              { heading: "Email ID" },
              { heading: "Company Name" },
              { heading: "Contact Number" },
              { heading: "Tax ID (EIN)" },
              { heading: "Entity Name" },
              { heading: "Mailing Address" }
            ]}
            mapArray={Sponsors}
          />
        </HeadingContainer>
      </Grid>

      <Grid container spacing={3} item xs={12}>
        <Grid item lg={6} md={12} xs={12}>
          {/* SponsorTiers */}
          <HeadingContainer
            Title="Sponsor Tiers"
            dropShadow={SpoTiers.length ? true : false}>
            {SpoTiers.length ? (
              <SponsorTable
                width={"714px"}
                Headingdata={[
                  { heading: "Tiers" },
                  { heading: "From" },
                  { heading: "To" },
                  { heading: "Promote" }
                ]}
                mapArray={SpoTiers}
              />
            ) : (
              <Grid item xs={12}>
                <Illustrations
                  src={noSponsorTiers}
                  subHeading={"No added Tiers."}
                />
              </Grid>
            )}
          </HeadingContainer>
        </Grid>

        <Grid item lg={6} md={12} xs={12}>
          {/* Net Operating Income */}
          <HeadingContainer
            Title="Net Operating Income"
            dropShadow={NOI.length ? true : false}>
            {NOI.length ? (
              <SponsorTable
                width={"714px"}
                Headingdata={[
                  { heading: "Year" },
                  { heading: "Tenure" },
                  { heading: "Amount" },
                  { heading: "Update Date" },
                  { heading: "Documents" }
                ]}
                mapArray={NOI}
              />
            ) : (
              <Grid item xs={12}>
                <Illustrations
                  src={noSponsorTiers}
                  subHeading={`No added Nois.`}
                />
              </Grid>
            )}
          </HeadingContainer>
        </Grid>
      </Grid>

      {/* K1s */}
      <Grid item xs={12}>
        <HeadingContainer
          Title="K1s"
          dropShadow={data?.manage_data?.length ? true : false}>
          {data?.manage_data?.length ? (
            <K1Table
              Headingdata={[
                { heading: "Investor" },
                { heading: "Year" },
                { heading: "Notes" },
                { heading: "Documents" }
              ]}
              mapArray={data?.manage_data}
            />
          ) : (
            <Grid item xs={12}>
              <Illustrations src={noManageData} subHeading={`No added K1s.`} />
            </Grid>
          )}
        </HeadingContainer>
      </Grid>

      {/* Contacts */}
      <Grid item xs={12}>
        <HeadingContainer
          Title="Contacts"
          dropShadow={data?.contacts?.length ? true : false}>
          {data?.contacts?.length ? (
            <ContactsTable
              Headingdata={[
                { heading: "Contact Name" },
                { heading: "Company Name" },
                { heading: "Email ID" },
                { heading: "Contact Number" },
                { heading: "Designation" },
                { heading: "Primary Contact" }
              ]}
              mapArray={data?.contacts}
            />
          ) : (
            <Grid item xs={12}>
              <Illustrations
                src={noContacts}
                subHeading={`No added Contacts.`}
              />
            </Grid>
          )}
        </HeadingContainer>
      </Grid>
    </Grid>
  );
};

export default OverViewSection;
