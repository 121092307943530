import React, { useState } from "react";
import styles from "./forgotPassword.module.scss";
import { useFormik } from "formik";
import {
  validationSchemaSendOtp,
  validationSchemaVerifyOtp,
  validationSchemaChangePassword
} from "../../../helpers/validationSchema";
import SendOTP from "./components/SendOTP";
import VerifyOtp from "./components/VerifyOtp";
import ChangePassword from "./components/ChangePassword";
import { useHistory } from "react-router-dom";
import { NetworkManager, API } from "../../../network/core/index";
import Toast from "../../../components/toast";
import Loader from "../../../components/Loader/AppLoader";

const ForgotPassword = () => {
  let navigate = useHistory();
  const [activeState, setActiveState] = useState({
    sendOtp: true,
    verifyOtp: false,
    changePassword: false
  });
  const [loading, setLoading] = useState(false);
  const [otpResult, setOtpResult] = useState([]);
  const [email, setEmail] = useState("");
  const [otps, setOtps] = useState("");
  const formikForSendOtp = useFormik({
    initialValues: {
      email: ""
    },
    validationSchema: validationSchemaSendOtp,
    onSubmit: async (values) => {
      setLoading(true);
      setEmail(values);
      const payload = {
        email: values.email,
        is_admin: true
      };
      const instance = new NetworkManager(API.AUTH.FORGOT_PASSWORD, payload);
      const resp = await instance.httpRequest();
      if (resp.success) {
        Toast.showSuccessToast("OTP Sent Successfully On Email");
        setLoading(false);
        setActiveState((prev) => ({
          ...prev,
          sendOtp: false,
          verifyOtp: true
        }));
      } else {
        Toast.showErrorToast(resp.error.message[0]);
        setLoading(false);
      }
    }
  });

  const handleChange = (otp) => {
    setOtps(otp);
  };

  const formikForVerifyOtp = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: formikForSendOtp.values.email,
      otp: otps
    },
    validationSchema: validationSchemaVerifyOtp,
    onSubmit: async (values) => {
      const payload = {
        email: values.email,
        otp: values.otp
      };

      setOtpResult(payload);
      const instance = new NetworkManager(API.AUTH.OTP_VERFICATION, payload);
      const resp = await instance.httpRequest();
      if (resp.success) {
        Toast.showSuccessToast("OTP Is Verified");
        setActiveState((prev) => ({
          ...prev,
          verifyOtp: false,
          changePassword: true
        }));
      } else {
        Toast.showErrorToast("OTP Is Not Valid");
      }
    }
  });

  const resendOTP = async () => {
    setLoading(true);
    const payload = {
      email: email.email,
      is_admin: true
    };
    const instance = new NetworkManager(API.AUTH.FORGOT_PASSWORD, payload);
    const resp = await instance.httpRequest();
    if (resp.success) {
      Toast.showSuccessToast("OTP Sent Successfully");
      setLoading(false);
      return true;
    } else {
      Toast.showErrorToast("OTP Not Send");
      return false;
    }
  };

  const formikForChangePassword = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: ""
    },
    validationSchema: validationSchemaChangePassword,
    onSubmit: async (values) => {
      const payload = {
        otp: otpResult.otp,
        email: otpResult.email,
        new_password: values.newPassword,
        retype_password: values.confirmPassword,
        is_admin: true
      };

      const instance = new NetworkManager(API.AUTH.RESET_PASSWORD, payload);
      const resp = await instance.httpRequest();
      if (resp.success) {
        Toast.showSuccessToast("Password Reset Successfully");
        navigate.replace("/auth/login");
      } else {
        Toast.showErrorToast("OTP Is Not Valid");
      }
    }
  });

  const verifyOtp = () => {
    formikForVerifyOtp.handleSubmit();
  };

  const sendOtp = () => {
    formikForSendOtp.handleSubmit();
  };

  const changePassword = () => {
    formikForChangePassword.handleSubmit();
  };

  return (
    <div className={styles.container}>
      {activeState.sendOtp && (
        <>
          {loading ? (
            <Loader />
          ) : (
            <SendOTP formik={formikForSendOtp} handlePress={sendOtp} />
          )}
        </>
      )}
      {activeState.verifyOtp && (
        <VerifyOtp
          formik={formikForVerifyOtp}
          handlePress={verifyOtp}
          handleChange={handleChange}
          resendOTP={resendOTP}
          loadings={loading}
        />
      )}
      {activeState.changePassword && (
        <ChangePassword
          formik={formikForChangePassword}
          handlePress={changePassword}
        />
      )}
    </div>
  );
};

export default ForgotPassword;
