import React, { useState, useEffect } from "react";
import { makeStyles, Grid, Box } from "@material-ui/core";
import AddIcons from "../../../../../../../assets/icons/addIcon.svg";
import collapseIconRoundRed from "../../../../../../../assets/icons/collapseIconRoundRed.svg";
import { FieldArray } from "formik";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import CustomSelect from "components/StyledComponents/Select";
import DescriptionBox from "components/StyledComponents/DescriptionBox";
import Toast from "components/toast";
import RedDelete from "../../../../../../../assets/icons/NewCardSvg/RedDelete.svg";
import { AddInvestmentInvestorService } from "network/addInvestmentInvestorsService";
import { preventNonNumericalInput } from "helpers";
import { CommonService } from "network/commonService";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import ImageUpload from "../../../../../properties/component/ImageUpload/ImageUpload";
import NewCategoryDocTable from "../../../../../properties/component/NewCategoryDocTable";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import FieldHeading from "components/fieldHeading";
import PropertyNOI from "./PropertyNOI";
import DeleteModal from "components/Modals/DeleteModal";
import { PropertiesService } from "network/propertiesService";

const useStyles = makeStyles(() => ({
  addMore: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    marginTop: 31.5
  },
  headingContainer: {
    display: "flex",
    alignItems: "center",
    paddingRight: 10,
    marginTop: 20
  },
  heading: {
    fontFamily: "Inter Regular",
    fontSize: 16,
    fontWeight: 700,
    letterSpacing: 0,
    color: "#616161",
    flexShrink: 0
  },
  grayContainer: {
    backgroundColor: "#FAFAFA",

    width: "100%",
    borderRadius: 12
  },
  deleteBtn: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#E13838",
    marginRight: 10,
    cursor: "pointer"
  }
}));

const StepOneProperties = ({
  formik,
  index,
  arrayHelper,
  ClickonPropertyEdit
}) => {
  const classes = useStyles();
  const [addMore, setAddMore] = useState(false);
  const [propertyTypeOptions, setPropertyTypeOptions] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [noiCadenceChoices, setNoiCadenceChoices] = useState([]);
  const [quarterChoices, setQuarterChoices] = useState([]);
  const [unitOptions, setUnitOptions] = useState([]);
  const [displayImage, setDisplayImage] = useState([]);
  const [docCategories, setDocCategories] = useState([]);
  const [noiCategories, setNoiCategories] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);

  const checkDelete = () => {
    if (formik.values.properties[index].id) {
      setDeleteModal(true);
    } else {
      arrayHelper.remove(index);
    }
  };

  const handleDeleteProperty = async (propertiesId) => {
    const res = await PropertiesService.deleteProperty([`${propertiesId}/`]);
    if (res.success) {
      Toast.showSuccessToast(res.data.message);
      setDeleteModal(false);
      arrayHelper.remove(index);
    } else {
      Toast.showErrorToast(res.error.message);
      setDeleteModal(false);
    }
  };

  const setDocData = (data, formik) => {
    formik.setFieldValue(`properties[${index}].documents`, data);
  };

  const loadCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTPROPERTY"
    });
    if (res.success) {
      res.data.length && setDocCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const loadNoiCategories = async () => {
    const res = await AddInvestmentInvestorService.getDocCategories({
      name: "INVESTMENTPROPERTYNOI"
    });
    if (res.success) {
      res.data.length && setNoiCategories(res.data[0]?.doccategory);
    } else {
      Toast.showErrorToast(`Failed To Load Document Categories`);
    }
  };

  const getOptions = async () => {
    const res = await AddInvestmentBasicDetailsService.dropdownOptions();
    if (res.success) {
      setPropertyTypeOptions(res.data.PROPERTY_TYPE);
      setStatusOptions(res.data.STATUSES);
      setUnitOptions(res.data.UNITS);
      setNoiCadenceChoices(res.data.NOI_CADENCE_CHOICES);
      setQuarterChoices(res.data.QUARTER_CHOICES);
    } else {
      Toast.showErrorToast("Unable To Load Options");
    }
  };

  const setpropertiesImageToFromik = () => {
    let ids = [];
    ids = displayImage?.map((ele) => ele.id);
    formik.setFieldValue(`properties[${index}].images_list`, ids);
  };

  useEffect(() => {
    setpropertiesImageToFromik();
  }, [displayImage]);

  useEffect(() => {
    loadCategories();
    loadNoiCategories();
    getOptions();
    setDisplayImage(formik.values?.properties?.[index]?.images);
  }, []);

  const handleImageDelete = async (id) => {
    const resp = await CommonService.deleteMedia(id);

    if (resp.success) {
      Toast.showSuccessToast("Image Deleted Successfully");
      setDisplayImage((prev) => {
        return prev.filter((item) => item && item.id != id);
      });
    } else {
      Toast.showErrorToast("Failed to Delete Image");
    }
  };

  return (
    <>
      <div style={index !== 0 ? { marginTop: "30px" } : {}}>
        <Grid container item xs={12}>
          <Grid container className={classes.grayContainer}>
            <Grid container spacing={2}>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Property Name" />
                <AppTextFieldWhite
                  disabled={!ClickonPropertyEdit}
                  placeholder="Property Name"
                  id="propertyName"
                  onChange={formik.handleChange(`properties[${index}].name`)}
                  variant="standard"
                  fullWidth
                  value={formik.values.properties[index].name}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    maxLength: 80
                  }}
                  error={
                    formik.touched?.properties?.[index]?.name &&
                    Boolean(formik.errors?.properties?.[index]?.name)
                  }
                  helperText={
                    formik.touched?.properties?.[index]?.name &&
                    formik.errors?.properties?.[index]?.name
                  }
                />
              </Grid>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Property Type (Optional)" />
                <CustomSelect
                  disabled={!ClickonPropertyEdit}
                  style={{ backgroundColor: "#fff" }}
                  fullWidth
                  items={propertyTypeOptions}
                  onChange={formik.handleChange(`properties[${index}].type`)}
                  value={formik.values.properties[index].type}
                  //   error={formik.errors.type}
                  //   touched={formik.touched.type}
                />
              </Grid>
              <Grid item xl={3} lg={4} sm={6}>
                <FieldHeading title="Address" />
                <AppTextFieldWhite
                  disabled={!ClickonPropertyEdit}
                  id="propertyAddress"
                  placeholder="Address"
                  variant="standard"
                  fullWidth
                  value={
                    formik.values.properties?.[index]?.address?.[0]?.address
                  }
                  onChange={formik.handleChange(
                    `properties[${index}].address[0].address`
                  )}
                  InputLabelProps={{
                    shrink: true
                  }}
                  InputProps={{
                    disableUnderline: true
                  }}
                  inputProps={{
                    maxLength: 180
                  }}
                  error={
                    formik.touched?.properties?.[index]?.address?.[0]
                      ?.address &&
                    Boolean(
                      formik.errors?.properties?.[index]?.address?.[0]?.address
                    )
                  }
                  helperText={
                    formik.touched?.properties?.[index]?.address?.[0]
                      ?.address &&
                    formik.errors?.properties?.[index]?.address?.[0]?.address
                  }
                />
              </Grid>
            </Grid>
          </Grid>

          {addMore && (
            <Grid container style={{ marginTop: "30px" }}>
              <Grid container spacing={2}>
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Property Value ($)" />
                  <AppTextFieldWhite
                    disabled={!ClickonPropertyEdit}
                    id="propertyValue"
                    placeholder="Property Value"
                    numFormat={true}
                    variant="standard"
                    fullWidth
                    onKeyDown={preventNonNumericalInput}
                    value={formik.values.properties[index].value}
                    onChange={formik.handleChange(`properties[${index}].value`)}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      disableUnderline: true
                    }}
                    inputProps={{
                      maxLength: 12
                    }}
                  />
                </Grid>
                <Grid item xl={3} lg={4} sm={6}>
                  <FieldHeading title="Status" />
                  <CustomSelect
                    disabled={!ClickonPropertyEdit}
                    style={{ backgroundColor: "#fff" }}
                    fullWidth
                    items={statusOptions}
                    value={formik.values.properties[index].status}
                    onChange={formik.handleChange(
                      `properties[${index}].status`
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} direction="column">
                <div className={classes.headingContainer}>
                  <div className={classes.heading}>Upload upto 5 images</div>
                </div>
              </Grid>

              <Grid container className={classes.grayContainer}>
                <Grid container spacing={2} style={{ padding: 20 }}>
                  <ImageUpload
                    index={index}
                    displayImage={displayImage}
                    setDisplayImage={setDisplayImage}
                    handleDelete={handleImageDelete}
                  />
                </Grid>
              </Grid>

              <Grid container xs={12} direction="column">
                <div className={classes.headingContainer}>
                  <div className={classes.heading}>Property Overview</div>
                </div>
              </Grid>

              <Grid container className={classes.grayContainer}>
                <Grid container spacing={2} style={{ padding: 20 }}>
                  <Grid item xl={3} lg={4} sm={6}>
                    <FieldHeading title="Zip Code" />
                    <AppTextFieldWhite
                      disabled={!ClickonPropertyEdit}
                      id="zip"
                      placeholder="Add Zip Code"
                      variant="standard"
                      fullWidth
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disableUnderline: true
                      }}
                      inputProps={{
                        maxLength: 20
                      }}
                      value={
                        formik.values.properties[index]?.address?.[0]?.zip_code
                      }
                      onChange={formik.handleChange(
                        `properties[${index}].address[0].zip_code`
                      )}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} sm={6}>
                    <FieldHeading title="Area" />
                    <Box sx={{ display: "inline-flex" }}>
                      <CustomSelect
                        disabled={!ClickonPropertyEdit}
                        id="units"
                        style={{ backgroundColor: "white" }}
                        placeholder="Units"
                        items={unitOptions}
                        value={formik.values.properties[index].units}
                        onChange={formik.handleChange(
                          `properties[${index}].units`
                        )}
                      />
                      <AppTextFieldWhite
                        disabled={!ClickonPropertyEdit}
                        style={{ paddingLeft: "12px" }}
                        id="area"
                        placeholder="Add Area Covered"
                        variant="standard"
                        fullWidth
                        InputLabelProps={{
                          shrink: true
                        }}
                        InputProps={{
                          disableUnderline: true
                        }}
                        inputProps={{
                          maxLength: 50
                        }}
                        numFormat={true}
                        value={formik.values.properties[index].area}
                        onChange={formik.handleChange(
                          `properties[${index}].area`
                        )}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={3} lg={4} sm={6}>
                    <FieldHeading title="Occupancy (%)" />
                    <AppTextFieldWhite
                      disabled={!ClickonPropertyEdit}
                      id="occupancy"
                      placeholder="Occupancy"
                      variant="standard"
                      fullWidth
                      onKeyDown={preventNonNumericalInput}
                      value={formik.values.properties[index].occupancy}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disableUnderline: true
                      }}
                      inputProps={{ maxLength: 3 }}
                      onChange={formik.handleChange(
                        `properties[${index}].occupancy`
                      )}
                    />
                  </Grid>
                  <Grid item xl={3} lg={4} sm={6}>
                    <FieldHeading title="Year Build" />
                    <SelectWithInfiniteScroll
                      disabled={!ClickonPropertyEdit}
                      id="yearBuild"
                      style={{ backgroundColor: "#fff" }}
                      fullWidth
                      minValue={2000}
                      selectedItem={
                        formik.values.properties[index].build_year ?? ""
                      }
                      onChange={(value) =>
                        formik.setFieldValue(
                          `properties[${index}].build_year`,
                          value
                        )
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container xs={12} direction="column">
                <div className={classes.headingContainer}>
                  <div className={classes.heading}>Description</div>
                </div>
              </Grid>

              <Grid container className={classes.grayContainer}>
                <Grid
                  container
                  xs={12}
                  style={{
                    paddingLeft: 20,
                    paddingTop: 20,
                    paddingBottom: 20
                  }}
                  spacing={2}>
                  <Grid item xs={12}>
                    <FieldHeading title="Add Property Description" />
                    <DescriptionBox
                      disabled={!ClickonPropertyEdit}
                      id="description"
                      placeholder="Add Description"
                      variant="standard"
                      fullWidth
                      rows={4}
                      multiline
                      value={formik.values.properties[index].description}
                      onChange={formik.handleChange(
                        `properties[${index}].description`
                      )}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        disableUnderline: true
                      }}
                      inputProps={{
                        maxLength: 100
                      }}
                    />
                    <Grid
                      container
                      style={{ color: "#589F11" }}
                      justifyContent="flex-end">
                      {`${
                        formik.values.properties[index].description?.length || 0
                      }/100`}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <div className={classes.headingContainer}>
                      <div className={classes.heading}>
                        Net Operating Income
                      </div>
                    </div>
                  </Grid>
                  <Grid container className={classes.grayContainer}>
                    <Grid container style={{ padding: 20 }} spacing={2}>
                      <FieldArray
                        name={`properties[${index}].noi`}
                        render={(arrayHelper) => (
                          <Grid container direction="column" spacing={2}>
                            <Grid item>
                              {formik.values?.properties[index]?.noi?.map(
                                (noi, i) => (
                                  <Box key={i} style={{ margin: "16px" }}>
                                    <PropertyNOI
                                      customStyle={{
                                        backgroundColor: "#fff",
                                        padding: 20,
                                        borderRadius: 8,
                                        margin: "0px 20px"
                                      }}
                                      cadenceChoices={noiCadenceChoices}
                                      quarterChoices={quarterChoices}
                                      id={i}
                                      parentIndex={index}
                                      noi={noi}
                                      arrayHelper={arrayHelper}
                                      formik={formik}
                                      noiCategories={noiCategories}
                                      ClickonPropertyEdit={ClickonPropertyEdit}
                                    />
                                  </Box>
                                )
                              )}
                            </Grid>

                            <div
                              className={classes.addMore}
                              onClick={
                                ClickonPropertyEdit
                                  ? () =>
                                      arrayHelper.push({
                                        property_noi: "",
                                        noi_cadence: "",
                                        noi_quarter: "",
                                        year: "",
                                        documents: []
                                      })
                                  : () => {}
                              }>
                              <img
                                src={AddIcons}
                                alt="add"
                                style={{ marginRight: 8 }}
                              />
                              Add NOI
                            </div>
                          </Grid>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="column">
                    <div className={classes.headingContainer}>
                      <div className={classes.heading}>Upload Documents</div>
                    </div>
                  </Grid>
                  <Grid container className={classes.grayContainer}>
                    <Grid container style={{ padding: 20 }} spacing={2}>
                      <NewCategoryDocTable
                        sponDocCategories={docCategories.filter(
                          (el) => el.name !== "Misc."
                        )}
                        listData={formik.values.properties[index].documents}
                        setListData={(list) => {
                          setDocData(list, formik);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}

          {ClickonPropertyEdit && (
            <Grid container justifyContent="space-between">
              <div
                className={classes.addMore}
                onClick={
                  ClickonPropertyEdit ? () => setAddMore(!addMore) : () => {}
                }>
                <img
                  src={addMore ? collapseIconRoundRed : AddIcons}
                  alt="add/Collapse"
                  style={{ marginRight: 8 }}
                />
                {addMore ? (
                  <div style={{ color: "#E13838" }}>Collapse</div>
                ) : (
                  <div>Add Details</div>
                )}
              </div>
              <div
                className={`${classes.deleteBtn} ${classes.addMore}`}
                onClick={ClickonPropertyEdit ? checkDelete : () => {}}>
                <img src={RedDelete} alt="delete" />
              </div>
            </Grid>
          )}
        </Grid>
      </div>
      <DeleteModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={() =>
          handleDeleteProperty(formik.values.properties[index].id)
        }
        title="Are You Sure You Want To Delete This Item?"
        description="This will permanently remove all data associated with it from Simplee."
      />
    </>
  );
};

export default StepOneProperties;
