import { API, NetworkManager } from "./core";

export class AddInvestmentBasicDetailsService {
  static async getSponsors() {
    const instance = new NetworkManager(API.ADD_INVESTMENT_BASIC.GET_SPONSORS);
    return await instance.httpRequest();
  }

  static async getSponByRole(params) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_BASIC.SPON_LIST_BY_ROLE,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getSponsorEntities(payload) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_BASIC.GET_SPONSOR_ENTITY,
      {},
      { entity_type_id: payload }
    );
    return await instance.httpRequest();
  }
  static async addInvestment(payload) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT.ADD_INVESTMENT_POST,
      payload
    );
    return await instance.httpRequest();
  }
  static async dropdownOptions() {
    const instance = new NetworkManager(API.DROPDOWN_OPTIONS.OPTIONS);
    return await instance.httpRequest();
  }

  static async deleteNOI(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_BASIC.DELETE_NOI,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
  static async deleteContacts(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_BASIC.DELETE_CONTACTS,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
  static async deleteTiers(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_BASIC.DELETE_TIERS,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
  static async deleteSponsors(id) {
    const instance = new NetworkManager(
      API.ADD_INVESTMENT_BASIC.DELETE_SPONSORS,
      {},
      [`${id}/`]
    );
    return await instance.httpRequest();
  }
}
