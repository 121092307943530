import FieldHeading from "../../../../../../../components/fieldHeading";
import React, { useEffect, useState } from "react";
import { makeStyles, Grid } from "@material-ui/core";
import AppTextFieldWhite from "../../../../../../../components/StyledComponents/AppTextFieldWhite";
import CustomSelect from "../../../../../../../components/StyledComponents/Select";
import Calender from "../../../../../../../components/calender";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import { AddInvestmentDistributionService } from "network/addInvestmentDistributionService";
import Toast from "components/toast";
import { preventNonNumericalInput } from "helpers";
import DeleteModal from "components/Modals/DeleteModal";
import DescriptionBox from "components/StyledComponents/DescriptionBox";
import RedDelete from "assets/icons/NewCardSvg/RedDelete.svg";
import NewCategoryDocTable from "pages/private/properties/component/NewCategoryDocTable";
import { DistributionService } from "network/distributionService";
import EditIcon from "assets/icons/EditIcon.svg";

const useStyles = makeStyles(() => ({
  root: {
    padding: 20,
    paddingBottom: 0,
    borderRadius: 8,
    marginBottom: 20,
    background: "#FAFAFA"
  },
  Headings: {
    fontFamily: "Inter Regular",
    fontSize: 16,
    fontWeight: 700,
    lineHeight: "19px",
    color: "#616161",
    margin: "42px 0px"
  },
  deleteButton: {
    padding: 8,
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    color: "#E13838",
    cursor: "pointer"
  }
}));

const Distribution = ({
  id,
  investmentID,
  formik,
  arrayHelper,
  investmentDetail,
  docCategories,
  desc,
  dropDownOpRes,
  isedit
}) => {
  const classes = useStyles();

  const [investors, setInvestors] = useState([]);
  const [CadenceData, setCadenceData] = useState({
    MONTH_CHOICES: [],
    QUARTER_CHOICES: []
  });
  const [distributionType, setDistributionType] = useState([]);
  const [cadenceSelected, setCadenceSelected] = useState("monthly");
  const [distYear, setDistYear] = useState(false);
  const [distmonth, setDistMonth] = useState(2);
  const [distTimeframeList, setDistTimeframeList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [distEdit, setDisEdit] = useState(false);

  const checkDelete = () => {
    if (formik.values.distributionSet[id].id) {
      setDeleteModal(true);
    } else {
      arrayHelper.remove(id);
    }
  };

  const distributionErrors =
    (formik.errors.distributionSet &&
      formik.errors.distributionSet.length &&
      formik.errors.distributionSet[id]) ||
    {};
  const distributionTouched =
    (formik.touched.distributionSet &&
      formik.touched.distributionSet.length &&
      formik.touched.distributionSet[id]) ||
    {};

  const handleDelete = async () => {
    const res = await AddInvestmentDistributionService.deleteDistribution(
      formik.values.distributionSet[id].id
    );
    if (res.success) {
      setDeleteModal(false);
      arrayHelper.remove(id);
    } else {
      setDeleteModal(false);
      return Toast.showErrorToast(res.error.message[0]);
    }
  };

  const setTotalDistributionData = (amount) => {
    formik.setFieldValue(`distributionSet[${id}].distribution_amount`, amount);
  };

  const setDate = (date) => {
    formik.setFieldValue(`distributionSet[${id}].distribution_date`, date);
  };

  const selectYear = (value) => {
    formik.setFieldValue(`distributionSet[${id}].distribution_year`, value);
    if (value > distYear) {
      if (cadenceSelected.toLowerCase() === "monthly") {
        setDistTimeframeList([...CadenceData.MONTH_CHOICES]);
      } else {
        setDistTimeframeList([...CadenceData.QUARTER_CHOICES]);
      }
    } else {
      if (cadenceSelected.toLowerCase() === "monthly") {
        setDistTimeframeList(
          CadenceData?.MONTH_CHOICES?.slice(distmonth - 1) || []
        );
      } else {
        setDistTimeframeList(
          CadenceData?.QUARTER_CHOICES?.slice(
            (CadenceData?.MONTH_QUARTER_MAPPING?.length &&
              CadenceData?.MONTH_QUARTER_MAPPING[distmonth - 1]?.label) - 1
          ) || []
        );
      }
    }
  };

  const setDocData = (data) => {
    formik.setFieldValue(`distributionSet[${id}].documents`, data);
  };

  const initDropdownValues = (data, date) => {
    if (formik.values.distributionSet[id].total_distribution) {
      // setTotalDistribution(
      //   formik.values.distributionSet[id].total_distribution
      // );
    }
    if (formik.values.distributionSet[id].distribution_year) {
      if (
        formik.values.distributionSet[id].distribution_year > parseInt(date[0])
      ) {
        if (
          investmentDetail.data.distribution_cadence.toLowerCase() === "monthly"
        ) {
          setDistTimeframeList(data?.MONTH_CHOICES);
        } else {
          setDistTimeframeList(data?.QUARTER_CHOICES);
        }
      } else {
        if (
          investmentDetail.data.distribution_cadence.toLowerCase() === "monthly"
        ) {
          setDistTimeframeList(
            data?.MONTH_CHOICES?.slice(parseInt(date[1]) - 1) || []
          );
        } else {
          setDistTimeframeList(
            data?.QUARTER_CHOICES?.slice(
              (data?.MONTH_QUARTER_MAPPING?.length &&
                data?.MONTH_QUARTER_MAPPING[parseInt(date[1]) - 1]?.label) - 1
            ) || []
          );
        }
      }
    }
    setDistributionType(data?.DISTRIBUTION_TYPE_CHOICES);
    setCadenceData({
      MONTH_CHOICES: data?.MONTH_CHOICES,
      QUARTER_CHOICES: data?.QUARTER_CHOICES,
      MONTH_QUARTER_MAPPING: data?.MONTH_QUARTER_MAPPING
    });
  };

  const SelectInvestor = (item) => {
    const Investor = item.map((ele) => {
      return {
        label: ele.investor_name,
        value: ele.id
      };
    });
    setInvestors(Investor);
  };

  const getInvestorList = async () => {
    let url = "";

    url = `?investment_id=${investmentID}`;

    const response = await DistributionService.getInvestorList([url]);
    if (response.success) {
      SelectInvestor(response.data);
    } else {
      setInvestors([]);
    }
  };

  useEffect(async () => {
    if (investmentDetail.success) {
      setCadenceSelected(investmentDetail.data.distribution_cadence);
      const date = investmentDetail.data.start_date.split("-");
      setDistYear(parseInt(date[0]));
      setDistMonth(parseInt(date[1]));
      if (dropDownOpRes.success) {
        initDropdownValues(dropDownOpRes.data, date);
      }
    }
    getInvestorList();
  }, [investmentDetail]);

  useEffect(() => {
    setDisEdit(formik.values.distributionSet[id].isDisEdit);
  }, [formik.values.distributionSet[id].isDisEdit]);

  return (
    <Grid item xs={12} className={classes.root}>
      <Grid item xs={12}>
        {isedit ? (
          <div
            style={{ cursor: "pointer" }}
            onClick={() =>
              formik.setFieldValue(`distributionSet[${id}].isDisEdit`, true)
            }>
            <img src={EditIcon} alt="edit" />
          </div>
        ) : null}
      </Grid>
      <Grid container spacing={4}>
        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Investor Name" />
          <CustomSelect
            disabled={!distEdit && isedit}
            id={`distributionSet[${id}].investor_name`}
            defaultValue={formik.values.distributionSet[id].investor_name}
            value={formik.values.distributionSet[id].investor_name}
            fullWidth
            items={investors}
            onChange={formik.handleChange(
              `distributionSet[${id}].investor_name`
            )}
            error={distributionErrors.investor_name}
            touched={distributionTouched.investor_name}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Distribution Amount ($)" />
          <AppTextFieldWhite
            disabled={!distEdit && isedit}
            placeholder=""
            variant="standard"
            onKeyDown={preventNonNumericalInput}
            fullWidth
            value={formik.values.distributionSet[id].distribution_amount}
            onChange={(e) => setTotalDistributionData(e.target.value)}
            error={
              distributionTouched.distribution_amount &&
              Boolean(distributionErrors.distribution_amount)
            }
            helperText={
              distributionTouched.distribution_amount &&
              distributionErrors.distribution_amount
            }
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 12 }}
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Distribution Date" />
          <Calender
            disabled={!distEdit && isedit}
            defaultDate={formik.values.distributionSet[id].distribution_date}
            setDate={(date) => setDate(date)}
            error={
              distributionTouched.distribution_date &&
              Boolean(distributionErrors.distribution_date)
            }
            helperText={
              distributionTouched.distribution_date &&
              distributionErrors.distribution_date
            }
          />
        </Grid>

        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Distribution Type" />
          <CustomSelect
            disabled={!distEdit && isedit}
            id={`distributionSet[${id}].distribution_type`}
            // defaultValue={desc.distribution_type}
            value={desc.distribution_type}
            fullWidth
            items={distributionType}
            onChange={formik.handleChange(
              `distributionSet[${id}].distribution_type`
            )}
            error={distributionErrors.distribution_type}
            touched={distributionTouched.distribution_type}
          />
        </Grid>

        {distYear ? (
          <Grid item xl={3} lg={4} sm={6}>
            <FieldHeading title="Distribution Year" />
            <SelectWithInfiniteScroll
              disabled={!distEdit && isedit}
              fullWidth
              minValue={distYear}
              selectedItem={desc.distribution_year}
              error={distributionErrors.distribution_year}
              touched={distributionTouched.distribution_year}
              onChange={(value) => selectYear(value)}
            />
          </Grid>
        ) : null}

        <Grid item xl={3} lg={4} sm={6}>
          <FieldHeading title="Distribution Time Frame" />
          <CustomSelect
            disabled={!distEdit && isedit}
            fullWidth
            items={distTimeframeList}
            defaultValue={
              formik.values.distributionSet[id].distribution_time_frame
            }
            value={formik.values.distributionSet[id].distribution_time_frame}
            error={distributionErrors.distribution_time_frame}
            touched={distributionTouched.distribution_time_frame}
            onChange={formik.handleChange(
              `distributionSet[${id}].distribution_time_frame`
            )}
          />
        </Grid>

        <Grid item xs>
          <FieldHeading title="Notes" />
          <DescriptionBox
            disabled={!distEdit && isedit}
            id="description"
            placeholder="Add Description"
            variant="standard"
            fullWidth
            multiline
            rows={2}
            value={formik.values.distributionSet[id].notes}
            onChange={formik.handleChange(`distributionSet[${id}].notes`)}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{
              disableUnderline: true
            }}
            inputProps={{
              maxLength: 100
            }}
            error={
              distributionTouched.notes && Boolean(distributionErrors.notes)
            }
            helperText={distributionTouched.notes && distributionErrors.notes}
          />
          <Grid
            container
            style={{ color: "#589F11" }}
            justifyContent="flex-end">
            {`${formik.values.distributionSet[id].notes?.length || 0}/100`}
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        item
        style={{
          filter: "drop-shadow(0px 4px 15px rgba(160, 160, 160, 0.15))"
        }}>
        <div className={classes.Headings}>Upload Documents</div>
        <NewCategoryDocTable
          sponDocCategories={docCategories}
          listData={formik.values.distributionSet[id].documents}
          setListData={(list) => setDocData(list)}
        />
      </Grid>

      <Grid container item xs={12} justifyContent="flex-end">
        {distEdit && (
          <span className={classes.deleteButton} onClick={checkDelete}>
            <img src={RedDelete} alt="Delete" />
          </span>
        )}
      </Grid>

      <DeleteModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={handleDelete}
        title="Are You Sure You Want To Delete This Item?"
        description="This will permanently remove all data associated with it from Simplee."
      />
    </Grid>
  );
};

export default Distribution;
