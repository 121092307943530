// import DocumentsUpload from "@components/UI/Properties/DocumentsUpload/documentsUpload";

// import CustomSelect from "@components/Form/CustomSelect";
// import FieldHeading from "@components/Form/FieldHeading";
// import SelectWithInfiniteScroll from "@components/StyledComponents/SelectWithInfiniteScroll";
// import SelectWithInfiniteScrollManageData from "@components/StyledComponents/SelectWithInfinitScrollManageData";
import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup
} from "@material-ui/core";
// import Image from "next/image";
import React, { useEffect, useState } from "react";
import styles from "./addk1.module.scss";
// import CustomSearchSelect from "@components/StyledComponents/CustomSearchSelect";
// const CustomSearchSelect = dynamic(
//   () => import("@pages/user/contacts/addContact/CustomSearchSelect"),
//   { ssr: false }
// );
// import { ManageDataService } from "@network/manageDataService";
// import dynamic from "next/dynamic";
// import SelectWithInfiniteScrollUpdated from "@components/StyledComponents/SelectWithInfiniteScrollUpdated";
import SelectWithInfiniteScroll from "components/StyledComponents/SelectWithInfiniteScroll";
import DocumentsUpload from "pages/private/properties/component/DocumentsUpload/documentsUpload";
import CustomSelect from "components/StyledComponents/Select";
import FieldHeading from "components/fieldHeading";
import CustomSearchSelect from "components/StyledComponents/CustomSearchSelect";
import { ManageDataService } from "network/manageDataService";
import Toast from "components/toast";
import DeleteIcon from "../../../../assets/icons/deleteIcon.svg";
import DocumentErrorIcon from "../../../../assets/icons/formikErrorIcon.svg";

function AddK1Fields({
  id,
  formik,
  arrayHelper,
  k1Categories,
  selectProperty
}) {
  const handleDelete = () => {
    arrayHelper.remove(id);
  };
  const [selectedItem, setSelectedItem] = useState({});
  const [propertyList, setPropertyList] = useState([]);
  const [investorList, setInvestorList] = useState([]);

  const [type, setType] = useState("investment");

  const k1Errors =
    (formik.errors?.associatedSet &&
      formik.errors?.associatedSet.length &&
      formik.errors?.associatedSet[id]) ||
    {};
  const k1Touched =
    (formik.touched?.associatedSet &&
      formik.touched?.associatedSet.length &&
      formik.touched?.associatedSet[id]) ||
    {};
  useEffect(() => {
    formik.setFieldValue(`associatedSet[${id}].type`, "investment");
  }, []);

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    params += `?page=${page}`;
    if (searchText) {
      params += `&search=${searchText}`;
    }

    const response = await ManageDataService.getInvestmentData([`${params}`]);
    if (response.success) {
      return {
        data: response.data,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getPropertyListByInvestment = async (investmentId) => {
    const res = await ManageDataService.getPropertiesListByInvestment({
      investment_id: investmentId
    });
    if (res.success) {
      const temp = res.data;
      setPropertyList(
        temp?.map((data) => ({
          label: data.name,
          value: data.id
        }))
      );
    } else {
      Toast.showErrorToast("Failed To Load Property Names");
    }
  };

  const getInvestorName = async (investmentId) => {
    const res = await ManageDataService.getInvestorListByInvestment({
      investment_id: investmentId
    });
    if (res.success) {
      const temp = res.data;
      setInvestorList(
        temp?.map((data) => ({
          label: data.investor_name,
          value: data.investment_investor_id
        }))
      );
    } else {
      Toast.showErrorToast("Failed To Load Property Names");
    }
  };

  const onHandleChange = (data) => {
    setSelectedItem(data);

    getInvestorName(data?.id);

    formik.setFieldValue(`associatedSet[${id}].investmentinvestor`, data?.id);

    if (type === "property") {
      getPropertyListByInvestment(data.id);
    }
  };

  return (
    <Grid
      style={{
        marginTop: "48.5px",
        padding: "20px 20px 20px 20px",
        backgroundColor: "#FAFAFA"
      }}>
      <Grid>
        <Grid xs={12} container>
          <Grid xs={6} item>
            <FieldHeading title="Choose one" />
          </Grid>
          <Grid justifyContent="flex-end" xs={6} item container>
            {id == 0 ? null : (
              <div onClick={handleDelete} style={{ cursor: "pointer" }}>
                <img src={DeleteIcon} width={40} height={40} alt="delete" />
              </div>
            )}
          </Grid>
        </Grid>
        <FormControl>
          <RadioGroup
            row
            value={type}
            name="controlled-radio-buttons-group"
            onChange={(e) => {
              formik.setFieldValue(
                `associatedSet[${id}].type`,
                e.target.value ? e.target.value : "investment"
              );
              setType(e.target.value);

              selectProperty(e.target.value);
            }}>
            <FormControlLabel
              value="investment"
              control={<Radio color="secondary" />}
              label="By Investment"
            />
            <FormControlLabel
              value="property"
              control={<Radio color="secondary" />}
              label="By Property"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid xs={12} spacing={3} container style={{ paddingTop: "34px" }}>
        <Grid style={{ width: "280px" }} item>
          <div className={styles.label}>Select Investment</div>
          <CustomSearchSelect
            getData={getInvestmentName}
            onChange={onHandleChange}
            selectedItem={selectedItem}
          />
          {(k1Touched.investmentinvestor || k1Touched.investment) &&
            k1Errors.investmentinvestor && (
              <span
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  color: "#e13838"
                }}>
                {k1Errors.investmentinvestor}
              </span>
            )}
        </Grid>
        <Grid style={{ width: "256px", paddingTop: "12px" }}>
          <div className={styles.label}>Select Investor</div>
          <CustomSelect
            placeholder={"Select Investor"}
            items={investorList}
            value={formik.values.investor}
            onChange={(e) =>
              formik.setFieldValue(
                `associatedSet[${id}].investor`,
                e.target.value
              )
            }
            fullWidth={true}
            error={k1Errors.investor}
            touched={k1Touched.investor}
          />
        </Grid>
        {type === "property" && (
          <Grid style={{ width: "270px", padding: "12px 0px 0px 12px" }}>
            <div className={styles.label}>Select Property</div>
            <CustomSelect
              placeholder={"Select Property"}
              items={propertyList}
              value={formik.values.property_id}
              onChange={(e) =>
                formik.setFieldValue(
                  `associatedSet[${id}].property_id`,
                  e.target.value
                )
              }
              fullWidth={true}
              error={k1Errors?.property_id}
              touched={true}
            />
            {(k1Touched.property || k1Touched.property_id) &&
              (id == 0
                ? formik.values?.associatedSet[id].property_id === ""
                : formik.values?.associatedSet[id].property_id
                ? formik.values?.associatedSet[id].property_id === ""
                : formik.values?.associatedSet[id].property === "") && (
                <span
                  style={{
                    fontWeight: "400",
                    fontSize: "12px",
                    color: "#e13838"
                  }}>
                  Property Name is Required
                </span>
              )}
          </Grid>
        )}

        <Grid style={{ width: "280px", paddingTop: "12px" }} item>
          <div className={styles.label}>Select Year</div>
          <SelectWithInfiniteScroll
            id="infiniteYear"
            fullWidth={true}
            minValue={2000}
            onChange={(value) =>
              formik.setFieldValue(`associatedSet[${id}].year`, value)
            }
            error={k1Errors?.year}
            touched={k1Touched?.year}
          />
        </Grid>

        <div
          style={
            k1Errors.documents && k1Touched.documents
              ? { display: "flex" }
              : { display: "flex:", paddingTop: "30px" }
          }>
          {k1Categories?.map((item, index) => (
            <DocumentsUpload
              index={index}
              key={item.id}
              type={item.id}
              title={item.name}
              listData={formik.values.associatedSet[id].documents}
              setListData={(list) => {
                // let array = [];
                // array = list?.map((doc) => ({
                //   document_media_id: doc.document_media_id
                //     ? doc.document_media_id
                //     : doc.id,
                //   category_id: doc.category_id ? doc.category_id : doc.type,
                //   ...(doc.docId ? { id: doc.docId } : {}),
                // }));
                formik.setFieldValue(`associatedSet[${id}].documents`, list);
              }}
            />
          ))}
          {k1Errors.documents && k1Touched.documents && (
            <div className={styles.error}>
              {" "}
              <img
                src={DocumentErrorIcon}
                width={24}
                height={24}
                alt="delete"
              />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}

export default AddK1Fields;
