import { Grid, Tooltip, Typography } from "@material-ui/core";
import { truncate } from "helpers/Common";
import style from "./propertiesProfile.module.scss";

import React from "react";

function CommonOverView({
  heading,
  value,
  headingColor = "#BBBBBB",
  color = "#191919"
}) {
  return (
    <>
      <Grid xs={12} sm={6} md={4} xl={3} style={{ paddingBottom: "15px" }}>
        <Grid style={{ color: headingColor }} container>
          <Grid style={{ width: "auto" }} item>
            <Typography className={style.GeneralInfoHeading}>
              {heading}
            </Typography>
          </Grid>
        </Grid>

        <Grid>
          <Tooltip arrow title={value}>
            <Typography
              style={
                heading == "Property Status"
                  ? {
                      color: value === "stabilized" ? color : "#E13838",
                      textTransform: "capitalize"
                    }
                  : { color: "#191919", textTransform: "capitalize" }
              }
              className={style.dataValue}>
              {truncate(value, 13)}
            </Typography>
          </Tooltip>
        </Grid>
      </Grid>
    </>
  );
}

export default CommonOverView;
