import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@material-ui/core";
import { truncate } from "helpers/Common";
import React from "react";
import styles from "./commanstyle.module.scss";

const ContactsTable = ({ Headingdata, mapArray, width }) => {
  return (
    <>
      <Table
        className={styles.Spon_T_C}
        style={width ? { minWidth: width } : {}}>
        <TableHead>
          <TableRow>
            {Headingdata?.map((item, i) => (
              <TableCell className={styles.T_Heading} key={i}>
                {item.heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {mapArray?.map((obj, i) => (
            <TableRow key={i}>
              <TableCell
                title={`${obj?.first_name} ${obj?.last_name}`}
                className={styles.T_body}>
                {obj?.first_name && obj?.last_name
                  ? truncate(`${obj?.first_name} ${obj?.last_name}`, 16)
                  : "-"}
              </TableCell>
              <TableCell title={obj?.company_name} className={styles.T_body}>
                {obj?.company_name ? truncate(obj?.company_name, 16) : "-"}
              </TableCell>
              <TableCell title={obj?.email} className={styles.T_body}>
                {obj?.email ? truncate(obj?.email, 16) : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.country_code && obj?.phone_number
                  ? `${obj?.country_code} ${obj?.phone_number}`
                  : "-"}
              </TableCell>
              <TableCell title={obj?.designation} className={styles.T_body}>
                {obj?.designation ? truncate(obj?.designation, 16) : "-"}
              </TableCell>
              <TableCell className={styles.T_body}>
                {obj?.is_primary ? "Yes" : "No"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default ContactsTable;
