import { Typography, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles(() => ({
  text: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#191919",
    fontWeight: 400,
    marginBottom: 4,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  }
}));

const FieldHeading = ({ title }) => {
  const classes = useStyles();
  return <Typography className={classes.text}>{title}</Typography>;
};

export default FieldHeading;
