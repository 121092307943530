import React from "react";
import { makeStyles, Typography, Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  main: {
    width: "100%",
    height: "84px",
    borderRadius: 8,
    marginRight: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  sponsorIcon: {
    height: "44px",
    width: "44px"
    // marginTop: 45,
    // marginLeft: 20
  },
  cardTitle: {
    // marginTop: 24,
    fontSize: 14,
    fontFamily: "Inter Bold !important",
    fontWeight: 600,
    color: "#BBBBBB"
  },
  cardContent: {
    fontSize: 26,
    fontFamily: "Inter Regular",
    fontWeight: 700,
    color: "#616161"
  }
}));

const EntityCard = ({ Title, count, icon, link = false }) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (link) {
      history.push(link);
    }
  };
  return (
    <Grid container item lg={3} md={4} sm={6}>
      <Grid
        item
        onClick={handleClick}
        container
        className={classes.main}
        style={{
          cursor: link ? "pointer" : ""
        }}
        direction="row">
        <Grid item xs={2}>
          <img src={icon} className={classes.sponsorIcon} />
        </Grid>
        <Grid
          item
          xs={10}
          // style={{ color: color === "green" ? "#55A55E" : "#5E5499" }}
        >
          <Typography className={classes.cardTitle}>{Title}</Typography>
          <Typography className={classes.cardContent}>{count}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EntityCard;
