import { Box, Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import React from "react";
import styles from "../../EntityDetail.module.scss";
import PropertiesTable from "./PropertiesTable";

const Properties = ({ data }) => {
  const heading = [
    "Property Name",
    "Managed By",
    "Property Value",
    "Loans",
    "Area",
    "Type",
    "Occupancy",
    "Investment Status"
  ];
  let val = [];

  data?.map((values) => {
    values?.properties.map((item) => {
      val.push(item);
    });
  });

  return (
    <Box>
      <Grid container>
        <Grid item xs={12} className={styles.overviewMainContainer}>
          <Grid container>
            <Typography className={styles.cardHeading}>
              Associated Properties
            </Typography>
          </Grid>

          <Grid container>
            <PropertiesTable data={data} heading={heading} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Properties;
