import React from "react";
import styles from "./graphComponent.module.scss";
import InfiniteyearDropDown from "./InfiniteYearDropdown";

const YearRange = ({ yearRangeArray, year_range, setyear_range }) => {
  return (
    <div className={styles.yearRangeContainer}>
      {yearRangeArray.map((el, i) => {
        return (
          <React.Fragment key={i}>
            <div className={styles.y_R_c1}>
              <div className={styles.dotContainer}>
                <div
                  className={styles.dot}
                  style={{ backgroundColor: el.color }}
                />
                {el.title}
              </div>
              <div>
                <InfiniteyearDropDown
                  selectedItem={year_range[i]}
                  onChange={(y) => {
                    const array = year_range;
                    array[i] = y;
                    setyear_range([...array]);
                  }}
                  minValue={2000}
                />
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default YearRange;
