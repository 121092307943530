import { API, NetworkManager } from "./core";

export class SponsorService {
  static async getSponsors() {
    const instance = new NetworkManager(API.SPONSOR.SPONSORS);
    return await instance.httpRequest();
  }

  static async get_Pdf_Or_Csv(params) {
    const instance = new NetworkManager(API.SPONSOR.EXPORT_PDF_CSV, {}, params);
    return await instance.httpRequest();
  }

  static async getSponsorStats() {
    const instance = new NetworkManager(API.SPONSOR.SPONSOR_STATS);
    return await instance.httpRequest();
  }

  static async sponsorsFilter(params) {
    const instance = new NetworkManager(
      API.SPONSOR.SPONSORS_FILTER,
      {},
      params
    );
    return await instance.httpRequest();
  }

  // skip invite
  static async addSponsor(payload) {
    const instance = new NetworkManager(API.SPONSOR.ADD_SPONSOR, payload);
    return await instance.httpRequest();
  }

  static async addSponsorSendInvite(payload) {
    const instance = new NetworkManager(API.SPONSOR.ADD_SPONSOR_SEND_INVITE, payload);
    return await instance.httpRequest();
  }

  static async editSponsor(payload, params) {
    const instance = new NetworkManager(
      API.SPONSOR.EDIT_SPONSOR,
      payload,
      params
    );
    return await instance.httpRequest();
  }

  static async getSponsorDetails(params) {
    const instance = new NetworkManager(
      API.SPONSOR.SPONSOR_PROFILE,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async deleteSponsor(params) {
    const instance = new NetworkManager(API.SPONSOR.DELETE_SPONSOR, {}, params);
    return await instance.httpRequest();
  }
}
