import { Grid, Typography } from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import { truncate } from "helpers/Common";
// import formatCurrency from "helpers/formatCurrency";
import React from "react";
import style from "../dashboard.module.css";
import CommonDashboardTable from "./CommonDashboardTable";
import { numFormatter } from "helpers";
import { useHistory } from "react-router-dom";

const investorHeading = [
  "Investment Name",
  "Investor Name",
  "Amount Received",
  "Date",
  "Status"
];

function RecentDistribution({ data, trigger }) {
  const history = useHistory();
  const tableData = data?.map((item) => {
    return {
      0: truncate(item?.investment?.name, 16) || "-",
      1: item?.investor?.name || "-",
      2: `$${numFormatter(item?.amount)}` || "-",
      3: item?.distribution_date || "-" || "0",
      4: item?.investment?.status || "-",
      id: item?.investment?.id,
      type: "investment_id"
    };
  });

  return (
    <Grid item xs={12} className={style.overviewMainContainer}>
      <Grid container justifyContent="space-between">
        <Typography className={style.heading}>Recent Distributions</Typography>
        <ChevronRight
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/u/distributions")}
        />
      </Grid>
      <Grid container>
        <CommonDashboardTable
          trigger={trigger}
          data={data}
          tableData={tableData}
          heading={investorHeading}
          type="distribution"
        />
      </Grid>
    </Grid>
  );
}

export default RecentDistribution;
