import { Grid, makeStyles, Typography } from "@material-ui/core";
import formatCurrency from "helpers/formatCurrency";
import React from "react";
import InvestmentSponsorTable from "../Investment/InvestmentSponsorTable";

const useStyles = makeStyles({
  cardHeading: {
    fontSize: "18px !important",
    fontFamily: "Inter SemiBold",
    fontWeight: 700,
    color: "#191919",
    paddingBottom: "20px",
    backgroundColor: "#fff"
  }
});

const heading = [
  "Entity Name",
  "Investments",
  "Distributions",
  "Managed By",
  "Tax ID (EIN)",
  "Total Asset Value",
  "Outstanding Loan",
  "Equity",
  "Status"
];

function EntitiesSponsor({ data = {} }) {
  const classes = useStyles();
  const tableData = data?.entities?.map((item) => {
    return {
      0: item?.name || "-",
      1: item?.investment || "-",
      2: formatCurrency(item?.stats?.total_distribution) || "-",
      3: item?.managed_by || "-",
      4: item?.tax_id || "-",
      5: formatCurrency(item?.stats?.total_asset_value || "0"),
      6: formatCurrency(item?.stats?.outstanding_loan || "0"),
      7: formatCurrency(item?.stats?.equity || "0"),
      8: item?.status || "-",
      toolTipData: [
        ["Entity ID:", item?.base_id],
        [
          "Distribution Value:",
          formatCurrency(item?.stats?.total_distribution || "0")
        ],
        [
          "Total Invested Amount:",
          formatCurrency(item?.stats?.total_raise || "0")
        ]
      ]
    };
  });

  return (
    <Grid style={{ paddingTop: "30px" }}>
      <Grid xs={12}>
        <Typography className={classes.cardHeading}>
          Associated Entities
        </Typography>
      </Grid>
      <Grid container>
        <InvestmentSponsorTable
          tableType={true}
          data={data?.investments}
          tableData={tableData}
          heading={heading}
        />
      </Grid>
    </Grid>
  );
}

export default EntitiesSponsor;
