import { API, NetworkManager } from "./core";

export class DashboardSerivice {
  static async getDashboardStats(params) {
    const instance = new NetworkManager(API.DASHBOARD.GET_STATS, {}, [params]);
    return await instance.httpRequest();
  }

  static async getRecentInvestor(params) {
    const instance = new NetworkManager(API.DASHBOARD.GET_RECENT_INVESTOR, {}, [
      params
    ]);
    return await instance.httpRequest();
  }

  static async getRecentDistribution(params) {
    const instance = new NetworkManager(
      API.DASHBOARD.GET_RECENT_DISTRIBUTIONS,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async getRecentInvestments(params) {
    const instance = new NetworkManager(
      API.DASHBOARD.GET_RECENT_INVESTMENTS,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async getInvestmentByProperty(params) {
    const instance = new NetworkManager(
      API.DASHBOARD.GET_INVESTMENT_BY_PROPERTY,
      {},
      [params]
    );
    return await instance.httpRequest();
  }
}
