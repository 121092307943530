import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import { Grid, makeStyles } from "@material-ui/core";
import EntityCard from "../../../components/Cards/SponsorCard";
import PinkDollar from "../../../assets/icons/NewCardSvg/pinkDollar.svg";
import orangeHome from "../../../assets/icons/NewCardSvg/orangeHome.svg";
import Table from "./components/table/Table";
import { EntityService } from "../../../network/entityService";
import Toast from "components/toast";
import { useSelector } from "react-redux";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import styles from "./components/entityTable.module.css";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";

const useStyles = makeStyles(() => ({
  cardsContainer: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: -16
  }
}));

const Entities = () => {
  const classes = useStyles();
  const [entities, setEntities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [permissions, setPermissions] = useState();
  const { userPermissions, user } = useSelector((store) => store.app);
  const [stats, setStats] = useState({});
  const history = useHistory();

  const fetchStatsData = async () => {
    setLoading(true);
    const response = await EntityService.getEntityStats();
    if (response.success) {
      setLoading(false);
      setStats(response.data);
    } else {
      Toast.showErrorToast("Failed To Load Entities Data");
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "ENTITY",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(async () => {
    checkPermissions();
    fetchStatsData();
    const response = await EntityService.getEntities();
    if (response.success) {
      setEntities(response.data.results);
    } else {
      Toast.showErrorToast("Failed To Load Entities List");
    }
  }, []);

  const skeleton = (values) => {
    return !loading ? values : <Skeleton width={150} variant="text" /> || "-";
  };

  return (
    <>
      <HeaderComponent
        heading="Entities"
        description="All Entities On Simplee For Your Respective Investments"
        addInvestorBtn={false}
        ExtraButtons={
          permissions?.is_create ? (
            <div className={styles.addInvestmentButtonContainer}>
              <p
                className={styles.addInvestmentButtonText}
                onClick={() => {
                  history.push("/u/entities/addEntity");
                }}>
                + Add Entity
              </p>
            </div>
          ) : null
        }
      />

      <Grid
        container
        className={classes.cardsContainer}
        spacing={4}
        direction="row">
        <Grid item>
          <EntityCard
            Title="Total Investments"
            link="/u/investments"
            count={skeleton(stats.total_investment || "0")}
            color="green"
            icon={PinkDollar}
          />
        </Grid>
        <Grid item>
          <EntityCard
            Title="Total Entities"
            count={skeleton(stats.total_entity || "0")}
            color="green"
            icon={orangeHome}
          />
        </Grid>
      </Grid>

      <Grid container item xs={12}>
        <Table tableData={entities} permissions={permissions} />
      </Grid>
    </>
  );
};

export default Entities;
