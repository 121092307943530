import { API, NetworkManager } from "./core";

export class ProfileServices {
  static async deleteAdminProfilePic(id) {
    const instance = new NetworkManager(API.PROFILE.DELETE_PROFILE_PIC, {}, [
      `${id}/`
    ]);
    return await instance.httpRequest();
  }
}
