import React, { useState, useEffect, useRef } from "react";
import style from "../../../manage_data.module.scss";
import { useFormik } from "formik";
import { AppDispatcher } from "../../../../../../redux";
import { ManageDataService } from "network/manageDataService";
import Toast from "components/toast";
import { useHistory } from "react-router-dom";

const Step1 = ({ setOpen }) => {
  const history = useHistory();
  const [initialValue] = useState({});
  const [downloading, setDownloading] = useState(true);
  const [counter, setCounter] = useState(5);
  const autoTimer = useRef();
  const downloadTimer = useRef();

  const formik = useFormik({
    initialValues: initialValue,
    enableReinitialize: true,
    onSubmit: async () => {
      downloadTemplate();
    }
  });

  const downloadTemplate = async () => {
    let res = await ManageDataService.templateDownload();
    if (res.success) {
      download(res.data?.download_url);
    } else {
      Toast.showErrorToast(res.error.message[0]);
    }
  };

  const getData = async () => {
    autoTimer.current = setTimeout(() => {
      setDownloading(false);
    }, 5000);
    downloadTimer.current = setTimeout(async () => {
      downloadTemplate();
    }, 5000);
  };

  const handleSkip = () => {
    clearTimeout(autoTimer.current);
    clearTimeout(downloadTimer.current);
    AppDispatcher.setUploadTemplateStep(1);
  };

  const download = (e) => {
    fetch(e, {
      method: "GET"
    })
      .then((response) => {
        let fileName = e?.split("/")?.pop();
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
        AppDispatcher.setUploadTemplateStep(1);
      })
      .catch(() => {
        Toast.showErrorToast("Something Went Wrong");
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    let Timer;
    if (counter > 0) {
      Timer = setTimeout(() => setCounter(counter - 1), 1000);
    }
    return () => clearTimeout(Timer);
  }, [counter]);

  return (
    <div className={style.downloadContainer}>
      <div className={style.downloadTitleConatiner}>
        {downloading ? (
          <div className={style.title}>Downloading Template...</div>
        ) : (
          <div className={style.title}>Download Template</div>
        )}

        {downloading ? (
          <div>
            If auto download doesn’t start in 5s, please click the download
            button below
          </div>
        ) : null}
      </div>

      <div className={style.downloadMsgConatiner}>
        {downloading ? (
          <div className={style.delayMsg}>Wait for {counter}s</div>
        ) : null}
        <div>
          <button
            className={style.downloadBtn}
            onClick={() => formik.handleSubmit()}>
            Download
          </button>
          <div className={style.skipContainer}>
            <div className={style.skipButton}>
              <p
                onClick={() => {
                  setOpen(false);
                  clearTimeout(autoTimer.current);
                  clearTimeout(downloadTimer.current);
                  AppDispatcher.setGroupBy("Template");
                  history.push("/u/manage_data");
                }}>
                Cancel
              </p>
            </div>
            <div className={style.skipButton}>
              <p onClick={() => handleSkip()}>Skip</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step1;
