import React, { useEffect, useState } from "react";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const Calender = ({
  error,
  helperText,
  setDate = () => {},
  disabled,
  defaultDate
}) => {
  const [selectedDate, setDateChange] = useState(null);

  const handleDateChange = (date) => {
    setDateChange(date);
    setDate(date);
  };

  useEffect(() => {
    setDateChange(defaultDate ? defaultDate : null);
  }, [defaultDate]);

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          placeholder="MM/DD/YYYY"
          fullWidth
          variant="inline"
          {...(error && { error: error })}
          {...(helperText && { helperText: helperText })}
          inputVariant="outlined"
          value={selectedDate}
          onChange={(date) => handleDateChange(date)}
          autoOk
          format="MM/dd/yyyy"
          inputProps={{
            style: {
              fontFamily: "Inter semibold",
              fontWeight: 400,
              height: 11,
              color: "#191919",
              backgroundColor: disabled ? "#E9E9E9" : "#fff"
            }
          }}
          InputAdornmentProps={{
            position: "end"
          }}
          disabled={disabled}
        />
      </MuiPickersUtilsProvider>
    </>
  );
};

export default Calender;
