import { makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CheckCheckBox from "assets/icons/CheckCheckBox.svg";
import UnCheckCheckbox from "assets/icons/UnCheckCheckbox.svg";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: "border-box",
    border: "1px solid #EBEFFF",
    borderRadius: 16,
    backgroundColor: "#FFFFFF",
    padding: "14px 0"
  },
  itemContainer: {
    padding: "7px 20px",
    display: "flex",
    width: "100%",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "space-between",
    fontFamily: "Inter Regular",
    fontSize: 14,
    margin: "6px 0",
    "&:hover": {
      backgroundColor: "#F5F7FB"
    }
  },
  roundCheckBox: {
    height: 17,
    width: 17,
    borderRadius: "50%",
    border: "1.6px solid #445EBE",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& div": {
      height: 10,
      width: 10,
      borderRadius: "50%"
    }
  },
  subMenu: {
    width: "100%",
    marginTop: 15,
    display: "flex"
  },
  verticalDivider: {
    flexShrink: 0,
    width: 1,
    backgroundColor: "#E9E9E9",
    marginLeft: 20
  },
  subItemContainer: {
    flexGrow: 1,
    marginLeft: 5
  },
  dateInputContainer: {
    display: "flex",
    justifyContent: "space-between",
    "div:first-child": {
      width: 150
    }
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    color: "#BBBBBB"
  },
  dateContainer: {
    height: 50,
    width: 148,
    borderRadius: 16,
    border: "1px solid #EBEFFF",
    marginTop: 4,
    cursor: "pointer",
    fontFamily: "Inter Regular",
    fontSize: 16,
    color: "#191919",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  datePickerContainer: {
    marginTop: 74,
    display: "flex",
    justifyContent: "center",
    "& .MuiToolbar-root": {
      display: "none"
    }
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 45
  },
  btn: {
    height: 50,
    width: 112,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#445EBE",
    fontFamily: "Inter SemiBold",
    fontSize: 18,
    color: "#ffffff",
    cursor: "pointer",
    borderRadius: 4
  }
}));

const FiltersList = ({
  selected,
  setSelected,
  handleClose,
  quarterList = [],
  selectedType
}) => {
  const classes = useStyles();
  const [yearList, setYearList] = useState([]);
  const [fromSelected, setFromSelected] = useState(true);
  const [from, setFrom] = useState(new Date());
  const [to, setTo] = useState(new Date());

  const onScroll = (container, setList) => {
    const { scrollTop, scrollHeight, clientHeight } = container;

    if (scrollTop + clientHeight >= scrollHeight - 5) {
      setList((prev) => {
        let newList = [...prev];
        let maxElement = prev[prev.length - 1] + 1;
        for (let i = maxElement; i <= maxElement + 10; ++i) {
          newList.push(i);
        }
        return newList;
      });
    }
  };

  const scrollHandler = (setList) => {
    let container = document.getElementById("scroll-content");
    container.addEventListener("scroll", () => onScroll(container, setList), {
      passive: true
    });
    return () => container.removeEventListener("scroll", onScroll);
  };

  const getYearlyList = () => {
    return (
      <div
        className={classes.container}
        id="scroll-content"
        style={{
          width: 197,
          height: 257,
          overflowY: "scroll"
        }}>
        {yearList.map((item) => (
          <div
            className={classes.itemContainer}
            key={item}
            onClick={() =>
              setSelected((prev) => ({
                ...prev,
                yearly: {
                  year: item
                }
              }))
            }>
            <div>{item}</div>
            <div className={classes.roundCheckBox}>
              <div
                style={{
                  backgroundColor:
                    selected?.yearly?.year === item ? "#445EBE" : "#ffffff"
                }}
              />
            </div>
          </div>
        ))}
      </div>
    );
  };

  const getQuarterlyList = () => {
    const selectQuarter = (value) => {
      let selectedValues = { ...selected };
      if (selectedValues.quarterly.quarters[value]) {
        selectedValues.quarterly.quarters[value] = false;
      } else {
        selectedValues.quarterly.quarters[value] = true;
      }
      setSelected(selectedValues);
    };

    return (
      <div
        className={classes.container}
        id="scroll-content"
        style={{
          width: 197,
          height: 257,
          overflowY: "scroll"
        }}>
        {yearList.map((item) => (
          <>
            <div
              className={classes.itemContainer}
              key={item}
              onClick={() =>
                setSelected((prev) => ({
                  ...prev,
                  quarterly: {
                    year: item,
                    quarters: {}
                  }
                }))
              }>
              <div>{item}</div>
              <div className={classes.roundCheckBox}>
                <div
                  style={{
                    backgroundColor:
                      selected?.quarterly?.year === item ? "#445EBE" : "#ffffff"
                  }}
                />
              </div>
            </div>
            {selected?.quarterly?.year === item ? (
              <div className={classes.subMenu}>
                <div className={classes.verticalDivider} />
                <div className={classes.subItemContainer}>
                  {quarterList.map((quarter) => (
                    <div
                      className={classes.itemContainer}
                      style={{ paddingLeft: 5 }}
                      key={quarter.value}
                      onClick={() => selectQuarter(quarter.value)}>
                      <div>{quarter.label}</div>
                      <img
                        src={
                          selected?.quarterly?.quarters[quarter.value]
                            ? CheckCheckBox
                            : UnCheckCheckbox
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </>
        ))}
      </div>
    );
  };

  const getCustomSelectMenu = () => {
    const getformattedDate = (date) =>
      `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;

    const handleDateChange = (date) => {
      if (fromSelected) {
        setFrom(date);
        if (
          date?.toISOString().split("T")[0] > to?.toISOString().split("T")[0]
        ) {
          setTo(date);
        }
      } else {
        setTo(date);
      }
    };

    const handleDateApply = () => {
      setSelected((prev) => ({
        ...prev,
        custom: {
          from,
          to
        }
      }));
      handleClose();
    };

    return (
      <div
        className={classes.container}
        id="scroll-content"
        style={{
          width: 403,
          padding: 20,
          maxHeight: 450,
          overflowY: "scroll"
        }}>
        <div className={classes.dateInputContainer}>
          <div>
            <p className={classes.label}>From</p>
            <div
              className={classes.dateContainer}
              onClick={() => setFromSelected(true)}>
              {getformattedDate(from)}
            </div>
          </div>
          <div>
            <p className={classes.label}>To</p>
            <div
              className={classes.dateContainer}
              onClick={() => setFromSelected(false)}>
              {getformattedDate(to)}
            </div>
          </div>
        </div>
        <div className={classes.datePickerContainer}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              autoOk
              variant="static"
              openTo="date"
              value={fromSelected ? from : to}
              {...(fromSelected ? {} : { minDate: from })}
              onChange={handleDateChange}
            />
          </MuiPickersUtilsProvider>
        </div>
        <div className={classes.btnContainer}>
          <div className={classes.btn} onClick={handleDateApply}>
            Apply
          </div>
        </div>
      </div>
    );
  };

  const getFilterList = () => {
    switch (selected.tenure) {
      case "yearly":
        return getYearlyList();
      case "quarterly":
        return getQuarterlyList();
      default:
        return getCustomSelectMenu();
    }
  };

  useEffect(() => {
    if (selectedType === "quarterly") {
      setSelected((prev) => ({
        ...prev,
        quarterly: {
          year: "",
          quarters: {}
        }
      }));
    }
    if (selectedType === "yearly" || selectedType === "quarterly") {
      let years = [];
      for (let i = 2000; i <= 2000 + 10; ++i) {
        years.push(i);
      }
      setYearList(years);
      return scrollHandler(setYearList);
    }
  }, [selectedType]);

  return <>{getFilterList()}</>;
};

export default FiltersList;
