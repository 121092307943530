import React from "react";
import { Grid } from "@material-ui/core";
import Table from "../components/template/Table";

const Template = () => {
  return (
    <>
      <Grid container item xs={12}>
        <Table />
      </Grid>
    </>
  );
};

export default Template;
