import { API, NetworkManager } from "./core";

export class DocumentService {
  static async getDocuments(params) {
    const instance = new NetworkManager(
      API.Document.GET_DOCUMENTS_LIST,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
