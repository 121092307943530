import React, { useEffect, useState } from "react";
import styles from "../documentTable.module.css";
import { Grid, Tooltip } from "@material-ui/core";
import { AppDispatcher } from "../../../../../redux/index";
import filterIcon from "assets/icons/Filter.svg";
import downloadIcon from "assets/icons/downloadIcon.svg";
import SearchInput from "components/Search";
import CloseIcon from "../../../../../assets/icons/cross.svg";
import { InvestmentListService } from "network/investment_ListService";
import { DocumentService } from "network/documentService";
import { PropertiesService } from "network/propertiesService";
import DateRange from "components/DateRange/dateRange";
import { useSelector } from "react-redux";
import { CommonService } from "network/commonService";
import Toast from "components/toast";
import Illustrations from "components/illustration/Illustrations";
import noDocuments from "../../../../../assets/emptyState/noDocuments.svg";
import FrwrdAsEmail from "components/ForwarsAsEmail";
import { ManageDataService } from "network/manageDataService";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
import CSV from "../../../../../assets/icons/NewCardSvg/CSV.svg";
import FWDEmail from "../../../../../assets/icons/NewCardSvg/FWDEmail.svg";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import DotFilterIcon from "assets/icons/DotFilter.svg";
import BluefilterIcon from "assets/icons/blueFilter.svg";
import MoreFilterContainer from "components/MoreFilterContainer/MoreFilterContainer";
import ManageColumn from "components/ManageColumns";
import NewTable from "components/NewTable/NewTable";
import LightTooltip from "components/LightTooltip";
import InvestmentToolTipData from "pages/private/entities/detail/section/Investment/InvestmentToolTipData";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import { formatDate, truncate } from "helpers/Common";
import NewDropDown from "components/NewDropDown";
import { useHistory } from "react-router-dom";

// const manageByList = [
//   {
//     label: "Managed By",
//     value: "managedby"
//   },
//   {
//     label: "Investor",
//     value: "INVESTOR"
//   },
//   {
//     label: "Admin",
//     value: "ADMIN"
//   }
// ];

const TableHeaders = [
  {
    id: "document_name",
    name: "Document Name"
  },
  {
    id: "document_type",
    name: "Document Type"
  },
  {
    id: "investment_name",
    name: "Investment Name"
  },
  {
    id: "managed_by",
    name: "Managed By"
  },
  {
    id: "investor_name",
    name: "Investor Name"
  },
  {
    id: "investor_entity",
    name: "Investor Entity"
  },
  {
    id: "sponsor_name",
    name: "Sponsor Name"
  },
  { id: "sponsor_entity", name: "Sponsor Entity" },
  {
    id: "property_name",
    name: "Property Name"
  },
  {
    id: "date_received",
    name: "Date Received"
  },
  {
    id: "year",
    name: "Year"
  },
  {
    id: "download",
    name: "Download"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "Pdf",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

function Table() {
  const history = useHistory();
  const [data, setData] = useState([]);
  const [expandIndex, setExpandIndex] = useState(-1);
  const [expandIndex1, setExpandIndex1] = useState(-1);
  const [expandIndex2, setExpandIndex2] = useState(-1);
  const [expandIndex3, setExpandIndex3] = useState(-1);
  const [expandIndex4, setExpandIndex4] = useState(-1);

  const [showFilter, setShowFilter] = useState(false);
  const [checked, setChecked] = useState([
    { key: "Investment Name", value: [] },
    { key: "Investor Name", value: [] },
    { key: "Distribution Type", value: [] }
  ]);
  const [filterState, setFilterState] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [pageResetValue, setPageResetValue] = useState(0);
  const { dateRange } = useSelector((store) => store.app);
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);
  const [Export, setExport] = useState("exportList");
  const [THeaders, setTHeaders] = useState(TableHeaders);
  // const [manageBy, setManageBy] = useState("managedby");
  const [isFilterActive, setFilterActive] = useState(false);

  const handleCellClick = (e, type, id) => {
    if (id) {
      switch (type) {
        case "investment_id":
          AppDispatcher.setInvestmentDetailID(id);
          history.push("/u/investments/detail");
          break;

        case "investor_entity_id":
          AppDispatcher.setEntitiesDetailData({
            id: id,
            entity_type: "INVESTOR"
          });
          history.push("/u/entities/detail");
          break;
        case "property_id":
          AppDispatcher.setPropertiesDetailID(id);
          history.push("/u/properties/detail");
          break;
        case "investor_id":
          AppDispatcher.setInvestorDetailID(id);
          history.push("/u/investors/detail");
          break;
        case "sponsor":
          AppDispatcher.setSponsorDetailID(id);
          history.push("/u/sponsors/detail");
          break;
        case "sponsor_entity_id":
          AppDispatcher.setEntitiesDetailData({
            id: id,
            entity_type: "SPONSOR"
          });
          history.push("/u/entities/detail");
          break;
        default:
          return;
      }
    }
  };

  const mappingObject = {
    "Investment Name": "investment_id",
    "Investor Name": "investor_id",
    "Sponsor Name": "sponsor_id",
    "Sponsor Entity": "sponsor_entity",
    "Investor Entity": "investor_entity",
    "Property Name": "property_id",
    Year: "year",
    "Document Type": "document_type"
  };

  const handleApply = (data) => {
    setShowFilter(false);
    setChecked([...data]);
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const onDeletedate = () => {
    AppDispatcher.setDateRange({});
  };

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = `?status=${status}`;

    if (emailData) {
      if (preview === "preview") {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `&${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    // if (manageBy !== "managedby") {
    //   url += `${url.length ? "&" : "?"}managed_by=${manageBy}`;
    // }

    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `&${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el?.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    url += `&page=${page}&page_size=${pageSize}`;

    setExport("exportList");
    const response = await ManageDataService.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          // link.target = "_blank";
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  const getDocumentData = async (pageNum, isAppend = false) => {
    setLoading(true);
    let url = "";
    if (searchQuery.length > 0) {
      url += `${url.length ? "&" : "?"}search=${searchQuery}`;
    }

    // if (manageBy !== "managedby") {
    //   url += `${url.length ? "&" : "?"}managed_by=${manageBy}`;
    // }

    if (Object.keys(dateRange).length > 0) {
      url += `${url.length ? "&" : "?"}custom=true`;
      url += `&start_date=${dateRange["start_date"]}&end_date=${dateRange["end_date"]}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `${url.length ? "&" : "?"}${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (url.length > 0) {
      url += `&page=${pageNum}&page_size=${pageSize}`;
    } else {
      url += `?page=${pageNum}&page_size=${pageSize}`;
    }
    if (Object.keys(dateRange).length > 0) {
      if (url.length > 0) {
        url += "&custom=true";
      } else {
        url += "?custom=true";
      }

      url += `&start_date=${dateRange["start_date"]}&end_date=${dateRange["end_date"]}`;
    }
    const response = await DocumentService.getDocuments([url]);
    if (response.success) {
      if (isAppend) {
        setData([...data, ...response.data.results]);
      } else {
        setData(response.data.results);
      }
      setCount(response.data.count);
    }
    setLoading(false);
  };

  const handleClear = () => {
    setShowFilter(false);
    setChecked([]);
  };

  const onHandleClick = () => {
    setShowFilter(!showFilter);
  };

  const ChipsComponent = () => {
    let newArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        newArray.push(newObj);
      });
    });

    return (
      <div className={styles.chipsCtr}>
        {newArray.length > 0
          ? newArray.map((item, index) => {
              return (
                <div key={index} className={styles.chips}>
                  <div className={styles.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
        {dateRange?.["start_date"] && dateRange?.["end_date"] ? (
          <div className={styles.chips}>
            <div
              className={
                styles.chipsText
              }>{`${dateRange["start_date"]} To ${dateRange["end_date"]}`}</div>
            <div onClick={() => onDeletedate()}>
              <img className={styles.chipsImg} src={CloseIcon} alt="cross" />
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
    let chipsArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        chipsArray.push(newObj);
      });
    });
    !chipsArray.length && setFilterActive(false);
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestmentName([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getInvestorName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getInvestors([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getInvestorEntityName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getSponserEntity([
      `?entity_type=INVESTOR${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponsorEntityName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getSponserEntity([
      `?entity_type=SPONSOR${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponsorName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await InvestmentListService.getSponsors([
      `?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getDocumentType = async () => {
    const response = await CommonService.getAllDocCategories();
    if (response.success) {
      const obj = {
        key: "Document Type",
        isStaticData: true,
        value: response.data
      };
      setFilterState((prev) => [...prev, obj]);
    }
  };

  const getPropertyName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;
    const response = await PropertiesService.getProperties([
      `properties?fields=id,name${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getDocumentData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const setFilterMeta = () => {
    setFilterState([
      {
        key: "Investment Name",
        value: getInvestmentName
      },
      {
        key: "Investor Name",
        value: getInvestorName
      },
      {
        key: "Investor Entity",
        value: getInvestorEntityName
      },
      {
        key: "Sponsor Entity",
        value: getSponsorEntityName
      },
      {
        key: "Sponsor Name",
        value: getSponsorName
      },
      {
        key: "Property Name",
        value: getPropertyName
      }
    ]);
    getDocumentType();
  };

  const getQueryParams = () => {
    let url = "";
    if (searchQuery.length > 0) {
      url += `${url.length ? "&" : "?"}search=${searchQuery}`;
    }
    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          url += `${url.length ? "&" : "?"}${mappingObject[item.key]}=`;
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (Object.keys(dateRange).length > 0) {
      if (url.length > 0) {
        url += "&custom=true";
      } else {
        url += "?custom=true";
      }
      url += `&start_date=${dateRange["start_date"]}&end_date=${dateRange["end_date"]}`;
    }
    return url;
  };

  const downloadCsv = async () => {
    setLoading(true);
    let url = getQueryParams();
    if (url.length > 0) {
      url += `&csv-download=true`;
    } else {
      url += `?csv-download=true`;
    }
    const resp = await DocumentService.getDocuments([url]);
    if (resp.success) {
      const link = document.createElement("a");
      link.href = resp?.data?.url;
      link.setAttribute("download", `${resp?.data?.url}`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } else {
      Toast.showErrorToast("Failed To Download CSV");
    }
    setLoading(false);
  };

  console.log(downloadCsv);
  useEffect(() => {
    setFilterMeta();
  }, []);

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getDocumentData(1);
  }, [searchQuery, checked, pageSize, dateRange]);

  const download = (e, filename) => {
    fetch(e, {
      method: "GET"
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(() => {
        Toast.showErrorToast("Download failed");
      });
  };

  const Tabledatalist = data.map((item, index) => {
    return [
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="document_name">
        <div className={`${styles.text}`}>
          <div>{truncate(item?.document_name, 16) || "-"}</div>
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="document_type">
        <div onClick={() => handleCellClick()} className={`${styles.text} `}>
          {item?.document_type || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="investment_name">
        <div
          onClick={(e) =>
            handleCellClick(
              e,
              "investment_id",
              item?.investment_name?.investment_id
            )
          }
          className={`${styles.text} ${styles.clickable} ${styles.onHover}`}>
          {truncate(item?.investment_name?.investment_name, 16) || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="managed_by">
        <div className={`${styles.text}`} onClick={() => handleCellClick()}>
          {item?.managed_by || "-"}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="investor_name">
        <div className={styles.text} style={{ cursor: "pointer" }}>
          {item?.investor_name?.length ? (
            <NewDropDown
              index={index}
              item={item?.investor_name?.map((item) => {
                return {
                  id: item?.id,
                  name: truncate(item?.investor_name, 16) || "-"
                };
              })}
              handleCellItemClick={handleCellClick}
              type="investor_id"
              expandIndex={expandIndex}
              setExpandIndex={setExpandIndex}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>
      </LightTooltip>,

      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="investor_entity">
        <div className={styles.text} style={{ cursor: "pointer" }}>
          {item?.investor_entity?.length ? (
            <NewDropDown
              index={index}
              item={item?.investor_entity?.map((item) => {
                return {
                  id: item?.id,
                  name: truncate(item?.name, 16) || "-"
                };
              })}
              handleCellItemClick={handleCellClick}
              type="investor_entity_id"
              expandIndex={expandIndex1}
              setExpandIndex={setExpandIndex1}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="sponsor_name">
        <div className={styles.text} style={{ cursor: "pointer" }}>
          {item?.sponsor_name?.length ? (
            <NewDropDown
              index={index}
              item={item?.sponsor_name?.map((item) => {
                return {
                  id: item?.sponsor_id,
                  name: truncate(item?.sponsor_name, 16) || "-"
                };
              })}
              handleCellItemClick={handleCellClick}
              type="sponsor"
              expandIndex={expandIndex2}
              setExpandIndex={setExpandIndex2}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="sponsor_entity">
        <div className={styles.text} style={{ cursor: "pointer" }}>
          {item?.sponsor_entity?.length ? (
            <NewDropDown
              index={index}
              item={item?.sponsor_entity?.map((item) => {
                return {
                  id: item?.entity_id,
                  name: truncate(item?.entity_name, 16) || "-"
                };
              })}
              handleCellItemClick={handleCellClick}
              type="sponsor_entity_id"
              expandIndex={expandIndex3}
              setExpandIndex={setExpandIndex3}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="property_name">
        <div className={styles.text} style={{ cursor: "pointer" }}>
          {item?.property_name?.length ? (
            <NewDropDown
              index={index}
              item={item?.property_name?.map((item) => {
                return {
                  id: item?.id,
                  name: truncate(item?.name, 16) || "-"
                };
              })}
              handleCellItemClick={handleCellClick}
              type="property_id"
              expandIndex={expandIndex4}
              setExpandIndex={setExpandIndex4}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="date_received">
        <div className={`${styles.displayFlex} `}>
          <div className={styles.text}>
            {formatDate(item?.date_received) || "-"}
          </div>
        </div>
      </LightTooltip>,
      <LightTooltip
        //  title={getinfo(item)}
        title={
          <InvestmentToolTipData
            data={[["Created By ", item?.created_by || "-"]]}
          />
        }
        arrow
        key="year">
        <div className={`${styles.displayFlex} `}>
          <div className={styles.text}>{item?.year || "-"}</div>
        </div>
      </LightTooltip>,

      <div key="download" style={{ cursor: "pointer", marginRight: 8 }}>
        <a
          download
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => download(item?.document_media, item?.document_name)}>
          <img src={downloadIcon} alt="alt" />
        </a>
      </div>
    ];
  });

  return (
    <>
      <Grid container>
        <HeaderComponent
          heading="Documents"
          description="Manage Various Documents Belonging To Your Investments"
          addInvestorBtn={false}
        />
        <Grid item style={{ paddingTop: "50px" }} xs={12}>
          <FrwrdAsEmail
            AllHeaders={TableHeaders}
            FrdasEmail={FrdasEmail}
            setFrdasEmail={setFrdasEmail}
            open={frdEmailOpen}
            setOpen={setFrdasEmailOpen}
            clickOnShare={(payload) => {
              getExport_PDF_CSV_Link(payload);
            }}
            clickOnPreview={(payload) => {
              getExport_PDF_CSV_Link(payload, "preview");
            }}
          />
          <TableTopFilter
            tabletViewCSV={
              <div className={styles.clickable}>
                <TabletVIewSelectMenu
                  image={
                    <img
                      src={CSV}
                      alt="doc"
                      layout="fixed"
                      height={48}
                      width={48}
                    />
                  }
                  menuItems={ExportList}
                  clickMenuItem={(obj, close) => {
                    setExport(obj?.value);
                    close();
                  }}
                />
              </div>
            }
            tabletViewFWDEmail={
              <div className={styles.clickable}>
                <TabletVIewSelectMenu
                  image={
                    <img
                      src={FWDEmail}
                      alt="doc"
                      layout="fixed"
                      height={48}
                      width={48}
                    />
                  }
                  menuItems={frdasEamilList}
                  clickMenuItem={(obj, close) => {
                    setFrdasEmail(obj?.value);
                    close();
                  }}
                />
              </div>
            }
            filterSection1={[
              <SearchInput setQuery={setQuery} key={0} />,

              // <CustomSelectFilter
              //   key="2"
              //   border={true}
              //   placeholder="Status"
              //   label="Status"
              //   items={statusList}
              //   value={status}
              //   onChange={(e) => setStatus(e.target.value)}
              // />,

              <DateRange key={1} />,
              <Tooltip key={2} title="More Filters" arrow>
                <div className={styles.clickable} onClick={onHandleClick}>
                  <img
                    src={
                      isFilterActive
                        ? DotFilterIcon
                        : showFilter
                        ? BluefilterIcon
                        : filterIcon
                    }
                    alt="alt"
                    layout="fixed"
                    width={45}
                    height={45}
                  />
                </div>
              </Tooltip>,
              " ",
              showFilter && (
                <>
                  <MoreFilterContainer
                    key={4}
                    open={showFilter}
                    checked={checked}
                    handleClose={onHandleClick}
                    handleApply={handleApply}
                    handleClear={handleClear}
                    filters={filterState}
                  />
                </>
              )
            ]}
            filterSection2={[
              <ManageColumn
                AllHeaders={TableHeaders}
                setTableHeaders={setTHeaders}
                key={0}
              />,
              <CustomSelectFilter
                border={true}
                key="1"
                placeholder="Forward as Email"
                items={frdasEamilList}
                value={FrdasEmail}
                onChange={(e) => setFrdasEmail(e.target.value)}
              />,
              <CustomSelectFilter
                key="2"
                border={true}
                placeholder="Export"
                items={ExportList}
                value={Export}
                onChange={(e) => setExport(e.target.value)}
              />
              //   <CustomSelectFilter
              //     border={true}
              //     key="2"
              //     placeholder="Forward as Email"
              //     items={frdasEamilList}
              //     value={FrdasEmail}
              //     onChange={(e) => setFrdasEmail(e.target.value)}
              //   />,
              //   <CustomSelectFilter
              //     key="3"
              //     border={true}
              //     placeholder="Export"
              //     items={ExportList}
              //     value={Export}
              //     onChange={(e) => setExport(e.target.value)}
              //   />,
            ]}
          />
        </Grid>
        <Grid item xs={12}>
          <ChipsComponent />

          <NewTable
            loading={loading}
            tableData={Tabledatalist}
            paginationLabel="Total Investments Per Page"
            ChipsComponent={ChipsComponent}
            pageSize={pageSize}
            changePage={changePage}
            changePageSize={changePageSize}
            count={count}
            labelRowsPerPage="Total No Of Documents Per Page"
            pageResetValue={pageResetValue}
            Header={[...THeaders, { id: "Action", name: "" }]}
            Illustration={
              <Illustrations
                src={noDocuments}
                heading="No Documents Found"
                subHeading="Add Documents To View"
              />
            }
          />
        </Grid>
      </Grid>
    </>
  );
}
export default Table;
