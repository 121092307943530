import React from "react";
import ManageColumnsWeb from "./web/ManageColumnsWeb";
import ManageColumnsMobile from "./mobile/ManageColumnsMobile";
import Hidden from "@material-ui/core/Hidden";
import { AppDispatcher } from "redux/index";

const Index = ({ AllHeaders, setTableHeaders, checkedHeaders }) => {
  const ExtractAllHeaders = AllHeaders.map((obj) => obj.name);
  const ECheckedAllheaders =
    checkedHeaders && checkedHeaders.length
      ? checkedHeaders.map((obj) => obj.name)
      : [];
  const [open, setOpen] = React.useState(false);
  const [columnNames, setColumnNames] = React.useState([...ExtractAllHeaders]);
  const [checked, setChecked] = React.useState(
    ECheckedAllheaders?.length
      ? [...ECheckedAllheaders]
      : [...ExtractAllHeaders]
  );
  const [searchTerm, setSearchTerm] = React.useState("");

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleSave = () => {
    const sortedHeader = [];

    checked.map((val) => {
      AllHeaders.map((obj) => {
        if (obj.name === val) {
          sortedHeader.push(obj);
        }
      });
    });

    setTableHeaders([...sortedHeader]);
    AppDispatcher.retainManageColumn([...sortedHeader]);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);

    const results = columnNames.filter((person) =>
      person.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setColumnNames(results);
  };

  const handleSelectAll = () => {
    setChecked([...ExtractAllHeaders]);
  };

  const handleClose = () => {
    setOpen(false);
    setSearchTerm("");
  };

  React.useEffect(() => {
    if (searchTerm === "") {
      setColumnNames([...ExtractAllHeaders]);
    }
  }, [searchTerm]);

  return (
    <>
      <Hidden xsDown>
        <ManageColumnsWeb
          open={open}
          handleSave={handleSave}
          columnNames={columnNames}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          checked={checked}
          setChecked={setChecked}
          handleToggle={handleToggle}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
          handleSelectAll={handleSelectAll}
          ExtractAllHeaders={ExtractAllHeaders}
        />
      </Hidden>
      <Hidden smUp>
        <ManageColumnsMobile
          open={open}
          handleSave={handleSave}
          columnNames={columnNames}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          checked={checked}
          setChecked={setChecked}
          setColumnNames={setColumnNames}
          handleToggle={handleToggle}
        />
      </Hidden>
    </>
  );
};

export default Index;
