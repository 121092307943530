// App wide dispatcher

import Stores from "../store";
import Actions from "../actions/appActions";

const AppDispatcher = {
  setUserLoggedIn: (data) => {
    Stores.dispatch({ type: Actions.SET_TOKEN, data: data.tokens });
    Stores.dispatch({ type: Actions.LOGIN, data: data });
  },
  setUserLoggedOut: () => {
    Stores.dispatch({ type: Actions.LOGOUT });
  },
  updateUserTokens: (data) => {
    Stores.dispatch({ type: Actions.SET_TOKEN, data });
  },
  updateUserInfo: (data) => {
    Stores.dispatch({ type: Actions.LOGIN, data });
  },
  setInvestorEmail: (data) => {
    Stores.dispatch({ type: Actions.SET_EMAIL, data: data });
  },
  setInvestorName: (data) => {
    Stores.dispatch({ type: Actions.SET_INVESTOR_NAME, data: data });
  },
  setInvestorId: (data) => {
    Stores.dispatch({ type: Actions.SET_INVESTOR_ID, data: data });
  },
  setInvestorDetailID: (data) => {
    Stores.dispatch({ type: Actions.SET_INVESTOR_DETAIL_ID, data: data });
  },
  setSponsorId: (data) => {
    Stores.dispatch({ type: Actions.SET_SPONSOR_ID, data: data });
  },
  setEntityId: (data) => {
    Stores.dispatch({ type: Actions.SET_ENTITY_ID, data: data });
  },
  setResetPasswordOTP: (data) => {
    Stores.dispatch({ type: Actions.SET_INVESTOR_ID, data: data });
  },
  setAddInvestmentStep: (data) => {
    Stores.dispatch({ type: Actions.SET_ADD_INVESTMENT_STEP, data: data });
  },
  resetAddInvestmentStep: (data) => {
    Stores.dispatch({ type: Actions.RESET_ADD_INVESTMENT_STEP, data: data });
  },
  addInvestmentNextStep: () => {
    Stores.dispatch({ type: Actions.ADD_INVESTMENT_NEXT_STEP });
  },
  setPropertyData: (data) => {
    Stores.dispatch({ type: Actions.SET_PROPERTY_DATA, data: data });
  },
  setInvestmentID: (data) => {
    Stores.dispatch({ type: Actions.ADD_INVESTMENT_ID, data: data });
  },
  setInvestorStatus: (data) => {
    Stores.dispatch({ type: Actions.SET_INVESTOR_STATUS, data: data });
  },
  setInvestmentDetailID: (data) => {
    Stores.dispatch({ type: Actions.SET_INVESTMENT_DETAIL_ID, data: data });
  },
  setPropertiesDetailID: (data) => {
    Stores.dispatch({ type: Actions.SET_PROPERTIES_DETAIL_ID, data: data });
  },
  setSponsorDetailID: (data) => {
    Stores.dispatch({ type: Actions.SET_SPONSOR_DETAIL_ID, data: data });
  },
  setEntitiesDetailData: (data) => {
    Stores.dispatch({ type: Actions.SET_ENTITIES_DETAIL_DATA, data: data });
  },
  setDistributionId: (data) => {
    Stores.dispatch({ type: Actions.SET_DISTRIBUTION_ID, data: data });
  },
  setPropertiesId: (data) => {
    Stores.dispatch({ type: Actions.SET_PROPERTIES_ID, data: data });
  },
  setContactsId: (data) => {
    Stores.dispatch({ type: Actions.SET_CONTACTS_ID, data: data });
  },
  setIsPrimary: (data) => {
    Stores.dispatch({ type: Actions.SET_IsPrimary, data: data });
  },
  setManageDataK1Id: (data) => {
    Stores.dispatch({ type: Actions.SET_MANAGEDATAK1_ID, data: data });
  },
  setRoleId: (data) => {
    Stores.dispatch({ type: Actions.SET_ROLE_ID, data: data });
  },
  setSubAdminId: (data) => {
    Stores.dispatch({ type: Actions.SET_SUB_ADMIN_ID, data: data });
  },
  setUserPermissions: (data) => {
    Stores.dispatch({ type: Actions.SET_USER_PERMISSIONS, data: data });
  },
  setDocumentId: (data) => {
    Stores.dispatch({ type: Actions.SET_DOCUMENT_ID, data: data });
  },
  setDateRange: (data) => {
    Stores.dispatch({ type: Actions.SET_DATE_RANGE, data });
  },
  setDraweropen: (data) => {
    Stores.dispatch({ type: Actions.SET_DRAWER_OPEN, data: data });
  },
  setEntitiesIdAndType: (data) => {
    Stores.dispatch({ type: Actions.SET_ENTITIES_ID_TYPE, data });
  },
  setUploadTemplateStep: (data) => {
    Stores.dispatch({ type: Actions.SET_UPLOAD_TEMPLATE_STEP, data });
  },
  setGroupBy: (data) => {
    Stores.dispatch({ type: Actions.SET_GROUPBY, data });
  },
  setALLRole: (data) => {
    Stores.dispatch({ type: Actions.SET_ALL_ROLES, data });
  },
  setEquityAmount: (data) => {
    Stores.dispatch({ type: Actions.SET_EQUITY_AMOUNT, data });
  },
  setOutStandingLoan: (data) => {
    Stores.dispatch({ type: Actions.SET_OUTSTANDING_LOAN, data });
  },
  setLoanSectionBarOutStandingLoan: (data) => {
    Stores.dispatch({ type: Actions.SET_LOANSECTION_BAR_VALUE, data });
  },
  openK1Add: (data) => {
    Stores.dispatch({ type: Actions.OPEN_ADD_MANAGE_DATA, data });
  },
  openK1Edit: (data) => {
    Stores.dispatch({ type: Actions.OPEN_EDIT_MANAGE_DATA, data });
  },
  sendEmailContact: (data) => {
    Stores.dispatch({ type: Actions.SEND_EMAIL_CONTACT, data });
  },
  sendEmailIdContact: (data) => {
    Stores.dispatch({ type: Actions.SEND_EMAIL_ID_CONTACT, data });
  },
  setAdminEmailId: (data) => {
    Stores.dispatch({ type: Actions.ADMIN_EMAIL_ID, data });
  },
  retainFilters: (data) => {
    Stores.dispatch({
      type: Actions.RETAIN_FILTERS,
      data
    });
  },
  retainCount: (data) => {
    Stores.dispatch({
      type: Actions.RETAIN_COUNT,
      data
    });
  },
  retainPage: (data) => {
    Stores.dispatch({
      type: Actions.RETAIN_PAGE,
      data
    });
  },
  retainPageValue: (data) => {
    Stores.dispatch({
      type: Actions.RETAIN_PAGERESETVALUE,
      data
    });
  },
  retainPageSize: (data) => {
    Stores.dispatch({
      type: Actions.RETAIN_PAGESIZE,
      data
    });
  },
  retainManageColumn: (data) => {
    Stores.dispatch({
      type: Actions.RETAIN_MANAGECOLUMN,
      data
    });
  }
};

export default AppDispatcher;
