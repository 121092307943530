import FieldHeading from "../../../../components/fieldHeading";
import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import AppTextFieldWhite from "../../../../components/StyledComponents/AppTextFieldWhite";
import CustomSelect from "components/StyledComponents/Select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { preventNonNumericalInput } from "helpers";

const useStyles = makeStyles(() => ({
  root: {
    background: "#FAFAFA",
    padding: 20,
    borderRadius: 8
  },
  basicContainer2: {
    marginTop: 10
  },
  primaryContactText: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#191919"
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0
  },
  text: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#fff",
    height: 20,
    marginBottom: 4
  },
  deleteBtn: {
    fontSize: 14,
    fontFamily: "Inter Regular",
    color: "#E13838",
    marginTop: 10,
    cursor: "pointer"
  }
}));

const EditSubAdmin = ({ formik, roleList }) => {
  const classes = useStyles();

  return (
    <>
      <Grid xs={12} className={classes.root}>
        <Grid container spacing={2} className={classes.basicContainer2}>
          <Grid item xs={12} sm={6} md={3}>
            <FieldHeading title="First Name" />
            <AppTextFieldWhite
              id={formik.values.first_name}
              placeholder="Add First Name"
              variant="standard"
              fullWidth
              value={formik.values.first_name}
              onChange={(e) =>
                formik.setFieldValue("first_name", e.target.value)
              }
              error={
                formik.touched.first_name && Boolean(formik.errors.first_name)
              }
              helperText={formik.touched.first_name && formik.errors.first_name}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldHeading title="Last Name" />
            <AppTextFieldWhite
              id={formik.values.last_name}
              placeholder="Add Last Name"
              variant="standard"
              fullWidth
              value={formik.values.last_name}
              onChange={(e) =>
                formik.setFieldValue("last_name", e.target.value)
              }
              error={
                formik.touched.last_name && Boolean(formik.errors.last_name)
              }
              helperText={formik.touched.last_name && formik.errors.last_name}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldHeading title="Email ID" />
            <AppTextFieldWhite
              id={formik.values.email}
              placeholder="Add Email ID"
              variant="standard"
              fullWidth
              value={formik.values.email}
              onChange={(e) => formik.setFieldValue("email", e.target.value)}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
              disabled
            />
          </Grid>
          <Grid container item xs={12} sm={6} md={3} spacing={1}>
            <Grid item xs={4}>
              <FieldHeading title="Phone Number" />
              <PhoneInput
                country={"us"}
                placeholder="Code"
                fullWidth
                value={formik.values.country_code}
                onChange={(e) =>
                  formik.setFieldValue("country_code", e.target.value)
                }
                error={
                  formik.touched.country_code &&
                  Boolean(formik.errors.country_code)
                }
                helperText={
                  formik.touched.country_code && formik.errors.country_code
                }
                inputStyle={{
                  border: "1px solid #BBBBBB",
                  borderRadius: "6px",
                  fontSize: "15px",
                  width: "100%",
                  height: 48,
                  marginRight: 10
                }}
                dropdownStyle={{
                  width: "550%",
                  marginBottom: 30,
                  height: 100
                }}
                buttonStyle={{
                  borderRadius: "4px"
                }}></PhoneInput>
            </Grid>

            <Grid item xs={8}>
              <Typography className={classes.text}></Typography>
              <AppTextFieldWhite
                id={formik.values.phone_number}
                placeholder="Enter Phone Number"
                variant="standard"
                type="tel"
                inputProps={{ maxLength: 12 }}
                onKeyDown={preventNonNumericalInput}
                fullWidth
                style={{ marginLeft: 10 }}
                value={formik.values.phone_number}
                onChange={(e) =>
                  formik.setFieldValue("phone_number", e.target.value)
                }
                error={
                  formik.touched.phone_number &&
                  Boolean(formik.errors.phone_number)
                }
                helperText={
                  formik.touched.phone_number && formik.errors.phone_number
                }
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FieldHeading title="Role" />
            <CustomSelect
              fullWidth
              items={roleList}
              value={formik.values.access_role}
              onChange={(e) =>
                formik.setFieldValue("access_role", e.target.value)
              }
              error={formik.errors.access_role}
              touched={formik.touched.access_role}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default EditSubAdmin;
