import { Grid, makeStyles } from "@material-ui/core";
// import Button from "components/Buttons/button";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import Loader from "components/Loader/AppLoader";
import { Form, Formik } from "formik";
import EditAdmin from "./editSubAdmin";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Endpoint, NetworkManager } from "network/core";
import { useSelector } from "react-redux";
import AddBtn from "components/Buttons/addBtn";
import { SubAdminService } from "network/subAdminService";
import { validationSchemaEditSubAdmin } from "helpers/validationSchema";
import Toast from "components/toast";

const useStyle = makeStyles({
  MainGrid: { margin: "50px 0 0 0" },
  Gridcontainer: {
    borderRadius: 8,
    marginBottom: 20
  },
  GridItem: {
    backgroundColor: "#FFFFFF",
    borderRadius: 8,
    padding: "20px"
  },
  addTier: {
    cursor: "pointer",
    color: "#445EBE",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    display: "flex",
    alignItems: "center",
    minWidth: 160,
    marginTop: 10
  },
  btnContainer: {
    marginLeft: 20
  },
  addBtn: {
    marginLeft: 10
  }
});

const EditSubAdmin = () => {
  const classes = useStyle();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const { subAdminId } = useSelector((store) => store.app);
  const [role, setRole] = useState([]);
  const [initialValues, setInitialValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: null,
    country_code: "+1",
    access_role: ""
  });

  const handleClose = () => {
    history.push("/u/sub_admin");
  };

  const handleFormSubmit = async (values) => {
    const payload = {
      id: subAdminId,
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      country_code: values.country_code,
      phone_number: values.phone_number,
      access_role: values.access_role
    };
    const res = await SubAdminService.editSubAdmin(payload, subAdminId + "/");
    if (res.success) {
      Toast.showSuccessToast("Sub-Admin Updated Successfully");
      history.push("/u/sub_admin");
    } else {
      Toast.showErrorToast(res.error.message[0]);
    }
  };

  const fetchSubAdminData = async () => {
    setLoader(true);
    const instance = new NetworkManager(
      new Endpoint(`subadmin/sub-admin/${subAdminId}`, "GET")
    );
    const resp = await instance.httpRequest();
    const data = resp.data;
    if (resp.success) {
      const res = await SubAdminService.getRole();
      if (res.success) {
        const roleList = res?.data?.map((ele) => ({
          value: ele.id,
          label: ele.name
        }));
        const role = roleList?.find(
          (el) => el.label === data.access_role.access_roles.name
        );

        setRole(roleList);
        setInitialValues({
          first_name: data.user.first_name,
          last_name: data.user.last_name,
          email: data.user.email,
          phone_number: data.user.phone_number,
          country_code: data.user.country_code,
          access_role: role?.value
        });
      }
    }
    setLoader(false);
  };

  useEffect(() => {
    fetchSubAdminData();
  }, []);

  return (
    <>
      <HeaderComponent
        heading="Edit Sub-Admin"
        description="Edit or Update Sub-Admins"
        addInvestorBtn={false}
        backBtn
      />
      {loader ? (
        <Loader />
      ) : (
        <Grid className={classes.MainGrid}>
          <Formik
            initialValues={initialValues}
            enableReinitialize={true}
            validationSchema={validationSchemaEditSubAdmin}
            onSubmit={(values) => {
              handleFormSubmit(values);
            }}>
            {(formik) => (
              <Form>
                <Grid container className={classes.Gridcontainer}>
                  <Grid item xs>
                    <EditAdmin
                      classes={classes}
                      formik={formik}
                      roleList={role}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item>
                    <AddBtn
                      type="Cancel"
                      style={{
                        backgroundColor: "#fff",
                        color: "#445EBE",
                        borderRadius: 4,
                        border: "1px solid #445EBE",
                        fontFamily: "Inter Regular",
                        fontWeight: 600
                      }}
                      text="Cancel"
                      onClick={() => handleClose()}
                    />
                  </Grid>
                  <Grid className={classes.addBtn} item>
                    <AddBtn text="Update" type="submit" />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
};

export default EditSubAdmin;
