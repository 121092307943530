import { Dialog, DialogContent, makeStyles } from "@material-ui/core";
import Toast from "components/toast";
import { RoleService } from "network/rolesService";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ErrorIcon from "assets/icons/ErrorIcon.svg";

const useStyles = makeStyles(() => ({
  dialog: {
    backgroundColor: "rgba(235, 239, 255, 0.6)",
    backdropFilter: "blur(26)"
  },
  errorIcon: {
    height: 33,
    width: 33
  },
  dialogContent: {
    width: 560,
    padding: 50,
    boxSizing: "border-box"
  },
  dialogContent1: {
    width: 424,
    padding: 50,
    boxSizing: "border-box"
  },
  container: {
    marginTop: 30,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  heading: {
    fontFamily: "Inter Bold",
    fontSize: 18
  },
  content: {
    marginTop: 20,
    width: "100%",
    textAlign: "center",
    fontFamily: "Inter Regular",
    fontSize: 16,
    color: "#616161"
  },
  btnContainer: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    marginTop: 40
  },
  btnPrimary: {
    backgroundColor: "#E13838",
    color: "#FFFFFF",
    height: 50,
    width: 174,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginLeft: 10
  },
  btnSecondary: {
    border: "1px solid #445EBE",
    backgroundColor: "#EBEFFF",
    color: "#445EBE",
    height: 48,
    width: 172,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    marginRight: 10
  },
  btnPrimaryBlue: {
    height: 50,
    width: 174,
    backgroundColor: "#445EBE",
    color: "#FFFFFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    fontFamily: "Inter SemiBold",
    fontSize: 18,
    borderRadius: 4,
    marginTop: 40
  }
}));

const DeleteRole = () => {
  const classes = useStyles();
  const history = useHistory();
  const [deleteFailed, setDeleteFailed] = useState(false);
  const { roleId } = useSelector((store) => store.app);

  const handleClose = () => {
    history.goBack();
  };

  const deleteDistribution = async () => {
    const res = await RoleService.deleteRole([`${roleId}\\`]);
    if (res.success) {
      Toast.showSuccessToast(res.data.message);
      handleClose();
    } else {
      setDeleteFailed(res?.error?.message || "Failed To Delete Role");
    }
  };

  return (
    <>
      {deleteFailed ? (
        <Dialog
          open={true}
          onClose={handleClose}
          className={classes.dialog}
          maxWidth={false}>
          <DialogContent className={classes.dialogContent1}>
            <div className={classes.container}>
              <img className={classes.errorIcon} src={ErrorIcon} alt="Error" />
              <div className={classes.content}>{deleteFailed}</div>
              <div className={classes.btnPrimaryBlue} onClick={handleClose}>
                Okay
              </div>
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={true}
          onClose={handleClose}
          className={classes.dialog}
          maxWidth={false}>
          <DialogContent className={classes.dialogContent}>
            <div className={classes.container}>
              <div className={classes.heading}>
                Are You Sure You Want To Delete This Role?
              </div>
              <div className={classes.content}>
                You Are About To Delete A Role.This Will Permanently Remove All
                Its Assigned Permissions From Simplee.
              </div>
              <div className={classes.btnContainer}>
                <div className={classes.btnSecondary} onClick={handleClose}>
                  Cancel
                </div>
                <div
                  className={classes.btnPrimary}
                  onClick={deleteDistribution}>
                  Delete
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default DeleteRole;
