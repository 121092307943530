import { Box, Grid } from "@material-ui/core";
import FieldHeading from "components/fieldHeading";
import NewAutoComplete from "components/NewAutoComplete/NewAutoComplete";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import { EntityService } from "network/entityService";
import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import SponsorDilogBox from "./SponsorDilogBox/SponsorDilogBox";
import RedDelete from "assets/icons/NewCardSvg/RedDelete.svg";
import { preventNonNumericalInput } from "helpers";
import { AddInvestmentBasicDetailsService } from "network/addInvestment_Basic_DetailsServices";
import Toast from "components/toast";
import DeleteModal from "components/Modals/DeleteModal";

const NewSposnors = ({
  index,
  formik,
  sponsors,
  SettaxIDsearch,
  dropDownStyle,
  showAddMore,
  setShowAddMore,
  arrayHelper,
  ClickonSponsorEdit
}) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [data, setData] = useState([]);
  const [Emailsearch, SetEmailSearchQuery] = useState("");
  const [sponsorDilogOpen, setSposorDilogOpen] = useState("");

  const checkDelete = (deleteid) => {
    if (deleteid) {
      setDeleteModal(true);
    } else {
      const ArrayOfIds = [...formik.values.Sponsor.id];
      ArrayOfIds.splice(index, 1);
      formik.setFieldValue(`Sponsor.id`, ArrayOfIds);
      arrayHelper.remove(index);
    }
  };

  const deleteSponsor = async (id) => {
    const resp = await AddInvestmentBasicDetailsService.deleteSponsors(id);
    if (resp.success) {
      arrayHelper.remove(index);
      const ArrayOfIds = [...formik.values.Sponsor.id];
      ArrayOfIds.splice(index, 1);
      formik.setFieldValue(`Sponsor.id`, ArrayOfIds);
      Toast.showSuccessToast("Sponsor Deleted Succesfully");
      setDeleteModal(false);
    } else {
      Toast.showErrorToast("Failed Delete Sponsor");
    }
  };

  /**************************************************** */
  /********** fetch dropdown list data ********** */
  /**************************************************** */

  const getEmailList = async () => {
    let url = "";
    if (Emailsearch) {
      url = `?search=${Emailsearch}`;
    }

    const response = await EntityService.getSponsorEmailList([url]);
    if (response.success) {
      setData(response.data.results);
    } else {
      setData([]);
    }
  };

  /**************************************************** */
  /*********handle DropDown onClick******************** */
  /**************************************************** */

  const handleEmaildropFromData = (item) => {
    if (
      formik.values?.Sponsor?.sponsors_details?.findIndex(
        (ele) => ele.email === item.email
      ) === -1
    ) {
      SetEmailSearchQuery("");
      formik.setFieldValue(`Sponsor.id[${index}]`, item.id);
      formik.setFieldValue(
        `Sponsor.sponsors_details[${index}].email`,
        item.email || "-"
      );
      formik.setFieldValue(
        `Sponsor.sponsors_details[${index}].name`,
        `${item.first_name} ${item.last_name}` || "-"
      );
      formik.setFieldValue(
        `Sponsor.sponsors_details[${index}].company_name`,
        item.company_name || "-"
      );
      formik.setFieldValue(
        `Sponsor.sponsors_details[${index}].code`,
        item.country_code || "-"
      );
      formik.setFieldValue(
        `Sponsor.sponsors_details[${index}].phone`,
        item.phone_number || "-"
      );
    } else {
      Toast.showErrorToast(`Sponsor Already Selected`);
    }
  };

  /**************************************************** */
  /********* Set Fetched data to drop down ************ */
  /**************************************************** */

  const EmaildataList = data?.map((item) => (
    <div
      className={dropDownStyle.mainContainer}
      onClick={() => handleEmaildropFromData(item)}>
      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        {"Sponsor Email"}:
        <span className={dropDownStyle.darktext}> {item.email}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        {"Sponsor Name"}:
        <span
          className={
            dropDownStyle.darktext
          }>{` ${item.first_name} ${item.last_name}`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Company Name:
        <span className={dropDownStyle.darktext}>{` ${
          item?.company_name || "-"
        }`}</span>
      </div>

      <div
        className={`${dropDownStyle.lighttext}  ${dropDownStyle.lightTextOverflow}`}>
        Contact Number:
        <span className={dropDownStyle.darktext}>{` ${
          item?.country_code || "-"
        } ${item?.phone_number || " "}`}</span>
      </div>

      <div className={dropDownStyle.lighttext}>
        Managed By:
        <span className={dropDownStyle.darktext}> {item.managed_by}</span>
      </div>
    </div>
  ));

  /**************************************************** */
  /**************************************************** */

  useEffect(() => {
    getEmailList();
    SettaxIDsearch("");
  }, [Emailsearch]);

  useEffect(() => {
    if (showAddMore === "Sponsor") {
      setSposorDilogOpen(true);
    } else {
      setSposorDilogOpen(false);
    }
  }, [showAddMore]);

  return (
    <Fragment>
      <SponsorDilogBox
        sponsorDilogOpen={sponsorDilogOpen}
        setSposorDilogOpen={setSposorDilogOpen}
        setShowAddMore={setShowAddMore}
        formik={formik}
        index={index}
        SetEmailSearchQuery={SetEmailSearchQuery}
      />

      {index !== 0 && (
        <Box
          height={"30px"}
          minWidth={"105%"}
          bgcolor={"#FFF"}
          mt="20px"
          ml="-12px"
          mb="20px"
        />
      )}

      <Grid item xl={3} lg={4} sm={6}>
        <FieldHeading title="Email ID" />
        <NewAutoComplete
          disabled={
            ClickonSponsorEdit
              ? index == 0
                ? true
                : false || index <= formik.values.Sponsor.sponsorLength
              : !ClickonSponsorEdit
          }
          placeholder="Enter Email Id"
          // setExistingEmailSelected={setExistingEmailSelected}
          // setClickedOnAddbtn={setClickedOnAddbtn}
          btnText="Sponsor"
          setShowAddMore={setShowAddMore}
          search={Emailsearch}
          setSearchQuery={SetEmailSearchQuery}
          dataList={EmaildataList}
          fieldValue={formik.values.Sponsor.sponsors_details[index].email}
          fieldValueError={
            formik.touched?.Sponsor?.sponsors_details?.[index]?.email &&
            formik.errors?.Sponsor?.sponsors_details?.[index]?.email
          }
          setFieldValue={(val) =>
            formik.setFieldValue(
              `Sponsor.sponsors_details[${index}].email`,
              val
            )
          }
          formik={formik}
        />
        <div style={{ fontSize: "12px", fontWeight: 400, color: "#E13838" }}>
          {formik.touched?.Sponsor?.sponsors_details?.[index]?.email &&
            formik.errors?.Sponsor?.sponsors_details?.[index]?.email}
        </div>
      </Grid>
      <Grid item xl={3} lg={4} sm={6}>
        <FieldHeading title="Sponsor Name" />
        <AppTextFieldWhite
          id="total_asset"
          placeholder="Add Sponsor Name"
          variant="standard"
          fullWidth
          disabled
          value={sponsors.name}
          onChange={formik.handleChange(
            `Sponsor.sponsors_details[${index}].name`
          )}
          error={
            formik.touched?.Sponsor?.sponsors_details?.[index]?.name &&
            Boolean(formik.errors?.Sponsor?.sponsors_details?.[index]?.name)
          }
          helperText={
            formik.touched?.Sponsor?.sponsors_details?.[index]?.name &&
            formik.errors?.Sponsor?.sponsors_details?.[index]?.name
          }
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{ disableUnderline: true }}
        />
      </Grid>
      <Grid item xl={3} lg={4} sm={6}>
        <FieldHeading title="Company Name" />
        <AppTextFieldWhite
          id="company_name"
          placeholder="Add Company Name"
          variant="standard"
          fullWidth
          disabled
          value={sponsors.company_name}
          onChange={formik.handleChange(
            `Sponsor.sponsors_details[${index}].company_name`
          )}
          error={
            formik.touched?.Sponsor?.sponsors_details?.[index]?.company_name &&
            Boolean(
              formik.errors?.Sponsor?.sponsors_details?.[index]?.company_name
            )
          }
          helperText={
            formik.touched?.Sponsor?.sponsors_details?.[index]?.company_name &&
            formik.errors?.Sponsor?.sponsors_details?.[index]?.company_name
          }
          InputLabelProps={{
            shrink: true
          }}
          InputProps={{ disableUnderline: true }}
        />
      </Grid>
      <Grid item xl={3} lg={4} sm={6} style={{ width: 408 }}>
        <FieldHeading title="Phone Number" />
        <Grid container wrap="nowrap">
          <Grid item>
            <PhoneInput
              id="code"
              country={"us"}
              disabled
              placeholder="Code"
              value={sponsors.code}
              onChange={formik.handleChange(
                `Sponsor.sponsors_details[${index}].code`
              )}
              error={
                formik.touched?.Sponsor?.sponsors_details?.[index]?.code &&
                Boolean(formik.errors?.Sponsor?.sponsors_details?.[index]?.code)
              }
              helperText={
                formik.touched?.Sponsor?.sponsors_details?.[index]?.code &&
                formik.errors?.Sponsor?.sponsors_details?.[index]?.code
              }
              inputStyle={{
                border: "1px solid #F4F2FF",
                borderRadius: "6px",
                fontSize: "15px",
                background: "#E9E9E9",
                width: "110px",
                height: 50,
                marginRight: "12px"
              }}
              dropdownStyle={{
                width: "550%",
                marginBottom: 30,
                height: 100
              }}
              buttonStyle={{
                borderRadius: "4px"
              }}></PhoneInput>
            <div>
              {formik.touched?.Sponsor?.sponsors_details?.[index]?.code &&
                formik.errors?.Sponsor?.sponsors_details?.[index]?.code}
            </div>
          </Grid>

          <Grid item xs>
            <AppTextFieldWhite
              id="phone"
              placeholder="Enter Phone Number"
              variant="standard"
              type="tel"
              onKeyDown={preventNonNumericalInput}
              value={sponsors.phone}
              style={{ width: "100%" }}
              disabled
              onChange={formik.handleChange(
                `Sponsor.sponsors_details[${index}].phone`
              )}
              error={
                formik.touched?.Sponsor?.sponsors_details?.[index]?.phone &&
                Boolean(
                  formik.errors?.Sponsor?.sponsors_details?.[index]?.phone
                )
              }
              helperText={
                formik.touched?.Sponsor?.sponsors_details?.[index]?.phone &&
                formik.errors?.Sponsor?.sponsors_details?.[index]?.phone
              }
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{ disableUnderline: true }}
            />
          </Grid>
        </Grid>
      </Grid>
      {index !== 0 && (
        <Grid container item xs={12} justifyContent="flex-end">
          {ClickonSponsorEdit && (
            <div
              onClick={
                ClickonSponsorEdit ? () => checkDelete(sponsors.id) : () => {}
              }
              style={{ cursor: "pointer" }}>
              <img src={RedDelete} alt="delete" />
            </div>
          )}
        </Grid>
      )}
      <DeleteModal
        open={deleteModal}
        handleClose={() => setDeleteModal(false)}
        handleSubmit={() => deleteSponsor(sponsors.id)}
        title="Are You Sure You Want To Delete This Item?"
        description="This will permanently remove all data associated with it from Simplee."
      />
    </Fragment>
  );
};

export default NewSposnors;
