import { API, NetworkManager } from "./core";

export class InvestmentListService {
  static async getInvestmentListService(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_INVESTMENT_NAME,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async get_Pdf_Or_Csv(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.EXPORT_PDF_CSV,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async getInvestmentListServiceAllData(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_ALL_INVESTMENT_NAME,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async getInvestmentName(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_INVESTMENT_NAME,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async getInvestmentNameAllData(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_ALL_INVESTMENT_NAME,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async getInvestmentStats() {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_INVESTMENT_STATS
    );
    return await instance.httpRequest();
  }

  static async getSponserEntity(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_INVESTMENT_ENTITY,
      {},
      [params]
    );
    return await instance.httpRequest();
  }

  static async getInvestmentsInvestor() {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_INVESTMENT_ROLE_INVESTOR
    );
    return await instance.httpRequest();
  }

  static async getInvestors(params) {
    const instance = new NetworkManager(API.LIST_INVESTMENT.GET_INVESTORS, {}, [
      params
    ]);
    return await instance.httpRequest();
  }

  static async getInvestmentsSponsor() {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_INVESTMENT_ROLE_SPONSOR
    );
    return await instance.httpRequest();
  }

  static async getSponsors(params) {
    const instance = new NetworkManager(API.LIST_INVESTMENT.GET_SPONSORS, {}, [
      params
    ]);
    return await instance.httpRequest();
  }

  static async getCsv(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.GET_CSV,
      {},
      params
    );
    return await instance.httpRequest();
  }

  static async deleteInvestment(params) {
    const instance = new NetworkManager(
      API.LIST_INVESTMENT.DELETE_INVESTMENT,
      {},
      params
    );
    return await instance.httpRequest();
  }
}
