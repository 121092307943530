import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import styles from "../InvestmentDetails.module.scss";

const DataContainer = ({ data, title, xs }) => {
  return (
    <Grid container className={styles.overviewMainContainer}>
      <Grid container>
        <Typography className={styles.cardHeading}>{title}</Typography>
        <Grid container className={styles.overview_card_container} spacing={2}>
          {data?.map((item, index) => {
            return (
              <Grid item xs={xs} key={index}>
                <Box sx={{ pb: 0.3 }} className={styles.card_detail_heading}>
                  {item?.name}
                </Box>
                <Box className={styles.card_detail_data}>
                  {item?.fieldData || "-"}
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DataContainer;
