import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent/HeaderComponent";
import { Grid, makeStyles } from "@material-ui/core";
import Table from "./components/table/Table";
import { useSelector } from "react-redux";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useHistory } from "react-router-dom";

const useStyle = makeStyles({
  addContactButtonContainer: {
    height: "48px",
    padding: "12px 22px 12px 22px",
    width: "fit-content",
    cursor: "pointer",
    borderRadius: "50px",
    alignItems: "center",
    justifyContent: "center",
    marginRight: "15px",
    marginTop: "-4px",
    background:
      "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)"
  },
  addContactButtonText: {
    fontSize: "18px",
    fontFamily: "Inter SemiBold",
    fontWeight: 600,
    color: "#fff",
    textAlign: "center",
    whiteSpace: "noWrap"
  }
});

const Contacts = () => {
  const classes = useStyle();
  const [permissions, setPermissions] = useState();
  const { userPermissions, user } = useSelector((store) => store.app);
  const history = useHistory();

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "CONTACTS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    setPermissions(screenPermissions);
  };

  useEffect(() => {
    checkPermissions();
  }, []);

  const handleAddContacts = () => {
    history.push("/u/contacts/addcontacts");
  };

  return (
    <>
      <HeaderComponent
        heading="Contacts"
        description="List of all contacts on Simplee"
        ExtraButtons={
          permissions?.is_create ? (
            <div className={classes.addContactButtonContainer}>
              <p
                className={classes.addContactButtonText}
                onClick={() => handleAddContacts()}>
                + Add Contact
              </p>
            </div>
          ) : null
        }
      />
      <Grid style={{ marginTop: 30 }} container>
        <Grid item xs={12}>
          <Table permissions={permissions} />
        </Grid>
      </Grid>
    </>
  );
};

export default Contacts;
