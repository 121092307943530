import { Box, Grid, makeStyles } from "@material-ui/core";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import RedDelete from "assets/icons/NewCardSvg/RedDelete.svg";
import { Field } from "formik";
import React from "react";

const useStyles = makeStyles(() => ({
  container: {
    padding: 18,
    backgroundColor: "#FAFAFA"
  },
  unChecked: {
    width: 23,
    height: 23,
    border: "1.6px solid #445EBE",
    borderRadius: 6
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 23
  },
  field: {
    marginBottom: 10
  },
  deleteButton: {
    cursor: "pointer",
    marginTop: 24
  },
  checkbox: {
    height: 23,
    cursor: "pointer"
  },
  P_F_container: {
    background: "#FAFAFA",
    marginBottom: 20
  },
  label: {
    fontFamily: "Inter Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px"
  }
}));

const PartnerForm = ({ id, arrayHelper, formik, setPartnerId }) => {
  const classes = useStyles();

  const handleDelete = () => {
    arrayHelper.remove(id);
    setPartnerId((pre) => [...pre, formik.values.partners[id].id]);
  };

  // const handleCheck = () => {
  //   formik.setFieldValue(
  //     `partners[${id}].isChecked`,
  //     !formik.values.partners[id].isChecked
  //   );
  // };

  const partnerErrors =
    (formik.errors.partners &&
      formik.errors.partners.length &&
      formik.errors.partners[id]) ||
    {};
  const partnerTouched =
    (formik.touched.partners &&
      formik.touched.partners.length &&
      formik.touched.partners[id]) ||
    {};

  return (
    <Grid container spacing={2} wrap="nowrap" className={classes.P_F_container}>
      <Grid item xs>
        <Box sx={{ maxWidth: 369 }}>
          <label className={classes.label}>Name</label>
          <Field name={`partners[${id}].name`}>
            {({ field }) => (
              <AppTextFieldWhite
                {...field}
                name={`partners[${id}].name`}
                placeholder="Add Partner Name"
                variant="standard"
                // className={classes.field}
                onChange={formik.handleChange(`partners[${id}].name`)}
                error={partnerTouched.name && Boolean(partnerErrors.name)}
                helperText={partnerTouched.name && partnerErrors.name}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            )}
          </Field>
        </Box>
      </Grid>
      <Grid item xs>
        <Box sx={{ maxWidth: 369 }}>
          <label className={classes.label}>Email ID</label>
          <Field name={`partners[${id}].email`}>
            {({ field }) => (
              <AppTextFieldWhite
                {...field}
                name={`partners[${id}].email`}
                placeholder="Add Email ID"
                variant="standard"
                onChange={formik.handleChange(`partners[${id}].email`)}
                error={partnerTouched.email && Boolean(partnerErrors.email)}
                helperText={partnerTouched.email && partnerErrors.email}
                fullWidth
                InputLabelProps={{
                  shrink: true
                }}
                InputProps={{ disableUnderline: true }}
              />
            )}
          </Field>
        </Box>
      </Grid>
      <Grid item xs container justifyContent="flex-end" alignItems="center">
        <span onClick={handleDelete}>
          <img src={RedDelete} alt="del" className={classes.deleteButton} />
        </span>
      </Grid>
    </Grid>
  );
};

export default PartnerForm;
