import { Button, Grid, makeStyles } from "@material-ui/core";
// import AddBtn from "components/Buttons/addBtn";
import FieldHeading from "components/fieldHeading";
import HeaderComponent from "components/HeaderComponent/HeaderComponent";
import Loader from "components/Loader/AppLoader";
import CustomSearchContact from "components/StyledComponents/CustomSearchContact";
// import CustomSearchSelect from "components/StyledComponents/CustomSearchSelect";
import Toast from "components/toast";
import { FieldArray, Form, Formik } from "formik";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { validationSchemaAddContancts } from "helpers/validationSchema";
import { ContactsService } from "network/contactsService";
// import { InvestmentListService } from "network/investment_ListService";
import Contacts from "pages/private/investments/addInvestment/steps/step1/components/contacts";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddIcon from "../../../../assets/icons/PlusAdd.svg";

const useStyle = makeStyles({
  MainGrid: { margin: "50px 0 0 0" },
  Gridcontainer: {
    backgroundColor: "#FAFAFA",
    // backgroundColor: "red",
    // padding: 20,
    borderRadius: 8,
    marginBottom: 20
  },
  GridcontainerHeading: {
    backgroundColor: "#FAFAFA",
    // backgroundColor: "red",
    padding: 20,
    borderRadius: 8,
    marginBottom: 20
  },
  GridItem: {
    backgroundColor: "#FAFAFA",
    borderRadius: 8
  },
  addTier: {
    cursor: "pointer",
    color: "#445EBE",
    backgroundColor: "#FAFAFA",
    // backgroundColor: "red",

    fontFamily: "Inter SemiBold",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    display: "flex",
    padding: "20px",
    alignItems: "center",
    width: 280,
    flexWrap: "nowrap",
    "&:active": {
      transform: "scale(1.1)"
    }
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "15px"
  },
  buttonCancel: {
    fontFamily: "Inter SemiBold",
    fontSize: 16,
    height: 45,
    minWidth: 180,
    padding: "0px 30px !important",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#ffffff",
    color: "#5775E5",
    cursor: "pointer",
    border: "1px solid #5775E5",
    marginRight: "10px"
  },
  submitButton: {
    backgroundColor: "#5775E6",
    fontFamily: "Inter SemiBold",
    fontSize: 16,
    height: 45,
    minWidth: 180,
    padding: "0px 30px !important",
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#ffff",
    cursor: "pointer",
    border: "1px solid #5775E5",
    "&:hover": {
      backgroundColor: "#5775E6",
      fontFamily: "Inter SemiBold",
      fontSize: 16,
      height: 45,
      minWidth: 180,
      padding: "0px 30px !important",
      borderRadius: 4,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#ffff",
      cursor: "pointer",
      border: "1px solid #5775E5"
    }
  }
});

const AddContacts = () => {
  const classes = useStyle();
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const { userPermissions, user } = useSelector((store) => store.app);
  const [payload, setPayload] = useState([]);
  const [initialValues] = useState({
    investment_name: "",
    associatedContactsSet: [
      {
        first_name: "",
        last_name: "",
        email: "",
        phone_number: null,
        country_code: "+1",
        designation: "",
        company_name: "",
        is_primary: true
      }
    ]
  });

  const getInvestmentName = async (searchText = "") => {
    const active_Inactive = [];
    let params = "";
    if (searchText) {
      params += `?search=${searchText}`;
    }

    const response = await ContactsService.getinvestmentDropdownValues(
      `${params}`
    );

    if (response.success) {
      active_Inactive.push(...response.data);
    }
    return {
      data: active_Inactive,
      maxPage: 0
    };
  };

  const onHandleChange = (formik, data) => {
    formik.setFieldValue("investment_name", data.id);
    setSelectedItem(data);
  };

  // generated payload of contacts
  const Addcontactpayload = (values) => {
    const investmentId = values.investment_name;
    const data = values.associatedContactsSet;

    const payloadData = data.map((obj) => ({
      ...obj,
      investment: investmentId
    }));

    setPayload(payloadData);
  };

  // Add contact with associated invesetment
  const AddContacts = async () => {
    if (payload.length) {
      setLoader(true);
      const datatobeSend = { contacts: payload };
      const res = await ContactsService.addContact(datatobeSend);
      if (res.success) {
        Toast.showSuccessToast("Contacts Added Successfully");
        history.push("/u/contacts");
        setLoader(false);
      } else {
        setLoader(false);
        return Toast.showErrorToast("Invalid Phone Number");
      }
    }
  };

  const checkPermissions = () => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "CONTACTS",
      user
    );
    if (!screenPermissions?.is_retrieve || !screenPermissions?.is_create) {
      return history.replace(getPageWithPermission(userPermissions));
    }
  };

  useEffect(() => {
    checkPermissions();
    AddContacts();
    getInvestmentName();
  }, [payload]);

  return (
    <>
      <HeaderComponent
        heading="Add contacts"
        description="Add Relevent Details To The Specific Investor And Investment Profile"
        addInvestorBtn={false}
        backBtn
      />
      {loader ? (
        <Loader />
      ) : (
        <Grid className={classes.MainGrid}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaAddContancts}
            onSubmit={(values) => {
              Addcontactpayload(values);
            }}>
            {(formik) => (
              <Form>
                <Grid container className={classes.GridcontainerHeading}>
                  <Grid
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={4}
                    className={classes.GridItem}>
                    <FieldHeading title={"Associated Investment"} />
                    <CustomSearchContact
                      getData={getInvestmentName}
                      onChange={(data) => onHandleChange(formik, data)}
                      selectedItem={selectedItem}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.Gridcontainer}>
                  <Grid item xs>
                    <FieldArray
                      name="associatedContactsSet"
                      render={(arrayHelper) => (
                        <>
                          {formik.values.associatedContactsSet.map(
                            (sponsors, index) => (
                              <Contacts
                                boxType={true}
                                add={true}
                                type={true}
                                ClickonContactsEdit={true}
                                key={index}
                                classes={classes}
                                id={index}
                                arrayHelper={arrayHelper}
                                formik={formik}
                              />
                            )
                          )}
                          <Grid
                            style={{
                              height: "20px",
                              backgroundColor: "#ffff"
                            }}></Grid>
                          <div
                            className={classes.addTier}
                            onClick={() =>
                              arrayHelper.push({
                                first_name: "",
                                last_name: "",
                                email: "",
                                phone_number: null,
                                country_code: "+1",
                                designation: "",
                                company_name: "",
                                is_primary: true
                              })
                            }>
                            <img
                              src={AddIcon}
                              alt=""
                              style={{ marginRight: 8 }}
                            />
                            <div>Add Additional Contacts</div>
                          </div>
                        </>
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.btnContainer}>
                  <Grid>
                    <Button
                      className={classes.buttonCancel}
                      onClick={() => {
                        history.goBack();
                      }}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    {/* <AddBtn text="Add Contacts" type="submit" /> */}
                    <Button type="submit" className={classes.submitButton}>
                      Add Contacts
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      )}
    </>
  );
};

export default AddContacts;
