import React from "react";
import styles from "../illustration/Illustration.module.scss";

const Illustrations = ({ src = "#", heading = "", subHeading = "" }) => {
  return (
    <div className={styles.mainContainer}>
      {src === null ? null : <img src={src} alt="icon" />}
      <h3 className={styles.mainHeading}>{heading}</h3>
      <h5 className={styles.mainSubheading}>{subHeading}</h5>
    </div>
  );
};

export default Illustrations;
