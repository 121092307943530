import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";

const LightTooltips = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "#0000",
    boxShadow: "0px 3px 8px -5px black",
    fontSize: 11,
    minWidth: "fit-content",
    borderRadius: "10px"
  }
}))(Tooltip);

function LightTooltip({ title, children }) {
  return (
    <LightTooltips title={title} placement="bottom">
      {children}
    </LightTooltips>
  );
}

export default LightTooltip;
