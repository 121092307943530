import React from "react";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import ToastErrorIcon from "../assets/icons/ErrorToastICon.svg";
import ToastSuccessIcon from "../assets/icons/SuccessToastIcon.svg";

const Container = (props) => <div>{props.children}</div>;



const customMessage = (icon, msg) => {
  return (
    <Container>
      <Grid container>
        <Grid item xs={2}>
          <img style={{ marginLeft: 20, marginRight: 20 }} src={icon} alt="I" />
        </Grid>
        <Grid item xs={10}>
          {msg}
        </Grid>
      </Grid>
    </Container>
  );
};

const showErrorToast = (message) => {
  toast.error(customMessage(ToastSuccessIcon, message), {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    pauseOnFocusLoss: false,
  });
};

const showInfoToast = (message) => {
  toast.info(message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    pauseOnFocusLoss: false,
  });
};

const showSuccessToast = (message) => {
  toast.success(customMessage(ToastErrorIcon, message), {
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    pauseOnFocusLoss: false,
  });
};

const showWarnToast = (message, position) => {
  toast.warn(message, {
    position: position ? position : "top-left",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    pauseOnFocusLoss: false,
  });
};

const Toast = {
  showSuccessToast,
  showErrorToast,
  showInfoToast,
  showWarnToast,
};

export default Toast;
