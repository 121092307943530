import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@material-ui/core";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { Form, Formik } from "formik";
import edit from "assets/icons/EditIcon.svg";
import styles from "../../profile.module.scss";
import { useSelector } from "react-redux";
import { API, NetworkManager, Endpoint } from "network/core/index";
import Toast from "components/toast";
import ProfileIcon from "assets/icons/defaultProfilePic.svg";
import CloseIcon from "assets/icons/CloseIcon.svg";
// import AppTextField from "components/StyledComponents/AppTextFeild";
import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ProfileServices } from "network/profileServices";
import { validationSchemaAdminEditProfile } from "helpers/validationSchema";

const FormDial = ({ data, updateRecord, detailType }) => {
  const [open, setOpen] = useState(false);
  const hiddenFileInput = React.useRef(null);
  const [profileImageData, setProfileImageData] = useState({
    image_id: "",
    image_url: data.image_url
  });
  const [imageLoader, setImageLoader] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const { user } = useSelector((state) => state.app);

  const updateAdminDetails = async (values) => {
    const payload = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone_number: values.phone_number,
      profile_image: profileImageData.image_id,
      country_code: `${
        values.country_code[0] === "+"
          ? values.country_code
          : `+${values.country_code}`
      }`
    };

    const instance = new NetworkManager(
      new Endpoint(`admin/profile/${user.id}/`, "PATCH"),
      payload
    );
    const resp = await instance.httpRequest();
    if (resp.success) {
      Toast.showSuccessToast("Profile Updated Successfully");
      setOpen(false);
      updateRecord();
    } else {
      Toast.showErrorToast(resp.error.message[0]);
    }
  };

  const handleImageChange = (e) => {
    let ext = e.target.value.split(".").pop();
    switch (ext) {
      case "jpg":
        break;
      case "jpeg":
        break;
      case "png":
        break;
      default:
        Toast.showErrorToast(
          "Only JPG, JPEG and PNG File Type Are Allowed. Please Upload Valid File Type"
        );
        return;
    }
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleUploadProfileImage(data);
    }
    e.target.value = "";
  };

  const refreshProfileData = () => {
    let tempData = { ...profileImageData };
    tempData["image_url"] = data.image_url;
    setProfileImageData(tempData);
  };

  const handleDeletProfilePic = async () => {
    setImageLoader(true);
    const resp = await ProfileServices.deleteAdminProfilePic(
      profileImageData.image_id
        ? profileImageData.image_id
        : data.profile_pic_id
    );
    if (resp.success) {
      setImageLoader(false);
      let tempData = { ...profileImageData };
      tempData["image_url"] = null;
      tempData["image_id"] = "";
      setProfileImageData(tempData);
      Toast.showSuccessToast("Profile Picture Removed");
    } else {
      Toast.showErrorToast("Unable To Remove Profile Picture");
    }
  };

  const handleUploadProfileImage = async (data) => {
    setImageLoader(true);
    const instance = new NetworkManager(API.COMMON.IMAGE_UPLOAD, data);
    const resp = await instance.httpRequest(true, true);
    if (resp.success) {
      const img_id = resp.data.id;
      setImageLoader(false);
      const imageUrl = resp.data.file;
      let tempData = { ...profileImageData };
      tempData["image_id"] = img_id;
      tempData["image_url"] = imageUrl;
      setProfileImageData(tempData);
    } else {
      Toast.showErrorToast("Profile Pic Not Uploaded");
    }
  };

  useEffect(() => {
    refreshProfileData();
  }, [data]);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleClose = () => {
    setOpen(false);
    updateRecord();
  };

  return (
    <div>
      {/* <Button  onClick={handleClickOpen}> */}
      <img
        height={65}
        width={65}
        src={edit}
        className={styles.sample}
        onClick={handleClickOpen}
        style={{ visibility: detailType === "personal" ? "show" : "hidden" }}
      />

      {/* </Button> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogContent style={{ width: 580 }}>
          <Grid container justifyContent="center" style={{ padding: 30 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h5">Edit Profile</Typography>
              </Grid>
              <Grid item>
                <img
                  src={CloseIcon}
                  onClick={handleClose}
                  height={35}
                  width={35}
                  style={{ cursor: "pointer" }}
                />
              </Grid>
            </Grid>
            <Grid container></Grid>

            <Grid container style={{ marginTop: 20, alignItems: "center" }}>
              <Grid item>
                {imageLoader ? (
                  <div
                    style={{ height: 100, width: 100, alignItems: "center" }}>
                    <CircularProgress style={{ margin: 25 }} />
                  </div>
                ) : (
                  <img
                    src={
                      profileImageData.image_url !== null
                        ? profileImageData.image_url
                        : ProfileIcon
                    }
                    height="100px"
                    width="100px"
                    style={{
                      borderRadius: "100px"
                    }}
                  />
                )}
              </Grid>
              <Grid item>
                <button
                  type="button"
                  className={styles.uploadprofileimage}
                  onClick={handleClick}>
                  Upload
                  <input
                    type="file"
                    onChange={handleImageChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                    accept="image/x-png,image/gif,image/jpeg"
                  />
                </button>
                <button
                  type="button"
                  onClick={() => handleDeletProfilePic()}
                  className={styles.deleteProfileImage}>
                  Remove
                </button>
              </Grid>
            </Grid>
            <Formik
              initialValues={data}
              validationSchema={validationSchemaAdminEditProfile}
              onSubmit={(values) => updateAdminDetails(values)}
              enableReinitialize={true}>
              {({ errors, touched, values, handleSubmit, handleChange }) => {
                return (
                  <Form>
                    <Grid
                      container
                      style={{ marginTop: 20 }}
                      spacing={1}
                      xs={12}>
                      <Grid
                        item
                        style={{ display: "flex", flexDirection: "column" }}
                        xs={6}>
                        <label className={styles.labelsEdit}>First Name</label>
                        <AppTextFieldWhite
                          id="first_name"
                          placeholder="Enter First Name"
                          variant="standard"
                          value={values.first_name}
                          style={{ width: 220 }}
                          onChange={handleChange("first_name")}
                          error={
                            touched.first_name && Boolean(errors.first_name)
                          }
                          helperText={touched.first_name && errors.first_name}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>
                      <Grid
                        item
                        style={{ display: "flex", flexDirection: "column" }}
                        xs={6}>
                        <label className={styles.labelsEdit}>Last Name</label>
                        <AppTextFieldWhite
                          id="last_name"
                          placeholder="Enter Last Name"
                          variant="standard"
                          value={values.last_name}
                          style={{ width: 220 }}
                          onChange={handleChange("last_name")}
                          error={touched.last_name && Boolean(errors.last_name)}
                          helperText={touched.last_name && errors.last_name}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>
                    </Grid>
                    {/* <Grid
                      container
                      xs={12}
                      style={{ marginTop: 10 }}
                      spacing={2}>
                      <Grid item xs={12}>
                        <label className={styles.labelsEdit}>Email</label>
                        <AppTextField
                          id="email"
                          placeholder="Enter investor name"
                          variant="standard"
                          value={values.email}
                          fullWidth
                          onChange={handleChange("email")}
                          error={touched.email && Boolean(errors.email)}
                          helperText={touched.email && errors.email}
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{ disableUnderline: true }}
                          disabled={true}
                        />
                      </Grid>
                    </Grid> */}
                    <Grid
                      container
                      xs={12}
                      style={{ marginTop: 10 }}
                      spacing={2}>
                      <Grid item xs={4}>
                        <label className={styles.labelsEdit}>
                          Mobile Number
                        </label>
                        <PhoneInput
                          country={"us"}
                          placeholder="Code"
                          value={values.country_code}
                          onChange={handleChange("country_code")}
                          error={
                            touched.phone_number && Boolean(errors.phone_number)
                          }
                          helperText={
                            touched.phone_number && errors.phone_number
                          }
                          inputStyle={{
                            border: "1px solid #F4F2FF",
                            borderRadius: "6px",
                            fontSize: "15px",
                            width: "100%",
                            height: 50
                          }}
                          dropdownStyle={{
                            width: "550%",
                            marginBottom: 30,
                            height: 100
                          }}
                          buttonStyle={{
                            borderRadius: "4px"
                          }}></PhoneInput>
                      </Grid>

                      <Grid item xs={8}>
                        <label style={{ color: "#fff" }}>Test</label>
                        <AppTextFieldWhite
                          id="phone_number"
                          placeholder="Enter Phone Number Here"
                          variant="standard"
                          type="tel"
                          value={values.phone_number}
                          fullWidth
                          onChange={handleChange("phone_number")}
                          error={
                            touched.phone_number && Boolean(errors.phone_number)
                          }
                          helperText={
                            touched.phone_number && errors.phone_number
                          }
                          InputLabelProps={{
                            shrink: true
                          }}
                          InputProps={{ disableUnderline: true }}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      xs={12}
                      justifyContent="center"
                      direction="row"
                      style={{ marginTop: 10 }}>
                      <div className={styles.changepasswordbutton}>
                        <button
                          size="large"
                          className={styles.cancelbtn}
                          type="button"
                          onClick={handleClose}>
                          Cancel
                        </button>
                        <button
                          size="large"
                          type="submit"
                          className={styles.updatebtn}
                          onClick={handleSubmit}>
                          Update
                        </button>
                      </div>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FormDial;
