import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import SearchIcon from "@material-ui/icons/Search";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(() => ({
  list: {
    height: "290px",
    overflow: "hidden",
    overflowY: "scroll"
  },
  leftSubHeading: {
    color: "#000000",
    fontSize: "14px",
    fontWeight: "400"
  },
  leftHeading: {
    fontWeight: "700",
    fontSize: "18px",
    paddingBottom: "10px",
    color: "#000000"
  },
  searchBox: {
    borderRadius: "16px",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "#EBEFFF",
    margin: "20px 46px 16px 0px",
    padding: "3px 0",
    display: "flex",
    ["@media (max-width:704px)"]: {
      // eslint-disable-line no-useless-computed-key
      margin: "20px 1px 16px -7px"
    }
  },
  iconButton: {
    margin: "-4px -3px"
  },
  buttonStyleCancel: {
    width: "174px",
    height: "48px",
    border: "1px solid #445EBE",
    marginRight: "15px",
    color: "#445EBE"
  },
  buttonStyle: {
    width: "174px",
    height: "48px",
    background:
      "radial-gradient(137.5% 137.5% at 64.58% 4.17%, #5775E6 0%, #445EBE 100%)",
    color: "white"
  }
}));

const ChooseColumn = ({
  handleToggle,
  checked,
  columnNames,
  handleSelectAll,
  ExtractAllHeaders,
  searchTerm,
  handleSearch,
  handleClose,
  handleSave
}) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.leftHeading}>Choose columns</div>
      <div className={classes.leftSubHeading}>
        Select columns which should be visible within the table
      </div>
      <div className={classes.searchBox}>
        <IconButton className={classes.iconButton} aria-label="menu">
          <SearchIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder="Search"
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <List className={classes.list}>
        <ListItem
          style={{ marginLeft: "-15px" }}
          disabled={ExtractAllHeaders.length === checked.length}
          onClick={handleSelectAll}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={ExtractAllHeaders.length === checked.length}
              tabIndex={-1}
              disableRipple
              color="primary"
              inputProps={{ "aria-labelledby": `checkbox-list-label-All` }}
            />
          </ListItemIcon>
          <ListItemText id={`checkbox-list-label-All`} primary={"All"} />
        </ListItem>
        {columnNames.map((value) => {
          const labelId = `checkbox-list-label-${value}`;

          return (
            value !== "Action" && (
              <ListItem
                key={value}
                role={undefined}
                dense
                button
                disableGutters={true}
                onClick={handleToggle(value)}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(value) !== -1}
                    tabIndex={-1}
                    disableRipple
                    color="primary"
                    inputProps={{ "aria-labelledby": labelId }}
                  />
                </ListItemIcon>
                <ListItemText id={labelId} primary={value} />
              </ListItem>
            )
          );
        })}
      </List>
      <div style={{ padding: 25, display: "flex" }}>
        <Button
          // variant="outlined"
          className={classes.buttonStyleCancel}
          onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className={classes.buttonStyle}
          disableElevation={true}
          onClick={() => {
            handleSave();
            handleClose();
          }}>
          Update
        </Button>
      </div>
    </>
  );
};

export default ChooseColumn;
