import React from "react";
import Streetview from "react-google-streetview";

function StreetViewComponent({ propertyData }) {
  const googleMapsApiKey = "AIzaSyA_J-TXbKBup88CxDVCXEHvfgYBzE3RkYo";

  const streetViewPanoramaOptions = {
    position: {
      lat: parseFloat(propertyData.property_address.latitude),
      lng: parseFloat(propertyData.property_address.longitude)
    },
    pov: { heading: 100, pitch: 0 },
    zoom: 1,
    addressControl: true,
    showRoadLabels: true,
    zoomControl: true
  };

  return (
    <Streetview
      apiKey={googleMapsApiKey}
      streetViewPanoramaOptions={streetViewPanoramaOptions}
    />
  );
}

export default StreetViewComponent;
