import React from "react";
import styles from "./Error404.module.scss";
import { Typography } from "@material-ui/core";
import Logo from "../assets/icons/Logo.svg";
import FourOhFour from "../assets/icons/FourOhFour.svg";
import PrimaryButton from "../components/Buttons/Primary";
import { useHistory } from "react-router-dom";

function Error404() {
  const history = useHistory();

  const onGoBackClick = () => {
    history.push("/");
  }

  return (
    <div className={styles.root}>
      <div className={styles.logo}>
        <img
          className={styles.logo}
          src={Logo}
          alt="Logo"
        />
      </div>
      <div className={styles.illustration}>
        <img
          className={styles.img}
          src={FourOhFour}
          alt="404"
        />
      </div>
      <div className={styles.bottomCtr}>
        <div><Typography className={styles.mainHeading}>404 - Something missing</Typography></div>
        <div><Typography className={styles.mainSubheading}>We could not find page you are looking for.</Typography></div>
        <div className={styles.btnCtr}>
          <PrimaryButton
            text="Go Back"
            onPress={onGoBackClick}
            width="233px"
          />
        </div>
      </div>
    </div>
  );
}

export default Error404;
