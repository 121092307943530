import HeaderComponent from "../../../../components/HeaderComponent/HeaderComponent";
import React, { useEffect, useState } from "react";
import BasicDetails from "./steps/step1/step1_Basic";
import Investor from "./steps/step2/step2_Investor";
import { useSelector } from "react-redux";
import Loans from "./steps/step4/step4_loan";
import Refinances from "./steps/step5/step5_refinance";
import Distributions from "./steps/step6/step6_distribution";
import { AddInvestmentPreviewService } from "network/addInvestmentPreviewService";
import {
  getPageWithPermission,
  getScreenPermissions
} from "helpers/permissionChecker";
import { useHistory } from "react-router-dom";
import Wizard from "./components/Wizard";
import NewPreview from "./steps/step7/NewPreview";

const AddInvestment = () => {
  const { investmentActiveStep, investmentID, userPermissions, user } =
    useSelector((store) => store.app);
  const [isEditing, setIsEditing] = useState(false);
  const [status, setStatus] = useState("Draft");
  const history = useHistory();

  const loadStep = () => {
    switch (investmentActiveStep) {
      case 0:
        return <BasicDetails />;
      case 1:
        return <Investor />;
      case 2:
        return <Loans />;
      case 3:
        return <Refinances />;
      case 4:
        return isEditing && status !== "Draft" ? (
          <NewPreview />
        ) : (
          <Distributions />
        );
      case 5:
        return <NewPreview />;
      default:
        return <Investor />;
    }
  };

  const checkPermissions = (isEditing) => {
    const screenPermissions = getScreenPermissions(
      userPermissions,
      "INVESTMENTS",
      user
    );
    if (!screenPermissions?.is_retrieve) {
      return history.replace(getPageWithPermission(userPermissions));
    }
    if (isEditing) {
      if (!screenPermissions?.is_update) {
        return history.replace(getPageWithPermission(userPermissions));
      }
    } else {
      if (!screenPermissions?.is_create) {
        return history.replace(getPageWithPermission(userPermissions));
      }
    }
  };

  useEffect(async () => {
    if (investmentID) {
      const res = await AddInvestmentPreviewService.getInvestmentDetails([
        investmentID + "/?fields=is_draft,status"
      ]);
      if (res.success && !res.data.is_draft) {
        checkPermissions(true);
        setIsEditing(true);
        setStatus(res.data?.investment?.status);
      } else {
        checkPermissions(false);
      }
    } else {
      setStatus("New");
    }
  }, []);

  return (
    <div>
      <HeaderComponent
        heading="Add Investment"
        description="Add Or Update Your Investments"
        backBtn={true}
        investment_Status={status}
      />
      <Wizard isEditing={isEditing} status={status} />
      {loadStep()}
    </div>
  );
};

export default AddInvestment;
