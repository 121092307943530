import React, { useRef, useState } from "react";
import { Box, CircularProgress, makeStyles } from "@material-ui/core";
import CustomSelect from "components/StyledComponents/Select";
import styles from "../../../../../pages/private/myProfile/profile.module.scss";
import UploadIcon from "../../../../../assets/icons/upload.svg";
import FileUploadIcon from "../../../../../assets/icons/FileUpload.svg";
import DeleteIcon from "../../../../../assets/icons/delete.svg";
import Toast from "components/toast";
import { CommonService } from "../../../../../network/commonService";
import { verifyDocUploadType } from "helpers/Common";

const useStyles = makeStyles(() => ({
  card: {
    backgroundColor: "#FAFAFA",
    padding: 20,
    marginBottom: 10
  },
  label: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400
  },
  deleteButton: {
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    color: "#E13838",
    cursor: "pointer"
  },
  allDocsContainer: {
    display: "flex",
    flexDirection: "column"
  },
  docContainer: {
    display: "flex",
    alignItems: "center",
    minHeight: 68,
    backgroundColor: "#E5E9ED",
    borderRadius: 4,
    justifyContent: "space-between",
    paddingLeft: 20,
    marginTop: 20
  },
  docImg: {
    height: 40,
    width: 40,
    borderRadius: 8
  },
  docText: {
    flexGrow: 1,
    fontFamily: "Inter Regular",
    fontSize: 18,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: 10,
    lineHeight: 2,
    maxWidth: 350
  },
  docDelete: {
    marginRight: 20
  },
  error: {
    color: "#E13838",
    fontSize: "0.75rem",
    marginTop: -10,
    marginBottom: 10
  }
}));

const DocumentUploadCard = ({ formik, index, arrayHelper, allCategories }) => {
  const classes = useStyles();
  const [docsLoading, setDocsLoading] = useState(false);
  const wrapperRef = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add("dragover");
  const onDragLeave = () => wrapperRef.current.classList.remove("dragover");
  const onDrop = () => wrapperRef.current.classList.remove("dragover");

  const onFileDrop = (e) => {
    if (!verifyDocUploadType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleImageUpload(data);
    }
  };

  const deleteImage = async (id) => {
    const resp = await CommonService.deleteMedia(id);
    if (resp.success) {
      formik.setFieldValue(
        `docsSet[${index}].documents`,
        formik.values.docsSet[index].documents.filter((e) => e.id !== id)
      );
      Toast.showSuccessToast("Document Deleted Successfully");
    } else {
      Toast.showErrorToast("Unable To Delete Document");
    }
  };

  const handleImageUpload = async (data) => {
    setDocsLoading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      const updatedItem = {
        name: resp.data.name,
        id: resp.data.id,
        link: resp.data.file
      };
      const updatedFileList = [
        ...formik.values.docsSet[index].documents,
        updatedItem
      ];
      formik.setFieldValue(`docsSet[${index}].documents`, updatedFileList);
      Toast.showSuccessToast("Documents Upload Successfully");
    } else {
      Toast.showErrorToast("Error Uploading Documents");
    }
    setDocsLoading(false);
  };

  const docsErrors =
    (formik.errors.docsSet &&
      formik.errors.docsSet.length &&
      formik.errors.docsSet[index]) ||
    {};
  const docsTouched =
    (formik.touched.docsSet &&
      formik.touched.docsSet.length &&
      formik.touched.docsSet[index]) ||
    {};

  return (
    <div className={classes.card} key={index}>
      <label className={classes.label}>Select document type</label>
      <CustomSelect
        fullWidth={true}
        items={allCategories}
        onChange={formik.handleChange(`docsSet[${index}].selectedType`)}
        value={formik.values.docsSet[index].selectedType}
        error={docsErrors.selectedType}
        touched={docsTouched.selectedType}
      />
      <Box height={20} />
      <div
        ref={wrapperRef}
        className={styles.drop_file_input}
        style={{ width: 480, minHeight: 136, marginRight: 20 }}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}>
        <div>
          {docsLoading ? (
            <CircularProgress />
          ) : (
            <>
              <img src={UploadIcon} className={styles.uploadImage} />
              <p className={styles.droptext}>
                Drag & Drop Files Here Or Click To Upload{" "}
              </p>
            </>
          )}
        </div>

        <input type="file" value="" onChange={onFileDrop} />
      </div>
      {formik.values.docsSet[index].documents.length ? (
        <div className={classes.allDocsContainer}>
          {formik.values.docsSet[index].documents.map((item) => (
            <>
              <div key={item.id} className={classes.docContainer}>
                <div
                  style={{ display: "flex", flexDirection: "row", width: 480 }}>
                  {item.link.endsWith("png") ||
                  item.link.endsWith("jpeg") ||
                  item.link.endsWith("jpg") ? (
                    <>
                      <img src={item.link} className={classes.docImg} />
                    </>
                  ) : (
                    <img src={FileUploadIcon} className={classes.docImg} />
                  )}
                  <div className={classes.docText}> {item.name}</div>
                </div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteImage(item.id)}>
                  <img src={DeleteIcon} className={classes.docDelete} />
                </div>
              </div>
            </>
          ))}
        </div>
      ) : (
        <Box height={20} />
      )}
      {docsErrors.documents && docsTouched.documents && (
        <div className={classes.error}>{docsErrors.documents}</div>
      )}
      <div
        onClick={() => arrayHelper.remove(index)}
        className={classes.deleteButton}>
        Delete
      </div>
    </div>
  );
};

export default DocumentUploadCard;
