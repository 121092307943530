import AppTextFieldWhite from "components/StyledComponents/AppTextFieldWhite";
import styles from "../../../../myProfile/profile.module.scss";
import React from "react";
import AppTextField from "components/StyledComponents/AppTextFeild";
import NewSelect from "components/StyledComponents/NewSelect";
import { Grid } from "@material-ui/core";

const EntityForm = ({ formik, classes }) => {
  return (
    <>
      <Grid style={{ paddingBottom: "40px" }} spacing={2} container>
        <Grid
          style={{ display: "flex", flexDirection: "column" }}
          item
          md={4}
          xs={6}>
          <label className={styles.labelsEdit}>Entity For</label>
          <AppTextFieldWhite
            id="entityFor"
            placeholder="Entity For"
            variant="standard"
            capitalize
            value={formik.values.entityFor}
            className={classes.textFields}
            style={{ textTransform: "capitalize" }}
            onChange={formik.handleChange("entityFor")}
            error={formik.touched.entityFor && Boolean(formik.errors.entityFor)}
            helperText={formik.touched.entityFor && formik.errors.entityFor}
            InputLabelProps={{
              shrink: true
            }}
            inputStyle={{ textTransform: "capitalize" }}
            InputProps={{
              disableUnderline: true,
              sx: { textTransform: "capitalize" }
            }}
            disabled={true}
          />
        </Grid>
        <Grid
          style={{ display: "flex", flexDirection: "column" }}
          item
          md={4}
          xs={6}>
          <label className={styles.labelsEdit}>Investor Name</label>
          <AppTextFieldWhite
            id="firstName"
            placeholder="Enter First Name"
            variant="standard"
            value={`${formik.values.firstName} `}
            className={classes.nameTextFields}
            onChange={formik.handleChange("firstName")}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
            disabled={true}
          />
        </Grid>
        <Grid
          style={{ display: "flex", flexDirection: "column" }}
          item
          md={4}
          xs={6}>
          <label className={styles.labelsEdit}>Tax ID (EIN)</label>
          <AppTextFieldWhite
            id="taxId"
            placeholder="taxId"
            variant="standard"
            value={formik.values.taxId}
            className={classes.nameTextFields}
            onChange={formik.handleChange("taxId")}
            error={formik.touched.taxId && Boolean(formik.errors.taxId)}
            helperText={formik.touched.taxId && formik.errors.taxId}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
            disabled={true}
          />
        </Grid>
        <Grid
          style={{ display: "flex", flexDirection: "column" }}
          item
          md={4}
          xs={6}>
          <label className={styles.labelsEdit}>Entity Name</label>
          <AppTextField
            id="entityName"
            placeholder="Enter Entity Name"
            variant="standard"
            value={formik.values.entityName}
            className={classes.textFields}
            onChange={formik.handleChange("entityName")}
            error={
              formik.touched.entityName && Boolean(formik.errors.entityName)
            }
            helperText={formik.touched.entityName && formik.errors.entityName}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid
          style={{ display: "flex", flexDirection: "column" }}
          item
          md={4}
          xs={6}>
          <label className={styles.labelsEdit}>Mailing Address</label>
          <AppTextField
            id="address"
            placeholder="Enter Mailing Address"
            variant="standard"
            value={formik.values.address}
            className={classes.textFields}
            onChange={formik.handleChange("address")}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
            InputLabelProps={{
              shrink: true
            }}
            InputProps={{ disableUnderline: true }}
          />
        </Grid>
        <Grid
          style={{ display: "flex", flexDirection: "column" }}
          item
          md={4}
          xs={6}>
          <label className={styles.labelsEdit}>Status</label>
          <NewSelect
            fullWidth
            style={{ minWidth: 244, maxWidth: 369 }}
            items={[
              { label: "Active", value: true },
              { label: "Inactive", value: false }
            ]}
            value={formik?.values?.is_active}
            onChange={formik.handleChange("is_active")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default EntityForm;
