import React, { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "../../../../../components/AppTable/AppTable.module.css";
import DotsMenu from "../../../../../assets/icons/dotsMenu.svg";
import { Menu, MenuItem, Button, Tooltip, Grid } from "@material-ui/core";
import { AppDispatcher } from "../../../../../redux/index";
import CloseIcon from "../../../../../assets/icons/cross.svg";
import styles1 from "../../../investments/investments.module.css";
import filterIcon from "../../../../../assets/icons/Filter.svg";
import BluefilterIcon from "../../../../../assets/icons/blueFilter.svg";
import DotFilterIcon from "../../../../../assets/icons/DotFilter.svg";
import CSV from "../../../../../assets/icons/NewCardSvg/CSV.svg";
import FWDEmail from "../../../../../assets/icons/NewCardSvg/FWDEmail.svg";
import SearchInput from "components/Search";
import { EntityService } from "network/entityService";
import { InvestmentListService } from "network/investment_ListService";
import { SponsorService } from "network/sponsorService";
import noSponsors from "../../../../../assets/emptyState/noSponsors.svg";
import Illustrations from "components/illustration/Illustrations";
import NewTable from "components/NewTable/NewTable";
import ManageColumn from "components/ManageColumns";
import TableTopFilter from "components/TableTopFilter/TableTopFilter";
import CustomSelectFilter from "components/StyledComponents/customSelectFilter";
import MoreFilterContainer from "components/MoreFilterContainer/MoreFilterContainer";
import { FilterStatus, truncate } from "helpers/Common";
import LightTooltip from "components/LightTooltip";
import NewDropDown from "components/NewDropDown";
import TabletVIewSelectMenu from "components/StyledComponents/TabletVIewSelectMenu";
import FrwrdAsEmail from "components/ForwarsAsEmail";
import Toast from "components/toast";

const addedByList = [
  {
    label: "Managed By",
    value: "managed_by"
  },
  {
    label: "Admin",
    value: "Admin"
  },
  {
    label: "Investor",
    value: "Investor"
  }
];

const sponsorStatusList = [
  {
    label: "Status",
    value: "All"
  },
  {
    label: "Active",
    value: "Active"
  },
  {
    label: "Inactive",
    value: "inactive"
  }
];

const frdasEamilList = [
  {
    label: "Forward as Email",
    value: "frdasEmail"
  },
  {
    label: "PDF",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const ExportList = [
  {
    label: "Export",
    value: "exportList"
  },
  {
    label: "Pdf",
    value: "pdf"
  },
  {
    label: "CSV",
    value: "csv"
  }
];

const TableHeaders = [
  {
    id: "sponsor_name",
    name: "Sponsor Name"
  },
  {
    id: "company_name",
    name: "Company Name"
  },
  {
    id: "email",
    name: "Email ID"
  },
  {
    id: "managed_by",
    name: "Managed By"
  },
  {
    id: "contact_number",
    name: "Phone Number"
  },
  {
    id: "associated_entities",
    name: "Associated Entities"
  },
  {
    id: "associated_investments",
    name: "Associated Investments"
  },
  {
    id: "status",
    name: "Status"
  }
];

function Table({ tableData, permissions }) {
  const history = useHistory();
  const [data, setData] = useState(tableData);
  const [THeaders, setTHeaders] = useState(TableHeaders);
  const [sponsorStatus, setSponsorStatus] = useState("All");
  const [addedBy, setAddedBy] = useState("managed_by");
  const [FrdasEmail, setFrdasEmail] = useState("frdasEmail");
  const [Export, setExport] = useState("exportList");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [showFilter, setShowFilter] = useState(false);
  const [checked, setChecked] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageResetValue, setPageResetValue] = useState(0);
  const [isFilterActive, setFilterActive] = useState(false);
  const [expandIndex, setExpandIndex] = useState(-1);
  const [apprenace, setApperence] = useState();
  const [invAppearance, setInvAppearance] = useState();
  const [frdEmailOpen, setFrdasEmailOpen] = useState(false);

  const mappingObject = {
    "Investment Name": "investment_id",
    "Entity Name": "entity_id"
  };

  const handleApply = (data) => {
    setShowFilter(false);
    setChecked([...data]);
  };

  const handleClear = () => {
    setShowFilter(false);
    setChecked([]);
  };

  const setQuery = (val) => {
    setSearchQuery(val);
  };

  const ChipsComponent = () => {
    let newArray = [];
    checked.forEach((item) => {
      item.value.forEach((el) => {
        let newObj = {
          id: el.id,
          name: el.name,
          key: item.key
        };
        newArray.push(newObj);
      });
    });

    return (
      <div className={styles1.chipsCtr}>
        {newArray.length > 0
          ? newArray.map((item, index) => {
              return (
                <div key={index} className={styles1.chips}>
                  <div className={styles1.chipsText}>{item.name}</div>
                  <div onClick={() => onDeleteClick(item.key, item.id)}>
                    <img
                      className={styles1.chipsImg}
                      src={CloseIcon}
                      alt="cross"
                    />
                  </div>
                </div>
              );
            })
          : null}
      </div>
    );
  };

  const onDeleteClick = (key, id) => {
    let newCheckedData = checked;
    let keyData = newCheckedData.find((el) => el.key === key)?.value;
    const keyIndex = newCheckedData.findIndex((el) => el.key === key);
    let spliceIndex = keyData.findIndex((el) => el.id === id);
    keyData.splice(spliceIndex, 1);
    newCheckedData[keyIndex].value = keyData;
    setChecked([...newCheckedData]);
  };

  const handleDeleteSponsor = () => {
    history.push("/u/sponsors/deleteSponsor");
  };

  const handleCellClick = (id) => {
    AppDispatcher.setSponsorDetailID(id);
    history.push("/u/sponsors/detail");
  };
  const handleClick = (event, item, managed_by) => {
    setAnchorEl(event.currentTarget);
    AppDispatcher.setSponsorId(item);
    setApperence(managed_by === "Admin");
    setInvAppearance(managed_by === "Investor");
  };
  const handleChangeStatus = () => {
    history.push("/u/sponsors/changeStatus");
  };

  const handleEdit = () => {
    history.push("/u/sponsors/editSponsor");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onHandleClick = () => {
    setShowFilter(!showFilter);
  };

  const getInvestmentName = async (page = 1, searchText = "") => {
    let params = "";
    if (searchText) {
      params += `&search=${searchText}`;
    }
    params += `&page=${page}`;

    const response = await InvestmentListService.getInvestmentName([
      `?query={id,name}${params}`
    ]);
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getEntityNames = async (page = 1, search = "") => {
    const response = await EntityService.getEntityListByRole({
      query: `{id,name}`,
      entity_type: "SPONSOR",
      page,
      ...(search ? { search } : {})
    });
    if (response.success) {
      return {
        data: response.data.results,
        maxPage: response.data.pages
      };
    }
    return { data: [], maxPage: 1 };
  };

  const getSponsorsData = async (pageNum, isAppend = false) => {
    setLoading(true);
    let url = `?status=${sponsorStatus}`;
    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (addedBy !== "managed_by") {
      url += `&managed_by=${addedBy}`;
    }

    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          if (url.length === 0) {
            url += `?${mappingObject[item.key]}=`;
          } else {
            url += `&${mappingObject[item.key]}=`;
          }
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (url.length > 0) {
      url += `&page=${pageNum}&page_size=${pageSize}`;
    } else {
      url += `?page=${pageNum}&page_size=${pageSize}`;
    }
    const response = await SponsorService.sponsorsFilter([url]);
    if (response.success) {
      if (isAppend) {
        setData([...data, ...response.data.results]);
      } else {
        setData(response.data.results);
      }
      setCount(response.data.count);
      setLoading(false);
    }
    setLoading(false);
  };

  const changePage = (val) => {
    if (val > page) {
      setPage(val);
      getSponsorsData(val, true);
      setPageResetValue(val - 1);
    }
  };

  const changePageSize = (val) => {
    setPageSize(val);
    setPageResetValue(val - 1);
  };

  const handleCellItemClick = (e, type, id) => {
    e.stopPropagation();
    if (id) {
      switch (type) {
        case "entity":
          AppDispatcher.setEntitiesDetailData({
            id: id,
            entity_type: "SPONSOR"
          });
          history.push("/u/entities/detail");
          break;
        case "investment":
          AppDispatcher.setInvestmentDetailID(id);
          history.push("/u/investments/detail");
          break;
      }
    }
  };

  const setFilterMeta = () => {
    setFilterState([
      {
        key: "Investment Name",
        value: getInvestmentName
      },
      {
        key: "Entity Name",
        value: getEntityNames
      }
    ]);
  };

  useEffect(() => {
    setFilterMeta();
  }, []);

  useEffect(() => {
    setPage(1);
    setPageResetValue(0);
    getSponsorsData(1);
    setFilterActive(FilterStatus(checked));
  }, [searchQuery, checked, pageSize, count, sponsorStatus, addedBy]);

  /*********************************************************************/
  /*************      Forward as Email and Export  ****************  ******** */
  /*********************************************************************/

  async function getExport_PDF_CSV_Link(emailData, preview) {
    let url = `?status=${sponsorStatus}`;

    if (emailData) {
      if (preview === "preview") {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}`;
      } else {
        url += `&${emailData.type}-download=true&sequence=${emailData.sequence}&emails=${emailData.emails}`;
      }
    } else {
      url += `&${Export}-download=true&sequence=${THeaders.map((el) => el.id)}`;
    }

    if (searchQuery.length > 0) {
      url += `&search=${searchQuery}`;
    }
    if (addedBy !== "managed_by") {
      url += `&managed_by=${addedBy}`;
    }

    if (checked.length > 0) {
      checked.forEach((item) => {
        if (item.value.length > 0) {
          if (url.length === 0) {
            url += `?${mappingObject[item.key]}=`;
          } else {
            url += `&${mappingObject[item.key]}=`;
          }
          item.value.forEach((el) => {
            url += `${el.id},`;
          });
          url = url.substring(0, url.length - 1);
        }
      });
    }
    if (url.length > 0) {
      url += `&page=${page}&page_size=${pageSize}`;
    } else {
      url += `?page=${page}&page_size=${pageSize}`;
    }

    setExport("exportList");
    const response = await SponsorService.get_Pdf_Or_Csv([url]);
    if (response.success) {
      if (emailData) {
        if (preview === "preview") {
          const file = response.data.url;
          const link = document.createElement("a");
          link.href = file;
          link.setAttribute("download", emailData.type);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          Toast.showSuccessToast(`Download ${emailData.type} Successful`);
        } else {
          Toast.showSuccessToast(response.data.message);
        }
      } else {
        const file = response.data.url;
        const link = document.createElement("a");
        link.href = file;
        link.setAttribute("download", emailData.type);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        Toast.showSuccessToast(`Export ${Export} Successful`);
      }
    } else {
      Toast.showErrorToast(
        emailData
          ? `Failed To Send ${emailData.type}`
          : `Export-${Export} Failed`
      );
    }
  }

  /**Don't Combine this Two useEffect**/
  useEffect(() => {
    if (Export !== "exportList") {
      getExport_PDF_CSV_Link(0);
    }
  }, [Export]);

  useEffect(() => {
    if (FrdasEmail !== "frdasEmail") {
      setFrdasEmailOpen(true);
    }
  }, [FrdasEmail]);

  /******************************************************************** */
  /*********************       New Table Data           *****************/
  /*********************************************************************/

  const getTooltipData = (item) => {
    return (
      <div className={styles.toolTipBox}>
        <Grid container style={{ margin: "16px 0px 16px 0px" }}>
          <Grid item xs={6}>
            <h4 className={styles.tooltipkey}>Sponsor ID:</h4>
          </Grid>
          <Grid item xs={6}>
            <div
              key="sponsor_id"
              className={styles.text}
              style={{ fontWeight: 600 }}>
              {item.base_id || "-"}
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 16 }}>
          <Grid item xs={6}>
            <h4 className={styles.tooltipkey}>Sponsor Status:</h4>
          </Grid>
          <Grid item xs={6}>
            <div
              key="status"
              style={{
                minHeight: 28,
                width: 72,
                alignItems: "center",
                backgroundColor:
                  item.status === "Active" ? "#EDFFEF" : "#FCEBEB ",
                borderRadius: 153,
                textAlign: "center",
                fontFamily: "Inter Bold",
                fontSize: 14,
                paddingTop: 4,
                color: item.status === "Active" ? "#50E138" : "#E13838",
                fontWeight: 700,
                marginLeft: 8
              }}>
              {item.status}
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 16 }}>
          <Grid item xs={6}>
            <h4 className={styles.tooltipkey}>Created By:</h4>
          </Grid>
          <Grid item xs={6}>
            <div
              key="sponsor_id"
              className={styles.text}
              style={{ fontWeight: 600 }}>
              {item.created_by_name || "-"}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  };

  const Tabledatalist =
    data &&
    data.map((item, index) => {
      return [
        <div
          key="sponsor_name"
          title={`${item.first_name} ${item.last_name}`}
          className={`${styles.text} `}
          onClick={() => handleCellClick(item?.id)}
          style={{ cursor: "pointer" }}>
          <LightTooltip title={getTooltipData(item)}>
            <p className={styles.fitContent}>
              {truncate(
                `${item.first_name} ${item.last_name ? item.last_name : "-"}`,
                15
              )}
            </p>
          </LightTooltip>
        </div>,
        <div
          key="company_name"
          title={item.company_name}
          className={styles.text}>
          <LightTooltip title={getTooltipData(item)}>
            <p className={styles.fitContent}>
              {truncate(item.company_name || "-", 15)}
            </p>
          </LightTooltip>
        </div>,
        <div key="email" title={item.email} className={styles.text}>
          <LightTooltip title={getTooltipData(item)}>
            <p className={styles.fitContent}>
              {truncate(item.email || "-", 15)}
            </p>
          </LightTooltip>
        </div>,
        <div key="managed_by" className={styles.text}>
          <LightTooltip title={getTooltipData(item)}>
            <p className={styles.fitContent}>
              {item?.managed_by ? <>{item.managed_by}</> : "-"}
            </p>
          </LightTooltip>
        </div>,

        <div
          key="contact_number"
          className={styles.text}
          style={{ whiteSpace: "nowrap" }}>
          <LightTooltip title={getTooltipData(item)}>
            <p className={styles.fitContent}>
              {`${item.country_code ? item.country_code + "-" : ""}${
                item.phone_number ? item.phone_number : "-"
              }`}
            </p>
          </LightTooltip>
        </div>,
        <Fragment key="associated_entities">
          {item.entitys?.length ? (
            <>
              <NewDropDown
                index={index}
                item={item?.entitys?.map((item) => {
                  return { name: item?.name, id: item?.id };
                })}
                handleCellItemClick={handleCellItemClick}
                type="entity"
                expandIndex={expandIndex}
                setExpandIndex={setExpandIndex}
              />
            </>
          ) : (
            <div className={styles.text}>-</div>
          )}
        </Fragment>,
        <Fragment key="associated_investments">
          {item.investments?.length ? (
            <NewDropDown
              index={index}
              item={item?.investments?.map((item) => {
                return { name: item?.name, id: item?.id };
              })}
              handleCellItemClick={handleCellItemClick}
              type="investment"
              expandIndex={expandIndex}
              setExpandIndex={setExpandIndex}
            />
          ) : (
            <div className={styles.text}>-</div>
          )}
        </Fragment>,
        <div
          key="status"
          style={{
            cursor: "pointer",
            minHeight: 28,
            width: 72,
            alignItems: "center",
            backgroundColor:
              item?.status === "Pending"
                ? "#EBEFFF"
                : item?.status == "Active"
                ? "#EDFFEF"
                : "#FCEBEB ",
            borderRadius: 153,
            textAlign: "center",
            fontFamily: "Inter Bold",
            fontSize: 14,
            paddingTop: 4,
            color:
              item?.status == "Active"
                ? "#50E138"
                : item?.status === "Pending"
                ? "#445EBE"
                : "#E13838",
            fontWeight: 700
          }}>
          {item?.status}
        </div>,
        (permissions?.is_update || permissions?.is_delete) && (
          <div key="Action">
            <Button
              id="basic-button"
              aria-controls="basic-menu"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(e) => handleClick(e, item.id, item.managed_by)}>
              <img src={DotsMenu} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  marginTop: 40,
                  boxShadow: "2px 2px 4px #E9E9E9"
                }
              }}
              MenuListProps={{
                "aria-labelledby": "basic-button"
              }}>
              {permissions?.is_update ? (
                <div>
                  <MenuItem onClick={handleEdit} className={styles.menuItem}>
                    Edit Sponsor
                  </MenuItem>
                  <MenuItem
                    onClick={() => !invAppearance && handleChangeStatus()}
                    className={styles.menuItem}>
                    {!invAppearance ? (
                      "Change Status"
                    ) : (
                      <div className={styles.disabledMenuItem}>
                        Change Status
                      </div>
                    )}
                  </MenuItem>
                </div>
              ) : null}
              {permissions?.is_delete ? (
                <MenuItem
                  className={styles.menuItemDelete}
                  onClick={() => {
                    apprenace && handleDeleteSponsor();
                  }}>
                  {apprenace ? (
                    "Delete"
                  ) : (
                    <div style={{ color: "#ababa2" }}>Delete</div>
                  )}
                </MenuItem>
              ) : null}
            </Menu>
          </div>
        )
      ];
    });

  /******************************************************************* */
  /******************************************************************* */
  /******************************************************************* */

  return (
    <>
      <FrwrdAsEmail
        AllHeaders={TableHeaders}
        FrdasEmail={FrdasEmail}
        setFrdasEmail={setFrdasEmail}
        open={frdEmailOpen}
        setOpen={setFrdasEmailOpen}
        clickOnShare={(payload) => {
          getExport_PDF_CSV_Link(payload);
        }}
        clickOnPreview={(payload) => {
          getExport_PDF_CSV_Link(payload, "preview");
        }}
      />

      <div className={styles.tableCtrOuter}>
        <TableTopFilter
          tabletViewCSV={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={CSV}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={ExportList}
                clickMenuItem={(obj, close) => {
                  setExport(obj?.value);
                  close();
                }}
              />
            </div>
          }
          tabletViewFWDEmail={
            <div className={styles.clickable}>
              <TabletVIewSelectMenu
                image={
                  <img
                    src={FWDEmail}
                    alt="doc"
                    layout="fixed"
                    height={48}
                    width={48}
                  />
                }
                menuItems={frdasEamilList}
                clickMenuItem={(obj, close) => {
                  setFrdasEmail(obj?.value);
                  close();
                }}
              />
            </div>
          }
          filterSection1={[
            <SearchInput setQuery={setQuery} />,
            <CustomSelectFilter
              border={true}
              placeholder="Status"
              items={sponsorStatusList}
              onChange={(e) => setSponsorStatus(e.target.value)}
              value={sponsorStatus}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Added By"
              items={addedByList}
              onChange={(e) => setAddedBy(e.target.value)}
              value={addedBy}
            />,
            <Tooltip title="More Filters" arrow>
              <div className={styles.clickable} onClick={onHandleClick}>
                <img
                  src={
                    isFilterActive
                      ? DotFilterIcon
                      : showFilter
                      ? BluefilterIcon
                      : filterIcon
                  }
                  alt="alt"
                  layout="fixed"
                  width={45}
                  height={45}
                />
              </div>
            </Tooltip>,

            showFilter && (
              <>
                <MoreFilterContainer
                  open={showFilter}
                  checked={checked}
                  handleClose={onHandleClick}
                  handleApply={handleApply}
                  handleClear={handleClear}
                  filters={filterState}
                />
              </>
            )
          ]}
          filterSection2={[
            <ManageColumn
              AllHeaders={TableHeaders}
              setTableHeaders={setTHeaders}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Forward as Email"
              items={frdasEamilList}
              value={FrdasEmail}
              onChange={(e) => setFrdasEmail(e.target.value)}
            />,
            <CustomSelectFilter
              border={true}
              placeholder="Export"
              items={ExportList}
              value={Export}
              onChange={(e) => setExport(e.target.value)}
            />
          ]}
        />

        <ChipsComponent />
        <NewTable
          loading={loading}
          Header={[
            ...THeaders,
            (permissions?.is_update || permissions?.is_delete) && {
              id: "Action",
              name: "Action"
            }
          ]}
          tableData={Tabledatalist}
          pageSize={pageSize}
          changePage={changePage}
          changePageSize={changePageSize}
          count={count}
          pageResetValue={pageResetValue}
          labelRowsPerPage="Total No Of Sponsors Per Page"
          Illustration={
            <Illustrations
              src={noSponsors}
              heading="No Sponsors Found"
              subHeading="Contact Your Admin To Access The List"
            />
          }
        />
      </div>
    </>
  );
}

export default Table;
