import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import menuItems from "./menuItems";
import Logo from "../assets/icons/Logo.svg";
import styles from "./privateLayout.module.scss";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppDispatcher } from "../redux/index";

const drawerWidth = 262;
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    position: "relative",
    "@media (min-width: 1920px)": {
      maxWidth: "1920px",
      marginLeft: "auto",
      marginRight: "auto"
    }
  },
  drawer: {
    "@media (max-width: 1023px)": {
      width: "190px",
      flexShrink: 0
    },
    "@media (min-width: 1023px)": {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none"
    }
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  sideTrackdrawerPaper: {
    width: drawerWidth,
    "@media (min-width: 1920px)": {
      left: "unset",
      height: "auto"
    },
    "@media (max-width: 1023px)": {
      width: "190px"
    }
  },
  drawerPaper: {
    width: drawerWidth,
    "@media (max-width: 1080px)": {
      display: "none"
    },
    "@media (min-width: 1920px)": {
      left: "unset",
      height: "auto"
    }
  },
  drawerPaperMedia: {
    width: drawerWidth,
    "@media (min-width: 1080px)": {
      display: "none"
    }
  },
  content: {
    position: "absolute",
    width: "100vw",
    paddingLeft: "20px",
    padding: "20px",
    "@media (min-width: 1080px)": {
      paddingLeft: "270px"
    },
    "@media (min-width: 1922px)": {
      position: "static",
      paddingLeft: "20px"
    }
  },
  contentInvestor: {
    width: "100vw",
    paddingLeft: "270px",
    padding: "20px",
    // "@media (max-width: 1023px)": {
    //   paddingLeft: "200px"
    // },
    "@media (min-width: 767px)": {
      maxWidth: "1920px",
      paddingLeft: "20px"
    }
  },
  logo: {
    cursor: "pointer"
  },
  logo1: {
    cursor: "pointer",
    "@media (max-width: 1023px)": {
      marginLeft: "-22px"
    }
  },
  stepLabel: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 400,
    whiteSpace: "nowrap",
    lineHeight: 0
  },
  stepLabelActive: {
    fontFamily: "Inter Bold",
    color: "#5775E5 !important"
  },
  stepperContainer: {
    width: "100%",
    height: "100vh"
  }
}));

function PrivateLayout(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { userPermissions, user, drawer, ...retainFilter } = useSelector(
    (store) => store.app
  );

  const ResetRetainedFilter = () => {
    AppDispatcher.retainFilters({
      ...retainFilter,
      searchQuery: "",
      checked: [],
      status: "All",
      addedVia: "added_via"
    });
    AppDispatcher.retainCount(10);
    AppDispatcher.retainManageColumn([]);
    AppDispatcher.retainPage(1);
    AppDispatcher.retainPageValue(0);
    AppDispatcher.retainPageSize(10);
  };

  const handleChange = (id, link) => {
    history.push(link);
    AppDispatcher.setDraweropen(false);
    AppDispatcher.setDateRange({});
    AppDispatcher.setGroupBy("K1");
    ResetRetainedFilter();
  };
  const checkActive = (item) => {
    let isActive = location.pathname
      .split("/")[2]
      .toLowerCase()
      .includes(item.toLowerCase());
    return isActive;
  };
  const grantedViewPermission = (module_name) => {
    if (user.role === "ADMIN") {
      return true;
    }
    let module = userPermissions?.find(
      (permission) => permission.module_name === module_name
    );
    return module?.operations?.is_retrieve;
  };

  const ListItem = ({
    item: { id, title, link, active, inActive, hasViewPermission, module_name },
    isActive
  }) => (
    <>
      {hasViewPermission || grantedViewPermission(module_name) ? (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 35,
            cursor: "pointer",
            userSelect: "none",
            borderRight: isActive ? "4px solid #5775E5" : null,
            marginLeft: 46
          }}
          onClick={() => {
            handleChange(id, link);
          }}>
          <div
            style={{
              marginRight: 20,
              color: "#fff",
              width: 20,
              marginTop: 3
            }}>
            <img
              src={isActive ? inActive : active}
              style={{ height: 17, width: 17 }}
            />
          </div>
          <Typography
            variant="h4"
            style={{
              fontFamily: isActive ? "Inter Bold" : "Inter Regular",
              color: isActive ? "#5775E5" : "#BBBBBB",
              fontSize: 18
            }}>
            {title}
          </Typography>
          <div
            style={{
              position: "absolute",
              borderRadius: "8px",
              height: "42px"
            }}></div>
        </div>
      ) : null}
    </>
  );

  return (
    <>
      <div className={classes.root}>
        {location.pathname === "/u/upload/template" ? (
          <main style={{ flexGrow: 1 }}>
            <Grid item xs={12}>
              {props.children}
            </Grid>
          </main>
        ) : (
          <>
            <CssBaseline />
            <nav className={classes.drawer} aria-label="mailbox folders">
              {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
              <>
                <Drawer
                  classes={{
                    paper: classes.drawerPaper
                  }}
                  variant={"permanent"}
                  open>
                  <div className={styles.logo}>
                    <img
                      className={classes.logo}
                      src={Logo}
                      onClick={() => history.push("/u/dashboard")}
                    />
                  </div>
                  {menuItems.map((item) => (
                    <ListItem
                      item={item}
                      key={item.id}
                      isActive={checkActive(item.id)}
                    />
                  ))}
                </Drawer>
                <Drawer
                  classes={{
                    paper: classes.drawerPaperMedia
                  }}
                  open={drawer}
                  onClose={() => AppDispatcher.setDraweropen(false)}>
                  <div className={styles.logo}>
                    <img
                      className={classes.logo}
                      src={Logo}
                      onClick={() => history.push("/u/dashboard")}
                    />
                  </div>
                  {menuItems.map((item) => (
                    <ListItem
                      item={item}
                      key={item.id}
                      isActive={checkActive(item.id)}
                    />
                  ))}
                </Drawer>
              </>
            </nav>
            <main className={classes.content}>
              <Grid item xs={12} className={styles.childrenCtr}>
                {props.children}
              </Grid>
            </main>
          </>
        )}
      </div>
    </>
  );
}
export default PrivateLayout;
