import React, { useEffect, useRef, useState } from "react";
import {
  Badge,
  CircularProgress,
  makeStyles,
  MenuItem
} from "@material-ui/core";
import ExpandIcon from "assets/icons/expandIcon.svg";
import DeleteDocIcon from "assets/icons/deleteDocIcon.svg";
import CollapseIcon from "assets/icons/collapseIcon.svg";
import StyledMenu from "components/StyledComponents/StyledMenu";
import Toast from "components/toast";
import { CommonService } from "network/commonService";
import newuploadDocIcon from "assets/icons/newuploadDocIcon.svg";

import { CovertToMb, truncate, verifyDocUploadType } from "helpers/Common";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "5px",
    borderRadius: 4,
    cursor: "pointer"
  },
  title: {
    fontFamily: "Inter Regular",
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0,
    marginRight: 5
  },
  count: {
    minWidth: 18,
    minHeight: 18,
    backgroundColor: "#445EBE",
    borderRadius: "50%",
    color: "#FFFFFF",
    fontFamily: "Inter Regular",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0,
    margin: 5,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  add: {
    marginRight: 5,
    cursor: "pointer",
    width: 20,
    height: "100%",
    display: "flex",
    justifyContent: "center"
  },
  expand: {
    marginLeft: 15
  },
  progress: {
    width: 18,
    margin: "0 5px"
  },
  docNameContainer: {
    maxWidth: 400,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  docName: {
    fontFamily: "Inter Bold",
    textDecoration: "none",
    fontSize: 18,
    fontWeight: 700,
    letterSpacing: 0,
    color: "#445EBE"
  },
  deleteDoc: {
    width: 20,
    marginLeft: 10,
    cursor: "pointer"
  },
  docContainer: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  docImg: {
    height: 40,
    width: 40,
    borderRadius: 8
  },
  addFile: {
    cursor: "pointer"
  }
}));

const DocumentsUpload = ({
  filterDocList = [],
  title = "",
  type = "",
  listData = [],
  editable = true,
  setListData = () => {},
  custom = "",
  setSize = () => {},
  deleteHandler,
  disabled
}) => {
  const other = title.toLocaleLowerCase() === "other";
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  // const [otherDialogOpen, setOtherDialogOpen] = useState(false);
  const [docs, setDocs] = useState([]);
  // const [isOtherCategory, setIsOtherCategory] = useState(false);
  const inputFile = useRef(null);
  const open = Boolean(anchorEl);

  const addFile = (e) => {
    e.stopPropagation();
    // if (other) {
    //   setIsOtherCategory(true);
    //   setOtherDialogOpen(true);
    //   return;
    // }
    inputFile.current.click();
  };

  const onFileDrop = (e) => {
    e.stopPropagation();
    if (!verifyDocUploadType(e)) return;
    const newFile = e.target.files[0];
    if (newFile) {
      let data = new FormData();
      data.append("file", newFile);
      data.append("name", newFile.name);
      handleAddDoc(data);
    }
  };
  function getSize(data) {
    let sum = 0;
    data.forEach((el) => {
      sum += el.size;
    });
    return CovertToMb(sum);
  }

  useEffect(() => {
    if (other) {
      let selectedDocs = [];
      listData.forEach((el) => {
        if (!filterDocList.find((type) => el.type === type.id)) {
          selectedDocs.push(el);
        }
      });

      setSize(getSize(selectedDocs));
      setDocs(selectedDocs);
    } else {
      type
        ? (setDocs(listData.filter((e) => e.type === type)),
          setSize(getSize(listData.filter((e) => e.type === type))))
        : (setDocs(listData), setSize(getSize(listData)));
    }
  }, [filterDocList.length, listData.length]);

  const handleAddDoc = async (data) => {
    setIsUploading(true);
    const resp = await CommonService.imageUpload(data);
    if (resp.success) {
      const updatedItem = {
        name: resp.data.name,
        id: resp.data.id,
        type: type,
        link: resp.data.file,
        size: resp.data.file_size
      };
      const updatedFileList = [...docs, updatedItem];

      setSize(getSize(updatedFileList));
      setDocs(updatedFileList);
      let list = [...listData];
      list.push(updatedItem);
      setListData(list);
      Toast.showSuccessToast("Documents Upload Successfully");
    } else {
      Toast.showErrorToast("Error Uploading Documents");
    }
    setIsUploading(false);
  };

  const deleteDoc = async (docId, id) => {
    setIsUploading(true);
    if (deleteHandler) {
      deleteHandler(docId);
      let data = docs.filter((item) => item.id !== id);
      setDocs(data);
      let list = listData.filter((item) => item.id !== id);
      setListData(list);
      setIsUploading(false);
    } else {
      let resp = { success: true };
      if (docId) {
        resp = await CommonService.deleteDoc(docId);
      }
      if (resp.success) {
        resp = await CommonService.deleteMedia(id);
      }
      if (resp.success) {
        let data = docs.filter((item) => item.id !== id);
        setDocs(data);
        let list = listData.filter((item) => item.id !== id);
        setListData(list);
        Toast.showSuccessToast("Documents Deleted Successfully");
        setIsUploading(false);
      } else {
        Toast.showErrorToast("Failed to Delete Document");
      }
      setIsUploading(false);
    }
  };

  const download = (e) => {
    fetch(e, {
      method: "GET"
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch(() => {
        Toast.showErrorToast("Download Failed");
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsExpanded(false);
  };

  const handleExpand = (event) => {
    setAnchorEl(event.currentTarget);
    if (!disabled) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };

  return (
    <>
      <div style={custom ? custom : null} className={classes.container}>
        <div
          onClick={!disabled ? addFile : null}
          className={classes.title}
          style={{ color: !editable && docs.length ? "#445EBE" : "#191919" }}>
          <div onClick={!disabled ? addFile : null} className={classes.addFile}>
            <Badge
              badgeContent={docs.length}
              style={{ color: "#5775E5" }}
              color="primary">
              {docs.length ? (
                <img src={newuploadDocIcon} className={classes.docImg} />
              ) : (
                <img src={newuploadDocIcon} className={classes.docImg} />
              )}
            </Badge>
          </div>
        </div>
        {isUploading ? (
          <div className={classes.progress}>
            <CircularProgress size={18} />
          </div>
        ) : null}
        {editable ? (
          <>
            <input
              type="file"
              id="file"
              ref={inputFile}
              style={{ display: "none" }}
              onChange={onFileDrop}
            />
          </>
        ) : null}
        {docs.length ? (
          <div
            className={classes.expand}
            onClick={!disabled ? handleExpand : null}>
            <img
              src={isExpanded ? CollapseIcon : ExpandIcon}
              alt="Expand/Collapse"
            />
          </div>
        ) : null}
      </div>
      {docs.length ? (
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            "aria-labelledby": "demo-customized-button"
          }}
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          style={{ width: "100%" }}
          open={open}
          onClose={handleClose}>
          {docs.length &&
            docs.map((doc) => (
              <MenuItem
                onMouseEnter={(e) =>
                  (e.target.style.backgroundColor = "#F5F7FB")
                }
                onMouseLeave={(e) =>
                  (e.target.style.backgroundColor = "#ffffff")
                }
                value={"Investor"}
                key={doc.id}
                style={{
                  fontSize: 14,
                  fontFamily: "Inter Regular",
                  marginTop: 5,
                  fontWeight: 400
                }}>
                <div className={classes.docContainer}>
                  <div className={classes.docNameContainer}>
                    <a
                      className={classes.docName}
                      href={doc.link}
                      onClick={() => download(doc.link)}
                      download
                      target="_blank"
                      rel="noopener noreferrer">
                      {truncate(doc.name, 20)}
                    </a>
                  </div>
                  {editable ? (
                    <div
                      className={classes.deleteDoc}
                      onClick={
                        !disabled ? () => deleteDoc(doc.docId, doc.id) : null
                      }>
                      <img src={DeleteDocIcon} alt="Delete Document" />
                    </div>
                  ) : null}
                </div>
              </MenuItem>
            ))}
        </StyledMenu>
      ) : null}
    </>
  );
};

export default DocumentsUpload;
